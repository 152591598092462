<template lang="pug">
.bill-top-container
  .bill-top-header
    el-row
      el-col(:span="8")
        .bill-top__label Vendor:
        .bill-top__value.fs-14 {{ getPrettyVendorByCode(bill.vendorCode) || '-' }}
      el-col(:span="7")
        .bill-top__label Billing ID:
        .bill-top__value {{ bill.clientAccount || '-' }}
      el-col(:span="5")
        .bill-top__label Pear ID:
        .bill-top__value {{ bill.id }}
      el-col(:span="4")
        el-button.bill-top__buttons(type="text" :disabled="!bill.priorBillId" @click="goToPriorBill")
            i.el-icon-back
            span Previous Bill
        el-button.bill-top__buttons(type="text" :disabled="bill && bill.nextBillIds && !bill.nextBillIds[0]" @click="goToNextBill")
          span Next Bill &nbsp;
          i.el-icon-right
  .bill-top-content
    el-row
      el-col(:span="10")
        span.bill-top__label Current Charges:
        span(v-if="nonUsBill").bill-top__value {{ bill.nativeCurrency }} {{ bill.currentChargesNativeCharge | currency('') }} ({{ bill.currency }} {{ bill.currentCharges | currency('') }})
        span(v-if="!nonUsBill").bill-top__value {{ bill.currentCharges | currency }}
      el-col(:span="7")
        .bill-top__label Invoice Date:
        .bill-top__value {{ bill.statementDate | datetime('LL/dd/yy') }}
      el-col(:span="7")
    el-row
      el-col(:span="10")
        span.bill-top__label Past Due:
        span(v-if="nonUsBill").bill-top__value {{ bill.nativeCurrency }} {{ bill.pastDueAmountNativeCharge | currency('') }} ({{ bill.currency }} {{ bill.pastDueAmount | currency('') }})
        span(v-if="!nonUsBill").bill-top__value {{ bill.pastDueAmount | currency }}
      el-col(:span="7")
        .bill-top__label Invoice Number :
        .bill-top__value {{ bill.invoiceNumber || 'N/A' }}
      el-col(:span="7")
  .bill-top-footer
    el-row
      el-col(:span="10")
        .bill-top__label
          span Amount Due:
        .bill-top__label.fs-14
          span(v-if="nonUsBill").bill-top__value {{ bill.nativeCurrency }} {{ bill.totalChargesNativeCharge | currency('') }} ({{ bill.currency }} {{ bill.totalCharges | currency('') }})
          span(v-if="!nonUsBill").bill-top__value {{ bill.totalCharges | currency }}
          span &nbsp; by {{ bill.dueDate | datetime('LL/dd/yy') }}
    el-row(v-if="nonUsBill")
      el-col(:span="10")
        .bill-top__label
          span Exchange Rate at Invoice Date:
        .bill-top__label.fs-14
          span.bill-top__value 1 {{ currentBillExchangeRateObject.currency }} = {{ (1 / currentBillExchangeRateObject.rate).toFixed(2)  }} USD
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  watch: {
    bill: {
      immediate: true,
      async handler(value) {
        if (this.nonUsBill && this.currentBillExchangeRateObject === 'N/A') {
          await this.getExchangeRates({
            customerId: this.$route.params.customerId,
            billId: this.$route.params.billId,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      customerId: 'customers/id',
      currentBillExchangeRateObject: 'bills/currentBillExchangeRateObject',
      bill: 'bills/bill',
      getVendorIdByCode: 'resources/getVendorIdByCode',
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
    }),
    nonUsBill() {
      return this.bill.nativeCurrency && this.bill.nativeCurrency !== 'USD';
    },
  },
  methods: {
    ...mapActions({
      getExchangeRates: 'bills/getExchangeRates',
      loadBillPath: 'bills/loadBillPath',
    }),
    goToPriorBill() {
      this.loadBillPath({
        path: {
          name: 'customer.bills.info',
          params: {
            customerId: String(this.bill.customerId),
            billId: this.bill.priorBillId,
          },
          query: this.$route.query,
        },
        router: this.$router,
      });
    },
    goToNextBill() {
      this.loadBillPath({
        path: {
          name: 'customer.bills.info',
          params: {
            customerId: String(this.bill.customerId),
            billId: this.bill.nextBillIds[0],
          },
          query: this.$route.query,
        },
        router: this.$router,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bill-top-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .el-col {
    white-space: nowrap;
  }
  .fs-14 {
    font-size: 14px;
  }
}

.text-blue {
  color: #273a58;
}

.bill-top {
  &__label {
    display: inline-block;
    min-width: 96px;
    margin: 0 10px 8px 0;
    white-space: nowrap;
    height: 17px;
    line-height: 17px;
    color: #222222;
  }
  &__buttons {
    display: inline-flex;
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
    font-size: 12px;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  &__value {
    display: inline-block;
    color: #273a58;
    font-weight: 700;
  }

  &-header {
    padding: 0 20px;
    margin-bottom: 10px;
    border-radius: 4px 4px 0 0;
    background-color: #273a58;
    height: 32px;
    line-height: 32px;
    .bill-top__label {
      min-width: initial;
      color: #ffffff;
    }

    .bill-top__value {
      color: #ffffff;
    }
  }

  &-content {
    padding: 0 20px 10px 20px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: calc(100% - 40px);
      height: 1px;
      bottom: 7px;
      background-color: #dcdfe6;
    }
  }

  &-footer {
    padding: 0 20px 3px 20px;
    .bill-top__label {
      color: #273a58;
    }

    .bill-top__value {
      color: #273a58;
    }
  }
}
</style>
