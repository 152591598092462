<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    @close="close"
    @open="open"
    :width="width"
  >
    <div>
      <slot name="content" />
    </div>
    <div
      slot="footer"
      :class="[
        isLeft
          ? 'dialog-footer-left'
          : isRight
          ? 'dialog-footer-right'
          : 'dialog-footer-center',
        'dialog-footer',
      ]"
    >
      <slot name="footer" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    open() {
      this.$emit('open');
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  margin-top: 1em;
  margin-bottom: 2em;
  &-center {
    justify-content: center;
  }
}
</style>
