<template lang="pug">
  .wrapper
    .filter
      .filter-title Creation Date
      .filter-input
        el-date-picker(v-model="period",
          size="small",
          type="daterange",
          range-separator="To",
          start-placeholder="Start date",
          format="yyyy-MM-dd",
          @change="changePeriod",
          end-placeholder="End date")
    .content
      el-table.reports-table(:data='reports',
        ref="reportsTable",
        @sort-change='sortTable',
        v-loading.body.lock='isLoading',
        :empty-text='noReportsMessage',
        data-element="reports-list",
        :cell-style='() => "border-bottom: 1px solid #ebeef5 !important;"')
        el-table-column(label='Report Type', prop='type', sortable)
          template(slot-scope='scope')
            span {{ scope.row.reportTemplate.title }}
        el-table-column(label='Format', prop='format', width='120', class-name='report-format', sortable)
        el-table-column(label='Date range')
          template(slot-scope='scope')
            span {{ setDateRange(scope.row.startDate, scope.row.endDate, scope.row.type) }}
        el-table-column(label='Creation Date', prop='createdAt', sortable)
          template(slot-scope='scope')
            span {{ scope.row.createdAt | datetime('LLLL d y t ZZZZ') }}
        el-table-column(label='Status', prop='status', width='150', sortable)
          template(slot-scope='scope')
            a.status-done(v-if='scope.row.status === "Done"', :href='scope.row.url', target='_blank', data-element="report-link") Download
            span(v-else, :title='scope.row.status === "Error" ? scope.row.reason : ""', :class='`status-${scope.row.status.toLowerCase()}`') {{ scope.row.status }}
    el-pagination.pagination-container(
      background,
      v-if='total > page_sizes[0]',
      layout='prev, pager, next, sizes',
      :page-size='filters.limit',
      :page-sizes='page_sizes',
      :current-page='currentPage',
      @current-change='changeOffset',
      @size-change='changeLimit',
      :total='total',
      :disabled='isLoading')
</template>

<script>
import * as R from 'ramda';
import { serializeOrderBy } from '@/lib/helpers';
import { DateTime } from 'luxon';
import { getGlobalReports } from '../../../../api/reports';

export default {
  data() {
    return {
      reports: [],
      reportFilters: {},
      period: {},
      noReportsMessage: '',
      isLoading: false,
      hasProcessingReports: false,
      checker: null,
      filters: {
        type: [],
        format: [],
        orderBy: ['createdAt.desc'],
        limit: 20,
        offset: 0,
      },
      page_sizes: [10, 20, 30, 40, 50, 100],
      total: 0,
    };
  },
  beforeMount() {
    this.loadReports();
  },
  beforeDestroy() {
    if (this.checker) {
      clearInterval(this.checker);
    }
  },
  computed: {
    currentPage() {
      return this.filters.offset / this.filters.limit + 1;
    },
  },
  watch: {
    reports() {
      if (
        (!R.isNil(this.filters.startDate) || !R.isNil(this.filters.endDate)) &&
        !this.total
      ) {
        this.noReportsMessage = 'No data found';
      } else {
        this.noReportsMessage =
          'You have no reports. Click "Create Report" to create a new one.';
      }
    },
    hasProcessingReports(value) {
      if (value) {
        this.checkProcessingReports();
      }
    },
  },
  methods: {
    async loadReports() {
      const { data, total, filters } = await getGlobalReports(this.filters);
      this.total = total;
      this.reports = data;
      this.reportFilters = filters;
      this.hasProcessingReports = R.any(
        (v) => v.status.toLowerCase() === 'processing',
      )(data);
      this.clearChecker();
    },
    changePeriod(period) {
      if (R.isNil(period)) {
        delete this.filters.startDate;
        delete this.filters.endDate;
      } else {
        const startDate = DateTime.fromJSDate(period[0])
          .setZone('UTC', { keepLocalTime: true })
          .toString();
        const endDate = DateTime.fromJSDate(period[1])
          .setZone('UTC', { keepLocalTime: true })
          .endOf('day')
          .toString();
        this.filters = { ...this.filters, startDate, endDate };
      }
      this.filters.offset = 0;
      this.loadReports();
    },
    changeLimit(limit) {
      this.filters.limit = limit;
      this.loadReports();
    },
    changeOffset(page) {
      this.filters.offset = (page - 1) * this.filters.limit;
      this.loadReports();
    },
    sortTable({ prop, order }) {
      const { path, query } = this.$route;

      if (R.isNil(prop) || R.isNil(order)) {
        this.$router.push({
          path: path,
          query: {
            ...query,
            orderBy: [],
          },
        });
      } else {
        this.$router.push({
          path: path,
          query: {
            ...query,
            orderBy: serializeOrderBy(prop, order),
          },
        });
      }
    },
    setDateRange(start, end, type) {
      if (start && end) {
        const startDate = DateTime.fromISO(start).setZone('UTC');
        const endDate = DateTime.fromISO(end).setZone('UTC');
        const diffDays =
          (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          86400000;
        if (type !== 'BILL_PROCESSING_TIME') {
          if (diffDays === 7) {
            return 'Last ' + diffDays.toString() + ' days';
          } else if (
            startDate.toFormat('LLL y') === endDate.toFormat('LLL y')
          ) {
            return startDate.toFormat('LLL y');
          } else {
            return `${startDate.toFormat('LLL y')} - ${endDate.toFormat(
              'LLL y',
            )}`;
          }
        } else {
          return `${startDate.toFormat('LLL dd, y')} - ${endDate.toFormat(
            'LLL dd, y',
          )}`;
        }
      }
      return 'All time';
    },
    checkProcessingReports() {
      if (R.isNil(this.checker)) {
        this.checker = setInterval(() => {
          this.loadReports();
          this.clearChecker();
        }, 5000);
      }
    },
    clearChecker() {
      if (!this.hasProcessingReports) {
        clearInterval(this.checker);
        this.checker = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  margin: 0 0 10px 0;
  padding: 20px 24px 24px 24px;
  font-size: 14px;
  border-radius: 8px;
  background-color: #fdfdfd;
  box-shadow: 0 2px 5px 0 rgba(204, 204, 204, 0.5);

  &-title {
    color: #606266;
    margin: 0 0 10px 0;
  }

  /deep/ .el-date-editor--daterange.el-input__inner {
    max-width: 285px;
  }
}

.status-done {
  color: #0080ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.content {
  margin-bottom: 30px;
}

/deep/ {
  .el-pagination {
    display: flex;
  }

  .el-pagination__sizes {
    margin-left: auto;
  }
}
</style>
