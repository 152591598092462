<template lang="pug">
.bill-short-info(v-if="bill" :class="{current: type === 'current'}")
  h3 {{ title }}
  .bill-short-info--wrapper
    .info
      .info__label Pear ID :
      .info__value(v-if="type === 'current'") {{ bill.id }}
      .info__value(v-else)
        u
          a(@click="changeBill()") {{ bill.id }}
    .info
      .info__label Invoice Date :
      .info__value {{ bill.statementDate | datetime('LL/dd/yy') }}
    .info
      .info__label Current Charges:
      .info__value {{ bill.currentCharges | currency }}
    .info
      .info__label Past Due:
      .info__value {{ bill.pastDueAmount | currency }}
    .info
      .info__label Total Amount Due:
      .info__value {{ bill.totalCharges | currency }}
    .info
      .info__label Due Date :
      .info__value {{ bill.dueDate | datetime('LL/dd/yy') }}

.bill-short-info.bill-short-info--no-bill(v-else)
  h3 {{ title }}
  .bill-short-info--wrapper
    span.bill-short-info-expected-next-bill-date(v-if="expectedNextBillDate")
      span.bill-short-info-expected-next-bill-date-label Expected Invoice Date: &nbsp;
      span.bill-short-info-expected-next-bill-date-exp-date {{ expectedNextBillDate | datetime('LL/dd/yyyy') }}
    span(v-else) No Bill

</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    bill: {
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    expectedNextBillDate: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      loadBillPath: 'bills/loadBillPath',
    }),
    changeBill() {
      this.loadBillPath({
        path: {
          name: 'customer.bills.info',
          params: {
            customerId: String(this.bill.customerId),
            billId: this.bill.id,
          },
          query: this.$route.query,
        },
        router: this.$router,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info__value {
  a {
    cursor: pointer;
  }
}
h3 {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 8px 0;
  padding: 0;
}

.bill-short-info {
  &--wrapper {
    padding: 10px;
    border: solid 1px #dcdfe6;
    border-radius: 4px;
  }
  &-expected-next-bill-date {
    color: #f86259;
    &-label {
      font-size: 14px;
      font-weight: 800;
      line-height: 19px;
    }
    &-exp-date {
      font-weight: 800;
      font-size: 10px;
      line-height: 14px;
    }
  }
  &--no-bill {
    display: flex;
    flex-direction: column;
    .bill-short-info--wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 200px;
      flex-grow: 1;
    }
  }

  &.current {
    .info {
      &__label {
        color: #222222;
        font-weight: 700;
      }

      &__value {
        color: #273a58;
        font-weight: 700;
      }
    }
  }

  .info {
    &__label {
      color: #666666;
      min-width: 120px;
      font-weight: 400;
    }

    &__value {
      font-weight: 400;
    }
  }
}
</style>
