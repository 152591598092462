import Reports from './Reports';

export default {
  path: 'exports',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: '<%= name %> Export',
      linkTemplate: '/customers/<%= id %>/exports',
      storeMappings: { name: 'customers/name', id: 'customers/id' },
    },
  },
  children: [
    {
      path: '',
      redirect: {
        name: 'not.found',
      },
    },
    {
      path: ':tab',
      component: Reports,
      name: 'customer.exports.tab',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
  ],
};
