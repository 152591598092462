import { prop, contains, propOr } from 'ramda';
import { parseJwt, getTimeUntilRefreshTokenMs } from '@/lib/helpers';
import { refreshToken as getNewToken } from '@/api/user';

export default {
  namespaced: true,
  state: {
    refreshTokenTask: null,
    user: {},
  },

  getters: {
    isAuth: () => !!localStorage.getItem('authToken'),
    authToken: () =>
      localStorage.getItem('authToken')
        ? `Bearer ${localStorage.getItem('authToken')}`
        : null,
    user: (state) => state.user,
    userId: (state) => prop('id', state.user),
    hasRoleOperator: (state) =>
      contains('operator', propOr([], 'roles', state.user)),
  },

  mutations: {
    setUser(state, user) {
      state.user = { ...user };
    },
    setRefreshTokenTask(state, task) {
      state.refreshTokenTask = task;
    },
    clear(state) {
      clearTimeout(state.refreshTokenTask);
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
    },
  },

  actions: {
    refreshTokenTaskSetter({ state, commit, dispatch }, { timeUntilRefresh }) {
      clearTimeout(state.refreshTokenTask);
      const refreshTokenTask = setTimeout(
        () => dispatch('refreshTokens'),
        timeUntilRefresh,
      );
      commit('setRefreshTokenTask', refreshTokenTask);
    },
    async refreshTokens({ state, commit, dispatch }) {
      if (!localStorage.getItem('refreshToken')) {
        return;
      }
      const timeUntilRefresh = getTimeUntilRefreshTokenMs();
      if (timeUntilRefresh >= 70000) {
        dispatch('refreshTokenTaskSetter', { timeUntilRefresh });
        return;
      }
      const data = await getNewToken(localStorage.getItem('refreshToken'));
      const { token, refreshToken } = data;
      localStorage.setItem('authToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      dispatch('autoRefreshToken', refreshToken);
    },
    autoRefreshToken({ state, commit, dispatch }) {
      const jwt = localStorage.getItem('authToken');
      if (!jwt) {
        return;
      }
      const { exp } = parseJwt(jwt);
      if (!jwt || (jwt && Date.now() > exp * 1000)) {
        return dispatch('logout');
      }
      const timeUntilRefresh = getTimeUntilRefreshTokenMs();
      dispatch('refreshTokenTaskSetter', { timeUntilRefresh });
    },
    login({ dispatch, commit }, { jwt, user, refresh_token }) {
      return new Promise((resolve) => {
        commit('setUser', user);
        commit('setJWT', jwt);
        commit('setRefreshToken', refresh_token);
        dispatch('autoRefreshToken');
        resolve();
      });
    },
    logout({ commit }) {
      return Promise.resolve(commit('clear'));
    },
    setUser({ commit }, user) {
      return Promise.resolve(commit('setUser', user));
    },
  },
};
