<template>
  <div>
    <div>
      <el-row class="top-container" justify="space-between">
        <el-col>
          <div @click="toggleContainerOpen">
            <span class="arrows-container">
              <i v-if="opened" class="el-icon-caret-bottom"></i>
              <i v-else class="el-icon-caret-right"></i>
            </span>
            <span class="attributes-toggle">Custom Attributes</span>
          </div>
        </el-col>
        <el-col>
          <el-row
            v-if="opened && attributes.length > 0"
            class="actions-container"
            justify="end"
          >
            <el-link
              v-if="!editMode"
              type="primary"
              icon="el-icon-edit"
              @click="enterEditMode"
            >
              Edit
            </el-link>
            <span v-else>
              <el-button
                @click="cancelEditMode"
                class="button-attribute-action"
              >
                Cancel
              </el-button>
              <el-button
                @click="saveChanges"
                type="primary"
                class="button-attribute-action"
                :disabled="saveDisabled"
              >
                Save
              </el-button>
            </span>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div v-if="opened" class="bottom-container">
      <p v-if="attributes.length === 0">N/A</p>
      <div v-else>
        <p v-for="attribute in attributes" :key="attribute.id">
          <el-row>
            <el-col :span="12" class="left-side">
              <div>{{ attribute.attributeName }}</div>
            </el-col>
            <el-col :span="6" class="right-side">
              <CustomAttribute
                :editMode="editMode"
                :attribute="attribute"
                @attribute-change="handleAttributeChange"
              />
            </el-col>
          </el-row>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomAttribute from './CustomAttribute.vue'

export default {
  components: { CustomAttribute },
  props: {
    initialAttributes: Array,
    providerVendorId: Number,
  },
  data() {
    return {
      opened: false,
      editMode: false,
      updatedAttributes: [],
    }
  },
  computed: {
    ...mapGetters({
      blocksExpanded: 'vendors/blocksExpanded',
      blocksExpandedChanged: 'vendors/blocksExpandedChanged',
    }),
    saveDisabled() {
      return this.updatedAttributes.length === 0
    },
  },
  methods: {
    ...mapActions({
      $updateCustomAttributes: 'vendors/updateCustomerVendorCustomAttributes',
    }),
    toggleContainerOpen() {
      this.editMode = false
      this.opened = !this.opened
    },
    cancelEditMode() {
      this.editMode = false
      this.updatedAttributes = []
    },
    enterEditMode() {
      this.editMode = true
      this.updatedAttributes = []
    },
    handleAttributeChange(attr) {
      // find index of element to be changed
      const index = this.updatedAttributes.findIndex(
        item => item.id === attr.id,
      )

      if (index !== -1) {
        // if the element exists, update the value
        this.updatedAttributes[index].value = attr.value
      } else {
        // otherwise, create a new attribute object and add it to the list
        this.updatedAttributes.push({
          id: attr.id,
          value: attr.value,
        })
      }
    },
    async saveChanges() {
      const parsedAttributes = this.updatedAttributes.map(att => {
        return {
          providersVendorsAttributesMetadataId: att.id,
          attributeValue: att.value,
        }
      })

      const response = await this.$updateCustomAttributes({
        customerId: this.$route.params.customerId,
        providerVendorId: this.providerVendorId,
        attributes: parsedAttributes,
      })
      this.attributes = response.data
      this.editMode = false

      if (response.status === 201) {
        this.$message({
          message: 'Custom attributes values updated successfully',
          type: 'success',
        })
      } else {
        this.$message({
          message: 'Error while updating the custom attributes',
          type: 'error',
        })
      }
    },
  },
  watch: {
    blocksExpandedChanged() {
      this.opened = this.blocksExpanded

      // exit editMode when blocks are collapsed from the action bar
      if (this.blocksExpanded === false) {
        this.editMode = false
      }
    },
  },
  mounted() {
    this.attributes = this.initialAttributes
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../Vendors/style/vendors.scss';

.top-container {
  align-items: center;
  height: 30px;

  .arrows-container {
    margin-right: 5px;
  }

  .attributes-toggle {
    font-weight: bold;
    cursor: pointer;
  }

  .actions-container {
    .button-attribute-action {
      padding: 5px 20px;
    }
  }
}

.bottom-container {
  padding-top: 20px;

  .left-side,
  .right-side {
    display: flex;
    align-items: center;
    min-height: 40px;
    height: auto;
  }
}
</style>
