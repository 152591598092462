<template lang="pug">
.tab-bill-summary
  .section.section-1
    .general-info
      .general-info__label Vendor ID :
      .general-info__value {{ getVendorIdByCode(bill.vendorCode) }} &nbsp;
      span.info__value (Provider Vendor {{ providerId }})
    .general-info
      .general-info__label Total Accounts :
      .general-info__value {{ virtualAccountQty }}
    .general-info
      .general-info__label Utilities :
      .general-info__value {{ commodities }}
    .general-info
      .general-info__label Date Loaded :
      .general-info__value {{ handleDateTimeFormat(bill.createdAt) }}
    .general-info
      .general-info__label Date Marked for Payment :
      .general-info__value(:class="(bill.customerReady && isDateNil(bill.markedForPayment) ? 'text-orange' : '')") {{ handleDateTimeFormat(bill.markedForPayment) }}
    .general-info(v-if="isLiveBill && paymentFiles.length > 0")
      .general-info__label  Date Fund Request sent by A/P file:
      .general-info__value {{ handleDateTimeFormat(createdAtPaymentFileDate) }}
    .general-info(v-if="bill.csiDetails")
      .general-info__label  Date Payment Sent to CSI:
      .general-info__value {{ handleDateTimeFormat(bill.csiDetails.transactionCreatedAt) }}
    .general-info(v-if="bill.csiDetails")
      .general-info__label  Payment Status for the Invoice:
      .general-info__value(v-if="bill.csiDetails.paymentStatus") {{ bill.csiDetails.paymentStatus | toTitleCase }}
      .general-info__value(v-else) N/A
  .section.section-2
    bill-info(title="Prior Bill" :bill="priorBill")
    bill-info(title="Current Bill" type="current"  :bill="currentBill")
    bill-info(title="Next Bill" :bill="nextBill" :expectedNextBillDate="expectedNextBillDate")
  .section.section-3
    h4 Bill Notes
    .warning(v-if="unmappedBillBlocksQty > 0")
      i.icon.icon-warning
      span There are one or more unmapped bill blocks
    .warning(v-if="isEstimated")
      i.icon.icon-warning
      span Charges for one or more accounts is estimated
    .warning(v-if="isDeposit")
      i.icon.icon-warning
      span Bill shows a deposit
  .section.section-3
    h4 A/P File
    .payment-info(v-if="paymentFiles.length === 0")
      span A/P file has not yet been generated
    .payment-info(v-else-if="!isLiveBill && paymentFiles.length > 0")
      span This is not a live bill and customers will not see or receive the generated payment file.
    .payment-info(v-else-if="isLiveBill && paymentFiles.length > 0")
      span A/P file for {{ paymentFileDate | datetime('LL/dd/yyyy') }} most recently generated {{ createdAtPaymentFileDate | datetime('LL/dd/yyyy') }}
      span.payment-info-span
        el-button.regenerate-button(type="text" @click="showRegenPaymentFileDialog = true") Regenerate payment file.
      span.payment-info-span
        el-button.regenerate-button(type="text" @click="showPaymentPopup") Download
  .section.section-3(v-if="bill.csiDetails")
    h4 Bill Payment
    .payment-info
      span(v-if="bill.csiDetails.batchCreatedAt") The bill has been included in the CSI batch on {{ bill.csiDetails.batchCreatedAt | datetime('LL/dd/yyyy') }}.
      span(v-else) N/A
  .section.section-5
    bill-history(:history='history', :customerId="parseInt(customerId)")
  el-dialog.modal-dialog.payment-download-modal(title="Download Bill Payments" :visible.sync='paymentDownloadModal' width='544px')
    payment-download-modal(:list="paymentFiles" @close="paymentDownloadModal=false")
  regen-payment-file-modal(:dialogVisible="showRegenPaymentFileDialog" :markedForPaymentDate="this.bill.markedForPayment" @close="showRegenPaymentFileDialog = false" @regen="regeneratePaymentFile")
  confirm-regen-modal(:dialogVisible="showPaymentFileDialog" :type="'Payment file'" @close="showPaymentFileDialog = false")
  confirm-regen-modal(:dialogVisible="showPaymentFileFailedDialog" :errorType="'payment file'" @close="showPaymentFileFailedDialog = false")
</template>
<script>
import * as R from 'ramda';
import { mapGetters } from 'vuex';
import BillHistory from './../BillHistory';
import ConfirmRegenModal from './SummaryModals/ConfirmRegenModal';
import RegenPaymentFileModal from './SummaryModals/RegenPaymentFileModal';
import PaymentDownloadModal from './../PaymentDownloadModal';
import BillInfo from './../BillInfo';
import { bills, payments } from '@/api';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

export default {
  components: {
    BillHistory,
    BillInfo,
    PaymentDownloadModal,
    ConfirmRegenModal,
    RegenPaymentFileModal,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      priorBill: null,
      nextBill: null,
      paymentDownloadModal: false,
      paymentFiles: [],
      showPaymentFileDialog: false,
      showRegenPaymentFileDialog: false,
      showPaymentFileFailedDialog: false,
    };
  },
  async beforeMount() {
    try {
      const { customerId, priorBillId, nextBillIds } = this.bill;
      if (priorBillId) {
        const priorBill = await bills.get(customerId, priorBillId);
        this.priorBill = this.pickBillInfo(priorBill);
      }
      if (nextBillIds && nextBillIds[0]) {
        const nextBill = await bills.get(customerId, nextBillIds[0]);
        this.nextBill = this.pickBillInfo(nextBill);
      }
      await this.getPaymentFiles();
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapGetters({
      bill: 'bills/bill',
      customerId: 'customers/id',
      getVendorIdByCode: 'resources/getVendorIdByCode',
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
      history: 'bills/history',
      entitlements: 'customers/entitlements',
    }),
    expectedNextBillDate() {
      return this.currentBill && this.currentBill.expectedNextBillDate;
    },
    next_bill_ids() {
      return R.uniq(R.propOr([], 'nextBillIds', this.bill));
    },
    virtualAccountQty() {
      const arr = R.pipe(R.map(R.prop('virtualAccountId')), R.uniq)(this.items);
      return arr.length;
    },
    locationQty() {
      const arr = R.pipe(R.map(R.prop('locationId')), R.uniq)(this.items);
      return arr.length;
    },
    unmappedBillBlocksQty() {
      return R.reduce(
        (acc, item) => (!R.prop('locationId', item) ? acc + 1 : acc),
        0,
      )(this.items);
    },
    currentBill() {
      return this.pickBillInfo(this.bill);
    },
    isEstimated() {
      return this.bill && this.bill.payload && this.bill.payload.estimated;
    },
    providerId() {
      if (
        this.bill.payload &&
        this.bill.payload.fdg &&
        this.bill.payload.fdg.vendorId
      ) {
        return this.bill.payload.fdg.vendorId;
      }
      return 'not specified';
    },
    isDeposit() {
      return R.any(
        R.pipe(
          R.prop('billingLineItems'),
          R.any(R.propEq('code', 'OTH_DEPOSIT')),
        ),
      )(this.items);
    },
    commodities() {
      const list = R.uniq(
        (this.items || []).map((e) => e.commodity || ''),
      ).filter((e) => e.length > 0);
      const order = ['ELECTRIC', 'NATURALGAS'];
      const orderNum = (commodity) => {
        const index = order.findIndex((c) => c === commodity);
        return index === -1 ? commodity : index;
      };
      const commodities = R.sortBy(orderNum)(list);
      const countCommodity = (c) =>
        R.reduce(
          (acc, val) => {
            return R.propEq('commodity', c, val) ? acc + 1 : acc;
          },
          0,
          this.items,
        );

      return R.map((v) => {
        const count = countCommodity(v);
        return `${v} (${count})`;
      })(commodities).join(', ');
    },
    isLiveBill() {
      return R.propOr('', 'billType', this.bill).toLowerCase() === 'live';
    },
    createdAtPaymentFileDate() {
      return R.pipe(R.head, R.prop('createdAt'))(this.paymentFiles);
    },
    paymentFileDate() {
      return R.pipe(R.head, R.prop('paymentFileDate'))(this.paymentFiles);
    },
  },
  methods: {
    pathOr: R.pathOr,
    prop: R.prop,
    pickBillInfo(bill) {
      return R.pick([
        'id',
        'currentCharges',
        'statementDate',
        'pastDueAmount',
        'totalCharges',
        'dueDate',
        'customerId',
        'expectedNextBillDate',
      ])(bill);
    },
    async showPaymentPopup() {
      this.paymentDownloadModal = true;
      await this.getPaymentFiles();
    },
    async getPaymentFiles() {
      if (!this.entitlements.payments) {
        return;
      }
      try {
        const files = await payments.getPaymentFileByBillId({
          customerId: this.customerId,
          billId: this.bill.id,
        });
        this.paymentFiles = files || [];
      } catch (e) {
        console.log(`can't get Payment Files`);
      }
    },
    handleDateTimeFormat(date) {
      if (this.isDateNil(date)) {
        if (this.bill.customerReady) {
          return 'To Be Added';
        }
        return 'N/A';
      }
      return DateTime.fromISO(date, { zone: 'utc' }).toFormat('M/dd/yy t');
    },
    isDateNil(date) {
      return _.isNil(date);
    },
    async regeneratePaymentFile() {
      try {
        const markedForPaymentDate = DateTime.fromISO(
          this.bill.markedForPayment,
        ).toFormat('yyyy-MM-dd');
        await payments.postRegenPaymentFile(
          this.bill.customerId,
          markedForPaymentDate,
        );
        await this.getPaymentFiles();
        this.showRegenPaymentFileDialog = false;
        setTimeout(() => {
          this.showPaymentFileDialog = true;
        }, 1000);
      } catch (e) {
        this.showRegenPaymentFileDialog = false;
        setTimeout(() => {
          this.showPaymentFileFailedDialog = true;
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss">
.bill-tabs-container {
  padding: 10px 0 20px 0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;

  .section {
    margin: 0 0 30px 0;

    &-2 {
      margin: 0 0 40px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .bill-short-info + .bill-short-info {
        margin-left: 42px;
      }
    }
  }
  .warning {
    display: flex;
    align-items: baseline;
    margin: 0 0 8px 0;
    font-size: 12px;
    line-height: 16px;

    .icon {
      margin: 0 12px 0 0;
    }
  }

  .payment-info {
    font-size: 12px;
    color: #222222;

    &--download {
      display: inline-block;
      margin-left: 7px;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
      color: #4a90e2;
    }
    &-span {
      margin-left: 16px;
      .regenerate-button {
        font-size: 12px;
      }
    }
  }

  .bill-filters-common-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 0 0;
    text-align: right;

    .icon {
      margin: 0 7px;
    }

    .el-button {
      margin-left: 24px;
      text-decoration: none;
      color: #273a58;

      &.active,
      &:hover {
        color: #267de2;

        .icon {
          background: url(../../../../../../assets/icons/filters1_active.svg)
            center center no-repeat;
        }
      }
    }

    .bill-filters--info {
      flex-grow: 1;
      padding-left: 20px;
      text-align: left;
      font-size: 12px;
      color: #666666;
    }
  }

  .bill-block {
    margin-bottom: 10px;

    .collapse {
      box-shadow: 0 2px 5px 0px rgba(225, 223, 223, 0.65);

      .icon {
        padding-left: 8px;
        background-size: contain;
        width: 8px;
      }

      .header {
        //background-color: #EDF5FF;
        padding: 7px 20px;

        .icon-arrow {
          background-image: url(../../../../../../assets/icons/arrow-bottom.svg);

          &.back {
            transform: rotate(270deg);
          }
        }

        .bill-collapse-block {
          flex-basis: 33%;

          &-title {
            //color: #606266;
          }

          &-value {
            //color: #273A58;
          }
        }
      }

      .content {
        padding: 10px 0;

        .bill-title {
          padding-left: 20px;
          color: #273a58;
        }

        .bill-field {
          &-container {
            padding-left: 10px;
          }

          &-key {
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
          }

          &-value {
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            color: #303133;

            a {
              color: #409eff;
            }
          }
        }

        .irregular-data {
          margin-top: 10px;

          .header {
            padding: 7px 0;
            color: #273a58;
            background-color: transparent;

            &-title {
              width: auto;
              padding-right: 8px;
              order: -1;
            }
          }

          &-item > div {
            padding-left: 10px;
          }

          &-title {
            color: #273a58;
          }

          &-row-key {
            padding-left: 10px;
            font-size: 12px;
            font-weight: 400;
          }

          &-row-value {
            font-size: 12px;
            color: #303133;
            text-align: right;
            padding-right: 20px;

            &.currency-value {
              color: #2893f9;
            }

            &.description-value {
              text-align: left;
              color: #838ea5;
            }
          }
        }
      }
    }
  }

  .el-tabs__item {
    &:hover {
      color: #273a58;
    }

    &.is-active {
      font-weight: 600;
      color: #273a58;
    }
  }

  .el-tabs__active-bar {
    background-color: #273a58;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  h4 {
    margin: 26px 0 10px 0;
  }

  .general-info {
    display: flex;
    flex-direction: row;
    font-size: 12px;

    + .info {
      padding-top: 10px;
    }

    &__label {
      padding-right: 7px;
      min-width: 210px;
      color: #222222;
    }

    &__value {
      color: #273a58;
      font-weight: 700;

      &.text-orange {
        color: #e6a23b;
      }
    }
  }

  a {
    color: #409eff;
  }

  .bills-navigation {
    a {
      font-weight: 600;
    }
  }

  .el-tabs__header {
    margin: 0 20px 15px 20px;
  }

  .tab {
    &-bill-summary {
      padding: 10px 20px;
    }

    &-bill-block {
    }

    &-remittance-info {
      padding: 10px 20px;

      h3 {
        font-size: 14px;
      }

      .info {
        &__label {
          min-width: 130px;
        }
      }
    }
  }
}
</style>
