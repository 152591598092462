import * as R from 'ramda';
import * as dot from 'dot-object';
import { http, dataMiddleware, ISODateFormat } from '@/lib';
import { hosts } from '@/lib/api.configs';
import store from '../store';
import qs from 'qs';

/**
 * @private
 * @param {*} bill
 */
const convertDate = (bill) => ({
  ...bill,
  dueDateFormatted: ISODateFormat(bill.due_date),
  statementDataFormatted: ISODateFormat(bill.statement_date),
});

/**
 * @private
 * @param {*} bill
 */
const setBillCommodity = (bill) => ({
  ...bill,
  commodity: R.uniq((bill.items || []).map((e) => e.commodity || ''))
    .filter((e) => e.length > 0)
    .join(', '),
});

const parseBillCharges = (bill) => {
  const billingLineItem = (description) =>
    bill.billingLineItems.filter((lineItem) => {
      return lineItem.description === description;
    })[0];

  const currentChargesLineItem = billingLineItem('Current Charges') || {};
  const totalChargesLineItem = billingLineItem('Total Amount Due') || {};
  const pastDueAmountLineItem = billingLineItem('Past Due Amount') || {};

  const getLineItemValue = (key, lineItem, defaultTo = 0) =>
    lineItem[key] || defaultTo;

  const currency = getLineItemValue('currency', currentChargesLineItem, null);
  const nativeCurrency = getLineItemValue(
    'nativeCurrency',
    currentChargesLineItem,
    null,
  );

  return {
    ...bill,
    nativeCurrency,
    currency,
    currentCharges: getLineItemValue('charge', currentChargesLineItem),
    currentChargesNativeCharge: getLineItemValue(
      'nativeCharge',
      currentChargesLineItem,
    ),
    totalCharges: getLineItemValue('charge', totalChargesLineItem),
    totalChargesNativeCharge: getLineItemValue(
      'nativeCharge',
      totalChargesLineItem,
    ),
    pastDueAmount: getLineItemValue('charge', pastDueAmountLineItem),
    pastDueAmountNativeCharge: getLineItemValue(
      'nativeCharge',
      pastDueAmountLineItem,
    ),
  };
};

const compareCalculateField = (field) => {
  const data = store.getters['resources/getResourceByName']('calculations');
  const calculations = R.propOr([], 'value', data);
  const fields = calculations.map((f) => ({ ...f, label: f.description }));
  return { ...field, ...fields.find((e) => e.name === field.name) };
};

const compareWeatherCalculateField = (field) => {
  const weatherData = store.getters['resources/getResourceByName'](
    'weather_calculations',
  );
  const weatherCalculations = R.propOr([], 'value', weatherData);
  const weatherFields = weatherCalculations.map((f) => ({
    ...f,
    label: f.description,
  }));
  return { ...field, ...weatherFields.find((e) => e.name === field.name) };
};

const prepareCalculationsFields = (bill) => ({
  ...bill,
  items: [
    ...(bill.items || []).map((e) => ({
      ...e,
      calculations: R.groupBy((e) => e.type || 'regular')([
        ...(e.calculations || [])
          .filter((i) => i.name.indexOf('total_consumption') !== 0)
          .filter((i) => i.name.indexOf('daily_consumption') !== 0)
          .map(compareCalculateField),
      ]),
      weatherCalculations: R.groupBy((e) => e.type || 'weather')([
        ...(e.weatherCalculations || []).map(compareWeatherCalculateField),
      ]),
    })),
  ],
});

const preparePayload = (bill) => ({
  ...bill,
  ...(bill.payload && { payload: { ...dot.object(bill.payload) } }),
});

/**
 * @private
 */
const reduceBill = R.compose(
  parseBillCharges,
  setBillCommodity,
  convertDate,
  prepareCalculationsFields,
  preparePayload,
);
/**
 * @public
 * @description method allows to get bill by it's id
 * @param customerId
 * @param billId
 */
const get = (customerId, billId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}`)
    .then(dataMiddleware)
    .then(reduceBill);

/**
 * @public
 * @description method allows to put bill by it's id
 * @param customer
 * @param bill
 * @param body
 */
const put = (customer, bill, body) =>
  http(hosts.v1)
    .put(`/customers/${customer}/bills/${bill}`, body)
    .then(dataMiddleware);

/**
 * @public
 * @description method allows to put bill by it's id
 * @param customer
 * @param bill
 * @param body
 */
const putV3 = (customer, bill, body) =>
  http(hosts.v3)
    .patch(`/customers/${customer}/bills/${bill}`, body)
    .then(dataMiddleware);

/**
 * @description allows to get bills list of customer by customer's id
 * @param {number} id identity key of customer
 * @param {Object} params {
 *  offset,
 *  limit,
 *  commodities,
 *  vendor_codes,
 *  total_charge_max,
 *  total_charge_min,
 *  statement_date_min,
 *  statement_date_max,
 *  orders
 * }
 */
// refactor this, after fixing backend calculation of total bills
const list = (id, params) => {
  if (params.amount_due_min && params.amount_due_max) {
    params = {
      ...params,
      amount_due_min: --params.amount_due_min,
      amount_due_max: ++params.amount_due_max,
    };
  }

  return http(hosts.v1)
    .get(
      `/customers/${id}/bills?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    )
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }));
};

/**
 * @description allows to get data for filters
 */
const getFiltersData = (customerId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/bills-filters`)
    .then(dataMiddleware);

const reparse = ({ customerId, billId }) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/bills/${billId}/reparse`)
    .then(dataMiddleware);

const changeWorkflowState = (customerId, billId, workflowState) =>
  http(hosts.v1)
    .patch(`customers/${customerId}/bills/${billId}/workflow-state`, {
      state: workflowState,
    })
    .then(dataMiddleware);

/**
 * @function
 * @memberOf bills
 * */
const getComments = (customerId, billId) => {
  return http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}/activity-history`)
    .then(dataMiddleware);
};

/**
 * @public
 * @description method allows to get bill pdf download link by it's id
 * @param {number} customerId id identity key of the customer
 * @param {number} billId id identity key of bill
 */
const getPdfDownloadLink = ({ customerId, billId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}/pdf`)
    .then(dataMiddleware);

/**
 * @public
 * @description method allows to get bill json download link by it's id
 * @param {number} customerId id identity key of the customer
 * @param {number} billId id identity key of bill
 */
const getJsonDownloadLink = ({ customerId, billId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}/json`)
    .then(dataMiddleware);

/**
 * @function
 * @memberOf bills
 * */
const createComment = (customerId, billId, message, chatTags) => {
  return http(hosts.v3)
    .post(`/customers/${customerId}/bills/${billId}/comments`, {
      message,
      chatTags,
    })
    .then(dataMiddleware);
};

/**
 * @function
 * @memberOf bills
 * */
const getBillCsv = (customerId, billId) => {
  return http(hosts.v1)
    .get(`/customers/${customerId}/bills/${billId}/csv`)
    .then(dataMiddleware);
};
/**
 * @function
 * @memberOf bills
 * */
const getBillObservation = (customerId, billId) => {
  return http(hosts.v1)
    .get(`/customers/${customerId}/bills/${billId}/observations`)
    .then(dataMiddleware);
};
/**
 * @function
 * @memberOf bills
 * */
const postChangedBillCsv = (customerId, billId, changedCsv) => {
  return http(hosts.v1)
    .put(`/customers/${customerId}/bills/${billId}/csv`, changedCsv)
    .then(dataMiddleware);
};

const deleteBill = (customerId, billId) => {
  return http(hosts.v3)
    .delete(`/customers/${customerId}/bills/${billId}`)
    .then(dataMiddleware);
};

const deleteUnprocessedBill = (customerId, recordId) => {
  return http(hosts.v1)
    .delete(`/customers/${customerId}/unprocessed-bills/${recordId}`)
    .then(dataMiddleware);
};

const processBillEvent = (eventId) => {
  return http(hosts.v1).get(`/events/${eventId}`).then(dataMiddleware);
};

const resolveErrors = (data) => {
  return http(hosts.v3)
    .post(`/bills/bulk-resolve-errors`, data)
    .then(dataMiddleware);
};
const unresolveErrors = (data) => {
  return http(hosts.v3)
    .post(`/bills/bulk-unresolve-errors`, data)
    .then(dataMiddleware);
};
const listHistory = (customer_id, bill_id) => {
  return http(hosts.v1)
    .get(`/customers/${customer_id}/bills/${bill_id}/bill-history`)
    .then(dataMiddleware);
};

const getChainsForBill = (customerId, clientAccount, billId) => {
  return http(hosts.v1)
    .get(
      `/customers/${customerId}/client-account/${clientAccount}/chains/bill-id/${billId}`,
    )
    .then(dataMiddleware);
};

const getExchangeRates = (customerId, billId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}/exchange-rate`)
    .then(dataMiddleware);

const getBillChain = (
  customerId,
  billChainId,
  { limit, offset, start_date, end_date },
) => {
  return http(hosts.v1)
    .get(`/customers/${customerId}/bill-chains/${billChainId}`, {
      params: { limit, offset, start_date, end_date },
    })
    .then(dataMiddleware);
};

const getEventsBatchStatus = (trackingId) => {
  return http(hosts.v3)
    .get(`/batches/batch-status/${trackingId}`)
    .then(dataMiddleware);
};

const getBillCustomerId = (bill_id) => {
  return http(hosts.v1)
    .get(`/bills/${bill_id}/customer-id`)
    .then(dataMiddleware);
};

const getBillErrorReasons = () => {
  return http(hosts.v3)
    .get(`/bills/error-reasonings?limit=1000`)
    .then(dataMiddleware);
};

const updateBillErrorReasoning = (body) => {
  return http(hosts.v3)
    .post(`/bills/update-error-reasoning`, body)
    .then(dataMiddleware);
};

const billValidationDefinitions = () =>
  http(hosts.v3)
    .get('resources/bill-validation-definitions?limit=100')
    .then(dataMiddleware);

const billOperatorNotes = (customerId, virtualAccountId) =>
  http(hosts.v3)
    .get(
      `customers/${customerId}/virtual-account-metadata-attributes/${virtualAccountId}/operator-notes`,
    )
    .then(dataMiddleware);

export {
  listHistory,
  createComment,
  get,
  put,
  putV3,
  getComments,
  list,
  getFiltersData,
  reparse,
  changeWorkflowState,
  getBillCsv,
  postChangedBillCsv,
  deleteBill,
  getPdfDownloadLink,
  getJsonDownloadLink,
  deleteUnprocessedBill,
  processBillEvent,
  resolveErrors,
  unresolveErrors,
  getBillObservation,
  getChainsForBill,
  getBillChain,
  getEventsBatchStatus,
  getBillCustomerId,
  getExchangeRates,
  billOperatorNotes,
  getBillErrorReasons,
  updateBillErrorReasoning,
  billValidationDefinitions,
};
