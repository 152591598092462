<template>
  <el-dialog
    :visible="dialogVisible"
    @close="close"
    width="25%"
    title="Regenerate Payment File"
  >
    <div class="content">
      Are you sure you would like to regenerate the payment file for
      {{ markedForPaymentDate | datetime('LL/dd/yyyy') }}? Payment files for all
      formats for this date will be regenerated.
    </div>
    <div slot="footer" class="center">
      <el-button class="button-width" @click="close">Cancel</el-button>
      <el-button
        type="primary"
        :key="buttonKey"
        class="button-width"
        @click.once="regen"
        >Regenerate</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    markedForPaymentDate: {
      type: String,
      default: () => '',
      required: true,
    },
  },
  data() {
    return {
      buttonKey: 0,
    };
  },
  methods: {
    close() {
      this.buttonKey++;
      this.$emit('close');
    },
    regen() {
      this.$emit('regen');
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}
.content {
  word-break: normal;
}
</style>
