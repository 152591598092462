<template lang="pug">
.history-container
  .history-header
    h3 Bill Submission History
    span * — Current Bill version
  .history-table
    el-table(:data="historyFormatted" empty-text=' ')
      el-table-column(label='#' type="index"  width="50")
      el-table-column(label='Date Processed' width="150" prop='created_at')
        template(slot-scope='scope')
          span.processed-date {{ dateFormat(scope.row) }}
      el-table-column(label='Bill Batch ID' width="150")
        template(slot-scope='scope')
          span(v-if="scope.row.type === 'internal' && scope.row.batch_id !== 'Manual Update'") Bill Editor
          span(v-else) {{ scope.row.batch_id || 'N/A' }}
      el-table-column(label='Download Processed File', width="190px")
        template(slot-scope='scope')
          el-button.el-button.el-button--text(v-if="scope.row.batch_id === 'Manual Update'")
          el-button.el-button.el-button--text(v-else-if="scope.row.down_file_id", @click="getProcessedFileUrl(scope.row.down_file_id)") Download
          span(v-else) N/A
</template>

<script>
import { batches } from '@/api';
import { DateTime } from 'luxon';
import _ from 'lodash';

export default {
  props: {
    history: {
      type: Array,
      default: () => [],
    },
    customerId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    historyFormatted() {
      let history = _.cloneDeep(this.history);
      // sort by ascending
      const sorted = _.sortBy(history, [
        function (row) {
          return DateTime.fromISO(row.created_at).valueOf();
        },
      ]);
      const billBatchIds = [];
      sorted.forEach((row, i, arr) => {
        const { batch_id, down_file_id } = row;
        if (!_.isEmpty(batch_id) && !_.isEmpty(down_file_id)) {
          if (billBatchIds.includes(batch_id)) {
            arr[i].batch_id = 'Manual Update';
          } else {
            billBatchIds.push(batch_id);
          }
        }
      });
      return sorted.reverse();
    },
  },
  methods: {
    dateFormat(row) {
      return `${DateTime.fromISO(row.created_at).toFormat('LL/dd/yyyy')} ${
        row.status === 'active' ? '*' : ''
      }`;
    },
    async getProcessedFileUrl(file_id) {
      const processedFileUrl = await batches.getProcessedFileUrl(
        this.customerId,
        file_id,
      );
      window.open(processedFileUrl.link, '_self');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../../../../styles/constants';

.history {
  &-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    max-width: 540px;
    color: #273a58;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }

  &-table {
    .processed-date {
      color: #606266;
      font-weight: 900;
      line-height: 20px;
    }
  }
}

h3 {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  color: #273a58;
  margin: 0;
  padding: 0;
}
</style>

<style lang="scss">
.history-container {
  .el-table::before {
    background-color: transparent;
  }

  .el-table__header-wrapper {
    .cell {
      color: #909399;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  .el-table__body-wrapper {
    td:first-child {
      color: #666666;
      font-weight: 700;
    }

    .cell {
      color: #222222;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
}
</style>
