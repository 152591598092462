<template>
  <div>
    <upload-modal
      :visible="isUploadModalVisible"
      title="Upload Attributes File"
      file-type="attributes"
      label-text="CSV Attributes File"
      button-text="Add Attributes"
      @success="handleSuccessUpload"
      @close="close"
    >
      <p slot="modal-description">
        Upload a CSV file containing Attributes for <b>{{ customerName }}</b
        >. It is important that the CSV Attributes file conform to the naming
        conventions specified to prevent errors from occurring.
      </p>
    </upload-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadModal from '../Modals/UploadModal.vue';

export default {
  components: {
    UploadModal,
  },
  data() {
    return {
      isUploadModalVisible: false,
      activeTab: 'locationAttributes',
    };
  },
  computed: {
    ...mapGetters({
      customerName: 'customers/name',
      customerId: 'customers/id',
      portingProps: 'porting/props',
    }),
  },
  mounted() {
    this.$store.commit(
      'porting/setProps',
      Object.assign(this.portingProps, {
        openLocationsMetadataLoadCsvModal: this.openLoadCsvModal,
      }),
    );
  },
  methods: {
    async handleSuccessUpload() {
      this.$message({
        type: 'success',
        message: 'The CSV Attribute File was uploaded successfully.',
      });
    },
    openLoadCsvModal() {
      this.isUploadModalVisible = true;
    },
    close() {
      this.isUploadModalVisible = false;
    },
  },
};
</script>
