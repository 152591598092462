export default {
  path: '/users',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    showBreadcrumbs: true,
    breadcrumb: {
      titleTemplate: 'Users',
      linkTemplate: '/users',
    },
  },
  children: [
    {
      path: '',
      name: 'users',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
    {
      path: ':userId',
      component: { template: '<div></div>' },
      name: 'user.info',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
  ],
};
