<template lang="pug">
  .delete-modal_container
    template
      .delete-modal_content
        p {{ groupsForDelete ? groupsForDelete + 1 : 1 }} location groups will be <b>deleted.</b>
        p <b> {{ locationsForDelete }} locations </b> from this hierarchy will become unassigned.
        p.danger This action cannot be undone.
      .delete-modal_actions
        el-button.plain-button-reverse(@click='confirmCancel') Cancel
        el-button(type='primary', @click='confirmDelete') Remove
</template>

<script>
export default {
  props: {
    locationsForDelete: {
      type: Number,
      required: true,
      default: () => 0,
    },
    groupsForDelete: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  methods: {
    confirmDelete() {
      this.$emit('confirmDelete');
    },
    confirmCancel() {
      this.$emit('confirmCancel');
    },
  },
};
</script>
