<template>
  <collapse-block
    :data-item-id="item.id"
    data-item="data-item"
    :isCollapsed="isCollapsed"
  >
    <div class="bill-collapse" slot="title">
      <div class="bill-collapse-col">
        <span class="bill-collapse-block-title">Service Account</span>
        <span class="bill-collapse-block-value">{{ item['ACCOUNTCODE'] }}</span>
        <span class="bill-collapse-block-title">Meter ID</span>
        <span class="bill-collapse-block-value">{{ item['METERSERIAL'] }}</span>
        <span class="bill-collapse-block-title">Utility</span>
        <span class="bill-collapse-block-value">{{ item['COMMODITY'] }}</span>
      </div>
      <div class="bill-collapse-col col-freeze">
        <freeze-virtual-account
          title="Freeze"
          :freezeReasons="freezeReasons"
          :vaFrozenReason="vaFrozenReason"
          :vaFrozen="vaFrozen"
          :disabled="disabled"
          @freezeVAChanged="freezeVAChanged"
        ></freeze-virtual-account>
      </div>
      <div class="bill-collapse-col col-remove">
        <div class="button-remove" @click.stop="removeItem">Remove</div>
      </div>
    </div>
    <div class="item-content-wrapper">
      <div class="bill-collapse-content" data-section="general-info">
        <div class="bill-collapse-content-data">
          <el-row>
            <el-col :span="24">
              <label class="bill-title">General Information</label>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Service Account</label>
              <el-input v-model="item['ACCOUNTCODE']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Service Start Date</label>
              <el-date-picker
                v-model="startDate"
                format="MMM dd, yyyy"
                type="date"
                size="mini"
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Service Address</label>
              <el-input v-model="item['SERVICEADDRESS']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Service End Date</label>
              <el-date-picker
                v-model="endDate"
                format="MMM dd, yyyy"
                type="date"
                size="mini"
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Service Zip</label>
              <el-input
                v-model="item['SERVICEADDRESSZIP']"
                size="mini"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <label>Days of Service</label>
              <el-input
                :value="item['DAYSOFSERVICE']"
                size="mini"
                readonly="readonly"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <label class="service-date-method"></label>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Meter ID</label>
              <el-input v-model="item['METERSERIAL']" size="mini"></el-input>
            </el-col>
            <el-col :span="6">
              <label>Service Date Method</label>
              <el-select v-model="serviceDateMethod" size="mini">
                <el-option
                  v-for="item in ['E-S', 'E-S+1']"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Vendor Type</label>
              <bill-editor-select
                :options="billTypesList"
                :selected="item['BILLTYPE']"
                :onSelectChange="onBillTypeChange"
              ></bill-editor-select>
            </el-col>
            <el-col :span="6">
              <label>Rate Plan</label>
              <el-input v-model="item['RATECODE']" size="mini"></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Vendor code</label>
              <el-input v-model="item['VENDORCODE']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Utility Type</label>
              <bill-editor-select
                :options="commoditiesList"
                itemValue="title"
                itemLabel="title"
                :selected="item['COMMODITY']"
                :onSelectChange="changeUtility"
              ></bill-editor-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Control code</label>
              <el-input v-model="item['CONTROLCODE']" size="mini"></el-input>
            </el-col>
          </el-row>
        </div>
        <observations-table
          @onChange="pushNewObservations"
          :nativeCurrency="nativeCurrency"
          :block="block"
          :data="observations"
          :loading="loading"
          :observationList="observationList"
          :uomsList="uomsList"
        ></observations-table>
      </div>
    </div>
    <collapse-block title="Additional Information" :open="false">
      <observations-table
        @onChange="pushNewHiddenObservations"
        :block="block"
        :hidden="true"
        :data="hiddenObservations"
        :observationList="observationNonBillingListFiltered"
        :uomsList="uomsList"
      ></observations-table>
    </collapse-block>
  </collapse-block>
</template>

<script>
import { CollapseBlock } from '@/components'
import { ISODateFormat } from '@/lib'
import MatchModal from '../../Exceptions/Unmatched/MatchModal'
import BillEditorSelect from './Select'
import _ from 'lodash'
import { DateTime } from 'luxon'
import * as R from 'ramda'
import {
  addDefaultBillBlockObservations,
  isDefaultBillBlockData,
  isDefaultBillData,
} from './defaults'
import ObservationsTable from './Observations'
import FreezeVirtualAccount from './FreezeVirtualAccount'

export default {
  components: {
    FreezeVirtualAccount,
    CollapseBlock,
    MatchModal,
    BillEditorSelect,
    ObservationsTable,
  },
  beforeMount() {
    this.block = this.itemObservations[0].block
    const observations = addDefaultBillBlockObservations(
      this.block,
      this.itemObservations,
    )
    this.item = this.itemLevel(observations)
    const days = this.getDaysOfService()
    if (!isNaN(days) && this.item['DAYSOFSERVICE'] !== String(days)) {
      this.serviceDateMethod = 'E-S+1'
    }
  },
  props: {
    itemObservations: {
      type: Array,
      default: () => [],
    },
    isCollapsed: {
      type: Object,
      default: () => {},
    },
    observationList: {
      type: Array,
      default: () => [],
    },
    observationNonBillingList: {
      type: Array,
      default: () => [],
    },
    vendorTypes: {
      type: Array,
      default: () => [],
    },
    uomsList: {
      type: Array,
      default: () => [],
    },
    _initializing: {
      type: Boolean,
      default: () => true,
    },
    commoditiesList: {
      type: Array,
      default: () => [],
    },
    nativeCurrency: {
      type: String,
      default: () => undefined,
    },
    billTypesList: {
      type: Array,
      default: () => [],
    },
    freezeReasons: {
      type: Array,
      default: () => [],
    },
    vaFrozenReason: {
      type: Number,
    },
    vaFrozen: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      locations: [],
      observations: [],
      newObservations: [],
      hiddenObservations: [],
      newHiddenObservations: [],
      serviceDateMethod: 'E-S',
      block: null,
      item: {},
      initializing: this._initializing !== false,
      loading: true,
    }
  },
  computed: {
    startDate: {
      get() {
        return this.item['STARTDATE']
          ? ISODateFormat(this.item['STARTDATE'])
          : null
      },
      set(value) {
        this.item['STARTDATE'] = value
          ? DateTime.fromJSDate(value)
              .setZone('UTC', { keepLocalTime: true })
              .toFormat('yyyyLLdd')
              .toString()
          : ''
        this.updateDaysOfService()
      },
    },
    endDate: {
      get() {
        return this.item['ENDDATE'] ? ISODateFormat(this.item['ENDDATE']) : null
      },
      set(value) {
        this.item['ENDDATE'] = value
          ? DateTime.fromJSDate(value)
              .setZone('UTC', { keepLocalTime: true })
              .toFormat('yyyyLLdd')
              .toString()
          : ''
        this.updateDaysOfService()
      },
    },
    observationNonBillingListFiltered() {
      return R.reject(R.either(isDefaultBillData, isDefaultBillBlockData))(
        this.observationNonBillingList,
      )
    },
  },
  watch: {
    observationList: {
      immediate: true,
      handler(value) {
        if (!_.isEmpty(value)) {
          this.createObservationsTable()
          this.createHiddenObservationsTable()
        }
      },
    },

    item: {
      deep: true,
      handler: function() {
        this.pushUpdates()
      },
    },
    serviceDateMethod() {
      this.updateDaysOfService()
    },
  },
  methods: {
    pushNewObservations(updates) {
      this.newObservations = updates
      this.$emit('onChange', this.mergeObservations())
    },
    pushNewHiddenObservations(updates) {
      this.newHiddenObservations = updates
      this.$emit('onChange', this.mergeObservations())
      if (this.initializing) {
        this.initializing = false
        this.$emit('onInitialized')
      }
    },
    pushUpdates() {
      this.$emit('onChange', this.mergeObservations())
    },
    onBillTypeChange(type) {
      this.item['BILLTYPE'] = type
    },
    removeItem() {
      this.$emit('onDeleteItem')
    },
    updateDaysOfService() {
      const days = this.getDaysOfService()
      if (isNaN(days)) {
        this.item['DAYSOFSERVICE'] = ''
      } else {
        this.item['DAYSOFSERVICE'] =
          this.serviceDateMethod === 'E-S' ? days : days + 1
      }
    },
    getDaysOfService() {
      const startDate = DateTime.fromISO(this.item['STARTDATE'])
      const endDate = DateTime.fromISO(this.item['ENDDATE'])
      return Math.round(endDate.diff(startDate, 'days').days)
    },
    itemLevel(observations = []) {
      const codeValue = obs => [R.prop('code', obs), R.prop('value', obs)]
      return R.pipe(
        R.reject(R.both(R.has('type'), R.pipe(isDefaultBillBlockData, R.not))),
        R.map(codeValue),
        R.fromPairs,
      )(observations)
    },
    createObservationsTable() {
      const observations = R.filter(R.has('type'))(this.itemObservations)
      this.observations = R.map(row => ({ currency: null, ...row }))(
        observations,
      )
      this.newObservations = this.observations
    },
    createHiddenObservationsTable() {
      this.hiddenObservations = R.reject(
        R.either(isDefaultBillBlockData, R.has('type')),
      )(this.itemObservations)
      this.newHiddenObservations = this.hiddenObservations
    },
    changeUtility(utility) {
      this.item['COMMODITY'] = utility
    },
    updatedItemLevelObservations(newValues = {}) {
      return (
        this.itemObservations
          .filter(R.both(R.pipe(R.has('type'), R.not), isDefaultBillBlockData))
          .map(observation => {
            const defaultValue = R.pathOr('', 'value', observation)
            observation.value = String(
              R.pathOr(defaultValue, [observation.code], newValues),
            )
            return observation
          }) || []
      )
    },
    mergeObservations() {
      const itemLevel = this.updatedItemLevelObservations(this.item)
      const observation = this.newObservations
      const hiddenObservation = this.newHiddenObservations
      return [itemLevel, hiddenObservation, observation].reduce(R.concat)
    },
    freezeVAChanged(event) {
      this.$emit('freezeVAChanged', event)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/constants.scss';

.table-observation-wrapper {
  overflow-y: auto;
}

.table-observation {
  /*display: flex;*/
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  overflow: initial;
  min-width: 1420px;

  /deep/ {
    .cell {
      overflow: initial;
    }

    .el-table__body-wrapper {
      overflow: initial;
    }
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  margin: 18px 0 18px 30px;

  .button-add-observation {
    cursor: pointer;
    color: #2893f9;
    font-size: 14px;
    text-decoration: underline;
  }

  .total {
    color: #606266;
    font-size: 16px;
    font-weight: bold;
    line-height: 14px;
  }
}

.item-content-wrapper {
  padding: 5px 0 25px;

  .service-date-method {
    font-weight: 700;
    line-height: 28px;
  }
}

.bill-collapse-content-data {
  padding: 15px 0 25px 0;
}

.button-remove {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #ff0000;
  cursor: pointer;
}

.bill-collapse {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;

  &-block {
    &-title {
      font-weight: 500;
    }

    &-value {
      font-weight: 600;
      padding-left: 12px;
      padding-right: 30px;
    }
  }

  &-col {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-wrap: wrap;

    &.col-remove {
      text-align: right;
      width: 60px;
      flex-basis: 60px;
      flex-grow: 0;
      flex-shrink: 0;
      display: block;
    }

    &.col-freeze {
      text-align: right;
      width: 180px;
      flex-basis: 180px;
      flex-grow: 0;
      flex-shrink: 0;
      display: block;
      padding-right: 156px;
    }
  }
}

.el-col {
  display: flex;
  align-items: center;

  &:first-child {
    padding-right: 20px;
  }
}

.el-input {
  flex-grow: 1;
  max-width: initial;
  flex-basis: 1%;
}

.button-remove {
  color: #ff0000;
  font-size: 14px;
}

/deep/ {
  .el-table {
    padding: 0 6px;

    .el-select {
      width: 100%;
    }
  }

  .el-table__header-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    width: 98%;
    margin-bottom: 10px;
    background-color: #fdfdfd;
    box-shadow: 0 2px 6px 0 rgba(227, 208, 208, 0.5);

    th {
      border-bottom: none;
    }
  }

  .el-table {
    &::before {
      display: none;
    }

    td {
      border-bottom: none;
    }
  }
}
</style>
