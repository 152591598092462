import * as R from 'ramda';
import { http, dataMiddleware, dateFormatSlash } from '@/lib';
import { hosts } from '@/lib/api.configs';

const convertDate = (insights) => {
  if (insights.data) {
    insights.data = insights.data.map((insight) => ({
      ...insight,
      created_at: dateFormatSlash(insight.created_at),
    }));
  }

  return insights;
};

const reduceInsights = R.compose(convertDate);

const list = (customerId, params) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/insights`, { params })
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }))
    .then(reduceInsights);

const recipients = (params) =>
  http(hosts.v1)
    .get(`/users`, { params })
    .then((res) => {
      if (res.data.data) {
        return res;
      } else {
        return {
          ...res,
          data: {
            ...res.data,
            data: [],
          },
        };
      }
    })
    .then(dataMiddleware);

const insightStatus = (customerId, insightId, params) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/insights/${insightId}`, { params })
    .then(dataMiddleware);

const postInsight = (customerId, insight) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/insights`, insight)
    .then(dataMiddleware);

const postUrl = (customerId, longUrl) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/short_urls`, longUrl)
    .then(dataMiddleware);

export { list, recipients, insightStatus, postInsight, postUrl };
