import { resources } from '@/api';
import { vendorsPrettyMap } from '@/lib/helpers';
import * as R from 'ramda';

export default {
  namespaced: true,

  state: {
    resources: [],
    prettyNames: {},
  },

  getters: {
    checkResources: (state) => state.resources.length,
    getResourceByName: (state) => (name) => {
      return state.resources.find((resource) => resource.name === name);
    },
    getCountries: (state) => {
      const countries = state.resources.find(
        (resource) => resource.name === 'countries',
      );
      return countries.value;
    },
    getStates: (state) => {
      const states = state.resources.find(
        (resource) => resource.name === 'states',
      );
      return states.value;
    },
    getPrettyVendorByCode: (state) => (code) => {
      return R.propOr(code, code, state.prettyNames);
    },
    getVendorIdByCode: (state, getters) => (code) => {
      const vendors = R.propOr(
        [],
        'value',
        getters.getResourceByName('vendors'),
      );
      const vendor = R.find(
        R.propSatisfies(R.contains(code), 'providers_codes'),
      )(vendors);
      return R.propOr(null, 'id', vendor);
    },
  },

  mutations: {
    setResources: (state, payload) => {
      state.resources = payload;
    },
    setPrettyVendorNames: (state, data) => {
      const vendors = R.pipe(
        R.find(R.propEq('name', 'vendors')),
        R.propOr([], 'value'),
      )(data);
      state.prettyNames = vendorsPrettyMap(vendors, 'providers_codes');
    },
  },

  actions: {
    async setResources({ commit }) {
      const { data } = await resources.getResources();
      commit('setResources', data);
      commit('setPrettyVendorNames', data);
    },
  },
};
