<template>
  <div class="bulk-selection">
    <div
      class="bulk-selection-actions__selection"
      :class="{ selected: selection.length > 0 || isSelectInProgress }"
    >
      <template v-if="selection.length === 0 && !isSelectInProgress">
        <span>Number of {{ items }}:</span>
        <span class="fw-600">{{ total || 0 }}</span>
      </template>
      <template v-else>
        <span>Selected {{ items }}:</span>
        <span class="fw-600">{{ selection.length || 0 }}</span>
        <span>of</span>
        <span class="fw-600">{{ total || 0 }}</span>
        <el-button type="text" @click="selectAll">Select All</el-button>
        <el-button type="text" @click="clearAll">Clear All</el-button>
        <div class="bulk-action">
          <el-dropdown
            trigger="click"
            @command="(fn) => fn()"
            placement="bottom"
          >
            <span class="fw-600">
              Bulk Action
              <div class="el-icon-caret-bottom"></div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in bulkActions"
                :key="item.label"
                :command="item.action"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selection: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    isSelectInProgress: {
      type: Boolean,
      default: () => false,
    },
    bulkActions: {
      type: Array,
      default: () => [],
    },
    items: {
      type: String,
      default: () => '',
    },
    totalResults: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    total() {
      return this.totalResults ? this.totalResults : this.groups.length;
    },
  },
  methods: {
    selectAll() {
      this.$emit('select-all');
    },
    clearAll() {
      this.$emit('clear-all');
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-selection {
  &-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;

    &__selection {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 20px;
      margin: 10px 0;
      color: #222222;

      &.selected {
        color: #4a90e2;
      }

      .el-button {
        margin-left: 28px;
        &--text {
          text-decoration: none;
        }
      }

      span {
        padding-right: 7px;
      }

      .el-dropdown {
        padding-left: 40px;
        color: #4a90e2;
        cursor: pointer;
      }
    }
  }
}
</style>
