<script>
import { mapGetters, mapActions } from 'vuex';
import * as R from 'ramda';
import { bills, globalBills, myBills } from '@/api';
import DeletePrompt from './../../DeletePrompt';
import CommentModal from './CommentModal';
import Papa from 'papaparse';

export default {
  components: {
    DeletePrompt,
    CommentModal,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isPriorityOpened: false,
      isAssignOpened: false,
      bulkActionsQueue: [],
      intervalHandler: null,
      stateShown: false,
      isDeleteConfirmVisible: false,
      isParkModalVisible: false,
      isUnparkModalVisible: false,
    };
  },
  mounted() {
    this.getMyBillsSummary();
    window.addEventListener('storage', this.localStorageWatcher);
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.localStorageWatcher);
  },
  computed: {
    ...mapGetters({
      bill: 'bills/bill',
      priority: 'bills/priority',
      userId: 'user/userId',
      customerId: 'customers/id',
      activeQty: 'myBills/activeQty',
      parkedQty: 'myBills/parkedQty',
      filtersData: 'globalBills/filtersData/filterData',
      customerList: 'customers/list',
      billOrVAFrozen: 'bills/billOrVAFrozen',
      reasonFrozenBill: 'bills/reasonFrozenBill',
    }),
    isParked() {
      return R.prop('isParked', this.bill);
    },
    billId() {
      return R.prop('id', this.bill);
    },
    priorityStr() {
      return +R.prop('priority', this.bill) === this.priority.high
        ? 'High'
        : 'Normal';
    },
    myPreviousBillId() {
      return R.path(['navigation', 'prior', 'billId'], this.bill);
    },
    myPreviousBillCustomerId() {
      return R.path(['navigation', 'prior', 'customerId'], this.bill);
    },
    myCurrentBillNumber() {
      return R.path(['navigation', 'index'], this.bill);
    },
    myNextBillId() {
      return R.path(['navigation', 'next', 'billId'], this.bill);
    },
    myNextBillCustomerId() {
      return R.path(['navigation', 'next', 'customerId'], this.bill);
    },
    myBillsTotal() {
      return R.path(['navigation', 'totalCount'], this.bill);
    },
    hasNextBill() {
      return this.myNextBillId && this.myNextBillId;
    },
    hasPreviousBill() {
      return this.myPreviousBillId && this.myPreviousBillCustomerId;
    },
    hasNavigation() {
      return this.hasNextBill || this.hasPreviousBill;
    },
    isMyBill() {
      return +this.billUserId === +this.userId;
    },
    billUserId() {
      return R.prop('userId', this.bill);
    },
    workflowColor() {
      let color = 'grey';
      const state = R.propOr('', 'workflowState', this.bill).toLowerCase();
      switch (state) {
        case 'integrity_check':
        case 'data_verification_1':
        case 'data_verification_2':
          color = 'red';
          break;
        case 'data_audit_1':
        case 'data_audit_2':
          color = 'orange';
          break;
        case 'paid':
        case 'processed':
          color = 'green';
          break;
      }
      return color;
    },
    workflowState() {
      const list = R.propOr([], 'workflow_states', this.filtersData);
      const prop = R.prop('workflowState', this.bill);
      const match = R.find(R.propEq('value', prop))(list);
      return R.propOr('', 'name', match);
    },
    userName() {
      const list = R.propOr([], 'users', this.filtersData);
      const prop = R.prop('userId', this.bill);
      const match = list.find((item) => +item.id === +prop);
      return R.propOr('', 'name', match);
    },
    customerName() {
      const prop = R.prop('customerId', this.bill);
      let customerName = '';
      if (prop) {
        const match = R.find(R.propEq('id', prop.toString()))(
          this.customerList,
        );
        customerName = R.propOr('', 'name', match);
      }
      return customerName;
    },
    isMarkedForPayment() {
      const isLive =
        R.propOr('', 'billType', this.bill).toLowerCase() === 'live';
      return isLive && R.has('markedForPayment', this.bill);
    },
  },
  methods: {
    ...mapActions({
      loadBillPath: 'bills/loadBillPath',
      getMyBillsSummary: 'myBills/getMyBillsSummary',
    }),
    changeBill(customerId, billId) {
      this.loadBillPath({
        path: `/customers/${customerId}/bills/${billId}`,
        router: this.$router,
      });
    },
    prop: R.prop,
    propOr: R.propOr,
    async openJSON() {
      const { billId = 0, customerId } = this.$route.params;
      const data = await bills.getJsonDownloadLink({ customerId, billId });
      if (data) {
        const w = window.open('about:blank');
        w.document.open();
        w.document.write(JSON.stringify(data));
        w.document.close();
      }
    },
    async deleteBill() {
      this.hideDeleteConfirm();
      this.isLoading = true;

      try {
        await bills.deleteBill(this.customerId, this.billId);
        this.$message({
          showClose: true,
          message: `Bill ${this.billId} successfully delete!`,
          type: 'success',
        });
        this.$router.push({ path: `/customers/${this.customerId}/bills` });
      } catch (e) {
        this.$message({
          showClose: true,
          message: `Conflict deleting bill ${this.billId}. Try again later...`,
          type: 'error',
        });
      }

      this.isLoading = false;
    },
    showDeleteConfirm() {
      this.isDeleteConfirmVisible = true;
    },
    hideDeleteConfirm() {
      this.isDeleteConfirmVisible = false;
    },
    async changeBillPriority(priority) {
      const billsIds = [+this.bill.id];
      const action = 'changePriority';
      const data = { [action]: { billsIds, priority } };
      await this.runBulkAction(
        data,
        priority === this.priority.high
          ? 'Set Priority Flag'
          : 'Clear Priority Flag',
      );
    },
    changeVisibility(dropdown, isVisible) {
      this[dropdown] = isVisible;
    },
    async reparseBill() {
      try {
        await bills.reparse({
          customerId: this.customerId,
          billId: this.bill.id,
        });
        this.$message({ message: `Reparsed successful`, type: 'success' });
        this.$emit('update');
      } catch (e) {
        this.$message({
          message: 'Reparse failed. Please try again later.',
          type: 'error',
        });
      }
    },
    async releaseBill() {
      const data = {
        assign: {
          billsIds: [this.billId],
          assignee: 0,
        },
      };
      this.reassign(data, 'Release');
    },
    async reassign(data, name = 'Resolve') {
      try {
        await globalBills.bulkActions(data);
        this.$message({ message: `${name} successful.`, type: 'success' });
        this.redirectAfterReassign();
      } catch (e) {
        this.$message({
          message: `${name} failed. Please try again later.`,
          type: 'error',
        });
      }
    },
    async assignBill(userId) {
      const data = {
        assign: {
          billsIds: [this.billId],
          assignee: +userId,
        },
      };
      await this.reassign(data, 'Reassign');
      this.getMyBillsSummary();
    },
    async parkBill(comment = '') {
      this.closeConfirmModals();
      try {
        await myBills.park({ bill_id: +this.billId, comment });
        this.$message({
          message: `The bill was parked successfully.`,
          showClose: true,
          type: 'success',
        });
        this.getMyBillsSummary();
        this.$emit('update');
      } catch (e) {
        this.$message.error({
          message: 'Please try again latter.',
          showClose: true,
        });
      }
    },
    async unParkBill(comment = '') {
      this.closeConfirmModals();
      try {
        await myBills.unpark({ bill_id: +this.billId, comment });
        this.$message({
          message: `The bill was unparked successfully.`,
          showClose: true,
          type: 'success',
        });
        this.getMyBillsSummary();
        this.$emit('update');
      } catch (e) {
        this.$message.error({
          message: 'Please try again latter.',
          showClose: true,
        });
      }
    },
    async runBulkAction(bulkData, label) {
      try {
        await globalBills.bulkActions(bulkData);
        this.$message({
          message: `${label} successful.`,
          showClose: true,
          type: 'success',
        });
        this.$emit('update');
      } catch (e) {
        console.log(e);
        this.$message.error({
          message: 'Please try again latter.',
          showClose: true,
        });
      }
    },
    redirectAfterReassign() {
      if (this.hasNextBill) {
        this.loadBillPath({
          path: {
            name: 'customer.bills.info',
            params: {
              customerId: String(this.myNextBillCustomerId),
              billId: this.myNextBillId,
            },
            query: this.$route.query,
          },
          router: this.$router,
        });
      } else {
        this.$router.push({ name: 'bills.my' });
      }
    },
    async openPdfInNewTab() {
      const url = await this.getPdfLink();
      if (url) {
        fetch(url)
          .then((response) => response.blob())
          .then((data) => {
            const file = new Blob([data], { type: 'application/pdf' });
            window.open(URL.createObjectURL(file));
          });
      }
    },
    async downloadPdf() {
      const url = await this.getPdfLink();
      if (url) {
        const a = window.document.createElement('a');
        a.setAttribute('href', url);
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
      } else {
        console.error(`GET PDF LINK ERROR: got empty download url`);
      }
    },
    async getPdfLink() {
      const { billId = 0, customerId } = this.$route.params;
      const { url } = await bills.getPdfDownloadLink({ customerId, billId });
      return url;
    },
    async downloadCsv() {
      const { billId = 0, customerId } = this.$route.params;
      try {
        const { data = [] } = await bills.getBillObservation(
          customerId,
          billId,
        );
        if (Array.isArray(data)) {
          const sortByBlock = (item) => parseInt(R.prop('block', item) || 0);
          const bill = R.sortBy(sortByBlock)(
            data.map(
              R.pickAll([
                'block',
                'description',
                'code',
                'type',
                'value',
                'usage',
                'uom',
                'charge',
                'currency',
              ]),
            ),
          );
          bill.map((bill) => {
            if (
              !bill.charge &&
              (bill.code === 'INFO_COST' || bill.code === 'TOTALPAYAMOUNT')
            ) {
              bill.charge = 0.0;
            }
            return bill;
          });
          const csv = Papa.unparse(bill);
          const element = document.createElement('a');
          element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(csv),
          );
          element.setAttribute('download', `Bill-${billId}.csv`);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
      } catch (e) {
        console.log(e);
      }
    },
    localStorageWatcher() {
      try {
        const editor = JSON.parse(localStorage.getItem('editor'));
        const { billId } = this.$route.params;
        if (editor.includes(billId)) {
          const data = R.reject(R.equals(billId))(editor);
          localStorage.setItem('editor', JSON.stringify(data));
          this.getMyBillsSummary();
          this.redirectAfterReassign();
        }
      } catch (e) {
        console.log(`can't parse editor in the localStorage`);
      }
    },
    closeConfirmModals() {
      this.isParkModalVisible = false;
      this.isUnparkModalVisible = false;
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<template lang="pug">
.bill-header-container
    el-dialog.delete-prompt(:visible.sync='isDeleteConfirmVisible' :modal='false' :show-close='false' top='25px' :width="isMarkedForPayment ? '480px' : '360px'")
        delete-prompt(@confirm='deleteBill' @cancel='hideDeleteConfirm' :billId='billId' :isMarkedForPayment="isMarkedForPayment")
    el-dialog(:visible.sync='isParkModalVisible' width='440px' isVisible="isParkModalVisible")
      comment-modal(@confirm='parkBill'  @cancel="closeConfirmModals"  :is-visible="isParkModalVisible"
        title="Park Bill"
        text='This bill will be added to your "Parked Bills" and removed from your "My Bills" queue.')
    el-dialog(:visible.sync='isUnparkModalVisible'  width='440px')
      comment-modal(@confirm='unParkBill' @cancel="closeConfirmModals"  :is-visible="isUnparkModalVisible"
        title="Unpark Bill"
        text='This bill will be removed from your "Parked Bills" and added to your "My Bills" queue.')
    el-row(:gutter="8", type="flex", align="middle")
        el-col(:span="5")
            router-link(:to="{ name: 'customer.bills' }")
                i.icon.icon-arrow-pointing-to-right
            .bill-header__wrapper
                .bill-header__label.bill-header__label--title Bill Details
                .bill-header__value.bill-header__value--customer-name(:title="customerName") {{ customerName }}
            .frozen-wrapper
                inline-svg(:src="require('../../../../../assets/icons/freezeBlue.svg')" v-if="billOrVAFrozen")
            .frozen-reason-wrapper
                el-tooltip(placement='top' effect='light' popper-class='frozen-reason' v-if="reasonFrozenBill")
                    template(slot='content') {{reasonFrozenBill}}
                    span.frozen-reason {{reasonFrozenBill}}
        el-col(:span="3")
            .bill-navigation(v-if="hasNavigation && !isParked")
                a(v-if='hasPreviousBill', @click="changeBill(myPreviousBillCustomerId, myPreviousBillId)")
                    i.el-icon-caret-left
                span Bill
                span.bill-navigation__current {{ myCurrentBillNumber }}
                span of
                span.bill-navigation__total {{ myBillsTotal }}
                a(v-if="hasNextBill", @click="changeBill(myNextBillCustomerId, myNextBillId)")
                    i.el-icon-caret-right
            div(v-else-if="isMyBill && isParked") Parked
            template(v-else-if="billUserId")
                .bill-header__wrapper
                    .bill-header__label Assigned to:
                    .bill-header__value {{ userName }}
            template(v-else)
                .bill-header__wrapper
                    .bill-header__label &nbsp;
                    .bill-header__value Unassigned
        el-col(:span="2")
            .bill-header__wrapper
                .bill-header__label Type:
                .bill-header__value {{ propOr('', 'billType', bill) | capitalize }}
        el-col(:span="3")
            .bill-header__wrapper
                .bill-header__label Workflow State:
                .bill-header__value(:class="workflowColor") {{ workflowState }}
        el-col(:span="3")
            .bill-header__wrapper
                .bill-header__label Priority:
                .bill-header__value.bill-header__value--priority
                    el-dropdown(:hide-on-click="true"
                        size="medium"
                        :trigger="'click'"
                        placement="bottom-start"
                        @visible-change="changeVisibility('isPriorityOpened', $event)" )
                        span
                            span(:class="{active: isPriorityOpened}") {{ priorityStr }}
                            i.el-icon-caret-bottom
                        el-dropdown-menu.actions-dropdown(slot="dropdown")
                            el-dropdown-item(@click.native="changeBillPriority(priority.high)")
                                span.dropdown-item Set Priority Flag
                            el-dropdown-item(@click.native="changeBillPriority(priority.normal)")
                                span.dropdown-item Clear Priority Flag
        el-col(:span="2")
            router-link(:to="`/customers/${customerId}/bills/${billId}/editor`", target='_blank')
                el-button(type="primary", size="mini" ) Edit
        el-col(:span="1")
            el-button(type="text" @click="reparseBill") Reparse
        el-col(:span="2")
            el-button(v-if="isMyBill" type="text" @click="releaseBill") Release
        el-col(:span="2")
            el-dropdown(:hide-on-click="true"
                size="medium"
                :trigger="'click'"
                placement="bottom-start"
                @visible-change="changeVisibility('isAssignOpened', $event)" )
                span
                    span(:class="{active: isAssignOpened}") Reassign
                    i.el-icon-caret-bottom
                el-dropdown-menu.actions-dropdown(slot="dropdown")
                    el-dropdown-item(v-for="user in users" @click.native="assignBill(user.id)" :key="user.id")
                        span.dropdown-item {{ user.name }}
        el-col.bill-actions(:span="1")
            el-dropdown(:hide-on-click="false", size="small")
                span.el-dropdown-link.actions-collapse
                    i.el-icon-more.actions-collapse-icons
                el-dropdown-menu.actions-dropdown(slot="dropdown")
                    el-dropdown-item(@click.native="openPdfInNewTab")
                        span.dropdown-item Open Bill PDF in a new tab
                    el-dropdown-item(@click.native="downloadPdf")
                        span.dropdown-item Download Bill PDF
                    el-dropdown-item(@click.native="downloadCsv")
                        span.dropdown-item Download CSV
                    el-dropdown-item(@click.native="openJSON")
                        span.dropdown-item Open JSON
                    el-dropdown-item(v-if="isMyBill && isParked" @click.native="isUnparkModalVisible = true" divided)
                        span(type="text") Unpark
                    el-dropdown-item(v-else-if="isMyBill" @click.native="isParkModalVisible = true" divided)
                        span(type="text") Park
                    el-dropdown-item(@click.native="showDeleteConfirm()", divided)
                        span.dropdown-item Delete

</template>
<style lang="scss">
.bill-header-container {
  width: 100%;

  .el-icon-caret-right,
  .el-icon-caret-left {
    cursor: pointer;
  }

  *:focus {
    outline: none;
  }

  .el-button--text {
    text-decoration: none;
  }

  .el-col {
    display: flex;
    align-items: center;

    &:first-child {
      min-width: 200px;
    }
  }

  .el-button {
    font-weight: 600;
  }

  .el-select {
    .el-input {
      .el-input__inner {
        width: 90px;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        color: #4a90e2;
        border: none;
      }
    }
  }

  .bill-header {
    &__title {
      display: inline-block;
      color: #273a58;
      font-size: 20px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 27px;
    }

    &__text {
      color: #222222;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__label {
      color: #919398;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 14px;

      &--title {
        color: #273a58;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 22px;
      }
    }

    &__value {
      color: #303133;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;

      &--customer-name {
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #273a58;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
      }

      &.red {
        font-weight: 600;
        color: #f56c6c;
      }

      &.orange {
        font-weight: 600;
        color: #f3a745;
      }

      &.green {
        font-weight: 600;
        color: #67c23a;
      }

      &.grey {
        font-weight: 600;
        color: #303133;
      }
    }
  }

  .bill-navigation {
    white-space: nowrap;

    i {
      position: relative;
      top: 3px;
      font-size: 24px;
      color: #3f9fff;
    }

    &__current {
      display: inline-block;
      padding: 0 5px;
    }

    &__total {
      display: inline-block;
      padding: 0 0 0 5px;
    }
  }

  .el-dropdown {
    &:hover {
      color: #4a90e2;
    }

    i {
      transition: all 0.3s;
      transform: rotate(0deg);
    }

    .active {
      color: #4a90e2;

      + i {
        color: #4a90e2;
        transform: rotate(180deg);
      }
    }

    font-weight: 600;
    cursor: pointer;

    span {
      display: inline-block;
      padding-right: 7px;
    }
  }

  .bill-actions {
    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-collapse {
        display: flex;
        background-color: #ffffff;
        min-height: 24px;
        min-width: 24px;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 6px 3px rgba(225, 223, 223, 0.65);
        border-radius: 24px;

        &-icon {
          font-size: 10px;
        }
      }
    }

    .el-dropdown {
      cursor: pointer;

      span {
        padding: 0;
      }
    }
  }
  .el-tooltip {
    color: #0080ff;
    font-size: 14px;
    margin-left: 6px;
  }

  .frozen-wrapper {
    margin-left: 5px;
  }
  .frozen-reason-wrapper {
    .frozen-reason {
      width: 80px;
      text-overflow: ellipsis;
      height: 18px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
