<template lang="pug">
  .delete-modal_container
    template
      .delete-modal_content
        p This location move to unassigned locations list.
      .delete-modal_actions
        el-button.plain-button-reverse(@click='confirmCancel') Cancel
        el-button(type='primary', @click='confirmDelete') Remove
</template>

<script>
export default {
  props: {
    locationData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    confirmDelete() {
      this.$emit('confirmDelete');
    },
    confirmCancel() {
      this.$emit('confirmCancel');
    },
  },
};
</script>
