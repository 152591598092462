<template lang="pug">
  .delete-modal_container
    template
      .delete-modal_content
        p.danger.text-center You may not create more than 10 nesting Locations
      .delete-modal_actions
        el-button(type='primary', @click='close') Return
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
