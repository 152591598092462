<template>
  <div class="create-wrapper" data-element="create-report-container">
    <div class="form-content">
      <div class="form-section">
        <h4 class="header">Report Type</h4>
        <div class="form-section-content">
          <div class="form-field">
            <el-select
              class="report-type-select"
              @change="reportChange"
              :popper-append-to-body="false"
              data-action="select-report-type"
              popper-class="select-report-type_dropdown"
              :value="selectedReport.readableId"
            >
              <el-option
                v-for="option in reportGlobalTemplates"
                :key="option.readableId"
                :label="option.title"
                :value="option.readableId"
                :data-element="option.readableId"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="form-section">
        <h4 class="header">Parameters</h4>
        <div class="form-section-content">
          <div class="form-field short">
            <label>Format</label>
            <el-select
              v-model="selectedFormat"
              data-action="select-format-type"
              popper-class="select-format-type_dropdown"
            >
              <el-option
                v-for="option in this.selectedReport.exportFormats"
                :key="option"
                :label="option.toUpperCase()"
                :value="option"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="hasFilters" class="form-section filters">
        <h4 class="header">Filters</h4>
        <report-filters
          :report="selectedReport"
          :resetDateRange="resetDateRange"
          :filters="appliedFilters"
        >
        </report-filters>
      </div>
    </div>
    <div
      class="reports-form-actions-wrapper"
      :class="{ success: successOpened }"
    >
      <div class="reports-form-actions">
        <el-button
          round
          type="primary"
          @click="createReport"
          data-action="submit-report"
          >Create report
        </el-button>
        <el-button
          round
          type="text"
          @click="resetFilters"
          data-action="reset-filters"
          >Reset</el-button
        >
      </div>
    </div>
    <el-dialog
      class="success-dialog"
      :visible.sync="successOpened"
      :show-close="false"
      title="Your report has been submitted"
      :lock-scroll="true"
      :fullscreen="false"
      width="530px"
      @close="closeSuccess"
    >
      <success @closeSuccess="closeSuccess"></success>
    </el-dialog>
  </div>
</template>

<script>
import MultiSelect from '@/pages/Bills/Filters/Select';
import Success from './Success';
import BaseCheckbox from '@/components/FormComponents/BaseCheckbox';
import ReportFilters from '@/components/ReportFilters';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    Success,
    MultiSelect,
    BaseCheckbox,
    ReportFilters,
  },
  async mounted() {
    await this.loadGlobalReportTemplates();
    this.selectedReport = this.reportGlobalTemplates.find(
      (template) => template.readableId === 'ADMIN_PROCESSED_BILLS',
    );
  },
  props: {
    handleCreateReport: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      appliedFilters: {},
      successOpened: false,
      selectedReport: { availableFilters: [] },
      selectedFormat: 'csv',
      resetDateRange: false,
      showErrors: false,
    };
  },
  computed: {
    ...mapGetters({
      customers: 'customers/list',
      user: 'user/user',
      reportGlobalTemplates: 'globalReportTemplates/templates',
    }),
    hasFilters () {
      return this.selectedReport
        && this.selectedReport.availableFilters
        && this.selectedReport.availableFilters.length > 0
    },
    cleanFilters() {
      let cleanFilters = {
        ...this.appliedFilters,
        customDateRange: undefined,
      };
      if (this.appliedFilters.customDateRange) {
        cleanFilters.start_date = this.appliedFilters.customDateRange[0];
        cleanFilters.end_date = this.appliedFilters.customDateRange[1];
      }

      Object.keys(cleanFilters).forEach((key) => {
        const value = cleanFilters[key];
        if (value === '') {
          cleanFilters[key] = undefined;
        }
        if (Array.isArray(value) && value.length === 0) {
          cleanFilters[key] = undefined;
        }
      });

      return cleanFilters;
    },
  },
  methods: {
    reportChange(selected) {
      this.selectedReport = this.reportGlobalTemplates.find(
        (template) => template.readableId === selected,
      );
      this.resetFilters();
    },
    closeSuccess() {
      this.successOpened = false;
      this.$emit('showList');
    },
    resetFilters() {
      this.selectedFormat = 'csv';
      this.appliedFilters = {};
      this.resetDateRange = !this.resetDateRange;
    },
    async createReport() {
      this.showErrors = false;

      try {
        await this.handleCreateReport({
          reportTemplateId: this.selectedReport.id,
          saveFormat: this.selectedFormat,
          customReportName: this.selectedReport.title,
          emailRecipients: [this.user.email],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          appliedFilters: this.cleanFilters,
        });
        this.successOpened = true;
      } catch (error) {
        if (_.get(error, ['response.status']) === 409) {
          this.showPopupError(
            _.get(
              error,
              ['message'],
              [
                'You have too many scheduled reports.',
                'Please try again later.',
              ],
            ),
          );
        } else {
          this.showPopupError(
            _.get(
              error,
              ['message'],
              ['Something went wrong.', 'Please try again later.'],
            ),
          );
        }
      }
    },
    showPopupError(message) {
      const h = this.$createElement;
      if (_.isArray(message)) {
        message = message.map((line) => h('p', null, line));
      }
      this.$msgbox({
        title: 'Error',
        message: h('div', null, message),
        confirmButtonText: 'OK',
        center: true,
      });
    },
    ...mapActions({
      loadGlobalReportTemplates:
        'globalReportTemplates/getGlobalReportTemplates',
    }),
  },
};
</script>

<style lang="scss" scoped>
.form-error {
  align-self: flex-end;
  padding: 0 10px;
  font-size: 14px;
  line-height: 30px;
  color: #ff0000;
}

.create-wrapper {
  /deep/ .select-report-type_dropdown .el-select-dropdown__wrap {
    max-height: initial;
  }

  /deep/ .success-dialog {
    .el-dialog {
      border-radius: 10px;

      .el-dialog__header {
        padding-top: 40px;
        padding-bottom: 0;

        .el-dialog__title {
          text-align: center;
          color: #2c2e34;
          font-size: 18px;
          font-weight: 900;
          line-height: 25px;
          display: block;
        }
      }

      .el-dialog__body {
        padding-bottom: 40px;
      }
    }
  }

  .form-section {
    padding-bottom: 24px;
    border-bottom: 1px solid #c6c6c6;

    &.filters {
      border: 0;
    }

    .header {
      font-size: 16px;
      line-height: 22px;
      font-weight: 900;
      color: #273a58;
      margin-top: 16px;
    }

    .form-section-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &.filters {
        margin: 0 -40px 0 -40px;
        padding: 20px 40px;
        background-color: #f7f8fa;
        flex-wrap: wrap;
      }

      .form-field-group {
        margin-left: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
      }

      .divider {
        align-self: flex-end;
        line-height: 30px;
        margin: 0 5px;
        height: auto;
        border: none;
        color: #7d89a6;
        font-size: 12px;
        font-weight: 500;
      }

      .form-field {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;

        &.placeholder {
          width: 180px;
        }

        label {
          margin-bottom: 7px;
          color: #606266;
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
        }

        /deep/ .el-input__inner {
          height: 30px;
          line-height: normal;
          width: 180px;
          outline: none;
        }

        .report-type-select {
          /deep/ .el-input__inner {
            width: 260px;
          }
        }

        /deep/ .el-input__icon {
          line-height: normal;
        }

        &.middle {
          max-width: 200px;

          /deep/ .el-input__inner {
            width: 200px;
          }
        }

        &.short {
          max-width: 150px;

          /deep/ .el-input__inner {
            width: 150px;
          }
        }
      }

      .report-type-tip {
        margin-left: 25px;
        height: 44px;
        padding: 3px 16px;
        border-left: 4px solid #0080ff;
        box-sizing: border-box;
        max-width: 400px;
      }
    }

    .custom-range {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      align-items: flex-start;

      .exports-field {
        width: 100%;
        max-width: 100%;

        label {
          margin-bottom: 7px;
          color: #606266;
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
        }

        .date-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }
      }

      .el-date-editor {
        + .el-date-editor {
          margin-left: 25px;
        }

        .el-input__inner {
          width: 100%;
          height: 36px;
          line-height: 36px;
        }
      }

      .el-date-editor .el-range-input {
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: top;
      }

      .el-date-editor .el-range__icon,
      .el-date-editor .el-input__icon,
      .el-date-editor .el-range-separator {
        line-height: 22px;
      }
    }

    .select-box {
      position: relative;
      width: 100%;

      i {
        position: absolute;
        top: -1px;
        right: 5px;
        color: #c0c4cc;
        z-index: 9;
      }
    }

    .date-input-box {
      position: relative;
      width: calc(50% - 13px);

      i {
        position: absolute;
        top: -5px;
        left: 5px;
        color: #c0c4cc;
      }

      span {
        color: #c0c4cc;
        position: absolute;
        top: 6px;
        left: 30px;
        z-index: 9;
      }

      input {
        text-indent: 16px;
        position: relative;
        height: 36px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 10px;
        border: 1px solid #dcdfe6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        font-size: inherit;
        display: inline-block;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color 0.2s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }
    }
  }

  .reports-form-actions-wrapper {
    .reports-form-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 21px;

      button {
        padding: 8px 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;

        &.el-button--primary {
          font-weight: 900;
          color: #ffffff;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.reports-page {
  .el-tabs__content {
    overflow: visible;
  }
}

.error-dialog {
  .el-dialog__header {
    text-align: center;
  }

  .el-dialog__title {
    color: #f86259;
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: center;
  }
}
</style>
