<template>
  <el-popover
    v-if="operatorNotesCamelCase && operatorNotesCamelCase.note"
    placement="top"
    width="148"
    trigger="hover"
  >
    <div>
      <div class="note">{{ operatorNotesCamelCase.note }}</div>
      <div class="last-edited">
        Last edited by {{ fullName(operatorNotesCamelCase) }},
        {{ operatorNotesCamelCase.updatedAt | datetime('MM.dd.yyyy') }}
      </div>
    </div>
    <img
      :src="getNoteSvg"
      slot="reference"
      :class="[getHighPriorityStatus]"
      alt="note-image"
    />
  </el-popover>
</template>

<script>
import note from '@/assets/icons/note.svg';
import noteWhite from '@/assets/icons/note-white.svg';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    operatorNotes: {
      type: Object,
      default: () => {},
    },
    isOnBillBlock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userId: 'user/userId',
    }),
    operatorNotesCamelCase() {
      return (
        this.operatorNotes &&
        this.operatorNotes.user &&
        this.operatorNotes.user.profile && {
          ..._.mapKeys(this.operatorNotes, (values, key) => _.camelCase(key)),
          user: {
            profile: {
              ..._.mapKeys(this.operatorNotes.user.profile, (values, key) =>
                _.camelCase(key),
              ),
            },
          },
        }
      );
    },
    getNoteSvg() {
      return this.isOnBillBlock ? this.noteWhite : this.note;
    },
    getHighPriorityStatus() {
      return this.operatorNotesCamelCase &&
        this.operatorNotesCamelCase.priority === 'High'
        ? 'priority'
        : '';
    },
    fullName() {
      return (operatorNotes) =>
        +operatorNotes.userId === +this.userId
          ? 'you'
          : `${operatorNotes.user.profile.firstName} ${operatorNotes.user.profile.lastName}`;
    },
  },
  data() {
    return {
      note,
      noteWhite,
    };
  },
};
</script>

<style scoped>
.note {
  max-width: 100%;
  font-size: 12px;
  word-break: break-word;
  text-align: left;
}
.last-edited {
  padding-left: 8px;
  padding-top: 8px;
  max-width: 100%;
  font-size: 10px;
  word-break: break-word;
  text-align: right;
}
</style>
