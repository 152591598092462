<template>
  <div class="batches-wrapper">
    <div class="batches-title">Bill Batches</div>
    <div class="filter-container">
      <div class="filter-input">
        <div class="list-filter-title">Customer</div>
        <bills-filter-select
          :options="customers"
          v-model="customer_ids"
          v-on:input="onCustomerChanged"
          item-value="id"
          item-label="name"
        ></bills-filter-select>
      </div>
      <div class="filter-input">
        <div class="list-filter-title">Batch ID</div>
        <el-input
          v-model="batch_id"
          v-on:input="debounceReloadData"
          size="small"
          placeholder="Search"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <div class="filter-input">
        <div class="list-filter-title">Received Date</div>
        <el-date-picker
          v-model="selectedDateRange"
          @change="onSelectedDatesChange"
          size="small"
          type="daterange"
          style="width: 300px"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          placeholder="Pick a day"
        />
      </div>

      <div class="filter-input active-toggle">
        <span>Active Customers Only</span>
        <el-switch
          v-model="customer_active"
          @change="updateActiveCustomersFilter"
        />
      </div>
      <div class="filter-input">
        <el-radio-group v-on:change="updateStatusFilter" v-model="status">
          <el-radio label="external">Show external batches only</el-radio>
          <el-radio label="processed">Exclude batches still at FDG</el-radio>
          <el-radio label="all">All batches</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="batches-no">
      <span>Batches:</span>
      <span class="fw-600">{{ this.totalBatches }}</span>
    </div>
    <el-table
      :data="batches"
      class="with-shadow"
      v-loading.body.lock="isLoading"
    >
      <el-table-column label="Customer" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.customer_name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Batch ID">
        <template slot-scope="scope">
          <router-link
            class="batch-link"
            v-if="scope.row.downloaded_at"
            :to="`/bills/dashboard?batch_id=${scope.row.batch_id}&customer_active=${customer_active}`"
          >
            <span>{{ scope.row.batch_id }}</span>
          </router-link>
          <span v-else>{{ scope.row.batch_id }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Type">
        <template slot-scope="scope">
          <span>{{ scope.row.batch_type }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Date submitted">
        <template slot-scope="scope">
          <span>{{ scope.row.uploaded_at | datetime('LL/dd/yyyy') }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Submitted Bills">
        <template slot-scope="scope">
          <span>{{ getSubmittedBillsNo(scope.row) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Date Received">
        <template slot-scope="scope">
          <span>{{ scope.row.downloaded_at | datetime('LL/dd/yyyy') }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Received Bills">
        <template slot-scope="scope">
          <span>{{ getDownloadedBillsNo(scope.row) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Errors">
        <template slot-scope="scope">
          <span>{{ getErrorsNo(scope.row) }}</span>
        </template>
      </el-table-column>

      <el-table-column>
        <template slot-scope="scope">
          <el-button
            @click="portingProps.showBillBatchAutoReparseDialog(scope.row)"
            v-if="scope.row.batch_type === 'Historical Bill'"
            type="text"
            ><u>Auto-resolve</u></el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="paginator-wrapper is-background"
      layout="prev, pager, next, ->, sizes"
      v-if="totalBatches > limit || limit > 20"
      :page-size="limit"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
      :total="totalBatches"
      :disabled="isLoading"
    ></el-pagination>
  </div>
</template>

<script>
import BillsFilterSelect from './Filters/Select';
import { batches as batchesApi } from '@/api';
import { pickNotEmpty, pickNotNil } from '@/lib/utils';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { inputKeyupDebounce } from '@/lib';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    BillsFilterSelect,
  },
  async mounted() {
    const params = this.getQueryParams();
    Object.assign(this.$data, params);
    if (
      this.start_date &&
      this.start_date !== '-1' &&
      this.end_date &&
      this.end_date !== '-1'
    ) {
      this.selectedDateRange = [this.start_date, this.end_date];
    }
    await this.loadCustomers(params.customer_active);
    this.onDataChanged();
    this.debounceReloadData = inputKeyupDebounce(() => this.onDataChanged());
  },
  data: function () {
    return {
      debounceReloadData: null,
      selectedDateRange: [],
      customer_ids: [],
      batches: [],
      totalBatches: 0,
      limit: 20,
      offset: 0,
      start_date: DateTime.local().minus({ weeks: 2 }).toISO(),
      end_date: DateTime.local().toISO(),
      batch_id: '',
      customer_active: true,
      status: 'processed',
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      portingProps: (state) => state.porting.props,
    }),
    ...mapGetters({
      customers: 'customers/list',
    }),
    currentPage() {
      return this.offset / this.limit + 1;
    },
  },
  methods: {
    ...mapActions({
      loadCustomers: 'customers/getCustomersList',
    }),
    onCustomerChanged() {
      this.onDataChanged();
    },
    updateActiveCustomersFilter() {
      this.onDataChanged();
    },
    updateStatusFilter() {
      this.onDataChanged();
    },
    changeLimit(limit) {
      this.limit = limit;
      this.changeOffset(1);
    },
    changeOffset(pageNo) {
      this.offset = (pageNo - 1) * this.limit;
      this.onDataChanged(true);
    },
    getDownloadedBillsNo(row) {
      return _.get(row, 'payload.processed_bills_count.total_bills', '');
    },
    getErrorsNo(row) {
      return _.get(row, 'payload.processed_bills_count.errors_count', '0');
    },
    getSubmittedBillsNo(batch) {
      if (batch.payload) {
        return batch.payload.pdf_file_count;
      }
      return '';
    },
    onSelectedDatesChange(selectedDates) {
      if (selectedDates) {
        this.start_date = DateTime.fromJSDate(selectedDates[0]).toISO();
        this.end_date = DateTime.fromJSDate(selectedDates[1]).toISO();
      } else {
        this.start_date = null;
        this.end_date = null;
      }
      this.onDataChanged();
    },
    /**
     * @param {boolean} keepOffset - determines if we keep the offset or reset it to 0
     *                               this is used when changing filters
     */
    async onDataChanged(keepOffset) {
      if (!keepOffset) {
        this.offset = 0;
      }
      const {
        customers,
        batches,
        selectedDateRange,
        totalBatches,
        isLoading,
        ...params
      } = R.pipe(pickNotNil, pickNotEmpty)(this.$data);
      delete params.debounceReloadData;
      await this.setQueryParams(params);
      this.loadCustomers(params.customer_active);
      this.loadBatches();
    },
    async loadBatches() {
      this.isLoading = true;
      const params = this.getQueryParams();
      params.orders = ['-downloaded_at', '-uploaded_at'];

      // the "all" value means no status filter is apply (i.e. get all batches)
      // so we simply remoce the status completely
      if (params.status === 'all') {
        delete params.status;
      }

      if (params.start_date && params.start_date !== '-1') {
        params.start_date = DateTime.fromISO(params.start_date)
          .startOf('day')
          .toISO();
      } else {
        delete params.start_date;
      }

      if (params.end_date && params.end_date !== '-1') {
        params.end_date = DateTime.fromISO(params.end_date)
          .endOf('day')
          .toISO();
      } else {
        delete params.end_date;
      }

      const response = await batchesApi.list(params);
      this.totalBatches = response && response.meta.total_amount;
      this.batches = response && response.data;
      this.isLoading = false;
    },
    setQueryParams(params = {}) {
      const currentParams = this.getQueryParams();
      if (_.isEqual(params, currentParams)) {
        return;
      }
      params.limit = params.limit || this.limit;
      params.offset = params.offset || this.offset;
      params.status = params.status || this.status;

      if (_.isNil(params.start_date)) {
        params.start_date = '-1';
      }

      if (_.isNil(params.end_date)) {
        params.end_date = '-1';
      }

      this.$router.replace({ query: params });
    },
    getQueryParams() {
      const params = {};
      const query = R.pipe(pickNotNil, pickNotEmpty)(this.$route.query);
      // setPagination from url query
      params.limit = query.limit && parseInt(query.limit, 10);
      params.offset = query.offset && parseInt(query.offset, 10);
      params.status = query.status;
      params.batch_id = query.batch_id;

      if (typeof query.customer_active === 'string') {
        query.customer_active = query.customer_active === 'true';
      }

      params.customer_active = query.customer_active !== false;

      if (query.customer_ids && query.customer_ids.length) {
        params.customer_ids = query.customer_ids;
      }

      params.start_date = query.start_date && query.start_date.toString();
      params.end_date = query.end_date && query.end_date.toString();
      return R.pipe(pickNotNil, pickNotEmpty)(params);
    },
  },
};
</script>

<style lang="scss">
.batches-wrapper {
  box-sizing: border-box;
  padding: 20px;

  .batches-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .filter-container {
    margin: 0 0 30px 0;
    padding: 20px 24px 18px 24px;
    font-size: 14px;
    border-radius: 8px;
    background-color: #fdfdfd;
    box-shadow: 0 2px 5px 0 rgba(204, 204, 204, 0.5);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .filter-input + .filter-input {
    margin-left: 20px;
  }

  .el-radio-group {
    padding-top: 8px;
  }

  .el-radio:not(:last-child) {
    display: block;
    margin-bottom: 10px;
  }

  .active-toggle {
    box-sizing: border-box;
    padding-top: 32px;
    display: flex;
    align-items: flex-start;
  }

  .active-toggle > span {
    margin-right: 10px;
  }

  .batches-no {
    margin-bottom: 10px;
  }

  .el-table {
    th,
    td {
      padding: 9px 0;
    }

    .batch-link {
      padding: 5px 0;
      display: block;
      color: #4a90e2;
      text-decoration: underline;
    }
  }
}
</style>
