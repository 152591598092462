import Insights from './List.vue';
import InsightCreate from './Create.vue';

export default {
  path: 'insights',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: 'Insights for <%= customer %>',
      linkTemplate: '/customers/<%= id %>/insights',
      storeMappings: { customer: 'customers/name', id: 'customers/id' },
    },
  },
  children: [
    {
      path: '',
      name: 'customer.insights',
      component: Insights,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: 'create',
      component: InsightCreate,
      name: 'customer.insight.create',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'Сreate Insight for <%= customer %>',
          storeMappings: { customer: 'customers/name', id: 'customers/id' },
        },
      },
    },
  ],
};
