<template lang="pug">
  .editor-modal_container
    template(v-if='modalType === "cancel"')
      .editor-modal_content Are you sure you want to discard changes?
      .editor-modal_actions
        el-button.plain-button-reverse(@click='confirmCancel') Yes
        el-button(type='primary', @click='cancel') No
    template(v-if='modalType === "update"')
      h3 Confirm Bill Update
      .editor-modal_content.left Are you sure you want to update the Bill with the changes made?
      .editor-modal_content.left Add a comment describing the changes you made:
      el-input(type="textarea", :rows="3", placeholder="Please input", v-model="comment")
      .editor-modal_actions.column(v-if="myBill")
        el-button.plain-button-reverse(@click='confirmUpdate') Update and Keep Assignment
        el-button.plain-button-reverse(@click='confirmUpdateUnassign') Update and Release Assignment
        el-button(@click='cancel') Cancel
      .editor-modal_actions(v-else)
        el-button.plain-button-reverse(@click='cancel') Cancel
        el-button(type='primary', @click='confirmUpdate') Update

</template>

<script>
export default {
  props: ['modal-type', 'myBill'],
  data: () => ({
    comment: '',
  }),
  methods: {
    confirmUpdateUnassign() {
      this.$emit('confirm-update', this.comment, true);
    },
    confirmUpdate() {
      this.$emit('confirm-update', this.comment);
    },

    confirmCancel() {
      this.$emit('confirm-cancel');
    },

    cancel() {
      this.$emit('close-editor-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
}
.editor-modal_content {
  word-break: keep-all;
  &.left {
    text-align: left;
  }
  margin-bottom: 8px;
}

.editor-modal_actions {
  .el-button {
    width: 140px;
    border-radius: 4px;
  }

  &.column {
    display: flex;
    flex-direction: column;
    align-items: center;

    .el-button {
      height: 30px;
      line-height: normal;
      padding: 0;
      width: 256px;
      border-radius: 4px;
      background-color: #edf5ff;
      margin-left: 0;
      color: #3f9fff;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      border: none;
      margin-bottom: 8px;

      &:last-child {
        background-color: transparent;
      }
    }
  }
}
</style>
