<template lang="pug">
  .payment-modal
    .payment-modal--content
      el-table.payment-modal--table(:data="['']")
        el-table-column(label='Date' width='120')
          template
            div {{ date | datetime('LL/dd/yyyy') }}
        el-table-column(label='Payment File Format')
          template
            el-select.payment-type(v-model="fileType" placeholder="Choose Payment File" size="small")
              el-option(v-for="(t, i) in types" :key="i" :value="t.fileType" :label="`${capitalize(t.fileType)} Payment File`")
        el-table-column(label='Link to download' width='150')
          template(v-if="this.url")
            el-button( type="text", @click="download", :disabled="fileType.length === 0 ")
              .button-wrapper
                span Download
                i.icon.icon-download
          span.no-download-available(v-else) Not available
    .payment-modal--footer
      el-button(@click="close" type="primary" size="small") OK

</template>

<script>
import { capitalize } from '@/lib/helpers';
import * as R from 'ramda';
import { DateTime } from 'luxon';

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      fileType: '',
    };
  },
  computed: {
    types() {
      const curratedList = {};
      this.list.forEach((val) => {
        if (!curratedList[val.fileType]) curratedList[val.fileType] = val;
      });
      return Object.values(curratedList);
    },
    file() {
      return R.find(R.propEq('fileType', this.fileType))(this.list);
    },
    date() {
      return R.pipe(R.head, R.prop('createdAt'))(this.list);
    },
    markedForPaymentDate() {
      return R.pipe(R.head, R.prop('paymentFileDate'))(this.list);
    },
    url() {
      return R.propOr('', 'url')(this.file);
    },
  },
  methods: {
    capitalize,
    async download() {
      const dateFormatted = DateTime.fromISO(
        String(this.markedForPaymentDate),
        { zone: 'utc' },
      )
        .toFormat('yyyyLLdd')
        .toString();
      const url = new URL(this.url);
      const filename = R.last(url.pathname.split('/'));
      const extension = R.last(filename.split('.'));
      fetch(this.url).then((t) =>
        t.blob().then((b) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.download = `${this.fileType}_payment_file_${dateFormatted}.${extension}`;
          a.click();
        }),
      );
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../../../../styles/constants';

.icon-download {
  margin: -3px 0 0 10px;
  vertical-align: top;
}

.payment-modal {
  .payment-type {
    /deep/ {
      .el-input__inner {
        padding-left: 0;
        height: 23px;
        line-height: 23px;
        font-size: 14px;
        border: none;
        background: none;
      }
      .el-input__icon {
        line-height: 23px;
      }
    }
  }

  &--content {
    margin: 0 -15px;
  }
  .no-download-available {
    opacity: 0.5;
  }
  &--table {
    /deep/ {
      .el-dialog__header {
        font-weight: 400;
      }
      .el-button {
        color: #0080ff;
        &:hover {
          font-weight: 400 !important;
        }
        &.is-disabled {
          opacity: 0.4;
        }
      }

      .cell {
        height: 23px;
        line-height: 23px;
      }
    }
  }

  &--footer {
    padding: 40px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button {
      padding: 0;
      min-width: 200px;
      height: 30px;
      font-size: 14px;
      font-weight: 700;
      line-height: 30px;
    }
  }
}
</style>
