<template>
  <div class="customize-date-container">
    <BaseSelect
      class="selector"
      :ordered="true"
      :isEditMode="true"
      size="medium"
      v-bind:id="testId(label, 'RangeList')"
      :label="label"
      name="Date Range"
      v-model="rangeType"
      @change="handleChange"
      immediate
    >
      <el-option
        v-for="v in dateRanges"
        :key="v.key"
        :value="v.name"
        :label="v.name"
      />
    </BaseSelect>
    <BaseDatePicker
      v-if="pickerVisible"
      name="customDateRange"
      classes="date-selector"
      v-bind:id="testId(label, 'Input')"
      :picker-options="pickerOptions"
      size="medium"
      :type="elementType === 'month' ? 'monthrange' : 'daterange'"
      :format="elementType === 'date' ? 'MM/dd/yyyy' : 'MM/yyyy'"
      value-format="yyyy-MM-ddThh:mm:ss.000Z"
      @input="datePicked"
      :default-value="
        elementType === 'date'
          ? new Date()
          : new Date().getFullYear() - 1 + '-01'
      "
      :start-placeholder="elementType === 'date' ? 'Start day' : 'Start month'"
      :end-placeholder="elementType === 'date' ? 'End day' : 'End month'"
      :isEditMode="true"
      :label="label + ' Custom Range'"
    />
  </div>
</template>

<script>
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import { DateTime } from 'luxon'
import _ from 'lodash'

const DAY_IN_MILLISECONDS = 86400000
export default {
  props: {
    filter: {
      type: Object | String,
      default: () => {},
    },
    filterKey: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: 'All Time',
    },
    reportType: {
      type: String,
      default: '',
    },
    customOptions: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Date Range',
    },
    elementType: {
      type: String,
      default: 'date',
    },
    resetDateRange: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    resetDateRange(val) {
      this.resetDateRangeFilter()
    },
    customOptions(val) {
      this.setCustomDateRangeOptions()
    },
  },
  computed: {
    testId() {
      return (label, suffix) => _.camelCase(`${label}${suffix}`)
    },
  },
  components: {
    BaseDatePicker,
    BaseSelect,
  },
  data() {
    return {
      rangeType: 'All Time',
      pickerVisible: false,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      dateRanges: [
        { name: 'All Time' },
        { name: 'Custom Range' },
        { name: 'Last Full Calendar Month' },
      ],
    }
  },
  mounted() {
    this.handleChange()
    this.setCustomDateRangeOptions()
  },
  methods: {
    setCustomDateRangeOptions() {
      const sevenDaysOptionIndex = this.dateRanges.findIndex(dateRange => {
        return dateRange.name === 'Last 7 Days'
      })
      if (
        this.customOptions.indexOf('7days') !== -1 &&
        sevenDaysOptionIndex === -1
      ) {
        this.dateRanges.push({ name: 'Last 7 Days' })
      } else {
        if (sevenDaysOptionIndex !== -1) {
          this.dateRanges.splice(sevenDaysOptionIndex, 1)
        }
      }
    },
    handleChange() {
      if (this.rangeType === 'Custom Range') {
        this.$emit('date-range-pick', { key: this.filterKey, value: null })
        this.pickerVisible = true
      } else if (this.rangeType === 'Last Full Calendar Month') {
        this.pickerVisible = false
        let date = new Date()
        const value = {}
        value.min = DateTime.fromJSDate(date, { zone: 'UTC' })
          .minus({ months: 1 })
          .startOf('month')
          .toISO()
        value.max = DateTime.fromJSDate(date, { zone: 'UTC' })
          .minus({ months: 1 })
          .endOf('month')
          .toISO()

        const filter = { key: this.filterKey, value }
        this.$emit('date-range-pick', filter)
      } else if (this.rangeType === 'Last 7 Days') {
        this.pickerVisible = false
        const value = {}
        value.min = new Date(Date.now() - DAY_IN_MILLISECONDS * 7).toISOString()
        value.max = new Date().toISOString()
        const filter = { key: this.filterKey, value }
        this.$emit('date-range-pick', filter)
      } else {
        this.pickerVisible = false
        this.$emit('date-range-pick', { key: this.filterKey, value: null })
      }
    },
    datePicked(pickerVal) {
      const value = {}
      if (pickerVal && pickerVal[0]) {
        value.min = DateTime.fromISO(pickerVal[0])
          .startOf('day')
          .toISO()
      }
      if (pickerVal && pickerVal[1]) {
        if (this.elementType === 'date') {
          value.max = DateTime.fromISO(pickerVal[1])
            .endOf('day')
            .toISO()
        } else {
          value.max = DateTime.fromISO(pickerVal[1])
            .endOf('month')
            .endOf('day')
            .toISO()
        }
      }
      const filter = { key: this.filterKey, value }
      this.$emit('date-range-pick', filter)
    },
    resetDateRangeFilter() {
      this.rangeType = 'All Time'
      this.handleChange()
      this.datePicked([])
    },
  },
}
</script>
<style lang="scss">
.date-selector {
  margin: 10px 0 0 0;
  width: 280px;
}

.selector {
  padding-right: 10px;
}

.customize-date-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
