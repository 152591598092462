<template>
  <main-layout>
    <div class="page page-vendors">
      <div class="page-header">
        <h1 class="page-title" v-if="!editPretty">
          Vendors for {{ $customerName }}
        </h1>
        <h1 class="page-title" v-if="editPretty">Update Blank Pretty Names</h1>
        <div
          class="button-update"
          v-if="!editPretty && total > 0"
          @click="setEditMode"
          data-testid="editPrettyNamesBtn"
        >
          Update Pretty Names
        </div>
        <div v-if="editPretty">
          <el-button
            class="button"
            plain="plain"
            size="small"
            @click="cancel"
            data-testid="cancelEditBtn"
            >Cancel
          </el-button>
          <el-button
            class="button"
            type="primary"
            size="small"
            @click="update"
            data-testid="updatePrettyNamesBtn"
            >Update
          </el-button>
          <div class="info">{{ offset + 1 }}-{{ offsetTo }} of {{ total }}</div>
        </div>
      </div>
      <vendor-filters
        v-if="!editPretty"
        @onChange="onFiltersChange"
        :init-filters="this.filters"
      ></vendor-filters>
      <div class="page-content">
        <div class="table-wrapper" :key="tableKey">
          <el-table
            class="with-shadow clickable"
            :data="vendors"
            v-loading.body.lock="isLoading"
            @row-click="goToVendorDetails"
            :style="{ width: '100%' }"
            :default-sort="
              editPretty ? null : { prop: sortProp, order: sortOrder }
            "
            @sort-change="sortTable"
            data-testid="vendorsTable"
          >
            <el-table-column
              prop="id"
              label="ID"
              width="75"
              :sortable="sortable"
            >
              <template slot-scope="scope"
                ><span data-testid="vendorIdCol">{{
                  scope.row.id
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              prop="code"
              label="Code"
              width="207"
              :sortable="sortable"
            >
              <template slot-scope="scope"
                ><span data-testid="vendorCodeCol">{{
                  scope.row.code
                }}</span></template
              >
            </el-table-column>
            <el-table-column
              prop="prettyName"
              label="Global Pretty Name"
              width="300"
              :sortable="sortable"
            >
              <template slot-scope="scope">
                <span
                  class="wrap-pretty-name"
                  data-testid="vendorPrettyNameCol"
                  >{{ scope.row.prettyName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="customPrettyName"
              label="Custom Pretty Name"
              :sortable="sortable"
            >
              <template slot-scope="scope">
                <span
                  class="wrap-pretty-name"
                  v-if="!editPretty"
                  data-testid="vendorCustomPrettyNameCol"
                  >{{ scope.row.customPrettyName }}
                </span>
                <div class="input-container" v-else>
                  <div class="input-wrapper">
                    <el-input
                      class="input-pretty-vendor"
                      :size="'mini'"
                      :value="scope.row.customPrettyName"
                      @input="changePretty($event, scope.$index)"
                      data-testid="customPrettyNameInp"
                    >
                    </el-input>
                  </div>
                  <div class="input-length" data-testid="prettyNameLength">
                    {{
                      (scope.row.customPrettyName &&
                        scope.row.customPrettyName.length) ||
                      0
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="paginator-wrapper"
          v-if="!editPretty"
          background="background"
          layout="prev, pager, next, -&gt;, slot, sizes"
          :page-size="limit"
          :current-page="currentPage"
          @current-change="changeOffset"
          @size-change="changeLimit"
          :total="total"
        >
          <slot><p class="pager-description">Items per page</p></slot>
        </el-pagination>
      </div>
    </div>
  </main-layout>
</template>

<script>
import { Main } from '@/components/layouts';
import VendorFilters from './Filters/Filters.vue';
import _ from 'lodash';
import { addGetParam, addGetParams } from '@/lib/route';
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  components: {
    mainLayout: Main,
    VendorFilters,
  },
  data() {
    return {
      filters: {
        query: this.$route.query.query || '',
        blankCustomPrettyName:
          this.$route.query.blankCustomPrettyName === 'true',
      },
      limit: Number(this.$route.query.limit) || 20,
      offset: Number(this.$route.query.offset) || 0,
      total: 0,
      order: this.$route.query.orderBy || 'id.asc',
      isLoading: true,
      editPretty: false,
      vendorsCopy: [],
      tableKey: 0,
    };
  },
  mounted() {
    addGetParam('orderBy', this.order);
    this.loadList();
  },
  computed: {
    ...mapGetters({
      $vendors: 'customers/vendors',
      $meta: 'customers/meta',
      $customerName: 'customers/name',
    }),
    sortable() {
      return this.editPretty ? false : 'custom';
    },
    offsetTo() {
      const offsetTo = this.offset + this.limit;
      return offsetTo < this.total ? offsetTo : this.total;
    },
    currentPage() {
      return this.offset / this.limit + 1;
    },
    sortOrder() {
      const order = this.order.split('.');
      if (order[1] === 'desc') {
        return 'descending';
      } else if (order[1] === 'asc') {
        return 'ascending';
      }
    },
    sortProp() {
      if (this.order.length > 0) {
        return this.order.split('.')[0];
      }
    },
    vendors: {
      get() {
        if (this.editPretty) {
          return this.vendorsCopy;
        }
        return this.$vendors;
      },
      set(value) {
        this.vendorsCopy = value;
      },
    },
  },
  methods: {
    ...mapActions({
      $getCustomerVendors: 'customers/getCustomerVendors',
      $updateVendorsBulk: 'customers/updateVendorsBulk',
      $validateCustomPrettyName: 'customers/validateCustomPrettyName',
    }),
    ...mapMutations({
      $setFilters: 'customers/setFilters',
    }),
    setEditMode() {
      const copy = _.cloneDeep(this.vendors);
      copy.forEach((row) => {
        row.customPrettyName = _.has(row, ['customPrettyName'])
          ? row.customPrettyName
          : '';
      });
      this.vendorsCopy = copy;
      this.editPretty = true;
    },
    cancel() {
      this.vendors = _.cloneDeep(this.vendorsCopy);
      this.editPretty = false;
      this.tableKey++;
    },
    update() {
      this.isLoading = true;
      this.$updateVendorsBulk(
        this.vendorsCopy.map((v) => ({
          ...v,
          customPrettyName: v.customPrettyName
            ? v.customPrettyName.trim()
            : null,
        })),
      );
      this.isLoading = false;
      this.editPretty = false;
    },
    sortTable(obj) {
      if (obj.prop && obj.order) {
        this.order = `${obj.prop}${
          obj.order === 'descending' ? '.desc' : '.asc'
        }`;
      } else {
        this.order = '';
      }
      addGetParam('orderBy', this.order);
      this.loadList();
    },
    changeOffset(e) {
      this.offset = (e - 1) * this.limit;
      addGetParam('offset', this.offset);
      this.loadList();
    },
    changeLimit(e) {
      this.limit = e;
      addGetParam('limit', this.limit);
      this.loadList();
    },
    onFiltersChange(filters) {
      this.offset = 0;
      addGetParam('offset', this.offset);
      this.filters = filters;
      addGetParams(filters);
      this.loadList();
    },
    changePretty(customPrettyName, index) {
      this.vendorsCopy[index].customPrettyName = customPrettyName;
    },
    async loadList() {
      this.isLoading = true;
      const filters = {
        limit: this.limit,
        offset: this.offset,
        ...(this.filters.query.length && { query: this.filters.query }),
        ...(this.filters.blankCustomPrettyName && {
          blankPrettyName: this.filters.blankCustomPrettyName,
        }),
      };
      if (!_.isEmpty(this.order)) {
        filters.orderBy = [this.order];
      }
      this.$setFilters(filters);
      await this.$getCustomerVendors(this.$route.params.customerId);
      this.total = Number(this.$meta.total);
      this.isLoading = false;
    },
    goToVendorDetails({ id = 0 }) {
      if (this.editPretty) {
        return;
      }

      this.$router.push({
        name: 'customer.vendor.details',
        params: {
          customerId: this.$route.params.customerId,
          vendorId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  /deep/ .el-table__body-wrapper {
    overflow: visible;
  }

  /deep/ .el-table tr,
  .el-table .cell {
    overflow: visible;
  }

  /deep/ .el-table .cell {
    overflow: visible;
  }

  .input-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .input-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      flex-grow: 1;
    }

    .input-pretty-vendor {
      padding-right: 15px;
    }

    .input-length {
      width: 40px;
      padding-right: 10px;
    }
  }

  /deep/ .el-table th > .cell {
    height: 34px;
    line-height: 34px;
  }

  .info {
    margin: 10px 0 0 0;
    text-align: right;
    color: #696969;
    font-size: 16px;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .button {
    width: 170px;
    padding: 8px 15px;
  }

  .button-update {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 900;
    text-decoration: underline;
    color: #409eff;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: #66b1ff;
    }
  }

  &-vendors {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 80px);
    padding: 20px 40px 60px 40px;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;

    .header-button {
      height: 40px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      margin-left: auto;
      border: none;
      border-radius: 4px;
      background-color: #1989fa;
    }
  }

  &-title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
  }

  &-search {
    position: relative;
    margin-bottom: 10px;

    .icon-search {
      width: 40px;
      height: 40px;
      position: absolute;
      // TODO added search icon
      //background: url(./../assets/icons/search.svg) center center no-repeat;
      z-index: 1;
    }

    &-input {
      > input {
        border: none;

        /deep/ &.el-input__inner {
          padding: 0 44px;
        }
      }
    }
  }
}

.wrap-pretty-name {
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

/deep/ .pager-description {
  position: relative;
  font-size: 12px;
  color: #606266;
  display: inline;
  font-weight: 400;
}
</style>
