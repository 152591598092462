<template lang="pug">
  li.hierarchy-item
    .hierarchy-item-container(:class='{"is-parent": !node.parent_id}')
      .label(@click="handleExpand")
        span(v-if="node.name")
          i.el-icon-caret-right(v-if="!expanded")
          i.el-icon-caret-bottom(v-else)
        i.el-icon-location(v-else)
        span.node-name(:class='{"is-expanded": expanded}') {{ node.name || node.location.name }}
        el-checkbox(v-if="node.parent_id", @change="handleChange", :value="checked")
      ul(v-if="node.children && node.children.length && expanded")
        hierarchy-item(v-for="child in node.children",
        :key="child.id",
        :node="child",
        :nodeClick="nodeClick",
        :selectedLocations="selectedLocations",
        :handleSelect="handleSelect")
</template>

<script>
import _ from 'lodash';
/**
 * Push location to location list.
 */
const pushLocation = (list, node) =>
  _.isEmpty(node.location) ? list : [...list, node.location.id];

/**
 * Recursively collect all locations from node provided.
 */
const collectLocations = (node = {}, list = []) =>
  (node.children || []).map((child) =>
    child.children.length
      ? collectLocations(child, list)
      : pushLocation(list, child),
  );

export default {
  name: 'HierarchyItem',
  props: {
    node: {
      type: Object,
      required: true,
      default: () => {},
    },
    selectedLocations: {
      type: Array,
      required: true,
      default: () => [],
    },
    handleSelect: {
      type: Function,
      required: true,
      default: () => null,
    },
    nodeClick: {
      type: Function,
      required: false,
      default: (node) => null,
    },
  },
  data() {
    return {
      expanded: false,
      checked: false,
    };
  },
  watch: {
    selectedLocations(value) {
      this.updateData(value);
    },
  },
  mounted() {
    this.updateData(this.selectedLocations);
    if (!this.node.parent_id) {
      this.expanded = true;
    }
  },
  methods: {
    updateData(locations) {
      if (!_.isEmpty(this.node.location)) {
        this.checked = !!_.find(locations, (l) => l === this.node.location_id);
      } else {
        const ownLocations = _.flattenDeep(collectLocations(this.node));
        if (ownLocations.length) {
          /**
           * If node is groups and it has all locations checked,
           * check this node by default
           */
          this.checked = _.every(ownLocations, (item) =>
            _.find(locations, (l) => l === item),
          );
          /**
           * If node isn't root and it has some locations checked,
           * expand this node by default to show all child nodes
           */
          if (
            this.node.parent_id &&
            _.some(ownLocations, (item) => _.find(locations, (l) => l === item))
          ) {
            this.expanded = true;
          }
        }
      }
    },
    handleChange(value) {
      this.checked = value;
      const locations = this.selectedLocations.slice();
      const id = _.get(this.node, 'location.id', null);
      if (id) {
        this.handleSelect(
          this.checked ? [...locations, id] : _.without(locations, id),
        );
      } else {
        /**
         * Create temporary list of locations from `selectedLocations`
         * Add new locations to temporary list
         * or remove unchecked locations from temporary list
         * and pass it to parent component
         */
        const ownLocations = _.flattenDeep(collectLocations(this.node));
        this.handleSelect(
          this.checked
            ? [...locations, ...ownLocations]
            : _.without(locations, ...ownLocations),
        );
      }
    },
    handleExpand() {
      if (!this.node.location) {
        this.expanded = !this.expanded;
      }
      this.nodeClick(_.cloneDeep(this.node));
    },
  },
};
</script>

<style lang="scss" scoped>
.hierarchy-item {
  ul {
    list-style: none;
  }

  &-container {
    .label {
      cursor: pointer;
      margin-bottom: 9px;
      .node-name {
        color: #2c2e34;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        margin: 0 12px 0 4px;

        &.is-expanded {
          font-weight: 900;
        }
      }
    }

    &.is-parent {
      > .label {
        > .node-name {
          font-size: 18px;
          font-weight: 900;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
