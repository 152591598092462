<template lang="pug">
  .link-modal-content
    .link-modal-content-header Please select virtual account you want to link.
    el-table.with-shadow(:data="data",
    data-type="virtual-accounts-groups-list",
    width="100%",
    ref='table',
    :default-sort='{"prop": "address", "order": "descending"}',
    @row-click="selectRow",
    @selection-change="handleSelectionChange")
      el-table-column(type="selection")
      el-table-column(label='ID', prop='id', sortable)
      el-table-column(label='Vendor', prop='vendor_code', sortable)
      el-table-column(label='Billing ID', prop='client_account', sortable)
      el-table-column(label='Service Account ID', prop='account_code', width="180" sortable)
      el-table-column(label='Meter ID', prop='meter_serial', sortable)
      el-table-column(label='Utility', prop='commodity', sortable)
      el-table-column(label='Type', prop='bill_type', sortable)
    .modal-dialog_actions
      el-button.plain-button-reverse(@click='closeModal') Cancel
      el-button(type='primary', @click='handleSubmit', :loading="isFetching", :disabled="isEmptyVirtualAccounts") Link Virtual Accounts
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { propOr } from 'ramda';

export default {
  props: {
    selectedId: {
      required: true,
      type: Number,
      default: () => 0,
    },
    selectedItems: {
      required: true,
      type: Array,
      default: () => [],
    },
    isVisible: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteItems: [],
      isLoading: false,
    };
  },
  watch: {
    selectedId(val) {
      if (!val) return;
      this.fetchVirtualAccounts();
    },
    isVisible(val) {
      if (!val) return;
      if (!this.isLoading) this.fetchVirtualAccounts();
    },
  },
  computed: {
    ...mapGetters({
      data: 'virtual_accounts/data',
      isFetching: 'virtual_accounts/isFetching',
    }),
    isEmptyVirtualAccounts() {
      return propOr(0, 'length', this.data) === 0;
    },
  },
  async mounted() {
    await this.fetchVirtualAccounts();
  },
  methods: {
    ...mapActions({
      $fetchVirtualAccounts: 'virtual_accounts/fetchVirtualAccounts',
      $fetchGroups: 'virtual_accounts/fetchGroups',
      $linkItems: 'virtual_accounts/linkItems',
    }),
    async fetchVirtualAccounts() {
      this.isLoading = true;
      await this.$fetchVirtualAccounts({
        ...this.$route.params,
        va_group_id: this.selectedId,
      });
      this.$nextTick(() => {
        this.data.forEach((item) => {
          if (
            this.selectedItems.find(
              ({ virtualAccountGroupId }) =>
                virtualAccountGroupId === item.virtualAccountGroupId,
            )
          ) {
            this.$refs.table.toggleRowSelection(item);
          }
        });
        this.isLoading = false;
      });
    },
    selectRow(location) {
      this.$refs.table.toggleRowSelection(location);
    },
    async handleSubmit() {
      this.$emit('handleSubmit');
    },
    handleSelectionChange(data) {
      this.$emit('handleSelectionChange', data);
    },
    handleUnselect() {
      this.$refs.table.clearSelection();
    },
    closeModal() {
      this.$refs.table.clearSelection();
      this.$emit('close');
    },
  },
};
</script>
