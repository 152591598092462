<template>
  <div class="comment-input-container" v-if="activityHistoryChat">
    <VueTribute :options="options">
      <div
        class="comment-input"
        contenteditable="true"
        ref="commentInput"
        placeholder="@tag Type a message"
        @keydown="checkInputLimit"
        @keydown.enter="submit"
      />
      <el-button class="send-button" type="text" @click="createComment">
        <i class="icon-send" />
      </el-button>
    </VueTribute>
  </div>
</template>

<script>
import { bills, customers } from '@/api';
import * as R from 'ramda';
import VueTribute from 'vue-tribute';

import { mapState, mapActions } from 'vuex';
import _ from 'lodash';

const ENTER_KEY = 13;
const ALLOWED_KEYS = [8, 37, 38, 39, 40, 46];

export default {
  components: {
    VueTribute,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: mapState({
    customerId: (state) => state.customers.customer.id,
    billId: (state) => state.bills.bill.id,
    activityHistoryChat: (state) =>
      state.customers.customer.entitlements.activityHistoryChat,
    options() {
      return {
        trigger: '@',
        values: [...this.optionsValue],
        positionMenu: true,
      };
    },
    optionsValue() {
      return this.items;
    },
  }),
  data() {
    return {
      newComment: '',
      inputLimit: 600,
    };
  },
  methods: {
    ...mapActions({ addComment: 'bills/addComment' }),
    submit(e) {
      e.preventDefault();
      if (e.keyCode === ENTER_KEY && (e.ctrlKey || e.metaKey)) {
        document.execCommand('insertText', true, '\n');
        return;
      }
      this.createComment();
    },

    async createComment() {
      this.newComment = this.$refs.commentInput.innerText.trim();
      if (R.isEmpty(this.newComment)) {
        return;
      }
      const itemsValue = [];
      const itemsFound = this.items.filter((item) =>
        this.newComment.includes(item.key),
      );
      itemsFound.forEach((val) => itemsValue.push(val.key));
      const chatList = await customers.getChatListForCurrentCustomer(
        this.customerId,
      );
      const chatTags = chatList.data.results.filter(
        (value) =>
          itemsValue.includes(value.name) &&
          ['customerGroup', 'customerUser'].includes(value.type),
      );
      const comment = await bills.createComment(
        this.customerId,
        this.billId,
        this.newComment,
        chatTags,
      );
      const commentCamelCase = {
        ..._.mapKeys(comment, (value, key) => _.camelCase(key)),
        user: {
          ..._.mapKeys(comment.user, (value, key) => _.camelCase(key)),
          profile: {
            ..._.mapKeys(comment.user.profile, (value, key) =>
              _.camelCase(key),
            ),
          },
        },
      };
      this.addComment(commentCamelCase);
      this.$emit('newComment');
      this.$refs.commentInput.innerText = '';
    },
    checkInputLimit(e) {
      if (R.contains(e.keyCode, ALLOWED_KEYS)) {
        return;
      }

      if (this.$refs.commentInput.innerText.length >= this.inputLimit) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../../styles/constants.scss';

.comment-input-container {
  position: relative;
}
.comment-input {
  min-height: 40px;
  max-height: 61px;
  padding: 10px 24px 10px;
  margin: 0 -15px;
  font-size: 12px;
  border-top: solid 1px #dcdfe6;
  box-sizing: border-box;
  cursor: text;
  outline: none;

  @extend %scrollbar;

  &:empty::before {
    display: block;
    color: #9b9b9b;
    font-weight: 600;
    content: attr(placeholder);
  }
}
.send-button {
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
}
.icon-send {
  display: inline-block;
  width: 14px;
  height: 13px;
  background: url('../../../../../../assets/icons/send.svg') no-repeat;
}
</style>
<style lang="scss">
@import '../../../../../../styles/constants.scss';

.tribute-container {
  position: absolute;
  @extend %scrollbar;
  top: 0;
  left: 0;
  height: auto;
  max-height: 195px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 1000 !important;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(#000, 0.13);
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(#000, 0.13);
  background-clip: padding-box;
  overflow: hidden;
}
.tribute-container li {
  color: #222222;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.tribute-container li.highlight,
.tribute-container li:hover {
  background: #f5f7fa;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}
</style>
