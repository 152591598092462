import Bills from './List.vue';
import BillDetails from './Details/BillDetails.vue';
import Editor from './Editor/Editor.vue';
import BillEditor from './BillEditor/Editor.vue';
import Source from './Source.vue';

export default {
  path: 'bills',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: '<%= customer %> Bills',
      linkTemplate: '/customers/<%= id %>/bills',
      storeMappings: { customer: 'customers/name', id: 'customers/id' },
    },
  },
  children: [
    {
      path: '',
      name: 'customer.bills',
      component: Bills,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: ':billId',
      component: BillDetails,
      name: 'customer.bills.info',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: '<%= billId %>',
          storeMappings: { billId: 'bills/id' },
        },
      },
    },
    {
      path: ':billId/edit',
      component: Editor,
      name: 'customer.bill.edit',
      meta: {
        auth: true,
        showNavbar: false,
        showBreadcrumbs: false,
      },
    },
    {
      path: ':billId/editor',
      component: BillEditor,
      name: 'customer.bill.editor',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: '<%= billId %>',
          linkTemplate: '/customers/<%= id %>/bills/<%= billId %>',
          storeMappings: {
            customer: 'customers/name',
            id: 'customers/id',
            billId: 'bills/id',
          },
        },
      },
    },
    {
      path: ':billId/source',
      component: Source,
      name: 'customer.bill.source',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
  ],
};
