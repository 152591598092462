<template lang="pug">
  el-tooltip(v-if="error",
    :content="error",
    placement="top-start",
    :popper-class="`error-tooltip ${extraClass}`",
    :visible-arrow="false")
    i.icon.icon-question
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: null,
    },
    extraClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  width: initial;
}

.icon-question {
  cursor: pointer;
  padding-left: 9px;
}
</style>

<style lang="scss">
.icon {
  &:focus {
    outline: none;
  }
}
.el-tooltip__popper.error-tooltip {
  margin-left: 23px;
  margin-top: 10px;
  padding: 9px 13px;
  font-size: 13px;
  line-height: 18px;
  color: #f86259;
  border: 1px solid #bcb9b9;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(179, 179, 179, 0.5);
}
</style>
