import { bills } from '@/api';

const SET_WORKFLOW_STATES = 'set_workflow_states';
const SET_DATA_LOADING = 'set_loading';

export default {
  namespaced: true,

  state: {
    data: {
      loading: false,
      workflow_states: [],
    },
    values: {},
  },

  mutations: {
    [SET_DATA_LOADING]: (state, loading) => {
      state.data.loading = loading;
    },
    [SET_WORKFLOW_STATES]: (state, workflow_states) => {
      state.data.workflow_states = workflow_states;
    },
  },

  actions: {
    async setWorkflowStates({ commit }, workflow_states) {
      await commit(SET_WORKFLOW_STATES, workflow_states);
    },
    async getFilterData({ commit }, customerId) {
      await commit(SET_DATA_LOADING, true);
      let filterData = {};
      try {
        filterData = await bills.getFiltersData(customerId);
      } catch (err) {
        console.error('error while loading filters for bills');
      }
      for (let key in filterData) {
        try {
          await commit(`set${key}`, filterData[key]);
        } catch (err) {
          console.warn('missing filter value: ', key);
        }
      }
      await commit(SET_DATA_LOADING, false);
    },
  },
  getters: {
    statesLookup: (state) => {
      return state.data.workflow_states.reduce((acc, { value, name }) => {
        acc[value] = name;
        return acc;
      }, {});
    },
  },
};
