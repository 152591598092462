<template>
  <span class="full-width">
    <span v-if="attribute.attributeType === 'String'">
      <AttrInputString
        :editMode="editMode"
        :initialValue="attribute.attributeValue"
        @input-string="handleAttributeChange"
      />
    </span>
    <span v-if="attribute.attributeType === 'Numeric'">
      <AttrInputNumeric
        :editMode="editMode"
        :initialValue="attribute.attributeValue"
        @input-numeric="handleAttributeChange"
      />
    </span>
    <span v-if="attribute.attributeType === 'List'">
      <AttrListSelect
        :multiple="attribute.isMultiselect === true"
        :editMode="editMode"
        :initialValue="attribute.attributeValue"
        :options="attribute.listValues"
        @list-select="handleAttributeChange"
      />
    </span>
    <span v-if="attribute.attributeType === 'Date'">
      <AttrDatePicker
        :editMode="editMode"
        :initialValue="attribute.attributeValue"
        @input-date="handleAttributeChange"
      />
    </span>
  </span>
</template>

<script>
import AttrInputString from './AttrInputString.vue'
import AttrInputNumeric from './AttrInputNumeric.vue'
import AttrDatePicker from './AttrDatePicker.vue'
import AttrListSelect from './AttrListSelect.vue'

export default {
  components: {
    AttrInputString,
    AttrInputNumeric,
    AttrDatePicker,
    AttrListSelect,
  },
  props: {
    attribute: {
      type: Object,
      required: true,
    },
    editMode: Boolean,
  },
  methods: {
    handleAttributeChange(newVal) {
      if (
        this.attribute.attributeType === 'List' &&
        this.attribute.isMultiselect
      ) {
        newVal = newVal.join(';')
      }
      this.$emit('attribute-change', {
        id: this.attribute.id,
        value: newVal,
      })
    },
  },
}
</script>

<style scss scoped>
@import './style.scss';
</style>
