<template>
  <BaseModal :dialogVisible="dialogVisible" @close="close" :width="width">
    <template #content>
      <p class="message">
        Your Energy Star account is not yet setup. Would you like to create a
        new Energy Star account?
      </p>
    </template>
    <template #footer>
      <el-button class="button-size plain-button" @click="close"
        >Cancel</el-button
      >
      <el-button class="button-size" type="primary" @click="openEnergyStar"
        >Create Account</el-button
      >
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal';

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    customerFDGCode: {
      type: String,
    },
    width: {
      type: String,
      default: '',
    },
  },
  components: {
    BaseModal,
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    openEnergyStar() {
      this.$emit('open-energy-star');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-size {
  width: 35%;
}
.plain-button {
  color: #2893f9;
  border-color: #2893f9;
}
.message {
  margin: 0;
  text-align: center;
  word-break: break-word;
}
</style>
