<template>
  <div>
    <el-dialog
      :visible="showVirtualAccountsNotification"
      @close="handleHideVirtualAccountsNotification()"
      width="480px"
    >
      <virtual-accounts-notification
        @close="handleHideVirtualAccountsNotification()"
        :message="errorMessage"
      ></virtual-accounts-notification>
    </el-dialog>
    <el-dialog
      title="Remap Virtual Account(s)"
      :visible="isRemapOpen"
      @close="close()"
      width="80%"
    >
      <div>
        <p>Virtual accounts selected</p>
        <el-table
          :data="virtualAcc"
          :default-sort="{ prop: 'id', order: 'descending' }"
        >
          <el-table-column prop="id" label="ID" sortable />
          <el-table-column prop="vendorCode" label="Vendor" sortable />
          <el-table-column prop="clientAccount" label="Billing ID" sortable />
          <el-table-column
            prop="accountCode"
            label="Service Account ID"
            width="180"
            sortable
          />
          <el-table-column prop="meterSerial" label="Meter ID" sortable />
          <el-table-column prop="commodity" label="Utility" sortable />
          <el-table-column prop="billType" label="Type" sortable />
          <el-table-column prop="lastBillId" label="Last Bill" sortable>
            <template slot-scope="scope">
              <router-link
                :to="`/customers/${$route.params.customerId}/bills/${scope.row.lastBillId}`"
                >{{
                  scope.row.lastBillStatementDate | datetime('LL/dd/yyyy')
                }}</router-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <p>Please select the location you want to remap to:</p>
        <FilterInput
          :searchQuery="query"
          @onSearchUpdate="onSearchQueryChange"
          :showFilter="false"
          :boldText="true"
        />
        <div class="locations-container">
          <el-table
            v-if="locationsList.length"
            :data="locationsList"
            :default-sort="{ prop: 'id', order: 'descending' }"
          >
            <el-table-column prop="name" label="Description" sortable />
            <el-table-column prop="building_type" label="Type" sortable />
            <el-table-column prop="address" label="Service Address" sortable />
            <el-table-column prop="city" label="City" sortable />
            <el-table-column prop="postcode" label="State" sortable />
            <el-table-column prop="state" label="Zip" sortable />
            <el-table-column
              prop="square_feet"
              label="Area (Sq. Ft)"
              sortable
            />
            <el-table-column>
              <template slot-scope="scope">
                <el-radio v-model="locationRB" :label="scope.row.id"
                  >Remap</el-radio
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="buttons-container">
        <el-button @click="close()" class="plain-button button-size" plain
          >Cancel</el-button
        >
        <el-button
          :disabled="enableRemap"
          class="button-large"
          type="primary"
          @click="confirm()"
          >Remap to New Location</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { locations } from '@/api';
import FilterInput from '@/components/FilterInput';
import VirtualAccountsNotification from '../Modals/VirtualAccountsNotification';

export default {
  props: {
    isRemapOpen: {
      type: Boolean,
      default: () => false,
    },
    virtualAcc: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VirtualAccountsNotification,
    FilterInput,
  },
  data() {
    return {
      query: '',
      locationsList: [],
      locationRB: '',
      errorMessage: '',
      showVirtualAccountsNotification: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
      this.locationsList = [];
      this.query = '';
    },
    async confirm() {
      const { customerId } = this.$route.params;
      let virtualAccountIds = [];
      for (let va in this.virtualAcc) {
        virtualAccountIds.push(+this.virtualAcc[va].id);
      }
      const newLocationId = +this.locationRB;
      const body = {
        virtualAccountIds,
        newLocationId,
      };
      try {
        await locations.remapVirtualAccounts(customerId, body);
      } catch (e) {
        this.showVirtualAccountsNotification = true;
        this.errorMessage = e.response.data.error;
      }
      this.$emit('close');
      this.query = '';
    },
    onSearchQueryChange(query) {
      this.query = query;
      this.fetchLocationsList();
    },
    async fetchLocationsList() {
      this.locationRB = '';
      const { customerId } = this.$route.params;
      const { data = [] } = await locations.list(customerId, {
        query: this.query,
        limit: 50,
      });
      this.locationsList = data;
    },
    handleHideVirtualAccountsNotification() {
      this.showVirtualAccountsNotification = false;
      this.errorMessage = '';
    },
  },
  computed: {
    enableRemap() {
      if (this.locationRB) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
}
.locations-container {
  max-height: 22vw;
  overflow-y: scroll;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  .button-size {
    width: 15vw;
  }
  .button-large {
    width: 20vw;
  }
}
</style>
