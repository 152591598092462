import Bills from './Bill/router';
import CustomersView from './container.vue';
import Info from './Info.vue';
import Locations from './Locations/router';
import Hierarchies from './Hierarchies/router';
import Exports from './Exports/router';
import Uploads from './Uploads/router';
import Home from './Home.vue';
import Insights from './Insights/router';
import Exceptions from './Exceptions/router';
import Vendors from './Vendors/router';
import LocationsCustomAttributes from './Locations/CustomAttributes/router';
import VendorsCustomAttributes from './Vendors/CustomAttributes/router';

export default {
  path: '/customers',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    showBreadcrumbs: true,
    breadcrumb: {
      titleTemplate: 'Customers',
      linkTemplate: '/customers',
    },
  },
  children: [
    {
      path: '',
      name: 'customers',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
    {
      path: ':customerId',
      component: CustomersView,
      meta: {
        auth: true,
        breadcrumb: false,
      },
      children: [
        Bills,
        Locations,
        Exports,
        LocationsCustomAttributes,
        VendorsCustomAttributes,
        Hierarchies,
        Uploads,
        Insights,
        Exceptions,
        Vendors,
        {
          path: '',
          name: 'customer.home',
          component: Home,
          meta: {
            auth: true,
            showNavbar: true,
            showBreadcrumbs: true,
            breadcrumb: {
              titleTemplate: '<%= name %> Homepage',
              linkTemplate: '/customers/<%= id %>',
              storeMappings: { name: 'customers/name', id: 'customers/id' },
            },
          },
        },
        {
          path: 'info',
          component: Info,
          alias: '',
          name: 'customer.info',
          meta: {
            auth: true,
            showNavbar: true,
            showBreadcrumbs: true,
            breadcrumb: {
              titleTemplate: '<%= name %> Customer Info',
              linkTemplate: '/customers/<%= id %>',
              storeMappings: { name: 'customers/name', id: 'customers/id' },
            },
          },
        },
      ],
    },
  ],
};
