<template lang="pug">
  .comment-modal_container
    slot
      .comment-modal_title {{ title }}
      .comment-modal_content {{ text }}
      el-input(type="textarea", :rows="2", placeholder="add a comment (optionally)", v-model="comment")
      .comment-modal_actions
        el-button(@click='cancel' size="small") Cancel
        el-button(type='primary' size="small" @click='confirm') OK

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    comment: '',
  }),
  watch: {
    isVisible() {
      this.comment = '';
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.comment);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-modal {
  &_container {
    margin: 0 10px;
  }
  &_title {
    margin-top: -12px;
    height: 42px;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #273a58;
  }

  &_content {
    margin: 0 0 18px 0;
    display: flex;
    align-items: center;
    word-break: keep-all;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  &_actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .el-button {
      width: 170px;
      border-radius: 4px;
    }
  }
}
</style>
