import LocationsList from './List.vue';
import Location from './View.vue';

export default {
  path: 'locations',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: '<%= customer %> Locations',
      linkTemplate: '/customers/<%= id %>/locations',
      storeMappings: { customer: 'customers/name', id: 'customers/id' },
    },
  },
  children: [
    {
      path: '',
      name: 'customer.locations',
      component: LocationsList,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: ':locationId',
      component: Location,
      name: 'customer.locations.info',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: '<%= name %>',
          storeMappings: { name: 'locations/name' },
        },
      },
    },
  ],
};
