<template lang="pug">
  ul.hierarchy-tree_root.parent
    li.hierarchy-tree_children-item(:class='{"is-group": true, "is-opened": rootOpened, "is-parent": true }')
      .hierarchy-tree_children-item-container
        .edit-input-wrapper(v-if="selfEditingMode && editMode && item.id === selfEditingId", :class="{'has-error': !isValidName}")
          input(v-model="newName")
          .icons-wrapper
            el-button(icon="el-icon-circle-close", @click="disableSelfEditingMode")
            el-button(icon="el-icon-circle-check", @click="renameItem")
        .button-wrapper(v-else)
          el-button(type='text',
            :icon='icon',
            @click='handleClick',
            @dblclick="handleDoubleClick",
            :class='{"text-button": true, "is-selected": isSelected, "is-root": true}')
          span(@click="click") {{ item.name }}
        .hierarchy-tree_children-item-container-controls(v-if="isSelected && editMode")
           el-button(type="text" icon="el-icon-plus", class="plus-icon", v-if="!item.location_id")
            .add-item-popup
              .add-item-popup-triangle
              ul
                li(@click="openCreateLocationGroupModal") Add Child Location Group
                li(@click="openAssignLocationModal") Assign Locations
           el-button(type="text", icon="el-icon-close", @click="openDeleteModal")

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'TreeRoot',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    rootOpened: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      clicks: 0,
      delay: 250,
      timer: null,
      newName: '',
      selfEditingMode: false,
      isValidName: true,
    };
  },
  mounted() {
    this.newName = this.item.name;
  },
  computed: {
    ...mapState({
      selectedItemId: (state) => state.hierarchy.selectedItemId,
      data: (state) => state.hierarchy.data,
      locations: (state) => state.hierarchy.locations,
      editMode: (state) => state.hierarchy.editMode,
      selfEditingId: (state) => state.hierarchy.selfEditingId,
    }),
    isSelected() {
      return this.selectedItemId === this.item.id;
    },
    icon() {
      return this.rootOpened ? 'el-icon-caret-bottom' : 'el-icon-caret-right';
    },
  },
  methods: {
    ...mapActions({
      setSelfEditMode: 'hierarchy/setSelfEditMode',
      selectItem: 'hierarchy/selectItem',
      fetchHierarchy: 'hierarchy/fetchHierarchy',
      patchHierarchyItem: 'hierarchy/patchHierarchyItem',
    }),
    click(e) {
      this.clicks++;
      if (this.clicks === 1) {
        const self = this;
        this.timer = setTimeout(function () {
          self.clicks = 0;
          self.selectItem(self.item.id);
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.handleDoubleClick(e);
        this.clicks = 0;
      }
    },
    async renameItem() {
      let error = null;
      await this.patchHierarchyItem({
        params: { ...this.$route.params, itemId: this.item.id },
        body: { name: this.newName },
      }).catch(
        ({
          response: {
            data: { code },
          },
        }) => (error = code),
      );

      if (error && error === 409) {
        this.isValidName = false;
      } else {
        await this.$emit('fetchTree');
        this.isValidName = true;
        this.selfEditingMode = false;
        this.newName = '';
      }
    },
    handleDoubleClick() {
      if (this.editMode) {
        this.newName = this.item.name;
        this.setSelfEditMode(this.item.id);
        this.selfEditingMode = true;
      }
    },
    disableSelfEditingMode() {
      this.selfEditingMode = false;
      this.isValidName = true;
      this.setSelfEditMode(null);
    },
    handleClick(e) {
      this.$emit('changeRootState');
    },
    openCreateLocationGroupModal() {
      this.$emit('openCreateLocationGroupModal');
    },
    openAssignLocationModal() {
      this.$emit('openAssignLocationModal');
    },
    openDeleteModal() {
      this.$emit('openDeleteModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.hierarchy-tree_children {
  list-style: none;

  &-item {
    .button-wrapper {
      cursor: pointer;

      > span {
        color: #273a58;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }
    }
    .el-button.text-button {
      padding: 5px 0;
      color: #4a4a4a;
      text-decoration: none;
      font-size: 14px;
      line-height: 19px;

      &.is-location {
        color: #606266;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 100px;
        .el-button {
          color: #4a4a4a;
          padding: 0;
          margin-left: 8px;

          &.plus-icon {
            position: relative;

            .add-item-popup {
              position: absolute;
              top: 24px;
              left: -11px;
              display: none;
              z-index: 99;

              &-triangle {
                &::after,
                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  left: 10px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                }
                &::after {
                  border-color: transparent transparent #fff transparent;
                  border-width: 8px;
                  top: -14px;
                }

                &::before {
                  top: -15px;
                  left: 10px;
                  border-color: transparent transparent #e4e7ed transparent;
                  border-width: 8px;
                }
              }

              ul {
                padding: 13px 7px;
                box-shadow: 0 -1px 5px 0 #f0f0f0;
                border-radius: 5px;
                background: #fff;
                list-style: none;

                li {
                  text-align: left;
                  color: #222222;
                  font-size: 10px;
                  line-height: 20px;
                  padding: 0 7px;
                  border-radius: 5px;

                  &:hover {
                    background: #e4e7ed;
                  }
                }
              }
            }

            &:hover {
              .add-item-popup {
                display: block;
              }
            }
          }
        }
      }
    }
    &.is-group.is-opened {
      > .hierarchy-tree_children-item-container {
        color: #273a58;
        font-weight: 700;
      }
    }

    &.is-opened {
      .is-location.is-selected {
        color: #2893f9 !important;
      }
    }

    .edit-input-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &.has-error {
        position: relative;
        margin-bottom: 20px;

        &::after {
          position: absolute;
          top: 115%;
          left: 0;
          height: 20px;
          content: 'Please enter a unique name.';
          font-size: 12px;
          color: #f86259;
        }

        > input {
          border: 1px solid #f86259 !important;
        }
      }

      input {
        height: 30px;
        width: 200px;
        border: 1px solid #d8dce6;
        border-radius: 4px;
        background-color: #ffffff;
        box-sizing: border-box;
        color: #4a4a4a;
        font-size: 14px;
        line-height: 20px;
        text-indent: 10px;
      }

      .icons-wrapper {
        margin-left: 8px;
        display: flex;

        > button {
          padding: 0;
          border: 0;
          border-radius: 50%;
          font-size: 18px;

          &:last-child {
            margin-left: 8px;
            color: #409eff;
          }

          &:first-child {
            color: #c7cbd2;

            &:hover {
              color: #c7cbd2;
            }
          }
        }
      }
    }
  }
}
</style>
