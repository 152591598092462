import Vue from 'vue';
import Router from 'vue-router';
import * as R from 'ramda';
import { parseJwt } from '@/lib/helpers';
import { stringify, parse } from 'qs';
import { router as Login } from '@/pages/Login';
import { router as Customers } from '@/pages/Customers';
import { router as Observations } from '@/pages/Observations';
import { router as Vendors } from '@/pages/Vendors';
import { router as Reports } from '@/pages/Reports';
import { router as Users } from '@/pages/Users';
import { router as Bills } from '@/pages/Bills';
import { router as Errors } from '@/pages/Errors';

import store from '../store';
import { parseInt } from 'lodash/string';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  history: true,
  hashbang: false,
  parseQuery: parse,
  stringifyQuery: function () {
    const res = stringify(...arguments);
    return res ? `?${res}` : '';
  },
  routes: [
    {
      component: {
        template: '<div></div>',
      },
      path: '/error',
      children: [
        {
          path: '404',
          name: 'not.found',
          component: { template: '<div></div>' },
          meta: {
            title: 'Page not found',
            subtitle: 'This page does not exist.',
          },
        },
      ],
    },
    Login,
    Customers,
    Observations,
    Vendors,
    Reports,
    Users,
    Bills,
    Errors,
    {
      path: '/',
      redirect: '/bills/dashboard',
    },
    {
      path: '*',
      redirect: '/error/404',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const loaders = document.getElementsByClassName('el-loading-mask');
  if (loaders.length) {
    setTimeout(() => {
      const vue2AppContainer =
        document.getElementsByClassName('vue-2-main-app')[0];
      const body = document.getElementsByTagName('body')[0];
      for (const loader of loaders) {
        if (
          loader.clientWidth !== body.clientWidth &&
          vue2AppContainer.clientWidth < loader.clientWidth
        ) {
          loader.style.width = loader.clientWidth - 185 + 'px';
          loader.style.left =
            parseInt(loader.style.left.replace('px', '')) + 185 + 'px';
        }
      }
    }, 150);
  }
  store.commit('customers/setRecentCustomer', to.params.customerId);
  if (R.isNil(to.meta.auth)) {
    return next();
  }
  if (to.meta.auth === store.getters['user/isAuth']) {
    if (to.meta.auth && !store.getters['resources/checkResources']) {
      await store.dispatch('resources/setResources');
    }
    next();
  } else {
    if (to.path.indexOf('/auth') >= 0) {
      return next({ path: '/' });
    }
    if (store.getters['user/isAuth']) {
      return next({ path: to.path });
    }
    localStorage.setItem('redirect', to.fullPath);
    next({ path: `/auth?referer=${to.path}` });
  }
  if (R.isNil(from.name) && to.name === 'customer.uploads.confirm') {
    return next({ name: 'customer.uploads' });
  }
  const authToken = localStorage.getItem('authToken');
  const jwt = authToken ? parseJwt(authToken) : '';
  if (!jwt && router.currentRoute.fullPath !== '/auth/login') {
    store.dispatch('user/logout').then(() => next({ path: '/auth/login' }));
  }
});

export default router;
