<template lang="pug">
  div.form-container
    h1.title Welcome to
      b Pear.ai
    form.form(method='POST', action='/', @submit.prevent='submit')
      .form-error(v-if='error') {{ typeof error === 'string' ? error : 'Login or password is incorrect.' }}
      .form-control
        .form-icon.email_icon
        input(type='email', v-model='email', :class='{ active: !!email, "has-error": errors.has("email") }', v-validate='"required|email"', name='email', placeholder='Email')
        span.error-message(v-if='errors.has("email")') {{ errors.first('email') }}
      .form-control
        .form-icon.key_icon
        input(type='password', v-model='password', autocomplete='section-blue current-password', :class='{ active: !!password, "has-error": errors.has("password") }', v-validate='"required"', name='password', placeholder='Password')
        span.error-message(v-if='errors.has("password")') {{ errors.first('password') }}
      button.form-button(:disabled='!email || errors.has("email") || !password || errors.has("password")') Login
      <!--router-link.form-link(to='/auth/password/forgot') Forgot your password?-->
</template>

<script>
import { user } from '@/api';
import * as R from 'ramda';

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      error: false,
    };
  },
  methods: {
    submit() {
      if (
        !this.email ||
        this.errors.has('email') ||
        !this.password ||
        this.errors.has('password')
      )
        return;
      user
        .authenticate(this.email, this.password)
        .then(({ token = '', user = {}, refresh_token = '' }) => {
          this.error = false;
          this.$store
            .dispatch('user/login', { jwt: token, user, refresh_token })
            .then(() =>
              this.$router.replace(
                this.$router.history.current.query.referer || '/',
              ),
            );
        })
        .catch((e) => {
          this.error = R.path(['response', 'data', 'message'], e) || true;
        });
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_login';
</style>
