import { hierarchies as h } from '@/api';

export default {
  namespaced: true,
  state: {
    params: {},
    isFetching: false,
    data: [],
  },

  getters: {
    data: (state) => state.data,
    isFetching: (state) => state.isFetching,
    query: (state) => state.query,
  },

  mutations: {
    requestHierarchies(state) {
      state.isFetching = true;
    },
    receiveHierarchies(state, data = []) {
      state.data = data;
      state.isFetching = false;
    },
    addHierarchy(state, data = null) {
      if (data) {
        state.data.push(data);
      }
    },
    setParams(state, value) {
      state.params = value;
    },
  },

  actions: {
    async fetchHierarchies({ commit, state }, customerId) {
      commit('requestHierarchies');
      const { data } = await h.list(customerId, state.params);
      commit('receiveHierarchies', data);
      return data;
    },
    async createHierarchy({ commit }, { customerId, body }) {
      const data = await h.postHierarchy(customerId, body);
      commit('addHierarchy', data);
    },
    setParams({ commit }, value) {
      commit('setParams', value);
    },
  },
};
