<template>
  <div class="bill bill-editor" @click="isCreateBillVisible = false">
    <el-dialog
      class="editor-modal"
      :visible.sync="isEditorModalOpen"
      :modal="false"
      :show-close="false"
      top="25px"
      width="460px"
    >
      <editor-modal
        :modal-type="modalType"
        :myBill="billData.user_id === user.id"
        @close-editor-modal="closeEditorModal"
        @confirm-update="updateBill"
        @confirm-cancel="cancelUpdate"
      ></editor-modal>
    </el-dialog>
    <el-dialog
      class="editor-modal"
      :visible.sync="isEditorInfoModalOpen"
      :show-close="false"
      top="25px"
      width="420px"
    >
      <editor-info-modal
        :modal-type="infoModalType"
        @close-editor-modal="closeEditorInfoModal"
      ></editor-info-modal>
    </el-dialog>
    <div class="bill-header">
      <div class="bill-header-title">
        <router-link
          class="bill-detail link clickable"
          :to="{ name: 'customer.bills' }"
        >
          <i class="icon icon-arrow-pointing-to-right"></i>
          <span class="text">Edit Bill {{ $route.params.billId }}</span>
        </router-link>
        <div class="actions">
          <el-button
            class="plain-button-reverse"
            plain="plain"
            size="mini"
            @click="showModal('cancel', false)"
          >
            Cancel
          </el-button>
          <el-button
            :type="isBlocksEmpty() || !canSubmit ? 'disabled' : 'primary'"
            size="small"
            @click="showModal('update', false)"
            :disabled="isBlocksEmpty() || !canSubmit"
          >
            Update Bill
          </el-button>
        </div>
      </div>
    </div>
    <div class="bill-total-info" data-section="bill-total-info">
      <div class="bill-total-info__item">
        <label>Date Due:</label>
        <el-date-picker
          v-model="dueDateFormatted"
          :class="{ 'prefixed-el-input': true, 'has-error': !dueDateFormatted }"
          format="MMM dd, yyyy"
          type="date"
          size="small"
          :clearable="false"
        ></el-date-picker>
      </div>
      <div class="bill-total-info__item">
        <label>Current Charges:</label>
        <el-input
          v-model="bill['INFO_COST']"
          size="small"
          :class="{
            'prefixed-el-input': true,
            'has-error': invalidCurrentCharges,
          }"
        >
          <template v-slot:prefix>
            <div class="prefix-el-input">
              {{ nativeCurrencySymbol }}
            </div>
          </template>
        </el-input>
      </div>
      <div class="bill-total-info__item">
        <label>Past Due Amount:</label>
        <el-input
          v-model="bill['PRIORBALANCE']"
          class="prefixed-el-input"
          size="small"
        >
          <template v-slot:prefix>
            <div class="prefix-el-input">
              {{ nativeCurrencySymbol }}
            </div>
          </template>
        </el-input>
      </div>
      <div class="bill-total-info__item">
        <label>Total Amount Due:</label>
        <el-input
          v-model="bill['TOTALPAYAMOUNT']"
          size="small"
          :class="{
            'prefixed-el-input': true,
            'has-error': invalidTotalAmountDue,
          }"
        >
          <template v-slot:prefix>
            <div class="prefix-el-input">
              {{ nativeCurrencySymbol }}
            </div>
          </template>
        </el-input>
      </div>
    </div>
    <div class="errors-block">
      <p v-if="!dueDateFormatted">
        A Due Date is mandatory to be set. Bills without a Due Date can not be
        Reparsed.
      </p>
      <p v-if="invalidCurrentCharges">
        Current Charges do not match Billing Line Items.
        <span class="btn" @click="updateCurrentCharges">
          Update Current Charges
        </span>
      </p>
      <p v-if="invalidTotalAmountDue">
        Total Amount Due does not match Current Charges plus Past Due Amount.
        <span class="btn" @click="updateTotalAmount">
          Update Total Amount Due
        </span>
      </p>
    </div>
    <div class="bill-body">
      <div
        class="bill-collapse-container general-info"
        data-section="general-info"
      >
        <collapse-block title="General Information">
          <el-row>
            <el-col :span="12">
              <label>Invoice Number</label>
              <el-input v-model="bill['INVOICENUMBER']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Vendor</label>
              <bill-editor-select-vendor
                size="mini"
                :onSelectChange="selectVendor"
                :selected="bill['VENDORCODE']"
              ></bill-editor-select-vendor>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Invoice Date</label>
              <el-date-picker
                v-model="statementDataFormatted"
                format="MMM dd, yyyy"
                type="date"
                size="mini"
                :clearable="false"
              ></el-date-picker>
            </el-col>
            <el-col :span="12">
              <label>Provider Vendor ID</label>
              <el-input v-model="bill['IDVENDOR']" size="mini"></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Date Marked for Payment</label>
              <el-date-picker
                :value-format="'yyyy-MM-dd'"
                :disabled="dateMarkedForPaymentDisabled"
                v-model="billData.markedForPayment"
                ref="dateMarkedForPaymentRef"
                format="MMM dd, yyyy"
                type="date"
                size="mini"
                :clearable="true"
              ></el-date-picker>
              <el-tooltip
                placement="top"
                effect="light"
                popper-class="marked-for-payment-info"
              >
                <template slot="content">
                  You can update the "Date Marked for Payment" when the bill is
                  customer ready.
                </template>
                <i class="icon icon-info-circle"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="12">
              <label>Vendor Primary Billing ID</label>
              <el-input v-model="bill['CLIENTACCOUNT']" size="mini"></el-input>
            </el-col>
          </el-row>
        </collapse-block>
      </div>
      <div class="bill-collapse-container billing-address">
        <collapse-block title="Billing Addresses">
          <el-row>
            <el-col :span="12">
              <label class="bill-title">Bill To Address</label>
            </el-col>
            <el-col :span="4">
              <label class="bill-title">Remittance Address</label>
            </el-col>
            <el-col :span="8" :style="{ 'justify-content': 'flex-end' }">
              <el-button
                class="select-address"
                type="text"
                @click="selectAddress"
                :disabled="isVendorEmpty"
              >
                Select Address
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Address Line 1</label>
              <el-input v-model="bill['BILLTOADDRESS1']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Address Line 1</label>
              <el-input
                v-model="bill['REMITADDRESS1']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Address Line 2</label>
              <el-input v-model="bill['BILLTOADDRESS2']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Address Line 2</label>
              <el-input
                v-model="bill['REMITADDRESS2']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Address Line 3</label>
              <el-input v-model="bill['BILLTOADDRESS3']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Address Line 3</label>
              <el-input
                v-model="bill['REMITADDRESS3']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Address Line 4</label>
              <el-input v-model="bill['BILLTOADDRESS4']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Address Line 4</label>
              <el-input
                v-model="bill['REMITADDRESS4']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>City</label>
              <el-input v-model="bill['BILLTOCITY']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>City</label>
              <el-input
                v-model="bill['REMITCITY']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>State</label>
              <el-input v-model="bill['BILLTOSTATE']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>State</label>
              <el-input
                v-model="bill['REMITSTATE']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Postcode</label>
              <el-input v-model="bill['BILLTOZIP']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Postcode</label>
              <el-input
                v-model="bill['REMITZIP']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <label>Country</label>
              <el-input v-model="bill['BILLTOCOUNTRY']" size="mini"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Country</label>
              <el-input
                v-model="bill['REMITCOUNTRY']"
                size="mini"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
        </collapse-block>
        <div class="bill-collapse-container">
          <collapse-block title="Additional Information" :open="false">
            <observations-table
              @onChange="pushNewHiddenObservations"
              :data="billHiddenObservations"
              :block="undefined"
              :hidden="true"
              :observationList="observationNonBillingListFiltered"
              :uomsList="uomsList"
            ></observations-table>
          </collapse-block>
        </div>
      </div>
      <div v-if="!initializingBills" class="bill-filters-container">
        <bill-filters
          :billItemsFilters="billItemsFilters"
          v-show="isFiltersVisible"
          @change="filtersChange"
        ></bill-filters>
        <div class="bill-filters-common-actions">
          <div class="bill-filters--info">
            Showing {{ filteredItems.length }} of {{ billItems.length }} bill
            blocks
          </div>
          <div
            class="icon icon-collapse clickable"
            @click="collapse(true)"
            :class="{ active: isCollapsed }"
          ></div>
          <div
            class="icon icon-expand clickable"
            @click="collapse(false)"
            :class="{ active: !isCollapsed }"
          ></div>
          <el-button
            type="text"
            @click="isFiltersVisible = !isFiltersVisible"
            :class="{ active: isFiltersVisible }"
          >
            <span>Filters</span>
            <div
              class="icon icon-filters1"
              :class="{ active: isFiltersVisible }"
            ></div>
          </el-button>
        </div>
        <div class="freeze-header" v-if="billItems.length">
          <div class="freeze-header-col">&nbsp;</div>
          <div class="freeze-header-col col-freeze">
            <freeze-virtual-account
              title="Freeze All"
              :freezeReasons="freezeReasons"
              :vaFrozenReason="freezeAllVAReason"
              :vaFrozen="vaFrozen"
              :disabled="newBillBlocksPresent"
              @freezeVAChanged="freezeAllVAChanged($event)"
            ></freeze-virtual-account>
          </div>
        </div>
      </div>
      <div
        v-if="!initializingBills"
        class="bill-collapse-container service-account"
        v-for="(item, index) of billItems"
        :key="getBlockNum(item)"
      >
        <item
          :_initializing="billItemsOptions[index].new === true ? false : true"
          :nativeCurrency="nativeCurrency"
          v-show="filteredItems.includes(item)"
          :isCollapsed="billItemsOptions[index].isCollapsed"
          :itemObservations="item"
          @onInitialized="initializeItem(index)"
          @onChange="changeItem($event, index)"
          @onDeleteItem="deleteItem(index)"
          :observationList="observationList"
          :commoditiesList="commoditiesList"
          :observationNonBillingList="observationNonBillingList"
          :billTypesList="billTypesList"
          :uomsList="uomsList"
          :freezeReasons="freezeReasons"
          :disabled="index > Object.keys(billData.items).length - 1"
          :vaFrozenReason="getVAFrozenReason(getItemKey(item))"
          :vaFrozen="getVAFrozen(getItemKey(item))"
          @freezeVAChanged="freezeVAChanged($event, getItemKey(item))"
        ></item>
      </div>
      <el-popover
        v-if="!initializingBills"
        placement="top-start"
        width="185"
        trigger="manual"
        popper-class="create-bill-popover"
        :visible-arrow="false"
        v-model="isCreateBillVisible"
      >
        <div class="create-bill-buttons-wrapper">
          <div class="create-bill-button" @click="createEmptyBillBlock">
            New Bill Block
          </div>
          <div class="create-bill-divider"></div>
          <div class="create-bill-button" @click="showSelectBill">
            From Existing Bill Block
          </div>
        </div>
        <el-button
          class="create-bill-triger"
          slot="reference"
          type="primary"
          size="small"
          @click.stop="() => {}"
          @click="isCreateBillVisible = !isCreateBillVisible"
        >
          Create a new Bill Block
        </el-button>
      </el-popover>
      <select-remittance
        :is-visible="isLocationModalVisible"
        :vendor="vendor"
        :selectedLocation="selectedLocation"
        data-value="select-location"
        @onChange="selectLocation"
        @close="isLocationModalVisible = false"
      ></select-remittance>
      <select-bill-block
        :is-visible="isSelectBillVisible"
        :id="this.$route.params.billId"
        @onCreate="createBillBlock"
        @onClose="isSelectBillVisible = false"
      ></select-bill-block>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as R from 'ramda'
import { bills, observations, vendors, globalBills } from '@/api'
import { CollapseBlock } from '@/components'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DeletePrompt from '../DeletePrompt'
import Item from './Item'
import BillFilters from '../Details/components/BillFilters'
import ErrorModal from '../ErrorModal'
import HistoryModal from '../HistoryModal'
import BillEditorSelectVendor from './SelectVendor'
import SelectLocation from '@/pages/Customers/Locations/Controls/SelectLocation'
import SelectBillBlock from './SelectBillBlock'
import { ISODateFormat } from '@/lib'
import { DateTime } from 'luxon'
import EditorModal from '@/pages/Customers/Bill/Editor/EditorModal.vue'
import EditorInfoModal from '@/pages/Customers/Bill/Editor/EditorInfoModal'
import {
  emptyBillData,
  emptyBillBlock,
  addDefaultBillBlockObservations,
  isDefaultBillData,
  isDefaultBillBlockData,
  addDefaultValues,
} from './defaults'
import ObservationsTable from './Observations'
import SelectRemittance from '@/pages/Customers/Locations/Controls/SelectRemittance'
import moment from 'moment'
import FreezeVirtualAccount from './FreezeVirtualAccount'

export default {
  components: {
    CollapseBlock,
    BillFilters,
    Item,
    ErrorModal,
    HistoryModal,
    DeletePrompt,
    BillEditorSelectVendor,
    SelectLocation,
    SelectBillBlock,
    EditorModal,
    EditorInfoModal,
    ObservationsTable,
    SelectRemittance,
    FreezeVirtualAccount,
  },
  data() {
    return {
      initializingBills: true,
      disabled: true,
      filters: {},
      isFiltersVisible: false,
      isCollapsed: false,
      bill: {},
      billData: {},
      originalBillData: {},
      billItems: [],
      billItemsOptions: [],
      billObservations: [],
      billHiddenObservations: [],
      billNewHiddenObservations: [],
      isLoading: true,
      isEditorModalOpen: false,
      isEditorInfoModalOpen: false,
      infoModalType: null,
      observationList: [],
      observationNonBillingList: [],
      itemsSubtotal: [],
      itemsObservation: [],
      isLocationModalVisible: false,
      isSelectBillVisible: false,
      selectedLocation: {},
      freezeVADirty: false,
      unFreezeVADirty: false,
      isCreateBillVisible: false,
      modalType: null,
      vendor: {},
      dateMarkedForPaymentDisabled: null,
      freezeVirtualAccounts: {
        items: {},
        selectedAll: false,
      },
      unFreezeVirtualAccounts: {
        items: {},
        selectedAll: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      customerId: 'customers/id',
      billId: 'bills/id',
      getResourceByName: 'resources/getResourceByName',
      locations: 'locations/locations',
      vendorsList: 'vendors/list',
      entitlements: 'customers/entitlements',
      user: 'user/user',
      freezeReasons: 'virtual_accounts/freezeReasons',
    }),
    nativeCurrency() {
      return this.billData.nativeCurrency
    },
    getItemKey() {
      return item => {
        const billItem = this.mapItemWithBillItem(item)
        return billItem ? +billItem.id : !billItem ? +item[0].block : null
      }
    },
    nativeCurrencySymbol() {
      return !this.nativeCurrency || this.nativeCurrency === 'USD'
        ? '$'
        : this.nativeCurrency
    },
    canSubmit() {
      return (
        (!this.disabled || this.canFreezeBill) &&
        !this.invalidTotalAmountDue &&
        !this.invalidCurrentCharges
      )
    },
    billItemsFilters() {
      return this.billItems.reduce((resultBillItem, billItem) => {
        resultBillItem.push(
          billItem.reduce((resultItem, item) => {
            switch (item.code) {
              case 'METERSERIAL':
                resultItem.meter_serial = item.value
                break
              case 'COMMODITY':
                resultItem.commodity = item.value
                break
              case 'ACCOUNTCODE':
                resultItem.account_code = item.value
                break
              case 'BILLTYPE':
                resultItem.bill_type = item.value
                break
              case 'SERVICEADDRESS':
                resultItem.service_address = item.value
                break
            }
            return resultItem
          }, {}),
        )
        return resultBillItem
      }, [])
    },
    filteredItems() {
      if (
        Object.keys(this.filters).length === 0 &&
        this.billItemsOptions.length
      ) {
        this.billItemsOptions = this.billItemsOptions.map(billItemsOption => {
          billItemsOption.ignoreFilters = false
          billItemsOption.filteredBy = []
          return billItemsOption
        })
      }
      return this.billItems.filter((billItem, index) => {
        const billItemsFilters = this.billItemsFilters
        if (
          this.billItemsOptions[index].ignoreFilters === true &&
          this.billItemsOptions[index].filteredBy &&
          this.billItemsOptions[index].filteredBy.length
        ) {
          this.billItemsOptions[index].ignoreFilters =
            this.billItemsOptions[index].filteredBy.filter(filter => {
              return (
                this.filters[filter.key] &&
                this.filters[filter.key].includes(filter.value)
              )
            }).length > 0 ||
            this.billItemsOptions[index].filteredBy.length === 0
          if (!this.billItemsOptions[index].ignoreFilters) {
            this.billItemsOptions[index].filteredBy.splice(0)
          }
        }
        var filter =
          Object.keys(this.filters).reduce((truthy, filterKey) => {
            const result = this.filters[filterKey].includes(
              billItemsFilters[index][filterKey],
            )
            if (result) {
              const _filter = {
                value: billItemsFilters[index][filterKey],
                key: filterKey,
              }
              const reducedFilteredBy = this.billItemsOptions[
                index
              ].filteredBy.filter(filterBy => {
                return (
                  filterBy.key !== _filter.key ||
                  (filterBy.value !== _filter.value &&
                    filterBy.key === _filter.key)
                )
              })
              this.billItemsOptions[index].filteredBy.splice(0)
              this.billItemsOptions[index].filteredBy.push(
                ...reducedFilteredBy,
                _filter,
              )
            }
            return truthy && result
          }, true) || this.billItemsOptions[index].ignoreFilters === true

        if (!filter) {
          this.billItemsOptions[index].ignoreFilters = false
          this.billItemsOptions[index].filteredBy = []
        }
        return filter
      })
    },
    observationNonBillingListFiltered() {
      return R.reject(R.either(isDefaultBillData, isDefaultBillBlockData))(
        this.observationNonBillingList,
      )
    },
    uomsList: {
      get() {
        return _.get(this.getResourceByName('units'), 'value', [])
      },
    },
    commoditiesList: {
      get() {
        return _.get(this.getResourceByName('commodities'), 'value', [])
      },
    },
    billTypesList: {
      get() {
        return ['Full Service', 'Supply Only', 'Distribution Only']
      },
    },
    currentCharges() {
      return this.itemsSubtotal
        .filter(v => !R.isNil(v))
        .reduce((acc, v) => acc + v, 0)
        .toFixed(2)
    },
    invalidCurrentCharges() {
      return (
        this.itemsSubtotal.length > 0 &&
        +R.pathOr(0, ['INFO_COST'], this.bill) !== +this.currentCharges
      )
    },
    invalidTotalAmountDue() {
      const currentCharges = +R.pathOr(0, ['INFO_COST'], this.bill)
      const pastDueAmount = +R.pathOr(0, ['PRIORBALANCE'], this.bill)
      const totalCharges = +R.pathOr(0, ['TOTALPAYAMOUNT'], this.bill)
      return (
        this.itemsSubtotal.length > 0 &&
        totalCharges.toFixed(2) !== (pastDueAmount + currentCharges).toFixed(2)
      )
    },
    dueDateFormatted: {
      get() {
        return this.bill['DUEDATE'] ? ISODateFormat(this.bill['DUEDATE']) : null
      },
      set(value) {
        this.bill['DUEDATE'] = value
          ? DateTime.fromJSDate(value)
              .setZone('UTC', { keepLocalTime: true })
              .toFormat('yyyyLLdd')
              .toString()
          : ''
      },
    },
    statementDataFormatted: {
      get() {
        return this.bill['STATEMENTDATE']
          ? ISODateFormat(this.bill['STATEMENTDATE'])
          : null
      },
      set(value) {
        this.bill['STATEMENTDATE'] = value
          ? DateTime.fromJSDate(value)
              .setZone('UTC', { keepLocalTime: true })
              .toFormat('yyyyLLdd')
              .toString()
          : ''
      },
    },
    isVendorEmpty() {
      return R.isEmpty(this.vendor)
    },
    defaultFreezeReason() {
      if (this.freezeReasons.length > 0) {
        let defaultFreezeReason = this.freezeReasons[0].id
        this.freezeReasons.forEach(freezeReason => {
          if (freezeReason.isDefault) {
            defaultFreezeReason = freezeReason.id
          }
        })
        return defaultFreezeReason
      }
    },
    freezeAllVAReason() {
      const mergedItems = this.getMergedVAItems()
      let reason =
        this.vaFrozen && !this.newBillBlocksPresent
          ? undefined
          : this.defaultFreezeReason
      if (!reason) {
        mergedItems.find(item => {
          if (!reason) {
            reason = item.frozenReasoningId
          } else if (item.frozenReasoningId !== reason) {
            reason = this.defaultFreezeReason
            return true
          }
          return false
        })
      }
      return reason || this.defaultFreezeReason
    },
    vaFrozen() {
      return Object.keys(this.unFreezeVirtualAccounts.items).length === 0
    },
    newBillBlocksPresent() {
      return this.getMergedVAItems().length > this.billData.items.length
    },
    canFreezeBill() {
      const canFreeze =
        this.freezeVADirty &&
        Object.keys(this.freezeVirtualAccounts.items).length > 0
      const canUnFreeze =
        this.unFreezeVADirty &&
        Object.keys(this.unFreezeVirtualAccounts.items).length > 0
      return canFreeze || canUnFreeze
    },
  },
  watch: {
    bill: {
      deep: true,
      handler(value) {
        if (this.bill.initialized) {
          this.disabled = false
        }
      },
    },
    'billData.markedForPayment': {
      handler(value) {
        if (this.bill.initialized) {
          this.disabled = false
        }
      },
    },
    freezeVirtualAccounts: {
      deep: true,
      handler(value) {
        if (this.bill.initialized) {
          this.freezeVADirty = true
        }
        let keys = Object.keys(this.freezeVirtualAccounts.items)
        for (let i = 0, len = keys.length; i < len; i++) {
          if (this.unFreezeVirtualAccounts.items[keys[i]]) {
            delete this.unFreezeVirtualAccounts.items[keys[i]]
          }
        }
      },
    },
    unFreezeVirtualAccounts: {
      deep: true,
      handler(value) {
        if (this.bill.initialized) {
          this.unFreezeVADirty = true
        }
        let keys = Object.keys(this.unFreezeVirtualAccounts.items)
        for (let i = 0, len = keys.length; i < len; i++) {
          if (this.freezeVirtualAccounts.items[keys[i]]) {
            delete this.freezeVirtualAccounts.items[keys[i]]
          }
        }
      },
    },
  },
  async mounted() {
    await this.loadBillData()
    await this.loadBill()
    await this.loadObservations()
    await this.loadNonBillingObservations()
    const filters = {
      limit: 300,
      offset: 0,
    }
    this.setLocationsFilters(filters)
    await this.getLocations(this.customerId)
    this.initializingBills = false

    // wrap the date marked for payment input to show the clear "x" button on input as well
    const dateMarkedForPaymentRef = this.$refs.dateMarkedForPaymentRef
    const old_dateMarkedForPaymentRef_handleKeydown =
      dateMarkedForPaymentRef.handleKeydown
    dateMarkedForPaymentRef.handleKeydown = (...attrs) => {
      old_dateMarkedForPaymentRef_handleKeydown(...attrs)
      setTimeout(() => {
        if (
          dateMarkedForPaymentRef.userInput &&
          dateMarkedForPaymentRef.userInput.length
        ) {
          dateMarkedForPaymentRef.showClose = true
        } else {
          dateMarkedForPaymentRef.showClose = false
        }
      }, 0)
    }

    dateMarkedForPaymentRef.handleMouseEnter = () => {
      if (
        dateMarkedForPaymentRef._props.readonly ||
        dateMarkedForPaymentRef._computedWatchers.pickerDisabled.getter()
      )
        return
      if (
        (!!dateMarkedForPaymentRef._props.value ||
          !!dateMarkedForPaymentRef.userInput) &&
        dateMarkedForPaymentRef._props.clearable
      ) {
        dateMarkedForPaymentRef.showClose = true
      }
    }
    await this.getFreezeReasons()
    this.billData.items.forEach(item => {
      if (item.vaFrozenAt) {
        this.freezeVirtualAccounts.items[+item.id] = {
          frozenAt: item.vaFrozenAt,
          virtualAccountId: +item.virtualAccountId,
          itemKey: +item.id,
          frozenReasoningId: this.freezeReasons.find(reason => {
            return reason.name === item.vaFrozenReason
          }).id,
        }
      } else {
        this.unFreezeVirtualAccounts.items[+item.id] = {
          frozenAt: null,
          virtualAccountId: +item.virtualAccountId,
          itemKey: +item.id,
          frozenReasoningId: null,
        }
      }
    })
    this.freezeVirtualAccounts = JSON.parse(
      JSON.stringify(this.freezeVirtualAccounts),
    )
    this.unFreezeVirtualAccounts = JSON.parse(
      JSON.stringify(this.unFreezeVirtualAccounts),
    )

    this.$nextTick(() => {
      // we need to bypass the watchers and their subsequent changes
      this.bill.initialized = true
    })
  },
  created() {
    window.addEventListener('beforeunload', this.handleTabClose)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleTabClose)
  },
  methods: {
    ...mapMutations({
      setLocationsFilters: 'locations/setFilters',
    }),
    ...mapActions({
      listHistory: 'bills/listHistory',
      putBill: 'bills/putBill',
      putBillV3: 'bills/putBillV3',
      getLocations: 'locations/getLocations',
      getFreezeReasons: 'virtual_accounts/getFreezeReasons',
      updateBill: 'bills/updateBill',
    }),
    getMergedVAItems() {
      return [
        ...Object.keys(this.freezeVirtualAccounts.items).map(
          key => this.freezeVirtualAccounts.items[key],
        ),
        ...Object.keys(this.unFreezeVirtualAccounts.items).map(
          key => this.unFreezeVirtualAccounts.items[key],
        ),
      ]
    },
    filtersChange(filters) {
      this.filters = filters
    },
    collapse(isCollapsed) {
      this.billItemsOptions = this.billItemsOptions.map(item => {
        item.isCollapsed = { isCollapsed, time: Date.now() }
        return item
      })
      this.isCollapsed = isCollapsed
    },
    async loadBillData() {
      const { billId = 0, customerId } = this.$route.params
      this.billData = await bills.get(customerId, billId)
      if (this.billData.customerReady) {
        const markedForPaymentToUtc = new Date(this.billData.markedForPayment)
        this.dateMarkedForPaymentDisabled = moment(
          moment().add(-1, 'days'),
        ).isAfter(markedForPaymentToUtc)
      } else {
        this.dateMarkedForPaymentDisabled = true
      }
      this.originalBillData = JSON.parse(JSON.stringify(this.billData))
    },
    isBlocksEmpty() {
      return R.isEmpty(this.billItems) || !this.dueDateFormatted
    },
    async loadObservations() {
      const { data } = await observations.list({
        limit: 1000,
        offset: 0,
        category: 'not.eq.Non-billing',
      })
      this.observationList = data
    },
    handleTabClose(event) {
      if (!this.closeTabFromModal) {
        event.preventDefault()
        event.returnValue = 'Are you sure?'
        return 'Are you sure?'
      }
    },
    async loadNonBillingObservations() {
      const { data } = await observations.list({
        limit: 1000,
        offset: 0,
        category: 'eq.Non-billing',
      })
      this.observationNonBillingList = data
    },

    async loadBill() {
      const { billId = 0, customerId } = this.$route.params
      this.isLoading = true
      await this.listHistory({ customerId, billId })
      return bills
        .getBillObservation(customerId, billId)
        .then(response => {
          let data = response.data.map(currentResponse => {
            if (!currentResponse.value) {
              currentResponse.value = ''
            }
            return currentResponse
          })
          let tempItems
          data = R.pipe(this.removeDuplicates, addDefaultValues)(data)
          if (R.type(data) === 'Array') {
            const bill = this.billLevel(data)
            emptyBillData().forEach(o => {
              if (R.isNil(bill[o.code])) {
                if (R.has('type')(o)) {
                  o.value = o.description
                }
                data.push(o)
              }
            })
            tempItems = this.itemsLevel(data)
            this.billItemsOptions = tempItems.reduce((total, current) => {
              total.push({
                filteredBy: [],
                isCollapsed: {
                  isCollapsed: false,
                  time: Date.now(),
                },
              })
              return total
            }, [])

            this.billItems = tempItems
            this.billItems.forEach((current, index) => {
              this.billItems[index].virtualAccountId = this.billData.items[
                index
              ].virtualAccountId
            })
            this.bill = this.billLevel(data)
            this.billObservations = R.filter(
              R.both(R.pipe(R.has('block'), R.not), isDefaultBillData),
            )(data)
            const hidden = R.filter(
              R.both(
                R.pipe(R.has('block'), R.not),
                R.pipe(isDefaultBillData, R.not),
              ),
            )(data)
            this.billHiddenObservations = hidden
            this.billNewHiddenObservations = hidden
          }
        })
        .catch(console.error.bind(console))
        .finally(() => {
          this.isLoading = false
        })
    },
    billLevel(observations = []) {
      const topLevel = R.filter(R.compose(R.not, R.has('block')))
      const codeValue = obs => [R.prop('code', obs), R.propOr('', 'value', obs)]
      const codeCharge = obs => [
        R.prop('code', obs),
        R.propOr('', 'charge', obs),
      ]
      const charges = R.pipe(
        topLevel,
        R.filter(R.propEq('type', 'C')),
        R.map(codeCharge),
        R.fromPairs,
      )
      const nonCharges = R.pipe(
        topLevel,
        R.filter(R.pipe(R.has('type'), R.not)),
        R.map(codeValue),
        R.fromPairs,
      )
      return R.merge(nonCharges(observations), charges(observations))
    },
    itemsLevel(observation = []) {
      const items = R.pipe(
        R.filter(R.has('block')),
        R.groupBy(R.prop('block')),
        R.values,
      )(observation)
      return this.sortBillBlocks(items)
    },
    sortBillBlocks(items) {
      const valueByCode = code =>
        R.pipe(R.find(R.propEq('code', code)), R.pathOr('', ['value']))

      const firstSort = item => {
        const commodity = valueByCode('COMMODITY')(item)
        return R.cond([
          [R.equals('ELECTRIC'), () => 1],
          [R.equals('NATURALGAS'), () => 2],
          [R.T, () => 3],
        ])(commodity)
      }

      const endDateSort = item => {
        const date = valueByCode('ENDDATE')(item)
        return DateTime.fromFormat(date, 'yyyyLLdd').valueOf()
      }

      return R.sortWith([
        R.ascend(firstSort),
        R.ascend(valueByCode('COMMODITY')),
        R.ascend(valueByCode('ACCOUNTCODE')),
        R.ascend(valueByCode('METERSERIAL')),
        R.descend(endDateSort),
      ])(items)
    },
    newBillBlockId() {
      let id = 1
      this.billItems.forEach(item => {
        const block = Number(R.pathOr(1, ['block'], item[0]))
        id = block >= id ? block + 1 : id
      })
      return id
    },
    selectVendor(vendorId) {
      const { code } = R.find(R.propEq('id', vendorId))(this.vendorsList)
      this.bill['VENDORCODE'] = code || ''
      this.getVendorByCode(code)
    },
    cancel() {
      this.$router.push({
        name: 'customer.bills.info',
        params: { billId: this.$route.params.billId },
      })
    },
    updateCurrentCharges() {
      this.bill['INFO_COST'] = this.currentCharges
    },
    updateTotalAmount() {
      const currentCharges = +_.get(this.bill, ['INFO_COST'], 0)
      const pastDueAmount = +_.get(this.bill, ['PRIORBALANCE'], 0)
      this.bill['TOTALPAYAMOUNT'] = (pastDueAmount + currentCharges).toFixed(2)
    },
    selectAddress() {
      this.isLocationModalVisible = true
    },
    selectLocation(location) {
      this.selectedLocation = location
      const { remittance_address } = location
      const {
        city,
        line_1,
        line_2,
        line_3,
        line_4,
        post_code,
        state,
        country,
      } = remittance_address
      this.bill['REMITADDRESS1'] = line_1 || ''
      this.bill['REMITADDRESS2'] = line_2 || ''
      this.bill['REMITADDRESS3'] = line_3 || ''
      this.bill['REMITADDRESS4'] = line_4 || ''
      this.bill['REMITZIP'] = post_code || ''
      this.bill['REMITCITY'] = city || ''
      this.bill['REMITSTATE'] = state || ''
      this.bill['REMITCOUNTRY'] = country || ''
    },
    showSelectBill() {
      this.isCreateBillVisible = false
      this.isSelectBillVisible = true
    },
    createBillBlock(items) {
      this.isCreateBillVisible = false
      this.isSelectBillVisible = false
      items.forEach(item => {
        const block = this.newBillBlockId()
        item = item.map(o => ({ ...o, block }))
        item = addDefaultValues(item)
        this.disabled = false
        this.billItemsOptions.push({
          filteredBy: [],
          isCollapsed: {
            isCollapsed: false,
            time: Date.now(),
          },
        })
        this.billItems.push(addDefaultBillBlockObservations(block, item))
      })
    },
    createEmptyBillBlock() {
      this.isCreateBillVisible = false
      const id = this.newBillBlockId()
      const addDefaultsFromBillLevel = block => {
        const defaults = ['VENDORCODE', 'CONTROLCODE']
        return block.map(row => {
          const { code } = row
          if (defaults.includes(code)) {
            row.value = R.propOr('', code, this.bill)
          }
          return row
        })
      }
      this.disabled = false
      this.billItemsOptions.push({
        new: true,
        ignoreFilters: true,
        filteredBy: [],
        isCollapsed: {
          isCollapsed: false,
          time: Date.now(),
        },
      })
      const newBillBlock = addDefaultsFromBillLevel(emptyBillBlock(id))
      this.freezeVirtualAccounts.items[+id] = { ...newBillBlock, itemKey: +id }
      this.billItems.push(newBillBlock)
      this.freezeVAChanged(
        {
          reasonId: this.freezeVAReason,
          freezeVA: false,
        },
        id,
      )
    },
    initializeItem(index) {
      this.billItemsOptions[index].initialized = true
    },
    changeItem(data, index) {
      this.itemsSubtotal[index] = data.reduce(
        (acc, val) => acc + (+val.charge || 0),
        0,
      )
      this.itemsSubtotal = [...this.itemsSubtotal]
      this.itemsObservation[index] = data

      if (this.billItemsOptions[index].initialized === true) {
        this.disabled = false
      }
      if (
        Object.keys(this.filters).length &&
        this.billItemsOptions[index].filteredBy.length
      ) {
        this.billItemsOptions[index].ignoreFilters = true
      }
    },
    showModal(type) {
      this.modalType = type
      this.isEditorModalOpen = !this.isEditorModalOpen
    },
    closeEditorModal() {
      this.isEditorModalOpen = !this.isEditorModalOpen
    },
    cancelUpdate() {
      this.closeTabFromModal = true
      this.showInfoModal('canceled')
    },
    showInfoModal(type) {
      this.infoModalType = type
      this.isEditorInfoModalOpen = !this.isEditorInfoModalOpen
    },
    closeEditorInfoModal() {
      this.isEditorInfoModalOpen = false
      const channel = new BroadcastChannel('closeEdit')
      channel.postMessage('close')
      window.open('', '_parent', '')
      window.close()
    },
    async unassign() {
      const { billId } = this.$route.params
      const action = 'reassign'
      const data = {
        [action]: {
          billsIds: [billId],
          assignee: 0,
        },
      }
      await this.runBulkAction(data, 'Release')
    },
    updateStorage() {
      try {
        const { billId } = this.$route.params
        const editor = localStorage.getItem('editor')
        const data = editor ? [...JSON.parse(editor), billId] : [billId]
        localStorage.setItem('editor', JSON.stringify(data))
      } catch (e) {
        console.log(`can't parse editor in the localStorage`)
      }
    },
    async updateBill(comment, unassign = false) {
      const { customerId, billId } = this.$route.params
      const body = this.observationsFromBill()
      this.isLoading = true
      try {
        const bodyForUpdate = {}
        if (
          this.billData.markedForPayment !==
          this.originalBillData.markedForPayment
        ) {
          if (this.billData.markedForPayment) {
            bodyForUpdate.markedForPayment = ISODateFormat(
              this.billData.markedForPayment,
            )
          }
        }
        if (this.canFreezeBill) {
          if (
            Object.keys(this.freezeVirtualAccounts.items).length > 0 &&
            this.freezeVADirty
          ) {
            let frozenItems = Object.keys(this.freezeVirtualAccounts.items)
              .map(key => this.freezeVirtualAccounts.items[key])
              .filter(item => {
                const originalItem = this.originalBillData.items.find(
                  originalItem => originalItem.id === item.itemKey,
                )
                return (
                  originalItem &&
                  (!!originalItem.vaFrozenAt !== !!item.frozenAt ||
                    this.vaFrozenReasonId(originalItem.vaFrozenReason) !==
                      item.frozenReasoningId)
                )
              })
            if (frozenItems.length) {
              bodyForUpdate.freezeVirtualAccounts = {
                items: frozenItems,
                selectedAll: this.freezeVirtualAccounts.selectedAll,
              }
            }
          }
          if (
            Object.keys(this.unFreezeVirtualAccounts.items).length > 0 &&
            this.unFreezeVADirty
          ) {
            let unfrozenItems = Object.keys(this.unFreezeVirtualAccounts.items)
              .map(key => this.unFreezeVirtualAccounts.items[key])
              .filter(item => {
                const originalItem = this.originalBillData.items.find(
                  originalItem => originalItem.id === item.itemKey,
                )
                return (
                  originalItem &&
                  (!!originalItem.vaFrozenAt !== !!item.frozenAt ||
                    !!originalItem.vaFrozenReason !== !!item.frozenReasoningId)
                )
              })
            if (unfrozenItems.length) {
              const freezableBills = []
              for (const item of this.billItems) {
                const freezableBillId = this.mapItemWithBillItem(item)
                  .accountCode
                if (freezableBills.indexOf(freezableBillId) === -1) {
                  freezableBills.push(freezableBillId)
                }
              }
              bodyForUpdate.unFreezeVirtualAccounts = {
                items: unfrozenItems,
                selectedAll:
                  !this.unFreezeVirtualAccounts.selectedAll &&
                  freezableBills.length === unfrozenItems.length,
              }
            }
          }
        }
        if (Object.keys(bodyForUpdate).length) {
          await this.putBillV3({
            customerId,
            billId,
            body: bodyForUpdate,
          })
        }
        if (this.canSubmit && !this.isBlocksEmpty()) {
          await this.putBill({ customerId, billId, body, comment })
        }
        if (unassign) {
          await this.unassign()
          await this.loadBillData()
        }
        this.closeTabFromModal = true
        this.showInfoModal('updating')
      } catch (e) {
        console.error(e)
        this.$message({
          showClose: true,
          message: `Something went wrong. Please try again later...`,
          type: 'error',
        })
      } finally {
        this.isEditorModalOpen = false
        this.isLoading = false
      }
    },
    async runBulkAction(bulkData, label) {
      try {
        await globalBills.bulkActions(bulkData)
        this.$message({ message: `${label} successful.`, type: 'success' })
        this.updateStorage()
      } catch (e) {
        this.$message.error('Please try again latter.')
      }
    },
    observationsFromBill() {
      const billTop = this.billObservations
        .filter(R.pipe(R.has('type'), R.not))
        .map(observation => {
          observation.value = String(
            R.pathOr(observation.value, [observation.code], this.bill),
          )
          return R.clone(observation)
        })
      const billCharge = this.billObservations
        .filter(R.propEq('type', 'C'))
        .map(observation => {
          observation.charge = String(
            R.pathOr(observation.charge, [observation.code], this.bill),
          )
          return R.clone(observation)
        })
      const items = this.itemsObservation
        .filter(R.pipe(R.isEmpty, R.not))
        .reduce(R.concat)
      const body = [
        billTop,
        billCharge,
        items,
        this.billNewHiddenObservations,
      ].reduce(R.concat)

      const isEmpty = R.either(R.isNil, R.isEmpty)
      // const isValueEmpty = R.pipe(R.prop('value'), isEmpty);
      const isCEmpty = R.both(
        R.propEq('type', 'C'),
        R.pipe(R.prop('charge'), isEmpty),
      )
      const isUEmpty = R.both(
        R.propEq('type', 'U'),
        R.pipe(R.prop('usage'), isEmpty),
      )
      const isCodeEmpty = R.pipe(R.prop('code'), isEmpty)
      const isUCEmpty = R.both(
        R.propEq('type', 'UC'),
        R.either(
          R.pipe(R.prop('usage'), isEmpty),
          R.pipe(R.prop('charge'), isEmpty),
        ),
      )
      const isChargeEmpty = v => {
        const { charge } = v
        return (
          R.propEq('code', 'PRIORBALANCE')(v) &&
          (R.isEmpty(charge) || R.isNil(charge) || Number(charge) === 0)
        )
      }
      const isAnyEmpty = v =>
        isCEmpty(v) ||
        isUEmpty(v) ||
        isUCEmpty(v) ||
        isChargeEmpty(v) ||
        isCodeEmpty(v)

      const castUC = o => {
        if (R.has('charge', o)) {
          o.charge = parseFloat(o.charge)
        }
        if (R.has('usage', o)) {
          o.usage = parseFloat(o.usage)
        }
        return o
      }

      const trimValue = o => {
        const fields = [
          'block',
          'description',
          'code',
          'type',
          'value',
          'usage',
          'uom',
          'charge',
          'currency',
        ]
        fields.forEach(field => {
          if (R.has(field, o) && typeof o[field] === 'string') {
            o[field] = o[field].trim()
          }
        })
        return o
      }
      return R.pipe(R.reject(isAnyEmpty), R.map(castUC), R.map(trimValue))(body)
    },
    deleteItem(index) {
      const unFrozenLength = Object.keys(this.unFreezeVirtualAccounts.items)
        .length
      const itemKey = this.getItemKey(this.billItems[index])
      const frozenItem = this.freezeVirtualAccounts.items[itemKey]
      if (!frozenItem) {
        if (unFrozenLength === 1) {
          this.freezeVAChanged(
            {
              reasonId: null,
              freezeVA: true,
            },
            itemKey,
          )
        }
      }
      for (let i = 0; i < this.billData.items.length; i++) {
        if (this.billData.items[i].id === itemKey) {
          this.billData.items.splice(i, 1)
        }
      }
      delete this.freezeVirtualAccounts.items[itemKey]
      delete this.unFreezeVirtualAccounts.items[itemKey]
      this.disabled = false
      this.billItems.splice(index, 1)
      this.itemsObservation.splice(index, 1)
      this.itemsSubtotal.splice(index, 1)
    },
    pushNewHiddenObservations(value) {
      this.billNewHiddenObservations = value
    },
    async getVendorByCode(code) {
      if (R.isEmpty(code) || R.isNil(code)) {
        return
      }
      try {
        this.vendor = await vendors.vendorByCode(code)
        const response = await vendors.providersByCode({
          query: this.vendor.code,
        })
        this.bill['IDVENDOR'] = response.results[0].providerId
      } catch (e) {
        console.error(e)
      }
    },
    removeDuplicates(data) {
      const isTotal = R.both(
        R.propEq('code', 'TOTALPAYAMOUNT'),
        R.compose(R.not, R.has('block')),
      )
      const indexes = data.reduce((acc, value, index) => {
        if (isTotal(value)) {
          acc.push(index)
        }
        return acc
      }, [])
      indexes.shift()
      return data.filter((v, i) => !indexes.includes(i))
    },
    getBlockNum(item) {
      return R.pathOr(0, [0, 'block'], item)
    },
    freezeAllVAChanged(event) {
      for (let i = 0, len = this.billItems.length; i < len; i++) {
        if (this.billItems[i]) {
          this.freezeVAChanged(event, this.getItemKey(this.billItems[i]))
        }
      }
      this.freezeVirtualAccounts.selectedAll = event.freezeVA === true
      this.unFreezeVirtualAccounts.selectedAll = event.freezeVA === false
    },
    freezeVAChanged(event, virtualAccountId) {
      if (event.freezeVA) {
        const prevItem =
          this.unFreezeVirtualAccounts.items[virtualAccountId] ||
          this.freezeVirtualAccounts.items[virtualAccountId]
        this.getMergedVAItems()
          .filter(
            item =>
              item.virtualAccountId &&
              item.virtualAccountId === prevItem.virtualAccountId,
          )
          .forEach(item => {
            this.freezeVirtualAccounts.items[item.itemKey] = {
              frozenAt: new Date(),
              virtualAccountId: item.virtualAccountId,
              itemKey: item.itemKey,
              frozenReasoningId:
                event.reasonId != null
                  ? event.reasonId
                  : item.frozenReasoningId,
            }
            delete this.unFreezeVirtualAccounts.items[item.itemKey]
          })
      } else {
        const prevItem =
          this.freezeVirtualAccounts.items[virtualAccountId] ||
          this.unFreezeVirtualAccounts.items[virtualAccountId]
        this.getMergedVAItems()
          .filter(
            item =>
              (item.virtualAccountId &&
                item.virtualAccountId === prevItem.virtualAccountId) ||
              item.itemKey === prevItem.itemKey,
          )
          .forEach(item => {
            this.unFreezeVirtualAccounts.items[item.itemKey] = {
              frozenAt: null,
              virtualAccountId: item.virtualAccountId,
              itemKey: item.itemKey,
              frozenReasoningId: null,
            }
            delete this.freezeVirtualAccounts.items[item.itemKey]
          })
      }
      this.freezeVirtualAccounts = JSON.parse(
        JSON.stringify(this.freezeVirtualAccounts),
      )
      this.unFreezeVirtualAccounts = JSON.parse(
        JSON.stringify(this.unFreezeVirtualAccounts),
      )
    },
    vaFrozenReasonId(reasonName) {
      if (this.freezeReasons.length > 0) {
        let reasonId
        this.freezeReasons.forEach(freezeReason => {
          if (freezeReason.name === reasonName) {
            reasonId = freezeReason.id
          }
        })
        return reasonId
      }
    },
    getVAFrozen(key) {
      let result = false
      if (this.freezeVirtualAccounts.items[key]) {
        result = !!this.freezeVirtualAccounts.items[key].frozenAt
      }
      return result
    },
    getVAFrozenReason(key) {
      let result = 1
      if (this.freezeVirtualAccounts.items[key]) {
        result = this.freezeVirtualAccounts.items[key].frozenReasoningId
      }
      return result
    },
    mapItemWithBillItem(blockItem) {
      let startDate = ''
      let accountCode = ''
      let meterSerial = ''
      let rateCode = ''
      let endDate = ''
      let virtualAccountId = ''
      Object.keys(blockItem).forEach(key => {
        if (blockItem[key].code === 'ACCOUNTCODE') {
          accountCode = blockItem[key].value
        }
        if (blockItem[key].code === 'STARTDATE') {
          startDate = blockItem[key].value
        }
        if (blockItem[key].code === 'METERSERIAL') {
          meterSerial = blockItem[key].value
        }
        if (blockItem[key].code === 'RATECODE') {
          rateCode = blockItem[key].value
        }
        if (blockItem[key].code === 'ENDDATE') {
          endDate = blockItem[key].value
        }
        if (key === 'virtualAccountId') {
          virtualAccountId = blockItem[key]
        }
      })

      return this.billData.items.find(item => {
        if (
          item.accountCode === accountCode &&
          item.rateCode === rateCode &&
          item.startDate.replace(/-/g, '') === startDate.replace(/-/g, '') &&
          item.endDate.replace(/-/g, '') === endDate.replace(/-/g, '') &&
          item.meterSerial === meterSerial &&
          item.virtualAccountId === virtualAccountId
        ) {
          return item
        }
      })
    },
  },
}
</script>
<style lang="scss">
.el-input {
  &.prefixed-el-input {
    .el-input__inner {
      padding-left: 32px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../../../styles/constants.scss';

.bill-editor {
  .bill-filters-common-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 0 0;
    text-align: right;

    .icon {
      margin: 0 7px;
    }

    .el-button {
      margin-left: 24px;
      text-decoration: none;
      color: #273a58;

      &.active,
      &:hover {
        color: #267de2;
      }
    }

    .bill-filters--info {
      flex-grow: 1;
      padding-left: 20px;
      text-align: left;
      font-size: 12px;
      color: #666666;
    }
  }

  .bill-header {
    min-height: 60px;
    position: absolute;
    right: 4px;
    left: 248px;
    padding-left: 32px;
    padding-right: 40px;
    z-index: 1;
    background-color: #f6f6f6;
    padding-bottom: 10px;
    padding-top: 25px;
  }

  .errors-block {
    font-size: 14px;
    color: #f50b0b;
  }

  .btn {
    display: inline-block;
    padding: 0 7px;
    cursor: pointer;
    color: #2893f9;
    text-decoration: underline;
  }

  .el-input,
  .el-select {
    width: 220px;

    .prefix-el-input {
      color: #606266;
      min-width: 26px;
      max-width: 26px;
      text-align: right;
    }
  }

  .has-error {
    position: relative;

    &:after {
      position: absolute;
      top: -10px;
      right: -10px;
      content: '\e7a3';
      font-family: element-icons !important;
      speak: none;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      color: #f91515;
      line-height: 1;
      vertical-align: baseline;
      background-color: #ffffff;
      border-radius: 50%;
    }

    /deep/ input {
      border: 1px solid #fb1c12;
    }
  }

  /deep/ {
    .collapse {
      .header {
        background-color: #ffffff;
        border: 2px solid #273a58;
        border-radius: 5px 5px 0 0;

        &-title {
          color: #273a58;
        }
      }
    }

    .icon {
      &-arrow {
        background-image: url('../../../../assets/icons/block-arrow.svg');
      }
    }

    .el-col:first-child {
      label {
        padding-left: 60px;
      }
    }
  }

  /deep/ label {
    display: inline-block;
    padding: 0 15px;
    font-size: 12px;
    line-height: 100%;
    white-space: nowrap;

    &.bill-title {
      font-size: 14px;
      font-weight: 600;
    }
  }

  /deep/ .el-col {
    &:first-child {
      label {
        width: 140px;
        min-width: 140px;
      }
    }

    label {
      width: 140px;
    }
  }

  /deep/ .el-row {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .general-info,
  .billing-address {
    /deep/ .content {
      padding: 20px;
    }
  }

  .billing-address {
    .el-col {
      display: flex;
      align-items: center;

      &:first-child {
        padding-right: 20px;
      }
    }

    .el-input {
      flex-grow: 1;
      max-width: initial;
      flex-basis: 1%;
    }
  }

  .select-address {
    color: #2893f9;
    font-size: 12px;
  }
}

.bill {
  padding: 0px 40px 84px 40px;
  height: calc(100vh - 105px);

  @extend %scrollbar;

  &.with-comments {
    margin-right: 240px;
  }

  &-header {
    &-title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;

      .bill-detail {
        flex: 1 0 auto;
        display: flex;
        align-items: center;

        .text {
          font-size: 20px;
          font-weight: bold;

          @media (max-width: 1200px) {
            font-size: 0;
          }
        }
      }

      .status {
        flex: none;
        display: flex;
        align-items: center;
        margin-left: 30px;

        @media (max-width: 1200px) {
          margin-left: 10px;
        }

        &-item {
          color: $primary;
          font-size: 16px;
          font-weight: 700;
          text-transform: capitalize;

          @media (max-width: 1500px) {
            font-size: 14px;
            line-height: 20px;
          }
          @media (max-width: 1200px) {
            font-size: 12px;
          }

          &:first-child::after {
            float: right;
            display: block;
            content: '';
            width: 1px;
            height: 20px;
            margin: 0 10px;
            background-color: #273a58;
          }
        }

        &-actions {
          flex: none;
          margin-right: auto;
          margin-left: 20px;

          @media (max-width: 1200px) {
            margin-left: 10px;
          }

          .el-button {
            padding: 4px 20px;
            font-size: 14px;
            font-weight: 600;

            @media (max-width: 1500px) {
              font-size: 12px;
            }
          }
        }
      }

      .actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-collapse {
          display: flex;
          background-color: #ffffff;
          min-height: 24px;
          min-width: 24px;
          align-items: center;
          justify-content: center;
          box-shadow: 0 2px 6px 3px rgba(225, 223, 223, 0.65);
          border-radius: 24px;

          &-icon {
            font-size: 10px;
          }
        }

        .el-dropdown {
          cursor: pointer;
        }

        .el-button:not(.button-comments) {
          padding: 7px 30px;
          font-weight: 600;
        }

        .el-button:not(.button-comments):not(.button-error) {
          @media (max-width: 1500px) {
            padding-right: 15px;
            padding-left: 15px;
            font-size: 12px;
          }
        }

        .el-button:not(.el-button--text):not(.is-plain) {
          background-color: $primary;
        }
      }
    }
  }

  .bill-total-info {
    display: flex;
    justify-content: space-between;
    margin-top: 110px;

    &__item {
      &:first-child {
        margin-left: -15px;
      }

      label {
        font-size: 14px;
      }
    }

    label {
      padding-right: 0;
    }

    .el-input {
      margin-left: 15px;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  &-body {
    .bill-collapse {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;

      &-block {
        min-width: calc(100% / 3);

        &-title {
          font-weight: 500;
        }

        &-value {
          font-weight: 600;
          padding-left: 10px;
        }
      }

      &-container {
        margin: 10px 0px;
      }

      &-content {
        display: flex;
        padding: 0 20px;

        &-data {
          width: 50%;

          &:nth-child(odd) {
            margin-right: 60px;
          }

          .bill-field-container {
            display: inline-flex;
            width: 100%;
            padding-left: 20px;
            box-sizing: border-box;

            &.highlighted:nth-child(even) {
              background-color: $concrete;
            }

            .bill-field-key {
              width: 40%;
              font-size: 12px;
              line-height: 20px;
            }

            .bill-field-value {
              font-weight: 600;
              width: 50%;
              font-size: 12px;
              line-height: 20px;
            }

            .bill-title {
              width: 100%;
              margin-bottom: 5px;
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.create-bill {
  &-buttons-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-triger {
    margin-top: 25px;
  }

  &-button {
    padding: 0 10px;
    height: 40px;
    color: #0f0f0f;
    font-size: 14px;
    line-height: 40px;
    cursor: pointer;
  }

  &-divider {
    width: 100%;
    height: 1px;
    background-color: #c6c6c6;
  }
}
</style>

<style lang="scss">
@import '../../../../styles/constants.scss';

.create-bill {
  &-popover {
    padding: 0;
    border-radius: 0 0 4px 0;
    background-color: #ffffff;
    box-shadow: 0 6px 12px 0 rgba(164, 164, 164, 0.5);
  }
}

.bill-collapse-container {
  &.general-info,
  &.billing-address {
    .header {
      height: auto !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &.service-account {
    .header {
      height: auto !important;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }
}

.actions {
  .button-error {
    padding-right: 0 !important;
    padding-left: 0 !important;

    span {
      text-decoration: underline;
    }
  }
}

.icon-no-error {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid $success;
  box-sizing: border-box;

  &::before {
    display: inline-block;
    width: 10px;
    height: 8px;
    line-height: 1px;
    content: '';
    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='10px' height='8px' viewBox='0 0 10 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cg id='Customer_bill_validation' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-694.000000, -61.000000)'%3E %3Cg id='No-errors' transform='translate(689.000000, 52.000000)' fill='%2343B493'%3E %3Cg id='exclamation-mark-inside-a-circle-copy' transform='translate(0.000000, 3.000000)'%3E %3Cg id='checked' transform='translate(5.000000, 6.000000)'%3E %3Cg id='Shape'%3E %3Cpath d='M0.115384615,4.4 C0.0384615385,4.32 0,4.2 0,4.12 C0,4.04 0.0384615385,3.92 0.115384615,3.84 L0.653846154,3.28 C0.807692308,3.12 1.03846154,3.12 1.19230769,3.28 L1.23076923,3.32 L3.34615385,5.68 C3.42307692,5.76 3.53846154,5.76 3.61538462,5.68 L8.76923077,0.12 L8.80769231,0.12 C8.96153846,-0.04 9.19230769,-0.04 9.34615385,0.12 L9.88461538,0.68 C10.0384615,0.84 10.0384615,1.08 9.88461538,1.24 L3.73076923,7.88 C3.65384615,7.96 3.57692308,8 3.46153846,8 C3.34615385,8 3.26923077,7.96 3.19230769,7.88 L0.192307692,4.52 L0.115384615,4.4 Z' fill-rule='nonzero'%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  }
}

.icon-error {
  font-style: normal;

  &::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    content: '!';
    color: $danger;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    border: 1px solid $danger;
    box-sizing: border-box;
  }
}

.icon-comments {
  font-style: normal;

  &::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    content: '';
    background: url('../../../../assets/icons/comments.svg') no-repeat;
  }
}

.button-comments {
  span {
    display: flex;
    align-items: center;
  }
}

.el-button {
  &.button-error,
  &.button-comments {
    span {
      @media (max-width: 1400px) {
        font-size: 0;

        i,
        > span {
          font-size: 16px;
        }
      }
    }
  }
}

.editor-modal {
  .el-dialog__header {
    padding: 0;
  }

  &_content {
    text-align: center;
  }

  &_actions {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .el-button {
      width: 78px;
      padding-top: 7px;
      padding-bottom: 7px;
      border-radius: 100px;
      font-size: 12px;
      font-weight: 500;

      &.btn-close {
        width: 92px;
      }
    }
  }

  .el-dialog__body {
    padding: 30px;
  }
}

.el-tooltip__popper.is-light.marked-for-payment-info {
  width: 140px;
  padding: 8px;
}

.freeze-header {
  background-color: #ffffff;
  height: 36px;
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px 0;
  text-align: right;

  &-col {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-wrap: wrap;

    &.col-freeze {
      text-align: right;
      width: 220px;
      flex-basis: 220px;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      padding-right: 211px;
    }
  }
}
</style>
