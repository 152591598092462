import AttributesList from './List.vue';

export default {
  path: 'custom_attributes',
  name: 'customer.custom_attributes',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: '<%= customer %> Custom Attributes',
      linkTemplate: '/customers/<%= id %>/custom_attributes',
      storeMappings: { customer: 'customers/name', id: 'customers/id' },
    },
  },
  children: [
    {
      path: 'locationAttributes',
      name: 'customer.custom_attributes_locations',
      component: AttributesList,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: 'vaAttributes',
      name: 'customer.custom_attributes_virtual_accounts',
      component: AttributesList,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
  ],
};
