<template lang="pug">
  .location-control_container
    .location-control_search
      el-input(v-model="query", prefix-icon="el-icon-search", placeholder="Search")
    .location-control_list
      el-button.location-control_list-item(v-for="(location, index) in filteredLocations",
        :class='{"is-active": checkSelection(location.id)}',
        :key="index",
        type="primary",
        plain,
        @click="handleSelection(location)")
        span.location-address {{ location.address }}
        span.location-state {{ location.city }}, {{ location.state }}
</template>

<script>
import { filter, contains, join, values, pathOr } from 'ramda';

export default {
  props: ['locations', 'storedLocation'],
  data() {
    return {
      query: '',
      selectedLocation: {},
    };
  },
  mounted() {
    this.selectedLocation = this.storedLocation;
  },
  watch: {
    storedLocation() {
      this.selectedLocation = this.storedLocation;
    },
  },
  computed: {
    filteredLocations() {
      return filter((location) => {
        return contains(
          this.query.toLowerCase(),
          join(' ', values(location)).toLowerCase(),
        );
      }, this.locations);
    },
  },
  methods: {
    checkSelection(locationId) {
      return pathOr(null, ['id'], this.selectedLocation) === locationId;
    },
    handleSelection(location) {
      this.selectedLocation = location;
      this.$emit('select-locations', this.selectedLocation);
    },
  },
};
</script>

<style lang="scss" scoped>
.location-control_search {
  padding: 0 20px 20px;

  /deep/ .el-input__icon {
    color: #909399;
  }
}

.location-control_list {
  padding: 0 16px 20px 20px;
  max-height: 58vh;
  -webkit-overflow-scrolling: touch;

  .el-button {
    display: flex;
    padding: 0;
    border-radius: 5px;
    border-color: #2893f9;

    /deep/ #{'>'} span {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      text-align: left;
      width: 100%;
      height: 54px;
      padding: 0 28px;
    }

    &:hover {
      > span > span {
        color: #fff;
      }
    }

    &.is-active {
      color: #ffffff;
      background-color: #0080ff;

      .location-address,
      .location-state {
        color: inherit;
      }
    }
  }
}

.location-control_list-item {
  display: block;
  width: 100%;
  min-height: 54px;
  background-color: transparent;

  + .location-control_list-item {
    margin: 10px 0 0;
  }
}

.location-address {
  color: #2c2e34;
}

.location-state {
  margin-top: 5px;
  color: #7d89a6;
  font-size: 10px;
}
</style>
