<template>
  <div class="edit-modal-content">
    <el-row>
      <el-col class="inputLabel" :span="9"><span>Account Opened:</span></el-col>
      <el-col class="dateInputField" :span="10">
        <el-date-picker
          v-model="dateOpened"
          :format="'MM/dd/yyyy'"
          value-format="yyyy-MM-dd"
          type="date"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="inputLabel" :span="9"><span>Account Closed:</span></el-col>
      <el-col class="dateInputField" :span="10">
        <el-date-picker
          v-model="dateClosed"
          :format="'MM/dd/yyyy'"
          value-format="yyyy-MM-dd"
          type="date"
        ></el-date-picker>
      </el-col>
    </el-row>
    <div class="modal-dialog_actions">
      <el-button class="plain-button-reverse" @click="closeEditModal"
        >Cancel</el-button
      >
      <el-button
        :disabled="!dateOpened || !dateClosed"
        type="primary"
        @click="handleEditSubmit"
        :loading="isFetching"
        >Confirm</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    row: {
      required: false,
      type: Object,
      default: () => null,
    },
    isVisible: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateOpened: null,
      dateClosed: null,
    };
  },
  watch: {
    row(val) {
      if (!val) {
        this.dateOpened = null;
        this.dateClosed = null;
        return;
      }
      this.dateOpened = val.accountOpened;
      this.dateClosed = val.accountClosed;
    },
  },
  computed: {
    ...mapGetters({
      data: 'virtual_accounts/data',
      isFetching: 'virtual_accounts/isFetching',
    }),
  },
  async mounted() {
    this.dateOpened = this.row.accountOpened;
    this.dateClosed = this.row.accountClosed;
  },
  methods: {
    async handleEditSubmit() {
      this.$emit('handleEditSubmit');
    },
    closeEditModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
