<template lang="pug">
  .insight-modal-content
    el-table.insight-status.with-shadow(:data='insightStatus', :cell-class-name='statusClassName', @sort-change='sortTable')
      el-table-column(label='Recipient', prop='recipient')
      el-table-column(label='Notification Methods', prop='channel')
      el-table-column(label='Status', prop='state', sortable, width='120')
    .insight-actions
      el-button(type='primary', @click='$emit("close-modal")') Ok
</template>

<script>
import { insights } from '@/api';

export default {
  props: {
    rowId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      insightStatus: [],
      order: '+id',
    };
  },
  mounted() {
    this.loadInsightStatus();
  },
  watch: {
    rowId() {
      this.loadInsightStatus();
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.url);
    },
    sortTable(obj) {
      if (obj.prop && obj.order) {
        this.order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      } else {
        this.order = '';
      }

      this.loadInsightStatus();
    },
    statusClassName({ row, columnIndex }) {
      if (columnIndex === 2 && row.state === 'delivered') {
        return 'state success';
      }
      if (
        columnIndex === 2 &&
        (row.state === 'sent' || row.state === 'submitted')
      ) {
        return 'state warning';
      }
      if (columnIndex === 2 && row.state === 'undelivered') {
        return 'state danger';
      }
    },
    async loadInsightStatus() {
      const { items = [] } = await insights.insightStatus(
        this.$route.params.customerId,
        this.rowId,
        {
          orders: [this.order],
        },
      );
      this.insightStatus = [...items];
    },
  },
};
</script>

<style lang="scss" scoped>
.insight {
  &-fields {
    color: #606266;
    font-size: 14px;
    line-height: 19px;
  }
  &-field {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    label {
      min-width: 132px;
    }
  }
  &-message {
    label {
      align-self: flex-start;
      margin-top: 8px;
    }
  }
  &-url {
    .el-button {
      width: 142px;
      margin-right: 15px;
    }
  }
  &-actions {
    margin-top: 30px;
    text-align: center;

    .el-button {
      width: 240px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
</style>

<style lang="scss">
@import '../../../styles/constants.scss';

.insight-status {
  th:first-child,
  td:first-child {
    .cell {
      padding-left: 40px;
    }
  }
  th.is-sortable {
    padding: 3px 0 2px;
  }
  td {
    padding: 11px 0 10px;
  }
  .el-dialog__body {
    padding: 30px 36px 40px 40px;
  }
  .el-table__body-wrapper {
    max-height: 320px;
    @extend %scrollbar;
  }
  .state {
    font-weight: 600;
    text-transform: capitalize;
  }
  .success {
    color: #5cb87a;
  }
  .warning {
    color: #ddcf17;
  }
  .danger {
    color: #f86259;
  }
}
</style>
