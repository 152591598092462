import { home } from '@/api';

export default {
  namespaced: true,
  state: {
    data: [],
  },

  getters: {
    billsData: (state) => state.data,
  },

  mutations: {
    setDataFromFetch(state, data) {
      state.data = data;
    },
    clearState(state) {
      state.data = [];
    },
  },

  actions: {
    async fetchBillsData({ commit }, id_customer) {
      const result = await home.get(id_customer);
      return commit('setDataFromFetch', result.data);
    },
  },
};
