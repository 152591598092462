<template lang="pug">
el-select(:placeholder="placeholder"
  :size="size"
  :value="value"
  @change="change"
  :disabled="disabled"
  :filterable="true"
  :remote="remote"
  :remote-method="remoteMethod"
  multiple clearable)
  el-option(v-if="typeof options[0] === 'object'" v-for='item in options'
    :key="String(item[itemValue])"
    :label="item[itemLabel]"
    :value="item[itemValue]")
  el-option(v-else :key="item" :value="item" :label="item")
</template>

<script>
export default {
  props: {
    options: { type: Array },
    itemValue: { type: String, default: () => 'value' },
    itemLabel: { type: String, default: () => 'name' },
    placeholder: { type: String, default: () => 'Select' },
    size: { type: String, default: () => 'small' },
    value: { type: Array, default: () => null },
    disabled: { type: Boolean, default: false },
    remote: { type: Boolean, default: false },
    'remote-method': { type: Function, default: () => {} },
  },
  methods: {
    change: function (val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  /deep/ {
    .el-tag {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      max-width: 100%;

      .el-select__tags-text {
        width: calc(100% - 13px);
        height: 19px;
        overflow: hidden;
      }

      .el-icon-close {
        margin-top: 0;
      }
    }
  }
}
</style>
