<template>
  <div>
    <div class="editor-modal_content">
      <p>
        Weather data setup for this location is
        <span v-bind:class="setupClass">{{ isSetup }}</span>
      </p>
    </div>
    <div class="editor-modal_content">
      <span>Activate location weather data: &nbsp;&nbsp;</span>
      <el-checkbox
        v-model="weather_active"
        @change="changedActive"
      ></el-checkbox>
    </div>
    <div class="editor-modal_actions">
      <el-button @click="cancel"> Cancel</el-button>
      <el-button class="btn-close" type="primary" @click="confirm">
        Confirm</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    weatherSetup: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    weatherActive: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      weather_active: this.weatherActive,
      weather_changed: false,
    };
  },
  methods: {
    changedActive() {
      this.weather_changed = true;
    },
    confirm() {
      this.$emit('confirm', {
        weather_changed: this.weather_changed,
        weather_active: this.weather_active,
      });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  computed: {
    isSetup() {
      if (!this.weatherActive) return 'Inactive';
      if (this.weatherActive && this.weatherSetup) return 'Complete';
      return 'Pending';
    },
    setupClass: function () {
      return {
        Inactive: !this.weatherActive,
        Complete: this.weatherActive && this.weatherSetup,
        Pending: this.weatherActive && !this.weatherSetup,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  text-align: center;
}
.bold {
  font-weight: 700;
}
.Inactive {
  color: red;
}
.Pending {
  color: red;
}
.Complete {
  color: green;
}
.editor-modal {
  &_content {
    display: inline-flex;
    text-align: center;
    word-break: normal;
  }
  &_actions {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .el-button {
      width: 78px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 12px;
      border-radius: 100px;
      font-weight: 500;

      &.btn-close {
        width: 126px;
      }
    }
  }
}
</style>
