<template>
  <div class="page-content">
    <div class="info-container">
      <div class="info-title">General Information</div>
      <div>
        <div class="info-row">
          <div class="info-key">ID</div>
          <div class="info-value">{{ vendor.id }}</div>
        </div>
        <div class="info-row">
          <div class="info-key">Vendor Code</div>
          <div class="info-value">{{ vendor.code }}</div>
        </div>
        <div class="info-row">
          <div class="info-key">Vendor Name</div>
          <div class="info-value">{{ vendor.prettyName }}</div>
        </div>
        <div class="info-row" v-if="customPrettyName">
          <div class="info-key">Custom Vendor Name</div>
          <div class="info-value">
            <span v-if="vendor.customPrettyName">
              {{ vendor.customPrettyName }}
            </span>
            <span v-else>-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vendor: {
      type: Object,
    },
    customPrettyName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style/vendors.scss';
.info-container {
  margin-bottom: 20px;
  .info-title {
    height: 30px;
    margin-bottom: 16px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    line-height: 30px;
    color: #ffffff;
    background-color: #273a58;
    border-radius: 5px 5px 0 0;
  }

  .info-row {
    display: flex;
    margin: 10px 0;
    padding: 0 20px;
    font-size: 16px;
    line-height: 22px;
    color: #4a4a4a;
    .info-key {
      width: 235px;
      color: #4a4a4a;
    }

    .info-value {
      color: #273a58;
    }
  }
}
</style>
