<template lang="pug">
  el-dialog.upload-modal(:visible="showModal",
                        title="Creating Bill Batch From Directory",
                        width="940px",
                        :close-on-click-modal="false",
                        @close="closeModal")
    .upload-modal_content(:class="{'is-error': uploadStatus && uploadStatus.error, 'is-completed': uploadStatus === 'Complete'}")
      p.upload-modal_message Please wait while the system prepares the  <b>“{{ batchStatus.folderName }}”</b> bill batch.
      p.upload-modal_status
        span(v-if="!uploadStatus") Processing…
        span(v-else-if="uploadStatus && uploadStatus.error") {{ uploadStatus.error.message }}
        span(v-else-if="uploadStatus === 'Complete'") Completed
      el-progress(:stroke-width="24", :percentage="progress")
      .upload-modal_steps(:class="`current-step-${steps.length}`")
        p(:class="setStepStatus(steps[0])")
          i.icon-status
          | Copying bill PDFs to Pear.ai.
        p(:class="setStepStatus(steps[1])")
          i.icon-status
          | Creating ZIP file.
        p(:class="setStepStatus(steps[2])")
          i.icon-status
          | Uploading batch to FDG.

      .upload-modal_info(v-if="billBatchId")
        p The bill batch has been submited to FDG.
        p The bill batch ID is {{ billBatchId }}.
        p You can track it’s status in the Bill Uploads page.
    .upload-modal_footer(slot="footer")
      el-button(type="primary", size="small", @click="ok") OK
</template>

<script>
import { path, contains, isEmpty, pathOr } from 'ramda';
import { mapGetters } from 'vuex';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    status: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      steps: [],
      uploadStatus: null,
      progress: 0,
      billBatchId: '',
    };
  },
  mounted() {
    window.addEventListener('beforeunload', this.closeConfirmation);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.closeConfirmation);
  },
  computed: {
    ...mapGetters({
      batchStatus: 'batches/batchStatus',
    }),
  },
  watch: {
    status(currentStatus) {
      const complete = currentStatus.find(
        (e) => path(['result', 'name'], e) === 'Complete',
      );
      const error = currentStatus.find((e) => e.error);

      if (complete) {
        this.uploadStatus = complete.result.name;
        this.billBatchId = complete.result.payload.batchId;
        this.progress = 100;
      } else {
        this.progress = currentStatus.length * 10;
      }
      if (error) {
        this.uploadStatus = error;
      }

      currentStatus.forEach((e) => {
        const currentStep = path(['result', 'name'], e);

        if (currentStep && !contains(currentStep, this.steps)) {
          this.steps.push(currentStep);
        }
      });
    },
  },
  methods: {
    closeModal() {
      this.okConfirmation();
    },
    ok() {
      this.okConfirmation();
    },
    setStepStatus(status) {
      if (
        status === 'Files are copied to Pear.ai' ||
        status === 'Batch Created' ||
        status === 'Batch Uploaded to FDG'
      ) {
        return 'is-completed';
      }
      if (status === 'Error') {
        return 'is-error';
      }
    },
    closeConfirmation(event) {
      const error = pathOr({}, ['uploadStatus', 'error'], this);
      if (isEmpty(error) && this.progress !== 100) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    okConfirmation() {
      const error = pathOr({}, ['uploadStatus', 'error'], this);
      if (isEmpty(error) && this.progress !== 100) {
        const confirm = window.confirm(
          'Changes you made may not be saved. Are you sure?',
        );
        if (confirm) {
          this.$router.replace({ name: 'customer.uploads' });
        }
      } else {
        this.$router.replace({ name: 'customer.uploads' });
      }
    },
  },
};
</script>

<style lang="scss">
.upload-modal {
  .el-dialog {
    border-radius: 3px;

    &__header {
      padding: 40px 40px 30px;
      color: #273a58;
      font-weight: 600;
    }
    &__body {
      padding: 0 40px;
    }
    &__footer {
      padding: 40px 30px;
    }
  }
  &_content {
    position: relative;

    .el-progress {
      display: flex;
      flex-direction: column;
    }
    .el-progress-bar {
      padding-right: 0;
      margin-right: 0;
      order: 1;

      &__inner {
        transition: width 0.25s;
        background-color: #2893f9;
      }
    }
    .el-progress__text {
      margin-left: 0;
      margin-bottom: 20px;
      color: #273a58;
      font-size: 12px !important;
      text-align: right;

      &::after {
        content: ' of 100%';
      }
    }
    &.is-completed {
      .upload-modal_status {
        color: #43b493;
      }
      .el-progress-bar__inner {
        background-color: #43b493;
      }
    }
    &.is-error {
      .upload-modal_status {
        color: #f86259;
      }
      .el-progress-bar__inner {
        background-color: #f86259;
      }
    }
  }
  &_message {
    margin: 0 0 40px;
    color: #222222;
    font-size: 16px;
  }
  &_status {
    position: absolute;
    top: 56px;
    margin: 0;
    color: #2684de;
    font-weight: 600;
  }
  &_steps {
    margin-top: 20px;
    color: #222222;
    font-size: 16px;

    .icon-status {
      display: none;
      margin-right: 8px;
      font-size: 20px;
      font-family: element-icons !important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    p {
      margin: 0;

      + p {
        margin-top: 5px;
      }
      &.is-completed {
        color: #43b493;

        .icon-status {
          display: inline-block;

          &::before {
            content: '\E6DA';
          }
        }
      }
      &.is-error {
        color: #f86259;

        .icon-status {
          display: inline-block;

          &::before {
            content: '\E6DB';
          }
        }
      }
    }
    &.current-step-0 {
      p:nth-child(1) {
        color: #2684de;
      }
    }
    &.current-step-1 {
      p:nth-child(2) {
        color: #2684de;
      }
    }
    &.current-step-2 {
      p:nth-child(3) {
        color: #2684de;
      }
    }
  }
  &_info {
    margin-top: 20px;
    color: #606266;
    font-size: 16px;
    font-weight: 600;

    p {
      margin: 0;
    }
  }
  &_footer {
    text-align: center;

    .el-button {
      min-width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 14px;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
</style>
