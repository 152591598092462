<template lang="pug">
  .delete-prompt(:class="{ 'delete-prompt__live': isMarkedForPayment}")
    template(v-if="isMarkedForPayment")
      .editor-modal_content
        | This bill is a live bill for which a payment file has been generated. The customer may have already processed a payment to the utility provider for this bill. If you delete this bill, you should inform the customer to note any corresponding issues on the bill's payment file.
      br
      .editor-modal_content {{ `Are you sure you want to delete this bill ${billId}?` }}
      .editor-modal_actions
        el-button.plain-button-reverse.plain-button-confirm(@click='confirm') Confirm Delete
        el-button(type='primary', @click='cancel') Cancel
    template(v-else)
      .editor-modal_content {{ `Are you sure you want to delete bill ${billId}?` }}
      .editor-modal_actions
        el-button(type='primary', @click='cancel') No
        el-button.plain-button-reverse(@click='confirm') Yes
</template>

<script>
export default {
  props: {
    billId: {
      type: String,
      default: '',
    },
    isMarkedForPayment: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },

    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-prompt__live {
  margin-top: -15px;
}
.editor-modal {
  &_content {
    text-align: center;
    word-break: keep-all;
  }

  &_actions {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .el-button {
      width: 78px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 12px;
      border-radius: 100px;
      font-weight: 500;

      &.btn-close {
        width: 92px;
      }
      &.plain-button-confirm {
        width: 130px;
      }
    }
  }

  .el-dialog__body {
    padding: 30px;
  }
}
</style>
