import _ from 'lodash';
import { customersUsers } from '@/api';

const isFullAccess = (customers, currentCustomerId) => {
  if (!_.isNil(customers)) {
    const { customerRolesPermissions } = customersUsers;
    const currentCustomer = customers.find(
      (customer) => +customer.id === +currentCustomerId,
    );
    const isFullAcc =
      currentCustomer &&
      currentCustomer.permissions &&
      !!currentCustomer.permissions.find(
        (p) =>
          p.feature ===
            customerRolesPermissions.customerFullAccess[0].feature &&
          p.permission ===
            customerRolesPermissions.customerFullAccess[0].permission,
      );
    const permissionsLength =
      currentCustomer && currentCustomer.permissions.length;
    return { isFullAcc, permissionsLength };
  }
};

const userRoles = (customers, currentCustomerId) => {
  const fullAccess = isFullAccess(customers, currentCustomerId);
  if (!_.isNil(fullAccess)) {
    if (!fullAccess.isFullAcc && fullAccess.permissionsLength > 0) {
      return 'Custom Role';
    }
    if (fullAccess.isFullAcc) {
      return 'Full Access';
    } else {
      return 'Standard User';
    }
  }
};

const customerLocations = (customers, currentCustomerId, locationsList) => {
  const fullAccess = isFullAccess(customers, currentCustomerId);
  if (!_.isNil(customers) && !_.isNil(fullAccess)) {
    const currentCustomer = customers.find(
      (customer) => +customer.id === +currentCustomerId,
    );
    const locations = currentCustomer && currentCustomer.locations;
    if (!_.isNil(locations)) {
      if (locations.length === locationsList.length || fullAccess.isFullAcc) {
        return 'All';
      }
      if (locations.length === 0) {
        return '-';
      }
      return locations.length;
    }
  }
};

const handleEditUserRoles = (payload) => {
  const { userId, userRoleRadio, fullName, editUserRole, customerId } = payload;
  const { customerRolesPermissions } = customersUsers;
  const customerAccess = customerRolesPermissions[userRoleRadio];
  const [userRole] = customerAccess;
  const permissions = !_.isNil(userRole) ? [userRole.id] : [];
  const readableUserRole =
    !_.isNil(userRole) &&
    userRole.feature === 'customer_full_access' &&
    userRole.permission === 'enabled'
      ? 'Full Access'
      : 'Standard User';
  return {
    permissions,
    userId,
    fullName,
    readableUserRole,
    editUserRole,
    customerId,
  };
};

export { userRoles, customerLocations, handleEditUserRoles };
