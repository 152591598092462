<template lang="pug">
  .insight-modal-content
    .insight-fields
      .insight-field.insight-message
        label User Message
        el-input(v-model='message', type='textarea', :rows='2')
      .insight-field
        el-checkbox(v-model='silent') Silent
      .insight-field.insight-url
        el-button(type='primary', size='small', @click='createUrl') Create URL
        el-input(v-model='url', size='small', disabled)
    .insight-actions
      el-button.plain-button-reverse(@click='closeModal') Close
      el-button(type='primary', v-clipboard:copy='url', v-clipboard:success='onCopy', :disabled='!url.length') Copy to Clipboard
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      message: '',
      silent: true,
    };
  },
  methods: {
    createUrl() {
      let url = '';
      if (this.silent) {
        url = `/sam?silent=${this.silent}&message=${encodeURIComponent(
          this.message,
        )}`;
      } else {
        url = `/sam?message=${encodeURIComponent(this.message)}`;
      }
      this.$emit('create-url', url);
    },
    closeModal() {
      this.$emit('close-modal');
    },
    onCopy(url) {
      this.$emit('save-message', this.silent, this.message);
    },
  },
};
</script>

<style lang="scss" scoped>
.insight {
  &-fields {
    color: #606266;
    font-size: 14px;
    line-height: 19px;
  }
  &-field {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    label {
      min-width: 132px;
    }
  }
  &-message {
    label {
      align-self: flex-start;
      margin-top: 8px;
    }
  }
  &-url {
    .el-button {
      width: 142px;
      margin-right: 15px;
    }
  }
  &-actions {
    padding-left: 110px;
    margin-top: 30px;

    .el-button {
      width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
</style>

<style lang="scss">
.insight-url {
  .el-input.is-disabled {
    .el-input__inner {
      color: inherit;
      border-color: #d8dce6;
      background-color: inherit;
      cursor: text;
    }
  }
}
</style>
