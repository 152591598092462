<template lang="pug">
  .hierarchy-wrapper
    .hierarchy-container
      ul.hierarchy-list
        hierarchy-item(:node="tree",
          :selectedLocations="selectedLocations",
          :nodeClick="nodeClick",
          :handleSelect="handleChange")
</template>

<script>
import HierarchyItem from './HierarchyItem.vue';
import _ from 'lodash';

export default {
  components: {
    HierarchyItem,
  },
  props: {
    hierarchy: {
      type: Array,
      required: true,
      default: () => [],
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    storedLocation: {
      type: Array,
      required: false,
      default: () => [],
    },
    nodeClick: {
      type: Function,
      required: false,
      default: (node) => null,
    },
  },
  data() {
    return {
      selectedLocations: [],
    };
  },
  watch: {
    storedLocation(val) {
      this.selectedLocations = val.map((l) => l.id);
    },
  },
  mounted() {
    this.selectedLocations = this.storedLocation.map((l) => l.id);
  },
  computed: {
    tree() {
      const roots = [];
      const list = this.hierarchy.slice();
      const map = new Map();
      list.forEach((node, i) => {
        map.set(node.id, i);
        node.children = [];

        if (node.parent_id) {
          list[map.get(node.parent_id)].children.push(node);
        } else {
          roots.push(node);
        }
      });

      /**
       * Return first hierarchy,
       * as we show only default hierarchy for now
       */
      return _.head(roots);
    },
  },
  methods: {
    handleChange(value) {
      this.selectedLocations = value;
      /**
       * Map location objects to flat array of location ids,
       * and pass it to parent component for submission
       */
      this.$emit(
        'select-locations',
        this.selectedLocations.map((sl) =>
          _.find(this.locations, (l) => l.id === sl),
        ),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.hierarchy-wrapper {
  overflow-x: auto;
}

.hierarchy-container {
  max-height: 58vh;
  -webkit-overflow-scrolling: touch;
  padding: 0 25px;

  ul {
    list-style: none;
    padding: 0;

    ul {
      list-style: none;
    }
  }

  overflow-x: auto;
}
</style>
