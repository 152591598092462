<template>
  <div class="create-wrapper" data-element="create-report-container">
    <div class="form-content">
      <div class="form-section">
        <h4 class="header">Report Type</h4>
        <div class="form-section-content">
          <div class="form-field">
            <el-select
              class="report-type-select"
              @change="reportChange"
              :popper-append-to-body="false"
              data-action="select-report-type"
              popper-class="select-report-type_dropdown"
              :value="selectedReport.readableId"
              filterable
            >
              <el-option
                v-for="option in reportTemplates"
                :key="option.readableId"
                :label="option.title"
                :value="option.readableId"
                :data-element="option.readableId"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="form-section">
        <h4 class="header">Parameters</h4>
        <div class="form-section-content">
          <div class="form-field short">
            <label>Format</label>
            <el-select
              v-model="selectedFormat"
              data-action="select-format-type"
              popper-class="select-format-type_dropdown"
              filterable
            >
              <el-option
                v-for="option in this.selectedReport.exportFormats"
                :key="option"
                :label="option.toUpperCase()"
                :value="option"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div
        class="form-section filters"
        v-if="selectedReport.availableFilters.length > 0"
      >
        <h4 class="header">Filters</h4>
        <report-filters
          :report="selectedReport"
          :filters="appliedFilters"
          @set-location-attrs="onSetFilters"
        />
      </div>
    </div>
    <div
      class="reports-form-actions-wrapper"
      :class="{ success: successOpened }"
    >
      <div class="reports-form-actions">
        <el-button
          round="round"
          type="primary"
          @click="createReport"
          data-action="submit-report"
          >Create report
        </el-button>
        <el-button
          round="round"
          type="text"
          @click="resetAllFilters"
          data-action="reset-filters"
          >Reset</el-button
        >
      </div>
    </div>
    <el-dialog
      class="preview-dialog"
      :visible.sync="previewOpened"
      :append-to-body="true"
      :lock-scroll="true"
      :fullscreen="false"
      width="1200px"
      top="5vh"
      @close="closePreview"
    >
      <preview
        :previewOpened="previewOpened"
        :filters="appliedFilters"
        :report-template="selectedReport"
      ></preview>
    </el-dialog>
    <el-dialog
      class="success-dialog"
      :visible.sync="successOpened"
      :show-close="false"
      title="Your report has been submitted"
      :lock-scroll="true"
      :fullscreen="false"
      width="530px"
      @close="closeSuccess"
    >
      <success @closeSuccess="closeSuccess"></success>
    </el-dialog>
  </div>
</template>

<script>
import Success from './Success';
import _ from 'lodash';
import ReportFilters from '@/components/ReportFilters';
import Preview from './Preview';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Success,
    Preview,
    ReportFilters,
  },
  props: {
    handleCreateReport: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  async mounted() {
    const { customerId } = this.$route.params;
    await this.loadReportTemplates({ customerId, params: this.filters });
    const [ firstTemplate ] = this.reportTemplates
    this.loadReportAvailableValues({ customerId, templateId: firstTemplate.id })
    this.selectedReport = firstTemplate
  },
  data() {
    return {
      selectedReport: {
        readableId: null,
        availableFilters: [],
        exportFormats: [],
        locationAttrsVals: {},
      },
      selectedFormat: 'csv',
      successOpened: false,
      previewOpened: false,
      invoicePeriod: {},
      billLoadedPeriod: {},
      appliedFilters: {},
      filters: {},
      helpModal: false,
    };
  },
  computed: {
    ...mapGetters({
      reportTemplates: 'customerReportTemplates/templates',
      user: 'user/user',
    }),
    cleanFilters() {
      let cleanFilters = {
        ...this.appliedFilters,
        ...this.locationAttrsVals,
        location_attributes: undefined,
      };
      if (this.selectedReport.readableId === 'ADMIN_BILL_ERRORS') {
        cleanFilters.start_date = undefined;
        cleanFilters.end_date = undefined;
      }

      Object.keys(cleanFilters).forEach((key) => {
        const value = cleanFilters[key];
        if (value === '') {
          cleanFilters[key] = undefined;
        }
        if (Array.isArray(value) && value.length === 0) {
          cleanFilters[key] = undefined;
        }
        if (_.isObject(value) && Object.keys(value).length === 0) {
          cleanFilters[key] = undefined;
        }
      });

      return cleanFilters;
    },
  },
  methods: {
    ...mapActions({
      loadReportTemplates: 'customerReportTemplates/getCustomerReportTemplates',
      loadReportAvailableValues: 'customerReportTemplates/getTemplateAvailableValues'
    }),
    onSetFilters(val) {
      this.locationAttrsVals = val;
    },
    reportChange(selected) {
      const { customerId } = this.$route.params;
      const selectedTemplate = this.reportTemplates.find(
        (template) => template.readableId === selected,
      );
      this.loadReportAvailableValues({ customerId, templateId: selectedTemplate.id });
      this.selectedReport = selectedTemplate;
      this.resetFilters();
    },
    openHelpModal() {
      this.helpModal = true;
    },
    closeSuccess() {
      this.successOpened = false;
      this.$emit('showList');
    },
    closePreview() {
      this.previewOpened = false;
    },
    resetFilters() {
      Object.keys(this.appliedFilters).forEach((key) => {
        const filter = this.appliedFilters[key];
        if (filter) {
          if (Array.isArray(filter)) {
            // is Array
            this.appliedFilters[key].splice(0);
          } else if (typeof filter === 'object') {
            // is Object
            this.appliedFilters[key] = undefined;
          } else {
            // is base DataType
            this.appliedFilters[key] = null;
          }
        }

        return filter;
      });
      this.selectedFormat = 'csv';
      this.filters = {};
      this.invoicePeriod = null;
      this.billLoadedPeriod = null;
    },
    resetAllFilters() {
      this.selectedReport = this.reportTemplates[0];
      this.resetFilters();
    },
    async createReport() {
      this.showErrors = false;

      try {
        await this.handleCreateReport({
          reportTemplateId: this.selectedReport.id,
          saveFormat: this.selectedFormat,
          customReportName: this.selectedReport.title,
          emailRecipients: [this.user.email],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          appliedFilters: this.cleanFilters,
        });
        this.successOpened = true;
      } catch (error) {
        if (_.get(error, ['response.status']) === 409) {
          this.showPopupError(
            _.get(
              error,
              ['message'],
              [
                'You have too many scheduled reports.',
                'Please try again later.',
              ],
            ),
          );
        } else {
          this.showPopupError(
            _.get(
              error,
              ['message'],
              ['Something went wrong.', 'Please try again later.'],
            ),
          );
        }
      }
    },
    showPopupError(message) {
      const h = this.$createElement;
      if (_.isArray(message)) {
        message = message.map((line) => h('p', null, line));
      }
      this.$msgbox({
        title: 'Error',
        message: h('div', null, message),
        confirmButtonText: 'OK',
        center: true,
      });
    },
    showPreview() {
      this.previewOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-error {
  align-self: flex-end;
  padding: 0 10px;
  font-size: 14px;
  line-height: 30px;
  color: #ff0000;
}

.create-wrapper {
  /deep/ .select-report-type_dropdown .el-select-dropdown__wrap {
    max-height: initial;
  }

  /deep/ .success-dialog {
    .el-dialog {
      border-radius: 10px;

      .el-dialog__header {
        padding-top: 40px;
        padding-bottom: 0;

        .el-dialog__title {
          text-align: center;
          color: #2c2e34;
          font-size: 18px;
          font-weight: 900;
          line-height: 25px;
          display: block;
        }
      }

      .el-dialog__body {
        padding-bottom: 40px;
      }
    }
  }

  .form-section {
    padding-bottom: 24px;
    border-bottom: 1px solid #c6c6c6;

    &:last-child {
      border-bottom: none;
    }

    &.filters {
      border: 0;
    }

    .header {
      font-size: 16px;
      line-height: 22px;
      font-weight: 900;
      color: #273a58;
      margin-top: 16px;
    }

    .form-section-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &.filters {
        margin: 0 -40px 0 -40px;
        padding: 0 40px;
        background-color: #f7f8fa;
        flex-wrap: wrap;
      }

      .form-field-group {
        margin-left: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
      }

      .form-field {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;

        &.placeholder {
          width: 180px;
        }

        label {
          margin-bottom: 7px;
          color: #606266;
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
        }

        /deep/ .el-input__inner {
          height: 30px;
          line-height: normal;
          width: 180px;
          outline: none;
        }

        .report-type-select {
          /deep/ .el-input__inner {
            width: 260px;
          }
        }

        /deep/ .el-input__icon {
          line-height: normal;
        }

        &.short {
          max-width: 150px;

          /deep/ .el-input__inner {
            width: 150px;
          }
        }
      }
    }
  }

  .reports-form-actions-wrapper {
    .reports-form-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 21px;

      button {
        padding: 8px 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;

        &.el-button--primary {
          font-weight: 900;
          color: #ffffff;
        }
      }
    }
  }

  .filter {
    padding: 0 25px 20px 0;

    &-title {
      color: #606266;
      margin: 0 0 10px 0;
    }

    /deep/ .el-date-editor--daterange.el-input__inner {
      max-width: 285px;
    }
  }
}
</style>

<style lang="scss">
.el-scrollbar__wrap {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.error-dialog {
  .el-dialog__header {
    text-align: center;
  }

  .el-dialog__title {
    color: #f86259;
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: center;
  }
}

.preview-dialog {
  .el-dialog {
    border-radius: 10px;

    .el-dialog__header {
      padding-top: 44px;

      .el-dialog__title {
        margin-left: 25px;
        color: #2c2e34;
        font-size: 18px;
        font-weight: 900;
        line-height: 25px;
      }
    }

    .el-dialog__body {
      padding-top: 0;
    }
  }
}

/deep/ .help-dialog {
  .el-dialog {
    border-radius: 10px;
  }

  .el-dialog__body {
    padding: 0 40px 40px;
  }

  .el-dialog__header {
    padding: 34px 40px 12px;

    span {
      color: #2c2e34;
      font-size: 22px;
      font-weight: 900;
      line-height: 30px;
    }
  }

  .help-modal-body {
    color: #2c2e34;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    max-width: 505px;
    word-break: initial;

    p {
      margin: 0 0 16px;

      &.margin-none {
        margin: 0;
      }
    }

    ul {
      font-size: 14px;
      list-style: none;
      padding-left: 18px;
      margin: 0 0 24px;

      li {
        padding: 0;
        position: relative;
        font-weight: 900;
        margin-bottom: 12px;

        &::before {
          color: #409eff;
          content: '\2022';
          display: inline-block;
          font-size: 16px;
          left: -1.1225em;
          position: absolute;
          top: 0;
        }
      }
    }
  }
}
</style>
