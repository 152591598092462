const inputKeyupDebounce = (callback, timeout = 700) => {
  let timer = null;
  return (e) => {
    clearTimeout(timer);

    timer = setTimeout(callback, timeout, e);
  };
};

export { inputKeyupDebounce };
