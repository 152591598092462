<template lang="pug">
  validation-observer.change-password__container(ref="form" v-slot="{ invalid }")
    .change-password__title Change password for {{ user.email }}
    .change-password__content
      form
        input.saveUser(name="username" autocomplete="username" id="username" type="text" :value="user.email")
        validation-provider.input__container(rules="required" ref="old_password" immediate v-slot="{ flags, errors, failedRules }")
          label Current password:
          .input__wrapper
            el-input(v-model="old_password"
              type="password"
              size="small"
              autocomplete="current-password"
              placeholder="**********"
              :class='{ "has-error": flags.invalid && flags.touched}'
              data-testid="currentPasswordIput")
            .error(v-if="errors[0] && isEmpty(failedRules)") {{ errors[0] }}
        validation-provider.input__container(
          :rules="{required: true, min: 8, max: 31, hasDigit: true, hasUpperCaseLetter: true, noSpaces: true }"
          ref="password"
          immediate
          v-slot="{ flags, errors, failedRules }")
          label New password:
          .input__wrapper
            el-input(v-model="password" type="password" autocomplete="new-password" size="small" placeholder="********" :class='{ "has-error": flags.invalid && flags.touched}' data-testid="newPasswordInput")
            .error(v-if="failedRules.hasDigit || failedRules.min || failedRules.max || failedRules.hasUpperCaseLetter")
              | Must contain 8 or more characters including one uppercase letter and one number.
            .error(v-else-if="failedRules.noSpaces") Spaces are not allowed.
            .error(v-else-if="flags.invalid && isEmpty(failedRules)") {{ errors[0] }}
        validation-provider.input__container(:rules="{required: true, is: password}" immediate v-slot="{ flags, failedRules }")
          label New password again:
          .input__wrapper
            el-input(v-model="password_confirmation"  autocomplete="new-password" type="password" size="small" placeholder="********" :class='{ "has-error": flags.invalid && flags.touched}' data-testid="newPasswordAgainInput")
            .error(v-if="flags.invalid && flags.touched") Passwords do not match.
      .change-password__actions
        el-button.plain-button-reverse(@click='cancel' size="small" data-testid="cancelPassChangeBtn") Cancel
        el-button(type='primary', @click='confirm' size="small" :disabled="invalid" data-testid="confirmPassChangeBtn") Change password
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  Validator,
} from 'vee-validate';
import { changePassword } from '@/api/user';
import * as R from 'ramda';

const defaultData = () => {
  return {
    old_password: '',
    password: '',
    password_confirmation: '',
  };
};

const complexErrorMessage =
  'Must contain 8 or more characters including one uppercase letter and one number.';

Validator.extend('hasDigit', {
  getMessage: () => complexErrorMessage,
  validate: (value) => /.*\d.*/.test(value),
});
Validator.extend('hasUpperCaseLetter', {
  getMessage: () => complexErrorMessage,
  validate: (value) => /.*[A-Z].*/.test(value),
});
Validator.extend('noSpaces', {
  getMessage: () => 'Spaces are not allowed.',
  validate: (value) => !/.*\s.*/.test(value),
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: () => false,
    },
  },
  data: defaultData,
  watch: {
    isVisible(val) {
      if (val) {
        this.$refs.form.validate();
      } else {
        Object.assign(this.$data, defaultData());
        this.$refs.form.reset();
      }
    },
  },
  methods: {
    isEmpty: R.isEmpty,
    async confirm() {
      try {
        await changePassword({
          user_id: this.user.id,
          old_password: this.old_password,
          password: this.password,
        });
        this.$emit('ok');
      } catch (e) {
        const error = R.path(['response', 'data', 'error'], e);
        if (R.isEmpty(error)) {
          this.$message({
            showClose: true,
            message: `Something went wrong. Please try again later...`,
            type: 'error',
          });
        } else {
          try {
            const data = JSON.parse(error);
            this.showErrors(R.prop('errors', data));
          } catch (e) {
            this.$message({
              showClose: true,
              message: `Something went wrong. Please try again later...`,
              type: 'error',
            });
          }
        }
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    showErrors(errors) {
      const addError = ({ title }, field) => {
        this.$refs[field].applyResult({
          errors: [title],
          valid: false,
          failedRules: {},
        });
      };
      R.forEachObjIndexed(addError)(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/constants.scss';

.change-password {
  &__container {
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    word-break: keep-all;
  }

  &__content {
    margin: 20px 0 32px 0;
    .saveUser {
      display: none;
    }
    .input {
      &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin: 0 0 16px 0;

        label {
          min-width: 156px;
        }

        .error {
          word-break: keep-all;
          color: #f86259;
          font-size: 10px;
          line-height: 14px;
        }
      }

      &__wrapper {
        width: 100%;
      }
    }
  }

  &__actions {
    text-align: center;
    .el-button {
      min-width: 170px;
    }
  }
}
</style>
