import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

const list = (customerId, params) =>
  http(hosts.v1)
    .get(`customers/${customerId}/locations`, { params })
    .then(dataMiddleware);

const get = (customerId, locationId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/locations/${locationId}`)
    .then(dataMiddleware);

const getFiltersData = (customerId) =>
  http(hosts.v1)
    .get(`customers/${customerId}/locations-filters`)
    .then(dataMiddleware);

const getLocationsMetadata = (customerId, limit = 20) =>
  http(hosts.v3)
    .get(`customers/${customerId}/locations-metadata?limit=` + limit.toString())
    .then(dataMiddleware);

const setUserLocations = (customerId, userId, body) =>
  http(hosts.v3).post(
    `/customers/${customerId}/users/${userId}/locations`,
    body,
  );

const create = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/locations`, body)
    .then(dataMiddleware);

const put = (customerId, locationId, body) =>
  http(hosts.v3)
    .put(`/customers/${customerId}/locations/${locationId}`, body)
    .then(dataMiddleware);

const deleteLocation = (customerId, locationId) =>
  http(hosts.v3)
    .delete(`/customers/${customerId}/locations/${locationId}`)
    .then(dataMiddleware);

// customers/{customer_id}/locations/validate-csv

const validateLocationsCsv = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/locations/bulk-validate`, body)
    .then(dataMiddleware);

const uploadLocationsCsv = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/locations/bulk`, body)
    .then(dataMiddleware);

const uploadAttributesCsv = (customer_id, body) =>
  http(hosts.v1)
    .post(`/customers/${customer_id}/locations/create-metadata-from-csv`, body)
    .then(dataMiddleware);

const createAttribute = (customer_id, body) =>
  http(hosts.v1)
    .post(`/customers/${customer_id}/locations-metadata`, body)
    .then(dataMiddleware);

const putAttribute = (customer_id, attribute_id, body) =>
  http(hosts.v1)
    .put(`/customers/${customer_id}/locations-metadata/${attribute_id}`, body)
    .then(dataMiddleware);

const deleteAttribute = (customer_id, attribute_id) =>
  http(hosts.v1)
    .delete(`/customers/${customer_id}/locations-metadata/${attribute_id}`)
    .then(dataMiddleware);

const updateAttributesOrder = (customer_id, order) =>
  http(hosts.v1)
    .post(`/customers/${customer_id}/locations-metadata/sort`, order)
    .then(dataMiddleware);

const remapVirtualAccounts = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/virtual-accounts-remap`, body)
    .then(dataMiddleware);

const downloadESScores = (customer_id, body) =>
  http(hosts.v3)
    .post(`/customers/${customer_id}/energy-star/save-scores`, body)
    .then(dataMiddleware);

const bulkEnableWeather = (customer_id, body) =>
  http(hosts.v3)
    .post(`/weather/${customer_id}/toggle-bulk-weather-setup`, body)
    .then(dataMiddleware);

/**
 * @typedef LocationMetadata
 * @property {number} id
 * @property {number} customer_id
 * @property {number} sort_order
 * @property {string} attribute_name
 * @property {string} attribute_type
 * @property {string} attribute_uom
 */
/**
 *
 * @param customer_id
 * @returns {LocationMetadata[]}
 */
const getMetaData = (customer_id) =>
  http(hosts.v1)
    .get(`/customers/${customer_id}/locations-metadata`)
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }));

export {
  create,
  put,
  list,
  get,
  getFiltersData,
  validateLocationsCsv,
  uploadLocationsCsv,
  uploadAttributesCsv,
  getMetaData,
  createAttribute,
  putAttribute,
  deleteAttribute,
  updateAttributesOrder,
  deleteLocation,
  remapVirtualAccounts,
  downloadESScores,
  setUserLocations,
  bulkEnableWeather,
  getLocationsMetadata,
};
