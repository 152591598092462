<template lang="pug">
small-menu(v-if="$route.name === 'customer.bills.info'")
Menu(v-else)
</template>

<script>
import smallMenu from './smallMenu';
import Menu from './Menu';

export default {
  components: {
    smallMenu,
    Menu,
  },
};
</script>
