import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

const customerRolesPermissions = {
  standardUser: [],
  customerFullAccess: [
    { feature: 'customer_full_access', permission: 'enabled', id: 2 },
  ],
};

const userRolesRadioSelection = [
  { label: 'standardUser', name: 'Standard User' },
  { label: 'customerFullAccess', name: 'Full Access (default)' },
];

/**
 * @function
 * @memberOf customersUsers
 * */
async function get(customerId, orderBy = '') {
  return http(hosts.v3)
    .get(`customers/${customerId}/users`, {
      params: {
        orderBy,
        limit: 1000,
      },
    })
    .then(dataMiddleware)
    .then((data) => ({
      data: data || [],
    }));
}

async function getActivationLink(user_id) {
  return http(hosts.v1).get(`/users/${user_id}/activation-link`);
}

async function getResetPasswordLink(user_id) {
  return http(hosts.v1).get(`/users/${user_id}/reset-password-link`);
}

async function activate(user) {
  return http(hosts.v1).post(`/users/${user}/send-activation`);
}

async function post(customer, user) {
  return http(hosts.v1)
    .post(`customers/${customer}/users`, user)
    .then(dataMiddleware);
}

async function patch(user) {
  return http(hosts.v1).patch(`/users`, user).then(dataMiddleware);
}

async function assignPermissions(customerId, permissions) {
  return http(hosts.v3).post(
    `/customers/${customerId}/permissions`,
    permissions,
  );
}

export {
  customerRolesPermissions,
  userRolesRadioSelection,
  activate,
  getActivationLink,
  getResetPasswordLink,
  get,
  post,
  patch,
  assignPermissions,
};
