<template lang="pug">
  main-layout
    .page.page-reports
      .page-header
        h1.page-title Reports
      .page-content
        el-tabs(v-model="activeTab")
          el-tab-pane(label='Reports List', name='list', data-action='show-list')
            list-tab(v-if="activeTab === 'list'")
          el-tab-pane(label='Create Report', name='create', data-action='create-report')
            create-tab(v-if="activeTab === 'create'", :handleCreateReport="createReport", @showList="showList" class="min-height")
</template>

<script>
import { Main } from '@/components/layouts';
import ListTab from './Tabs/List/View';
import CreateTab from './Tabs/Create/View';
import * as R from 'ramda';
import { createGlobalReport } from '../../api/reports';

export default {
  components: {
    mainLayout: Main,
    CreateTab,
    ListTab,
  },
  beforeMount() {
    this.activeTab = R.pathOr('list', ['tab'], this.$route.params);
  },
  data() {
    return {
      activeTab: 'list',
    };
  },
  watch: {
    activeTab(tab) {
      this.$router.push({ name: 'reports.tab', params: { tab } });
    },
    $route(to, from) {
      this.activeTab = R.pathOr('list', ['tab'], this.$route.params);
    },
  },
  methods: {
    async createReport(reportOptions) {
      const response = await createGlobalReport(reportOptions);
      return R.pathOr(null, ['response', 'results'], response);
    },
    showList() {
      this.activeTab = 'list';
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 80px);
  padding: 20px 40px 60px 40px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
  }
}

/deep/ {
  .el-tabs__item {
    &:hover {
      color: #273a58;
    }

    &.is-active {
      font-weight: 900;
      color: #273a58;
    }
  }

  .el-tabs__active-bar {
    background-color: #273a58;
  }
}
.min-height {
  min-height: 50vh;
}
</style>
