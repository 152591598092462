<template lang="pug">
  el-dialog.confirm-modal(:visible="showModal",
                          top="70px",
                          width="420px",
                          :show-close="false",
                          :close-on-click-modal="false")
    template(v-if="status.error")
      .confirm-modal_content
        p The Google Drive ID or Folder is invalid, or Pear.ai does not have access to download files from this folder.<br> Please try again.
      .confirm-modal_footer(slot="footer")
        el-button(type="danger", size="mini", round, @click="cancel") Ok

    template(v-else-if="status.isProcessed")
      .confirm-modal_content
        p This Google Drive directory ID ({{ status.folderId }}) has been processed before. Are you sure you want to proceed?
        p
          a(:href="folderLink", target="_blank") View Directory
      .confirm-modal_footer(slot="footer")
        el-button(size="mini", round, @click="confirm") Continue
        el-button(type="danger", size="mini", round, @click="cancel") Cancel

    template(v-else-if="!status.isProcessed && status.pdfCount !== '0'")
      .confirm-modal_content
        p This directory <b>has {{ status.pdfCount }} PDF bills</b> to process.
        p There are <b>{{ status.ignoreCount }} non-PDF files</b> which will be ignored.
        p OK to proceed?
      .confirm-modal_footer(slot="footer")
        el-button(size="mini", round, @click="cancel") Cancel
        el-button(type="primary", size="mini", round, @click="confirm") Confirm

    template(v-else-if="!status.isProcessed && status.pdfCount === '0'")
      .confirm-modal_content
        p This directory has no PDF bills to process.
      .confirm-modal_footer(slot="footer")
        el-button(type="danger", size="mini", round, @click="cancel") Ok

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      status: 'batches/batchStatus',
    }),
    folderLink() {
      return `https://drive.google.com/open?id=${this.status.folderId}`;
    },
  },
  methods: {
    confirm() {
      this.$router.push({ name: 'customer.uploads.confirm' });
    },
    cancel() {
      this.$router.push({ name: 'customer.uploads' });
    },
  },
};
</script>

<style lang="scss">
.confirm-modal {
  .el-dialog {
    border-radius: 4px;

    &__body {
      padding: 0 30px;
    }
    &__footer {
      padding: 10px 30px 30px;
    }
  }
  &_content {
    text-align: center;

    p {
      margin: 5px 0;
    }
    a {
      color: #2893f9;
    }
  }
  &_footer {
    text-align: center;

    .el-button {
      min-width: 92px;

      + .el-button {
        margin-left: 20px;
      }
    }
  }
}
</style>
