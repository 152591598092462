<template lang="pug">
  .hierarchy-modal-content
    .modal-dialog-breadcrumbs {{ breadcrumbs }}
    el-table.locations-hierarchies-table.with-shadow(:data="locations",
    data-type="hierarchies-list",
    height="560",
    width="100%",
    ref='table',
    :default-sort='{"prop": "address", "order": "descending"}',
    @row-click="selectRow",
    @selection-change="handleSelectionChange")
      el-table-column(type="selection")
      el-table-column(label="Description", prop="name", sortable="", width="200")
      el-table-column(label="Type", prop="building_type", sortable="")
      el-table-column(label="Service Address", prop="address", sortable="", width="200")
      el-table-column(label="City", prop="city", sortable="")
      el-table-column(label="State", prop="state", sortable="")
      el-table-column(label="Zip", prop="postcode", sortable="")
      el-table-column(label="Sq. Ft", prop="square_feet", sortable="")
    .modal-dialog_actions
      el-button.plain-button-reverse(@click='closeModal') Cancel
      el-button(type='primary', @click='handleSubmit') Apply Changes
      .selected-count-wrapper
        .selected-count(v-if="selectedLocations.length")
          span {{ selectedLocations.length }} locations are checked
          el-button(type="text", icon="el-icon-close", @click="handleUnselect")
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { locations } from '@/api';

export default {
  data() {
    return {
      selectedLocations: [],
      locations: [],
      deleteItems: [],
    };
  },
  props: {
    routeParams: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    breadcrumbs: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  computed: {
    ...mapState({
      unmachedLocations: (state) => state.hierarchy.unmachedLocations,
      unmachedLocationParams: (state) => state.hierarchy.unmachedLocationParams,
    }),
    inheritedLocationIds() {
      return (this.item.children || [])
        .filter((item) => item.location_id)
        .map((item) => item.location_id);
    },
  },
  watch: {
    unmachedLocationParams() {
      this.fetchLocations();
    },
  },
  async mounted() {
    await this.fetchLocations();
  },
  methods: {
    ...mapActions({
      setUnmachedLocationsParams: 'hierarchy/setUnmachedLocationsParams',
      fetchUnmachedLocationsToAssign:
        'hierarchy/fetchUnmachedLocationsToAssign',
    }),
    async fetchLocations() {
      const unmachedLocations = await this.fetchUnmachedLocationsToAssign(
        this.routeParams,
      );
      const { data } = await locations.list(this.routeParams.customerId, {
        limit: 1000,
      });
      this.locations = [
        ...unmachedLocations,
        ...this.inheritedLocationIds.map((id) => ({
          ...data.find((l) => l.id === id),
          inherit: true,
        })),
      ];

      this.$nextTick(() => {
        this.locations.forEach((item) => {
          if (item.inherit) {
            this.$refs.table.toggleRowSelection(item);
          }
        });
      });
    },
    selectRow(location) {
      this.$refs.table.toggleRowSelection(location);
    },
    handleSubmit() {
      const insertItems = this.selectedLocations.filter((l) => !l.inherit);
      const deleteLocations = this.inheritedLocationIds.filter(
        (id) => !this.selectedLocations.find((l) => l.id === id),
      );
      const deleteItems = this.item.children
        .filter((item) => deleteLocations.includes(item.location_id))
        .map((i) => i.id);
      this.$emit('assignLocations', { insertItems, deleteItems });
      this.$refs.table.clearSelection();
    },
    closeModal() {
      this.$refs.table.clearSelection();
      this.$emit('close-modal');
    },
    handleSelectionChange(data) {
      this.selectedLocations = data;
    },
    handleUnselect() {
      this.$refs.table.clearSelection();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog-breadcrumbs {
  color: #909399;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 16px;
}

.modal-dialog_actions {
  padding: 30px 0;
}

.modal-dialog {
  .checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > span {
      margin-left: 16px;
    }
  }

  &_fields {
    color: #606266;
    font-size: 14px;
    line-height: 19px;
  }
  &_field {
    display: flex;
    align-items: center;

    label {
      min-width: 132px;
      margin-top: 0;
    }
  }
  &_note {
    margin-top: 14px;
  }
  &_actions {
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .selected-count-wrapper {
      width: 1px;
      margin-left: 20px;
      position: relative;

      .selected-count {
        position: absolute;
        top: -12px;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 200px;

        span {
          color: #273a58;
          font-size: 14px;
          line-height: 19px;
        }

        .el-button {
          max-width: 30px;
          width: 30px;
          min-width: 0;
          color: #273a58;
        }
      }
    }

    .el-button {
      width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
</style>
