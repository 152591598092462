<style src="./annotationLayer.css"></style>
<script>
import componentFactory from './componentFactory.js';
var component;
if (process.env.VUE_ENV !== 'server') {
  var pdfjsWrapper = require('./pdfjsWrapper.js').default;
  var PDFJS = require('pdfjs-dist/es5/build/pdf.js');

  try {
    if (
      typeof window !== 'undefined' &&
      'Worker' in window &&
      navigator.appVersion.indexOf('MSIE 10') === -1
    ) {
      /* eslint-disable */
      var PdfjsWorker = require('worker-loader!pdfjs-dist/es5/build/pdf.worker.js');
      /* eslint-enable */
      PDFJS.GlobalWorkerOptions.workerPort = new PdfjsWorker();
    }
  } catch (e) {
    // no empty
  }

  component = componentFactory(pdfjsWrapper(PDFJS));
} else {
  component = componentFactory({});
}

export default component;
</script>
