<template lang="pug">
  el-select( :placeholder="placeholder_",
    :size="size",
    :value="selected_",
    @change="change",
    @visible-change="onVisibleChange",
    @focus="onFocus",
    :popper-class="'dropdown-menu'",
    :disabled="disabled"
    filterable)
    template(v-if="typeof optionsShow[0] === 'object'")
      el-option(v-for='item in optionsShow',
        :key="item[itemValue]",
        :label="item[itemLabel]",
        :value="item[itemValue]")
        .dropdown-item(:class="{ 'dropdown-item-selected': selected === item[itemLabel]}")
          span {{ item[itemLabel] }}
    template(v-else)
      el-option(v-for='item in optionsShow',
        :key="item",
        :value="item",
        :label="item")
        .dropdown-item(:class="{ 'dropdown-item-selected': selected === item}")
          span {{ item }}
</template>

<script>
import _ from 'lodash';

export default {
  name: 'BillEditorSelect',
  props: {
    onSelectChange: {
      type: Function,
      default: () => {},
    },
    options: { type: Array, default: () => [] },
    itemValue: { type: String, default: () => 'value' },
    itemLabel: { type: String, default: () => 'name' },
    placeholder: { type: String, default: () => 'Select' },
    size: { type: String, default: () => 'mini' },
    selected: { type: String, default: () => null },
    disabled: { type: Boolean, default: false },
    index: { type: Number, default: null },
  },
  watch: {
    selected(value) {
      this.selected_ = value;
    },
    placeholder(value) {
      this.placeholder_ = value;
    },
  },
  beforeMount() {
    this.optionsShow = this.options.filter((el) => el.id === this.selected);
  },
  data() {
    return {
      selected_: this.selected,
      placeholder_: this.placeholder,
      optionsShow: [],
    };
  },
  methods: {
    onVisibleChange(visible) {
      this.selected_ = visible ? '' : this.selected;
      this.placeholder_ = visible ? 'Search' : this.placeholder;
    },
    change(value) {
      this.onSelectChange(value, _.get(this, ['index'], null));
    },
    onFocus() {
      this.optionsShow = this.options;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select-dropdown__list {
  padding-top: 0;
}

.el-select-dropdown__item {
  height: 28px;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 29px;
  padding: 0 20px;
  margin: 0 -20px;
  font-size: 12px;
  border-top: 1px solid #e7e7e7;
  &-selected {
    color: #273a58;
    font-weight: 700;
  }
}
</style>

<style lang="scss">
.dropdown-menu {
  .el-select-dropdown__list {
    padding-top: 0;
  }
}
</style>
