<template>
  <div class="vendor-details-container">
    <VendorDetailsHeader :vendor="vendor" :backPath="backPath" />
    <VendorGeneralInfo :vendor="vendor" :customPrettyName="true" />
    <ActionsBar />
    <div v-if="vendor.vendorProviderDetails">
      <VendorBlock
        v-for="(vendor, index) in vendor.vendorProviderDetails"
        :key="vendor.id"
        :vendor="vendor"
        :index="index"
        :customerId="$route.params.customerId"
      >
      </VendorBlock>
    </div>
  </div>
</template>

<script>
import ActionsBar from './components/ActionsBar.vue';
import VendorBlock from '../../Vendors/components/VendorBlock.vue';
import VendorGeneralInfo from '../../Vendors/components/VendorGeneralInfo.vue';
import VendorDetailsHeader from '../../Vendors/components/VendorDetailsHeader.vue';
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ActionsBar,
    VendorDetailsHeader,
    VendorGeneralInfo,
    VendorBlock,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      vendor: 'vendors/vendor',
    }),
    backPath() {
      const path = `/customers/${this.$route.params.customerId}/vendors`;
      return path;
    },
  },
  methods: {
    ...mapMutations({
      $resetVendor: 'vendors/resetVendor',
      $setVendorName: 'customers/setVendorName',
    }),
    ...mapActions({
      $getCustomerVendor: 'vendors/getCustomerVendor',
    }),
  },
  async beforeMount() {
    this.$resetVendor();
    await this.$getCustomerVendor({
      customerId: this.$route.params.customerId,
      vendorId: this.$route.params.vendorId,
    });
    this.$setVendorName(this.vendor.code);
  },
};
</script>

<style lang="scss" scoped>
@import '../../Vendors/style/vendors.scss';
</style>
