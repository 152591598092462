import BillUploads from './List.vue';
import Directory from './Directory.vue';
import Confirm from './ConfirmBillBatch.vue';
import Upload from './Upload.vue';

export default {
  path: 'uploads',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: 'Bill Upload',
      linkTemplate: '/customers/<%= id %>/uploads',
      storeMappings: { id: 'customers/id' },
    },
  },
  children: [
    {
      path: '',
      name: 'customer.uploads',
      component: BillUploads,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: 'directory',
      component: Directory,
      name: 'customer.uploads.directory',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'Upload Bills',
        },
      },
    },
    {
      path: 'confirm',
      component: Confirm,
      name: 'customer.uploads.confirm',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'Upload Bills',
        },
      },
    },
    {
      path: 'steps',
      component: Upload,
      name: 'customer.uploads.upload',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'Upload Files',
        },
      },
    },
  ],
};
