<template>
  <BaseModal
    :dialogVisible="showAdminModal"
    :title="title"
    :width="'75%'"
    @close="close"
  >
    <template #content>
      <div class="tab-title">
        <h2
          class="subtitle"
          :class="[{ 'tab-selected': !showBulkSetupTab }]"
          @click="toggleEnergyStarTab"
        >
          Energy Star Admin
        </h2>
      </div>
      <div class="tab-title">
        <h2
          class="subtitle"
          :class="[{ 'tab-selected': showBulkSetupTab }]"
          @click="toggleWeatherTab"
        >
          Weather Admin
        </h2>
      </div>
      <div class="clear"></div>
      <ErrorCard title="" :statusMessage="statusMessage" />
      <template v-if="!showBulkSetupTab">
        <p>
          Downloading Energy Star scores transfers Energy Star ratings for all
          configured Energy Star properties from Energy Star to Pear.ai. After
          download, these ratings will be available for customers in all reports
          and in Power BI visualizations.
        </p>
        <p>
          Before downloading Energy Star scores, make sure that you have
          successfully uploaded Energy Star meter read data for all configured
          Pear.ai locations (and their associated Energy Star properties) using
          the Pear.ai Customer Web Meter Reads report.
        </p>
        <p>
          Energy Star scores are available for a given calendar month. For
          Energy Star to provide a calendar month rating, meter read information
          for all meters mapped to a given Energy Star property must be provided
          for 12 calendar months continuously through the last day of the
          specified month.
        </p>
        <br />
        <ValidationObserver ref="observer">
          <el-form @submit="submit">
            <p>Select calendar month</p>
            <div class="date-pickers">
              <BaseDatePicker
                size="small"
                v-model="startDate"
                immediate
                name="From"
                type="month"
                format="yyyy-MM"
                label="From"
                rules="required"
                placeholder="Start month"
                @change="dateChange"
                :disabled="isLoading"
                :isEditMode="true"
                :labelWidth="'55px'"
              />
              <BaseDatePicker
                size="small"
                v-model="endDate"
                immediate
                @change="dateChange"
                label="To"
                type="month"
                format="yyyy-MM"
                name="To"
                :disabled="isLoading"
                rules="required"
                placeholder="End month"
                :isEditMode="true"
                :labelWidth="'55px'"
              />
            </div>
          </el-form>
        </ValidationObserver>
      </template>
      <template v-else>
        <p>
          <strong>Activate Weather For All Locations</strong>
        </p>
        <p>
          Activating Weather for All Locations sets the “weather active”
          attribute for all locations to “on”.
        </p>
        <p>
          To configure a location to use weather data, both the customer-level
          weather attribute and the individual location-level weather attribute
          must be set to “on”.
        </p>
        <p>
          The customer-level weather attribute is set in the Customer Info
          screen. You can configure individual location “weather active”
          attribute from within individual Location Detail screens. To turn on
          weather-active attributes for all locations, use the button bellow.
        </p>
        <br />
      </template>
    </template>
    <template #footer>
      <el-button class="button-size plain-button" @click="close"
        >Cancel</el-button
      >
      <el-button
        class="button-size"
        :loading="isLoading"
        type="primary"
        :disabled="!canDownload"
        @click="submit"
        v-if="!showBulkSetupTab"
        >Download Energy Star Scores</el-button
      >
      <el-button
        class="button-size"
        :loading="isLoading"
        type="primary"
        :disabled="!isWeatherEntitlement"
        @click="bulkEnableWeather"
        v-if="showBulkSetupTab"
        >Activate Weather for All Locations</el-button
      >
    </template>
  </BaseModal>
</template>

<script>
// import _ from 'lodash';
import BaseModal from '@/components/BaseModal';
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker';
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import ErrorCard from '@/components/Cards/ErrorCard';
import { DateTime } from 'luxon';
import { locations } from '@/api';

export default {
  components: {
    BaseModal,
    BaseDatePicker,
    ErrorCard,
    ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isWeatherEntitlement: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(val) {
      this.showAdminModal = val;
    },
  },
  computed: {
    ...mapGetters({
      customerName: 'customers/name',
    }),
    canDownload: {
      get() {
        return (
          this.statusMessage.length === 0 && this.startDate && this.endDate
        );
      },
    },
  },
  data() {
    return {
      showAdminModal: false,
      endDate: '',
      isLoading: false,
      startDate: '',
      statusMessage: '',
      showBulkSetupTab: false,
    };
  },
  methods: {
    toggleEnergyStarTab() {
      this.showBulkSetupTab = false;
    },
    toggleWeatherTab() {
      this.showBulkSetupTab = true;
    },
    async submit(e) {
      e.preventDefault();
      const body = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      this.isLoading = true;
      const result = await locations.downloadESScores(
        this.$route.params.customerId,
        body,
      );
      this.isLoading = false;
      this.$emit('success', {
        ...result,
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
    async bulkEnableWeather(e) {
      e.preventDefault();
      this.isLoading = true;
      const result = await locations.bulkEnableWeather(
        this.$route.params.customerId,
        { value: true },
      );
      this.isLoading = false;
      this.$emit('bulkWeatherSetup', result);
    },
    dateChange() {
      if (this.startDate) {
        if (DateTime.fromFormat(this.startDate, 'LL/dd/yyyy').isValid) {
          this.startDate = DateTime.fromFormat(
            this.startDate,
            'LL/dd/yyyy',
          ).toFormat('yyyy-LL-dd');
        }
        if (this.endDate) {
          if (DateTime.fromFormat(this.endDate, 'LL/dd/yyyy').isValid) {
            this.endDate = DateTime.fromFormat(
              this.endDate,
              'LL/dd/yyyy',
            ).toFormat('yyyy-LL-dd');
          }
          if (
            DateTime.fromFormat(this.endDate, 'yyyy-LL-dd') <
            DateTime.fromFormat(this.startDate, 'yyyy-LL-dd')
          ) {
            this.statusMessage = 'Start Date must be before end Date.';
            return;
          }
        }
      }
      this.statusMessage = '';
    },
    close() {
      this.endDate = '';
      this.startDate = '';
      this.statusMessage = '';
      this.adminError = false;
      this.isLoading = false;
      if (!this.showBulkSetupTab) {
        this.$refs.observer.reset();
      }
      this.$emit('close');
      setTimeout(() => {
        this.showBulkSetupTab = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 0 0 16px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
}
.subtitle:hover {
  text-decoration: underline;
}
.tab-selected {
  text-decoration: underline;
  color: #273a58;
  cursor: default;
  font-weight: 600;
}
.tab-title {
  float: left;
  width: 45%;
}
.clear {
  clear: both;
}
.date-pickers {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}
p {
  word-break: normal;
  text-align: justify;
}
</style>
