<template lang="pug">
  .editor-modal_container
    template(v-if='modalType === "updating"')
      .editor-modal_content
        span You have successfully made changes to&nbsp;
        span bill ID {{ $route.params.billId }}.
        br
        span Click the button below to close this window.
      .editor-modal_actions
        el-button.btn-close(type='primary', @click='close') Close
    template(v-if='modalType === "canceled"')
      .editor-modal_content
        span Bill edits have been cancelled.
        br
        span Click the button below to close this window.
      .editor-modal_actions
        el-button.btn-close(type='primary', @click='close') Close
</template>

<script>
export default {
  props: ['modal-type'],
  methods: {
    close() {
      this.$emit('close-editor-modal');
    },
  },
};
</script>
