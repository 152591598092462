<template lang="pug">
.bill-tabs-container
  el-tabs(v-model="activeTab")
    el-tab-pane(label="Bill Summary" :name="availableTabNames.billSummary")
      tab-summary(v-if="bill.id" :items="items")
    //el-tab-pane(label="Account View" :name="availableTabNames.accountView") Account View
    el-tab-pane(label="Full Bill" :name="availableTabNames.fullBill")
      .tab-full-bill
        .bill-filters-container
          bill-filters(v-show="isFiltersVisible" @change="filtersChange")
          .bill-filters-common-actions
            .bill-filters--info Showing {{ filteredItems.length }} of {{ items.length }} bill blocks
            .icon.icon-collapse.clickable(@click="collapse(true)" :class="{active: isCollapsed}")
            .icon.icon-expand.clickable(@click="collapse(false)" :class="{active: !isCollapsed}")
            el-button(type="text" @click="isFiltersVisible = !isFiltersVisible" :class="{active: isFiltersVisible}")
              span Filters
              .icon.icon-filters1(:class="{active: isFiltersVisible}")
        .bill-block(v-for='item of filteredItems', :key='item.id')
          bill-block(v-if="observationList.length > 0",
            :item='item'
            :isCollapsed='item.isCollapsed'
            :observationList="observationList"
            :hasObservations="hasObservations"
            :categories="categories"
            @updateItem='updateBillBlock')
    el-tab-pane(label="Account History" :name="availableTabNames.accountHistory")
      tab-account-history(v-if="bill.id" :items="items" :full-view="!isSideBarVisible" )
    //el-tab-pane(label="Trend Analysis" :name="availableTabNames.trendAnalysis") Trend Analysis
    el-tab-pane(label="Bill Chain" :name="availableTabNames.billChain" :lazy="lazyTab")
      bill-chain-tab(v-if="bill.id")
    el-tab-pane(label="Remittance Info" :name="availableTabNames.remittanceInfo")
      .tab-remittance-info
        el-row
          el-col(:span="12")
            h3 Bill to Address
            .info
              .info__label Address Line 1:
              .info__value {{ pathOr('-', ['billToAddress', 'line1'], bill) }}
            .info
              .info__label Address Line 2:
              .info__value {{ pathOr('-', ['billToAddress', 'line2'], bill) }}
            .info
              .info__label City:
              .info__value {{ pathOr('-', ['billToAddress', 'city'], bill) }}
            .info
              .info__label State:
              .info__value {{ pathOr('-', ['billToAddress', 'state'], bill) }}
            .info
              .info__label Postcode:
              .info__value {{ pathOr('-', ['billToAddress', 'postCode'], bill) }}
          el-col(:span="12")
            h3 Remittance Address
            .info
              .info__label Name:
              .info__value {{ pathOr('-', ['remittanceName' ], bill) }}
            .info
              .info__label Address Line 1:
              .info__value {{ pathOr('-', ['remittanceAddress', 'line1'], bill) }}
            .info
              .info__label City:
              .info__value {{ pathOr('-', ['remittanceAddress', 'city'], bill) }}
            .info
              .info__label State:
              .info__value {{ pathOr('-', ['remittanceAddress', 'state'], bill) }}
            .info
              .info__label Postcode:
              .info__value {{ pathOr('-', ['remittanceAddress', 'postCode'], bill) }}
    //el-tab-pane(label="Other Information" name="availableTabNames.otherInformation") Other Information
</template>
<script>
import * as R from 'ramda';
import { mapGetters, mapMutations } from 'vuex';
import BillBlock from './../components/BillBlock';
import BillFilters from './../components/BillFilters';
import TabSummary from './Tabs/Summary';
import TabAccountHistory from './Tabs/AccountHistory';
import BillChainTab from './BillChainTab';

export default {
  components: {
    BillBlock,
    BillFilters,
    TabSummary,
    TabAccountHistory,
    BillChainTab,
  },
  props: {
    observationList: {
      type: Array,
      default: () => [],
    },
    isSideBarVisible: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (!R.isEmpty(R.omit(['tab'], this.$route.query))) {
      this.isFiltersVisible = true;
    }
    window.addEventListener('keydown', this.keyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyPress);
  },
  data() {
    return {
      activeTab: 'full-bill',
      filters: {},
      isFiltersVisible: false,
      isCollapsed: false,
      formats: [],
      lazyTab: true,
      availableTabNames: {
        fullBill: 'full-bill',
        remittanceInfo: 'remittance-info',
        billSummary: 'bill-summary',
        accountHistory: 'account-history',
        billChain: 'bill-chain',
        // trendAnalysis: 'trend-analysis'
        // otherInformation: 'other-information'
      },
    };
  },
  watch: {
    $route: {
      async handler() {
        const tabName = R.propOr(
          this.availableTabNames.fullBill,
          'tab',
          this.$route.query,
        );
        const sameTab = tabName && this.activeTab && tabName === this.activeTab;
        if (sameTab) {
          return;
        }
        this.activeTab = tabName;
        if (!this.$route.query.tab) {
          this.setDontReload(true);
          await this.$router.replace({
            query: { ...this.$route.query, ...{ tab: tabName } },
          });
        }
        this.$emit('update');
      },
      immediate: true,
    },
    async activeTab(tab) {
      if (tab === this.$route.query.tab) {
        return;
      }
      this.setDontReload(true);
      await this.$router.replace({
        query: { ...this.$route.query, ...{ tab } },
      });
    },
  },
  computed: {
    ...mapGetters({
      bill: 'bills/bill',
      customerId: 'customers/id',
      getVendorIdByCode: 'resources/getVendorIdByCode',
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
      history: 'bills/history',
    }),
    hasObservations() {
      return R.pipe(
        R.propOr([], 'files'),
        R.any(R.propSatisfies(R.contains('/observations/'), 'path')),
      )(this.bill);
    },
    categories() {
      const categoryOrder = [
        'Usage Information',
        'Usage Charges',
        'Commodity Charges',
        'Customer Charges',
        'Other Charges',
        'Taxes',
        'Unknown Information',
      ];
      const hiddenCategories = ['(DO NOT SHOW)', 'Non-billing'];
      const list = R.pipe(
        R.map(R.propOr('', 'category')),
        R.uniq,
        R.reject((v) => hiddenCategories.includes(v)),
      )(this.observationList);
      list.push('Unknown Information');
      return R.sortBy((category) =>
        categoryOrder.findIndex((c) => c === category),
      )(list);
    },
    items() {
      return R.propOr([], 'items', this.bill);
    },
    filteredItems() {
      const filters = R.omit(['location_name'], this.filters);
      const isIncluded = (item) => {
        let locationMatch = true;
        if (R.has('location_name', this.filters)) {
          locationMatch = this.filters.location_name.includes(
            R.path(['location', 'name'], item),
          );
        }
        return (
          R.reduce(
            (acc, key) => {
              const match = filters[key].includes(item[key]);
              return match && acc;
            },
            true,
            R.keys(filters),
          ) && locationMatch
        );
      };
      return R.filter(isIncluded)(this.items);
    },
  },
  methods: {
    ...mapMutations({
      setDontReload: 'bills/setDontReload',
    }),
    pathOr: R.pathOr,
    prop: R.prop,
    updateBillBlock(item) {
      this.$emit('update', item);
    },
    filtersChange(filters) {
      this.filters = filters;
    },
    collapse(isCollapsed) {
      this.bill.items = this.bill.items.map((item) => {
        item.isCollapsed = { isCollapsed, time: Date.now() };
        return item;
      });
      this.isCollapsed = isCollapsed;
    },
    keyPress(e) {
      const { keyCode } = e;
      const billBlocks = [...document.querySelectorAll('.bill-block')];
      const container = document.querySelector('.bill-detail__content');
      const heightTop =
        document.querySelector('.bill-detail__content--top').offsetHeight +
        document.querySelector('.bill-tabs-container .el-tabs__header')
          .offsetHeight +
        34;
      const findCurrentBlock = (block) =>
        block.offsetTop >= container.scrollTop - heightTop;
      let currentBlockIndex = R.findIndex(findCurrentBlock, billBlocks);
      if (container.scrollTop === 0) {
        currentBlockIndex = -1;
      }
      const topElement = document.querySelector('.bill-detail__content--top');
      switch (keyCode) {
        case 38: // up
          this.scrollToBlock(e, billBlocks, currentBlockIndex - 1, topElement);
          break;
        case 40: // down
          this.scrollToBlock(e, billBlocks, currentBlockIndex + 1);
          break;
      }
    },
    scrollToBlock(e, billBlocks, index, topElement) {
      if (index > billBlocks.length - 1) {
        index = billBlocks.length - 1;
      } else if (index < 0) {
        topElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        return;
      }

      const isCollapsed = { isCollapsed: false, time: Date.now() };
      this.bill.items[index] = {
        ...this.bill.items[index],
        ...{ isCollapsed },
      };
      this.bill.items = R.clone(this.bill.items);

      const el = billBlocks[index];
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      e.preventDefault();
    },
  },
};
</script>
<style lang="scss">
.bill-tabs-container {
  padding: 10px 0 20px 0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;

  .bill-filters-common-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 0 0;
    text-align: right;

    .icon {
      margin: 0 7px;
    }

    .el-button {
      margin-left: 24px;
      text-decoration: none;
      color: #273a58;

      &.active,
      &:hover {
        color: #267de2;

        .icon {
          background: url(../../../../../assets/icons/filters1_active.svg)
            center center no-repeat;
        }
      }
    }

    .bill-filters--info {
      flex-grow: 1;
      padding-left: 20px;
      text-align: left;
      font-size: 12px;
      color: #666666;
    }
  }

  .bill-block {
    margin-bottom: 10px;

    .collapse {
      box-shadow: 0 2px 5px 0px rgba(225, 223, 223, 0.65);

      .icon {
        padding-left: 8px;
        background-size: contain;
        width: 8px;
      }

      .header {
        padding: 7px 20px;

        .icon-arrow {
          background-image: url(../../../../../assets/icons/arrow-bottom.svg);

          &.back {
            transform: rotate(270deg);
          }
        }

        .bill-collapse-block {
          flex-basis: 33%;
        }
      }

      .content {
        padding: 10px 0;

        .bill-title {
          padding-left: 20px;
          color: #273a58;
        }

        .bill-field {
          &-container {
            padding-left: 10px;
          }

          &-key {
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
          }

          &-value {
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            color: #303133;

            a {
              color: #409eff;
            }
          }
        }

        .irregular-data {
          margin-top: 10px;

          .header {
            padding: 7px 0 7px 10px !important;
            color: #273a58;
            background-color: transparent;

            &-title {
              width: auto;
              padding-right: 8px;
              order: -1;
            }
          }

          &-item > div {
            padding-left: 10px;
          }

          &-title {
            color: #273a58;
          }

          &-row-key {
            padding-left: 10px;
            font-size: 12px;
            font-weight: 400;
          }

          &-row-value {
            font-size: 12px;
            color: #303133;
            text-align: right;
            padding-right: 20px;

            &.currency-value {
              color: #2893f9;
            }

            &.description-value {
              text-align: left;
              color: #838ea5;
            }
          }
        }
      }
    }
  }

  .el-tabs__item {
    &:hover {
      color: #273a58;
    }

    &.is-active {
      font-weight: 600;
      color: #273a58;
    }
  }

  .el-tabs__active-bar {
    background-color: #273a58;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  h4 {
    margin: 26px 0 10px 0;
  }

  .info {
    display: flex;
    flex-direction: row;
    font-size: 12px;

    + .info {
      padding-top: 10px;
    }

    &__label {
      padding-right: 7px;
      min-width: 150px;
      color: #222222;
    }

    &__value {
      color: #273a58;
      font-weight: 700;
    }
  }

  a {
    color: #409eff;
  }

  .bills-navigation {
    a {
      font-weight: 600;
    }
  }

  .el-tabs__header {
    margin: 0 20px 15px 20px;
  }

  .tab {
    &-bill-summary {
      padding: 10px 20px;
    }

    &-bill-block {
    }

    &-remittance-info {
      padding: 10px 20px;

      h3 {
        font-size: 14px;
      }

      .info {
        &__label {
          min-width: 120px;
        }
      }
    }
  }
}
</style>
