import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';
import qs from 'qs';

/**
 * @description allows to get my bills list
 * @param {Object} params {
 *  offset,
 *  limit,
 *  orders
 * }
 */
const list = (params) =>
  http(hosts.v1)
    .get(`/bills/my-bills?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }));

/**
 * @description allows to reprioritize my bills
 */
const reprioritize = () =>
  http(hosts.v1).post(`/bills/reprioritize`).then(dataMiddleware);
const getMyBillsSummary = () =>
  http(hosts.v1).get(`/bills/my-bills/summary`).then(dataMiddleware);
const park = (data) =>
  http(hosts.v1).post(`/bills/my-bills/park`, data).then(dataMiddleware);
const unpark = (data) =>
  http(hosts.v1).post(`/bills/my-bills/unpark`, data).then(dataMiddleware);

export { list, reprioritize, getMyBillsSummary, park, unpark };
