import { reports } from '../../api';

const initialState = () => {
  return {
    templates: [],
    params: { limit: 1000, exclude: ['total', 'extra', 'filters'] },
    loading: true,
  };
};

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    templates: (state) => state.templates.results && state.templates.results,
    loading: (state) => state.loading,
  },

  mutations: {
    setGlobalReportTemplates(state, payload) {
      state.templates = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    resetState: (state) => {
      const initState = initialState();
      Object.assign(state, initState);
    },
  },
  actions: {
    async getGlobalReportTemplates({ commit, state }) {
      try {
        commit('setLoading', true);
        const templates = await reports.getGlobalReportTemplates(state.params);
        commit('setGlobalReportTemplates', templates);
        commit('setLoading', false);
      } catch (e) {
        console.log(e);
        commit('setLoading', false);
      }
    },
  },
};
