import _ from 'lodash';
import { virtual_accounts as va } from '@/api';

const REQUEST_VIRTUAL_ACCOUNTS = 'REQUEST_VIRTUAL_ACCOUNTS';
const RECEIVE_VIRTUAL_ACCOUNTS = 'RECEIVE_VIRTUAL_ACCOUNTS';
const REQUEST_VIRTUAL_ACCOUNTS_GROUPS = 'REQUEST_VIRTUAL_ACCOUNTS_GROUPS';
const RECEIVE_VIRTUAL_ACCOUNTS_GROUPS = 'RECEIVE_VIRTUAL_ACCOUNTS_GROUPS';
const CHANGE_PARAMS = 'CHANGE_PARAMS';

const defaultParams = () => ({
  limit: 10,
  offset: 0,
  order: '',
});

export default {
  namespaced: true,
  state: {
    isFetching: false,
    data: [],
    groups: [],
    meta: {
      total: 0,
    },
    params: defaultParams(),
    freezeReasons: [],
  },

  getters: {
    data: (state) => state.data,
    groups: (state) => state.groups,
    meta: (state) => state.meta,
    params: (state) => state.params,
    isFetching: (state) => state.isFetching,
    freezeReasons: (state) => state.freezeReasons,
  },

  mutations: {
    setCurrentLocation(state, location) {
      state.location = { ...location };
    },

    [REQUEST_VIRTUAL_ACCOUNTS_GROUPS](state) {
      state.isFetching = true;
    },

    [RECEIVE_VIRTUAL_ACCOUNTS_GROUPS](state, { data = [], meta = {} }) {
      state.groups = data
        .slice()
        .map(({ virtualAccounts, virtualAccountGroupId }) => {
          const children = _.remove(
            virtualAccounts.slice(),
            (o) => o.id !== virtualAccountGroupId,
          ).map((item) => ({ ...item, isChild: true }));
          return {
            ..._.find(
              virtualAccounts.slice(),
              (o) => o.id === virtualAccountGroupId,
            ),
            ...(children.length && { children }),
          };
        });
      state.meta = { total: parseInt(_.get(meta, 'total', 0)) };
      state.isFetching = false;
    },

    [CHANGE_PARAMS](state, params = defaultParams()) {
      state.params = params;
    },

    [REQUEST_VIRTUAL_ACCOUNTS](state) {
      state.isFetching = true;
    },

    [RECEIVE_VIRTUAL_ACCOUNTS](state, { data = [] }) {
      state.data = data;
      state.isFetching = false;
    },
    clearParams: (state) => {
      Object.assign(state.params, defaultParams());
    },
    setFreezeReasons(state, data) {
      state.freezeReasons = data;
    },
  },

  actions: {
    async fetchGroups({ commit, state }, { customerId, locationId }) {
      commit(REQUEST_VIRTUAL_ACCOUNTS_GROUPS);
      const { results, total } = await va.listGroups(
        customerId,
        locationId,
        state.params,
      );
      commit(RECEIVE_VIRTUAL_ACCOUNTS_GROUPS, {
        data: results,
        meta: { total: total },
      });
    },
    async changeParams({ commit, state }, params) {
      commit(CHANGE_PARAMS, params);
    },
    async fetchVirtualAccounts(
      { commit },
      { customerId, locationId, va_group_id },
    ) {
      commit(REQUEST_VIRTUAL_ACCOUNTS);
      const { data, meta } = await va.list(customerId, locationId, va_group_id);
      commit(RECEIVE_VIRTUAL_ACCOUNTS, { data, meta });
    },
    async editVirtualAccount({ commit, state }, { virtual_account_id, body }) {
      commit(REQUEST_VIRTUAL_ACCOUNTS);
      await va.editVirtualAccount(virtual_account_id, body);
      state.isFetching = false;
    },
    async linkItems({ commit }, { customerId, locationId, va_group_id, body }) {
      commit(REQUEST_VIRTUAL_ACCOUNTS);
      await va.linkItems(customerId, locationId, va_group_id, body);
    },
    async getFreezeReasons({ commit }) {
      const data = await va.freezeReasons();
      commit('setFreezeReasons', data);
    },
  },
};
