<template lang="pug">
  validation-observer.attributes-tab(v-slot="{ invalid }", tag="div")
    .attributes-tab_actions(v-if="!isLocationEmpty")
      el-button(v-if="!editMode", type="text", icon="el-icon-edit", @click="enableEditMode") Edit
      .edit-controls-wrapper(v-else)
        el-button.plain-button-reverse(@click="disableEditMode") Cancel
        el-button(type="primary", @click="handleSubmit", :disabled="invalid") Save
    el-row(type="flex", style="flex-wrap: wrap")
      el-col(:span="12", style="min-width: 400px")
        .header
          span.attributes-tab-block-title Main Information
        .item
          el-row.row-border
            el-col(:span="10")
              p.attributes-tab-field Description
            el-col(:span="14")
              validation-provider.validation-provider-wrapper(v-if="editMode", name="name", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.name")
                el-input(v-model="form.name",
                  size="small",
                  placeholder="",
                  :class='{ "has-error": flags.invalid}')
                error-tooltip(:error="errors[0]")
              p.attributes-tab-field.value(v-else) {{ location.name || 'N/A' }}
        .item
          el-row.row-border
            el-col(:span="10")
              p.attributes-tab-field Address
            el-col(:span="14")
              validation-provider.validation-provider-wrapper(v-if="editMode", name="address", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.address")
                el-input(v-model="form.address",
                  size="small",
                  placeholder="",
                  :class='{ "has-error": flags.invalid}')
                error-tooltip(:error="errors[0]")
              p.attributes-tab-field.value(v-else) {{ location.address || 'N/A'  }}
        .item
          el-row.row-border
            el-col(:span="10")
              p.attributes-tab-field Postcode
            el-col(:span="14")
              validation-provider.validation-provider-wrapper(v-if="editMode", name="postcode", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.postcode")
                el-input(v-model="form.postcode",
                  size="small",
                  placeholder="",
                  :class='{ "has-error": flags.invalid}')
                error-tooltip(:error="errors[0]")
              p.attributes-tab-field.value(v-else) {{ location.postcode  || 'N/A' }}
        .item
          el-row.row-border
            el-col(:span="10")
              p.attributes-tab-field Type
            el-col(:span="14")
              validation-provider.validation-provider-wrapper(v-if="editMode", name="building_type", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.building_type")
                el-select(placeholder="Type",
                  filterable
                  clearable
                  v-model="formBuildingType",
                  :class='{ "has-error": flags.invalid}')
                  el-option(v-for='item in ["Building", "Campus", "Storefront"]', :key='item', :label='item', :value='item')
                error-tooltip(:error="errors[0]")
              p.attributes-tab-field.value(v-else) {{ buildingType  || 'N/A' }}
        .item
          el-row.row-border
            el-col(:span="10")
              p.attributes-tab-field Area
            el-col(:span="14")
              validation-provider.validation-provider-wrapper(v-if="editMode", name="square_feet", rules="required|double", immediate, v-slot="{ flags, errors }", ref="payload.square_feet")
                el-input(v-model.number="form.squareFeet",
                  size="small",
                  placeholder="",
                  :class='{ "has-error": flags.invalid}')
                error-tooltip(:error="errors[0]")
              p.attributes-tab-field.value(v-else) {{ location.square_feet || 0 }}
        el-row(v-if='!isPayloadEmpty')
          el-col
            span.attributes-tab-block-title Additional Information
        el-row(v-if='!isPayloadEmpty')
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Site number
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="site_number", immediate, v-slot="{ flags, errors }", ref="payload.site_number")
                  el-input(v-if="editMode",
                    v-model="form.payload.siteNumber",
                    size="small",
                    placeholder="",
                    :class='{ "has-error": flags.invalid}')
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ location.payload && location.payload.site_number || 'N/A' }}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field City
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="city", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.city")
                  el-input(v-model="form.city",
                    size="small",
                    placeholder="",
                    :class='{ "has-error": flags.invalid}')
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ location.city || 'N/A'}}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field State
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="state", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.state")
                  el-select(placeholder="State",
                    filterable
                    clearable
                    v-model="form.state",
                    :class='{ "has-error": flags.invalid}')
                    el-option(v-for='(v, k) in states', :key='k', :label='v.pretty_name', :value='k')
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ removeCountry(location.state) }}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Country
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="country", immediate, v-slot="{ flags, errors }", ref="payload.country")
                  el-select(v-if="editMode",
                    filterable
                    clearable
                    placeholder="Country",
                    v-model="form.country",
                    :class='{ "has-error": flags.invalid}')
                    el-option(v-for='(v, k) in countries', :key='k', :label='v.pretty_name', :value='k')
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ location.country || 'N/A' }}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Division
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="site_division", immediate, v-slot="{ flags, errors }", ref="payload.site_division")
                  el-input(v-if="editMode",
                    v-model="form.payload.siteDivision",
                    size="small",
                    placeholder="",
                    :class='{ "has-error": flags.invalid}')
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ location.payload && location.payload.site_division || 'N/A'}}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Phone
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="site_phone", immediate, v-slot="{ flags, errors }", ref="payload.site_phone")
                  el-input(v-if="editMode",
                    v-model="form.payload.sitePhone",
                    size="small",
                    placeholder="(000)-000-0000",
                    :class='{ "has-error": flags.invalid}')
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ location.payload && location.payload.site_phone || 'N/A' }}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Fax
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="site_fax", immediate, v-slot="{ flags, errors }", ref="payload.site_fax")
                  el-input(v-if="editMode",
                    v-model="form.payload.siteFax",
                    size="small",
                    :class='{ "has-error": flags.invalid}',
                    placeholder="(000)-000-0000")
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ location.payload && location.payload.site_fax || 'N/A' }}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Latitude
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="latitude", immediate, v-slot="{ flags, errors }", ref="payload.latitude")
                  el-input(v-if="editMode",
                    v-model="form.latitude",
                    size="small",
                    :class='{ "has-error": flags.invalid}',
                    placeholder="")
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ setMaximumDecimals(location.latitude) }}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Longitude
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="longitude", immediate, v-slot="{ flags, errors }", ref="payload.longitude")
                  el-input(v-if="editMode",
                    v-model="form.longitude",
                    size="small",
                    :class='{ "has-error": flags.invalid}',
                    placeholder="")
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ setMaximumDecimals(location.longitude) }}
          .item
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Operating status
              el-col(:span="14")
                validation-provider.validation-provider-wrapper(v-if="editMode", name="site_status", immediate, v-slot="{ flags, errors }", ref="payload.site_status")
                  el-select(v-if="editMode",
                    filterable
                    clearable
                    placeholder="Select",
                    v-model="form.payload.siteStatus",
                    :class='{ "has-error": flags.invalid}')
                    el-option(v-for='item in ["Operational", "Non-Operational", "Not Set"]', :key='item', :label='item', :value='item')
                  error-tooltip(:error="errors[0]")
                p.attributes-tab-field.value(v-else) {{ location.payload && location.payload.site_status || 'N/A'}}
          //.item(v-if="location.payload && location.payload.tags")
            el-row.row-border
              el-col(:span="10")
                p.attributes-tab-field Custom tags
              el-col(:span="14")
                p.attributes-tab-field.value
                  span(v-for="tag in location.payload.tags") {{ tag }}
          el-col(:span="6")
      el-col(:span="12", style="min-width: 400px")
        template(v-if="location.payload && location.payload.metadata")
          el-row
            el-col(:span="20")
              .header(v-if="customAttributes.length")
                span.attributes-tab-block-title Custom Attributes
          .item
            el-row.row-border
              el-col(:span="8")
                p.attributes-tab-field(v-if='location.payload && location.payload.senior_manager') Senior Manager
              el-col(:span="16")
                p.attributes-tab-field.value(v-if='location.payload && location.payload.senior_manager') {{ location.payload.senior_manager }}
          .item(v-for='{id, attribute_name, attribute_value, attribute_uom} in customAttributes')
            el-row.row-border(v-if="!editMode")
              el-col(:span="10")
                p.attributes-tab-field {{ attribute_name }}
              el-col(:span="14")
                p.attributes-tab-field.value(v-if="customAttributesForm[attribute_name].type === 'Date'")
                  span(v-if="attribute_value") {{ attribute_value.replace(/\./gi, '/') }}
                  span(v-else) N/A
                p.attributes-tab-field.value(v-else) {{ `${attribute_value ? attribute_value : 'N/A'} ${attribute_uom && attribute_value ? attribute_uom : ''}` }}
            el-row.row-border(v-else)
              el-col(:span="10")
                p.attributes-tab-field {{ attribute_name }}
              el-col(:span="14")
                el-input(v-if="customAttributesForm[attribute_name].type === 'String'",
                  v-model="customAttributesForm[attribute_name].value",
                  size="small",
                  placeholder="N/A",
                  @blur="handleInput(attribute_name)")
                el-input-number(v-if="customAttributesForm[attribute_name].type === 'Numeric'",
                  size="small",
                  v-model="customAttributesForm[attribute_name].value",
                  @change="handleInput(attribute_name)")
                el-date-picker(v-if="customAttributesForm[attribute_name].type === 'Date'",
                  size="small",
                  v-model="customAttributesForm[attribute_name].value",
                  format="MM/dd/yyyy",
                  placeholder="N/A",
                  :clearable="false",
                  @blur="handleInput(attribute_name)")
                el-select(v-if="customAttributesForm[attribute_name].type === 'List'",
                  filterable
                  clearable
                  :multiple="customAttributeListsOptions[id].isMultiselect"
                  placeholder="Select",
                  v-model="customAttributesForm[attribute_name].value",
                  @change="handleInput(attribute_name)")
                  el-option(v-for='(item) in customAttributeListsOptions[id].listValues', :key='item.id', :label='item.value', :value='item.value')

</template>

<script>
import * as R from 'ramda';
import _ from 'lodash';
import { locations } from '@/api';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ErrorTooltip from '@/pages/Customers/Locations/Controls/ErrorTooltip';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorTooltip,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      metadataList: [],
      form: {
        name: '',
        address: '',
        postcode: '',
        buildingType: '',
        squareFeet: 0,
        city: '',
        state: '',
        country: 'US',
        latitude: '',
        longitude: '',
        payload: {
          siteDivision: '',
          siteNumber: '',
          sitePhone: '',
          siteStatus: '',
          siteFax: '',
        },
      },
      customAttributesForm: {},
      customAttributeListsOptions: {},
    };
  },
  watch: {
    location() {
      this.form = _.merge(
        this.form,
        _.cloneDeep(this.changeLocationObjToCamelCase()),
      );
      this.setCustomAttributesForm();
    },
    metadataList() {
      this.setCustomAttributesForm();
    },
    'form.country': function () {
      this.form.state = '';
    },
  },
  computed: {
    ...mapGetters({
      customerLocationsMetadata: 'locations/customerLocationsMetadata',
      isLoading: 'locations/loading',
      getResourceByName: 'resources/getResourceByName',
      customerId: 'customers/id',
    }),
    states: {
      get() {
        const { country } = this.form;
        return R.pipe(
          R.propOr({}, 'value'),
          R.pickBy((value, key) => R.startsWith(country, key)),
        )(this.getResourceByName('states'));
      },
    },
    buildingType() {
      return (
        this.location &&
        this.location.building_type &&
        this.location.building_type.charAt(0).toUpperCase() +
          this.location.building_type.slice(1).toLowerCase()
      );
    },
    formBuildingType: {
      get() {
        return (
          this.form &&
          this.form.buildingType &&
          this.form.buildingType.charAt(0).toUpperCase() +
            this.form.buildingType.slice(1).toLowerCase()
        );
      },
      set(val) {
        this.form.buildingType = val;
        return val;
      },
    },
    countries: {
      get() {
        return R.propOr({}, 'value', this.getResourceByName('countries'));
      },
    },
    isLocationEmpty() {
      return R.isEmpty(this.location);
    },
    isPayloadEmpty() {
      return R.isEmpty(this.location.payload);
    },
    customAttributes() {
      return this.metadataList.map((e) => ({
        ...e,
        attribute_value: this.getMetadataValue(e.id),
      }));
    },
  },
  async mounted() {
    await this.loadMetadataList();
    await this.$getCustomerLocationsMetadata(this.customerId);
    this.customAttributeListsOptions = this.customerLocationsMetadata
      .filter((metadataAttribute) => {
        return metadataAttribute.attributeType === 'List';
      })
      .reduce((reduced, metadataAttribute) => {
        reduced[metadataAttribute.id] = {
          listValues: metadataAttribute.listValues,
          isMultiselect: metadataAttribute.isMultiselect,
        };
        return reduced;
      }, {});
  },
  methods: {
    ...mapActions({
      $putLocation: 'locations/putLocation',
      $getCustomerLocationsMetadata: 'locations/getCustomerLocationsMetadata',
    }),
    handleInput(key) {
      const { value, initial_value } = this.customAttributesForm[key];
      if (value !== initial_value) {
        this.customAttributesForm[key].wasChanged = true;
      }
      if (value === '' || value === null || value === undefined) {
        this.customAttributesForm[key].value = null;
      }
    },
    removeCountry(state) {
      return (state || 'N/A').replace(/^.*-/g, '');
    },
    async handleSubmit() {
      let metadata = Object.keys(this.customAttributesForm)
        .map((key) => {
          let dateValue = null;

          if (
            this.customAttributesForm[key].value &&
            this.customAttributesForm[key].type === 'Date'
          ) {
            dateValue = DateTime.fromMillis(
              Date.parse(this.customAttributesForm[key].value),
            ).toFormat('MM/dd/yyyy');
          }

          if (this.customAttributesForm[key].type === 'List') {
            return {
              ...this.customAttributesForm[key],
              value: String(this.customAttributesForm[key].value).replaceAll(
                ',',
                ';',
              ),
            };
          }

          return {
            ...this.customAttributesForm[key],
            value: dateValue || String(this.customAttributesForm[key].value),
          };
        })
        .filter((e) => e.wasChanged)
        .map(({ id, value }) => ({ id, value }));

      let payload = _.pickBy(
        this.form.payload,
        (value, key) => key !== 'metadata',
      );

      metadata = this.mergeMetadata(metadata).filter((e) => e.value !== 'null');
      metadata.forEach((item) => {
        payload[item.id] = item.value;
      });
      let data = _.pickBy(this.form, (value, key) => key !== 'payload');
      delete data.weather_active;
      delete data.weather_setup;
      delete data.square_feet;
      delete data.building_type;
      payload = { ...data, payload };
      payload.buildingType = payload.buildingType.toUpperCase();
      if (!_.isEmpty(payload)) {
        try {
          const { customerId, locationId } = this.$route.params;
          await this.$putLocation({
            customerId,
            locationId,
            body: payload,
          });
          this.editMode = false;
        } catch (e) {
          const code = _.get(e, 'response.data.code', 500);
          if (code === 406) {
            try {
              const errors = JSON.parse(
                _.get(e, 'response.data.message', '{}'),
              );
              _.each(errors, (i, key, obj) => {
                this.$refs[key].applyResult({
                  errors: obj[key],
                  valid: false,
                  failedRules: {},
                });
              });
            } catch (e) {
              console.log('invalid json response');
            }
          }
          console.error(e);
        }
      } else {
        this.$message({
          type: 'warning',
          message: 'Nothing to save.',
        });
      }
    },
    async loadMetadataList() {
      const { data } = await locations.getMetaData(
        this.$route.params.customerId,
      );
      data.sort((a, b) => a.sort_order - b.sort_order);
      this.metadataList = data;
    },
    getMetadataValue(id) {
      const metadata = _.get(this.location, 'payload', {});
      const key = Object.keys(metadata).find((key) => key === id);
      return key ? metadata[key] : '';
    },
    setCustomAttributesForm() {
      this.customAttributesForm = this.customAttributes.reduce((o, k) => {
        let isMultiselect = this.customAttributeListsOptions[k.id]
          ? this.customAttributeListsOptions[k.id].isMultiselect
          : null;

        o[k.attribute_name] = {
          id: k.id,
          type: k.attribute_type,
          value:
            k.attribute_type === 'List' && isMultiselect
              ? k.attribute_value.length
                ? k.attribute_value.split(';')
                : []
              : k.attribute_value,
          initial_value:
            k.attribute_type === 'List' && isMultiselect
              ? k.attribute_value.length
                ? k.attribute_value.split(';')
                : []
              : k.attribute_value,
          wasChanged: false,
        };

        return o;
      }, {});
    },
    resetForms() {
      Object.keys(this.customAttributesForm).forEach((key) => {
        this.customAttributesForm[key].value =
          this.customAttributesForm[key].initial_value;
        this.customAttributesForm[key].wasChanged = false;
      });
    },
    mergeMetadata(newMeta) {
      const originMetada = this.location.payload.metadata.reduce((o, k) => {
        o[k.id] = k.value;
        return o;
      }, {});

      const newMetadata = newMeta.reduce((o, k) => {
        o[k.id] = k.value;
        return o;
      }, {});

      const metadata = Object.assign({}, originMetada, newMetadata);

      return Object.keys(metadata).map((e) => ({
        id: e,
        value: metadata[e],
      }));
    },
    changeLocationObjToCamelCase() {
      const locationToCamelCase = _.mapKeys(this.location, (value, key) =>
        _.camelCase(key),
      );
      const payloadLocationCamelCase = _.mapKeys(
        this.location.payload,
        (value, key) => _.camelCase(key),
      );
      delete locationToCamelCase.payload;
      return {
        ...locationToCamelCase,
        payload: { ...payloadLocationCamelCase },
      };
    },
    enableEditMode() {
      this.form = _.merge(
        this.form,
        _.cloneDeep(this.changeLocationObjToCamelCase()),
      );
      this.setCustomAttributesForm();
      this.editMode = true;
    },
    disableEditMode() {
      this.editMode = false;
      this.resetForms();
    },
    setMaximumDecimals(coordinate) {
      coordinate = Number.parseFloat(coordinate);
      if (_.isFinite(coordinate)) {
        return coordinate.toFixed(6);
      }
      return 'N/A';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/constants.scss';

::v-deep.el-select {
  .el-input__suffix {
    display: flex;
    align-items: center;
  }
}

.attributes-tab {
  position: relative;
  padding: 0 0 20px;

  .has-error {
    /deep/ input {
      border: 1px solid red;
      background: rgba(255, 0, 0, 0.1);
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-block-title {
    margin: 20px 0;
    font-size: 16px;
    font-weight: bold;
    display: block;
  }

  &-field {
    margin: 8px 0;
    color: #222222;

    &.value {
      color: #4a4a4a;
    }
  }

  .attributes-tab-field {
    font-weight: 500;
    color: #4a4a4a;
    font-size: 16px;
    line-height: 22px;

    &.value {
      padding-right: 10px;
      padding-left: 10px;
      color: #273a58;
    }
  }

  .location-field {
    margin: 10px 0;
    font-weight: 500;
    color: #4a4a4a;

    &.value {
      padding-right: 10px;
      padding-left: 10px;
      color: #273a58;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .item {
    /deep/ {
      .el-input__icon {
        line-height: 28px;
      }

      .el-input-number--small,
      .el-date-editor.el-input {
        width: 200px;
      }

      .el-input-number__decrease,
      .el-input-number__increase {
        line-height: 28px;
      }
    }
  }

  &_actions {
    position: absolute;
    top: 17px;
    right: 78px;
    z-index: 1;

    @media (max-width: 860px) {
      right: 0;
    }

    .edit-controls-wrapper {
      .el-button {
        height: 28px;
        width: 76px;
        line-height: 28px;
        padding: 0;
      }
    }
  }

  .el-input {
    width: initial;
  }

  .validation-provider-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}
</style>

<style lang="scss">
.attributes-tab {
  .el-row {
    display: block;
  }

  .item {
    .row-border {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .el-input {
      &__inner {
        height: 30px;
        width: 200px;
      }
    }
  }
}
</style>
