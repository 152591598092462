import Vue from 'vue';
import Vuex from 'vuex';

import * as modules from './modules';
import { DateTime } from 'luxon';
import router from '@/router';
import { pickNotEmpty } from '@/lib/utils';
import { has, isNil } from 'ramda';
import { changeRoute } from '@/lib/route';

const billsFiltersPlugin = (store) => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'bills/setLimit':
        return changeRoute({
          method: 'push',
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            limit: String(mutation.payload),
          },
        });
      case 'bills/setOffset': {
        const method = has('offset', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            offset: String(mutation.payload),
          },
        });
      }
      case 'bills/setOrder': {
        const method = has('orders', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            orders: [mutation.payload],
          },
        });
      }
      case 'bills/setCommodities': {
        const method = has('query', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            commodities: [...mutation.payload],
          },
        });
      }
      case 'bills/setVendorCodes': {
        const method = has('vendor_codes', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            vendor_codes: [...mutation.payload],
          },
        });
      }
      case 'bills/setStatuses': {
        const method = has('workflow_states', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            workflow_states: [...mutation.payload],
          },
        });
      }
      case 'bills/setLocations': {
        const method = has('location_ids', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            location_ids: [...mutation.payload],
          },
        });
      }
      case 'bills/setBillTypes': {
        const method = has('bill_types', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: {
            ...router.currentRoute.query,
            bill_types: [...mutation.payload],
          },
        });
      }
      case 'bills/setQuery': {
        const method = has('query', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: pickNotEmpty({
            ...router.currentRoute.query,
            query: mutation.payload,
          }),
        });
      }
      case 'bills/setErrorTypes': {
        const method = has('error_types', router.currentRoute.query)
          ? 'push'
          : 'replace';
        return changeRoute({
          method,
          path: router.currentRoute.path,
          query: pickNotEmpty({
            ...router.currentRoute.query,
            error_types: mutation.payload,
          }),
        });
      }
      case 'bills/setAmountDue':
        return changeRoute({
          method: 'push',
          path: router.currentRoute.path,
          query: pickNotEmpty({
            ...router.currentRoute.query,
            amount_due_min: String(mutation.payload.min),
            amount_due_max: String(mutation.payload.max),
          }),
        });
      case 'bills/setStatementDates':
        return changeRoute({
          method: 'push',
          path: router.currentRoute.path,
          query: pickNotEmpty({
            ...router.currentRoute.query,
            ...{
              statement_date_min: mutation.payload.min
                ? DateTime.fromFormat(
                    new Date(mutation.payload.min).toDateString(),
                    'EEE MMM dd yyyy',
                    { zone: 'utc' },
                  ).toISO()
                : '',
            },
            ...{
              statement_date_max: mutation.payload.max
                ? DateTime.fromFormat(
                    new Date(mutation.payload.max).toDateString(),
                    'EEE MMM dd yyyy',
                    { zone: 'utc' },
                  ).toISO()
                : '',
            },
          }),
        });
      case 'bills/setLoadedDates':
        return changeRoute({
          method: 'push',
          path: router.currentRoute.path,
          query: pickNotEmpty({
            ...router.currentRoute.query,
            ...{
              loaded_date_min: mutation.payload.min
                ? DateTime.fromFormat(
                    new Date(mutation.payload.min).toDateString(),
                    'EEE MMM dd yyyy',
                    { zone: 'utc' },
                  ).toISO()
                : '',
            },
            ...{
              loaded_date_max: mutation.payload.max
                ? DateTime.fromFormat(
                    new Date(mutation.payload.max).toDateString(),
                    'EEE MMM dd yyyy',
                    { zone: 'utc' },
                  ).toISO()
                : '',
            },
          }),
        });
      case 'bills/clearFilters':
        return changeRoute({
          method: 'push',
          path: router.currentRoute.path,
          query: {},
        });
    }
  });
};

const exportsFiltersPlugin = (store) => {
  store.subscribe((mutation) => {
    if (mutation.type === 'exports/setFilter') {
      const filter = mutation.payload.name || null;
      const method = has(filter, router.currentRoute.query)
        ? 'push'
        : 'replace';

      if (filter === 'invoice_date') {
        if (isNil(mutation.payload.value)) {
          return changeRoute({
            method,
            path: router.currentRoute.path,
            query: pickNotEmpty({
              ...router.currentRoute.query,
              invoice_date_min: '',
              invoice_date_max: '',
            }),
          });
        } else {
          return changeRoute({
            method,
            path: router.currentRoute.path,
            query: pickNotEmpty({
              ...router.currentRoute.query,
              invoice_date_min: DateTime.fromFormat(
                new Date(mutation.payload.value[0]).toDateString(),
                'EEE MMM dd yyyy',
                { zone: 'utc' },
              ).toISO(),
              invoice_date_max: DateTime.fromFormat(
                new Date(mutation.payload.value[1]).toDateString(),
                'EEE MMM dd yyyy',
                { zone: 'utc' },
              ).toISO(),
            }),
          });
        }
      }

      return changeRoute({
        method,
        path: router.currentRoute.path,
        query: pickNotEmpty({
          ...router.currentRoute.query,
          [filter]: mutation.payload.value,
        }),
      });
    }
  });
};

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  plugins: [billsFiltersPlugin, exportsFiltersPlugin],
});

if (module.hot) {
  module.hot.accept(['./modules'], () => {
    store.hotUpdate({
      modules: require('./modules'),
    });
  });
}

export default store;
