<template lang="pug">
el-dialog(class="modal-upload-dialog-result",
          :title="title",
          width='563px',
          :visible.sync='showResultModal',
          @close="close")
  .modal-upload-wrapper
    .result-count-wrapper
      .result-count-item(v-if="validations.locationsCreated")
        span.value {{ validations.locationsCreated }}
        span locations created
        i.icon.icon-check-mark
      .result-count-item(v-if="validations.locationsGeocoded")
        span.value {{ validations.locationsGeocoded }}
        span geocoded locations
        i.icon.icon-check-mark
      .result-count-item(v-if="hasGeocodedErrors")
        span.value {{ validations.failed_geocode_locations.length }}
        span failed geocoded locations
        i.icon.icon-exclamation-mark
      .table-wrapper
        el-table.with-shadow(v-if="hasGeocodedErrors",
          width="100%",
          :data="validations.failed_geocode_locations")
          el-table-column(label='Description', prop='name')
          el-table-column(label='Address', prop='address')
          el-table-column(label='')
            template(slot-scope="scope")
              router-link.icon.icon-select-link(v-if="scope.row.id",
                :to="{name: 'customer.locations.info', params: {locationId: scope.row.id}}",
                target="_blank")
    .button-wrapper-result
      el-button.submit-button(@click="submitResult", type="primary") OK
</template>

<script>
import { pathOr } from 'ramda';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    validations: {
      type: Object,
      required: true,
    },
  },
  watch: {
    visible(val) {
      this.showResultModal = val;
    },
  },
  data() {
    return {
      showResultModal: false,
    };
  },
  computed: {
    hasGeocodedErrors() {
      return (
        pathOr(0, ['validations', 'failed_geocode_locations', 'length'], this) >
        0
      );
    },
  },
  methods: {
    submitResult() {
      this.$emit('result');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
