<template>
  <BaseModal
    :dialogVisible="showModal"
    :title="title"
    :width="'33%'"
    @close="close"
  >
    <template #content>
      <p>
        Weather-active attribute set to “on” for
        {{ bulkResults.totalUpdated }} locations
      </p>
      <ErrorCard title="" :statusMessage="statusMessage" />
    </template>
    <template #footer>
      <el-button class="button-size" @click="close" type="primary"
        >OK</el-button
      >
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import ErrorCard from '@/components/Cards/ErrorCard';

export default {
  components: {
    BaseModal,
    ErrorCard,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    bulkResults: {
      type: Object,
      required: true,
    },
  },
  watch: {
    visible(val) {
      this.showModal = val;
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    statusMessage() {
      if (this.bulkResults && this.bulkResults.error) {
        return `${this.bulkResults.error}`;
      }
      return '';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.error-table {
  max-height: 35vh;
  overflow-y: auto;
  .error-reason {
    color: #f86259;
  }
}
p {
  word-break: normal;
  text-align: justify;
}
</style>
