<template>
  <BaseModal
    :dialogVisible="showDwResultModal"
    :title="title"
    :width="hasDownloadErrors ? '75%' : '50%'"
    @close="close"
  >
    <template #content>
      <p>
        Energy Star scores downloaded for
        <b>
          {{ downloadResults.totalLocations }} locations (
          {{ downloadResults.totalProperties }} Energy Star properties)
        </b>
        from {{ startMonth }} through {{ endMonth }}
      </p>
      <ErrorCard title="" :statusMessage="statusMessage" />
      <div class="table-wrapper error-table" v-if="hasDownloadErrors">
        <el-table
          class="with-shadow"
          v-if="downloadResults.errors"
          width="100%"
          :cell-class-name="checkStatus"
          :data="downloadResults.errors"
        >
          <el-table-column label="Date" prop="date"></el-table-column>
          <el-table-column label="Location" prop="location"></el-table-column>
          <el-table-column label="Reason" prop="reason"></el-table-column>
        </el-table>
      </div>
    </template>
    <template #footer>
      <el-button class="button-size" @click="close" type="primary"
        >OK</el-button
      >
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import ErrorCard from '@/components/Cards/ErrorCard';
import { DateTime } from 'luxon';

export default {
  components: {
    BaseModal,
    ErrorCard,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    downloadResults: {
      type: Object,
      required: true,
    },
  },
  watch: {
    visible(val) {
      this.showDwResultModal = val;
    },
  },
  data() {
    return {
      showDwResultModal: false,
      startMonth: '',
      endMonth: '',
    };
  },
  computed: {
    hasDownloadErrors() {
      return (
        this.downloadResults &&
        this.downloadResults.errors &&
        this.downloadResults.errors.length
      );
    },
    statusMessage() {
      if (this.hasDownloadErrors) {
        return `${this.downloadResults.errors.length} Energy Star Scores could not be downloaded. Check the list below for more information.`;
      }
      return '';
    },
  },
  beforeUpdate() {
    if (this.downloadResults && this.downloadResults.startDate) {
      this.startMonth = DateTime.fromFormat(
        this.downloadResults.startDate,
        'yyyy-LL-dd',
      ).toFormat('LLLL y');
    }
    if (this.downloadResults && this.downloadResults.endDate) {
      this.endMonth = DateTime.fromFormat(
        this.downloadResults.endDate,
        'yyyy-LL-dd',
      ).toFormat('LLLL y');
    }
  },
  methods: {
    checkStatus({ row, column }) {
      if (column.property === 'reason') {
        return 'error-reason';
      }
    },
    close() {
      this.startMonth = '';
      this.endMonth = '';
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.error-table {
  max-height: 35vh;
  overflow-y: auto;
  .error-reason {
    color: #f86259;
  }
}
p {
  word-break: normal;
  text-align: justify;
}
</style>
