<template lang="pug">
  .page-container
    .page-header
      template(v-if="!previewMode")
        .page-header-title Create Bill Batch From Directory
      template(v-else)
        .page-header-title Confirm Bill Batch From Directory
        .page-header-subtite You are uploading the following set of bills:
    .page-content
      .confirm-field
        label Google Drive Directory ID:
        span {{ status.folderId }}
          a(:href="folderLink", target="_blank") [View]
      .confirm-field
        label Google Drive Directory Name:
        span {{ status.folderName }}
      .confirm-field
        label Number of bills:
        span {{ status.pdfCount }}
      .confirm-field
        label Bill Batch Name:
        el-input(v-if="!previewMode", v-model="batchName", size="small")
        span(v-else) {{ batchName }}
      .confirm-field
        label Bill Batch Type:
        el-select(v-if="!previewMode", v-model="batchType", size="small" filterable)
          el-option(v-for="option in batchTypeOptions", :key="option.value", :value="option.value", :label="option.label")
        span(v-else) {{ batchType }}
    .confirm-action
      el-button.plain-button-reverse(size="small", @click="$router.replace( { name: 'customer.uploads' } )") Cancel
      el-button(v-if="!previewMode", type="primary", size="small", :disabled="!batchName.length || !batchType", @click="next") Next
      el-button(v-else, type="primary", size="small", @click="start") Start Upload

    upload-modal(:show-modal="isUploadModalOpened", :status="batchProcessingStatus")

</template>

<script>
import { hosts } from '@/lib/api.configs';
import { batchTypes } from '@/lib';
import { mapState, mapGetters } from 'vuex';
import UploadModal from './UploadModal.vue';

export default {
  components: {
    UploadModal,
  },
  data() {
    return {
      batchName: '',
      batchType: '',
      batchTypeOptions: batchTypes,
      previewMode: false,
      isUploadModalOpened: false,
      batchProgress: null,
      batchProcessingStatus: [],
    };
  },
  computed: {
    ...mapGetters({
      status: 'batches/batchStatus',
    }),
    ...mapState({
      customer: (state) => state.customers.customer || {},
    }),
    folderLink() {
      return `https://drive.google.com/open?id=${this.status.folderId}`;
    },
  },
  mounted() {
    this.setBatchName();
  },
  methods: {
    cancel() {
      if (this.previewMode) {
        this.previewMode = false;
      }
    },
    next() {
      this.previewMode = true;
    },
    start() {
      // this.isUploadModalOpened = true;
      // const res = await batches.post(this.customer.id, {
      //   folder: this.status.folderId,
      //   batchType: this.batchType,
      //   name: this.batchName
      // });

      this.isUploadModalOpened = true;

      fetch(`${hosts.v1}/customers/${this.customer.id}/batches`, {
        method: 'post',
        headers: new Headers({
          Authorization:
            localStorage.getItem('authToken') &&
            `Bearer ${localStorage.getItem('authToken')}`,
        }),
        body: JSON.stringify({
          folder: this.status.folderId,
          batchType: this.batchType,
          name: this.batchName,
        }),
      })
        .then((response) => {
          const reader = response.body.getReader();
          const processMessage = ({ done, value }) => {
            if (!done) {
              proceed(reader);
              const decodedValue = new TextDecoder('utf-8').decode(value);
              decodedValue
                .split('\n')
                .filter((e) => e.length)
                .map(JSON.parse)
                .forEach((e) => this.batchProcessingStatus.push(e));
            }
          };
          const proceed = (reader) => {
            reader.read().then(processMessage);
          };
          proceed(reader);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setBatchName() {
      this.batchName = this.status.folderName;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  flex-wrap: wrap;
}
.page-header-subtite {
  width: 100%;
  margin-top: 20px;
}
.page-content {
  padding-top: 20px;
}
.confirm-field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    min-width: 250px;
  }
  span {
    color: #222222;
    font-weight: 600;

    a {
      color: #2893f9;
      margin-left: 8px;
    }
  }
  .el-input,
  .el-select {
    width: 200px;
  }
}
.confirm-action {
  margin-top: 40px;

  .el-button {
    width: 170px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;

    + .el-button {
      margin-left: 30px;
    }
    &.is-disabled {
      background-color: #a4a4a4;
      border: 1px solid #a4a4a4;
    }
  }
}
</style>
<style lang="scss">
@import '../../../styles/constants.scss';

.confirm-field {
  .el-input__inner {
    padding-left: 10px;
    padding-right: 10px;
    color: #222222;
    font-size: 14px;
  }
}
</style>
