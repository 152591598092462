<template>
  <div class="page-header">
    <h1 class="page-title link clickable" v-if="vendor.code" @click="goBack()">
      <i class="icon icon-arrow-pointing-to-right"></i>
      <span class="text">Vendor Details for {{ vendor.code }}</span>
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    vendor: Object,
    backPath: {
      type: String,
      default: '/vendors',
    },
  },
  methods: {
    goBack() {
      this.$router.push({ path: this.backPath });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;

  .page-title {
    display: flex;
    align-items: center;
    margin-top: 9px;
    font-weight: 600;
    font-size: 20px;
  }
}
</style>
