<template>
  <div>
    <el-select
      v-if="editMode"
      v-model="value"
      :multiple="multiple"
      placeholder="Select"
      class="full-width"
      @change="handleSelect"
      filterable
      clearable
    >
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item"
        :value="item"
      ></el-option>
    </el-select>
    <span v-else>
      <span v-if="value">{{ multiple ? value.join(';') : value }}</span>
      <span v-else>N/A</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    initialValue: String,
    options: Array,
    editMode: Boolean,
    multiple: Boolean,
  },
  data() {
    return {
      value: '',
    }
  },
  mounted() {
    this.adaptData()
  },
  watch: {
    editMode() {
      this.adaptData()
    },
  },
  methods: {
    adaptData() {
      if (this.initialValue && this.$props.multiple) {
        this.value = this.initialValue.split(';')
      } else {
        this.value = this.initialValue
      }
    },
    handleSelect() {
      this.$emit('list-select', this.value)
    },
  },
}
</script>

<style scss scoped>
@import './style.scss';
</style>
