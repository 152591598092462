<template lang="pug">
  div
    .button-wrapper
      .button-container
        el-button(type='text',@click="openAdminModal", data-action="location-admin-button") Location Admin
      .button-dropdown
        el-button.create-location-button(type='text', data-action="create-location-button") Add Locations +
        .menu-container
          .menu-item(@click="openFileForm", data-action='create-location-from-csv') Upload Location File
          .menu-item(@click="openCreateLocationForm", data-action='create-location') Create Location

    success-modal(:visible="showResultModal",
                  :validations="validations",
                  title="The CSV Location File was uploaded successfully.",
                  @result="handleResult",
                  @close="close('showResultModal')")

    upload-modal(:visible="showUploadModal",
                title="Upload Location File",
                label-text="CSV Location File",
                button-text="Add Locations",
                :confirm-upload-error="confirmUploadError",
                :errorMsg="errorMsg",
                @success="handleSuccessUpload",
                @error="handleErrorUpload",
                @close="close('showUploadModal')")

    admin-modal(:visible="showAdminModal",
                title="Location Admin",
                :isWeatherEntitlement="entitlements.weather"
                @close="close('showAdminModal')",
                @success="handleSuccessESDownload"
                @bulkWeatherSetup="handleBulkWeatherSetupSuccess"
    )

    energy-star-locations-downloaded(:visible="showDwResultModal",
                title="Locations Downloaded",
                @result="handleDwResult",
                :downloadResults="downloadResults",
                @close="close('showDwResultModal')",
    )

    confirm-upload-modal(:visible="showConfirmUploadModal",
                        title="Upload Location File",
                        :validations="validations",
                        :file-data="fileData",
                        @success="handleSuccessConfirmUpload",
                        @error="handleErrorConfirmUpload",
                        @close="close('showConfirmUploadModal')")

    create-location-modal(:visible="showCreateLocationModal",
                          title="Create a New Location",
                          @close="close('showCreateLocationModal')")

    bulk-locations-weather-enabled-modal(:visible="showBulkWeatherSetupSuccess",
                        title=""
                        :bulkResults="bulkResults"
                        @close="close('showBulkWeatherSetupSuccess')")
</template>

<script>
import UploadModal from './Modals/UploadModal.vue';
import AdminModal from './Modals/LocationAdmin.vue';
import EnergyStarLocationsDownloaded from './Modals/EnergyStarLocationsDownloaded.vue';
import ConfirmUploadModal from './Modals/ConfirmUploadModal.vue';
import SuccessModal from './Modals/SuccessModal.vue';
import CreateLocationModal from './Modals/CreateLocationModal.vue';
import BulkLocationsWeatherEnabledModal from './Modals/BulkLocationsWeatherEnabledModal';

const changesBoilerplate = () => ({
  changes: [],
  num_updated_locations: 0,
  num_created_locations: 0,
  num_geocode_locations: 0,
  failed_geocode_locations: [],
});

export default {
  components: {
    UploadModal,
    ConfirmUploadModal,
    SuccessModal,
    AdminModal,
    CreateLocationModal,
    EnergyStarLocationsDownloaded,
    BulkLocationsWeatherEnabledModal,
  },
  data() {
    return {
      showCreateLocationModal: false,
      showUploadModal: false,
      showAdminModal: false,
      showDwResultModal: false,
      showConfirmUploadModal: false,
      showBulkWeatherSetupSuccess: false,
      validations: changesBoilerplate(),
      downloadResults: {},
      bulkResults: {},
      isUploadFetching: false,
      errorMsg: '',
      showResultModal: false,
      confirmUploadError: false,
      fileData: [],
    };
  },
  props: {
    entitlements: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openFileForm() {
      this.confirmUploadError = false;
      this.showUploadModal = true;
    },
    openAdminModal() {
      this.confirmUploadError = false;
      this.showAdminModal = true;
    },
    handleResult() {
      this.showResultModal = false;
      this.validations = changesBoilerplate();
    },
    handleDwResult() {
      this.showDwResultModal = false;
      this.downloadResults = {};
    },
    handleSuccessUpload(payload) {
      this.validations = { ...payload.validations };
      this.fileData = [...payload.data];
      this.showConfirmUploadModal = true;
    },
    handleErrorUpload() {
      this.confirmUploadError = false;
    },
    handleSuccessConfirmUpload(data = {}) {
      this.validations = { ...data };
      this.showResultModal = true;
    },
    handleSuccessESDownload(data = {}) {
      this.downloadResults = data;
      this.showAdminModal = false;
      this.showDwResultModal = true;
    },
    handleBulkWeatherSetupSuccess(data = {}) {
      this.bulkResults = data;
      this.showAdminModal = false;
      this.showBulkWeatherSetupSuccess = true;
    },
    handleErrorConfirmUpload(e) {
      this.errorMsg = e;
      this.confirmUploadError = true;
      this.showUploadModal = true;
    },
    openCreateLocationForm() {
      this.showCreateLocationModal = true;
    },
    close(type) {
      this[type] = false;
      if (
        [
          'showCreateLocationModal',
          'showResultModal',
          'showBulkWeatherSetupSuccess',
        ].includes(type)
      ) {
        this.$emit('updated');
      }
    },
  },
};
</script>

<style lang="scss">
.modal-upload-dialog-result {
  .result-count-wrapper {
    margin: 16px 0 42px;
    .result-count-item {
      width: 272px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 22px;

      i {
        margin-left: auto;
      }

      span {
        color: #3b3e48;
        font-size: 16px;
        line-height: 22px;

        &.value {
          width: 40px;
          font-weight: 900;
        }
      }
    }
  }
}

.modal-upload-dialog-submit .el-dialog {
  margin-top: 5vh !important;
}
.modal-upload-wrapper {
  /deep/ .table-wrapper {
    padding: 0 18px;
    .el-table {
      max-height: 740px;
      overflow-y: scroll;
      box-shadow: none;
      .cell {
        line-height: 24px;
      }
    }
  }
}

.modal-validate-wrapper {
  > p {
    margin-top: 0;
    color: #575757;
    font-size: 12px;
    line-height: 20px;
  }

  /deep/ .table-wrapper {
    padding: 0 18px;
    .el-table {
      max-height: 740px;
      overflow-y: scroll;
      box-shadow: none;
      .cell {
        line-height: 24px;
      }
    }
  }

  .counter-container {
    margin-bottom: 23px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .counter-item {
      margin-left: 26px;
      > span {
        color: #7d89a6;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: right;

        &.value {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 900;
          line-height: 22px;
          text-align: right;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px 0 16px;

    > .el-button {
      padding: 2px 20px;
      height: 30px;
      width: 170px;

      &:last-child {
        margin-left: 40px;
      }
    }
  }
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .button-container {
    margin-right: 16px;
    position: relative;
  }
  .button-dropdown {
    margin-right: 16px;
    position: relative;
    .menu-container {
      display: none;
      position: absolute;
      top: 32px;
      left: 0;
      z-index: 99;
      overflow: hidden;
      box-shadow: 0 1px 2px 0 rgba(194, 194, 194, 0.5);
      border-radius: 8px;

      .menu-item {
        cursor: pointer;
        background: #fff;
        white-space: nowrap;
        padding: 8px 16px;
        color: #222222;
        font-size: 12px;
        line-height: 16px;

        &:first-child {
          border: 1px solid #efefef;
        }

        &:hover {
          color: #2893f9;
        }
      }
    }
    &:hover {
      .menu-container {
        display: block;
      }
    }
  }
}
.button-wrapper-result {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .el-button {
    padding: 2px 20px;
    height: 30px;
    width: 170px;
  }
}

form.create-location {
  h3 {
    color: #273a58;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 0 15px 0;
  }

  .hr {
    height: 1px;
    background: #dcdfe6;
    margin: 19px 0 15px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .form-group {
      display: flex;
      flex: 0 0 calc(50% - 20px);
      /*flex: 0 0 50%;*/
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }

      .el-input,
      .el-select {
        height: 30px;
        width: 200px;
        &__inner {
          height: 30px;
        }

        .el-input__icon {
          line-height: normal;
        }
        &.has-error {
          input {
            border: 1px solid red;
            background: rgba(255, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0;

    button {
      height: 30px;
      width: 170px;
      padding: 0;
      margin: 0 20px;
    }
  }
}
</style>
