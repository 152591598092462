<template lang="pug">
  .location-hierarchy.page-container(:class='{"edit-mode": editMode}')
    .page-header
      h1.page-header-title {{ hName }} Location Hierarchy for {{ customer.name }}
      .page-header-actions
        el-button.edit-hierarchy-button(type='text', @click='toggleEditMode')
          i.icon.icon-edit-filled-blue(v-if="!editMode")
          | {{ `${editMode ? 'Done Editing' : 'Edit'}` }}
    .hierarchy-search.with-shadow
      i.icon-search
      el-input(v-model="query", placeholder='Search')
    el-row.hierarchy-container(type='flex')
      el-col.hierarchy-tree(:span='12')
        tree-root(v-if="(root)",
          :item="root",
          :rootOpened="rootOpened",
          @fetchTree="fetchTree",
          @changeRootState="changeRootState",
          @openCreateLocationGroupModal="openCreateLocationGroupModal",
          @openAssignLocationModal="openAssignLocationModal",
          @openDeleteModal="openDeleteModal")
        ul.hierarchy-tree_root.nested-wrapper(v-if="(treeData || []).length", ref="hierarchyRoot", :class='{"opened": rootOpened}')
          vue-nestable(v-model="treeData",
            @change="handleChange",
            cross-list,
            group="cross",
            key-prop="id",
            children-prop="children")
            template(slot-scope="{ item }")
              tree-item(:item='item',
              @fetchTree="fetchTree",
              @openCreateLocationGroupModal="openCreateLocationGroupModal",
              @openAssignLocationModal="openAssignLocationModal",
              @openDeleteModal="openDeleteModal",
              @openUnassignSingleLocationModal="openUnassignSingleLocationModal",
              :selectedPath="selectedPath")

      el-col.hierarchy-details(:span='12', :style='{top: `${hierarchyDetailsTopPosition}px`}')
        .header-wrapper
          h3.hierarchy-title(v-if="selectedItem && editMode && !detailsActive && (selectedItem || {}).location_id") Unassigned Locations
          h3.hierarchy-title(v-else-if="selectedItem && (selectedItem || {}).location_id") {{ selectedItem.location.name }} Location Details
          h3.hierarchy-title(v-else) {{ detailsActive ? `${selectedName} Location Group Details` : `Unassigned Locations`}}
          ul.switcher(v-if="editMode")
            li(:class='{"active": detailsActive}', @click="selectDetails") Details
            li(:class='{"active": !detailsActive}', @click="selectUnassignedLocations") Unassigned&nbsp;Locations
        .locations-wrapper(v-if="!detailsActive && editMode")
          .locations-wrapper-header Drag locations to a location group to assign them.
          vue-nestable(v-model="unmachedLocations", cross-list, group="cross", class="locations-list",  @change="handleLocationsChange",)
            template(slot-scope="{ item }")
              li
                i(class="el-icon-location")
                span {{ `${item.name}, ${item.address}, ${item.city || ''}, ${item.state || ''}` }}
                vue-nestable-handle(:item="item")
                  i(class="el-icon-rank")
        .details-wrapper(v-if="detailsActive")
          ul.controls(v-if="selectedItem && !selectedItem.location_id && editMode")
            li(@click="openCreateLocationGroupModal") Add Child Location Group
            li(@click="openAssignLocationModal") Assign Locations
            li(v-if="selectedItem.id !== ([...treeData][0] || {}).id", @click="openDeleteModal") Remove Location Group
          .details-info-wrapper(v-if="selectedItem")
            .breadcrumbs {{ `${breadcrumbs}${selectedItem.location_id ? selectedItem.location.name : ''}` }}
            .location-actions-wrapper(v-if="selectedItem.location_id")
              span
                router-link.form-link(:to="locationDetailLink") View Locations Details
              span(v-if="editMode", @click="openUnassignSingleLocationModal") Unassign Location
            ul(v-if="!selectedItem.location_id")
              li
                span Direct Groups
                span {{ directItems.directGroups }}
              li
                span Direct Child Locations
                span {{ directItems.directLocations }}
              li
                span Nested Groups
                span {{ nestedItems.nestedGroups }}
              li
                span Nested Locations
                span {{ nestedItems.nestedLocations }}
              li(v-if="!selectedItem.parent_id")
                span Unassigned Locations
                span {{ (locations || []).length }}
      el-dialog.modal-dialog(:title='createLocationGroupModalTitle',
        width='440px',
        :visible.sync='createLocationGroupModal',
        @close="resetLocationGroupModalData")
        create-location-group-modal(@createLocationGroup='createLocationGroup',
          @close-modal='closeCreateLocationGroupModal',
          :breadcrumbs="breadcrumbs",
          :validateLocationGroup="validateLocationGroup")
      el-dialog.modal-dialog(v-if='assignLocationModal', :title='assignLocationTitle', width='980px', :visible.sync='assignLocationModal', custom-class="locations-dialog", :modal-append-to-body="false")
        assign-location-modal(@assignLocations='assignLocations', @close-modal='closeAssignLocationModal', :breadcrumbs="breadcrumbs", :routeParams="$route.params", :item="selectedItem")
      el-dialog.delete-modal(:visible.sync='deleteModal',
          :modal='false',
          :show-close='false',
          top='45px',
          width='410px',
          :title="`Are you sure you want to remove ${(selectedItem || {}).name} and all child sub-groups in this location group?`")
        delete-modal(@closeDeleteModal='closeDeleteModal',
        @confirmDelete='deleteItem',
        @confirmCancel='closeDeleteModal',
        :locationsForDelete="nestedItems.nestedLocations",
        :groupsForDelete="nestedItems.nestedGroups")
      el-dialog.unassign-modal(:visible.sync='unassignSingleLocationModal',
          :modal='false',
          :show-close='false',
          custom-class="locations-unassign-dialog",
          top='45px',
          width='410px',
          :title="`Are you sure you want to unassign ${locationData.location_name} location from ${locationData.name} location group?`")
        unassign-modal(@closeUnassignSingleLocationModal='closeUnassignSingleLocationModal',
        @confirmDelete='deleteItem',
        @confirmCancel='closeUnassignSingleLocationModal',
        :locationData="locationData")
      el-dialog.unassign-modal(:visible.sync='creatGroupWarning',
        :modal='false',
        :show-close='false',
        custom-class="create-group-warning",
        top='45px',
        width='410px',
        title="Nesting Locations Error:")
        create-group-warning-modal(@close='closeCreateGroupWarning')
</template>

<script>
import { Events } from '@/lib';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import * as R from 'ramda';
import TreeItem from './TreeItem.vue';
import CreateLocationGroupModal from './CreateLocationGroupModal';
import AssignLocationModal from './AssignLocationModal';
import CreateGroupWarningModal from './CreateGroupWarningModal';
import DeleteModal from './DeleteModal';
import UnassignModal from './UnassignModal';
import TreeRoot from './TreeRoot';

export default {
  order: 14,
  components: {
    TreeItem,
    CreateLocationGroupModal,
    CreateGroupWarningModal,
    AssignLocationModal,
    DeleteModal,
    UnassignModal,
    TreeRoot,
  },
  data() {
    return {
      creatGroupWarning: false,
      unmachedLocations: [],
      detailsActive: true,
      selectedId: null,
      createLocationGroupModal: false,
      assignLocationModal: false,
      deleteModal: false,
      unassignSingleLocationModal: false,
      query: '',
      treeData: [],
      flatTreeData: [],
      rootOpened: false,
      hierarchyDetailsTopPosition: 160,
      selectedPath: [],
    };
  },
  computed: {
    ...mapState({
      selectedItemId: (state) => state.hierarchy.selectedItemId,
      data: (state) => state.hierarchy.data,
      isFetching: (state) => state.isFetching,
      locations: (state) => state.hierarchy.locations,
      customer: (state) => state.customers.customer,
      editMode: (state) => state.hierarchy.editMode,
    }),
    root() {
      return [...this.data].find((e) => !e.parent_id);
    },
    tree() {
      return this.buildTree();
    },
    hName() {
      return _.get(this.tree, '0.name', null);
    },
    selectedName() {
      if (this.selectedItem) return _.get(this.selectedItem, 'name', null);
      return _.get(this.tree, '0.name', null);
    },
    selectedItem() {
      return (
        this.data.find((item) => item.id === this.selectedItemId) ||
        this.tree[0]
      );
    },
    breadcrumbs() {
      const { data } = this;
      if (!this.selectedItem) {
        return _.get(this.tree, '0.name', '');
      }
      return [
        ...(this.selectedItem.path || []).map(
          (id) => data.find((i) => i.id === id).name,
        ),
        this.selectedItem.name,
      ].join(' > ');
    },
    nestedItems() {
      let countLocations = 0;
      let countGroups = 0;
      if (this.selectedItem) {
        let item = this.selectedItem;

        const calculate = (item) => {
          (item.children || []).forEach((child) => {
            if (child.location_id) {
              countLocations++;
            } else if (child.name) {
              countGroups++;
            }

            calculate(child);
          });
        };
        calculate(item);
      }
      return { nestedGroups: countGroups, nestedLocations: countLocations };
    },
    directItems() {
      let countLocations = 0;
      let countGroups = 0;
      if (this.selectedItem) {
        let item = this.selectedItem;

        (item.children || []).forEach((child) => {
          if (child.location_id) {
            countLocations++;
          } else if (child.name) {
            countGroups++;
          }
        });
      }
      return { directGroups: countGroups, directLocations: countLocations };
    },
    createLocationGroupModalTitle() {
      return `Create a New Location Group under ${_.get(
        this.selectedItem,
        'name',
        '',
      )}`;
    },
    assignLocationTitle() {
      return `Assign Locations to ${_.get(
        this.selectedItem,
        'name',
        '',
      )} location group`;
    },
    locationDetailLink() {
      if (!this.selectedItem || !this.customer) return '';
      return `/customers/${this.customer.id}/locations/${this.selectedItem.location_id}`;
    },
    locationData() {
      if (!this.selectedItem) return {};
      if (!this.selectedItem.location_id) return {};
      const {
        parent_id,
        location: { name: location_name },
      } = this.selectedItem;
      const { name } = this.data.find((item) => item.id === parent_id);
      return { name, location_name };
    },
  },
  watch: {
    editMode(val) {
      if (!val) {
        this.detailsActive = true;
        this.setSelfEditMode(null);
      } else {
        this.rootOpened = true;
      }
    },
    query(value) {
      const flatTree = R.clone(this.flatTreeData);
      if (!value) {
        this.treeData = this.buildTree(flatTree);
        return;
      }
      this.rootOpened = true;
      let matchedPaths = [];
      const data = R.map((node) => {
        const name =
          R.propOr(false, 'name', node) ||
          R.pathOr('', ['location', 'name'], node);
        value = value.toLowerCase();
        const path = R.propOr([], 'path', node);
        if (name && name.toLowerCase().includes(value)) {
          node.query = value;
          matchedPaths = [...matchedPaths, ...path];
        }
        return node;
      })(flatTree);
      matchedPaths = R.uniq(matchedPaths);
      const addOpened = (v) => {
        if (matchedPaths.includes(v.id)) v.isOpened = true;
        return v;
      };
      this.treeData = this.buildTree(R.map(addOpened)(data));
    },
  },
  async mounted() {
    await this.fetchTree();
    this.selectItem(this.data.find((i) => !i.parent_id).id);
    await this.fetchLocations();
    if (_.get(this.$route, 'query.editMode', null)) {
      this.setEditMode(true);
    }
    if (Object.keys(this.$route.query).length > 0) {
      this.$router.replace({ query: {} });
    }
    const selected = _.get(this.$route, 'query.selected', null);
    if (selected) {
      const node = this.data.slice().find((item) => item.id === selected) || {};
      this.selectedPath = [...(node.path || []), selected];
      this.rootOpened = true;
      this.selectItem(selected);
    }
    document
      .querySelector('main.main-container')
      .addEventListener('scroll', this.handleWindowScroll);
    this.$refs.hierarchyRoot.addEventListener(
      'DOMNodeInserted',
      this.onDomNodeInsertedHandler,
    );
  },
  beforeDestroy() {
    this.clearState();
  },
  methods: {
    ...mapActions({
      createItemsBatch: 'hierarchy/createItemsBatch',
      fetchHierarchy: 'hierarchy/fetchHierarchy',
      fetchUnmachedLocations: 'hierarchy/fetchUnmachedLocations',
      deleteHierarchyItem: 'hierarchy/deleteHierarchyItem',
      patchHierarchyItem: 'hierarchy/patchHierarchyItem',
      createGroup: 'hierarchy/createGroup',
      setEditMode: 'hierarchy/setEditMode',
      clearState: 'hierarchy/clearState',
      selectItem: 'hierarchy/selectItem',
      setSelfEditMode: 'hierarchy/setSelfEditMode',
      insertLocationsBulk: 'hierarchy/insertLocationsBulk',
    }),
    async fetchLocations() {
      this.unmachedLocations = await this.fetchUnmachedLocations(
        this.$route.params,
      );
    },
    handleLocationsChange() {
      return false;
    },
    onDomNodeInsertedHandler(e) {
      if (!e.target) return false;
      if (e.target.nodeType !== 1) return false;
      // [todo] refactor this
      if (!e.target.classList.contains('is-dragging')) return false;
      const nestableItem = e.target.closest('.nestable-item');
      if (nestableItem) {
        if (nestableItem.classList.contains('is-dragging')) {
          nestableItem.closest('ol').classList.add('is-opened');
          return;
        }
      }

      const nestableList = e.target;
      if (e.target.classList.contains('nestable-list')) {
        const nestableItem = e.target.querySelector('.is-dragging');
        if (nestableItem) {
          nestableList.classList.add('is-opened');
        }
      }

      this.$refs.hierarchyRoot.querySelectorAll('ol').forEach((el) => {
        if (!el.classList.contains('is-opened')) {
          el.classList.add('is-opened');
        }
      });
    },
    handleWindowScroll(e) {
      const scrollTop = _.get(e, 'target.scrollTop', 0);

      const top = 160 - scrollTop;
      if (top >= 26 && top <= 160) {
        this.hierarchyDetailsTopPosition = top;
      }

      if (top > 160) {
        this.hierarchyDetailsTopPosition = 160;
      }

      if (top < 26) {
        this.hierarchyDetailsTopPosition = 26;
      }
    },
    selectDetails() {
      this.detailsActive = true;
    },
    selectUnassignedLocations() {
      this.detailsActive = false;
    },
    toggleEditMode() {
      this.setEditMode(!this.editMode);
    },
    async handleChange(val, { items, pathTo = [] }) {
      const root = this.data.find((i) => !i.parent_id);
      if (val.path && !pathTo.length) {
        await this.deleteHierarchyItem({
          params: this.$route.params,
          id: val.id,
        });
        await this.fetchTree(this.$route.params);
        await this.fetchLocations();
        this.selectItem(root.id);
        return;
      }
      if (!pathTo || !pathTo.length) return;

      let parent = _.get(
        this.treeData,
        pathTo.slice(0, pathTo.length - 1).join('.children.'),
        root,
      );

      if (parent.location_id) {
        parent = null;
      }

      const siblingPath = [
        ...pathTo.slice(0, pathTo.length - 1),
        pathTo[pathTo.length - 1] + 1,
      ];
      const sibling = _.get(
        this.treeData,
        siblingPath.join('.children.'),
        null,
      );

      const order = parseInt(_.get(sibling, 'order_value', 0));

      const body = {
        ...(parent && { parent_id: parent.id }),
        ...(order >= 0 && { order_value: order + 1 }),
      };

      if (_.isEmpty(body) || !body.parent_id) {
        await this.fetchTree();
        await this.fetchLocations();
        return;
      }

      if (val.name && val.address && val.id) {
        const batch = [{ ...body, location_id: val.id }];
        await this.createItemsBatch({
          params: this.$route.params,
          body: batch,
        });
        await this.fetchTree();
        await this.fetchLocations();
        return;
      }

      await this.patchHierarchyItem({
        params: { ...this.$route.params, itemId: val.id },
        body,
      });
      await this.fetchTree();
    },
    async fetchTree() {
      const data = await this.fetchHierarchy(this.$route.params);
      this.flatTreeData = R.clone(data);
      this.treeData = this.buildTree(data);
    },
    expandNestableItem() {
      if (!this.selectedItem) return;
      const el = this.$refs.hierarchyRoot.querySelector(
        `.nestable-item-${this.selectedItem.id}`,
      );
      if (el) {
        el.querySelector('ol').classList.add('is-opened');
        el.querySelector(`.item-id-${this.selectedItem.id}`).classList.add(
          'is-opened',
        );

        if (el.querySelector('.el-icon-caret-right')) {
          el.querySelector('.el-icon-caret-right').className =
            'el-icon-caret-bottom';
        }
      }
    },
    buildTree(data) {
      const tmp = [];
      if (!data) {
        return [];
      }
      const dataMap = data.slice().reduce((map, node) => {
        if (!node.location_id) {
          node.children = [{ id: node.id + 1, empty: true }];
        }
        map[node.id] = node;
        return map;
      }, {});

      data.slice().forEach((node) => {
        const parent = dataMap[node.parent_id];

        if (parent) {
          (parent.children || (parent.children = [])).push(node);
          parent.children = _.orderBy(
            parent.children,
            [
              (i) => !!i.name,
              (i) => parseInt(i.order_value) || 0,
              'name',
              (i) => _.get(i, 'location.name', 0),
            ],
            ['desc', 'desc', 'desc'],
          );
        } else {
          tmp.push(node);
        }
      });

      return tmp[0].children;
    },
    closeCreateGroupWarning() {
      this.creatGroupWarning = false;
    },
    openCreateLocationGroupModal() {
      if ((this.selectedItem.path || []).length + 1 >= 11) {
        this.creatGroupWarning = true;
      } else {
        this.createLocationGroupModal = true;
      }
    },
    closeCreateLocationGroupModal() {
      this.createLocationGroupModal = false;
    },
    resetLocationGroupModalData() {
      Events.$emit('reset-location-group-modal');
    },
    openAssignLocationModal() {
      this.fetchLocations();
      this.assignLocationModal = true;
    },
    closeAssignLocationModal() {
      this.assignLocationModal = false;
    },
    async createLocationGroup(name) {
      try {
        const { id } = this.selectedItem;
        await this.createGroup({
          params: this.$route.params,
          body: { parent_id: id, name },
        });
        await this.fetchTree();
        this.closeCreateLocationGroupModal();
        this.expandNestableItem();
        this.selectItem(null);
        this.selectItem(id);
      } catch (e) {
        // console.log(e)
        // TODO: Handle errors
        console.log(e.response.data);
      }
    },
    async assignLocations({ insertItems, deleteItems }) {
      try {
        const { id } = this.selectedItem;
        const insert_items = insertItems.map((item) => ({
          location_id: item.id,
          parent_id: id,
          customer_id: this.customer.id,
          order_value: 0,
        }));
        const body = { insert_items, delete_items: deleteItems };

        // return false;
        // await this.createItemsBatch({ params: this.$route.params, body });
        await this.insertLocationsBulk({ params: this.$route.params, body });
        await this.fetchTree(this.$route.params);
        await this.fetchLocations();
        this.closeAssignLocationModal();
        this.expandNestableItem();
        this.selectItem(null);
        this.selectItem(id);
      } catch (e) {
        // TODO: Handle errors
        console.log(e.response.data);
      }
    },
    openDeleteModal() {
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    openUnassignSingleLocationModal() {
      this.unassignSingleLocationModal = true;
    },
    closeUnassignSingleLocationModal() {
      this.unassignSingleLocationModal = false;
    },
    changeRootState() {
      this.rootOpened = !this.rootOpened;
    },
    async deleteItem() {
      const parentId = this.data.find(
        (i) => i.id === this.selectedItem.id,
      ).parent_id;

      await this.deleteHierarchyItem({
        params: this.$route.params,
        id: this.selectedItem.id,
      });
      await this.fetchTree(this.$route.params);
      await this.fetchLocations();
      this.deleteModal = false;
      this.unassignSingleLocationModal = false;

      if (!parentId) {
        this.$router.push({ name: 'customer.hierarchies' });
        return;
      }

      this.expandNestableItem();
      this.selectItem(this.data.find((i) => !i.parent_id).id);
    },
    validateLocationGroup(value) {
      return !this.data.find(({ name }) => name === value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/constants.scss';
.edit-hierarchy-button {
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.hierarchy {
  &-search {
    position: relative;

    .icon-search {
      width: 40px;
      height: 40px;
      position: absolute;
      background: url(../../../../assets/icons/search.svg) center center
        no-repeat;
      z-index: 1;
    }
  }
  &-container {
    margin-top: 15px;
  }
  &-tree {
    margin-top: 20px;
  }
  &-details {
    position: fixed;
    top: 160px;
    right: 56px;
    width: 35vw;
    bottom: 40px;
    padding: 20px 10px 20px 20px;
    background-color: #fff;
  }
  &-title {
    margin: 0;
    word-break: break-all;
    font-weight: 700;
  }
  &-tree {
    &_root {
      list-style: none;
    }
  }
}

.hierarchy-details {
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;

    ul.switcher {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      li {
        color: #606266;
        font-size: 9px;
        line-height: 14px;
        text-align: center;
        padding: 8px;
        border: 1px solid #e8eaed;
        cursor: pointer;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }

        &.active {
          background-color: #e6f1fc;
          border: 1px solid #a3d0fd;
          color: #267de2;
        }
      }
    }
  }
  .details-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

    ul.controls {
      margin: 16px 0 0;
      padding: 0;
      flex-grow: 1;
      flex-basis: 30%;
      list-style: none;
      li {
        padding-bottom: 5px;
        color: #2893f9;
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        text-align: right;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .details-info-wrapper {
      flex-grow: 1;
      flex-basis: 70%;
      .breadcrumbs {
        margin: 16px 0;
        padding: 0;
        word-break: break-all;
        color: #273a58;
        font-size: 10px;
        line-height: 14px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 16px;
          span {
            &:first-child {
              color: #4a4a4a;
              font-size: 16px;
              line-height: 22px;
              width: 200px;
            }
          }
        }
      }

      .location-actions-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          color: #2893f9;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
          text-decoration: underline;
          cursor: pointer;

          a {
            color: #2893f9;
          }

          &:last-child {
            font-size: 12px;
          }
        }
      }
    }
  }
  .locations-wrapper {
    height: calc(100% - 92px);
    .locations-wrapper-header {
      text-align: left;
      color: #222222;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      margin: 18px 0 16px;
    }

    .locations-list {
      margin: 0;
      padding: 0;
      overflow-y: scroll;
      height: 100%;

      @extend %scrollbar;

      li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;

        i {
          font-size: 12px;

          &.el-icon-rank {
            margin-left: 48px;
            display: none;
          }
        }

        &:hover {
          i.el-icon-rank {
            display: flex;
          }
        }

        span {
          color: #606266;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          margin-left: 7px;
        }
      }
    }
  }
}

ul[dnd-list],
ul[dnd-list] > li {
  position: relative;
}
</style>

<style lang="scss">
.hierarchy-container {
  .locations-unassign-dialog {
    .el-dialog__body {
      h2 {
        margin-top: 0;
      }
    }
  }
}

.hierarchy {
  &-search {
    .el-input__inner {
      padding: 0 44px !important;
      border: none;
    }
  }
}

.hierarchy-tree_root {
  margin: 0;
  padding: 0 0 0 24px;

  &.nested-wrapper {
    display: none;

    &.opened {
      display: block;
    }
  }

  &.parent {
    padding: 0;
  }

  > li > button > span {
    color: #273a58;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }
  ul {
    padding-left: 26px;
  }
}

.nestable {
  position: relative;
}
.nestable .nestable-list {
  margin: 0;
  list-style-type: none;
}
.nestable > .nestable-list {
  padding: 0;
  ol {
    display: none;

    &.is-opened {
      display: block;
    }
  }
}
.nestable-item,
.nestable-item-copy {
}
.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
}
.nestable-item {
  position: relative;
}
.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}
.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}
.nestable-item.is-dragging:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a3d0fd;
  border: 1px dashed #2893f9;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}
.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: #a3d0fd;
  opacity: 0.5;
}
.nestable [draggable='true'] {
  cursor: move;
}
.nestable-handle {
  display: inline;
}
.nestable-list {
  list-style: none !important;
  padding-left: 24px;
}
</style>
