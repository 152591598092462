<template lang="pug">
  .bills-list-container
    .bills-list-header
      .bills-list-header-title Create Bill Batch From Directory
      //-.bills-list-header-action
        router-link.el-button.el-button--mini(:to="{ name: 'customer.uploads.directory' }", active-class="active")
          span Diretory
        router-link.el-button.el-button--mini(:to="{ name: 'customer.uploads.upload' }", active-class="active")
          span Upload
    p.directory-note Paste the URL to a Google Drive directory below:
    .directory-link
      label Google Drive directory link:
      el-input(v-model="driveLink", size="small", placeholder="https://drive.google.com…")
    .directory-actions
      el-button.plain-button-reverse(size="small", @click="cancelUpload") Cancel
      el-button(type="primary", size="small", :disabled="!driveLink.length", @click="next") Next

    confirm-modal(:show-modal="isConfirmModalOpened")

</template>

<script>
import { mapState, mapActions } from 'vuex';
import ConfirmModal from './ConfirmModal.vue';

export default {
  components: {
    ConfirmModal,
  },
  data() {
    return {
      driveLink: '',
      batchStatus: {},
      isConfirmModalOpened: false,
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer || {},
    }),
  },
  beforeMount() {
    this.resetBatchStatus();
  },
  methods: {
    ...mapActions({
      validateBatch: 'batches/fetchBatchStatus',
      resetBatchStatus: 'batches/resetBatchStatus',
    }),
    cancelUpload() {
      this.$router.push({ name: 'customer.uploads' });
    },
    async next() {
      await this.validateBatch({
        folder: this.driveLink,
      });

      this.isConfirmModalOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.bills-list {
  &-header {
    &-action {
      .el-button {
        width: 120px;
        font-size: 9px;
        text-decoration: none;
        border-radius: 4px;

        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        + .el-button {
          margin-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        &.active {
          color: #409eff;
          border-color: #c6e2ff;
          background-color: #ecf5ff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '../../../styles/constants.scss';
.bills-list {
  &-container {
    margin: 20px 40px;
  }
  &-header {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    &-title {
      font-size: 20px;
      font-weight: 600;
      justify-content: space-between;
      align-items: center;
      display: flex;
    }
  }
  &-total {
    margin: 10px 0;
    display: block;
    font-size: 12px;
    color: #222222;

    .number {
      margin-left: 5px;
      font-weight: 600;
    }
  }
  &-table {
    max-height: 75%;

    .el-table__row {
      cursor: pointer;
    }
    td {
      padding: 0;
    }
  }
}
.directory {
  &-link {
    display: flex;
    align-items: center;
    max-width: 700px;

    label {
      flex: 0 0 210px;
      color: #606266;
      font-size: 14px;
    }
    .el-input__inner {
      height: 30px;
    }
  }
  &-note {
    margin: 0 0 40px;
    color: #222222;
  }
  &-actions {
    margin-top: 40px;

    .el-button {
      width: 170px;
      padding-top: 8px;
      padding-bottom: 8px;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
</style>
