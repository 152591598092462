<template lang="pug">
  li.hierarchy-tree_children-item(v-if="!item.empty",
  :class='{"is-group": isGroup, "is-opened": !isCollapsed, "is-parent": !item.parent_id, [`item-id-${item.id}`]: true }',
  ref='component')
    .hierarchy-tree_children-item-container
      .edit-input-wrapper(v-if="selfEditingMode && item.name && editMode", :class="{'has-error': !isValidName}")
        input(v-model="newName")
        .icons-wrapper
          el-button(icon="el-icon-circle-close", @click="disableSelfEditingMode")
          el-button(icon="el-icon-circle-check", @click="renameItem")
      .button-wrapper(v-else, :class='{"is-location": item.location_id, "is-selected": isSelected}')
        el-button(type='text',
          :icon='icon',
          @click='handleExpand',
          @dblclick="handleDoubleClick",
          :class='{"text-button": true}')
        span(v-if="item.query", @click='click')
          span {{ matchedParts.before }}
          span.matched-fragment {{ matchedParts.match }}
          span {{ matchedParts.after }}
        span(v-else, @click='click') {{ item.name || item.location.name }}
      .hierarchy-tree_children-item-container-controls(v-if="isSelected && editMode")
         el-button(type="text",
                  icon="el-icon-plus",
                  class="plus-icon",
                  v-if="!item.location_id",
                  @mouseenter.native="showOnTop",
                  @mouseleave="showOnTop")
          .add-item-popup
            .add-item-popup-triangle
            ul
              li(@click="openCreateLocationGroupModal") Add Child Location Group
              li(@click="openAssignLocationModal") Assign Locations

         vue-nestable-handle(:item="item")
          el-button(type="text" icon="el-icon-rank")
         el-button(type="text", icon="el-icon-close", @click="openDeleteModal")

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'TreeItem',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    selectedPath: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCollapsed: true,
      clicks: 0,
      delay: 250,
      timer: null,
      newName: '',
      isValidName: true,
    };
  },
  mounted() {
    this.newName = this.item.name;
    this.setNodeInsertListener();
  },
  computed: {
    ...mapState({
      selectedItemId: (state) => state.hierarchy.selectedItemId,
      data: (state) => state.hierarchy.data,
      locations: (state) => state.hierarchy.locations,
      editMode: (state) => state.hierarchy.editMode,
      selfEditingId: (state) => state.hierarchy.selfEditingId,
    }),
    isSelected() {
      return this.selectedItemId === this.item.id;
    },
    selfEditingMode() {
      return this.item.id === this.selfEditingId;
    },
    isGroup() {
      return !this.item.location_id;
    },
    icon() {
      if (this.item.location_id) {
        return 'el-icon-location';
      }
      return this.isCollapsed ? 'el-icon-caret-right' : 'el-icon-caret-bottom';
    },
    matchedParts() {
      const name = this.item.name || this.item.location.name;
      const index = name.toLowerCase().indexOf(this.item.query);
      const len = this.item.query.length;
      return {
        before: name.substr(0, index),
        match: name.substr(index, len),
        after: name.substr(index + len),
      };
    },
  },
  watch: {
    selectedPath(path) {
      if (path.includes(this.item.id)) {
        this.isCollapsed = false;
      }
    },
    item(value) {
      this.setNodeInsertListener();
      this.isCollapsed = !value.isOpened;
    },
    editMode(val) {
      if (this.item.empty) {
        return;
      }

      this.isCollapsed = !val;
    },
    isCollapsed(val) {
      const el = this.$refs.component
        .closest('.nestable-item-content')
        .parentElement.querySelector('ol');
      if (el) {
        if (!val) {
          el.classList.add('is-opened');
        } else {
          el.classList.remove('is-opened');
        }
      }
    },
  },
  methods: {
    ...mapActions({
      selectItem: 'hierarchy/selectItem',
      setSelfEditMode: 'hierarchy/setSelfEditMode',
      createGroup: 'hierarchy/createGroup',
      fetchHierarchy: 'hierarchy/fetchHierarchy',
      createItemsBatch: 'hierarchy/createItemsBatch',
      fetchUnmachedLocations: 'hierarchy/fetchUnmachedLocations',
      deleteHierarchyItem: 'hierarchy/deleteHierarchyItem',
      patchHierarchyItem: 'hierarchy/patchHierarchyItem',
    }),
    onDomNodeInsertedHandler(e) {
      if (!e.target) return;
      if (e.target.nodeType !== 1 || this.item.empty) return;

      const nestableItem = e.target.closest('.nestable-item');
      if (nestableItem) {
        if (nestableItem.classList.contains('is-dragging')) {
          this.isCollapsed = false;
          nestableItem.closest('ol').classList.add('is-opened');
          return;
        }
      }

      const nestableList = e.target;
      if (e.target.classList.contains('nestable-list')) {
        const nestableItem = e.target.querySelector('.is-dragging');
        if (nestableItem) {
          this.isCollapsed = false;
          nestableList.classList.add('is-opened');
        }
      }
    },
    setNodeInsertListener() {
      if (this.item.empty || !this.$refs.component) return;
      const el = this.$refs.component
        .closest('.nestable-item-content')
        .parentElement.querySelector('ol');
      if (el) {
        el.addEventListener('DOMNodeInserted', this.onDomNodeInsertedHandler);
      }
    },
    click(e) {
      this.clicks++;
      if (this.clicks === 1) {
        const self = this;
        this.timer = setTimeout(function () {
          self.clicks = 0;
          self.handleSelect(e);
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.handleDoubleClick(e);
        this.clicks = 0;
      }
    },
    async renameItem() {
      let error = null;
      await this.patchHierarchyItem({
        params: { ...this.$route.params, itemId: this.item.id },
        body: { name: this.newName },
      }).catch(
        ({
          response: {
            data: { code },
          },
        }) => (error = code),
      );

      if (error && error === 409) {
        this.isValidName = false;
      } else {
        await this.$emit('fetchTree');
        this.setSelfEditMode(null);
        this.isValidName = true;
      }
    },
    handleDoubleClick() {
      if (this.editMode) {
        this.newName = this.item.name;
        this.setSelfEditMode(this.item.id);
      }
    },
    disableSelfEditingMode() {
      this.isValidName = true;
      this.setSelfEditMode(null);
    },
    handleExpand(e) {
      if (this.item.empty) return;
      const el = e.target
        .closest('.nestable-item-content')
        .parentElement.querySelector('ol');
      if (this.isCollapsed) {
        this.isCollapsed = false;
        if (el) {
          el.classList.add('is-opened');
        }
      } else {
        this.isCollapsed = true;
        if (el) {
          el.classList.remove('is-opened');
        }
      }
    },
    handleSelect() {
      if (this.item.empty) return;
      this.selectItem(this.item.id);
    },
    openCreateLocationGroupModal() {
      this.$emit('openCreateLocationGroupModal');
    },
    openAssignLocationModal() {
      this.$emit('openAssignLocationModal');
    },
    openDeleteModal() {
      if (this.item.location_id) {
        this.$emit('openUnassignSingleLocationModal');
      } else {
        this.$emit('openDeleteModal');
      }
    },
    showOnTop({ pageY, target }) {
      const popup = target.querySelector('.add-item-popup');

      if (pageY > window.innerHeight - 100) {
        popup.classList.add('show-on-top');
      } else if (popup.classList.contains('show-on-top')) {
        popup.classList.remove('show-on-top');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.matched-fragment {
  background-color: #dae7ff;
}
.hierarchy-tree_children {
  list-style: none;

  &-item {
    .button-wrapper {
      cursor: pointer;
      color: #273a58;
      text-decoration: none;
      font-size: 14px;
      line-height: 19px;

      > span {
        margin-left: 5px;
        user-select: none;
        cursor: pointer;
      }
      span {
        word-break: break-all;
      }

      &.is-selected.is-location {
        span {
          color: #2893f9;
        }
      }
    }

    .el-button.text-button {
      padding: 5px 0;
      color: #4a4a4a;
      text-decoration: none;
      font-size: 14px;
      line-height: 19px;

      &.is-location {
        color: #606266;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 100px;
        .el-button {
          color: #4a4a4a;
          padding: 0;
          margin-left: 8px;

          &.plus-icon {
            position: relative;

            .add-item-popup {
              position: absolute;
              top: 24px;
              left: -11px;
              display: none;
              z-index: 99;

              &-triangle {
                &::after,
                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  left: 10px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                }
                &::after {
                  border-color: transparent transparent #fff transparent;
                  border-width: 8px;
                  top: -14px;
                }

                &::before {
                  top: -15px;
                  left: 10px;
                  border-color: transparent transparent #e4e7ed transparent;
                  border-width: 8px;
                }
              }

              ul {
                padding: 13px 7px;
                box-shadow: 0 -1px 5px 0 #f0f0f0;
                border-radius: 5px;
                background: #fff;
                list-style: none;

                li {
                  text-align: left;
                  color: #222222;
                  font-size: 10px;
                  line-height: 20px;
                  padding: 0 7px;
                  border-radius: 5px;

                  &:hover {
                    background: #e4e7ed;
                  }
                }
              }
            }
            .show-on-top {
              top: -75px;

              .add-item-popup-triangle {
                &::after {
                  top: auto;
                  border-color: #fff transparent transparent transparent;
                  border-width: 8px;
                  bottom: -16px;
                }

                &::before {
                  top: auto;
                  bottom: -17px;
                  left: 10px;
                  border-color: #e4e7ed transparent transparent transparent;
                  border-width: 8px;
                }
              }
            }

            &:hover {
              .add-item-popup {
                display: block;
              }
            }
          }
        }
      }
    }
    &.is-group.is-opened {
      font-weight: 700;
    }

    &.is-opened {
      .is-location.is-selected {
        span,
        button {
          color: #2893f9 !important;
        }
      }
    }

    .edit-input-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &.has-error {
        position: relative;
        margin-bottom: 20px;

        &::after {
          position: absolute;
          top: 115%;
          left: 0;
          height: 20px;
          content: 'Please enter a unique name.';
          font-size: 12px;
          color: #f86259;
        }

        > input {
          border: 1px solid #f86259 !important;
        }
      }

      input {
        height: 30px;
        width: 200px;
        border: 1px solid #d8dce6;
        border-radius: 4px;
        background-color: #ffffff;
        box-sizing: border-box;
        color: #4a4a4a;
        font-size: 14px;
        line-height: 20px;
        text-indent: 10px;
      }

      .icons-wrapper {
        display: flex;
        margin-left: 8px;

        > button {
          padding: 0;
          border: 0;
          border-radius: 50%;
          font-size: 18px;

          &:last-child {
            margin-left: 8px;
            color: #409eff;
          }

          &:first-child {
            color: #c7cbd2;

            &:hover {
              color: #c7cbd2;
            }
          }
        }
      }
      + .hierarchy-tree_children-item-container-controls {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.hierarchy-tree_children {
  &-item {
    .button-wrapper {
      &.is-selected.is-location {
        i {
          color: #2893f9;
        }
      }
    }
  }
}
</style>
