<template lang="pug">
  .error-modal
    .errors-info
      .errors-count(v-if='issues.length')
        i.icon-error
        | {{ issues.length }} errors found
      .errors-count.no-errors(v-else)
        i.icon-no-error
        | {{ issues.length }} errors found
      .errors-info_versions
        .version-item
          b Parser Version
          span &nbsp;{{ versions.parser }}
        .version-item
          b Calculations Version
          span &nbsp;{{ versions.calculation }}
        .version-item
          b Validations Version
          span &nbsp;{{ versions.validation }}
    .errors-info_text Errors
    .errors-table
      el-table(:data="orderedIssues", max-height='320', empty-text=' ')
        el-table-column(label='Errors', class-name='errors-column', prop='message')
        el-table-column(label='Error Category', prop='category', width='155px')
        el-table-column(label='ID', prop='validation_check_id', width='80px')
        el-table-column(label='Severity', width='150px')
          template(slot-scope='scope')
            span.severity(:class="setSeverityColor(scope.row.severity)") {{ scope.row.severity }} - {{ severityList[scope.row.severity] }}

    .error-modal_actions
      el-button(type="primary", @click="cancel()") OK

</template>

<script>
import * as R from 'ramda';

export default {
  props: {
    issues: {
      type: Array,
      default: () => [],
    },
    versions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderedIssues: [],
      severityList: {
        10: '"Informational"',
        20: '"Warning"',
        30: '"Error Sev 4"',
        40: '"Error Sev 3"',
        50: '"Error Sev 2"',
        60: '"Critical Error"',
      },
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    sortIssues() {
      this.orderedIssues = R.sort(
        (a, b) => b.severity - a.severity,
        this.issues,
      );
    },
    setSeverityColor(severity) {
      if (severity === 10) {
        return 'info';
      }
      if (severity === 20) {
        return 'warning';
      }
      if (severity > 20 && severity < 60) {
        return 'danger';
      }
      if (severity === 60) {
        return 'critical';
      }
    },
  },
  mounted() {
    this.sortIssues();
  },
};
</script>

<style lang="scss" scoped>
@import './../../../styles/constants';

.error-modal {
  color: $main-color;

  &_actions {
    display: flex;
    padding: 20px 0;
    justify-content: center;

    .el-button {
      width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;

      + .el-ubtton {
        margin-left: 40px;
      }
    }
  }
}
.errors-count {
  color: $danger;
  font-weight: 600;

  &.no-errors {
    color: $success;
  }
}
.errors-info {
  display: flex;
  justify-content: space-between;

  &_text {
    margin: 20px 0 10px;
    font-weight: 600;
  }
  &_versions {
    display: flex;
  }
}
.version-item {
  margin-right: 20px;
  font-size: 10px;

  &:last-child {
    margin-right: 0;
  }
}
</style>

<style lang="scss" scoped>
@import './../../../styles/constants';

.severity {
  font-weight: 600;
}
.info {
  color: $primary;
}
.warning {
  color: $warning;
}
.danger {
  color: $danger;
}
.critical {
  color: $critical;
}
</style>

<style lang="scss">
@import './../../../styles/constants';

.error-modal {
  .el-table {
    font-size: 12px;
    overflow: visible;

    .el-table__header-wrapper {
      position: relative;
      box-shadow: $primary-shadow;
      z-index: 10;
    }
    .el-table__body-wrapper {
      box-shadow: $primary-shadow;
    }

    td {
      border-bottom-color: #ebeef5;
    }
    .el-table__empty-block {
      min-height: 30px;
    }
  }
}
.errors-column {
  .cell {
    padding-left: 20px;
  }
}
</style>
