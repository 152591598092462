<template lang="pug">
main-layout
  router-view
</template>

<script>
import { Main } from '@/components/layouts';

export default {
  components: {
    mainLayout: Main,
  },
};
</script>

<style lang="scss" scoped></style>
