import router from '@/router';
import { equals, has } from 'ramda';

const changeRoute = (params) => {
  let { method, path, query } = params;
  let cRoute = router.currentRoute;
  if (cRoute.path === path && equals(cRoute.query, query)) {
    console.warn('NavigationDuplicated');
    return;
  }
  router[method]({ path, query });
};

const addGetParam = (name, value) => {
  const method = has(name, router.currentRoute.query) ? 'push' : 'replace';
  changeRoute({
    method,
    path: router.currentRoute.path,
    query: {
      ...router.currentRoute.query,
      [name]: String(value),
    },
  });
};

const addGetParams = (params) => {
  changeRoute({
    method: 'push',
    path: router.currentRoute.path,
    query: {
      ...router.currentRoute.query,
      ...params,
    },
  });
};

export { changeRoute, addGetParam, addGetParams };
