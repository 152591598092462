<template>
  <base-modal
    width="480px"
    @open="open"
    @close="close"
    :dialogVisible="showEditUserRole"
    :title="'Edit User Role and Locations'"
  >
    <template #content>
      <div class="info-container border-bottom">
        <div class="padding">
          <div class="label">Name:</div>
          <div class="info">{{ fullName }}</div>
        </div>
        <div class="padding">
          <div class="label">Current user role:</div>
          <div class="info">{{ userRole }}</div>
        </div>
        <div class="padding">
          <div class="label">Email address:</div>
          <div class="info">{{ user && user.email }}</div>
        </div>
      </div>
      <base-radio-group
        class="border-bottom"
        v-model="userRoleRadio"
        :radioButtons="userRolesRadioSelection"
      />
      <map-locations-to-standard-user
        class="border-bottom"
        :standard-user="userRoleRadio"
        :total-locations="totalLocations"
        :defaultSelectedLocations="selectedLocations"
        :hierarchies="hierarchies"
        :filterText="filterText"
        @filter-val-change="handleFilterValue"
        :showOnlySelectedLocations="showOnlySelectedLocations"
        @show-selected-locs="handleShowOnlySelectedLocations"
        @get-checked-keys="handleSelectedLocations"
      />
      <!--<el-checkbox class="checkbox" v-model="showOnlySelectedLocations">Notify the user regarding the changes that were made.</el-checkbox> -->
    </template>
    <template #footer>
      <el-button class="button-size plain-button-reverse" @click="close"
        >Cancel</el-button
      >
      <el-button class="button-size" type="primary" @click="editUserRole"
        >Confirm Changes</el-button
      >
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import BaseRadioGroup from '@/components/FormComponents/BaseRadioGroup';
import { userRoles } from '../../../lib/userRoleHelpers';
import _ from 'lodash';
import MapLocationsToStandardUser from './MapLocationsToStandardUser';

export default {
  components: {
    MapLocationsToStandardUser,
    BaseModal,
    BaseRadioGroup,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    userRolesRadioSelection: {
      type: Array,
      default: () => [],
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    totalLocations: {
      type: Number,
      default: 0,
    },
    hierarchies: {
      type: Array,
      default: () => [],
    },
    customer: {
      type: Object,
      default: () => {},
    },
    currentCustomerId: {
      type: String,
    },
  },
  data() {
    return {
      showEditUserRole: false,
      userRoleRadio: 'customerFullAccess',
      filterText: '',
      showOnlySelectedLocations: false,
      selectedLocations: [],
    };
  },
  watch: {
    dialogVisible(val) {
      this.showEditUserRole = val;
    },
  },
  computed: {
    userRole() {
      const { customers } = this.user;
      return userRoles(customers, this.currentCustomerId);
    },
    fullName() {
      if (!_.isNil(this.user.profile)) {
        return `${this.user.profile.firstName} ${this.user.profile.lastName}`;
      }
      return '-';
    },
  },
  methods: {
    open() {
      if (this.userRole === 'Standard User') {
        this.userRoleRadio = 'standardUser';
      }
      const { customers } = this.user;
      this.selectedLocations =
        customers &&
        customers.find((customerU) => +customerU.id === +this.customer.id)
          .locations;
    },
    close() {
      this.$emit('close');
      this.selectedLocations = [];
      this.userRoleRadio = 'customerFullAccess';
      this.showOnlySelectedLocations = false;
      this.filterText = '';
    },
    handleSelectedLocations(locations) {
      this.$emit('get-checked-keys', locations);
    },
    handleFilterValue(val) {
      this.filterText = val;
    },
    handleShowOnlySelectedLocations(val) {
      this.showOnlySelectedLocations = val;
    },
    editUserRole() {
      this.$emit('edit-user-role', {
        userId: +this.user.id,
        userRoleRadio: this.userRoleRadio,
        customerId: this.customer.id,
        fullName: this.fullName,
        editUserRole: true,
        selectedLocations: this.selectedLocations,
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.button-size {
  width: 45%;
  font-weight: 600;
  font-size: 14px;
}

.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebeef5;
}

.info-container {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  .label {
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }

  .info {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 16px 0;
    color: #222222;
  }
  .padding {
    padding-right: 10px;
  }
}
</style>
