import { myBills } from '@/api';
import * as R from 'ramda';

const defaultPaginationState = () => ({
  limit: 20,
  offset: 0,
  total: 0,
});

export default {
  namespaced: true,
  state: {
    list: [],
    isLoading: false,
    pagination: defaultPaginationState(),
    summary: {},
  },

  getters: {
    isLoading: (state) => state.isLoading,
    list: (state) => state.list,
    limit: (state) => +state.pagination.limit,
    offset: (state) => +state.pagination.offset,
    total: (state) => +state.pagination.total,
    activeQty: (state) => R.propOr(0, 'bills_active', state.summary),
    parkedQty: (state) => R.propOr(0, 'bills_parked', state.summary),
    firstBillId: (state) => R.prop('first_bill_id', state.summary),
    firstCustomerId: (state) => R.prop('first_customer_id', state.summary),
    isMyBillEmpty: (state, getters) => getters.activeQty === 0,
  },

  mutations: {
    setList(state, data) {
      state.list = data;
    },
    setLimit(state, limit) {
      state.pagination.limit = limit;
    },
    setOffset(state, offset) {
      state.pagination.offset = offset;
    },
    setTotal(state, total) {
      state.pagination.total = total;
    },
    setSummary(state, summary = {}) {
      state.summary = summary;
    },
    setLoading(state) {
      state.isLoading = true;
    },
    setLoaded(state) {
      state.isLoading = false;
    },
    clearState(state) {
      state.pagination = defaultPaginationState();
    },
  },

  actions: {
    async setPagination({ commit }, { limit, offset }) {
      await commit('setLimit', limit);
      await commit('setOffset', offset);
    },
    list: async ({ commit }, { params }) => {
      try {
        commit('setLoading');
        commit('setList', []);
        const { data = [], meta = {} } = await myBills.list(params);
        const total = Number(R.pathOr(0, ['content_range', 'total'], meta));
        commit('setList', data);
        commit('setTotal', total);
      } catch (e) {
        commit('setTotal', 0);
        console.error(e);
      } finally {
        commit('setLoaded');
      }
    },
    getMyBillsSummary: async ({ commit, rootGetters }) => {
      try {
        const data = await myBills.getMyBillsSummary();
        await rootGetters['porting/props'].updateBillsSummary();
        commit('setSummary', data);
      } catch (e) {
        console.error(e);
      } finally {
        // no empty
      }
    },
  },
};
