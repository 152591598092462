import filters from './global.bills.filters';
import { globalBills } from '@/api';
import * as R from 'ramda';

const defaultFilterState = () => ({
  query: '',
  customer_ids: [],
  users_ids: [],
  workflow_states: [],
  bill_types: [],
  error_ids: [],
  vendor_codes: [],
  max_due_date: null,
  max_target_date: null,
  customer_active: true,
});

const defaultOrdersState = () => [
  { field: 'priority', order: '-' },
  { field: 'target_date', order: '+' },
  { field: 'statement_date', order: '+' },
];

const defaultPaginationState = () => ({
  limit: 20,
  offset: 0,
  total: 0,
});

export default {
  namespaced: true,
  modules: { filtersData: filters },
  state: {
    list: [],
    isLoading: false,
    filters: defaultFilterState(),
    pagination: defaultPaginationState(),
    selection: [],
    orders: defaultOrdersState(),
  },

  getters: {
    isLoading: (state) => state.isLoading,
    list: (state) => state.list,
    filters: (state) => state.filters,
    limit: (state) => +state.pagination.limit,
    offset: (state) => +state.pagination.offset,
    total: (state) => +state.pagination.total,
    orders: (state) => state.orders,
    selection: (state) => state.selection,
  },

  mutations: {
    setList(state, data) {
      state.list = data;
    },
    setLimit(state, limit) {
      state.pagination.limit = limit;
    },
    setOffset(state, offset) {
      state.pagination.offset = offset;
    },
    setFilter(state, { filter, value }) {
      state.filters[filter] = value;
    },
    setFilters(state, filters) {
      filters = R.clone(filters);
      if (+R.head(R.propOr([], 'users_ids', filters)) === 0) {
        filters.users_ids = [];
      }
      Object.assign(state.filters, filters);
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setTotal(state, total) {
      state.pagination.total = total;
    },
    setLoading(state) {
      state.isLoading = true;
    },
    setLoaded(state) {
      state.isLoading = false;
    },
    setSelection(state, selection) {
      state.selection = selection;
    },
    clearState(state) {
      state.filters = defaultFilterState();
      state.pagination = defaultPaginationState();
      state.orders = defaultOrdersState();
      state.selection = [];
    },
  },

  actions: {
    async setPagination({ commit }, { limit, offset }) {
      await commit('setLimit', limit);
      await commit('setOffset', offset);
    },
    list: async ({ commit }, { params }) => {
      try {
        commit('setLoading');
        commit('setList', []);
        const { data = [], meta = {} } = await globalBills.list(params);
        const total = Number(R.propOr('', 'total_amount', meta));
        commit('setList', data);
        commit('setTotal', total);
      } catch (e) {
        commit('setTotal', 0);
        console.error(e);
      } finally {
        commit('setLoaded');
      }
    },
  },
};
