<template lang="pug">
.list-filter-wrapper
  .list-filter-inner
    .list-filter
      .list-filter-input
        .list-filter-title Meter ID:
        bills-filter-select(
          :options="uniqBy(prop('meterSerial'), items)",
          v-model="filters.meterSerial",
          item-label="meterSerial",
          item-value="meterSerial"
        )
      .list-filter-input
        .list-filter-title Utility:
        bills-filter-select(
          :options="uniqBy(prop('commodity'), items)",
          v-model="filters.commodity",
          item-label="commodity",
          item-value="commodity"
        )
      .list-filter-input
        .list-filter-title Service Account ID:
        bills-filter-select(
          :options="uniqBy(prop('accountCode'), items)",
          v-model="filters.accountCode",
          item-label="accountCode",
          item-value="accountCode"
        )
      .list-filter-input
        .list-filter-title Location Description:
        bills-filter-select(
          :options="locationNamesList",
          v-model="filters.locationName"
        )
      .list-filter-input
        .list-filter-title Bill Type:
        bills-filter-select(
          :options="uniqBy(prop('billType'), items)",
          v-model="filters.billType",
          item-label="billType",
          item-value="billType"
        )
      .list-filter-input
        .list-filter-title Service Address:
        bills-filter-select(
          :options="uniqBy(prop('serviceAddress'), items)",
          v-model="filters.serviceAddress",
          item-label="serviceAddress",
          item-value="serviceAddress"
        )
    .list-filter-clear
      el-button.fw-400(type="text", @click="clear") Clear
  .list-filter-action
    .list-filter-action--expand
    .list-filter-action--collapse
    .list-filter-action--show
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { FilterInput } from '@/components';
import BillsFilterSelect from '@/pages/Bills/Filters/Select';
import * as R from 'ramda';

const defaultFilters = () => ({
  meterSerial: [],
  commodity: [],
  serviceAddress: [],
  accountCode: [],
  locationDescription: [],
  billType: [],
  locationName: [],
});

export default {
  name: 'BillsFilters',
  components: {
    FilterInput,
    BillsFilterSelect,
  },
  beforeMount() {
    if (R.isEmpty(this.$route.query)) {
      Object.assign(this.filters, defaultFilters());
    } else {
      Object.assign(
        this.filters,
        R.pick(R.keys(defaultFilters()), this.$route.query),
      );
    }
  },
  data() {
    return {
      filters: defaultFilters(),
    };
  },
  props: {
    billItemsFilters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      bill: 'bills/bill',
    }),
    items() {
      if (this.billItemsFilters.length) {
        return this.billItemsFilters;
      } else {
        return R.propOr([], 'items', this.bill);
      }
    },
    locationNamesList() {
      const locations = R.reduce((acc, val) => {
        const name = R.path(['location', 'name'], val);
        return name ? [...acc, name] : acc;
      }, [])(this.items);
      return R.uniq(locations);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: async function (filters) {
        if (
          !R.equals(
            filters,
            R.pick(R.keys(defaultFilters()), this.$route.query),
          )
        ) {
          this.setDontReload(true);
          await this.$router.replace({
            query: { ...this.$route.query, ...filters },
          });
        }
        this.$emit('change', R.pickBy(R.pipe(R.isEmpty, R.not), filters));
      },
    },
  },
  methods: {
    ...mapMutations({
      setDontReload: 'bills/setDontReload',
    }),
    clear() {
      Object.assign(this.filters, defaultFilters());
    },
    uniqBy: R.uniqBy,
    prop: R.prop,
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0 15px 5px 15px;

  &-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &-clear {
    flex-grow: 1;
    margin: 34px 25px 0 0;
    text-align: right;

    .el-button {
      text-decoration: none;
    }
  }

  &-title {
    white-space: nowrap;
    font-size: 12px;
    margin-bottom: 4px;
  }

  &-input {
    flex-basis: 200px;
    margin: 15px 45px 15px 0;
  }
}

.el-date-editor {
  width: 100%;
}
</style>
