<template lang="pug">
  .location-control.control
    el-dialog.location-modal(title="Select a location",
      custom-class="select-location-dialog",
      :visible="isVisible",
      :append-to-body="true",
      :close-on-click-modal="false",
      top="40px",
      width="640px",
      @close="close")
      locations-control(:locations="locations", :stored-locations="selectedLocation", @select-locations="selectLocation")
      .location-control_actions
        el-button(type="primary", size="small", round, @click="changeFilter") Select
</template>

<script>
import { mapGetters } from 'vuex';
import LocationsControl from './LocationsControl.vue';
import HierarchiesControl from './HierarchiesControl.vue';

export default {
  components: {
    LocationsControl,
    HierarchiesControl,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    selectedLocation: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  updated() {
    this.selected = this.selectedLocation;
  },
  data() {
    return {
      selected: this.selectedLocation,
    };
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
      hierarchy: 'locations/hierarchy',
    }),
  },
  watch: {
    selectedLocation(location) {
      this.selected = location;
    },
  },
  methods: {
    selectLocation(location) {
      this.selected = location;
    },
    changeFilter() {
      this.$emit('onChange', this.selected);
      this.$emit('close');
      this.selected = {};
    },
    close() {
      this.$emit('close');
      this.selected = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.location-control_actions {
  padding-top: 20px;
  text-align: center;

  .el-button {
    width: 160px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 900;
  }
}
</style>

<style lang="scss">
.location-modal {
  .location-control_container {
    overflow-y: auto;
  }

  .el-dialog__header {
    padding: 40px 40px 0 40px;
  }

  .el-dialog__title {
    color: #273a58;
    font-size: 24px;
    font-weight: 700;
  }

  .el-dialog__headerbtn {
    top: 42px;
    right: 38px;
  }

  .el-dialog__body {
    padding: 20px 15px;
  }

  .el-tabs__header {
    padding: 0 25px;
    margin-bottom: 20px;
  }

  .el-tabs__item {
    font-weight: 900;
  }
}
</style>
