<template lang="pug">
  .comments-container
    .comments
      .comments-head
        h3.comments-label Activity History ({{ comments.total || 0 }})
      .comments-body(v-on:scroll="handleScroll")
        .comments-list
          .comments-bottom(ref="bottom")
          comment-item(:items="items" v-for='(item, index) in comments.data', :key='item.id', :comment="item")
          .loading-indicator(v-loading='loading')
      .comments-actions
        comment-input(@newComment='scrollToBottom' :items="items")
</template>

<script>
import CommentItem from './CommentItem';
import CommentInput from './CommentInput';
import { mapState, mapActions } from 'vuex';
import { bills } from '@/api';
import { debounce } from 'lodash';
import { customers } from '../../../../../../api';

export default {
  computed: {
    ...mapState({
      comments: (state) => state.bills.bill.comments,
      customerId: (state) => state.customers.customer.id,
      billId: (state) => state.bills.bill.id,
    }),
    offset: function () {
      return (this.page - 1) * this.limit;
    },
  },
  components: {
    CommentItem,
    CommentInput,
  },
  beforeDestroy() {
    clearTimeout(this.scrollToBottomHandler);
  },
  data: function () {
    return {
      page: 1,
      limit: 10,
      loading: false,
      items: [],
      scrollToBottomHandler: null,
    };
  },
  methods: {
    ...mapActions({
      loadMoreComments: 'bills/loadMoreComments',
      setTotalComments: 'bills/setTotalComments',
    }),
    async loadComments() {
      this.loading = true;
      const { results } = await bills.getComments(this.customerId, this.billId);
      this.loadMoreComments(results);
      this.loading = false;
    },
    scrollToBottom() {
      this.scrollToBottomHandler = setTimeout(() => {
        if (this.$refs.bottom) {
          this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    },
    async getChatList() {
      const chatList = await customers.getChatListForCurrentCustomer(
        this.$route.params.customerId,
      );
      this.items = chatList.data.results.map((chatTag) => ({
        value: chatTag.name.substring(1),
        key: chatTag.name,
        type: chatTag.type,
      }));
    },
  },
  beforeCreate() {
    this.handleScroll = debounce((event) => {
      if (event.target.scrollTop === 0) {
        if (this.comments.data.length < this.comments.total) {
          this.page++;
          this.loadComments();
        }
      }
    }, 500);
  },
  async mounted() {
    await this.getChatList();
    this.scrollToBottom();
  },
};
</script>

<style lang="scss" scoped>
@import './../../../../../../styles/constants.scss';
.comments-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  border: solid 1px #dcdfe6;
  box-shadow: $primary-shadow;
  background-color: $secondary-backgound;
  box-sizing: border-box;
  transition: right 0.25s;
  z-index: 10;
}
.comments {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  &-head {
    align-self: flex-start;
    position: relative;
    width: 100%;
    padding: 30px 0 20px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: solid 1px #dcdfe6;
  }
  &-label {
    margin: 0;
  }
  &-body {
    height: 100%;
    max-height: 100%;
    padding-right: 11px;
    margin-right: -15px;

    @extend %scrollbar;
  }
  &-list {
    display: flex;
    flex-direction: column-reverse;
    min-height: 100%;
  }
  &-bottom {
    height: 1px;
  }
}
.close-button {
  position: absolute;
  top: 15px;
  right: 0;
  padding: 0;
  color: #979797;
  font-size: 17px;
}
.loading-indicator {
  position: relative;
  top: 20px;
}
</style>

<style lang="scss">
.loading-indicator {
  .el-loading-spinner {
    background-color: #fff;
  }
}
</style>
