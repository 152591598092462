<template lang="pug">
  .upload-container
    .page-header
      .page-header_title Upload Files
    .upload-steps
      el-steps(:active='activeStep', process-status='finish' :finish-status='finishStatus' simple)
        el-step(title='Choose the file')
        el-step(title='Confirmation')
        el-step(title='Uploading')
        el-step(title='Success')
    .upload-steps_content
      .upload-step(v-if='activeStep === 0')
        el-row(type='flex')
          el-col(:span='12')
            .upload-step_title File Settings
            .upload-step_field
              label.upload-step_field-label Customer
              .upload-step_field-value {{ customer.name }}
            .upload-step_field
              label.upload-step_field-label Bill Type
              .upload-step_field-value
                el-select(v-model='selectedBillType', placeholder='Select Bill Type' filterable)
                  el-option(v-for='option in billType', :key='option.value', :label='option.label', :value='option.value')
            .upload-step_field
              label.upload-step_field-label Zip File to Upload
              .upload-step_field-value.upload-drag-and-drop
                el-upload(action='https://jsonplaceholder.typicode.com/posts/',
                                              drag,
                                              :auto-upload='false',
                                              :show-file-list='false',
                                              :on-change='handleUpload')
                  template(v-if='!file.name')
                    i.icon.icon-file-zip
                    span.big-text Drag file here
                    span.small-text or
                      span browse your computer
                  template(v-else)
                    i.icon.icon-file-zip
                    span.medium-text
                      em Selected file:
                      span {{ file.name }}
                    span.small-text You can choose and drag other file here or
                      span browse your computer
            .upload-step_actions
              el-button.plain-button-reverse(@click='cancelUpload') Cancel
              el-button(type='primary', @click='nextStep(1)') Preview
          el-col(:span='12')
            .upload-step_title File Upload Requirements
            ol.upload-step_list
              li All files must be in .zip format.
              li The Zip file must only contain bills in PDF format, and cannot not contain other .zip files.
              li All bills in a single zip must refer to a single pear.ai customer.
              li PDF bills may exist in a folder structure inside the Zip file.
              li Only one .zip file per batch.
              li The maximum size of a file is 5 GB.

      .upload-step(v-if='activeStep === 1')
        .upload-step_title File Settings
        .upload-step_text You are uploading the following set of bills:
          .upload-step_fields
            .upload-step_field
              label.upload-step_field-label File Name
              .upload-step_field-value {{ file.name }}
            .upload-step_field
              label.upload-step_field-label Customer
              .upload-step_field-value {{ customer.name }}
            .upload-step_field
              label.upload-step_field-label Bill Type
              .upload-step_field-value {{ selectedTypeText }}
            .upload-step_actions
              el-button.plain-button-reverse(@click='cancelUpload') Cancel
              el-button(type='primary', @click='confirmUpload') Confirm Upload

      .upload-step(v-if='activeStep === 2')
        .upload-step_title File Uploading
        .upload-step_text Please wait while the file batch is uploaded to FDG. This may take a few minutes.
        el-progress(:percentage='10', :status='loadingStatus', :data-status='loadingStatusMessage')

      .upload-step(v-if='activeStep === 4')
        .upload-step_title Successfull Upload
        .upload-step_text The file batch has been successfully uploaded.
        .upload-step_text The batch ID is <b>4003</b>.
        el-button(type='text', @click='cancelUpload') Go to Bill Uploads
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      file: {},
      activeStep: 0,
      selectedBillType: '',
      finishStatus: 'success',
      loadingStatus: '',
      loadingStatusMessage: 'Uploading...',
      billType: [
        {
          label: 'Live Bill',
          value: 'live',
        },
        {
          label: 'Historical Bill',
          value: 'hist',
        },
        {
          label: 'Setup Bill',
          value: 'setup',
        },
        {
          label: 'Resubmission (FREE)',
          value: 'free',
        },
        {
          label: 'Resubmission (MAINT)',
          value: 'maint',
        },
        {
          label: 'Special Project',
          value: 'proj',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer || {},
    }),
    selectedTypeText() {
      let text = '';

      this.billType.forEach((item) => {
        if (item.value === this.selectedBillType) {
          text = item.label;
        }
      });

      return text;
    },
  },
  methods: {
    nextStep(step) {
      this.activeStep = step;
    },
    confirmUpload() {
      this.nextStep(2);
    },
    cancelUpload() {
      this.$router.push({ name: 'customer.uploads' });
    },
    handleUpload(file) {
      this.file = file;
    },
  },
};
</script>

<style lang="scss" scoped>
// move this styles to main and change page header to common styles
.page-header {
  &_title {
    color: #273a58;
    font-size: 20px;
    font-weight: 700;
  }
}
.upload {
  &-container {
    padding: 20px 40px;
  }
  &-step {
    &_title {
      font-weight: 700;
    }
    &_text {
      margin-top: 30px;
      color: #222222;
    }
    &_field {
      display: flex;
      align-items: center;
      margin-top: 20px;

      &-label {
        flex: none;
        width: 200px;
      }
      &-value {
        color: #222222;
        font-weight: 600;
      }
    }
    &_actions {
      padding-left: 80px;
      margin-top: 50px;

      .el-button {
        width: 170px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 600;

        + .el-button {
          margin-left: 30px;
        }
      }
    }
    &_list {
      padding-left: 30px;
      margin: 20px 0;
      color: #4a4a4a;

      li {
        + li {
          margin-top: 15px;
        }
      }
    }
    .el-button--text {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
.big-text {
  margin: 9px 0 18px;
  font-size: 18px;
  font-weight: 600;
}
.medium-text {
  margin: 10px 0;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;

  > span {
    padding-left: 15px;
    font-weight: 600;
  }
}
.small-text {
  max-width: 200px;
  font-size: 12px;
  font-weight: 400;

  > span {
    color: #2893f9;
    text-decoration: underline;
  }
}
.icon-file-zip {
  margin-right: 0;
}
</style>

<style lang="scss">
.upload-container {
  .el-col {
    &:first-child {
      padding-right: 15px;
    }

    + .el-col {
      position: relative;
      padding-left: 30px;

      &::before {
        position: absolute;
        left: 0;
        content: '';
        width: 1px;
        height: calc(100vh - 100%);
        background-color: #dcdfe6;
      }
    }
  }
  .el-steps {
    margin: 20px 0 30px;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: #fff;
    counter-reset: step;
  }
  .el-step {
    &__head {
      &.is-wait {
        color: #909399;
        border-color: #909399;
      }
      &.is-success {
        color: #5cb87a;
        border-color: #5cb87a;

        .el-step__icon {
          font-size: 20px;

          &::before {
            font-size: 0 !important;
          }
        }
      }
    }
    &__title {
      &.is-wait {
        color: #909399;
        font-weight: 400;
      }
      &.is-success {
        color: #5cb87a;
      }
    }
    &.is-simple {
      .el-step__icon {
        width: 26px;
        height: 26px;

        &::before {
          counter-increment: step;
          content: counter(step);
          font-size: 16px;
        }
      }
    }
  }
  .el-step.is-simple .el-step__arrow::after,
  .el-step.is-simple .el-step__arrow::before {
    height: 20px;
    background-color: #909399;
  }
  .el-step.is-simple .el-step__arrow::before {
    transform: rotate(-45deg) translatey(-5px);
  }
  .el-step.is-simple .el-step__arrow::after {
    transform: rotate(45deg) translateY(5px);
  }
  .el-input__inner {
    height: 30px;
  }
  .el-progress {
    margin-top: 30px;

    &::before {
      display: block;
      margin-bottom: 20px;
      content: attr(data-status);
      color: #2684de;
      font-size: 14px;
      font-weight: 600;
    }
    &.is-exception {
      &::before {
        color: #f86259;
      }
    }
  }
  .el-progress-bar {
    padding-right: 0;
    margin-right: 0;
  }
  .el-progress-bar__outer {
    height: 24px !important;
    border: 1px solid #dcdfe6;
    background-color: #fff;
  }
  .el-progress__text {
    position: absolute;
    top: 3px;
    right: 0;
    color: #273a58;
    font-size: 12px !important;

    &::after {
      content: ' of 100%';
    }
  }
}
.upload-drag-and-drop {
  width: 100%;
  max-width: 340px;

  .el-upload-dragger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #b2b2b2;
    text-align: center;
  }
  .el-upload {
    display: inherit;
  }
}
</style>
