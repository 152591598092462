<template lang="pug">
  main-layout
    .page.page-observations
      .page-header(:type="'flex'", :justify="'space-between'")
        el-col
          h1.page-title Observations
      .page-search.with-shadow
        i.icon-search
        el-input.page-search-input(placeholder="Search", v-model="query", v-on:input="search")
      .page-content
        .table-wrapper
          el-table.with-shadow(:data="observations",
            v-loading.body.lock='isLoading',
            :style="{ width: '100%' }",
            :default-sort = "{prop: sortProp, order: sortOrder}",
            @sort-change='sortTable')
            el-table-column(prop="id", label="ID", width="80", sortable="custom")
              template(slot-scope="scope")
                span {{ scope.row.id }}

            el-table-column(prop="service_description", label="Service Description", sortable="custom")
              template(slot-scope="scope")
                span {{ scope.row.service_description }}

            el-table-column(prop="code", label="Code", sortable="custom")
              template(slot-scope="scope")
                span {{ scope.row.code }}

            el-table-column(prop="type", label="Type", width="100", sortable="custom")
              template(slot-scope="scope")
                span {{ scope.row.type }}

            el-table-column(prop="category", label="Pear.ai Category", sortable="custom")
              template(slot-scope="scope")
                span {{ scope.row.category }}
        el-pagination.paginator-wrapper(background,
          layout='prev, pager, next, ->, sizes',
          :page-size='limit',
          :pageSizes="[10, 20, 30, 40, 50, 100, 200]",
          :current-page='currentPage',
          @current-change='changeOffset',
          @size-change='changeLimit',
          :total='total')
          .pager-description Items per page
</template>

<script>
import { Main } from '@/components/layouts';
import _ from 'lodash';
import { observations } from '@/api';
import { addGetParam } from '@/lib/route';

export default {
  components: {
    mainLayout: Main,
  },
  data() {
    return {
      query: this.$route.query.query || '',
      limit: Number(this.$route.query.limit) || 200,
      offset: Number(this.$route.query.offset) || 0,
      total: 0,
      order: this.$route.query.orders || '',
      isLoading: true,
      observations: [],
    };
  },
  mounted() {
    this.loadList();
  },
  computed: {
    currentPage() {
      return this.offset / this.limit + 1;
    },
    sortOrder() {
      if (this.order[0] === '-') {
        return 'descending';
      } else if (this.order[0] === '+') {
        return 'ascending';
      }
    },
    sortProp() {
      if (this.order.length > 0) {
        return this.order.substr(1);
      }
    },
  },
  methods: {
    search: _.debounce(function () {
      this.offset = 0;
      addGetParam('query', this.query);
      this.loadList();
    }, 700),
    sortTable(obj) {
      if (obj.prop && obj.order) {
        this.order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      } else {
        this.order = '';
      }
      addGetParam('orders', this.order);
      this.loadList();
    },
    changeOffset(e) {
      this.offset = (e - 1) * this.limit;
      addGetParam('offset', this.offset);
      this.loadList();
    },
    changeLimit(e) {
      this.limit = e;
      addGetParam('limit', this.limit);
      this.changeOffset(1);
    },
    async loadList() {
      this.isLoading = true;
      const params = {
        limit: this.limit,
        offset: this.offset,
        ...(this.query.length && { query: this.query }),
        category: 'not.eq.Non-billing',
      };
      const hasOrder = !_.isEmpty(this.order);
      params.orders = hasOrder ? [this.order] : ['+sort_order'];

      const { data, meta } = await observations.list(params);

      this.observations = data;

      const {
        content_range: { total = 0 },
      } = { ...meta };
      this.total = Number(total);

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  &-observations {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 80px);
    padding: 20px 40px 60px 40px;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;

    .header-button {
      height: 40px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      margin-left: auto;
      border: none;
      border-radius: 4px;
      background-color: #1989fa;
    }
  }

  &-title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
  }

  &-search {
    position: relative;
    margin-bottom: 10px;

    .icon-search {
      width: 40px;
      height: 40px;
      position: absolute;
      // TODO added search icon
      background: url(../../assets/icons/search.svg) center center no-repeat;
      z-index: 1;
    }

    &-input {
      > input {
        border: none;

        /deep/ &.el-input__inner {
          padding: 0 44px;
        }
      }
    }
  }
}
</style>
