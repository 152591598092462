<template lang="pug">
  .exceptions-list-container
    el-dialog.modal-dialog.match-dialog(
      title='Map Virtual Account to Location',
      :visible.sync='isModalOpened',
      width='768px',
      height='620px',
      :before-close="onExceptionModalClose")
      match-modal(:exception='selectedException'
        key="keyMatchModal"
        :matching='isLocationMatching'
        :locations='locations'
        :isModalOpened='isModalOpened'
        :loading='isLocationsLoading'
        @match='matchException'
        @cancel='onExceptionModalClose')
    span.exceptions-list-title Unmapped Virtual Accounts
    filter-input(:showFilter='false', :searchQuery='query', @onSearchUpdate='onSearch')
      .list-filter(v-if='false')
        el-row.list-filter-inputs(size='mini', :gutter="25")
          el-col(:xs="24", :sm="24", :md="5", :lg="5", :xl="5")
            .list-filter-title
              | Type
            el-select(placeholder="Select", size='mini', @change='onFiltersChange', v-model='filter.vendor_codes', multiple, filterable, clearable)
              el-option(v-for='item in filterData.building_types', :key='item', :label='item', :value='item')
          el-col(:xs="24", :sm="24", :md="5", :lg="5", :xl="5")
            .list-filter-title
              | City
            el-select(placeholder="City", size='mini', @change='onFiltersChange', v-model='filter.commodities', multiple, filterable, clearable)
              el-option(v-for='item in filterData.cities', :key='item', :label='item', :value='item')
          el-col(:xs="24", :sm="24", :md="5", :lg="5", :xl="5")
            .list-filter-title
              | State
            el-select(placeholder="State", size='mini', @change='onFiltersChange', v-model='filter.commodities', multiple, filterable, clearable)
              el-option(v-for='item in filterData.states', :key='item', :label='item', :value='item')
    el-table.exceptions-list-table.with-shadow(
      :data='exceptions',
      data-type='exceptions-list',
      @sort-change='sortTable',
      ref='exceptionsListTable'
      @row-click='openMatchExceptionModal',
      v-loading.body.lock='isLoading')
      el-table-column(label='VA ID' prop='virtual_account_id' sortable)
      el-table-column(label='Vendor' prop='pretty_name' sortable)
      el-table-column(label='Meter ID' prop='meter_serial' sortable)
      el-table-column(label='Service Account ID' prop='account_code' sortable)
      el-table-column(label='Service Address' prop='service_address' sortable)
      el-table-column(label='City' v-if='false')
      el-table-column(label='State' v-if='false')
      el-table-column(label='Service Zip' prop='service_zip' width='120' sortable)
      el-table-column(label='#Bill Blocks' prop='items_count' width='120' sortable="custom")
      el-table-column(width='100')
        template(slot-scope='scope')
          el-button.match-button(type='text', @click.native='openMatchExceptionModal(scope.row)') Map
    el-pagination.paginator-wrapper(background,
        v-if='total > limit || limit > 20',
        layout='prev, pager, next, ->, sizes',
        :page-size='limit',
        :current-page='currentPage',
        @current-change='changeOffset',
        @size-change='changeLimit',
        :total='total')
</template>

<script>
import { mapState } from 'vuex';
import { FilterInput } from '@/components';
import { exceptions } from '@/api';
import * as R from 'ramda';
import _ from 'lodash';
import { pickNotEmpty, pickNotNil } from '@/lib';
import MatchModal from './MatchModal';

export default {
  components: {
    FilterInput,
    MatchModal,
  },
  data() {
    return {
      exceptions: [],
      isLoading: [],
      selectedException: {},
      isModalOpened: false,
      limit: 20,
      total: 0,
      offset: 0,
      query: '',
      order: '',
      locations: [],
      isLocationsLoading: true,
      isLocationMatching: false,
      keyMatchModal: 0,
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer || {},
    }),
    currentPage() {
      return this.offset / this.limit + 1;
    },
  },
  methods: {
    list() {
      this.isLoading = true;
      const params = R.pipe(
        pickNotEmpty,
        pickNotNil,
      )({
        query: this.query,
        limit: this.limit,
        offset: this.offset,
        orders: this.order,
      });
      exceptions
        .list(this.$route.params.customerId, params)
        .then((response) => {
          const { data = [], meta = {} } = { ...response };
          this.total = Number(meta.content_range.total);
          this.exceptions = data;
          this.isLoading = false;
        });
    },

    sortTable(obj) {
      if (obj.prop && obj.order) {
        this.order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      } else {
        this.order = '';
      }
      this.list();
    },

    openMatchExceptionModal(exception = {}) {
      const exceptionCamelCase = _.mapKeys(exception, (value, key) =>
        _.camelCase(key),
      );
      this.keyMatchModal++;
      this.selectedException = { ...exceptionCamelCase };
      this.locationsList(this.selectedException.serviceZip);
      this.isModalOpened = true;
    },

    onExceptionModalClose() {
      this.isModalOpened = false;
      this.selectedException = {};
    },

    matchException({ location, mapServiceAddress }) {
      const matchParams = {
        locationId: +location.id,
        virtualAccountId: +this.selectedException.virtualAccountId,
      };
      if (mapServiceAddress) {
        matchParams.serviceAddress = this.selectedException.serviceAddress;
      }
      this.isLocationMatching = true;
      exceptions
        .matchException(this.$route.params.customerId, location.id, matchParams)
        .then((data) => {
          this.onExceptionModalClose();
          this.list();
          this.isLocationMatching = false;
        });
    },

    onSearch(query) {
      this.query = query;
      this.offset = 0;
      this.list();
    },

    onFiltersChange() {
      this.offset = 0;
      this.list();
    },

    changeOffset(page) {
      this.offset = (page - 1) * this.limit;
      this.list();
    },

    changeLimit(limit) {
      this.offset = 0;
      this.limit = limit;
      this.list();
    },

    locationsList(id) {
      this.isLocationsLoading = true;
      return exceptions.getMatchesList(this.customer.id, id).then((e) => {
        this.locations = [..._.get(e, 'data', [])];
        this.isLocationsLoading = false;
        return this.locations;
      });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style lang="scss" scoped>
.exceptions-list {
  &-container {
    margin: 20px 40px;
  }
  &-title {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 18px;
  }
  &-table {
    max-height: 75%;
  }
}
</style>

<style lang="scss">
@import './../../../../styles/constants.scss';
.exceptions-list-table {
  &.el-table--enable-row-hover {
    .el-table__body tr:hover {
      > td {
        background-color: #f5f7fa;
        cursor: pointer;
      }
    }
  }
}
.match-button {
  color: $primary-hover;

  &:hover {
    color: $primary-hover;
  }
}
.match-dialog {
  .el-dialog {
    height: auto;
  }
}
</style>
