import { hierarchies, locations } from '@/api';
import * as dot from 'dot-object';
import { pathOr } from 'ramda';

const getDefaultState = () => ({
  location: {},
  locations: {},
  meta: {},
  filters: {},
  loading: false,
  hierarchy: [],
  customerLocationsMetadata: [],
  hierarchyLoading: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),

  getters: {
    name: (state) => state.location.name || '',
    id: (state) => state.location.id,
    location: (state) => state.location,
    locations: (state) => state.locations,
    meta: (state) => state.meta,
    filters: (state) => state.filters,
    customerLocationsMetadata: (state) => state.customerLocationsMetadata,
    loading: (state) => state.loading,
    hierarchy: (state) => state.hierarchy,
    hierarchyLoading: (state) => state.hierarchyLoading,
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCurrentLocation(state, location) {
      state.location = { ...location };
    },
    setHierarchyLoading(state, loading) {
      state.hierarchyLoading = loading;
    },
    setHierarchy(state, data) {
      state.hierarchy = data;
    },
    setCustomerLocationsMetadata(state, data) {
      state.customerLocationsMetadata = data;
    },
    setLocations(state, data) {
      state.locations = data;
    },
    setMeta(state, data) {
      state.meta = data;
    },
    setFilters(state, data) {
      state.filters = data;
    },
    resetHierarchy(state) {
      state.hierarchy = [];
    },
    clearState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  actions: {
    async fetchDefaultHierarchy({ commit, dispatch }, { customerId }) {
      try {
        commit('setHierarchyLoading', true);
        const { results } = await hierarchies.fetchDefaultHierarchy(customerId);
        await commit('setHierarchy', results);
      } catch (e) {
        await commit('resetHierarchy');
      } finally {
        commit('setHierarchyLoading', false);
      }
    },
    async setLocation({ commit }, { customerId, locationId }) {
      try {
        commit('setLoading', true);

        let location = await locations.get(customerId, locationId);
        const payload = pathOr({}, ['payload'], location);

        location = {
          ...location,
          payload: {
            ...dot.object(payload),
            metadata: payload.metadata ? payload.metadata : [],
          },
        };

        commit('setCurrentLocation', location);
      } catch (e) {
        console.error(e);
      } finally {
        commit('setLoading', false);
      }
    },

    async getCustomerLocationsMetadata({ commit }, customerId) {
      try {
        commit('setLoading', true);
        const { results } = await locations.getLocationsMetadata(
          customerId,
          1000,
        );
        commit('setCustomerLocationsMetadata', results);
      } catch (e) {
        console.log(e);
      } finally {
        commit('setLoading', false);
      }
    },

    async putLocation({ dispatch }, { customerId, locationId, body }) {
      await locations.put(customerId, locationId, body);
      dispatch('setLocation', { customerId, locationId });
    },

    async getLocations({ commit, state }, customerId) {
      try {
        commit('setLoading', true);
        const response = await locations.list(customerId, state.filters);
        const { data = [], meta = {} } = { ...response };
        commit('setLocations', [...data]);
        commit('setMeta', meta);
      } catch (e) {
        console.log(e);
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
