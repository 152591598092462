<template lang="pug">
  .locations-list-container
    .header-wrapper
      span.locations-list-title Locations for {{ customer.name }}
      .page-header-actions
        create-modal(@updated="list" :entitlements="entitlements")
    filter-input(:searchQuery='query', @onSearchUpdate='onSearchQueryChange')
      .list-filter
        el-row.list-filter-inputs(size='mini', :gutter="25")
          el-col(:xs="24", :sm="24", :md="5", :lg="5", :xl="5")
            .list-filter-title
              | Type
            el-select(placeholder="Select", size='mini', @change='onFiltersChange', v-model='filter.building_types', multiple, clearable, filterable, popper-class='filter-by-type')
              el-option(v-for='item in filterData.building_types', :key='item', :label='item', :value='item')
          el-col(:xs="24", :sm="24", :md="5", :lg="5", :xl="5")
            .list-filter-title
              | City
            el-select(placeholder="City", size='mini', @change='onFiltersChange', v-model='filter.city', filterable, clearable, popper-class='filter-by-city')
              el-option(v-for='item in filterData.cities', :key='item', :label='item', :value='item')
          el-col(:xs="24", :sm="24", :md="5", :lg="5", :xl="5")
            .list-filter-title
              | State
            el-select(placeholder="State", size='mini', @change='onFiltersChange', v-model='filter.state', filterable, clearable, popper-class='filter-by-state')
              el-option(v-for='item in filterData.states', :key='item', :label='stateLabel(item)', :value='item')
          el-col(:xs="48", :sm="48", :md="8", :lg="8", :xl="8")
            .list-filter-title
              | Area (Sq. Ft.)
            .input-row
              el-input(v-model="filter.square_feet_min", placeholder="Min", @change='onFiltersChange')
              span to
              el-input(v-model="filter.square_feet_max", placeholder="Max", @change='onFiltersChange')
    el-table.locations-list-table.with-shadow(
      :data='locationsList',
      @row-click='goTo',
      data-type='locations-list',
      @sort-change='sortTable',
      :default-sort = "{prop: sortProp, order: sortOrder}",
      v-loading.body.lock='isLoading')
      el-table-column(label='Description', prop='name', sortable)
      el-table-column(label='Type', prop='building_type', width='100', sortable)
      el-table-column(label='Service Address', prop='address', sortable)
      el-table-column(label='City', prop='city', sortable)
      el-table-column(label='State', prop='state', width='80', sortable)
        template(slot-scope='scope')
          span {{ removeCountry(scope.row.state) }}
      el-table-column(label='Zip', prop='postcode', width='80', sortable)
      el-table-column(label='Area (Sq. Ft)', prop='square_feet', width='140', sortable)
      el-table-column(label='Weather', prop='weather_active', width='100', sortable, :sort-method="sortWeather")
        template(slot-scope='scope')
          span.weather-active(:class="[scope.row.weather_active ? 'weather-active-color' : 'weather-active-not']")
    el-pagination.paginator-wrapper(background,
      v-if='total > limit || limit > 20',
      layout='prev, pager, next, ->, sizes',
      :page-size='limit',
      :current-page='currentPage',
      @current-change='changeOffset',
      @size-change='changeLimit',
      :total='total')
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { locations } from '@/api';
import { FilterInput } from '@/components';
import * as R from 'ramda';
import CreateModal from './CreateModal';
import { addGetParam, addGetParams } from '@/lib/route';

export default {
  components: {
    FilterInput,
    CreateModal,
  },
  data() {
    return {
      showCreateModal: false,
      locationsList: [],
      isLoading: true,
      query: this.$route.query.query || '',
      limit: Number(this.$route.query.limit) || 20,
      offset: Number(this.$route.query.offset) || 0,
      total: 0,
      order: this.$route.query.orders || '+name',
      filterData: {
        building_types: [],
        cities: [],
        square_feet: { min: 0, max: 0 },
        states: [],
      },
      filter: {
        city: '',
        state: '',
        building_types: [],
        square_feet_min: '',
        square_feet_max: '',
        orders: '+name',
      },
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer || {},
      entitlements: (state) => state.customers.customer.entitlements || {},
    }),
    ...mapGetters({
      getResourceByName: 'resources/getResourceByName',
    }),
    currentPage() {
      return this.offset / this.limit + 1;
    },
    sortOrder() {
      if (this.order[0] === '-') {
        return 'descending';
      } else if (this.order[0] === '+') {
        return 'ascending';
      }
    },
    sortProp() {
      if (this.order.length > 0) {
        return this.order.substr(1);
      }
    },
  },
  watch: {
    query(value) {
      addGetParam('query', value);
    },
    limit(value) {
      addGetParam('limit', value);
    },
    offset(value) {
      addGetParam('offset', value);
    },
    order(value) {
      addGetParam('orders', value);
    },
    filter: {
      deep: true,
      handler: function (value) {
        addGetParams(value);
      },
    },
  },
  mounted() {
    const keys = R.keys(this.filter);
    const notEmpty = (v, k) => !R.isEmpty(v) && keys.includes(k);
    this.filter = { ...this.filter, ...R.pickBy(notEmpty, this.$route.query) };
    this.list();
    this.getFiltersData();
  },
  methods: {
    sortWeather(a, b) {
      let nr1 = a.weather_active === true ? 1 : 0;
      let nr2 = b.weather_active === true ? 1 : 0;
      return nr1 - nr2;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    list() {
      const building_type = _.castArray(this.filter.building_types);
      const { limit, offset, query, order } = this;
      let params = {
        ...this.filter,
        limit,
        offset,
        query,
        orders: [order],
        ...{ building_type },
      };
      this.isLoading = true;
      locations
        .list(
          this.$route.params.customerId,
          R.pickBy(R.pipe(R.isEmpty, R.not), params),
        )
        .then(
          (response) => {
            const { data = [], meta = {} } = { ...response };
            this.locationsList = [...data];
            const {
              content_range: { total = 0 },
            } = { ...meta };
            this.total = Number(total);

            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          },
        );
    },

    sortTable(obj) {
      if (obj.prop && obj.order) {
        let prop = obj.prop;
        if (prop.indexOf('payload.') >= 0) {
          prop = prop.replace('.', '->');
        }
        this.order = `${obj.order === 'descending' ? '-' : '+'}${prop}`;
      } else {
        this.order = '';
      }
      this.list();
    },

    onSearchQueryChange(query) {
      this.query = query;
      this.offset = 0;
      this.list();
    },

    getFiltersData() {
      locations.getFiltersData(this.$route.params.customerId).then((data) => {
        this.filterData = {
          ...this.filterData,
          square_feet: {
            ...this.filterData.square_feet,
            ...R.map((e) => +e, data.square_feet),
          },
          cities: data.city,
          states: data.state,
          building_types: data.building_type,
        };
        // this.filter = {...this.filter, square_feet: ([...Object.values(this.filterData.square_feet || {})].map(e => +e))};
      });
    },

    goTo({ id = 0 }) {
      const { customerId } = this.$route.params;
      this.$router.push({
        name: 'customer.locations.info',
        params: { locationId: id, customerId },
        query: this.$route.query,
      });
    },
    onFiltersChange() {
      this.offset = 0;
      this.list();
    },

    changeOffset(page) {
      this.offset = (page - 1) * this.limit;
      this.list();
    },

    changeLimit(limit) {
      this.offset = 0;
      this.limit = limit;
      this.list();
    },
    stateLabel(state) {
      const label = R.pathOr(
        '',
        ['value', state, 'pretty_name'],
        this.getResourceByName('states'),
      );
      state = this.removeCountry(state);
      return `${label} (${state})`;
    },
    removeCountry(state) {
      return (state || '').replace(/^.*-/g, '');
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/dot.scss';
.locations-list {
  &-container {
    margin: 13px 40px 20px 40px;

    .input-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      > span {
        margin: 0 15px;
      }

      .el-input__inner {
        height: 28px;
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 600;
    display: block;
  }

  &-table {
    max-height: 75%;
  }
}
.weather-active {
  @include weather-active;
}
</style>

<style lang="scss" scoped>
.header-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
