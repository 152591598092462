// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './set-public-path.js';
import 'babel-polyfill';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import Vuex from 'vuex';
import VeeValidate from 'vee-validate';
import date from 'vue-date-filter';
import VueCurrencyFilter from 'vue-currency-filter';
import VueFilter from 'vue-filter';
import VueClipboard from 'vue-clipboard2';
import { VueNestable, VueNestableHandle } from 'vue-nestable';
import { InlineSvgPlugin } from 'vue-inline-svg';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

import { pipes } from '@/lib';

import App from './App.vue';
import router from './router';
import store from './store';
import VueCodeHighlight from 'vue-code-highlight';

Vue.use(VueCodeHighlight);

VeeValidate.Validator.extend('double', {
  getMessage: (field) => 'The ' + field + ' value is not double.',
  validate: (value) => Number(value).toString() === String(value),
});

Vue.component('VueNestable', VueNestable);
Vue.component('VueNestableHandle', VueNestableHandle);
Vue.use(Vuex);
Vue.use(VeeValidate, {
  fieldsBagName: 'veefields',
});
VeeValidate.Validator.extend('verify_password', {
  getMessage: (field) => `The password you have chosen is not valid.
  Please make sure that your password is at least 8 characters long and includes at least three of the following:
  lowercase letters, uppercase letters, numbers and/or special characters (such as *, #, %, etc.).`,
  validate: (value) => {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
    );
    return strongRegex.test(value);
  },
});
Vue.use(ElementUI, { locale });
Vue.use(date);
Vue.use(VueFilter);
Vue.use(VueClipboard);
Vue.use(InlineSvgPlugin);

Vue.config.productionTip = false;

Vue.filter('datetime', pipes.datetime);
Vue.filter('unit', pipes.unit);
Vue.filter('price', pipes.formatPrice);
Vue.filter('toTitleCase', pipes.toTitleCase);
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
});

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
          // /*
          singleSpaData: this.props,
          // mountParcel: this.mountParcel,
          // singleSpa: this.singleSpa,
          // */
        },
      });
    },
    router,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
