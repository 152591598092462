<template>
  <el-form label-position="left">
    <div v-for="locationAttr in locationAttrs" :key="locationAttr.key">
      <base-input
        v-if="isStringOrNumeric(locationAttr.fieldType)"
        label-width="400px"
        :isEditMode="true"
        class="custom-fields"
        :type="getInputType(locationAttr.fieldType)"
        v-model="locationAttr.val"
        placeholder="Search"
        :label="locationAttr.value"
        size="medium"
        @input="onInput(locationAttr, $event)"
      />
      <base-select
        v-if="isSelectable(locationAttr.fieldType)"
        label-width="400px"
        :isEditMode="true"
        :filterable="true"
        class="custom-fields"
        size="medium"
        :ordered="true"
        :label="locationAttr.value"
        immediate
        @change="onChange(locationAttr, $event)"
        :multiple="locationAttr.isMultiselect"
        v-model="locationAttr.val"
      >
        <el-option
          v-for="v in locationAttr.fieldValues"
          :key="v.key"
          :value="v.value"
          :label="v.value"
        />
      </base-select>
      <base-date-picker
        v-if="isDate(locationAttr.fieldType)"
        :isEditMode="true"
        label-width="400px"
        class="custom-fields"
        v-model="locationAttr.val"
        :label="locationAttr.value"
        @change="onChange(locationAttr, $event)"
        size="medium"
      />
    </div>
  </el-form>
</template>

<script>
import BaseInput from '@/components/FormComponents/BaseInput';
import BaseSelect from '@/components/FormComponents/BaseSelect';
import BaseDatePicker from '../../../../components/FormComponents/BaseDatePicker';
import _ from 'lodash';

export default {
  props: {
    locationAttrs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      locationAttrsVals: {},
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseDatePicker,
  },
  computed: {
    isStringOrNumeric() {
      return (fieldType) => ['Numeric', 'String'].includes(fieldType);
    },
    getInputType() {
      return (fieldType) => (fieldType === 'Numeric' ? 'number' : '');
    },
    isSelectable() {
      return (fieldType) => fieldType === 'List';
    },
    isDate() {
      return (fieldType) => fieldType === 'Date';
    },
  },
  methods: {
    onInput: _.debounce(function (locationAttr, val) {
      this.locationAttrsVals = {
        ...this.locationAttrsVals,
        [locationAttr.key]: isNaN(+val) ? val : +val,
      };
      this.$emit('set-location-attrs', this.locationAttrsVals);
    }, 500),
    onChange(locationAttr, val) {
      this.locationAttrsVals = {
        ...this.locationAttrsVals,
        [locationAttr.key]: val,
      };
      this.$emit('set-location-attrs', this.locationAttrsVals);
    },
  },
};
</script>

<style lang="scss" scoped>
.pt-16 {
  padding-top: 16px;
}
::v-deep .custom-fields {
  .el-form-item__label {
    margin-top: 16px;
  }
  .el-form-item__content {
    margin-top: 16px;
  }
  .el-input__inner {
    width: 280px;
  }
}
</style>
