<template>
  <!--  Extended in Vue3, 'this' object gets forwarded to Vue3-nav for display and navigation purposes  -->
  <!--  We still need to keep the breadcrumbs and change password popup for now to avoid porting the stores for now  -->
  <div class="main">
    <main class="main-container">
      <div
        class="main-container-breadcrumbs"
        v-if="$route.meta.showBreadcrumbs"
      >
        <slot name="breadcrumbs">
          <breadcrumbs class="breadcrumbs" :route="$route"></breadcrumbs>
        </slot>
      </div>
      <slot></slot>
    </main>
  </div>
</template>

<script>
import Breadcrumbs from '../Breadcrumbs';

export default {
  data() {
    return {};
  },
  components: {
    Breadcrumbs,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/constants';

#single-spa-parcel {
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    &-container {
      width: 100%;
      overflow: auto;
      background-color: $main-background;
      padding-top: 20px;

      &-breadcrumbs {
        width: 100%;
        position: fixed;
        top: 0;
        background-color: $secondary-backgound;
        min-height: 20px;
        box-shadow: $primary-shadow;
        z-index: 10;
      }
    }

    .breadcrumbs {
      margin-left: 40px;
      padding-top: 5px;

      .el-breadcrumb__item {
        font-size: 10px;
        &[aria-current='page'] {
          > .el-breadcrumb__inner {
            font-weight: bold;
          }
        }
        .el-breadcrumb__inner {
          font-weight: normal;
        }
      }
    }
  }
}
</style>
