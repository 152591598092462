<template lang="pug">
  .table-observation-wrapper
    el-table.table-observation(:data="observations", style="width: 100%", v-loading="loading")
      template(v-if="hidden")
        el-table-column(prop="service_description", label="Description", width="270")
          template(slot-scope='scope')
            bill-editor-select(:options="observationList",
              :selected="scope.row.description",
              :onSelectChange="onHiddenObservationChange"
              :index="scope.$index"
              :itemValue="'id'",
              :itemLabel="'service_description'")
        el-table-column(prop="code", label="Code", width="180")
          template(slot-scope='scope')
            el-input(v-model="scope.row.code", size="mini", readonly)
        el-table-column(prop="value", label="Value", width="250")
          template(slot-scope='scope')
            el-input(v-model="scope.row.value", size="mini", :disabled="!scope.row.description")
      template(v-else)
        el-table-column(prop="service_description", label="Service Description", width="270")
          template(slot-scope='scope')
            bill-editor-select(:options="observationList",
              :selected="scope.row.description",
              :onSelectChange="onObservationChange"
              :index="scope.$index"
              :itemValue="'id'",
              :itemLabel="'service_description'",
              data-testid='obsDescription')
        el-table-column(prop="value", label="Label", width="250")
          template(slot-scope='scope')
            el-input(v-model="scope.row.value", size="mini", :disabled="!scope.row.description", data-testid='obsLabel')
        el-table-column(prop="usage", label="Usage", width="180")
          template(slot-scope='scope')
            el-input(v-model="scope.row.usage", size="mini", :disabled="scope.row.type === 'C' || !scope.row.description", data-testid='obsUsage')
        el-table-column(prop="uom", label="UoM", width="180")
          template(slot-scope='scope')
            bill-editor-select(:options="uomsList",
              :selected="scope.row.uom",
              :onSelectChange="onUomsChange"
              :index="scope.$index"
              :disabled="scope.row.type === 'C' || !scope.row.description",
              data-testid='obsUOM')
        el-table-column(prop="charge", label="Charge", width="180")
          template(slot-scope='scope')
            el-input(v-model="scope.row.charge", size="mini", :disabled="scope.row.type === 'U' || !scope.row.description", data-testid='obsCharge')
        el-table-column(prop="currency", label="Currency", width="180")
          template(slot-scope='scope')
            bill-editor-select(:options="getResourceByName('currencies').value"
              :selected="scope.row.currency"
              :onSelectChange="onCurrencyChange"
              :index="scope.$index"
              :disabled="true",
              data-testid='obsCurrency')
      el-table-column(label="Action", type="index", width="180")
        template(slot-scope='scope')
          span.button-remove(@click="remove(scope.$index)", data-testid='obsRemove') Remove

    .summary
      .button-add-observation(@click="add") + Add new Observation
      .total(v-if="!hidden && nativeCurrency && nativeCurrency !== 'USD'") Subtotal: {{ nativeCurrency }} {{ subTotal | currency('') }}
      .total(v-if="!hidden && (!nativeCurrency || nativeCurrency === 'USD')") Subtotal: {{ subTotal | currency }}
</template>

<script>
import BillEditorSelect from './Select';
import _ from 'lodash';
import * as R from 'ramda';
import { mapGetters } from 'vuex';

export default {
  components: {
    BillEditorSelect,
  },
  props: {
    observationList: {
      type: Array,
      default: () => [],
    },
    uomsList: {
      type: Array,
      default: () => [],
    },
    nativeCurrency: {
      type: String,
      default: () => 'USD',
    },
    data: {
      type: Array,
      default: () => [],
    },
    block: '',
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    const instance = this;
    const rowsBuffer = 5;
    const { data } = this;
    this.loading = true;

    let temp = [];
    this.loading = data.length !== 0;
    _.chunk(data, rowsBuffer).forEach(function (rows, index, arr) {
      temp = [...temp, ...rows];
    });
    this.observations = temp;
    instance.loading = false;
  },
  data() {
    return {
      observations: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getResourceByName: 'resources/getResourceByName',
    }),
    subTotal() {
      let sum =
        (this.observations || [])
          .map((el) => el.charge)
          .reduce((acc, val) => acc + (+val || 0), 0) || 0;
      sum = sum.toFixed(3);
      return +sum === 0 ? 0 : sum;
    },
  },
  watch: {
    observations: {
      deep: true,
      handler: function (value) {
        this.pushUpdates(value);
      },
    },
    data: function (value) {
      if (!R.isEmpty(value)) {
        this.observations = value;
      }
    },
  },
  methods: {
    pushUpdates() {
      this.$emit('onChange', R.clone(this.observations) || []);
    },
    remove(index) {
      this.observations.splice(index, 1);
    },
    add() {
      const observation = {
        block: this.block,
        charge: null,
        code: null,
        currency: this.nativeCurrency || 'USD',
        description: null,
        type: null,
        usage: null,
        uom: null,
        value: null,
      };
      this.observations.push(observation);
    },
    onObservationChange(value, index) {
      const observation = _.find(this.observationList, function (o) {
        return o.id === value;
      });
      const type = _.get(observation, ['type'], '');
      this.observations[index].code = _.get(observation, ['code'], '');
      this.observations[index].description = _.get(
        observation,
        ['service_description'],
        '',
      );
      this.observations[index].type = type;
      this.observations[index].currency = this.nativeCurrency || 'USD';
      if (type === 'C') {
        this.observations[index].uom = null;
        this.observations[index].usage = null;
      } else if (type === 'U') {
        this.observations[index].charge = null;
      }
      this.observations = [...this.observations];
    },
    onHiddenObservationChange(value, index) {
      const observation = _.find(this.observationList, function (o) {
        return o.id === value;
      });
      this.observations[index].code = _.get(observation, ['code'], '');
      this.observations[index].description = _.get(
        observation,
        ['service_description'],
        '',
      );
      this.observations[index].value = '';
      this.observations = [...this.observations];
    },
    onUomsChange(value, index) {
      this.observations[index].uom = value;
      this.observations = [...this.observations];
    },
    onCurrencyChange(value, index) {
      this.observations[index].currency = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/constants.scss';

.table-observation-wrapper {
  overflow-y: auto;
}

.table-observation {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  overflow: initial;
  min-width: 1420px;

  /deep/ {
    .cell {
      overflow: initial;
    }

    .el-table__body-wrapper {
      overflow: initial;
    }
  }
}

.el-input {
  flex-grow: 1;
  max-width: initial;
  flex-basis: 1%;
}

.summary {
  display: flex;
  justify-content: space-between;
  margin: 18px 0 18px 30px;

  .button-add-observation {
    cursor: pointer;
    color: #2893f9;
    font-size: 14px;
    text-decoration: underline;
  }

  .total {
    color: #606266;
    font-size: 16px;
    font-weight: bold;
    line-height: 14px;
  }
}

/deep/ {
  .el-table {
    padding: 0 6px;

    .el-select {
      width: 100%;
    }
  }

  .el-table__header-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    width: 98%;
    margin-bottom: 10px;
    background-color: #fdfdfd;
    box-shadow: 0 2px 6px 0 rgba(227, 208, 208, 0.5);

    th {
      border-bottom: none;
    }
  }

  .el-table {
    &::before {
      display: none;
    }

    td {
      border-bottom: none;
    }
  }
}
</style>
