<template lang="pug">
.list-filter-wrapper
  .list-filter-inner
    .list-filter
      .list-filter-input
        .list-filter-title Meter ID:
        bills-filter-select(:options="uniqBy(prop('meterSerial'), items)", v-model="filters.meterSerial", itemLabel="meterSerial", item-value="meterSerial")
      .list-filter-input
        .list-filter-title Utility:
        bills-filter-select(:options="uniqBy(prop('commodity'), items)", v-model="filters.commodity", item-label="commodity", item-value="commodity")
      .list-filter-input
        .list-filter-title Service Account ID:
        bills-filter-select(:options="uniqBy(prop('accountCode'), items)", v-model="filters.accountCode", item-label="accountCode", item-value="accountCode")
      .list-filter-input
        .list-filter-title Bill Type:
        bills-filter-select(:options="uniqBy(prop('billType'), items)", v-model="filters.billType", item-label="billType", item-value="billType")
    .list-filter-clear
      el-button.fw-400(type="text" @click="clear") Clear
</template>

<script>
import { mapGetters } from 'vuex';
import { FilterInput } from '@/components';
import BillsFilterSelect from '@/pages/Bills/Filters/Select';
import * as R from 'ramda';

const defaultFilters = () => ({
  meterSerial: [],
  commodity: [],
  accountCode: [],
  billType: [],
});

export default {
  name: 'BillsFilters',
  components: {
    FilterInput,
    BillsFilterSelect,
  },
  beforeMount() {
    const { virtual_account_id } = this.$route.query;
    if (!virtual_account_id) {
      Object.assign(this.filters, defaultFilters());
    }
  },
  data() {
    return {
      filters: defaultFilters(),
    };
  },
  computed: {
    ...mapGetters({
      bill: 'bills/bill',
    }),
    items() {
      return R.propOr([], 'items', this.bill);
    },
    locationNamesList() {
      const locations = R.reduce((acc, val) => {
        const name = R.path(['location', 'name'], val);
        return name ? [...acc, name] : acc;
      }, [])(this.items);
      return R.uniq(locations);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: async function (filters) {
        this.$emit('change', R.pickBy(R.pipe(R.isEmpty, R.not), filters));
      },
    },
  },
  methods: {
    clear() {
      Object.assign(this.filters, defaultFilters());
    },
    uniqBy: R.uniqBy,
    prop: R.prop,
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  &-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &-clear {
    flex-grow: 1;
    margin: 34px 25px 0 0;
    text-align: right;

    .el-button {
      text-decoration: none;
    }
  }

  &-title {
    white-space: nowrap;
    font-size: 12px;
    margin-bottom: 4px;
  }

  &-input {
    flex-basis: 200px;
    margin: 15px 45px 15px 0;
  }
}

.el-date-editor {
  width: 100%;
}
</style>
