<template lang="pug">
  .confirm-modal_container
    .modal_content Are you sure you want to proceed?
    .modal_actions
      el-button.plain-button-reverse(@click='cancel') Cancel
      el-button(type='primary', @click='confirm', :loading="isFetching") Confirm
</template>

<script>
export default {
  props: {
    isFetching: {
      required: true,
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirmEditSubmit');
    },
    cancel() {
      this.$emit('closeConfirmEditModal');
    },
  },
};
</script>
