<template lang="pug">
.bill-detail-page()
    .bill-detail__header
        bill-header(@update="loadBill" :users="propOr([], 'users', filtersData)")
    .bill-detail__container
        .bill-detail__content
            .bill-detail__content--top
                bill-top
            .bill-detail__content--tabs
                bill-tabs(
                  :isSideBarVisible="isSideBarVisible"
                  @update="loadBill"
                  :observationList="observationList")                )
        .bill-detail__sidebar(:class="{'is-visible': isSideBarVisible}" ref="sidebar")
            .resize-area(@mousedown="startResize")
            bill-side-bar(:key="updateSidebar" :sidebar="sidebar", :billValidations='billValidations' @loadBill="load")
        .bill-detail__sidebar-buttons
            bill-side-bar-buttons(@open="showSidebar", @close="hideSideBar")
</template>

<script>
import BillHeader from './components/BillHeader';
import BillTop from './components/BillTop';
import BillTabs from './components/BillTabs';
import BillSideBar from './components/BillSideBar';
import BillSideBarButtons from './components/BillSideBarButtons';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import { bills, observations } from '@/api';
import * as R from 'ramda';
import { Events } from '@/lib';
import { billOperatorNotes } from '../../../../api/bills';

export default {
  components: {
    BillHeader,
    BillTop,
    BillTabs,
    BillSideBar,
    BillSideBarButtons,
  },
  async mounted() {
    await this.load();
    await this.getCustomersList();
    this.showSidebar('isErrorsVisible');
    const channel = new BroadcastChannel('closeEdit');
    channel.onmessage = () => {
      if (this.$route.name === 'customer.bills.info') {
        location.reload();
      }
    };
  },
  beforeDestroy() {
    this.clearBill();
  },
  data() {
    return {
      originalWidth: null,
      originalMouseX: null,
      maxWidth: 900,
      minWidth: 540,
      isSideBarVisible: false,
      sidebar: null,
      billHistoryModal: false,
      bill: { files: [] },
      isLoading: true,
      billValidations: [],
      isErrorModalOpened: false,
      isCommentsShown: false,
      hideCommentsLink: true,
      deletePrompt: false,
      processBillEventInterval: null,
      observationList: [],
      vendorId: null,
      updateSidebar: 0,
    };
  },
  watch: {
    async $route(to, from) {
      if (this.dontReload) {
        return;
      }
      await this.loadBill();
      this.updateSidebar++;
    },
    bill(value) {
      const { vendorCode } = value;
      this.vendorId = this.getVendorIdByCode(vendorCode);
    },
    'portingProps.refreshBillNotes': {
      async handler(val) {
        if (val) {
          this.bill.items = await Promise.all(
            this.bill.items.map(this.addOperatorNotes),
          );
          this.loadComments();
          this.setBill(R.clone(this.bill));
        }
      },
    },
  },
  computed: {
    ...mapState({
      portingProps: (state) => state.porting.props,
    }),
    ...mapGetters({
      getVendorIdByCode: 'resources/getVendorIdByCode',
      filtersData: 'globalBills/filtersData/filterData',
      dontReload: 'bills/dontReload',
    }),
  },
  methods: {
    ...mapMutations({
      clearBill: 'bills/clearBill',
      setDontReload: 'bills/setDontReload',
    }),
    ...mapActions({
      listHistory: 'bills/listHistory',
      loadMoreComments: 'bills/loadMoreComments',
      setTotalComments: 'bills/setTotalComments',
      setBill: 'bills/setBill',
      getFilterData: 'globalBills/filtersData/getFilterData',
      getCustomersList: 'customers/getCustomersList',
      setBillIsFrozen: 'bills/setBillIsFrozen',
    }),
    propOr: R.propOr,
    async load() {
      await this.loadObservations();
      await this.loadBill();
      await this.getFilterData();
      await this.loadBillValidations();
    },
    async loadBillValidations() {
      try {
        const { results } = await bills.billValidationDefinitions();
        this.billValidations = results;
      } catch (e) {
        console.log(e);
      }
    },
    showSidebar(sidebar) {
      this.sidebar = sidebar;
      this.isSideBarVisible = true;
    },
    hideSideBar() {
      this.$refs.sidebar.style.flexBasis = null;
      this.isSideBarVisible = false;
    },
    async loadBill() {
      // if (status === 401) {
      //   store.dispatch('user/logout');
      //   window.location.href = '/auth/login';
      // }
      const { billId = 0, customerId } = this.$route.params;
      this.isLoading = true;
      this.clearBill();
      await this.listHistory({ customerId, billId });
      this.updateSidebar++;
      return bills
        .get(customerId, billId)
        .then(async (data) => {
          this.bill = this.addObservationType(data);
          this.bill.items = await Promise.all(
            this.bill.items.map(this.addOperatorNotes),
          );
          return this.setBill(R.clone(this.bill));
        })
        .then(() => {
          this.setBillIsFrozen(R.clone(this.bill));
          this.loadComments();
        })
        .catch((error) => {
          const status = R.pathOr('', ['response', 'status'], error);
          if (status === 404) {
            Events.$emit('not.found');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async loadComments() {
      const { billId = 0, customerId } = this.$route.params;
      this.hideCommentsLink = true;
      if (billId) {
        const { results, total } = await bills.getComments(customerId, billId);
        this.setTotalComments(total || 0);
        await this.loadMoreComments(results || []);
      }
      this.hideCommentsLink = false;
    },
    async loadObservations() {
      const { data } = await observations.list({
        limit: 1000,
        offset: 0,
      });
      this.observationList = data;
    },
    async addOperatorNotes(item) {
      let operatorNotes;
      try {
        operatorNotes = await billOperatorNotes(
          this.$route.params.customerId,
          item.virtualAccountId,
        );
      } catch (e) {
        console.log(e);
      }
      item = { ...item, operatorNotes };
      return item;
    },
    addObservationType(data) {
      const addCategory = R.map((billingLineItem) => {
        const { description } = billingLineItem;
        const category = R.pipe(
          R.find(R.propEq('service_description', description)),
          R.propOr('Unknown Information', 'category'),
        )(this.observationList);
        return { ...billingLineItem, category };
      });
      const items = R.map((item) => ({
        ...item,
        billingLineItems: addCategory(R.propOr([], 'billingLineItems', item)),
      }))(R.propOr([], 'items', data));
      return { ...data, items };
    },
    startResize(e) {
      this.originalWidth = parseFloat(
        getComputedStyle(this.$refs.sidebar, null)
          .getPropertyValue('width')
          .replace('px', ''),
      );
      this.originalMouseX = e.pageX;
      e.preventDefault();
      window.addEventListener('mousemove', this.resize);
      window.addEventListener('mouseup', this.stopResize);
    },
    resize(e) {
      const width = this.originalWidth - (e.pageX - this.originalMouseX);
      if (width > this.minWidth && width < this.maxWidth) {
        this.$refs.sidebar.style.flexBasis = width + 'px';
      }
    },
    stopResize() {
      window.removeEventListener('mousemove', this.resize);
    },
  },
};
</script>

<style lang="scss">
@import '../../../../styles/constants.scss';

.bill-detail-page {
  height: 100%;

  * {
    box-sizing: border-box;
  }

  .bill-detail {
    &__container {
      display: flex;
      flex-direction: row;
      margin: 10px 20px;
      min-height: calc(100% - 80px);
      max-height: calc(100% - 80px);
    }

    &__header {
      display: flex;
      max-height: 60px;
      padding: 10px 20px;
      width: 100%;
      align-items: center;
      background-color: #ffffff;
    }

    &__content {
      flex-grow: 1;
      flex-basis: 0;
      max-height: calc(100% - 130px);
      overflow-y: auto;
      @extend %scrollbar;

      &--top {
        min-height: 116px;
        margin-bottom: 10px;
        background-color: #ffffff;
        box-shadow: 0 0 20px 0 rgba(225, 223, 223, 0.5);
        border-radius: 4px;
      }

      &--tabs {
        //background-color: #83c2c9;
        background-color: #ffffff;
        border-radius: 4px;
        min-height: calc(100% - 10px);
        box-shadow: 0 0 20px 0 rgba(225, 223, 223, 0.5);
      }
    }

    &__sidebar {
      position: relative;
      flex-basis: 0;
      margin: 0 10px;
      max-height: calc(100% - 130px);
      //background-color: #e8e4b5;
      background-color: #ffffff;
      transition: flex-basis 0.3s;
      border-radius: 4px;
      overflow-x: hidden;
      overflow-y: auto;
      @extend %scrollbar;

      .resize-area {
        position: absolute;
        left: -5px;
        height: 100%;
        width: 9px;
        //background-color: #7D89A6;
        z-index: 1;
        cursor: ew-resize;
      }

      &.is-visible {
        flex-basis: 540px;
        margin-right: 20px;
      }
    }

    &__sidebar-buttons {
      position: fixed;
      top: 90px;
      right: 0;
      width: 28px;
      min-height: 304px;
    }
  }
}
</style>
