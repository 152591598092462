// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/icons/send.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comment-input[data-v-281eed50]{overflow-y:auto}.comment-input[data-v-281eed50]::-webkit-scrollbar{width:4px}.comment-input[data-v-281eed50]::-webkit-scrollbar-track{background-color:hsla(0,0%,84.7%,.3)}.comment-input[data-v-281eed50]::-webkit-scrollbar-thumb{width:4px;border-radius:3px;background-color:#d8d8d8}.comment-input-container[data-v-281eed50]{position:relative}.comment-input[data-v-281eed50]{min-height:40px;max-height:61px;padding:10px 24px 10px;margin:0 -15px;font-size:12px;border-top:1px solid #dcdfe6;-webkit-box-sizing:border-box;box-sizing:border-box;cursor:text;outline:none}.comment-input[data-v-281eed50]:empty:before{display:block;color:#9b9b9b;font-weight:600;content:attr(placeholder)}.send-button[data-v-281eed50]{position:absolute;top:50%;right:-5px;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.icon-send[data-v-281eed50]{display:inline-block;width:14px;height:13px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}", ""]);
// Exports
module.exports = exports;
