<template>
  <div class="virtual-accounts">
    <bulk-selection
      :selection="selection"
      :groups="groups"
      :bulkActions="bulkActions"
      :items="'Virtual Accounts'"
      :totalResults="meta.total"
      @select-all="selectAll"
      @clear-all="clearAll"
    ></bulk-selection>
    <el-table
      :data="groups"
      @sort-change="sortTable"
      @selection-change="handleSelectionChange"
      ref="virtualAccountsTable"
      v-loading.body.lock="isFetching"
      row-key="id"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="frozenAt" width="50" sortable>
        <template slot="header">
          <inline-svg
            :src="require('../../../../assets/icons/freezeGray.svg')"
            width="100"
          />
        </template>
        <template slot-scope="scope" v-if="scope.row.frozenAt">
          <inline-svg
            :src="require('../../../../assets/icons/freezeBlue.svg')"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="ID"
        prop="id"
        width="80"
        sortable
      ></el-table-column>
      <el-table-column label="Vendor" prop="vendorCode" width="150" sortable>
        <template slot-scope="scope">
          <div>{{ scope.row.vendorCode }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="Billing ID"
        prop="clientAccount"
        width="120"
        sortable
      ></el-table-column>
      <el-table-column
        label="Service Account ID"
        prop="accountCode"
        width="170"
        sortable
      ></el-table-column>
      <el-table-column
        label="Meter ID"
        prop="meterSerial"
        sortable
        width="120"
      ></el-table-column>
      <el-table-column
        label="Utility"
        prop="commodity"
        width="120"
        sortable
      ></el-table-column>
      <el-table-column
        label="Type"
        prop="billType"
        width="120"
        sortable
      ></el-table-column>
      <el-table-column label="Last Bill" prop="lastBillId" width="140" sortable>
        <template slot-scope="scope"
          ><a
            class="last-bill"
            :href="`/customers/${$route.params.customerId}/bills/${scope.row.lastBillId}`"
            >{{ scope.row.lastBillStatementDate | datetime('LL/dd/yyyy') }}</a
          ></template
        >
      </el-table-column>
      <el-table-column
        label="Open/Closed"
        width="130"
        sortable
        :sort-method="sortBy('accountOpened', 'accountClosed')"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top" effect="light">
            <template slot="content">
              <span class="account-date-tooltip-label">Account Opened:</span>
              <br />
              <span
                v-if="scope.row.accountOpened"
                class="account-date-tooltip-value"
              >
                {{ scope.row.accountOpened | datetime('MM/dd/yyyy') }}
              </span>
              <span
                v-if="!scope.row.accountOpened"
                class="account-date-tooltip-value"
              >
                {{ 'Unknown' }}
              </span>
              <br />
              <span class="account-date-tooltip-label">Account Closed:</span>
              <br />
              <span class="account-date-tooltip-value">
                {{ scope.row.accountClosed | datetime('MM/dd/yyyy') }}
              </span>
              <span
                v-if="!scope.row.accountClosed"
                class="account-date-tooltip-value"
              >
                {{ 'Unknown' }}
              </span>
            </template>
            <el-row class="account-date-row">
              <el-col class="account-date-info" :span="16"
                ><span
                  v-if="
                    (scope.row.accountOpened &&
                      scope.row.accountClosed &&
                      new Date(scope.row.accountClosed).getTime() >
                        new Date().getTime() &&
                      new Date(scope.row.accountOpened).getTime() <
                        new Date().getTime()) ||
                    (scope.row.accountOpened &&
                      scope.row.accountClosed == null &&
                      new Date(scope.row.accountOpened).getTime() <
                        new Date().getTime()) ||
                    (scope.row.accountClosed &&
                      new Date(scope.row.accountClosed).getTime() >
                        new Date().getTime() &&
                      scope.row.accountOpened == null)
                  "
                  >Opened</span
                ><span
                  v-else-if="
                    (scope.row.accountClosed &&
                      new Date(scope.row.accountClosed).getTime() <
                        new Date().getTime()) ||
                    (new Date(scope.row.accountClosed).getTime() >
                      new Date().getTime() &&
                      scope.row.accountOpened == null)
                  "
                  >Closed</span
                ><span v-else>Unknown</span></el-col
              >
              <el-col :span="8"><i class="icon icon-info-circle"></i></el-col>
            </el-row>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="{ row }">
          <operator-notes :operator-notes="row.operatorNotes" />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.isChild"
            type="text"
            icon="el-icon-circle-plus-outline"
            @click="link(scope.row)"
            >Link</el-button
          >
          <el-button
            class="cancel-button"
            v-else
            type="text"
            icon="el-icon-circle-close"
            @click="link(scope.row)"
            >Unlink
          </el-button>
          <el-button
            type="text"
            @click="
              portingProps.edit(scope.row, +$route.params.customerId, +userId)
            "
          >
            <i class="icon icon-edit-filled"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="paginator-wrapper"
      background
      layout="prev, pager, next, -&gt;, sizes"
      :page-size="params.limit"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
      :total="meta.total"
    ></el-pagination>
    <el-dialog
      :visible.sync="linkModal"
      custom-class="link-accounts-dialog"
      top="45px"
      width="980px"
      title="Link Virtual Account"
    >
      <link-modal
        @close="closeLinkModal"
        :isVisible="linkModal"
        :selectedId="selectedId"
        :selectedItems="selectedItems"
        @handleSelectionChange="handleModalSelectionChange"
        @handleSubmit="openConfirmModal"
      ></link-modal>
    </el-dialog>
    <el-dialog
      :visible.sync="editModal"
      custom-class="edit-account-dialog"
      top="calc(50vh - 116px)"
      width="452px"
      title="Edit Virtual Account"
    >
      <edit-modal
        @close="closeEditModal"
        :isVisible="editModal"
        :row="row"
        ref="editModal"
        @handleEditSubmit="openConfirmEditModal"
      ></edit-modal>
    </el-dialog>
    <el-dialog
      class="confirm-editor-modal"
      :visible.sync="showEditConfirm"
      :show-close="false"
      top="calc(50vh - 62px)"
      width="360px"
      custom-class="va-confirm-modal"
    >
      <confirm-edit-modal
        @confirmEditSubmit="handleEditSubmit"
        @closeConfirmEditModal="closeConfirmEditModal"
        :isFetching="isFetching"
      ></confirm-edit-modal>
    </el-dialog>
    <el-dialog
      class="editor-modal"
      :visible.sync="showConfirm"
      :show-close="false"
      top="25px"
      width="360px"
      custom-class="va-confirm-modal"
    >
      <confirm-modal
        @confirmSubmit="handleSubmit"
        @closeConfirmModal="closeConfirmModal"
        :isFetching="isFetching"
      ></confirm-modal>
    </el-dialog>
    <remap-modal
      :isRemapOpen="isRemapVisible"
      @close="toggleRemapModalFalse()"
      :virtualAcc="virtualAcc"
    ></remap-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import LinkModal from './LinkModal';
import EditModal from './EditModal';
import ConfirmModal from './ConfirmModal';
import note from '@/assets/icons/note.svg';
import ConfirmEditModal from './ConfirmEditModal';
import RemapModal from './RemapModal';
import BulkSelection from '@/components/BulkSelection';
import OperatorNotes from '../../../../components/OperatorNotes/OperatorNotes';

export default {
  components: {
    OperatorNotes,
    LinkModal,
    EditModal,
    ConfirmModal,
    ConfirmEditModal,
    RemapModal,
    BulkSelection,
  },
  data() {
    return {
      note,
      row: null,
      editModal: false,
      linkModal: false,
      selectedId: 0,
      selectedItems: [],
      selectedItemsInPopup: [],
      showConfirm: false,
      showEditConfirm: false,

      isRemapVisible: false,
      virtualAcc: [],
      selection: [],
      bulkActions: [
        {
          label: 'Remap',
          action: () => {
            this.isRemapVisible = true;
          },
        },
      ],
    };
  },
  watch: {
    'portingProps.openEditVirtualAccount': {
      handler(val) {
        if (val) {
          this.$fetchGroups(this.$route.params);
        }
      },
    },
  },
  computed: {
    ...mapState({
      portingProps: (state) => state.porting.props,
    }),
    ...mapGetters({
      groups: 'virtual_accounts/groups',
      meta: 'virtual_accounts/meta',
      userId: 'user/userId',
      params: 'virtual_accounts/params',
      isFetching: 'virtual_accounts/isFetching',
    }),
    currentPage() {
      const { offset, limit } = this.params;
      return offset / limit + 1;
    },
  },
  async mounted() {
    await this.$fetchGroups(this.$route.params);
  },

  beforeDestroy() {
    this.$clearParams();
    this.clearSelection();
  },
  methods: {
    ...mapActions({
      $fetchGroups: 'virtual_accounts/fetchGroups',
      $changeParams: 'virtual_accounts/changeParams',
      $linkItems: 'virtual_accounts/linkItems',
      $editVirtualAccount: 'virtual_accounts/editVirtualAccount',
    }),
    ...mapMutations({
      $clearParams: 'virtual_accounts/clearParams',
    }),
    sortBy(key1, key2) {
      return (a, b) =>
        a[key1] + a[key2] > b[key1] + b[key2]
          ? 1
          : a[key1] + a[key2] < b[key1] + b[key2]
          ? -1
          : 0;
    },
    openConfirmEditModal() {
      this.editModal = false;
      this.showEditConfirm = true;
    },
    openConfirmModal() {
      this.linkModal = false;
      this.showConfirm = true;
    },
    closeConfirmEditModal() {
      this.showEditConfirm = false;
    },
    closeConfirmModal() {
      this.showConfirm = false;
    },
    async handleEditSubmit() {
      const body = {
        accountOpened: this.$refs.editModal.dateOpened,
        accountClosed: this.$refs.editModal.dateClosed,
      };
      await this.$editVirtualAccount({ virtual_account_id: this.row.id, body });
      this.row.accountOpened = body.accountOpened;
      this.row.accountClosed = body.accountClosed;
      this.closeConfirmEditModal();
    },
    async handleSubmit() {
      const body = {
        virtual_account_ids: this.selectedItemsInPopup.map(({ id }) =>
          parseInt(id),
        ),
      };
      await this.$linkItems({
        ...this.$route.params,
        va_group_id: this.selectedId,
        body,
      });
      await this.$fetchGroups(this.$route.params);
      this.closeLinkModal();
      this.closeConfirmModal();
    },
    async toggleRemapModalFalse() {
      this.isRemapVisible = false;
      await this.$fetchGroups(this.$route.params);
    },
    handleSelectionChange(virtualAcc) {
      this.virtualAcc = virtualAcc;
      this.setSelection(virtualAcc.map((el) => Number(el.id)));
    },
    handleModalSelectionChange(data = []) {
      this.selectedItemsInPopup = data;
    },
    setSelection(selection) {
      this.selection = selection;
    },
    clearSelection() {
      this.selection = [];
    },
    edit(row) {
      this.row = null;
      this.row = row;
      this.editModal = true;
    },
    link(row) {
      this.selectedItems = this.groups.find(
        (item) => item.virtualAccountGroupId === row.virtualAccountGroupId,
      ).children;
      this.selectedId = parseInt(row.virtualAccountGroupId);
      this.linkModal = true;
    },
    closeEditModal() {
      this.editModal = false;
    },
    closeLinkModal() {
      this.selectedId = 0;
      this.linkModal = false;
    },
    async changeOffset(offset) {
      const params = {
        ...this.params,
        offset: (offset - 1) * this.params.limit,
      };
      this.$changeParams(params);
      await this.$fetchGroups(this.$route.params);
    },
    async changeLimit(limit) {
      this.$changeParams({ ...this.params, limit, offset: 0 });
      await this.$fetchGroups(this.$route.params);
    },
    async sortTable(obj) {
      let order = '';
      if (obj.prop && obj.order) {
        order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      } else {
        order = '';
      }
      this.$changeParams({ ...this.params, orders: [order] });
      await this.$fetchGroups(this.$route.params);
    },
    async selectAll() {
      if (this.params.limit !== 10) {
        this.$changeParams({ ...this.params, limit: 10, offset: 0 });
        await this.$fetchGroups(this.$route.params);
      }
      this.$refs.virtualAccountsTable.clearSelection();
      this.$refs.virtualAccountsTable.toggleAllSelection();
    },
    clearAll() {
      this.$refs.virtualAccountsTable.clearSelection();
    },
  },
};
</script>

<style lang="scss">
.priority {
  filter: brightness(0) saturate(100%) invert(62%) sepia(7%) saturate(3562%)
    hue-rotate(312deg) brightness(89%) contrast(119%);
}
.link-accounts-dialog {
  .link-modal-content-header {
    margin-bottom: 20px;
  }

  .modal-dialog_actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 29px 0 10px;

    button {
      width: 100px;
    }

    button:first-child {
      margin-right: 10px;
    }
  }
}

.edit-account-dialog {
  .el-row {
    display: flex;

    .el-date-editor {
      width: auto;
    }

    .inputLabel {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .edit-modal-content-container {
    display: flex;
    flex-direction: column;
  }

  .link-modal-content-header {
    margin-bottom: 20px;
  }

  .modal-dialog_actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 29px 0 10px;

    button:first-child {
      margin-right: 10px;
    }
  }
}

.account-date-row {
  cursor: pointer;
  display: flex;

  .account-date-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

div.el-tooltip__popper[role='tooltip'] {
  min-width: 140px;
  border-radius: 8px;
  pointer-events: none;
  text-align: center;
  padding: 16px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

  &.is-light {
    margin-bottom: 6px;
    border-color: #e4e7ed;
  }

  .account-date-tooltip-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  .account-date-tooltip-value {
    font-size: 14px;
    line-height: 18px;
  }

  .popper__arrow {
    display: none;
  }
}

.el-dialog__wrapper {
  .link-accounts-dialog {
    border-radius: 10px;
  }
}

.virtual-accounts {
  .el-table {
    .cell {
      word-break: break-word;
      display: flex;
      align-items: center;
      height: 3rem;
    }

    .account-date-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  &-title {
    font-size: 20px;
    font-weight: 600;
    display: block;
  }
  &-table {
    max-height: 75%;
  }

  .el-table__indent {
    padding: 0 !important;
  }

  .el-table__row--level-1 {
    background-color: #efefef;
    box-shadow: inset 2px 0 6px 0 rgba(227, 208, 208, 0.5);
  }

  th.el-table_1_column_1 > .cell {
    padding-left: 10px;
  }

  .el-table__row--level-1 .el-table_1_column_1 > .cell {
    padding-left: 10px;
  }

  .el-table__row--level-1 {
    .el-table_1_column_1 {
      padding-left: 2px;
    }
  }

  .el-table__expand-icon {
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -10px;
  }
  .el-table__expand-icon--expanded {
    .el-icon-arrow-right {
      color: #3888e5;
    }
  }
  .cancel-button {
    color: #f86259;
  }
}

a.last-bill {
  color: #3888e5;
}

.va-confirm-modal {
  .el-dialog__header {
    padding: 10px;
  }

  .confirm-modal_container {
    .modal_content {
      text-align: center;
    }

    .modal_actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 16px;

      button {
        border-radius: 100px;
        height: 30px;
        padding-top: 0;
        padding-bottom: 0;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.header-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
