<template lang="pug">
  .editor-cell(:class='{"non-editable": columnIndex === 0 && validation !== "observationHeader", [`item-index-${columnIndex}-${cellIndex}`]: true }', @click="openPopup", ref="component")
    span.el-dropdown-link(:class='{active: popupActive}') {{ cell }}
    .el-popup-perspective
      transition(name="fade")
        .el-popup-wrapper(v-if="popupActive")
          .el-popup-arrow
          .el-popup-container

            .edit-dropdown(v-if='cellType === ""')
              .edit-dropdown_header
                | Uneditable Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                span.note The selected cell is not editable
              .edit-dropdown_footer
                el-button(type='primary', @click='closePopup') OK

            .edit-dropdown(v-else-if='cellType === "observationHeader"')
              .edit-dropdown_header
                | Edit Observation Header Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                .edit-dropdown_content-item(style='margin-bottom:0')
                  span.label.full-width Current Value (Format=Observation:UnitofMeasure:Description:ObsType):
                .edit-dropdown_content-item
                  span.value.full-width {{ cell }}
                .edit-dropdown_content-item
                  span.label New Observation:
                  span.value
                    el-select(v-model='newObservationValue.value')
                      el-option(v-for='option in observationTypes[observationHeaderType]', :key='option', :label='option', :value='option')
                .edit-dropdown_content-item
                  span.label New Description:
                  span.value
                    el-input(v-model='newObservationValue.description')
                .edit-dropdown_content-item
                  span.note Note: Unit of Measure and Observation Type are not editable
              .edit-dropdown_footer
                el-button.plain-button-reverse(plain, @click='closePopup') Cancel
                el-button(type='primary', @click='updateCell') Update Cell

            .edit-dropdown(v-else-if='cellType === "number"')
              .edit-dropdown_header
                | Edit Numeric Value Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                .edit-dropdown_content-item
                  span.label Current Value:
                  span.value {{ cell }}
                .edit-dropdown_content-item
                  span.label New value:
                  span.value
                    el-input(v-model='newValue', @keypress.native='validateNumberInput')
                .edit-dropdown_content-item
                  span.note Note: Enter only digits (0-9) or periods (.). All other characters including spaces are not allowed.
              .edit-dropdown_footer
                el-button.plain-button-reverse(plain, @click='closePopup') Cancel
                el-button(type='primary', @click='updateCell') Update Cell

            .edit-dropdown(v-else-if='cellType === "integer"')
              .edit-dropdown_header
                | Edit Numeric Value Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                .edit-dropdown_content-item
                  span.label Current Value:
                  span.value {{ cell }}
                .edit-dropdown_content-item
                  span.label New value:
                  span.value
                    el-input(v-model='newValue', @keypress.native='validateIntegerInput')
                .edit-dropdown_content-item
                  span.note Note: Enter only digits (0-9). All other characters including spaces are not allowed.
              .edit-dropdown_footer
                el-button.plain-button-reverse(plain, @click='closePopup') Cancel
                el-button(type='primary', @click='updateCell') Update Cell

            .edit-dropdown(v-else-if='cellType === "date"')
              .edit-dropdown_header
                | Edit Date Value Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                .edit-dropdown_content-item
                  span.label Current Value:
                  span.value {{ cell }}
                .edit-dropdown_content-item
                  span.label New Value:
                  span.value
                    el-date-picker(v-model='newValue',
                    type='date',
                    format='yyyyMMdd',
                    value-format='yyyyMMdd')
              .edit-dropdown_footer
                el-button.plain-button-reverse(plain, @click='closePopup') Cancel
                el-button(type='primary', @click='updateCell') Update Cell

            .edit-dropdown(v-else-if='cellType === "string" && cellName === "BILLTYPE"')
              .edit-dropdown_header
                | Edit Date Value Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                .edit-dropdown_content-item
                  span.label Current Value:
                  span.value {{ cell }}
                .edit-dropdown_content-item
                  span.label New Value:
                  span.value
                    el-select(v-model='newValue')
                      el-option(v-for='item in billTypes', :key='item', :label='item', :value='item')
              .edit-dropdown_footer
                el-button.plain-button-reverse(plain, @click='closePopup') Cancel
                el-button(type='primary', @click='updateCell') Update Cell

            .edit-dropdown(v-else-if='cellType === "string" && cellName === "COMMODITY"')
              .edit-dropdown_header
                | Edit Date Value Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                .edit-dropdown_content-item
                  span.label Current Value:
                  span.value {{ cell }}
                .edit-dropdown_content-item
                  span.label New Value:
                  span.value
                    el-select(v-model='newValue')
                      el-option(v-for='item in commodities', :key='item', :label='item', :value='item')
              .edit-dropdown_footer
                el-button.plain-button-reverse(plain, @click='closePopup') Cancel
                el-button(type='primary', @click='updateCell') Update Cell

            .edit-dropdown(v-else='cellType === "string"')
              .edit-dropdown_header
                | Edit Text Value Cell
                button.close(@click='closePopup')
                  i.el-icon-close
              .edit-dropdown_content
                .edit-dropdown_content-item
                  span.label Current Value:
                  span.value(:title='cell') {{ cell }}
                .edit-dropdown_content-item
                  span.label New value:
                  span.value
                    el-input(v-model='newValue', @keypress.native='validateStringInput')
                .edit-dropdown_content-item
                  span.note Note: Commas, colons and non-alphanumeric characters are not allowed.
              .edit-dropdown_footer
                el-button.plain-button-reverse(plain, @click='closePopup') Cancel
                el-button(type='primary', @click='updateCell') Update Cell

</template>

<script>
import { test, contains, split, slice, join } from 'ramda';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  props: [
    'cell',
    'pane-index',
    'column-index',
    'cell-index',
    'validation',
    'cellName',
  ],
  data() {
    return {
      popupActive: false,
      newValue: this.cell,
      cellType: '',
      observationString: [],
      newObservationValue: {
        value: '',
        description: '',
      },
      observationHeaderType: '',
      billTypes: ['Full Service', 'Supply Only', 'Distribution Only'],
    };
  },
  mounted() {
    this.checkCell();
    this.checkObservationCell();
    document.body.addEventListener('click', this.handleBodyClick);
  },
  computed: {
    ...mapGetters({
      getResourceByName: 'resources/getResourceByName',
    }),
    commodities: {
      get() {
        return _.get(this.getResourceByName('commodities'), 'value', []).map(
          (i) => i.title,
        );
      },
    },
    observationTypes: {
      get() {
        return _.get(this.getResourceByName('observations'), 'value', {
          U_TYPES: [],
          C_TYPES: [],
        });
      },
    },
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleBodyClick);
  },
  methods: {
    handleBodyClick(e) {
      if (
        !e.target.closest(
          `.editor-cell.item-index-${this.columnIndex}-${this.cellIndex}`,
        ) &&
        this.popupActive
      ) {
        this.closePopup(e);
      }
    },
    checkCell() {
      if (
        this.columnIndex !== 0 &&
        (this.validation === 'number' ||
          this.validation === 'observationHeader')
      ) {
        this.cellType = 'number';
      }
      if (this.columnIndex !== 0 && this.validation === 'date') {
        this.cellType = 'date';
      }
      if (this.columnIndex !== 0 && this.validation === 'string') {
        this.cellType = 'string';
      }
      if (this.columnIndex !== 0 && this.validation === 'integer') {
        this.cellType = 'integer';
      }
      if (this.columnIndex === 0 && this.validation === 'observationHeader') {
        this.cellType = 'observationHeader';
        this.parseObservationValue();
      }
    },
    checkObservationCell() {
      if (
        this.columnIndex === 0 &&
        this.cellType === 'observationHeader' &&
        contains(':UC', slice(-3, Infinity, this.newValue)) &&
        contains('CHG_', this.newValue)
      ) {
        this.observationHeaderType = 'C_TYPES';
      } else if (
        this.cellType === 'observationHeader' &&
        contains(':C', slice(-3, Infinity, this.newValue))
      ) {
        this.observationHeaderType = 'C_TYPES';
      } else {
        this.observationHeaderType = 'U_TYPES';
      }
    },
    updateCell(e) {
      if (this.cellType === 'observationHeader') {
        this.unparseObservationValue();
      }

      let updatedValue = {
        value: this.newValue.replace(/\s*$/, ''),
        paneIndex: this.paneIndex,
        columnIndex: this.columnIndex,
        cellIndex: this.cellIndex,
      };

      if (this.newValue !== this.cell) {
        this.$emit('update-cell', updatedValue);
      }

      this.closePopup(e);
    },
    parseObservationValue() {
      this.observationString = split(':', this.cell);
      this.newObservationValue = {
        value: this.observationString[0],
        description: this.observationString[2],
      };
    },
    unparseObservationValue() {
      this.observationString[0] = this.newObservationValue.value;
      this.observationString[2] = this.newObservationValue.description;
      this.newValue = join(':', this.observationString);
    },
    validateStringInput(e) {
      let regExp = /[a-z0-9]|\s|\./i;

      if (!test(regExp, e.key)) {
        e.preventDefault();
      }
    },
    validateNumberInput(e) {
      let regExp = /\d|\./;

      if (!test(regExp, e.key)) {
        e.preventDefault();
      }
    },
    validateIntegerInput(e) {
      let regExp = /\d/;

      if (!test(regExp, e.key)) {
        e.preventDefault();
      }
    },
    openPopup() {
      this.popupActive = true;
    },
    closePopup(e) {
      e.preventDefault();
      e.stopPropagation();
      this.popupActive = false;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
// [Konsta] need to refactor CSS and HTML

.editor-cell {
  display: flex;
  align-items: center;
  min-height: 26px;
  font-size: 12px;
  line-height: 1;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
  position: relative;

  .el-popup-perspective {
    position: absolute;
    right: 0;
    perspective: 2000px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &:last-child {
    border-bottom: 0;
  }
  &.non-editable {
    border-bottom-color: #fbccc9;
  }

  > span {
    &.active {
      box-shadow: 0 0 0 1px #2893f9;
    }
  }

  .el-popup-wrapper {
    position: absolute;
    left: calc(100% - 6px);
    z-index: 9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .el-popup-arrow {
      margin-left: 19px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 1px;
        width: 0;
        height: 0;
        border-style: solid;
      }
      &::after {
        border-color: transparent #fff transparent transparent;
        border-width: 10px;
      }

      &::before {
        left: -2px;
        border-color: transparent #e4e7ed transparent transparent;
        border-width: 11px;
      }
    }

    .el-popup-container {
      background-color: #fff;
      padding: 0 40px;
      border: 1px solid #e4e7ed;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    }
  }
}
.edit-dropdown {
  line-height: 1;

  &_header {
    padding-top: 20px;
    color: #273a58;
    font-weight: 600;
  }
  &_content {
    padding: 20px 0;

    &-item {
      display: flex;
      align-items: center;
      min-height: 28px;
      margin-bottom: 10px;
      color: #222222;
      font-size: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &_footer {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    .el-button {
      width: 120px;
      padding: 6px 0;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
  .label:not(.full-width) {
    width: 30%;
    padding-right: 15px;
  }
  .value {
    color: #267de2;
    font-weight: 600;
    word-break: break-all;

    .el-select {
      width: 100%;
    }
    &:not(.full-width) {
      width: 70%;
    }
  }
  .note {
    color: #f86259;
    font-size: 12px;
    line-height: 17px;
  }
}
.close {
  position: absolute;
  top: 20px;
  right: 28px;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: rotateX(-45deg);
}
</style>

<style lang="scss">
.editor-cell {
  .el-dropdown-link {
    display: block;
    width: 100%;
    min-height: 22px;
    padding: 2px 10px;
    line-height: 1.5;
    box-sizing: border-box;
  }
}
.editor-cell-dropdown {
  padding: 0;

  &.is-error {
    width: 240px;
  }
  &.is-string {
    width: 360px;
  }
  &.is-number {
    width: 370px;
  }
  &.is-observationHeader {
    width: 480px;
  }

  .el-dropdown-menu__item {
    position: relative;
    padding: 0 30px;
  }
  .el-dropdown-menu__item {
    &:focus,
    &:hover {
      color: inherit;
      background-color: transparent;
      cursor: default;
    }
  }
}
.edit-dropdown {
  &_content {
    input {
      height: 28px;
      padding: 0 10px;
      color: #4a4a4a;
      font-size: 12px;
    }
    .el-input__icon {
      line-height: 28px;
    }
  }
}
</style>
