const reviewStatuses = [
  'integrity_check',
  'data_verification_1',
  'data_verification_2',
];
const errorStatuses = ['data_audit_1', 'data_audit_2'];

export default {
  reviewStatuses,
  errorStatuses,
};
