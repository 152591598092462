const env = require('../../config/env').default;
const hosts = {
  api: env.api.host.replace(/"/g, ''),
  v1: env.v1.host.replace(/"/g, ''),
  v2: env.v2.host.replace(/"/g, ''),
  v3: env.v3.host.replace(/"/g, ''),
  static: env.api.static.replace(/"/g, ''),
};

export { hosts };
