import { hosts } from '@/lib/api.configs';
import { http } from '@/lib';
import { dataMiddleware } from '@/lib/http';
import qs from 'qs';

const getPaymentFileByBillId = ({ customerId, billId }) =>
  http(hosts.v3)
    .get(`payments/${customerId}/for-bill/${billId}`)
    .then(dataMiddleware);

const postRegenPaymentFile = (customerId, markedForPaymentDate) => {
  return http(hosts.v3).post(
    `/payments/generate/${customerId}/${markedForPaymentDate}`,
  );
};

const getSSHKey = () =>
  http(hosts.v3).get(`/sftp/sftp-public-key`).then(dataMiddleware);

const testSFTPConnection = (host, folder, username, password, connectionId) => {
  const body = {};
  if (host) {
    body.host = host;
  }
  if (folder) {
    body.folder = folder;
  }
  if (username) {
    body.username = username;
  }
  if (password) {
    body.password = password;
  }
  if (connectionId) {
    body.connectionId = connectionId;
  }
  return http(hosts.v3).post(`/sftp/test-sftp`, body).then(dataMiddleware);
};

const list = (params) => {
  return http(hosts.v3)
    .get(
      `/payments/events?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
    )
    .then(dataMiddleware);
};

export {
  getPaymentFileByBillId,
  postRegenPaymentFile,
  getSSHKey,
  testSFTPConnection,
  list,
};
