<template>
  <div class="tag-select" v-if="selected" :class="{ disabled: disabled }">
    <el-tag
      :class="{ 'ml-2': true, 'default-value': !selected.isDefault }"
      type="info"
      @click="onClick"
    >
      {{ selected.name }} <i class="el-icon-arrow-down"
    /></el-tag>
    <el-select @change="onChange" ref="selectRef" v-model="selected">
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'TagSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    initialValue: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.selected = this.options.filter((currentOption) => {
          return this.initialValue !== null
            ? currentOption.id === this.initialValue
            : currentOption.isDefault;
        })[0];
      },
      immediate: true,
    },
  },
  methods: {
    onClick() {
      this.$refs.selectRef.toggleMenu();
    },
    onChange() {
      this.$emit('updatedValue', this.selected);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.tag-select {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  &.disabled {
    pointer-events: none;
    color: #606266;
    font-weight: 400;
  }
  .el-select {
    visibility: hidden;
    max-height: 0px;
    max-width: 180px;

    input {
      max-height: 0px;
      max-width: 180px;
    }
  }

  .el-tag {
    height: 18px;
    font-size: 10px;
    line-height: 18px;
    border-style: none;
    max-width: 180px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;

    &.default-value {
      background-color: rgba(0, 128, 255, 0.12);
    }

    &:hover {
      outline: 1px solid #e9e9eb;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &.default-value {
        background-color: rgba(0, 128, 255, 0.12);
      }

      .el-icon-arrow-down {
        display: initial;
        margin-left: auto;
      }
    }

    .el-icon-arrow-down {
      display: none;
    }
  }
}
</style>
