<template lang="pug">
.sidebar-container
  .sidebar-item.sidebar-item__errors(:class="{active: sidebar === 'isErrorsVisible'}")
    errors-bar(
      v-if="bill.errors.length && billValidations.length"
      :errorsList="bill.errors",
      :billId="bill.id",
      :navigation="bill.navigation",
      :billValidations="billValidations"
      @loadBill="loadBill",
      :userId="bill.userId",
      :workflowState="bill.workflowState")
  .sidebar-item.sidebar-item__logs(v-if="sidebar === 'isLogsVisible'" :class="{active: sidebar === 'isLogsVisible'}")
    logs-bar
  .sidebar-item.sidebar-item__pdf-bill(:class="{active: sidebar === 'isPdfBillVisible'}")
    bill-pdf-side-bar(v-if="sidebar === 'isPdfBillVisible' || isPdfWasOpened" @pdfRendered="pdfWasRendered")

</template>

<script>
import { mapGetters } from 'vuex';
import ErrorsBar from './Errors';
import LogsBar from './Logs/Logs';
import BillPdfSideBar from './BillPdfSideBar';

export default {
  components: {
    ErrorsBar,
    LogsBar,
    BillPdfSideBar,
  },
  props: {
    sidebar: {
      type: String,
      default: 'isErrorsVisible',
    },
    billValidations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isPdfWasOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      bill: 'bills/bill',
    }),
  },
  methods: {
    async loadBill() {
      await this.$emit('loadBill');
    },
    pdfWasRendered() {
      this.isPdfWasOpened = true;
    },
  },
};
</script>

<style lang="scss">
@import '../../../../../styles/constants.scss';

.sidebar-container {
  padding: 0;
  min-width: 540px;
  height: 100%;
  box-sizing: border-box;

  .sidebar-item {
    display: none;

    &.active {
      display: flex;
    }

    &__pdf-bill {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
    }

    &__errors {
      max-height: 100%;
      height: 100%;
    }
  }
}
</style>
