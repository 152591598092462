import axios from 'axios';

import { hosts } from './api.configs';
import store from '../store';

const statusMiddleware = (status) => {
  // status === 401 && Events.$emit('invalid.token');
  if (status === 401) {
    store.dispatch('user/logout');
    window.location.href = '/auth/login';
  }
  return status >= 200 && status < 300;
};

const http = (
  baseURL = hosts.api,
  headers = {
    Authorization: localStorage.getItem('authToken')
      ? `Bearer ${localStorage.getItem('authToken')}`
      : '',
  },
) =>
  axios.create({
    baseURL,
    headers,
    validateStatus: statusMiddleware,
  });

export const dataMiddleware = (response) => response.data;

export default http;
