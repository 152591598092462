<template>
  <div class="block" v-bind:key="vendor.id">
    <el-row class="block_first-row">
      <el-col :span="8">
        <div class="block_key">Provider:</div>
        <div class="block_value">{{ vendor.provider || 'N/A' }}</div>
      </el-col>
      <el-col :span="8">
        <div class="block_key">Provider ID:</div>
        <div class="block_value">{{ vendor.providerId || 'N/A' }}</div>
      </el-col>
      <el-col :span="8">
        <div class="block_key">
          <div class="icon icon-earth"></div>
        </div>
        <div class="block_value">
          <a
            v-if="vendor.webAddress"
            :href="`//${vendor.webAddress}`"
            target="_blank"
          >
            {{ vendor.webAddress }}
          </a>
          <span v-else>{{ 'N/A' }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="block_key">Vendor Name</div>
        <div class="block_value">{{ vendor.name || 'N/A' }}</div>
      </el-col>
      <el-col :span="8">
        <div class="block_key">Vendor Code</div>
        <div class="block_value">{{ vendor.code || 'N/A' }}</div>
      </el-col>
      <el-col :span="8">
        <div class="block_key">Remittance Name</div>
        <div class="block_value">
          {{ vendor.remittanceName || 'N/A' }}
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="block_key">Main Phone</div>
        <div class="block_value">{{ vendor.mainPhone || 'N/A' }}</div>
      </el-col>
      <el-col :span="8">
        <div class="block_key">Customer Service Phone</div>
        <div class="block_value">
          {{ vendor.customerServicePhone || 'N/A' }}
        </div>
      </el-col>
      <el-col :span="8">
        <div class="block_key">Remittance Address</div>
        <div class="block_value">
          {{ remittanceAddress(vendor.remittanceAddress) }}
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="block_key">Priority Phone</div>
        <div class="block_value">
          {{ vendor.priorityPhone || 'N/A' }}
        </div>
      </el-col>
      <el-col :span="8">
        <div class="block_key">Emergency Phone</div>
        <div class="block_value">
          {{ vendor.emergencyPhone || 'N/A' }}
        </div>
      </el-col>
    </el-row>
    <el-row v-if="customerId" class="attributes-container">
      <el-col :span="24">
        <CustomAttributesContainer
          :initialAttributes="vendor.customVendorAttributes"
          :providerVendorId="vendor.id"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CustomAttributesContainer from '../../Customers/Vendors/components/CustomAttributes/CustomAttributesContainer.vue'

export default {
  props: {
    vendor: Object,
    index: Number,
    customerId: String,
  },
  components: {
    CustomAttributesContainer,
  },
  computed: {
    remittanceAddress() {
      return address => {
        if (!address) {
          return 'N/A'
        }
        const props = [
          'line1',
          'line2',
          'line3',
          'line4',
          'city',
          'state',
          'postCode',
        ]
        // filter address that only contain values from keys defined above
        // const remittanceAddress = this.vendor.remittanceAddress
        let parsedAddress = ''
        for (const [key, value] of Object.entries(address)) {
          if (value && props.includes(key)) {
            // join results in a joined string with commas
            parsedAddress += `${value}, `
          }
        }
        // get rid of last 2 characters: comma and empty char
        parsedAddress = parsedAddress.slice(0, -2)
        return parsedAddress
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../style/vendors.scss';
.icon-earth {
  margin-right: 7px;
}

.attributes-container {
  border-top: 1px solid #e5e4e4;
}

a {
  color: #2893f9;
}

.el-col {
  padding: 20px 25px;
}
</style>
