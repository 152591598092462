<template lang="pug">
  .select-bill.control
    el-dialog.select-bill-modal(title="Create Bill Blocks from Existing Bill",
      custom-class="select-bill-items",
      :visible="isVisible",
      :append-to-body="true",
      :close-on-click-modal="false",
      top="40px",
      width="740px",
      @close="close")
      .select-bill_body
        .search-block
          span.search-block_title Bill ID:
          span.search-block_input
            el-input(v-model="query", size="small")
              i(slot="prefix", class="el-input__icon el-icon-search")
          span.search-block_action(@click="search") search
        template(v-if="isNotEmpty")
          .search-info-block Select bill blocks for {{ getValue(bill, 'REMITNAME') }} Bill {{ billId }} (Billing ID: {{ getValue(bill, 'CLIENTACCOUNT') }})
          el-table(:data="items", style="width: 100%", v-loading="loading")
            el-table-column(label="Service Account", width="200")
              template(slot-scope='scope')
                el-checkbox(v-model="selected[scope.$index]")
                label {{ getValue(scope.row, 'ACCOUNTCODE') }}
            el-table-column(label="Meter ID")
              template(slot-scope='scope')
                label {{ getValue(scope.row, 'METERSERIAL') }}
            el-table-column(label="Service Period")
              template(slot-scope='scope')
                label {{ getValue(scope.row, 'DAYSOFSERVICE') }}
            el-table-column(label="Bill Type")
              template(slot-scope='scope')
                label {{ getValue(scope.row, 'BILLTYPE') }}
            el-table-column(label="Subtotal")
              template(slot-scope='scope')
                label {{ getSubtotal(scope.row) | unit('USD') }}
          .action-block
            el-radio(v-model="radio", label="0") Copy identifiers only
            el-radio(v-model="radio", label="1") Copy identifiers and billing line item headers
            el-radio(v-model="radio", label="2") Create cancellation bill blocks
      .select-bill_actions
        el-button(type="primary", size="small", round, @click="create", :disabled="selectedQty === 0")
          | Create {{ selectedQty > 0 ? selectedQty: ''}} Bill Block{{ selectedQty > 1 ? 's': ''}}
</template>

<script>
import { bills } from '@/api';
import * as R from 'ramda';
import { DateTime } from 'luxon';

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    id: {
      type: String,
      default: '',
    },
    defaultBill: {
      type: Function,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      query: '',
      items: [],
      selected: [],
      loading: false,
      radio: '0',
      billId: '',
      bill: [],
    };
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.init();
      }
    },
  },
  computed: {
    selectedQty() {
      return this.selected.filter((i) => i).length;
    },
    isNotEmpty() {
      return (this.items || []).length > 0;
    },
  },
  methods: {
    init() {
      this.bill = this.defaultBill();
      this.items = this.getItemsObservation(this.bill);
      this.selected = this.items.map(() => false);
      this.billId = this.id;
    },
    reset() {
      this.items = [];
      this.selected = this.items.map(() => false);
    },
    create() {
      const selected = this.items.filter((el, index) => this.selected[index]);
      this.$emit('onCreate', this.getBillBlocks(selected));
    },
    close() {
      this.$emit('onClose');
      this.selected = [];
    },
    search() {
      this.query = R.trim(R.pathOr('', ['query'], this));
      this.loadBill();
    },
    loadBill() {
      if (this.query === '') {
        return;
      }
      this.billId = this.query;
      const { customerId } = this.$route.params;
      const billId = this.query;
      this.isLoading = true;
      this.reset();
      bills
        .getBillObservation(customerId, billId)
        .then((response) => {
          this.items = this.sortBillBlocks(
            this.getItemsObservation(response.data),
          );
          this.bill = response.data;
        })
        .catch(console.error.bind(console))
        .finally(() => {
          this.isLoading = false;
        });
    },
    getItemsObservation(observations = []) {
      return R.pipe(
        R.filter(R.has('block')),
        R.groupBy(R.prop('block')),
        R.values,
      )(observations);
    },
    getValue(observations, code) {
      return R.pipe(
        R.find(R.propEq('code', code)),
        R.prop('value'),
      )(observations);
    },
    getSubtotal(data = []) {
      const total = (acc, value) => acc + +R.propOr(0, 'charge', value);
      return R.reduce(total, 0)(data);
    },
    getBillBlocks(items) {
      return items.map((item) => {
        let newItem = [];
        switch (this.radio) {
          case '0':
            newItem = this.identifiersOnly(item);
            break;
          case '1':
            newItem = this.identifiersAndBillingLineItem(item);
            break;
          case '2':
            newItem = this.cancellationBillBlocks(item);
            break;
        }
        return newItem;
      });
    },
    identifiersOnly(item) {
      return R.filter(R.pipe(R.has('type'), R.not))(item);
    },
    identifiersAndBillingLineItem(item) {
      return R.map((o) => {
        if (o.type) {
          if (o.charge) {
            o.charge = '';
          }
          if (o.usage) {
            o.usage = '';
          }
        }
        return o;
      })(item);
    },
    cancellationBillBlocks(item) {
      return R.map((o) => {
        if (o.type) {
          if (o.charge) {
            o.charge = -o.charge;
          }
          if (o.usage) {
            o.usage = -o.usage;
          }
        }
        return o;
      })(item);
    },
    sortBillBlocks(items) {
      const valueByCode = (code) =>
        R.pipe(R.find(R.propEq('code', code)), R.pathOr('', ['value']));

      const firstSort = (item) => {
        const commodity = valueByCode('COMMODITY')(item);
        return R.cond([
          [R.equals('ELECTRIC'), () => 1],
          [R.equals('NATURALGAS'), () => 2],
          [R.T, () => 3],
        ])(commodity);
      };

      const endDateSort = (item) => {
        const date = valueByCode('ENDDATE')(item);
        return DateTime.fromFormat(date, 'yyyyLLdd').valueOf();
      };

      return R.sortWith([
        R.ascend(firstSort),
        R.ascend(valueByCode('COMMODITY')),
        R.ascend(valueByCode('ACCOUNTCODE')),
        R.ascend(valueByCode('METERSERIAL')),
        R.descend(endDateSort),
      ])(items);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-bill_actions {
  padding-top: 20px;
  text-align: center;

  .el-button {
    width: 160px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 900;
  }
}
</style>

<style lang="scss">
.select-bill-modal {
  .action-block {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .el-radio {
      margin: 7px 0;
    }
  }

  .el-dialog__header {
    padding: 40px 15px 0 40px;
  }

  .el-dialog__title {
    color: #273a58;
    font-size: 24px;
    font-weight: 700;
  }

  .el-dialog__headerbtn {
    top: 42px;
    right: 38px;
  }

  .el-dialog__body {
    padding: 20px 40px;
  }

  .el-tabs__header {
    padding: 0 25px;
    margin-bottom: 20px;
  }

  .el-tabs__item {
    font-weight: 900;
  }

  .search-block {
    display: flex;
    align-items: center;

    &_title {
      font-size: 18px;
      font-weight: 900;
      line-height: 25px;
    }

    &_input {
      margin: 0 10px;
    }

    &_action {
      cursor: pointer;
      font-size: 14px;
      line-height: 19px;
      color: #4a90e2;
      text-decoration: underline;
    }
  }

  .search-info-block {
    margin: 15px 0;
    font-size: 16px;
    line-height: 24px;
    color: #273a58;
  }
}
</style>
