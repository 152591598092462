<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name"
    :immediate="immediate"
    :rules="rules"
    v-slot="{ flags, errors }"
  >
    <el-form-item
      :error="isEditMode ? errors[0] : ''"
      :label="$attrs.label"
      :label-width="labelWidth"
    >
      <slot :flags="flags" />
    </el-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__error {
  padding-left: 14px;
  position: static;
  word-break: break-word;
}
/deep/ .el-form-item__label {
  line-height: normal;
  font-size: 14px;
  word-break: break-word;
}

/deep/ .el-form-item__content {
  line-height: normal;
}
</style>
