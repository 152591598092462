<template>
  <base-modal
    width="480px"
    @open="open"
    @close="close"
    :dialogVisible="showCarbonFootprintModal"
    :title="'Configure Carbon Footprint'"
  >
    <template #content>
      <el-form class="form" label-position="left" label-width="100px">
        <el-form-item label="Use Custom Report" label-width="150px">
          <el-checkbox v-model="carbonFootprintObj.customReportEnabled" />
        </el-form-item>
        <el-form-item label="Group ID:">
          <el-input
            v-model="carbonFootprintObj.groupId"
            :disabled="!carbonFootprintObj.customReportEnabled"
            size="small"
          />
        </el-form-item>
        <el-form-item label="Report ID:">
          <el-input
            v-model="carbonFootprintObj.reportId"
            :disabled="!carbonFootprintObj.customReportEnabled"
            size="small"
          />
        </el-form-item>
        <el-form-item label="Dataset ID:">
          <el-input
            v-model="carbonFootprintObj.datasetId"
            :disabled="!carbonFootprintObj.customReportEnabled"
            size="small"
          />
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <el-button class="button-size plain-button-reverse" @click="close"
        >Cancel</el-button
      >
      <el-button
        class="button-size"
        type="primary"
        @click="createOrUpdateCarbonFootprint"
        >Save</el-button
      >
    </template>
  </base-modal>
</template>

<script>
import { customers } from '@/api';
import BaseModal from '../../../components/BaseModal';

export default {
  components: { BaseModal },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carbonFootprintObj: {
        customReportEnabled: false,
        groupId: '',
        reportId: '',
        datasetId: '',
      },
      hasCarbonFootprint: true,
      showCarbonFootprintModal: false,
    };
  },
  watch: {
    dialogVisible(val) {
      this.showCarbonFootprintModal = val;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    open() {
      this.fetchCarbonFootprintData();
    },
    async fetchCarbonFootprintData() {
      try {
        const { data } = await customers.getCarbonFootprint(
          this.$route.params.customerId,
          this.carbonFootprintObj,
        );
        this.carbonFootprintObj = data;
        this.hasCarbonFootprint = true;
      } catch (e) {
        console.log(e);
        this.hasCarbonFootprint = false;
      }
    },
    async createOrUpdateCarbonFootprint() {
      if (this.hasCarbonFootprint) {
        await this.putCarbonFootprintData();
      } else {
        await this.postCarbonFootprintData();
      }
      this.close();
    },
    async postCarbonFootprintData() {
      try {
        const { data } = await customers.postCarbonFootprint(
          this.$route.params.customerId,
          this.carbonFootprintObj,
        );
        this.carbonFootprintObj = data;
      } catch (e) {
        console.log(e);
      }
    },
    async putCarbonFootprintData() {
      try {
        const { data } = await customers.putCarbonFootprint(
          this.$route.params.customerId,
          this.carbonFootprintObj,
        );
        this.carbonFootprintObj = data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 0;
}
.button-size {
  width: 170px;
}

.form {
  width: 300px;
}
</style>
