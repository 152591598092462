<template>
  <div id="app" class="vue-2-main-app">
    <router-view></router-view>
    <el-dialog
      class="change-password-modal"
      :visible.sync="isChangePasswordVisible"
      top="25px"
      width="500px"
    >
      <change-password
        @ok="isChangePasswordVisible = false"
        @cancel="isChangePasswordVisible = false"
        :isVisible="isChangePasswordVisible"
        :user="user"
      ></change-password>
    </el-dialog>
  </div>
</template>

<script>
import { user } from '@/api'
import { Events } from '@/lib'
import { parseJwt } from '@/lib/helpers'
import { customMessages } from '@/lib/utils'
import { Validator } from 'vee-validate'
import { mapState, mapActions } from 'vuex'
import ChangePassword from '@/components/Controls/ChangePassword'

Validator.localize('en', customMessages)

export default {
  name: 'App',
  props: ['singleSpaData'],
  components: {
    ChangePassword,
  },
  data() {
    return {
      isChangePasswordVisible: false,
    }
  },
  methods: {
    ...mapActions({
      $callStoreMethod: 'porting/callStoreMethod',
    }),
  },
  mounted() {
    const authToken = localStorage.getItem('authToken')
    const jwt = authToken ? parseJwt(authToken) : ''
    if (!jwt && this.$router.currentRoute.fullPath !== '/auth/login') {
      return this.$store.dispatch('user/logout')
    }
    Events.$on('not.found', () => {
      this.$router.history.push(this.$router.match('/error/404'))
    })
    this.singleSpaData.vue2Router = this.$router
    this.$store.commit(
      'porting/setProps',
      Object.assign(this.singleSpaData, {
        callStoreMethod: ({ methodName, moduleName, data }) =>
          this.$callStoreMethod({ methodName, moduleName, data }),
        showChangePasswordModal: () => {
          this.isChangePasswordVisible = true
        },
      }),
    )
    this.$store.dispatch('user/autoRefreshToken')
    this.$store.getters['user/isAuth'] &&
      user
        .getMe()
        .then((data) => {
          this.$store.dispatch('user/setUser', data)
        })
        .catch((e) => {
          if (e.response.status !== 401) {
            location.href = 'auth/login'
            this.$store.dispatch('user/logout')
          }
        })
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
}
</script>

<style lang="scss">
@import './styles/main.scss';
@import './styles/constants.scss';

#app {
  height: 100vh;
}
</style>
