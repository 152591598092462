<template lang="pug">
el-dialog(class="modal-upload-dialog-submit",
          :title="title",
          width="746px",
          :visible.sync="showConfirmUploadModal",
          @close="close")
  .modal-validate-wrapper
    p Upload a CSV file containing locations for <b>{{ customerName }}</b>. It is important that the CSV Location file conform to the naming conventions specified to prevent errors from occurring.
    .counter-container(data-element="locations-counter")
      .counter-item
        span Qty of locations to create:
        span.value(data-element="locations-counter-created") {{ validations.createCount || 0 }}
      .counter-item
        span Qty of locations to update:
        span.value(data-element="locations-counter-updated") {{ validations.updateCount || 0 }}
      .counter-item
        span Qty of locations to geocode:
        span.value(data-element="geocode-counter-updated") {{ validations.geocodeCount || 0 }}
    .table-wrapper
      el-table.with-shadow(:data="validations.changes",
      data-type="locations-upload-list",
      width="100%")
        el-table-column(label='Site Name', prop='name')
        el-table-column(label='Site Address', prop='address')
        el-table-column(label='Actions', prop='action')
        el-table-column(label='Geocode', prop='shouldGeocode')
          template(slot-scope="scope")
            span {{ scope.row.shouldGeocode ? 'Yes' : 'No' }}
    .buttons-container
      el-button.plain-button-reverse(@click="close") Cancel
      el-button(type="primary", @click="submitUpload", :loading="isUploadFetching", data-action="locations-csv-submit-upload") Confirm Upload
</template>

<script>
import { locations } from '@/api';
import { mapGetters } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    validations: {
      type: Object,
      required: true,
    },
    fileData: {
      type: Array,
      required: true,
    },
  },
  watch: {
    visible(val) {
      this.showConfirmUploadModal = val;
    },
  },
  data() {
    return {
      showConfirmUploadModal: false,
      isUploadFetching: false,
      weatherUpdated: false,
      fData: this.fileData,
    };
  },
  computed: {
    ...mapGetters({
      customerName: 'customers/name',
    }),
  },
  methods: {
    async submitUpload() {
      try {
        this.fData = this.fileData;
        this.isUploadFetching = true;
        const data = await locations.uploadLocationsCsv(
          this.$route.params.customerId,
          { locations: this.fData },
        );
        this.$emit('success', data);
        this.close();
      } catch (e) {
        let errorMsg;
        if (e.response.data.statusCode === 409) {
          errorMsg = e.response.data.error;
        } else {
          errorMsg = 'There was a problem when uploading the file.';
        }
        this.uploadError = true;
        this.showConfirmUploadModal = false;
        this.showUploadModal = true;
        this.$emit('error', errorMsg);
        this.close();
      } finally {
        this.isUploadFetching = false;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
