<template lang="pug">
  el-breadcrumb.breadcrumb(separator-class="el-icon-arrow-right")
    el-breadcrumb-item.breadcrumb-item(v-for="breadcrumb of links", :key="breadcrumb.title", :to="breadcrumb.route") {{ breadcrumb.title }}
</template>

<script>
import { template } from 'lodash';
import * as R from 'ramda';

export default {
  props: {
    route: {
      type: Object,
    },
  },
  computed: {
    links() {
      const links = [];
      this.route.matched.forEach(({ meta: { breadcrumb } }) => {
        if (!breadcrumb) return null;

        const stateValues = R.mapObjIndexed(
          (val) => this.$store.getters[val],
          breadcrumb.storeMappings,
        );
        if (breadcrumb.titleTemplate) {
          const link = {
            title: template(breadcrumb.titleTemplate)(stateValues),
            route: template(breadcrumb.linkTemplate)(stateValues),
          };
          document.title = link.title + ' - pear-dashboard';
          links.push(link);
        }
      });
      return links;
    },
  },
};
</script>
