import Vendors from './Vendors.vue';
import VendorDetails from './VendorDetails.vue';

export default {
  path: 'vendors',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: '<%= customer %> Vendors',
      linkTemplate: '/customers/<%= id %>/vendors',
      storeMappings: { customer: 'customers/name', id: 'customers/id' },
    },
  },
  children: [
    {
      path: '',
      name: 'customer.vendors',
      component: Vendors,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: ':vendorId',
      component: VendorDetails,
      name: 'customer.vendor.details',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: '<%= name %>',
          linkTemplate: '/vendors/<%= id %>',
          storeMappings: { name: 'customers/vendorName', id: 'vendors/id' },
        },
      },
    },
  ],
};
