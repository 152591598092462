import HierarchiesList from './List.vue';
import Hierarchy from './View/View.vue';

export default {
  path: 'hierarchies',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: '<%= customer %> Hierarchies',
      linkTemplate: '/customers/<%= id %>/hierarchies',
      storeMappings: { customer: 'customers/name', id: 'customers/id' },
    },
  },
  children: [
    {
      path: '',
      name: 'customer.hierarchies',
      component: HierarchiesList,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: ':hierarchyId',
      component: Hierarchy,
      name: 'customer.hierarchies.info',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: '<%= customer %> <%= name %> Hierarchy',
          storeMappings: { name: 'hierarchy/name', customer: 'customers/name' },
        },
      },
    },
  ],
};
