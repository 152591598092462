import Vendors from './Vendors';
import VendorDetails from './VendorDetails';

export default {
  path: '/vendors',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    showBreadcrumbs: true,
    breadcrumb: {
      titleTemplate: 'Vendors',
      linkTemplate: '/vendors',
    },
  },
  children: [
    {
      path: '',
      component: Vendors,
      name: 'vendors',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
    {
      path: ':vendorId',
      component: VendorDetails,
      name: 'vendorDetails',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: '<%= code %>',
          linkTemplate: '/vendors/<%= id %>',
          storeMappings: { code: 'vendors/code', id: 'vendors/id' },
        },
      },
    },
  ],
};
