const tags = {};

const calculatedFields = [
  {
    name: 'days_of_service_billblock',
    label: 'Days of Service',
  },
  {
    name: 'total_consumption_billblock',
    label: 'Total Consumption',
  },
  {
    name: 'total_consumption_onpeak_billblock',
    label: 'On-Peak Consumption',
  },
  {
    name: 'total_consumption_midpeak_billblock',
    label: 'Mid-Peak Consumption',
  },
  {
    name: 'total_consumption_offpeak_billblock',
    label: 'Off-Peak Consumption',
  },
  {
    name: 'total_consumption_shoulderpeak_billblock',
    label: 'Shoulder-Peak Consumption',
  },
  {
    name: 'total_consumption_superpeak_billblock',
    label: 'Super-Peak Consumption',
  },
  {
    name: 'demand_billblock',
    label: 'Demand',
  },
  {
    name: 'demand_onpeak_billblock',
    label: 'On-Peak Demand',
  },
  {
    name: 'demand_midpeak_billblock',
    label: 'Mid-Peak Demand',
  },
  {
    name: 'demand_offpeak_billblock',
    label: 'Off-Peak Demand',
  },
  {
    name: 'demand_shoulderpeak_billblock',
    label: 'Shoulder-Peak Demand',
  },
  {
    name: 'demand_superpeak_billblock',
    label: 'Super-Peak Demand',
  },
  {
    name: 'powerfactor_billblock',
    label: 'Power Factor',
  },
  {
    name: 'loadfactor_billblock',
    label: 'Load Factor',
  },
  {
    name: 'total_charges_billblock',
    label: 'Total Charges',
  },
  {
    name: 'total_charges_taxes_billblock',
    label: 'Taxes',
  },
  {
    name: 'total_charges_other_billblock',
    label: 'Other Charges',
  },
  {
    name: 'total_charges_customer_billblock',
    label: 'Customer Charges',
  },
  {
    name: 'total_charges_commodity_billblock',
    label: 'Commodity Charges',
  },
  {
    name: 'total_charges_usage_billblock',
    label: 'Total Usage Charges',
  },
  {
    name: 'total_charges_consumption_billblock',
    label: 'Consumption Charges',
  },
  {
    name: 'total_charges_consumption_onpeak_billblock',
    label: 'On-Peak Consumption Charges',
  },
  {
    name: 'total_charges_consumption_midpeak_billblock',
    label: 'Mid-Peak Consumption Charges',
  },
  {
    name: 'total_charges_consumption_offpeak_billblock',
    label: 'Off-Peak Consumption Charges',
  },
  {
    name: 'total_charges_consumption_shdpeak_billblock',
    label: 'Shoulder-Peak Consumption Charges',
  },
  {
    name: 'total_charges_consumption_suppeak_billblock',
    label: 'Super-Peak Consumption Charges',
  },
  {
    name: 'total_charges_demand_billblock',
    label: 'Demand Charges',
  },
  {
    name: 'total_charges_generation_billblock',
    label: 'Generation Charges',
  },
  {
    name: 'total_charges_billeduse_billblock',
    label: 'Billed Usage Charges',
  },
  {
    name: 'daily_consumption_billblock',
    label: 'Daily Consumption',
    type: 'dav',
  },
  {
    name: 'daily_consumption_onpeak_billblock',
    label: 'Daily On-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_consumption_midpeak_billblock',
    label: 'Daily Mid-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_consumption_offpeak_billblock',
    label: 'Daily Off-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_consumption_shoulderpeak_billblock',
    label: 'Daily Shoulder-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_consumption_superpeak_billblock',
    label: 'Daily Super-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_charges_billblock',
    label: 'Daily Total Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_usage_billblock',
    label: 'Daily Usage Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_consumption_billblock',
    label: 'Daily Consumption Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_consumption_onpeak_billblock',
    label: 'Daily On-Peak Consumption Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_consumption_midpeak_billblock',
    label: 'Daily Mid-Peak Consumption Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_consumption_offpeak_billblock',
    label: 'Daily Off-Peak Consumption Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_consumption_shdpeak_billblock',
    label: 'Daily Shoulder-Peak Consumption Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_consumption_suppeak_billblock',
    label: 'Daily Super-Peak Consumption Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_demand_billblock',
    label: 'Daily Demand Charges',
    type: 'dav',
  },
  {
    name: 'daily_unit_costs_billblock',
    label: 'Daily Unit Costs',
    type: 'dav',
  },
  {
    name: 'unit_costs_billblock',
    label: 'Unit Costs',
  },
  {
    name: 'daily_charges_customer_billblock',
    label: 'Daily Customer Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_other_billblock',
    label: 'Daily Other Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_taxes_billblock',
    label: 'Daily Taxes',
    type: 'dav',
  },
  {
    name: 'daily_charges_commodity_billblock',
    label: 'Daily Commodity Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_billeduse_billblock',
    label: 'Daily Billed Usage Charges',
    type: 'dav',
  },
  {
    name: 'daily_charges_generation_billblock',
    label: 'Daily Generation Charges',
    type: 'dav',
  },
  {
    name: 'gen_consumption_billblock',
    label: 'Gen Consumption',
  },
  {
    name: 'gen_consumption_onpeak_billblock',
    label: 'Gen On-Peak Consumption',
  },
  {
    name: 'gen_consumption_midpeak_billblock',
    label: 'Gen Mid-Peak Consumption',
  },
  {
    name: 'gen_consumption_offpeak_billblock',
    label: 'Gen Off-Peak Consumption',
  },
  {
    name: 'gen_consumption_shoulderpeak_billblock',
    label: 'Gen Shoulder-Peak Consumption',
  },
  {
    name: 'gen_consumption_superpeak_billblock',
    label: 'Gen Super-Peak Consumption',
  },
  {
    name: 'gen_consumption_superoffpeak_billblock',
    label: 'Gen SupOffPeak Consumption',
  },
  {
    name: 'daily_gen_consumption_billblock',
    label: 'Daily Gen Consumption',
    type: 'dav',
  },
  {
    name: 'daily_gen_consumption_onpeak_billblock',
    label: 'Daily Gen On-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_gen_consumption_midpeak_billblock',
    label: 'Daily Gen Mid-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_gen_consumption_offpeak_billblock',
    label: 'Daily Gen Off-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_gen_consumption_shoulderpeak_billblock',
    label: 'Daily Gen Shoulder-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_gen_consumption_superpeak_billblock',
    label: 'Daily Gen Super-Peak Consumption',
    type: 'dav',
  },
  {
    name: 'daily_gen_consumption_superoffpeak_billblock',
    label: 'Daily Gen Super-Peak Consumption',
    type: 'dav',
  },
];

const batchTypes = [
  {
    label: 'Live Bill',
    value: 'live',
  },
  {
    label: 'Historical Bill',
    value: 'hist',
  },
  {
    label: 'Setup Bill',
    value: 'setup',
  },
  {
    label: 'Resubmission (FREE)',
    value: 'free',
  },
  {
    label: 'Resubmission (MAINT)',
    value: 'maint',
  },
  {
    label: 'Special Project',
    value: 'proj',
  },
];

export { tags, calculatedFields, batchTypes };
