import * as R from 'ramda';

const getDefaultState = () => ({
  filterData: {},
  isLoading: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    filterData: (state) => {
      return state.filterData;
    },
  },

  mutations: {
    setLoading(state) {
      state.isLoading = true;
    },
    setLoaded(state) {
      state.isLoading = false;
    },
    setFilterDate(state, data) {
      state.filterData = data || {};
    },
    clearState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  actions: {
    async getFilterData({ commit, rootGetters }) {
      commit('setLoading');
      try {
        const bill_errors = R.propOr(
          [],
          'value',
          rootGetters['resources/getResourceByName']('bill_errors'),
        );
        commit('setFilterDate', { bill_errors });
      } catch (err) {
        console.error('error while loading filters for global bills');
      }
      commit('setLoaded');
    },
  },
};
