import filters from './bills.dashboard.filters'
import { globalBills } from '@/api'
import * as R from 'ramda'

const defaultFilterState = () => ({
  customer_ids: [],
  batch_id: '',
  customer_active: true,
})

const defaultOrdersState = () => [{ field: 'data_verification_1', order: '-' }]

const defaultPaginationState = () => ({
  limit: 20,
  offset: 0,
  total: 0,
})

export default {
  namespaced: true,
  modules: { filtersData: filters },
  state: {
    list: [],
    summary: {},
    isLoading: false,
    filters: defaultFilterState(),
    pagination: defaultPaginationState(),
    orders: defaultOrdersState(),
  },

  getters: {
    isLoading: state => state.isLoading,
    list: state => state.list,
    summary: state => state.summary,
    filters: state => state.filters,
    limit: state => +state.pagination.limit,
    offset: state => +state.pagination.offset,
    total: state => +state.pagination.total,
    orders: state => state.orders,
  },

  mutations: {
    setList(state, data) {
      state.list = data
    },
    setSummary(state, data) {
      state.summary = data
    },
    setLimit(state, limit) {
      state.pagination.limit = limit
    },
    setOffset(state, offset) {
      state.pagination.offset = offset
    },
    setFilter(state, { filter, value }) {
      state.filters[filter] = value
    },
    setFilters(state, filters) {
      Object.assign(state.filters, filters)
    },
    setOrders(state, orders) {
      state.orders = [orders]
    },
    setTotal(state, total) {
      state.pagination.total = total
    },
    setLoading(state) {
      state.isLoading = true
    },
    setLoaded(state) {
      state.isLoading = false
    },
    clearState(state) {
      state.filters = defaultFilterState()
      state.pagination = defaultPaginationState()
      state.orders = defaultOrdersState()
    },
    clearFilters(state) {
      state.filters = defaultFilterState()
    },
  },

  actions: {
    async setPagination({ commit }, { limit, offset }) {
      await commit('setLimit', limit)
      await commit('setOffset', offset)
    },
    list: async ({ commit }, { params }) => {
      try {
        commit('setLoading')
        commit('setList', [])
        commit('setSummary', {})
        const { data = [], summary = {} } = await globalBills.dashboard(params)
        const total = Number(R.propOr(0, 'total_rows_count', R.head(data)))
        commit('setList', data)
        commit('setSummary', summary)
        commit('setTotal', total)
      } catch (e) {
        commit('setTotal', 0)
        console.error(e)
      } finally {
        commit('setLoaded')
      }
    },
  },
}
