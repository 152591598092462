<template lang="pug">
  .success-wrapper
    .text-wrapper
      p It may take time to complete your report request.
      p Go to the "Reports List" tab to download your report.
    .button-wrapper
      el-button(type="primary", @click="close", data-action="submit-success") Go to Reports List
</template>

<script>
export default {
  name: 'success',
  methods: {
    close() {
      this.$emit('closeSuccess');
    },
  },
};
</script>

<style scoped lang="scss">
.success-wrapper {
  .text-wrapper {
    p {
      text-align: center;
      margin: 0;
      color: #909399;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0.09px;
      line-height: 22px;
    }
  }

  .button-wrapper {
    margin-top: 37px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .el-button {
      border-radius: 15px;
      background-color: #0080ff;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900;
      line-height: 14px;
      text-align: center;
      padding: 8px 30px;
    }
  }
}
</style>
