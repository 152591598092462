<template lang="pug">
  .bills-list-container.bills-list-uploads
    .bills-list-header
      .bills-list-header-title FDG Uploads
      .bills-list-header-action
        router-link.button-uploads(:to="{ name: 'customer.uploads.directory' }")
          i.icon.icon-uploads-sm
          | Upload Files
    el-table(class="bills-list-upload-table with-shadow",
      :data="batches",
      :default-sort = "{prop: 'downloaded_at', order: 'descending'}",
      :cell-class-name="checkStatus",
      @sort-change="sortTable")
      el-table-column(label="Batch ID", prop="batch_id", width="120", sortable)
      el-table-column(label="Type", prop="batch_type", width="110", sortable)
      el-table-column(label="Date Created", prop="created_at", width="148", sortable)
        template(slot-scope="scope")
          span(data-type='created_at') {{ scope.row['created_at'] | datetime('LLLL dd, yyyy') || '-' }}
      el-table-column(label="Date Submitted", prop="uploaded_at", width="162", sortable)
        template(slot-scope="scope")
          span(data-type='uploaded_at') {{ scope.row['uploaded_at'] | datetime('LLLL dd, yyyy') || '-' }}
      el-table-column(label="Date Processed", prop="downloaded_at", width="163", sortable="custom")
        template(slot-scope="scope")
          span(data-type='downloaded_at') {{ scope.row['downloaded_at'] | datetime('LLLL dd, yyyy') || '-' }}
      el-table-column(label="Uploaded Bills", prop="payload.pdf_file_count", width="165", sortable="custom")
      el-table-column(label="Downloaded Bills",  width="173", sortable="custom")
        template(slot-scope="scope")
          span(data-type='downloaded_at') {{ getDownloadedBills(scope.row) }}
      el-table-column(label="Processing Errors",  width="175", sortable="custom")
        template(slot-scope="scope")
          span(data-type='downloaded_at') {{ getErrors(scope.row) }}
      el-table-column(label="Status", prop="status", width="100", sortable)
      el-table-column(label="Directory", width="90")
        template(slot-scope="scope")
          a.drive-folder-link(v-if="scope.row.user_specified_folder_id", target="_blank", :href="`https://drive.google.com/drive/u/2/folders/${scope.row.user_specified_folder_id}`") Link
          .drive-popover-body(v-if="scope.row.payload.folder_name")
            .drive-popover-content
              span {{ scope.row.payload.folder_name }}
            .drive-popover-arrow
      el-table-column(label="Download", width="110")
        template(slot-scope="scope")
          el-button.el-button.el-button--text(v-if="scope.row.up_file_id", @click="getProcessedFileUrl(scope.row.up_file_id)") Uploaded file
          el-button.el-button.el-button--text(v-if="scope.row.down_file_id", @click="getProcessedFileUrl(scope.row.down_file_id)") Processed file

    el-pagination.paginator-wrapper(background,
      v-if="total > limit || limit > 20",
      layout="prev, pager, next, ->, sizes",
      :page-size="limit",
      :current-page="currentPage",
      @current-change="changeOffset",
      @size-change="changeLimit",
      :total="total")
</template>

<script>
import { mapState } from 'vuex';
import { hosts } from '@/lib/api.configs';
import { batches } from '@/api';
import _ from 'lodash';

export default {
  data() {
    return {
      isLoading: true,
      limit: 20,
      offset: 0,
      total: 0,
      order: '-downloaded_at',
      batches: [],
      API_HOST: hosts.api,
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer,
    }),
    currentPage() {
      return this.offset / this.limit + 1;
    },
  },
  mounted() {
    this.loadList();
  },
  methods: {
    getDownloadedBills(row) {
      return _.get(row, 'payload.processed_bills_count.total_bills', 'N/A');
    },
    getErrors(row) {
      return _.get(row, 'payload.processed_bills_count.errors_count', '0');
    },
    async loadList() {
      this.isLoading = true;

      const { data, meta } = await batches.list({
        customer_ids: [this.$route.params.customerId],
        limit: this.limit,
        offset: this.offset,
        orders: this.order,
      });

      this.batches = data;

      this.total = Number(meta.total_amount);
      this.isLoading = false;
    },
    async getProcessedFileUrl(file_id) {
      const processedFileUrl = await batches.getProcessedFileUrl(
        this.customer.id,
        file_id,
      );
      window.open(processedFileUrl.link, '_self');
    },
    sortTable(obj) {
      if (obj.prop && obj.order) {
        this.order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      } else {
        this.order = '';
      }
      this.loadList();
    },
    changeOffset(e) {
      this.offset = (e - 1) * this.limit;
      this.loadList();
    },
    changeLimit(e) {
      this.limit = e;
      this.loadList();
    },
    checkStatus({ row, column }) {
      if (column.property === 'status' && row.status.includes('Error')) {
        return 'status-with-error';
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/constants.scss';

.bills-list {
  &-container {
    margin: 20px 40px;
  }

  &-header {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;

    &-title {
      font-size: 20px;
      font-weight: 600;
      justify-content: space-between;
      align-items: center;
      display: flex;
    }

    &-total-amount {
      font-size: 14px;
      color: #4a4a4a;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .currency-value {
        margin-left: 20px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &-total {
    margin: 10px 0;
    display: block;
    font-size: 12px;
    color: #222222;

    .number {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  &-upload-table {
    .el-table__footer-wrapper,
    .el-table__header-wrapper,
    .el-table__body-wrapper {
      overflow-y: auto;
    }

    max-height: 75%;

    td {
      padding: 7px 0;

      .cell {
        overflow: visible !important;
      }
    }

    .el-button {
      + .el-button {
        margin-top: 3px;
        margin-left: 0;
      }
    }

    .status-with-error {
      color: #f86259;
    }

    .drive-folder-link {
      color: #409eff;
      position: relative;

      &:hover ~ .drive-popover-body {
        display: block;
      }
    }

    .drive-popover-body {
      display: none;
      height: 40px;
      position: absolute;
      top: -44px;
      left: 8px;
      z-index: 99;

      .drive-popover-content {
        text-align: center;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #e4e7ed;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
        padding: 4px 16px;
        margin-bottom: 20px;

        span {
          line-height: 20px;
          font-size: 12px;
        }
      }

      .drive-popover-arrow {
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 28px;
          width: 0;
          height: 0;
          border-style: solid;
        }

        &:before {
          border-color: #e4e7ed transparent transparent transparent;
          border-width: 11px;
        }

        &:after {
          border-color: #fff transparent transparent transparent;
          border-width: 10px;
        }
      }
    }
  }
}

.bills-list-link {
  padding: 5px 0;
  display: block;
  color: inherit;
  text-decoration: none;
}

.button-uploads {
  font-size: 16px;
  color: $primary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
</style>
