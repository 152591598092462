<template>
  <filter-input
    placeholder="Search"
    :searchQuery="filters.query"
    @onSearchUpdate="onQueryChange"
    data-testid="searchInp"
  >
    <div class="list-filter">
      <div class="list-filter-item">
        <div class="list-filter-title">
          Show only vendors with blank Custom Pretty Names
        </div>
        <div class="list-filter-parameter">
          <el-switch
            v-model="filters.blankCustomPrettyName"
            @change="onPrettyChange"
            data-testid="showOnlyWithPrettyBtn"
          ></el-switch>
        </div>
      </div>
    </div>
  </filter-input>
</template>

<script>
import { FilterInput } from '@/components';
import _ from 'lodash';

export default {
  name: 'VendorsFilters',
  components: {
    FilterInput,
  },
  props: {
    initFilters: { type: Object },
  },
  data() {
    return {
      filters: {
        query: '',
        blankCustomPrettyName: false,
      },
    };
  },
  beforeMount() {
    Object.assign(this.filters, this.initFilters);
  },
  methods: {
    onQueryChange(query) {
      this.filters.query = query;
      this.onFiltersChange();
    },
    onPrettyChange(flag) {
      this.filters.blankCustomPrettyName = flag;
      this.onFiltersChange();
    },
    onFiltersChange: _.debounce(function () {
      this.$emit('onChange', _.clone(this.filters));
    }, 700),
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  .el-range__close-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-item {
    display: flex;
    align-items: center;
  }

  &-title {
    padding-right: 17px;
    margin: 0;
    color: #606266;
  }
}
</style>
