import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

const list = (customerId, params) =>
  http(hosts.v1)
    .get(`customers/${customerId}/hierarchies`, { params })
    .then(dataMiddleware);

const postHierarchy = (customerId, hierarchy) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/hierarchies`, hierarchy)
    .then(dataMiddleware);

const fetchHierarchy = (customerId, hierarchyId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/hierarchies/${hierarchyId}/items`)
    .then(dataMiddleware);

const fetchDefaultHierarchy = (customerId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/hierarchies/default`)
    .then(dataMiddleware);

const postHierarchyItems = (customerId, hierarchyId, body) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/hierarchies/${hierarchyId}/items`, body)
    .then(dataMiddleware);

const fetchUnmachedLocations = (customerId, hierarchyId, params = {}) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/hierarchies/${hierarchyId}/unmatched-locations`,
      { params },
    )
    .then(dataMiddleware);

const deleteItem = (customerId, hierarchyId, id) =>
  http(hosts.v1)
    .delete(`/customers/${customerId}/hierarchies/${hierarchyId}/items/${id}`)
    .then(dataMiddleware);

const patchItem = (customerId, hierarchyId, itemId, body) =>
  http(hosts.v1)
    .patch(
      `/customers/${customerId}/hierarchies/${hierarchyId}/items/${itemId}`,
      body,
    )
    .then(dataMiddleware);

const insertLocationsBulk = (customerId, hierarchyId, body) =>
  http(hosts.v1)
    .post(
      `/customers/${customerId}/hierarchies/${hierarchyId}/assign-locations`,
      body,
    )
    .then(dataMiddleware);

export {
  list,
  postHierarchy,
  fetchHierarchy,
  postHierarchyItems,
  fetchUnmachedLocations,
  deleteItem,
  patchItem,
  insertLocationsBulk,
  fetchDefaultHierarchy,
};
