import _ from 'lodash';
import { hierarchies as h } from '@/api';

const getDefaultState = () => ({
  isFetching: false,
  data: [],
  name: '',
  selectedItemId: null,
  locations: [],
  unmachedLocations: [],
  editMode: false,
  selfEditingId: null,
  unmachedLocationParams: {},
  defaultHierarchies: {},
});

export default {
  namespaced: true,
  state: getDefaultState(),

  getters: {
    data: (state) => state.data,
    isFetching: (state) => state.isFetching,
    locations: (state) => state.locations,
    unmachedLocations: (state) => state.unmachedLocations,
    unmachedLocationParams: (state) => state.unmachedLocationParams,
    editMode: (state) => state.editMode,
    name: (state) => state.name || '',
  },

  mutations: {
    requestHierarchy(state) {
      state.isFetching = true;
    },
    receiveHierarchy(state, data = []) {
      state.data = data;
      state.isFetching = false;
    },
    selectItemId(state, id) {
      state.selectedItemId = id;
    },
    setEditMode(state, value) {
      state.editMode = value;
    },
    requestLocations(state) {
      state.isFetching = true;
    },
    receiveLocations(state, locations) {
      state.locations = locations;
      state.isFetching = false;
    },
    receiveLocationsToAssign(state, locations) {
      state.unmachedLocations = locations;
    },
    clearState(state) {
      Object.assign(state, getDefaultState());
    },
    setSelfEditMode(state, id) {
      state.selfEditingId = id;
    },
    setName(state, name) {
      state.name = name;
    },
    setUnmachedLocationsParams(state, value) {
      state.unmachedLocationParams = value;
    },
    setDefaultHierarchies(state, defaultHierarchies) {
      state.defaultHierarchies = defaultHierarchies;
    },
    resetUnmatchedLocations(state) {
      state.locations = [];
    },
  },

  actions: {
    async fetchHierarchy({ commit, state }, { customerId, hierarchyId }) {
      commit('requestHierarchy');
      const { data } = await h.fetchHierarchy(customerId, hierarchyId);
      commit('receiveHierarchy', data);
      commit('setName', _.get(data, '0.name', ''));
      return data;
    },
    async fetchUnmachedLocations({ commit }, { customerId, hierarchyId }) {
      try {
        commit('requestLocations');
        const { results } = await h.fetchUnmachedLocations(
          customerId,
          hierarchyId,
        );
        commit('receiveLocations', results);
        return results;
      } catch (e) {
        console.error(e);
        commit('resetUnmatchedLocations');
      }
    },
    async fetchUnmachedLocationsToAssign(
      { commit, state },
      { customerId, hierarchyId },
    ) {
      const { results } = await h.fetchUnmachedLocations(
        customerId,
        hierarchyId,
        state.unmachedLocationParams,
      );
      commit('receiveLocationsToAssign', results);
      return results;
    },
    async createGroup(
      { commit },
      { params: { customerId, hierarchyId }, body },
    ) {
      return h.postHierarchyItems(customerId, hierarchyId, { items: [body] });
      // commit push data to state
    },
    async createItemsBatch(
      { commit },
      { params: { customerId, hierarchyId }, body },
    ) {
      return h.postHierarchyItems(customerId, hierarchyId, { items: body });
      // commit push data to state
    },
    async deleteHierarchyItem(
      { commit },
      { params: { customerId, hierarchyId }, id },
    ) {
      return h.deleteItem(customerId, hierarchyId, id);
    },
    async patchHierarchyItem(
      { commit },
      { params: { customerId, hierarchyId, itemId }, body },
    ) {
      return h.patchItem(customerId, hierarchyId, itemId, body);
    },
    async insertLocationsBulk(
      { commit },
      { params: { customerId, hierarchyId }, body },
    ) {
      await h.insertLocationsBulk(customerId, hierarchyId, body);
    },
    selectItem({ commit }, id) {
      commit('selectItemId', id);
    },
    setEditMode({ commit }, value) {
      commit('setEditMode', value);
    },
    clearState({ commit }) {
      commit('clearState');
    },
    setSelfEditMode({ commit }, value) {
      commit('setSelfEditMode', value);
    },
    setUnmachedLocationsParams({ commit }, value) {
      commit('setUnmachedLocationsParams', value);
    },
  },
};
