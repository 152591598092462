<template lang="pug">
  .wrapper
    header
      a(href='/').logo
        img(:src='logo' alt='Logo').logo__image
        |Pear.ai
    .container
      slot
</template>

<script>
import logo from '@/assets/logo.png';

export default {
  data() {
    return {
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/constants';

header {
  height: $header-height;
  border-bottom: solid 1px #dcdfe6;
  text-align: center;
  margin-bottom: 90px;
}

.logo {
  line-height: $header-height;
  font-size: 18px;
  color: #273a58;
  text-decoration: none;

  .logo__image {
    margin-right: 14px;
    display: inline-block;
    vertical-align: middle;
    max-height: 26px;
  }
}
</style>
