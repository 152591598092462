export default {
  path: '/paymenterrors',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    showBreadcrumbs: true,
    breadcrumb: {
      titleTemplate: 'Errors',
      linkTemplate: '/paymenterrors',
    },
  },
  children: [
    {
      path: '',
      name: 'errorsTab.paymenterrors',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
    {
      path: '/communicationerrors',
      name: 'errorsTab.communicationerrors',
      meta: {
        breadcrumb: {
          titleTemplate: 'Communication Error Log',
          linkTemplate: '/communicationerrors',
        },
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
  ],
};
