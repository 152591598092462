import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';
import qs from 'qs';

/**
 * @description allows to get bills list of bill
 * @param {Object} params {
 *  offset,
 *  limit,
 *  commodities,
 *  vendor_codes,
 *  total_charge_max,
 *  total_charge_min,
 *  statement_date_min,
 *  statement_date_max,
 *  orders
 * }
 */
const list = (params) =>
  http(hosts.v1)
    .get(`/bills?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }));

const bulkActions = (data) =>
  http(hosts.v3).post(`/bills/bulk-actions`, data).then(dataMiddleware);

const reprioritize = () =>
  http(hosts.v3).post(`/bills/reprioritize`).then(dataMiddleware);

const bulkReassign = (data) =>
  http(hosts.v1).post(`/bills/bulk-reassign`, data).then(dataMiddleware);
/**
 * @description allows to get data for bill filters
 */
const getFiltersData = () =>
  http(hosts.v1).get(`/bills/filters`).then(dataMiddleware);

/**
 * @description allows to get bills list of bill
 * @param {Object} params {
 *  offset,
 *  limit,
 *  orders
 * }
 */
const dashboard = (params) =>
  http(hosts.v1)
    .get(`/bills/dashboard?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
    .then(dataMiddleware)
    .then((data) => ({
      data: data.data || [],
      summary: data.summary || {},
    }));

export {
  list,
  bulkActions,
  bulkReassign,
  reprioritize,
  getFiltersData,
  dashboard,
};
