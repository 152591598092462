import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

/**
 * @function
 * @memberOf exports
 * */
const getExport = (customerId, params) => {
  return http(hosts.v1)
    .post(`/customers/${customerId}/generate-export`, params)
    .then(dataMiddleware);
};

/**
 * @function
 * @memberOf exports
 * @param {string} customerId
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getErrorsExport = (customerId, params) => {
  return http(hosts.v1)
    .post(`/customers/${customerId}/generate-errors-export`, params)
    .then(dataMiddleware);
};

/**
 * @function
 * @memberOf exports
 * @param {string} customerId
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getBillingLineItems = (customerId, body) => {
  return http(hosts.v3)
    .post(`/customers/${customerId}/reports/preview`, body)
    .then(dataMiddleware);
};

/**
 * @function
 * @memberOf exports
 * @param {string} customerId
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getBillingLineItemsExport = (customerId, params) => {
  return http(hosts.v1)
    .post(`/customers/${customerId}/generate-billing-line-items-export`, params)
    .then(dataMiddleware);
};

/**
 * @function
 * @memberOf exports
 * @param {string} customerId
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFiltersData = (customerId) => {
  return http(hosts.v1)
    .get(`/customers/${customerId}/generate-billing-line-items-export/filters`)
    .then(dataMiddleware);
};

/**
 * @description get report by id
 * @param {string} customerId
 * @param {string} reportId
 * @return {Promise<{id: string, status: string, [url]: string}>}
 */
const report = (customerId, reportId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/reports/${reportId}`)
    .then(dataMiddleware);

/**
 * @description create report
 * @param {string} customerId
 * @param {object} reportOptions
 */
const createReport = (customerId, reportOptions) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/reports`, reportOptions)
    .then(dataMiddleware);

export {
  getExport,
  getErrorsExport,
  getBillingLineItems,
  getBillingLineItemsExport,
  getFiltersData,
  report,
  createReport,
};
