export default {
  path: 'custom_attributes_vendor',
  name: 'customer.custom_attributes_vendor',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    auth: true,
    showNavbar: true,
    showBreadcrumbs: true,
    breadcrumb: {
      titleTemplate: '<%= customer %> Custom Attributes',
      linkTemplate: '/customers/<%= id %>/custom_attributes',
      storeMappings: { customer: 'customers/name', id: 'customers/id' },
    },
  },
};
