import { head, isNil, merge, not, propOr, reduce, tail } from 'ramda';

function serializeOrderBy(prop, order) {
  const orders = {
    ascending: '+',
    descending: '-',
  };

  if (not(order in orders)) {
    throw new Error('unknown order : ' + order);
  }

  return orders[order] + prop;
}

function serializeOrderByV3(prop, order) {
  const orders = {
    ascending: 'asc',
    descending: 'desc',
  };

  if (not(order in orders)) {
    throw new Error('unknown order : ' + order);
  }

  return `${prop}.${orders[order]}`;
}

function deserializeOrderBy(orderBy) {
  const orders = {
    '+': 'ascending',
    '-': 'descending',
  };

  if (isNil(orderBy)) {
    orderBy = '';
  }

  return {
    prop: tail(orderBy),
    order: orders[head(orderBy)],
  };
}

function setPieChartUtilityColor(utility) {
  switch (utility) {
    case 'SEWER':
      return '#43B493';
    case 'WATER':
      return '#7AC0E7';
    case 'REFUSE':
      return '#F3BC6A';
    case 'SOLARPV':
      return '#6D8ED1';
    case 'ELECTRIC':
      return '#C28226';
    case 'HOTWATER':
      return '#DB3B3B';
    case 'INTERNET':
      return '#D1AEAE';
    case 'LIGHTING':
      return '#93B443';
    case 'TELEPHONE':
      return '#B283D3';
    case 'IRRIGATION':
      return '#CC1B84';
    case 'NATURALGAS':
      return '#34598B';
    case 'STORMDRAIN':
      return '#888888';
    case 'STORMWATER':
      return '#E0EDA8';
    case 'CHILLEDWATER':
      return '#F7A6A6';
    case 'FIREPROTECTION':
      return '#842121';
  }
}

function vendorsPrettyMap(vendors = [], propWithCodes) {
  const providers = (acc, value) => {
    const { pretty_name } = value;
    const codes = propOr([], propWithCodes, value);
    const prettyMap = reduce(
      (acc, code) => merge(acc, { [code]: pretty_name || code }),
      {},
      codes,
    );
    return merge(acc, prettyMap);
  };
  return reduce(providers, {}, vendors);
}

function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  try {
    return JSON.parse(jsonPayload);
  } catch (e) {
    return {};
  }
}

const getTimeUntilRefreshTokenMs = () => {
  const jwt = localStorage.getItem('authToken');
  if (!jwt) {
    return;
  }
  const { exp } = parseJwt(jwt);
  const now = Date.now();
  let timeUntilRefresh = exp * 1000 - now;
  timeUntilRefresh -= (10 + Math.floor(Math.random() * 60)) * 1000;
  return timeUntilRefresh;
};

export {
  serializeOrderBy,
  serializeOrderByV3,
  deserializeOrderBy,
  setPieChartUtilityColor,
  vendorsPrettyMap,
  capitalize,
  parseJwt,
  getTimeUntilRefreshTokenMs,
};
