<template lang="pug">
  el-dialog.modal-confirmation(:visible="isVisible",
    title="",
    @open="notification=true",
    @close="close()",
    width='440px')
    .message-box
      .message-box__header
        .message-box__title
          span {{ title }}
      .message-box__content
        .message-box__container
          .message-box__message
            .el-icon-warning
            span {{ message }}
          el-checkbox(v-model="notification")
            span Send notification email to this user
      .message-box__btns
        el-button(@click="cancel()", size="small") Cancel
        el-button(@click="ok()", type="primary", size="small") OK
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => 'Warning',
    },
    message: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      notification: true,
    };
  },
  methods: {
    ok() {
      this.$emit('ok', this.notification);
    },
    cancel() {
      this.$emit('cancel');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/constants.scss';
.message-box {
  margin: -15px -15px 0 -15px;
  &__title {
    font-size: 18px;
    line-height: 1;
    color: #303133;
  }
  &__content {
    .el-icon-warning {
      color: #e6a23c;
      font-size: 24px;
    }
  }
  &__message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px 15px 20px 0;
    word-break: break-word;
    .el-icon-warning {
      padding-right: 12px;
    }
  }
  &__btns {
    padding: 15px 0 0 0;
    text-align: right;
  }
}
</style>
