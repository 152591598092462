<template lang="pug">
  .confirm-modal_container
    .modal_content Changing linked accounts will trigger bill revalidation for all bills for this virtual account. Are you sure you want to proceed?
    .modal_actions
      el-button.plain-button-reverse(@click='cancel') Cancel
      el-button(type='primary', @click='confirm', :loading="isFetching") Confirm
</template>

<script>
export default {
  props: {
    isFetching: {
      required: true,
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirmSubmit');
    },
    cancel() {
      this.$emit('closeConfirmModal');
    },
  },
};
</script>
