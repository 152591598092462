<template>
  <div
    class="component-container"
    :class="border(data)"
    @click="expandNode(node)"
  >
    <div :class="nodeName(node)">
      <span
        >{{ node.label }} <span>{{ numberOfChildNodes(data) }}</span></span
      >
      <div class="address" v-if="data.address">{{ address(data) }}</div>
    </div>
    <div v-if="node.childNodes.length" class="arrow-container-padding">
      <i class="el-icon-arrow-right" :class="[arrowLeft, nodeName(node)]" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    totalLocations: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    address() {
      return (data) => {
        let { address, city, state, postcode } = data;
        return `${address && address}, ${city && city}, ${state && state}, ${
          postcode && postcode
        }`;
      };
    },
    arrowLeft() {
      let arrow = 'turn-arrow';
      if (this.showDropdown === true) {
        return arrow;
      }
      return true;
    },
    nodeName() {
      return (node) => {
        let boldClose = 'node-name-closed';
        let boldOpen = 'node-name-opened';
        if (node.childNodes.length > 0 && this.showDropdown === true) {
          return boldOpen;
        }
        return boldClose;
      };
    },
    border() {
      return (data) => {
        let border = 'node-border';
        if (data.hasOwnProperty('location') || data.name === 'All Locations') {
          return border;
        }
        return true;
      };
    },
    numberOfChildNodes() {
      return (data) => {
        if (data.name === 'All Locations') {
          return `(${this.totalLocations})`;
        }
        if (data.hasOwnProperty('location') && data.children) {
          return `(${data.children.length})`;
        }
        return '';
      };
    },
  },
  methods: {
    expandNode(node) {
      node.expanded = !node.expanded;
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style lang="scss" scoped>
.component-container {
  display: inline-flex;
}
.checkbox-container {
  padding-right: 8px;
}
.el-icon-arrow-right {
  color: #909399;
}
.turn-arrow {
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition-timing-function: ease-out;
  transition: 0.3s;
}
.node-border {
  border: 1px solid #dcdfe5;
  border-radius: 4px;
  padding: 6px 10px;
}
.node-name-closed {
  font-weight: 900;
  color: #909399;
}
.node-name-opened {
  font-weight: 900;
  color: #409eff;
}
.location-name {
  color: #666666;
}
.arrow-container-padding {
  padding-left: 12px;
}
.address {
  font-size: 10px;
  line-height: 14px;
}
</style>
