// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/search.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-observations[data-v-6408b079]{min-height:calc(100% - 80px);padding:20px 40px 60px 40px}.page-content[data-v-6408b079],.page-observations[data-v-6408b079]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.page-content[data-v-6408b079]{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.page-header[data-v-6408b079]{display:-webkit-box;display:-ms-flexbox;display:flex}.page-header .header-button[data-v-6408b079]{height:40px;padding:0 18px;color:#fff;font-size:14px;margin-left:auto;border:none;border-radius:4px;background-color:#1989fa}.page-title[data-v-6408b079]{margin:0;font-weight:600;font-size:20px;line-height:40px}.page-search[data-v-6408b079]{position:relative;margin-bottom:10px}.page-search .icon-search[data-v-6408b079]{width:40px;height:40px;position:absolute;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;z-index:1}.page-search-input>input[data-v-6408b079]{border:none}[data-v-6408b079] .page-search-input>input.el-input__inner{padding:0 44px}", ""]);
// Exports
module.exports = exports;
