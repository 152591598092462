<template lang="pug">
  collapse-block(:data-item-id='item.id' data-item :isCollapsed="isCollapsed")
    el-dialog.match-dialog(
      title='Map Virtual Account to Location',
      :visible.sync="isMatchModalOpened",
      width="768px",
      height='650px',
      :before-close="closeMatchModal")
      match-modal(
        :key="keyMatchModal"
        :exception='item',
        :locations='locations',
        :loading='isLocationsLoading',
        @match='matchException',
        @cancel='closeMatchModal')
    .bill-collapse(slot='title', data-section="item-header")
      .bill-collapse-block.frozen-block(@contextmenu.prevent="copyHeaderText(`${item.vaFrozenReason}`)" v-if="item.vaFrozenAt && item.vaFrozenReason")
        span.bill-collapse-block-title
          inline-svg(:src="require('../../../../../assets/icons/freezeBlue.svg')")
      .bill-collapse-block.frozen-reason-wrapper(@contextmenu.prevent="copyHeaderText(`${item.vaFrozenReason}`)" v-if="item.vaFrozenAt && item.vaFrozenReason && reasonFrozenBill === null")
        el-tooltip(placement='top' effect='light' popper-class='frozen-reason' v-if="item.vaFrozenReason && reasonFrozenBill === null")
          template(slot='content') {{item.vaFrozenReason}}
          span.frozen-reason {{item.vaFrozenReason}}
      .bill-collapse-block(@contextmenu.prevent="copyHeaderText(`${item.meterSerial}`)")
        span.bill-collapse-block-title MID
        span.bill-collapse-block-value(data-type='meter-id') {{ item.meterSerial }}
      .bill-collapse-block(data-type='service-account' @contextmenu.prevent="copyHeaderText(`${item.accountCode}`)")
        span.bill-collapse-block-title(data-title) SAID
        span.bill-collapse-block-value(data-value) {{ item.accountCode }}
      .bill-collapse-block(data-type='utility' @contextmenu.prevent="copyHeaderText(`${item.commodity} (${shortBillType })`)")
        span.bill-collapse-block-title(data-title)
        span.bill-collapse-block-value(data-value) {{ item.commodity }} ({{ shortBillType }})
      .bill-collapse-block(@contextmenu.prevent="copyHeaderText(`${item.startDate} - ${endDate}`)")
        span.bill-collapse-block-title(data-title) {{ startDate }} - {{ endDate }}
      .bill-collapse-block.ta-r.bill-collapse-block--rate-code(:title="item.rateCode" @contextmenu.prevent="copyHeaderText(`${item.rateCode}`)")
        span.bill-collapse-block-title(data-title) {{ item.rateCode }}
      .bill-collapse-block.ta-r.bill-collapse-block--rate-code(:title="item.rateCode")
        el-button.bill-collapse-block-title.more-actions-button(data-title @click.prevent="portingProps.openVirtualAccountDetails(item, +$route.params.customerId, userId)" type="text") More Details
        operator-notes(class="op-notes" :operator-notes="item.operatorNotes", :is-on-bill-block="true")
    .item-content-wrapper
      .bill-collapse-content(data-section="general-info")
        .bill-collapse-content-data
          .bill-field-container
            //.bill-title.clickable(@click="showGeneralInfo = !showGeneralInfo", :style="{'margin-left': '-20px'}") General Information1
              .bill-arrow(:style="{'margin-left': '10px'}")
                i.icon.icon-block-arrow(:class="{'back': !showGeneralInfo }")
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Bill Subtotal
            .bill-field-value(v-if="subTotal && subTotal.value") {{ subTotal.value | unit(subTotal.units, nonUsBill) }}
            .bill-field-value(v-else) {{ "N/A" }}
          .bill-field-container.highlighted
            .bill-field-key Virtual Account ID
            .bill-field-value(data-type='location', :data-id='item.id')
              router-link.clickable.match-location(v-if="item.location && item.location.id", :to='{ name: "customer.locations.info", params: { locationId: item.location.id }}') {{ item.virtualAccountId }}
              .bill-field-value(v-else, data-type='rate-plan', :data-id='item.id') {{ item.virtualAccountId }}
              span &nbsp;&nbsp;
              a.clickable.match-location(v-if="item.location && item.location.id"
                @click="loadBillPath({ query: { ...$route.query, tab: 'account-history', virtualAccountId: item.virtualAccountId}})")
                |  (Account History)
          .bill-field-container.highlighted(v-if="(item.location && item.location.id) && (+item.virtualAccountId !== +item.virtualAccountGroupId)")
            .bill-field-key Virtual Account group ID
            .bill-field-value.clickable.match-location(data-type='location', :data-id='item.id')
              router-link(:to='{ name: "customer.locations.info", params: { locationId: item.location.id }}') {{ item.virtualAccountGroupId }}
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Service Address
            .bill-field-value(data-type='service-address', :data-id='item.id') {{ item.serviceAddress || '-' }}
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Service Zip
            .bill-field-value(data-type='service-zip', :data-id='item.id') {{ item.serviceZip || '-' }}
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Delivery Location
            .bill-field-value(data-type='meter-id', :data-id='item.id') {{ item.serviceLocation || 'N/A' }}
          //.bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Vendor Type
            .bill-field-value(data-type='vendor-type', :data-id='item.id') {{ item.bill_type || '-'}}
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Location Address
            .bill-field-value.clickable.match-location(data-type='location', :data-id='item.id', v-if='item.location && item.location.id')
              router-link(:to='{ name: "customer.locations.info", params: { locationId: item.location.id }}')
                | {{ item.location.address }}
            .bill-fielt-value.clickable.match-location(v-if='!item.locationId', @click='openMatchModal') Map
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Location Description
            .bill-field-value.clickable.match-location(data-type='location', :data-id='item.id', v-if='item.location && item.location.id')
              router-link(:to='{ name: "customer.locations.info", params: { locationId: item.location.id }}')
                | {{ item.location.name || "N/A" }}
            .bill-field-value(v-else, data-type='location', :data-id='item.id') N/A
        .bill-collapse-content-data(v-if="showGeneralInfo")
          .bill-field-container
            .bill-title
              a.clickable(@click="openCSV()" v-if='has(item.payload, "pear.drive_csv_id")', :data-id='item.id') CSV
          .bill-field-container.highlighted
            .bill-field-key Service Start Date
            .bill-field-value(data-type='billing-start-date', :data-id='item.id') {{ startDate }}
          .bill-field-container.highlighted
            .bill-field-key Service End Date
            .bill-field-value(data-type='billing-end-date', :data-id='item.id') {{ endDate }}
          .bill-field-container.highlighted
            .bill-field-key Days of Service
            .bill-field-value(data-type='days-of-service', :data-id='item.id') {{ item.daysOfService || 0 }} ({{ item.serviceDateMethod || 'N/A' }})
          .bill-field-container.highlighted
            .bill-field-key Adjusted Service End Date
            .bill-field-value(data-type='billing-end-date', :data-id='item.id') {{ adjustedEndDate }} ({{ adjustedDaysOfService }})
          .bill-field-container.highlighted
            .bill-field-key Rate Plan
            .bill-field-value(data-type='rate-plan', :data-id='item.id') {{ item.rateCode }}
          //.bill-field-container.highlighted
            .bill-field-key Prior Meter Reading
            router-link.internal-link(v-if='item.prior_bill_id', :to='`/customers/${$route.params.customerId}/bills/${item.prior_bill_id}`') {{ item.prior_bill_id }}
            .bill-field-value(v-else, data-type='prior-first-bill', data-value) First Bill
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Control code
            .bill-field-value(data-type='control-code', :data-id='item.id') {{ item.controlCode || '-'}}
          .bill-field-container.highlighted(v-if="showGeneralInfo")
            .bill-field-key Estimated
            .bill-field-value(data-type='estimated', :data-id='item.id') {{ isEstimated }}
      .irregular-data.irregular-data-bli(v-for="category of categories", :key="category", :data-section='category')
        collapse-block.irregular-data-title(v-if='getCategoryBLI(category).length !== 0' :title='category' mode='inner' arrowClass="icon-block-arrow")
          el-row.irregular-data-row.highlighted(v-for='(bli, index) of getCategoryBLI(category)', :key="index")
            template(v-if="bli.type === 'C'")
              el-col.irregular-data-row-key(:span="4", data-testid="obsLabel") {{ (bli.value ||  '') }}
              el-col.irregular-data-row-value(v-if='bli.usage', :span="8", data-testid="obsUsage") {{ (+(bli.usage || 0)).toFixed(3) | unit(bli.uom ? bli.uom : 'KWH', nonUsBill) }}
              el-col.irregular-data-row-value(v-else, :span="8", data-testid="obsUsage") &nbsp;
              el-col.irregular-data-row-value.currency-value(:span="3" v-if='!nonUsBill' , data-testid="obsCharge") {{ (bli.charge || 0) | currency }}
              el-col.irregular-data-row-value.currency-value(:span="3" v-if='nonUsBill', data-testid="obsCharge") {{ bli.nativeCurrency }} {{ (bli.nativeCharge || 0) | currency('') }}
              el-col.irregular-data-row-value.description-value(:span="5", data-testid="obsDescription") {{ bli.description }}
            template(v-if="bli.type === 'U'")
              el-col.irregular-data-row-key(:span="4", data-testid="obsLabel") {{ (bli.value ||  '') }}
              el-col.irregular-data-row-value(:span="8", data-testid="obsUsage") {{ (+(bli.usage || 0)).toFixed(3) | unit(bli.uom ? bli.uom : 'KWH', nonUsBill) }}
              el-col.irregular-data-row-value.currency-value(v-if='bli.charge && !nonUsBill', :span="3", data-testid="obsCharge") {{ (bli.charge || 0) | currency }}
              el-col.irregular-data-row-value.currency-value(v-if='bli.charge && nonUsBill', :span="3", data-testid="obsCharge") {{ bli.nativeCurrency }} {{ (bli.nativeCharge || 0) | currency('') }}
              el-col.irregular-data-row-value.currency-value(v-else, :span="3", data-testid="obsCharge") &nbsp;
              el-col.irregular-data-row-value.description-value(:span="5", data-testid="obsDescription") {{ bli.description }}
            template(v-if="bli.type === 'UC'")
              el-col.irregular-data-row-key(:span="4", data-testid="obsLabel") {{ (bli.value ||  '') }}
              el-col.irregular-data-row-value(:span="8", data-testid="obsUsage") {{ (+(bli.usage || 0)).toFixed(3) | unit(bli.uom ? bli.uom : 'KWH', nonUsBill) }}
              el-col.irregular-data-row-value.currency-value(:span="3" v-if="!nonUsBill", data-testid="obsCharge") {{ (bli.charge || 0) | currency }}
              el-col.irregular-data-row-value.currency-value(:span="3" v-if="nonUsBill", data-testid="obsCharge") {{ bli.nativeCurrency }} {{ (bli.nativeCharge || 0) | currency('') }}
              el-col.irregular-data-row-value.description-value(:span="5", data-testid="obsDescription") {{ bli.description }}
      .divider.divider-wapper
      el-row.computed-title.clickable(
        v-if='(calculationsRegularFields && calculationsRegularFields.length) || (calculationsDavFields && calculationsDavFields.length)',
        @click.native="showCalculatedValues = !showCalculatedValues", data-section='calculated-values', :data-item-id='item.id')
        span Calculated Values
        .bill-arrow
          i.icon.icon-block-arrow(:class="{'back': !showCalculatedValues }")
      .irregular-data.computed-sublock(v-if='calculationsRegularFields && calculationsRegularFields.length && showCalculatedValues', data-section='regular-values', :data-item-id='item.id')
        el-row.irregular-data-title.computed-subtitle.clickable(@click.native="showRegularValues = !showRegularValues")
          span Regular Values
          .bill-arrow
            i.icon.icon-block-arrow(:class="{'back': !showRegularValues }")
        el-row.irregular-data-row(v-if="showRegularValues")
          .irregular-data-item.highlighted(v-for='val of calculationsRegularFields', :key='val.name')
            el-col(:span="14") {{ (val.label || 'Val') }}
            el-col.irregular-data-row-value(:span="10", data-type='calculation', :data-id='val.id', :data-title='val.label', :data-value='val.value', :data-unit='val.unit') {{ (+(val.value || 0)).toFixed(3) | unit(val ? val.units : '', nonUsBill) }}
      .irregular-data.computed-sublock(v-if='weatherCalculations && weatherCalculations.length && showCalculatedValues', data-section='regular-values', :data-item-id='item.id')
        el-row.irregular-data-title.computed-subtitle.clickable(@click.native="showWeatherValues = !showWeatherValues")
          span Weather-related values
          .bill-arrow
            i.icon.icon-block-arrow(:class="{'back': !showWeatherValues }")
        .irregular-data-column(v-if="showWeatherValues")
          .irregular-data-weather-item.highlighted-weather(v-for='val of weatherCalculations', :key='val.name')
            el-col(:span="14") {{ (val.label || 'Val') }}
            el-col.irregular-data-row-value(:span="10", data-type='calculation', :data-id='val.id', :data-title='val.name', :data-value='val.value', :data-unit='val.unit') {{ (+(val.value || 0)).toFixed(3) | unit(val && val.units === 'KWHDD' ? val.units : '', nonUsBill) }}
      .irregular-data.computed-sublock(v-if='calculationsDavFields && calculationsDavFields.length && showCalculatedValues', data-section="daily-average-values", :data-item-id='item.id')
        el-row.irregular-data-title.computed-subtitle.clickable(@click.native="showDailyAverageValues = !showDailyAverageValues")
          span Daily-average Values
          .bill-arrow
            i.icon.icon-block-arrow(:class="{'back': !showDailyAverageValues }")
        el-row.irregular-data-row(v-if="showDailyAverageValues")
          .irregular-data-item.highlighted(v-for='val of calculationsDavFields', :key='val.name')
            el-col(:span="14") {{ (val.label || 'Val') }}
            el-col.irregular-data-row-value(:span="10", data-type='calculation', :data-id='val.id', :data-title='val.label', :data-value='val.value', :data-unit='val.unit') {{ (+(val.value || 0)).toFixed(3) | unit(val ? val.units : '', nonUsBill) }}
</template>

<script>
import { CollapseBlock } from '@/components';
import { get, has } from 'lodash';
import { ISODateFormat } from '@/lib';
import { bills, exceptions } from '@/api';
import MatchModal from '../../../Exceptions/Unmatched/MatchModal';
import * as R from 'ramda';
import OperatorNotes from '../../../../../components/OperatorNotes/OperatorNotes';
import { DateTime, Interval } from 'luxon';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    CollapseBlock,
    OperatorNotes,
    MatchModal,
  },
  beforeDestroy() {
    R.forEach((item) => clearInterval(item.handler))(this.bulkActionsQueue);
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    observationList: {
      type: Array,
      default: () => [],
    },
    hasObservations: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    isCollapsed: {
      type: Object,
      default: () => ({ isCollapsed: false, time: Date.now() }),
    },
  },
  data() {
    return {
      showGeneralInfo: true,
      showCalculatedValues: true,
      showMoreDetails: false,
      virtualAccount: false,
      showWeatherValues: true,
      showRegularValues: true,
      showDailyAverageValues: true,
      isMatchModalOpened: false,
      locations: [],
      isLocationsLoading: false,
      keyMatchModal: 0,
      bulkActionsQueue: [],
    };
  },
  computed: {
    ...mapState({
      portingProps: (state) => state.porting.props,
    }),
    ...mapGetters({
      bill: 'bills/bill',
      userId: 'user/userId',
      billOrVAFrozen: 'bills/billOrVAFrozen',
      reasonFrozenBill: 'bills/reasonFrozenBill',
    }),
    nonUsBill() {
      return this.bill.nativeCurrency && this.bill.nativeCurrency !== 'USD';
    },
    startDate() {
      return ISODateFormat((this.item || {}).startDate || {}, 'MM/DD/YY');
    },

    endDate() {
      return ISODateFormat((this.item || {}).endDate || {}, 'MM/DD/YY');
    },

    adjustedEndDate() {
      return ISODateFormat((this.item || {}).adjustedEndDate || {}, 'MM/DD/YY');
    },

    adjustedDaysOfService() {
      const start = DateTime.fromISO(this.item.startDate);
      const end = DateTime.fromISO(this.item.adjustedEndDate);
      const i = Interval.fromDateTimes(start, end);
      const days = Math.round(i.length('days')) + 1;
      return isNaN(days) ? '' : days;
    },

    calculationsRegularFields() {
      const calculationsRegular = get(this.item, 'calculations.regular', []);
      const sortedRegularCalc = calculationsRegular.sort((a, b) =>
        a.label < b.label ? -1 : 1,
      );
      return sortedRegularCalc;
    },
    weatherCalculations() {
      const calculationsWeather = get(
        this.item,
        'weatherCalculations.weather',
        [],
      );
      return calculationsWeather.sort((a, b) => (b.name < a.name ? -1 : 1));
    },
    calculationsDavFields() {
      const calculationsDav = get(this.item, 'calculations.dav', []);
      return calculationsDav.sort((a, b) => (a.label < b.label ? -1 : 1));
    },

    isEstimated() {
      if (this.item.estimated) {
        return 'Yes';
      }
      return 'No';
    },

    subTotal() {
      return get(this.item, 'calculations.regular', []).find(
        (e) => e.name === 'total_charges_billblock',
      );
    },
    shortBillType() {
      return (this.item.billType || '').slice(0, 4);
    },
  },
  methods: {
    ...mapMutations({
      setCurrentBill: 'bills/setCurrentBill',
    }),
    ...mapActions({
      $loadBillPath: 'bills/loadBillPath',
    }),
    copyHeaderText(text) {
      this.$message({
        showClose: true,
        message: `"${text}" was copied to your clipboard`,
        type: 'success',
      });
      this.$copyText(text);
    },
    has() {
      return has(...arguments);
    },
    loadBillPath(path) {
      this.$loadBillPath({
        path: path,
        router: this.$router,
      });
    },
    openCSV() {
      window.open(
        `https://drive.google.com/open?id=${this.item.payload['pear.drive_csv_id']}`,
      );
    },
    openMatchModal() {
      this.keyMatchModal++;
      this.locationsList(this.item)
        .then(this.getBillBlocks)
        .then((itemsCount) => {
          this.item.itemsCount = itemsCount;
          this.isMatchModalOpened = true;
        });
    },

    getBillBlocks() {
      const { virtualAccountId } = this.item;
      return exceptions
        .list(this.$route.params.customerId, { virtualAccountId })
        .then((response) => {
          const { data = [] } = { ...response };
          return R.pipe(R.head, R.prop('items_count'))(data);
        });
    },

    openLocation(id) {
      this.$router.push(
        `/customers/${this.$route.params.customerId}/locations/${id}`,
      );
    },

    closeMatchModal() {
      this.isMatchModalOpened = false;
    },

    matchException({ location, mapServiceAddress }) {
      const matchParams = {
        customerId: this.$route.params.customerId,
        locationId: location.id,
        virtualAccountId: this.item.virtualAccountId,
      };
      if (mapServiceAddress) {
        matchParams.serviceAddress = this.item.serviceAddress;
      }

      exceptions
        .matchException(this.$route.params.customerId, location.id, matchParams)
        .then((data) => {
          this.isMatchModalOpened = false;
          const bill = R.clone(this.bill);
          bill.items.forEach((item) => {
            if (item.virtualAccountId === this.item.virtualAccountId) {
              item.location = location;
              item.locationId = location.id;
            }
          });
          this.setCurrentBill(bill);
          this.showMatchMessage({
            ...data,
            virtualAccountId: this.item.virtualAccountId,
            location,
            mapServiceAddress,
          });
        });
    },
    locationsList({ service_zip }) {
      this.isLocationsLoading = true;
      return exceptions
        .getMatchesList(this.$route.params.customerId, service_zip)
        .then((e) => {
          this.locations = [...get(e, 'data', [])];
          this.isLocationsLoading = false;
          return this.locations;
        });
    },
    getCategoryBLI(category) {
      const billingLineItems = R.pathOr([], ['billingLineItems'], this.item);
      return R.filter(R.propEq('category', category))(billingLineItems) || [];
    },

    showMatchMessage({
      billItemsUpdated,
      billsToReparse,
      trackingId,
      virtualAccountId,
      location,
      mapServiceAddress,
    }) {
      const unmappedBlocks = R.pipe(
        R.reject(R.propEq('virtualAccountId', virtualAccountId)),
        R.reject(R.prop('locationId')),
        R.length,
      )(this.bill.items || []);
      let message = `Mapped ${billItemsUpdated} block${
        +billItemsUpdated > 1 ? 's' : ''
      }. Reparsing ${billsToReparse.length} bill${
        billsToReparse.length > 1 ? 's' : ''
      }.`;
      let type = 'success';
      if (unmappedBlocks > 0) {
        if (!mapServiceAddress) {
          message =
            message +
            ` Bill has ${unmappedBlocks} more unmapped block${
              unmappedBlocks > 1 ? 's' : ''
            }.`;
        }
        type = 'warning';
      }
      message = `${message} This bill page will be reloaded upon completion.`;
      this.$message({ message, type, showClose: true, duration: 10000 });
      this.handleProcessBillEvent({ trackingId, location });
    },
    async handleProcessBillEvent({ trackingId, location }) {
      const checker = async () => {
        const data = await bills.getEventsBatchStatus(trackingId);
        const { total = 0, complete = 0, failed = 0 } = data;
        if (+total === +complete + +failed) {
          clearInterval(handler);
          const queueIndex = R.findIndex(
            R.propEq('trackingId', trackingId),
            this.bulkActionsQueue,
          );
          this.bulkActionsQueue = R.remove(
            queueIndex,
            1,
            this.bulkActionsQueue,
          );
          this.$emit('updateItem', { ...this.item, location });
        }
      };
      const handler = setInterval(checker, 2000);
      this.bulkActionsQueue.push({ trackingId, handler });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../styles/constants.scss';

::v-deep.collapse {
  .header {
    user-select: auto;
  }
}

.op-notes {
  padding-left: 16px;
  vertical-align: middle;
}

.more-actions-button {
  padding: 0;
  color: #ffffff;
}

.item-content-wrapper {
  padding: 0 20px;
}

.match-location {
  color: #2893f9;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;

  a {
    color: inherit;
  }
}

.bill-collapse {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;

  &-block {
    white-space: nowrap;
    align-self: center;

    &-title {
      font-weight: 500;
    }

    &-value {
      font-weight: 600;
      padding-left: 10px;
    }

    &--rate-code {
      overflow: hidden;
      max-width: 200px;
      text-overflow: ellipsis;
    }

    &.frozen-block {
      width: 30px;
      flex-basis: 30px !important;
      flex-grow: 0;
      flex-shrink: 0;

      span {
        display: inline-block;
        padding-top: 4px;
      }
    }

    &.frozen-reason-wrapper {
      .frozen-reason {
        max-width: 10ch;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
  }

  &-container {
    margin: 10px 0px;
  }

  &-content {
    display: flex;

    &-data {
      width: calc(50% - 30px);

      &:nth-child(even) {
        margin-left: 60px;
      }

      .bill-field-container {
        width: 100%;
        display: inline-flex;
        padding-left: 20px;
        box-sizing: border-box;

        &.highlighted:nth-child(even) {
          background-color: $concrete;
        }

        .bill-field-key {
          width: 40%;
          font-size: 12px;
        }

        .bill-field-value {
          width: 50%;
          font-weight: 600;
          font-size: 12px;
          overflow-wrap: break-word;
        }

        .bill-title {
          display: flex;
          width: 100%;
          min-height: 20px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.internal-link {
  color: #2893f9;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  line-height: 20px;
}

.bill-arrow {
  .back {
    transform: rotate(270deg);
  }
}

.irregular-data {
  margin-bottom: 10px;

  &:not(.computed-sublock) {
    //max-width: 80%;
  }

  &[data-section='usage-info'] {
    /*width: calc(50% - 30px);*/
  }

  &-title {
    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    font-size: 14px;
  }

  &-bli {
    margin-bottom: 0;

    .description-value {
      color: #838ea5;
    }

    .irregular-data-title {
      display: flex;
      flex-direction: column;
      margin: 0;
      border-radius: 0;
      box-shadow: none !important;
    }
  }

  &-row {
    display: flex;
    flex-flow: row wrap;
    margin: 2px 0;
    font-size: 12px;
    line-height: 20px;

    &.highlighted:nth-child(even) {
      background-color: $concrete;
    }

    &-key {
      padding-left: 20px;
      white-space: nowrap;
    }

    &-value {
      font-weight: 600;
      box-sizing: border-box;
    }
  }

  &-column {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 2px 0;
    font-size: 12px;
    line-height: 20px;

    &.highlighted:nth-child(even) {
      background-color: $concrete;
    }

    &-key {
      padding-left: 20px;
      white-space: nowrap;
    }

    &-value {
      font-weight: 600;
      box-sizing: border-box;
    }
  }

  &-item {
    width: calc(50% - 31px);
    white-space: nowrap;

    &:nth-child(even) {
      margin-left: 60px;
    }

    &.highlighted {
      > div {
        min-height: 20px;
        padding-left: 20px;
      }
    }

    &.highlighted:nth-child(4n + 1),
    &.highlighted:nth-child(4n + 2) {
      background-color: $concrete;
    }
  }

  &-weather-item {
    width: calc(50% - 31px);
    white-space: nowrap;

    &:nth-child(n + 5) {
      margin-left: 60px;
    }

    &:nth-child(1) {
      order: -1;
    }

    &:nth-child(3) {
      order: -1;
    }

    &.highlighted-weather {
      > div {
        min-height: 20px;
        padding-left: 10px;
      }
    }

    &.highlighted-weather:nth-child(4n + 1),
    &.highlighted-weather:nth-child(2),
    &.highlighted-weather:nth-child(7) {
      background-color: $concrete;
    }
  }
}

.icon-block-arrow {
  background-image: url(../../../../../assets/icons/block-arrow.svg);
}

.divider-wapper {
  margin-top: 20px;
}

.computed {
  &-title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #273a58;
    display: flex;
    align-items: center;

    span {
      padding-right: 8px;
    }
  }

  &-sublock {
    margin: 10px 0 0 0;
  }

  &-subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    span {
      padding-right: 8px;
    }
  }
}

.el-tooltip__popper.is-light.frozen-reason {
  width: 140px;
  padding: 8px;
  margin-left: 8px;
  height: 18px;
}

.el-tooltip {
  margin-left: 6px;
}
</style>

<style lang="scss">
.match-dialog {
  .el-dialog {
    height: 660px;
  }
}

.el-message {
  p {
    justify-content: initial;
    text-align: initial;
  }
}
</style>
