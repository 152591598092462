<template>
  <div class="preview-wrapper">
    <el-row class="summary">
      <el-col class="summary-preview" :span="12">Preview</el-col>
      <el-col :span="12" align="right"
        ><span class="summary-description">Qty of rows:</span
        ><span class="summary-count">{{
          (billingLineItems && billingLineItems.length) || 0
        }}</span
        ><strong>&nbsp;/ {{ total }}</strong></el-col
      >
    </el-row>
    <el-table
      class="billing-line-items-list"
      :data="billingLineItems"
      :header-row-class-name="'table-header'"
      :max-height="400"
    >
      <el-table-column
        v-for="field in reportTemplate.fieldMap"
        :key="field.key"
        :label="field.header"
        :prop="field.key"
        min-width="100"
      >
        <template slot-scope="scope">
          <span>{{ scope.row[field.key] || '—' }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { getBillingLineItems } from '../../../../../api/exports';

export default {
  props: {
    previewOpened: {
      type: Boolean,
      required: false,
      default: false,
    },
    reportTemplate: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeight: 400,
      billingLineItems: [],
      total: 0,
    };
  },
  computed: {},
  watch: {
    previewOpened(value) {
      if (value) {
        this.loadData();
      }
    },
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    locationName(id) {
      if (_.isEmpty(id)) {
        return '—';
      }
      const locations = _.get(this.filters, ['locations'], []);
      let location = _.find(locations, (item) => item.id === id);
      return _.get(location, ['name'], '—');
    },
    async loadData() {
      const body = {
        reportTemplateId: this.reportTemplate.id,
        appliedFilters: {
          ...this.filters,
        },
      };
      const { results, total } = await getBillingLineItems(
        this.$route.params.customerId,
        body,
      );
      this.billingLineItems = results;
      this.total = total;
    },
  },
};
</script>

<style scoped lang="scss">
.summary {
  font-size: 16px;
  margin: -44px 0 14px 0;

  &-preview {
    color: #2c2e34;
    font-weight: 600;
  }

  &-description {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #7d89a6;
    text-align: right;
  }

  &-count {
    padding-left: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: right;
    color: #78797d;

    strong {
      font-weight: 900;
      color: #3b3e48;
    }
  }
}
</style>
