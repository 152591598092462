<template>
  <div class="actions-bar">
    <div class="buttons-container">
      <div
        @click="setOpen(true)"
        class="icon icon-expand clickable"
        :class="{ active: blocksExpanded }"
      ></div>
      <div
        @click="setOpen(false)"
        class="icon icon-collapse clickable"
        :class="{ active: !blocksExpanded }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  methods: {
    setOpen(openState) {
      this.$setBlocksExpanded(openState);
    },
    ...mapMutations({
      $setBlocksExpanded: 'vendors/setBlocksExpanded',
    }),
  },
  computed: {
    ...mapGetters({
      blocksExpanded: 'vendors/blocksExpanded',
    }),
  },
};
</script>

<style lang="scss" scoped>
.actions-bar {
  max-width: 930px;
  padding: 6px 0;
  display: flex;
  justify-content: end;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 7px 13px 0 rgba(197, 197, 197, 0.5);

  .icon {
    &.active {
      color: #267de2;
    }
  }
}
</style>
