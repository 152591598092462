<template lang="pug">
  .page-container.insights-page-container
    .page-header
      .page-header-title Create insight for {{ customer.name }}
    .page-content
      template(v-if='!previewMode')
        .insight-fields
          .insight-field
            label Company
            p.customer-name {{ customer.name }}
          .insight-field.insight-recipients
            label Recipients
            .selected-recipients
              span(v-for='(recipient, index) in selectedRecipients')
                template(v-if='index === 0')
                  | {{ pathOr('', ['profile', 'first_name'], recipient) }} {{ pathOr('', ['profile', 'last_name'], recipient) }}
                template(v-else)
                  |, {{ pathOr('', ['profile', 'first_name'], recipient) }} {{ pathOr('', ['profile', 'last_name'], recipient) }}
              el-button(type='text', @click='openRecipientsModal') Edit Recipients
          .insight-field.insight-message
            label Message
            el-input(v-model='message', type='textarea', :rows='6', :class='{"is-preview": previewMode}', :disabled='previewMode')
          .insight-field
            label
            el-button(type='text', @click='openCreateUrlModal') Create URL
        .insight-actions
          el-button.plain-button-reverse(@click='cancel') Cancel
          el-button(type='primary', @click='previewMode = true', :disabled='!selectedRecipients.length') Preview

      template(v-else)
        .insight-fields
          .insight-field
            label Company
            p.customer-name {{ customer.name }}
          .insight-field.insight-recipients
            label Recipients
            .selected-recipients
              span(v-for='(recipient, index) in selectedRecipients')
                template(v-if='index === 0')
                  | {{ pathOr('', ['profile', 'first_name'], recipient) }} {{ pathOr('', ['profile', 'last_name'], recipient) }}
                template(v-else)
                  |, {{ pathOr('', ['profile', 'first_name'], recipient) }} {{ pathOr('', ['profile', 'last_name'], recipient) }}
          .insight-field.insight-message.preview
            label Message
            span {{ message }}
        .preview-message Do you want to send this insight to the selected recipients?
        .insight-actions
          el-button.plain-button-reverse(@click='previewMode = false') Back
          el-button(type='primary', :disabled='!!newInsight.url', @click='sendInsight') Send

    el-dialog.recipients-modal(title='Choose Recipients', width='624px', :visible.sync='showRecipientsModal')
      .insight-modal-content
        el-table.with-shadow(:data='recipients', @sort-change='sortTable')
          el-table-column(label='ID', prop='id', width='100', sortable)
            template(slot-scope='scope')
              router-link(:to="{name: 'user.info', params: { userId: scope.row.id}}") {{ scope.row.id }}
          el-table-column(label='Recipient', prop='profile.first_name', width='200', sortable)
            template(slot-scope='scope')
              | {{ pathOr('', ['profile', 'first_name'], scope.row) }} {{ pathOr('', ['profile', 'last_name'], scope.row) }}
          el-table-column(label='Notification Methods', width='180')
            template(slot-scope='scope')
              span(v-if='scope.row.profile && scope.row.profile.notifications_settings')
                template(v-if='scope.row.profile.notifications_settings.notifications_email')
                  | Email
                template(v-if='scope.row.profile.notifications_settings.notifications_email && scope.row.profile.notifications_settings.notifications_sms') ,&nbsp;
                template(v-if='scope.row.profile.notifications_settings.notifications_sms')
                  | SMS
          el-table-column(label='Selected')
            template(slot-scope='scope')
              el-checkbox(v-model='scope.row.selected', :disabled="!pathOr(false, ['profile', 'notifications_settings', 'notifications_email'], scope.row)")
        .insight-actions
          el-button.plain-button-reverse(@click='closeRecipientsModal') Cancel
          el-button(type='primary', @click='setRecipients') Done

    el-dialog(:title="`Create URL for ${customer.name}`", width='624px', :visible.sync='showCreateUrlModal')
      url-modal(:url='shortUrl', @create-url='createUrl', @save-message='saveMessage', @close-modal='closeCreateUrlModal')
</template>

<script>
import { insights } from '@/api';
import { mapState } from 'vuex';
import UrlModal from './UrlModal.vue';
import { pathOr } from 'ramda';

export default {
  components: {
    UrlModal,
  },
  data() {
    return {
      showRecipientsModal: false,
      showCreateUrlModal: false,
      previewMode: false,
      newInsight: {},
      shortUrl: '',
      offset: 0,
      limit: 200,
      message: '',
      recipients: [],
      selectedRecipients: [],
      order: '+id',
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer || {},
    }),
  },
  mounted() {
    this.loadRecipients();
  },
  methods: {
    pathOr(defaultValue, path, context) {
      return pathOr(defaultValue, path, context);
    },
    openRecipientsModal() {
      this.showRecipientsModal = true;
    },
    closeRecipientsModal() {
      this.showRecipientsModal = false;
    },
    openCreateUrlModal() {
      this.showCreateUrlModal = true;
    },
    closeCreateUrlModal() {
      this.showCreateUrlModal = false;
    },
    setRecipients() {
      this.selectedRecipients = this.recipients.filter((item) => item.selected);
      this.closeRecipientsModal();
    },
    cancel() {
      this.$router.push({ name: 'customer.insights' });
    },
    sortTable(obj) {
      if (obj.prop && obj.order) {
        if (obj.prop === 'profile.first_name') {
          obj.prop = 'profile->first_name';
        }
        this.order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      } else {
        this.order = '';
      }

      this.loadRecipients();
    },
    async loadRecipients() {
      const { customerId } = this.$route.params;
      const data = await insights.recipients({
        offset: this.offset,
        limit: this.limit,
        active: true,
        orders: [this.order],
        customer_id: customerId,
      });
      this.recipients = [...data.data];
    },
    createUrl(longUrl) {
      const customerId = this.$route.params.customerId;
      insights
        .postUrl(customerId, {
          customer_id: customerId,
          urn: `/${customerId}${longUrl}`,
        })
        .then((res) => (this.shortUrl = res.url));
    },
    saveMessage(silent, user_message) {
      this.newInsight = {
        ...this.newInsight,
        user_message,
        silent,
      };
    },
    sendInsight() {
      this.newInsight = {
        ...this.newInsight,
        customer_id: this.$route.params.customerId,
        message: this.message,
        recipients: this.selectedRecipients.map((item) => item.id),
      };
      insights
        .postInsight(this.$route.params.customerId, this.newInsight)
        .then(() => {
          this.$message({
            message: 'Insight was sent to specified recipients.',
            type: 'success',
          });
        })
        .then(() => this.$router.push({ name: 'customer.insights' }))
        .catch((error) => {
          const code = pathOr(500, ['response', 'data', 'code'], error);
          switch (code) {
            case 422:
              this.$message({
                message: 'Recipients should be active users',
                type: 'error',
              });
              break;
            case 406:
              this.$message({
                message: 'The message is required',
                type: 'error',
              });
              break;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.insight {
  &-fields {
    color: #606266;
    font-size: 14px;
    line-height: 19px;
  }
  &-field {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      min-width: 110px;
    }
    .el-button {
      padding: 0;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  &-recipients {
    label {
      align-self: flex-start;
    }
  }
  &-message {
    label {
      align-self: flex-start;
      margin-top: 8px;
    }
    .el-textarea {
      position: relative;
      left: -14px;
      max-width: 460px;
    }

    &.preview {
      label {
        margin-top: 0;
      }
      span {
        color: #222222;
      }
    }
  }
  &-actions {
    padding-left: 110px;
    margin-top: 30px;

    .el-button {
      width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
.customer-name {
  margin: 0;
  color: #222222;
}
.recipients-modal {
  .insight {
    &-actions {
      padding-left: 0;
      margin-top: 40px;
      text-align: center;

      .el-button {
        width: 170px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 600;

        + .el-button {
          margin-left: 30px;
        }
      }
    }
  }
}
.selected-recipients {
  > span {
    float: left;
    color: #222222;

    + .el-button {
      margin-top: 5px;
      font-size: 12px;
    }
  }
  .el-button {
    float: left;
    clear: left;
  }
}
.preview-message {
  font-weight: 700;
}
</style>

<style lang="scss">
@import '../../../styles/constants.scss';

.recipients-modal {
  a {
    color: #409eff;
  }

  .el-table {
    th:first-child,
    td:first-child {
      .cell {
        padding-left: 40px;
      }
    }
    th.is-sortable {
      padding: 3px 0 2px;
    }
    td {
      padding: 11px 0 10px;
    }
  }
  .el-table__body-wrapper {
    max-height: 320px;
    @extend %scrollbar;
  }
}
</style>
