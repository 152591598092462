import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

const list = (params) =>
  http(hosts.v1).get('/customers', { params }).then(dataMiddleware);

const createCustomer = (customer) =>
  http(hosts.v3).post('/customers', customer).then(dataMiddleware);

const updateCustomer = (id, customer) =>
  http(hosts.v3).put(`/customers/${id}`, customer).then(dataMiddleware);

const get = (id) => http(hosts.v3).get(`customers/${id}`).then(dataMiddleware);

const getEmailDeliveries = (customerId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/settings/payments/email-deliveries`)
    .then(dataMiddleware);

const putEmailDeliveries = (customerId, emailDeliveriesId, body) =>
  http(hosts.v3)
    .put(
      `/customers/${customerId}/settings/payments/email-deliveries/${emailDeliveriesId}`,
      body,
    )
    .then(dataMiddleware);

const postEmailDeliveries = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/settings/payments/email-deliveries`, body)
    .then(dataMiddleware);

const deleteEmailDelivery = (customerId, id) =>
  http(hosts.v3)
    .delete(`/customers/${customerId}/settings/payments/email-deliveries/${id}`)
    .then(dataMiddleware);

const getSFTPDeliveries = (customerId) =>
  http(hosts.v3)
    .get(`/payments/${customerId}/sftp-deliveries`)
    .then(dataMiddleware);

const putSFTPDeliveries = (customerId, sftpDeliveriesId, body) =>
  http(hosts.v3)
    .put(`/payments/${customerId}/sftp-deliveries/${sftpDeliveriesId}`, body)
    .then(dataMiddleware);

const postSFTPDeliveries = (customerId, body) =>
  http(hosts.v3)
    .post(`/payments/${customerId}/sftp-deliveries`, body)
    .then(dataMiddleware);

const deleteSFTPDelivery = (customerId, id) =>
  http(hosts.v3)
    .delete(`/payments/${customerId}/sftp-deliveries/${id}`)
    .then(dataMiddleware);

const getEnergyStarAcc = (customerId) =>
  http(hosts.v3).get(`/customers/${customerId}/energy-star`);

const postEnergyStarAcc = (customerId, body) =>
  http(hosts.v3).post(`/customers/${customerId}/energy-star`, body);

const putEnergyStarAcc = (customerId, body) =>
  http(hosts.v3).put(`/customers/${customerId}/energy-star`, body);

const getPlainPassword = (customerId) =>
  http(hosts.v3).get(`/customers/${customerId}/energy-star/password`);

const getCarbonFootprint = (customerId, body) =>
  http(hosts.v3).get(`/customers/${customerId}/carbon-footprint`, body);

const postCarbonFootprint = (customerId, body) =>
  http(hosts.v3).post(`/customers/${customerId}/carbon-footprint`, body);

const putCarbonFootprint = (customerId, body) =>
  http(hosts.v3).put(`/customers/${customerId}/carbon-footprint`, body);

const fetchCountScheduledReports = (customerId, id) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/sftp-connections/${id}/count-scheduled-reports`,
    )
    .then(dataMiddleware);

const deleteSftpConnections = (customerId, id) =>
  http(hosts.v3).delete(`/customer/${customerId}/sftp-connections/${id}`);

const getChatListForCurrentCustomer = (customerId) => {
  return http(hosts.v3).get(`customers/${customerId}/chat-tags`);
};

const getCustomerVendors = (customerId, body) =>
  http(hosts.v3).get(`/customers/${customerId}/vendors`, { params: body });

const getCustomerVendor = async (customerId, vendorId) =>
  http(hosts.v3).get(`/customers/${customerId}/vendors/${vendorId}`);

const updateCustomAttributes = async (customerId, vendorId, attributes) => {
  return http(hosts.v3).post(
    `/customers/${customerId}/vendors/${vendorId}`,
    attributes,
  );
};

const updateBulk = (customerId, body) =>
  http(hosts.v3).patch(`customers/${customerId}/vendors/pretty-names`, body);

export {
  list,
  get,
  createCustomer,
  updateCustomer,
  getEmailDeliveries,
  putEmailDeliveries,
  postEmailDeliveries,
  deleteEmailDelivery,
  getSFTPDeliveries,
  putSFTPDeliveries,
  postSFTPDeliveries,
  deleteSFTPDelivery,
  getEnergyStarAcc,
  postEnergyStarAcc,
  putEnergyStarAcc,
  getPlainPassword,
  getCarbonFootprint,
  postCarbonFootprint,
  putCarbonFootprint,
  fetchCountScheduledReports,
  deleteSftpConnections,
  getChatListForCurrentCustomer,
  getCustomerVendors,
  getCustomerVendor,
  updateCustomAttributes,
  updateBulk,
};
