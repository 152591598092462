<template lang="pug">
  el-select( :placeholder="placeholder_",
    :size="size",
    :value="selected_",
    @change="onSelectChange",
    @visible-change="onVisibleChange",
    :popper-class="'dropdown-menu'",
    remote,
    :remote-method="remoteMethod",
    :loading="loading",
    filterable)
    el-option(v-if="typeof options_[0] === 'object'" v-for='item in options_',
      :key="item[itemValue]",
      :label="item[itemLabel]",
      :value="item[itemValue]")
      .dropdown-item(:class="{ 'dropdown-item-selected': selected === item[itemLabel]}")
        span {{ item[itemLabel] }}
        router-link.icon.icon-select-link(:to="{name: 'vendorDetails', params: {vendorId: item[itemValue]}}")
    el-option(v-else, :key="item", :value="item", :label="item")
      .dropdown-item(:class="{ 'dropdown-item-selected': selected === item}")
        span {{ item }}
        router-link.icon.icon-select-link(:to="{name: 'vendors', query: {query: item}}")
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'BillEditorSelectVendor',
  props: {
    onSelectChange: { type: Function },
    options: { type: Array },
    itemValue: { type: String, default: () => 'id' },
    itemLabel: { type: String, default: () => 'code' },
    placeholder: { type: String, default: () => 'Select' },
    size: { type: String, default: () => 'mini' },
    selected: { type: String, default: () => '' },
  },
  watch: {
    selected(value) {
      this.selected_ = value;
    },
    placeholder(value) {
      this.placeholder_ = value;
    },
  },
  data() {
    return {
      selected_: this.selected,
      placeholder_: this.placeholder,
      options_: this.options || this.defaultList,
      limit: 10,
      loading: false,
      defaultList: [],
    };
  },
  computed: {
    ...mapGetters({
      $vendorsList: 'vendors/list',
      $meta: 'vendors/meta',
    }),
  },
  mounted() {
    this.loadList();
  },
  methods: {
    ...mapActions({
      $getVendorsList: 'vendors/getList',
    }),
    ...mapMutations({
      $setVendorsFilters: 'vendors/setFilters',
    }),
    onVisibleChange(visible) {
      this.selected_ = visible ? '' : this.selected;
      this.placeholder_ = visible ? 'Search' : this.placeholder;
    },
    openVendor(vendorCode) {
      this.$router.push({ name: 'vendors', query: { query: vendorCode } });
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          const filters = {
            limit: 30,
            offset: 0,
            query,
          };
          this.$setVendorsFilters(filters);
          await this.$getVendorsList();
          this.loading = false;
          this.options_ = this.$vendorsList;
        }, 500);
      } else {
        this.options_ = this.defaultList;
      }
    },
    async loadList() {
      const filters = {
        limit: 30,
        offset: 0,
      };
      this.$setVendorsFilters(filters);
      await this.$getVendorsList();
      this.loading = false;
      this.defaultList = this.$vendorsList;
      this.options_ = this.defaultList;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select-dropdown__item {
  height: 28px;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 29px;
  max-width: 260px;
  padding: 0 20px;
  margin: 0 -20px;
  font-size: 12px;
  border-top: 1px solid #e7e7e7;
  span {
    max-width: 200px;
    overflow: hidden;
  }
  &-selected {
    color: #273a58;
    font-weight: 700;
  }
}
</style>
