import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

const list = (params) =>
  http(hosts.v1).get('/observations', { params }).then(dataMiddleware);

const get = (id) =>
  http(hosts.v1).get(`observations/${id}`).then(dataMiddleware);

export { list, get };
