import * as R from 'ramda';

const emptyBillBlockData = () => [
  {
    description: 'Service Account',
    code: 'ACCOUNTCODE',
    value: '',
  },
  {
    description: 'Billing Start Date',
    code: 'STARTDATE',
    value: '',
  },
  {
    description: 'Billing End Date',
    code: 'ENDDATE',
    value: '',
  },
  {
    description: 'Days of Service',
    code: 'DAYSOFSERVICE',
    value: '',
  },
  {
    description: 'Rate Plan',
    code: 'RATECODE',
    value: '',
  },
  {
    description: 'Service Address',
    code: 'SERVICEADDRESS',
    value: '',
  },
  {
    description: 'Service Postcode',
    code: 'SERVICEADDRESSZIP',
    value: '',
  },
  {
    description: 'Utility',
    code: 'COMMODITY',
    value: '',
  },
  {
    description: 'Vendor Type',
    code: 'BILLTYPE',
    value: '',
  },
  {
    description: 'Meter Serial',
    code: 'METERSERIAL',
    value: '',
  },
  {
    description: 'Vendor Code',
    code: 'VENDORCODE',
    value: '',
  },
  {
    description: 'Control Code',
    code: 'CONTROLCODE',
    value: '',
  },
];

const emptyBillData = () => [
  {
    description: 'Vendor',
    code: 'REMITNAME',
    value: '',
  },
  {
    description: 'Provider Vendor Id',
    code: 'IDVENDOR',
    value: '',
  },
  {
    description: 'Remittance Address Line 1',
    code: 'REMITADDRESS1',
    value: '',
  },
  {
    description: 'Remittance Address Line 2',
    code: 'REMITADDRESS2',
    value: '',
  },
  {
    description: 'Remittance Address Line 3',
    code: 'REMITADDRESS3',
    value: '',
  },
  {
    description: 'Remittance Address Line 4',
    code: 'REMITADDRESS4',
    value: '',
  },
  {
    description: 'Remittance Address City',
    code: 'REMITCITY',
    value: '',
  },
  {
    description: 'Remittance Address State',
    code: 'REMITSTATE',
    value: '',
  },
  {
    description: 'Remittance Address Postcode',
    code: 'REMITZIP',
    value: '',
  },
  {
    description: 'Vendor Primary Billing ID',
    code: 'CLIENTACCOUNT',
    value: '',
  },
  {
    description: 'Bill To Address Line 1',
    code: 'BILLTOADDRESS1',
    value: '',
  },
  {
    description: 'Bill To Address Line 2',
    code: 'BILLTOADDRESS2',
    value: '',
  },
  {
    description: 'Bill To Address Line 3',
    code: 'BILLTOADDRESS3',
    value: '',
  },
  {
    description: 'Bill To Address Line 4',
    code: 'BILLTOADDRESS4',
    value: '',
  },
  {
    description: 'Bill To Address City',
    code: 'BILLTOCITY',
    value: '',
  },
  {
    description: 'Bill To Address State',
    code: 'BILLTOSTATE',
    value: '',
  },
  {
    description: 'Bill To Address Postcode',
    code: 'BILLTOZIP',
    value: '',
  },
  {
    description: 'Date Due',
    code: 'DUEDATE',
    value: '',
  },
  {
    description: 'Invoice Date',
    code: 'STATEMENTDATE',
    value: '',
  },
  {
    description: 'Vendor Code',
    code: 'VENDORCODE',
    value: '',
  },
  {
    description: 'Invoice Number',
    code: 'INVOICENUMBER',
    value: '',
  },
  {
    description: 'Remittance Address Country',
    code: 'REMITCOUNTRY',
    value: '',
  },
  {
    description: 'Bill To Address Country',
    code: 'BILLTOCOUNTRY',
    value: '',
  },
  {
    description: 'Past Due Amount',
    code: 'PRIORBALANCE',
    type: 'C',
    charge: '0.00',
    currency: 'USD',
  },
  {
    description: 'Current Charges',
    code: 'INFO_COST',
    type: 'C',
    charge: '0.00',
    currency: 'USD',
  },
  {
    description: 'Total Amount Due',
    code: 'TOTALPAYAMOUNT',
    type: 'C',
    charge: '0.00',
    currency: 'USD',
  },
];

const emptyBillBlock = (block) =>
  emptyBillBlockData().map((v) => {
    v.block = block;
    return v;
  });

const addDefaultBillBlockObservations = (id, item) => {
  emptyBillBlockData().forEach((o) => {
    const index = R.findIndex(R.propEq('code', o.code))(item);
    if (index === -1) {
      o.block = id;
      item.push(o);
    }
  });
  return item;
};

const isDefaultBillBlockData = (observation) => {
  const { code } = observation;
  return R.any(R.propEq('code', code))(emptyBillBlockData());
};

const isDefaultBillData = (observation) => {
  const { code } = observation;
  return R.any(R.propEq('code', code))(emptyBillData());
};

const addDefaultValues = (data) => {
  const defaultFields = {
    C: ['charge'],
    U: ['usage'],
    UC: ['charge', 'usage'],
  };
  const defaultValue = '0.00';

  const addDefault = (row) => {
    const { type } = row;
    if (R.has('type')(row)) {
      R.forEach((key) => {
        if (R.either(R.isEmpty, R.isNil)(row[key])) {
          row[key] = defaultValue;
        }
      })(defaultFields[type]);
    }
    return row;
  };
  return R.map(addDefault)(data);
};

export {
  emptyBillBlock,
  emptyBillBlockData,
  emptyBillData,
  addDefaultBillBlockObservations,
  isDefaultBillBlockData,
  isDefaultBillData,
  addDefaultValues,
};
