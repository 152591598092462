import { reports } from '../../api';
const initialState = () => {
  return {
    templates: [],
    params: { limit: 1000, exclude: ['total', 'extra', 'filters'] },
    loading: true,
  };
};

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    templates: (state) => state.templates.results && state.templates.results,
    loading: (state) => state.loading,
  },

  mutations: {
    setCustomerReportTemplates(state, payload) {
      state.templates = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setTemplateAvailableValues(state, payload) {
      state.templates.results = state.templates.results.map(template => {
        if (template.id === payload.id) {
          template.availableFilters = [ ...payload.availableFilters ]
        }
        return template;
      })
    },
    resetState: (state) => {
      const initState = initialState();
      Object.assign(state, initState);
    },
  },
  actions: {
    async getCustomerReportTemplates({ commit, state }, { customerId }) {
      try {
        commit('setLoading', true);
        const templates = await reports.getCustomerReportTemplates(
          customerId,
          state.params,
        );
        commit('setCustomerReportTemplates', templates);
        commit('setLoading', false);
      } catch (e) {
        console.log(e);
        commit('setLoading', false);
      }
    },
    async getTemplateAvailableValues({ commit, state }, { customerId, templateId }) {
      try {
        const templateAvailableValues = await reports.getTemplatesAvailableValues(templateId, customerId);
        commit('setTemplateAvailableValues', templateAvailableValues.data)
      } catch (e) {
        console.log(e);
        commit('setLoading', false);
      }
    }
  },
};
