<template lang="pug">
  main-layout
    .page.page-vendors
      .page-header
        h1.page-title(v-if="!editPretty") Vendors
        h1.page-title(v-if="editPretty") Update Blank Pretty Names
        .button-update(v-if="!editPretty  && total > 0", @click="setEditMode", data-testid='editPrettyNamesBtn') Update Pretty Names
        div(v-if="editPretty")
          el-button.button(plain, size='small', @click='cancel', data-testid='cancelEditBtn') Cancel
          el-button.button(type='primary', size='small', @click='update', :disabled="errorsExist", data-testid='updatePrettyNamesBtn') Update
          .info {{ offset + 1 }}-{{ offsetTo }} of {{ total }}
      vendor-filters(v-if="!editPretty", @onChange="onFiltersChange", :init-filters="this.filters")
      .page-content
        .table-wrapper(:key="tableKey")
          el-table.with-shadow.clickable(:data="vendors",
            v-loading.body.lock='isLoading',
            @row-click='goTo',
            :style="{ width: '100%' }",
            :default-sort = "editPretty ? null : {prop: sortProp, order: sortOrder}",
            @sort-change='sortTable',
            data-testid='vendorsTable')
            el-table-column(prop="id", label="ID", width="80", :sortable="sortable")
              template(slot-scope="scope")
                span(data-testid='vendorIdCol') {{ scope.row.id }}

            el-table-column(prop="code", label="Code", width="500", :sortable="sortable")
              template(slot-scope="scope")
                span(data-testid='vendorCodeCol') {{ scope.row.code }}

            el-table-column(prop="prettyName", label="Pretty Name", :sortable="sortable")
              template(slot-scope="scope")
                span.wrap-pretty-name(v-if="!editPretty", data-testid='vendorPrettyNameCol') {{ scope.row.prettyName }}
                .input-container(v-else)
                  .input-wrapper
                    .tip-wrapper(v-if="validationErrors[scope.row.id]")
                      .tip-inner
                        .tip-container
                          span {{ validationErrors[scope.row.id] }}
                        .icon-wrapper
                          i.icon-question
                    el-input.input-pretty-vendor(:size="'mini'",
                      :class='{ "has-error": validationErrors[scope.row.id]}',
                      :value="scope.row.prettyName",
                      @input="changePretty($event, scope.$index)"
                      data-testid="prettyNameInp")
                  .input-length(data-testid="prettyNameLength") {{ scope.row.prettyName && scope.row.prettyName.length || 0 }}

        el-pagination.paginator-wrapper(v-if="!editPretty",
          background,
          layout='prev, pager, next, ->, sizes',
          :page-size='limit',
          :current-page='currentPage',
          @current-change='changeOffset',
          @size-change='changeLimit',
          :total='total')
          .pager-description Items per page
</template>

<script>
import { Main } from '@/components/layouts';
import VendorFilters from './Filters/Filters.vue';
import _ from 'lodash';
import { addGetParam, addGetParams } from '@/lib/route';
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  components: {
    mainLayout: Main,
    VendorFilters,
  },
  data() {
    return {
      filters: {
        query: this.$route.query.query || '',
        blankPrettyName: this.$route.query.blankPrettyName === 'true',
      },
      limit: Number(this.$route.query.limit) || 20,
      offset: Number(this.$route.query.offset) || 0,
      total: 0,
      order: this.$route.query.orderBy || 'id.asc',
      isLoading: true,
      editPretty: false,
      vendorsCopy: [],
      validationErrors: {},
      tableKey: 0,
    };
  },
  mounted() {
    addGetParam('orderBy', this.order);
    this.loadList();
  },
  computed: {
    ...mapGetters({
      $vendors: 'vendors/list',
      $meta: 'vendors/meta',
    }),
    errorsExist() {
      return !_.isEmpty(this.validationErrors);
    },
    sortable() {
      return this.editPretty ? false : 'custom';
    },
    offsetTo() {
      const offsetTo = this.offset + this.limit;
      return offsetTo < this.total ? offsetTo : this.total;
    },
    currentPage() {
      return this.offset / this.limit + 1;
    },
    sortOrder() {
      const order = this.order.split('.');
      if (order[1] === 'desc') {
        return 'descending';
      } else if (order[1] === 'asc') {
        return 'ascending';
      }
    },
    sortProp() {
      if (this.order.length > 0) {
        return this.order.split('.')[0];
      }
    },
    vendors: {
      get() {
        if (this.editPretty) {
          return this.vendorsCopy;
        }
        return this.$vendors;
      },
      set(value) {
        this.vendorsCopy = value;
      },
    },
  },
  methods: {
    ...mapActions({
      $getList: 'vendors/getList',
      $updateVendorsBulk: 'vendors/updateVendorsBulk',
      $validatePrettyName: 'vendors/validatePrettyName',
    }),
    ...mapMutations({
      $setFilters: 'vendors/setFilters',
    }),
    setEditMode() {
      const copy = _.cloneDeep(this.vendors);
      copy.forEach((row) => {
        row.prettyName = _.has(row, ['prettyName']) ? row.prettyName : '';
      });
      this.vendorsCopy = copy;
      this.editPretty = true;
    },
    cancel() {
      this.vendors = _.cloneDeep(this.vendorsCopy);
      this.editPretty = false;
      this.tableKey++;
    },
    update() {
      this.$updateVendorsBulk(
        this.vendorsCopy.map((v) => ({
          ...v,
          prettyName: v.prettyName ? v.prettyName.trim() : null,
        })),
      );
      this.editPretty = false;
    },
    sortTable(obj) {
      if (obj.prop && obj.order) {
        this.order = `${obj.prop}${
          obj.order === 'descending' ? '.desc' : '.asc'
        }`;
      } else {
        this.order = '';
      }
      addGetParam('orderBy', this.order);
      this.loadList();
    },
    changeOffset(e) {
      this.offset = (e - 1) * this.limit;
      addGetParam('offset', this.offset);
      this.loadList();
    },
    changeLimit(e) {
      this.limit = e;
      addGetParam('limit', this.limit);
      this.loadList();
    },
    onFiltersChange(filters) {
      this.offset = 0;
      addGetParam('offset', this.offset);
      this.filters = filters;
      addGetParams(filters);
      this.loadList();
    },
    changePretty(prettyName, index) {
      this.vendorsCopy[index].prettyName = prettyName;
      this.onChangePretty(prettyName, this.vendors[index].id);
    },
    onChangePretty: _.debounce(async function (prettyName, id) {
      try {
        if (
          prettyName.length &&
          this.vendorsCopy.find(
            (v) => v.prettyName === prettyName && v.id !== id,
          )
        ) {
          this.validationErrors = {
            ...this.validationErrors,
            [id]: 'Vendor pretty name should be unique!',
          };
        } else {
          await this.$validatePrettyName({ id, prettyName });
          this.validationErrors = _.omit(this.validationErrors, [id]);
        }
      } catch (e) {
        const error = _.get(e, 'response.data', {});
        if (error.code === 406) {
          this.validationErrors = {
            ...this.validationErrors,
            [id]: JSON.parse(error.message).message.replace(/"/g, ''),
          };
        }
      }
    }, 500),
    async loadList() {
      this.isLoading = true;
      const filters = {
        limit: this.limit,
        offset: this.offset,
        ...(this.filters.query.length && { query: this.filters.query }),
        ...(this.filters.blankPrettyName && {
          blankPrettyName: this.filters.blankPrettyName,
        }),
      };
      if (!_.isEmpty(this.order)) {
        filters.orderBy = [this.order];
      }
      this.$setFilters(filters);
      await this.$getList();
      this.total = Number(this.$meta.total);
      this.isLoading = false;
    },
    goTo({ id = 0 }) {
      if (!this.editPretty) {
        this.$router.push(`/vendors/${id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  /deep/ .el-table__body-wrapper {
    overflow: visible;
  }

  /deep/ .el-table tr,
  .el-table .cell {
    overflow: visible;
  }

  /deep/ .el-table .cell {
    overflow: visible;
  }

  .input-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .input-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      flex-grow: 1;

      .el-input.has-error {
        /deep/ .el-input__inner {
          border: 1px solid red;
          background: rgba(255, 0, 0, 0.1);
        }
      }

      .tip-wrapper {
        z-index: 99;
        position: absolute;
        top: 4px;
        right: 20px;
        height: 20px;
        width: 20px;
        .tip-inner {
          position: relative;
          .tip-container {
            display: none;
            white-space: nowrap;
            position: absolute;
            top: -50px;
            right: 0;
            padding: 9px 13px;
            font-size: 13px;
            line-height: 18px;
            color: #f86259;
            border: 1px solid #bcb9b9;
            border-radius: 6px;
            background-color: #ffffff;
            box-shadow: 0 3px 6px 0 rgba(179, 179, 179, 0.5);
          }
          .icon-wrapper {
            i {
              display: block;
            }
          }
        }

        &:hover {
          .tip-inner .tip-container {
            display: block;
          }
        }
      }
    }

    .input-pretty-vendor {
      padding-right: 15px;
    }

    .input-length {
      width: 40px;
      padding-right: 10px;
    }
  }

  /deep/ .el-table th > .cell {
    height: 34px;
    line-height: 34px;
  }

  .info {
    margin: 10px 0 0 0;
    text-align: right;
    color: #696969;
    font-size: 16px;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .button {
    width: 170px;
    padding: 8px 15px;
  }

  .button-update {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 900;
    text-decoration: underline;
    color: #409eff;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: #66b1ff;
    }
  }

  &-vendors {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 80px);
    padding: 20px 40px 60px 40px;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;

    .header-button {
      height: 40px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      margin-left: auto;
      border: none;
      border-radius: 4px;
      background-color: #1989fa;
    }
  }

  &-title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
  }

  &-search {
    position: relative;
    margin-bottom: 10px;

    .icon-search {
      width: 40px;
      height: 40px;
      position: absolute;
      // TODO added search icon
      background: url(../../assets/icons/search.svg) center center no-repeat;
      z-index: 1;
    }

    &-input {
      > input {
        border: none;

        /deep/ &.el-input__inner {
          padding: 0 44px;
        }
      }
    }
  }
}
.wrap-pretty-name {
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
</style>
