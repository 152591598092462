import Observations from './Observations';

export default {
  path: '/observations',
  component: {
    template: '<router-view></router-view>',
  },
  meta: {
    showBreadcrumbs: true,
    breadcrumb: {
      titleTemplate: 'Observations',
      linkTemplate: '/observations',
    },
  },
  children: [
    {
      path: '',
      component: Observations,
      name: 'observations',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
  ],
};
