import { Unauthorized } from '@/components/layouts';
import Login from './Login';

export default {
  path: '/auth',
  component: {
    template: `<unauthorized>
      <router-view></router-view>
    </unauthorized>`,
    components: {
      Unauthorized,
    },
  },
  meta: {
    auth: false,
  },
  children: [
    {
      path: 'login',
      component: Login,
      alias: '',
      meta: {
        auth: false,
      },
    },
    // {
    //   path: 'password/forgot',
    //   component: Forgot,
    //   meta: { auth: false }
    // },
    // {
    //   path: 'reset/:token',
    //   component: ResetPassword,
    //   meta: { auth: false }
    // }
  ],
};
