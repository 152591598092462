<template lang="pug">
  filter-input(placeholder="Search",
    :searchQuery="filters.query",
    @onSearchUpdate="onQueryChange",
    data-testid="searchInp")
    .list-filter
      .list-filter-item
        .list-filter-title Show only vendors with blank Pretty Names
        .list-filter-parameter
          el-switch(v-model="filters.blankPrettyName", @change="onPrettyChange", data-testid="showOnlyWithPrettyBtn")
</template>

<script>
import { FilterInput } from '@/components';
import _ from 'lodash';

export default {
  name: 'VendorsFilters',
  components: {
    FilterInput,
  },
  props: {
    initFilters: { type: Object },
  },
  data() {
    return {
      filters: {
        query: '',
        blankPrettyName: false,
      },
    };
  },
  beforeMount() {
    Object.assign(this.filters, this.initFilters);
  },
  methods: {
    onQueryChange(query) {
      this.filters.query = query;
      this.onFiltersChange();
    },
    onPrettyChange(flag) {
      this.filters.blankPrettyName = flag;
      this.onFiltersChange();
    },
    onFiltersChange: _.debounce(function () {
      this.$emit('onChange', _.clone(this.filters));
    }, 700),
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  .el-range__close-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-item {
    display: flex;
    align-items: center;
  }

  &-title {
    padding-right: 17px;
    margin: 0;
    color: #606266;
  }
}
</style>
