<template lang="pug">
  .page-container.insights-page-container
    .page-header
      .page-header-title Insights for {{ customer.name }}
      .page-header-actions
        el-button.create-insight-button(type='text', @click='createInsight') + Create Insight

    el-table.insights-list.with-shadow(:data='insights',
                        @sort-change='sortTable',
                        @row-click='openInsightStatusModal')
      el-table-column(prop='id', label='ID', width='80', sortable="custom")
      el-table-column(label='Recipients')
        template(slot-scope='scope')
          span(v-for='(recipient, index) in scope.row.recipients', :key='index')
            template(v-if='index === 0')
              | {{ recipient.first_name }} {{ recipient.last_name }}
            template(v-else)
              |, {{ recipient.first_name }} {{ recipient.last_name }}
      el-table-column(prop='created_at', label='Sent', width='180', sortable="custom")
      el-table-column(prop='message', label='Message')

    el-pagination.paginator-wrapper(background,
      v-if='total > limit || limit > 20',
      layout='prev, pager, next, ->, sizes',
      :page-size='limit',
      :current-page='currentPage',
      @current-change='changeOffset',
      @size-change='changeLimit',
      :total='total')

    el-dialog.insight-status-modal(title='Insight Status', width='624px', :visible.sync='showInsightStatusModal')
      insight-status-modal(:row-id='insightStatusRowId', @close-modal='closeInsightStatusModal')
</template>

<script>
import { insights } from '@/api';
import { mapState } from 'vuex';
import InsightStatusModal from './InsightStatusModal.vue';

export default {
  components: {
    InsightStatusModal,
  },
  data() {
    return {
      showInsightStatusModal: false,
      total: 0,
      limit: 20,
      offset: 0,
      insights: [],
      insightStatusRowId: null,
      order: '+id',
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer || {},
    }),
    currentPage() {
      return this.offset / this.limit + 1;
    },
  },
  mounted() {
    this.loadList();
  },
  methods: {
    createInsight() {
      this.$router.push({ name: 'customer.insight.create' });
    },
    changeOffset(e) {
      this.offset = (e - 1) * this.limit;
      this.loadList();
    },
    changeLimit(e) {
      this.limit = e;
      this.loadList();
    },
    openInsightStatusModal(row) {
      this.insightStatusRowId = row.id;
      this.showInsightStatusModal = true;
    },
    closeInsightStatusModal() {
      this.showInsightStatusModal = false;
    },
    sortTable(obj) {
      if (obj.prop && obj.order) {
        this.order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      } else {
        this.order = '';
      }

      this.loadList();
    },
    async loadList() {
      const { data, meta } = await insights.list(
        this.$route.params.customerId,
        {
          limit: this.limit,
          offset: this.offset,
          orders: [this.order],
        },
      );

      this.insights = [...data];

      const {
        content_range: { total = 0 },
      } = { ...meta };
      this.total = Number(total);
    },
  },
};
</script>

<style lang="scss" scoped>
.create-insight-button {
  padding: 0;
  color: #2893f9;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
</style>

<style lang="scss">
.insights-list {
  .el-table__body-wrapper {
    .el-table__row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.insight-status-modal {
  .el-dialog__body {
    padding: 30px 36px 40px 40px;
  }
}
</style>
