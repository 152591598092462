<template lang="pug">
.collapse
  .collapse-header.header(@mousedown="startMouseWatch", :class='{"header-bottom": !state}')
    span.header-icon
      i.icon(:class='{"back": !state, [arrowClass]: true}')
    span.header-title
      slot(name='title')
        span {{ title }}
  .content(v-show="state")
    slot
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => '',
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
    isCollapsed: {
      type: Object,
      default: () => null,
    },
    arrowClass: {
      type: String,
      default: 'icon-arrow',
    },
  },
  beforeMount() {
    if (this.isCollapsed) {
      this.state = !this.isCollapsed.isCollapsed;
    } else {
      this.state = this.open;
    }
  },
  data() {
    return {
      mouseStartPos: null,
      mouseCurrentPos: null,
      state: true,
    };
  },
  watch: {
    state: {
      handler(value) {
        this.$emit('onCollapse', value);
      },
    },
    isCollapsed(val) {
      this.state = !val.isCollapsed;
    },
  },
  methods: {
    startMouseWatch(event) {
      if (event.which !== 1) {
        // we only listen for left clicks
        return;
      }
      // we only want to click buttons if there are any
      // so we stop if we find a button and it's above our header
      const buttonIndex = event.path.findIndex((element) => {
        return element.className && element.nodeName.includes('BUTTON');
      });
      const skipCollapseElementIndex = event.path.findIndex((element) => {
        return element.className && element.className.includes('skip-collapse');
      });
      if (buttonIndex >= 0 || skipCollapseElementIndex >= 0) {
        const headerIndex = event.path.findIndex((element) => {
          return (
            element.className &&
            element.className.includes('collapse-header header')
          );
        });
        if (
          (buttonIndex >= 0 && buttonIndex < headerIndex) ||
          (skipCollapseElementIndex >= 0 &&
            skipCollapseElementIndex < headerIndex)
        ) {
          return;
        }
      }
      // when the user clicks the header we start watching the mouse to determine weather it is selecting text or
      // it is just clicking
      this.mouseStartPos = null;
      this.mouseCurrentPos = null;
      document.addEventListener('mouseup', this.onMouseUp);
      document.addEventListener('mousemove', this.onMouseMove);
    },
    onMouseMove(event) {
      if (!this.mouseStartPos) {
        this.mouseStartPos = {
          x: event.clientX,
          y: event.clientY,
        };
      }
      this.mouseCurrentPos = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    onMouseUp() {
      // if the cursor moved less than 10 pixels
      // we consider that a click, otherwise we consider the user was selecting text or miss-clicked
      let dist = 0;
      if (this.mouseStartPos) {
        const mouseX = this.mouseCurrentPos.x - this.mouseStartPos.x;
        const mouseY = this.mouseCurrentPos.y - this.mouseStartPos.y;
        dist = Math.sqrt(mouseX * mouseX + mouseY * mouseY);
      }
      if (dist < 10) {
        this.collapse();
      }
      document.removeEventListener('mouseup', this.onMouseUp);
      document.removeEventListener('mousemove', this.onMouseMove);
    },
    collapse(state = !this.state) {
      if (this.clickable) {
        this.state = state;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/constants.scss';

.collapse {
  box-shadow: 0 2px 6px 3px rgba(225, 223, 223, 0.65);
  border-radius: 0px 0px 5px 5px;

  .icon {
    width: 7px;
  }
  .header {
    display: flex;
    align-items: center;
    background-color: #273a58;
    height: 31px;
    padding: 7px 11px;
    cursor: pointer;
    user-select: none;
    color: #ffffff;
    border-radius: 5px 5px 0px 0px;
    &-icon {
      pointer-events: none;
      width: 17px;
      .back {
        transform: rotate(270deg);
      }
    }
    &-title {
      font-weight: 600;
      width: 100%;
    }
    &-bottom {
      border-radius: 5px;
    }
  }

  .content {
    border-top: none;
    padding: 10px 20px;
    background-color: #ffffff;
  }
}
</style>
