<template>
  <div class="freeze-content skip-collapse">
    <span>{{ title }}</span>
    <el-tooltip placement="bottom" effect="light" popper-class="freeze-info">
      <template slot="content">
        Switch on in order to stop this bill and all following bills from
        automatically entering the payment file. Switch off to allow regular
        workflow.
      </template>
      <i class="icon icon-info-circle"></i>
    </el-tooltip>
    <span>:</span>
    <el-switch
      :disabled="disabled"
      v-model="vaFrozenComputed"
      @change="onFreezeChange"
      data-testid="freezeAccountSwitch"
    ></el-switch>
    <tag-select
      :options="options"
      :initialValue="vaFrozenReason"
      :disabled="!vaFrozenComputed"
      @updatedValue="updatedFreezeReason($event)"
    ></tag-select>
  </div>
</template>

<script>
import TagSelect from '@/components/ExtendedFormComponents/TagSelect.vue'

export default {
  name: 'FreezeVirtualAccount',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    freezeReasons: {
      type: Array,
      default: () => [],
    },
    vaFrozenReason: {
      type: Number,
      default: 1,
    },
    vaFrozen: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    TagSelect,
  },
  data() {
    return {
      freezeVirtualAccount: false,
      freezeVAReason: 0,
      options: [],
    }
  },
  async mounted() {
    this.freezeVirtualAccount = this.vaFrozen || false
    this.freezeVAReason = this.vaFrozenReason || 1
    this.options = this.freezeReasons
  },
  computed: {
    vaFrozenComputed: {
      get: function() {
        return !this.disabled && this.freezeVirtualAccount
      },
      set: function(value) {
        this.freezeVirtualAccount = value
        this.emitFreezeVAChanged(value)
      },
    },
    defaultFreezeReason() {
      const defaultReason = this.freezeReasons.find(reason => reason.isDefault)
      return defaultReason ? defaultReason.id : 0
    },
  },
  watch: {
    vaFrozen: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.freezeVirtualAccount = newValue
        if (!newValue) {
          this.reasonChanged(this.defaultFreezeReason)
        }
      },
    },
    vaFrozenReason: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.reasonChanged(newValue)
      },
    },
    freezeReasons: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.options = newValue
      },
    },
  },
  methods: {
    // this is necessary for the tag-select to update
    reasonChanged(newValue) {
      this.freezeVAReason = newValue
      this.options = JSON.parse(JSON.stringify(this.freezeReasons))
    },
    onFreezeChange() {
      if (this.vaFrozenComputed === false) {
        this.reasonChanged(this.defaultFreezeReason)
      }
      this.emitFreezeVAChanged(this.freezeVirtualAccount)
    },
    updatedFreezeReason(reason) {
      this.freezeVAReason = reason.id
      this.emitFreezeVAChanged(this.freezeVirtualAccount)
    },
    emitFreezeVAChanged(value) {
      this.$emit('freezeVAChanged', {
        reasonId: this.freezeVAReason,
        freezeVA: value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.freeze-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 340px;
  span {
    font-weight: normal;
  }

  .icon {
    margin-right: 0px;
  }

  .el-switch {
    margin: 0px 8px;
  }

  .tag-select {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      pointer-events: auto;

      * {
        pointer-events: none;
      }
    }
  }
}

.el-tooltip__popper.is-light.freeze-info {
  width: 40px;
}
</style>
