<template lang="pug">
  .location-control_container
    .location-control_search
      div(class="el-input el-input--prefix")
        input(v-model="query", type="text", autocomplete="off", placeholder="Search", class="el-input__inner")
        span(class="el-input__prefix")
          i.icon.icon-search
    .location-control_list
      el-button.location-control_list-item(v-for="(location, index) in filteredLocations",
        :class='{"is-active": checkSelection(location.id)}',
        :key="index",
        type="primary",
        plain,
        @click="handleSelection(location)")
        span.location-address {{ location.name }}
        span.location-state {{ address(location) }}
        span.location-state {{ zip(location) }}
</template>

<script>
import { pathOr } from 'ramda';

export default {
  props: ['locations', 'storedLocation'],
  data() {
    return {
      query: '',
      selectedLocation: {},
    };
  },
  mounted() {
    this.selectedLocation = this.storedLocation;
  },
  watch: {
    storedLocation() {
      this.selectedLocation = this.storedLocation;
    },
    query() {
      this.handleSelection({});
    },
  },
  computed: {
    filteredLocations() {
      const hasStr = (location) => {
        const { name } = location;
        const extraInfo = this.address(location) + this.zip(location);
        const fullRemit = [name, extraInfo].join(' ').toLowerCase();
        return fullRemit.includes(this.query.toLowerCase().trim());
      };
      return this.locations.filter(hasStr);
    },
  },
  methods: {
    checkSelection(locationId) {
      return pathOr(null, ['id'], this.selectedLocation) === locationId;
    },
    handleSelection(location) {
      this.selectedLocation = location;
      this.$emit('select-locations', this.selectedLocation);
    },
    address(location) {
      const { line_1, line_2, line_3, line_4 } = pathOr(
        {},
        ['remittance_address'],
        location,
      );
      return `${line_1 || line_2 || line_3 || line_4}`;
    },
    zip(location) {
      const { city, post_code, state } = pathOr(
        {},
        ['remittance_address'],
        location,
      );
      return `${city}, ${state}, ${post_code}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.location-control_search {
  padding: 0 20px 20px;
  .el-input__prefix {
    line-height: 38px;
  }

  /deep/ .el-input__icon {
    color: #909399;
  }
}

.location-control_list {
  padding: 0 16px 20px 20px;
  max-height: 58vh;
  -webkit-overflow-scrolling: touch;

  .el-button {
    display: flex;
    padding: 0;
    border-radius: 5px;
    border-color: #2893f9;

    /deep/ #{'>'} span {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      text-align: left;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 28px;
    }

    &:hover {
      > span > span {
        color: #fff;
      }
    }

    &.is-active {
      color: #ffffff;
      background-color: #0080ff;

      .location-address,
      .location-state {
        color: inherit;
      }
    }
  }
}

.location-control_list-item {
  display: block;
  width: 100%;
  white-space: initial;
  min-height: 76px;
  background-color: transparent;
  box-sizing: border-box;

  + .location-control_list-item {
    margin: 10px 0 0;
  }
}

.location-address {
  font-weight: 900;
  line-height: 19px;
  color: #474747;
}

.location-state {
  margin-top: 2px;
  font-weight: 500;
  line-height: 19px;
  color: #474747;
}
</style>
