import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';
import qs from 'qs';

const list = (customerId, locationId, virtualAccountGroupId) =>
  http(hosts.v1)
    .get(`customers/${customerId}/locations/${locationId}/virtual_accounts`, {
      params: { virtualAccountGroupId },
    })
    .then(dataMiddleware);

const editVirtualAccount = (virtual_account_id, params) =>
  http(hosts.v3)
    .put(`virtual-accounts/${virtual_account_id}`, params)
    .then(dataMiddleware);

const listGroups = (customerId, locationId, params) =>
  http(hosts.v3)
    .get(
      `customers/${customerId}/locations/${locationId}/virtual-account-groups`,
      { params },
    )
    .then(dataMiddleware);

const linkItems = (customerId, locationId, virtualAccountGroupId, body) =>
  http(hosts.v1)
    .patch(
      `customers/${customerId}/locations/${locationId}/virtual_account_groups/${virtualAccountGroupId}`,
      body,
    )
    .then(dataMiddleware);

const getAccountHistory = ({ customer_id, virtual_account_id, params }) => {
  return http(hosts.v1)
    .get(
      `/customers/${customer_id}/accounts/${virtual_account_id}/history?${qs.stringify(
        params,
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(dataMiddleware);
};

const freezeReasons = () =>
  http(hosts.v3).get(`/virtual-accounts/freeze-reasons`).then(dataMiddleware);

export {
  list,
  listGroups,
  linkItems,
  getAccountHistory,
  editVirtualAccount,
  freezeReasons,
};
