<template>
  <div>
    <el-date-picker
      v-if="editMode"
      class="full-width"
      placeholder="N/A"
      type="date"
      v-model="value"
      format="MM-dd-yyyy"
      @input="handleInput"
    />
    <span v-else>
      <span v-if="value"> {{ value }}</span>
      <span v-else>N/A</span>
    </span>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: {
    initialValue: String,
    editMode: Boolean,
  },
  data() {
    return {
      value: '',
    };
  },
  mounted() {
    this.value = this.parseDate(this.initialValue);
  },
  watch: {
    initialValue() {
      this.value = this.parseDate(this.initialValue);
    },
    editMode() {
      this.value = this.parseDate(this.initialValue);
    },
  },
  methods: {
    handleInput() {
      this.$emit('input-date', this.value);
    },
    parseDate(date) {
      if (date === null || date === '') {
        return '';
      }
      return DateTime.fromJSDate(new Date(date)).toFormat('MM-dd-yyyy', {
        zone: 'utc',
      });
    },
  },
};
</script>

<style scss scoped>
@import './style.scss';
</style>
