<template>
  <base-modal
    title="Delete SFTP Confirmation"
    :dialogVisible="showDeleteSftpConnModal"
    @open="open"
    @close="close"
    width="480px"
  >
    <template #content>
      <div>
        <p class="text">
          This SFTP Location is linked to
          <strong>[{{ number }}]</strong> Scheduled Reports. By deleting this
          location those reports will no longer be sent to any SFTP location
          until they are reconfigured by the user.
        </p>
        <p class="text">Are you sure you want to delete this SFTP Location ?</p>
      </div>
    </template>
    <template #footer>
      <el-button class="btn plain-button-reverse" @click="close">No</el-button>
      <el-button class="btn" type="primary" @click="deleteSftpConnection"
        >Yes</el-button
      >
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '../../../components/BaseModal';
import { customers } from '@/api';
import _ from 'lodash';

export default {
  components: { BaseModal },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    sftpConnectionId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      showDeleteSftpConnModal: false,
      number: 0,
      customerId: this.$route.params.customerId,
    };
  },
  watch: {
    dialogVisible(val) {
      this.showDeleteSftpConnModal = val;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    open() {
      if (!_.isNil(this.sftpConnectionId)) this.fetchCountScheduledReports();
    },
    async fetchCountScheduledReports() {
      try {
        const data = await customers.fetchCountScheduledReports(
          this.customerId,
          this.sftpConnectionId,
        );
        this.number = data.scheduledReports;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteSftpConnection() {
      try {
        await customers.deleteSftpConnections(
          this.customerId,
          this.sftpConnectionId,
        );
        this.$emit('delete-successful');
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #273a58;
}
.btn {
  width: 120px;
}
</style>
