<template>
  <BaseFormComponent
    :vid="vid"
    :name="$attrs.name"
    :isEditMode="isEditMode"
    :label="label"
    :rules="rules"
    :labelWidth="labelWidth"
  >
    <span v-if="isEditMode" class="form-item">
      <span v-if="required" class="required-star">*</span>
      <el-date-picker
        v-model="innerValue"
        :type="type"
        :size="$attrs.size"
        :disabled="$attrs.disabled"
        :format="format"
        value-format="yyyy-MM-dd"
        v-on="$listeners"
        :placeholder="placeholder"
      >
      </el-date-picker>
    </span>
    <span v-else>{{ innerValue || message }}</span>
  </BaseFormComponent>
</template>

<script>
import BaseFormComponent from './BaseFormComponent';

export default {
  components: {
    BaseFormComponent,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'date',
    },
    format: {
      type: String,
      default: 'MM/dd/yyyy',
    },
    placeholder: {
      type: String,
      default: 'Pick a day',
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
      default: '125px',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
<style style="scss">
@import '../../styles/_formcomponent.scss';
</style>
