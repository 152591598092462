<template lang="pug">
  .page-container.insights-page-container
    .page-header
      .page-header-title Sources for bill {{this.$route.params.billId}}
      .page-header-actions
    el-button(@click="download" type="primary") Download Sources

    form(@submit="upload")
      .input-wrapper
        label Submit one or more csv files:
        .input-container
          ul(v-if="files.length")
            li(v-for="file in files") {{ file.name }}
          div.input-field {{ (files.length && "") || `Please, browse your CSV files...` }}
          input(type="file", :v-model="files", @change="handleFileChange", ref="files-input", multiple, accept=".csv")
          .error-container
            span(v-if="uploadError") {{ uploadError }}
      el-button(@click="upload", :disabled="!allowUpload", type="primary") Submit
</template>

<script>
import _ from 'lodash';
import JSZip from 'jszip';
import { bills } from '@/api';
import assert from 'assert';

const captureFileBytes = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(new Uint8Array(this.result));
    };
    reader.readAsArrayBuffer(data);
  });

export default {
  name: 'Source',
  data() {
    return {
      bill: null,
      downloading: false,
      uploading: false,
      files: [],
      uploadError: false,
      allowUpload: false,
    };
  },
  async mounted() {
    const { billId = 0, customerId } = this.$route.params;
    this.bill = await bills.get(customerId, billId);
  },
  methods: {
    async upload(e) {
      e.preventDefault();
      if (!this.allowUpload) {
        return false;
      }
      try {
        const { billId = 0, customerId } = this.$route.params;
        const event = await bills.postChangedBillCsv(customerId, billId, {
          payload: this.files,
        });
        this.$refs['files-input'].value = '';
        this.files = [];
        this.allowUpload = false;
        this.$message({
          type: 'success',
          message: `Update scheduled event id: ${event.id}.`,
        });
      } catch (e) {
        console.error(e);
        this.$message({
          type: 'error',
          message:
            'Sorry, something went wrong. Please try again later or contact admin.',
        });
      }
    },
    async download(e) {
      e.preventDefault();
      try {
        const { billId = 0, customerId } = this.$route.params;
        this.downloading = true;
        const billCsv = await bills.getBillCsv(customerId, billId);
        const zip = new JSZip();
        for (let file of billCsv.payload) {
          zip.file(file.name, file.payload);
        }
        const content = await zip.generateAsync({ type: 'blob' });
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(content);
        a.style = 'display: none';
        a.href = url;
        a.download = `${customerId}_${billId}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.error(e);
        this.$message({
          type: 'error',
          message:
            'Sorry, something went wrong. Please try again later or contact admin.',
        });
      } finally {
        this.downloading = false;
      }
    },
    async handleFileChange(e) {
      try {
        const fileList = _.get(e, 'target.files', []);
        if (!fileList.length) {
          return false;
        }
        const payload = [];
        for (let fileData of fileList) {
          payload.push({
            name: fileData.name,
            payload: new TextDecoder('utf-8').decode(
              await captureFileBytes(fileData),
            ),
          });
        }
        payload.forEach((file) => {
          assert(
            file.name.split('_')[0] === this.bill.payload.fdg_batch_id,
            `File ${file.name} is not a valid for this bill, current batch id is ${this.bill.payload.fdg_batch_id}`,
          );
          assert(file.payload.length, `File ${file.name} is empty`);
        });
        this.files = payload;
        this.uploadError = false;
      } catch (e) {
        this.uploadError = e.message;
      } finally {
        this.allowUpload = !!this.files.length && !this.uploadError;
      }
      // this.uploadError = false;
    },
  },
};
</script>

<style scoped lang="scss">
form {
  padding-top: 16px;
  margin-top: 40px;
  border-top: 1px solid #868686;
}

.input-wrapper {
  label {
    color: #222222;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
  }
  .input-container {
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    ul {
      list-style: none;
      display: block;
      padding: 0;
    }

    input {
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 294px;
      top: 0;
      left: 0;
    }

    .input-field {
      display: block;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-sizing: border-box;
      height: 30px;
      width: 294px;
      border: 1px solid #d8dce6;
      border-radius: 4px 0 0 4px;
      background-color: #ffffff;
      color: #acacac;
      font-size: 12px;
      line-height: 30px;
      text-align: left;
      text-indent: 10px;
      border-right: none;
      margin-bottom: 8px;

      &.has-error {
        border: 1px solid #f86259;
      }
    }

    button {
      height: 30px;
      width: 81px;
      border-radius: 0 4px 4px 0;
      background-color: #2487e3;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      border: none;
      line-height: 17px;
    }
  }

  .error-container {
    color: #f86259;
    margin-top: 11px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
  }
}
</style>
