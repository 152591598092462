import { globalBills } from '@/api';
import * as R from 'ramda';

const getDefaultState = () => ({
  filterData: {},
  isLoading: false,
  statesLookup: {},
});

export default {
  namespaced: true,

  state: getDefaultState(),
  getters: {
    filterData: (state) => {
      return state.filterData;
    },
    statesLookup: (state) => state.statesLookup,
  },

  mutations: {
    setLoading(state) {
      state.isLoading = true;
    },
    setLoaded(state) {
      state.isLoading = false;
    },
    setFilterDate(state, data) {
      state.filterData = data || {};
    },
    setStatesLookup(state, data) {
      state.statesLookup = data || {};
    },
    clearState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  actions: {
    async getFilterData({ commit, rootGetters }) {
      commit('setLoading');
      try {
        const data = await globalBills.getFiltersData();
        data.bill_errors = R.propOr(
          [],
          'value',
          rootGetters['resources/getResourceByName']('bill_errors'),
        );
        commit('setFilterDate', data);
        const list = R.propOr([], 'workflow_states', data);
        const statesLookup = R.reduce(
          (acc, state) => {
            acc[state.value] = state.name;
            return acc;
          },
          {},
          list,
        );
        commit('setStatesLookup', statesLookup);
      } catch (err) {
        console.error('error while loading filters for global bills');
      }
      commit('setLoaded');
    },
  },
};
