import { batches } from '@/api';

export default {
  namespaced: true,
  state: {
    batchStatus: {},
  },

  getters: {
    batchStatus: (state) => state.batchStatus,
  },

  mutations: {
    setBatchStatus(state, batchStatus) {
      state.batchStatus = batchStatus;
    },
  },

  actions: {
    async fetchBatchStatus({ commit, rootGetters }, batch) {
      const customer_id = rootGetters['customers/id'];

      try {
        const result = await batches.validate(customer_id, batch);
        return commit('setBatchStatus', result);
      } catch (err) {
        return commit('setBatchStatus', {
          error: err.response.data,
        });
      }
    },
    resetBatchStatus({ commit }) {
      return commit('setBatchStatus', {});
    },
  },
};
