<template>
  <div>
    <el-input
      v-if="editMode"
      class="full-width"
      placeholder="N/A"
      v-model="value"
      clearable
      @input="handleInput"
    />
    <span v-else>
      <span v-if="value"> {{ value }}</span>
      <span v-else>N/A</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    initialValue: String,
    editMode: Boolean,
  },
  data() {
    return {
      value: '',
    };
  },
  mounted() {
    this.value = this.initialValue;
  },
  watch: {
    editMode() {
      this.value = this.initialValue;
    },
  },
  methods: {
    handleInput() {
      this.$emit('input-string', this.value);
    },
  },
};
</script>

<style scss scoped>
@import './style.scss';
</style>
