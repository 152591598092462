<template>
  <div class="info customer-info-page">
    <validation-observer
      class="customer-edit-form"
      v-slot="{ invalid }"
      ref="observerCustomerEdit"
    >
      <div class="main-block-title">
        <div class="main-block-title-text">
          Customer Info for {{ customer.name }}
        </div>
        <div class="main-block-title-actions">
          <template v-if="isEditMode">
            <el-button type="text" @click="cancelEditMode" size="small"
              >Cancel</el-button
            >
            <el-button
              type="primary"
              @click="updateCustomer"
              size="small"
              :disabled="isCustomerNotModified || invalid"
              >Save</el-button
            >
          </template>
          <template v-else>
            <el-button
              class="action-edit"
              type="text"
              @click="enableEditMode"
              size="small"
              >Edit</el-button
            >
          </template>
        </div>
      </div>
      <el-row>
        <el-col :span="12">
          <h2 class="info-block-title">Main information</h2>
        </el-col>
        <el-col :span="12">
          <h2 class="info-block-title">Target Processing Time (hours)</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="customer.id">
                Customer ID:
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="customer.id">
                {{ customer.id }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="customer.name">
                FDG Customer Code:
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="customer.fdgCode">
                {{ customer.fdgCode }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="customer.name">
                Customer Name *:
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <validation-provider
                  class="validation-provider-wrapper"
                  name="Customer Name"
                  rules="required"
                  immediate
                  v-slot="{ flags, errors }"
                >
                  <el-input
                    class="el-input--customer-name"
                    v-model="customerEdit.name"
                    :class="{ 'has-error': flags.invalid && flags.touched }"
                    size="small"
                  />
                  <error-tooltip :error="errors[0]" />
                </validation-provider>
              </div>
              <div class="info-field--value" v-else>{{ customer.name }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="line1">Address Line 1:</div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <el-input
                  class="el-input--customer-name"
                  placeholder="Street and number"
                  v-model="customerEdit.address.line1"
                  size="small"
                />
              </div>
              <div class="info-field--value" v-else-if="line1">
                {{ customer.address.line1 }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><div class="info-field--label" v-if="line2">
                Address Line 2:
              </div></el-col
            >
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <el-input
                  class="el-input--customer-name"
                  placeholder="Apt., suite, unit, floor, etc."
                  v-model="customerEdit.address.line2"
                  size="small"
                />
              </div>
              <div class="info-field--value" v-else-if="line2">
                {{ customer.address.line2 }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="cityField">City:</div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <el-input
                  class="el-input--customer-name"
                  placeholder="City"
                  v-model="customerEdit.address.city"
                  size="small"
                />
              </div>
              <div class="info-field--value" v-else-if="cityField">
                {{ customer.address.city }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="stateField">State:</div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <el-select
                  class="el-select--customer-name"
                  size="small"
                  v-model="customerEdit.address.state"
                  clearable
                  filterable
                  no-data-text="Please select a country"
                >
                  <el-option
                    v-for="(v, k) in states"
                    :key="k"
                    :label="v.pretty_name"
                    :value="v.pretty_name"
                  />
                </el-select>
              </div>
              <div class="info-field--value" v-else-if="stateField">
                {{ customer.address.state }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="countryField">Country:</div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <el-select
                  class="el-select--customer-name"
                  size="small"
                  v-model="customerEdit.address.country"
                  @change="onChangeCountry"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(v, k) in countries"
                    :key="k"
                    :label="v.pretty_name"
                    :value="k"
                  />
                </el-select>
              </div>
              <div class="info-field--value" v-else-if="countryField">
                {{ countryPrettyName(customer.address.country) }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="postcodeField">
                Postcode:
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <el-input
                  class="el-input--customer-name"
                  placeholder="Postcode"
                  v-model="customerEdit.address.postCode"
                  size="small"
                />
              </div>
              <div class="info-field--value" v-else-if="postcodeField">
                {{ customer.address.postCode }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label" v-if="tin">
                Tax ID Number (TIN):
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-field--value" v-if="isEditMode">
                <el-input
                  class="el-input--customer-name"
                  placeholder="TIN"
                  v-model="customerEdit.tin"
                  size="small"
                />
              </div>
              <div class="info-field--value" v-else-if="tin">
                {{ customer.tin }}
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col>
          <el-row>
            <el-col :span="12">
              <div class="info-field--label">Setup Bills:</div>
            </el-col>
            <el-col :span="12">
              <div v-if="isEditMode">
                <div class="info-field--value">
                  <validation-provider
                    class="validation-provider-wrapper"
                    name="Setup Bills"
                    rules="required|numeric"
                    immediate
                    v-slot="{ flags, errors }"
                  >
                    <el-input
                      v-model="setup"
                      :class="{ 'has-error': flags.invalid && flags.touched }"
                      size="small"
                    />
                    <error-tooltip :error="errors[0]"></error-tooltip>
                  </validation-provider>
                </div>
              </div>
              <div v-else>
                <div class="info-field--value">
                  {{ pathOr('', ['targetHours', 'setup'], customer) }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><div class="info-field--label">Live Bills:</div></el-col
            >
            <el-col :span="12">
              <div v-if="isEditMode">
                <div class="info-field--value">
                  <validation-provider
                    class="validation-provider-wrapper"
                    name="Live Bills"
                    rules="required|numeric"
                    immediate
                    v-slot="{ flags, errors }"
                  >
                    <el-input
                      v-model="live"
                      :class="{ 'has-error': flags.invalid && flags.touched }"
                      size="small"
                    />
                    <error-tooltip :error="errors[0]"></error-tooltip>
                  </validation-provider>
                </div>
              </div>
              <div v-else>
                <div class="info-field--value">
                  {{ pathOr('', ['targetHours', 'live'], customer) }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><div class="info-field--label">Historical Bills:</div></el-col
            >
            <el-col :span="12">
              <div v-if="isEditMode">
                <div class="info-field--value">
                  <validation-provider
                    class="validation-provider-wrapper"
                    name="Historical Bills"
                    rules="required|numeric"
                    immediate
                    v-slot="{ flags, errors }"
                  >
                    <el-input
                      v-model="historical"
                      :class="{ 'has-error': flags.invalid && flags.touched }"
                      size="small"
                    />
                    <error-tooltip :error="errors[0]"></error-tooltip>
                  </validation-provider>
                </div>
              </div>
              <div v-else>
                <div class="info-field--value">
                  {{ pathOr('', ['targetHours', 'historical'], customer) }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><div class="info-field--label">Demo Bills:</div></el-col
            >
            <el-col :span="12">
              <div v-if="isEditMode">
                <div class="info-field--value">
                  <validation-provider
                    class="validation-provider-wrapper"
                    name="Demo Bills"
                    rules="required|numeric"
                    immediate
                    v-slot="{ flags, errors }"
                  >
                    <el-input
                      v-model="demo"
                      :class="{ 'has-error': flags.invalid && flags.touched }"
                      size="small"
                    />
                    <error-tooltip :error="errors[0]"></error-tooltip>
                  </validation-provider>
                </div>
              </div>
              <div v-else>
                <div class="info-field--value">
                  {{ pathOr('', ['targetHours', 'demo'], customer) }}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <h2 class="info-block-title">Entitlements</h2>
        </el-col>
        <el-col :span="12">
          <h2 class="info-block-title">Contract Details</h2>
        </el-col>
      </el-row>
      <el-row class="row-border">
        <el-col :span="5">
          <div class="info-field--label" v-if="customer.id">Payments:</div>
        </el-col>
        <el-col :span="7">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox v-model="customerEdit.entitlements.payments" />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value--with-button">
              <div class="setting-status">
                {{ paymentEntitlement ? 'On' : 'Off' }}
              </div>
              <div class="info-field--value--with-button--reset-font">
                <el-button
                  class="payment-settings-button"
                  :disabled="!paymentEntitlement"
                  type="primary"
                  @click="
                    portingProps.showPaymentsDialog(
                      paymentEntitlement,
                      paymentFileFormats,
                    )
                  "
                  >Payment Settings</el-button
                >
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="info-field--label">Product Tier:</div>
        </el-col>
        <el-col :span="8">
          <div v-if="isEditMode">
            <el-select
              class="el-input--customer-name el-input--small"
              :value="customerEdit.contractDetails.productTier"
              @change="handleProductTier"
              filterable
            >
              <el-option v-for="v in productTiers" :key="v" :value="v" />
            </el-select>
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">{{ productTier || 'N/A' }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-border">
        <el-col :span="5">
          <div class="info-field--label" v-if="customer.id">Weather:</div>
        </el-col>
        <el-col :span="7">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox v-model="customerEdit.entitlements.weather" />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">
                {{ weatherEntitlement ? 'On' : 'Off' }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="info-field--label">Contract Signed:</div>
        </el-col>
        <el-col :span="8">
          <div class="info-field--value" v-if="isEditMode">
            <el-select
              class="el-input--customer-name el-input--small"
              v-model="customerEdit.contractDetails.contractSigned"
              filterable
            >
              <el-option v-for="v in contractTypes" :key="v" :value="v" />
            </el-select>
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">{{ contractSigned }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-border">
        <el-col :span="5">
          <div class="info-field--label" v-if="customer.id">PowerBI:</div>
        </el-col>
        <el-col :span="7">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox v-model="customerEdit.entitlements.powerBi" />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value--with-button">
              <div class="setting-status">
                {{ powerBiEntitlement ? 'On' : 'Off' }}
              </div>
              <el-button
                class="payment-settings-button"
                type="primary"
                :disabled="!powerBiEntitlement"
                @click="portingProps.showCustomerAnalyticsReportsDialog()"
                >Analytics Reports</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="info-field--label">Start Date:</div>
        </el-col>
        <el-col :span="8">
          <div
            style="margin-left: 16px"
            class="info-field--value"
            v-if="isEditMode"
          >
            <el-date-picker
              class="el-input--customer-name el-input--small"
              format="MM/dd/yyyy"
              placeholder="Select Start Date"
              v-model="contractStartDateEdit"
              :picker-options="pickerOptionsStartDate"
            />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">{{ contractStartDate }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-border">
        <el-col :span="5">
          <div class="info-field--label">Budgeting:</div>
        </el-col>
        <el-col :span="7">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox v-model="customerEdit.entitlements.budgeting" />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">
                {{ budgetingEntitlement ? 'On' : 'Off' }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="info-field--label">End Date:</div>
        </el-col>
        <el-col :span="8">
          <div
            style="margin-left: 16px"
            class="info-field--value"
            v-if="isEditMode"
          >
            <el-date-picker
              class="el-input--customer-name el-input--small"
              format="MM/dd/yyyy"
              placeholder="Select End Date"
              v-model="contractEndDateEdit"
              :picker-options="pickerOptionsEndDate"
            />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">{{ contractEndDate }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-border">
        <el-col :span="5">
          <div class="info-field--label" v-if="customer.id">
            Sustainability:
          </div>
        </el-col>
        <el-col :span="7">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox
              v-model="sustainability"
              @change="handleSustainabilityEntitlements"
            />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value--with-button">
              <div class="setting-status">{{ sustain ? 'On' : 'Off' }}</div>
            </div>
          </div>
        </el-col>
        <el-col :span="4" class="pl-48">
          <div class="info-field--label label-bold">Partner Details</div>
        </el-col>
      </el-row>
      <el-row class="row-border">
        <el-col :span="1" />
        <el-col :span="4">
          <div class="info-field--label" v-if="customer.id">Energy Star:</div>
        </el-col>
        <el-col :span="8">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox
              v-model="customerEdit.entitlements.energyStar"
              @change="handleSustainability"
            />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value--with-button">
              <div class="setting-status">
                {{ energyStarEntitlement ? 'On' : 'Off' }}
              </div>
              <div class="info-field--value--with-button--reset-font">
                <div
                  v-if="!energyStarEntitlement"
                  @mouseenter="onHover($event, 'tooltipES')"
                  @mousemove="onHover($event, 'tooltipES')"
                  @mouseleave="onLeave"
                >
                  <el-button
                    :disabled="!energyStarEntitlement"
                    class="payment-settings-button"
                    type="primary"
                    >Energy Star Settings</el-button
                  >
                  <div ref="tooltipES" class="btn-tooltip" v-show="tooltipES">
                    <p class="btn-tooltip-text">
                      To Setup the Energy Star entitlement, please enable
                      Sustainability and Energy Star.
                    </p>
                  </div>
                </div>
                <el-button
                  v-else
                  class="payment-settings-button"
                  type="primary"
                  @click="handleEnergyStarModals"
                  >Energy Star Settings</el-button
                >
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="info-field--label">Partner:</div>
        </el-col>
        <el-col :span="8">
          <div v-if="isEditMode">
            <el-select
              class="el-input--customer-name el-input--small"
              v-model="customerEdit.contractDetails.partner"
              filterable
            >
              <el-option
                v-for="v in partnerTypes"
                :key="v.value"
                :value="v.value"
                :label="v.label || v.value"
              />
            </el-select>
          </div>
          <div v-else class="info-field--value">
            <div class="info-field--value">
              <div class="setting-status">{{ partner() || 'None' }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="pt-16">
        <el-col :span="1" />
        <el-col :span="4">
          <div class="info-field--label" v-if="customer.id">
            Carbon Footprint:
          </div>
        </el-col>
        <el-col :span="8">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox
              v-model="customerEdit.entitlements.carbonFootprint"
              @change="handleSustainability"
            />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value--with-button">
              <div class="setting-status">
                {{ carbonFootprintEntitlement ? 'On' : 'Off' }}
              </div>
              <div class="info-field--value--with-button--reset-font">
                <div
                  v-if="!carbonFootprintEntitlement"
                  @mouseenter="onHover($event, 'tooltipCarbon')"
                  @mousemove="onHover($event, 'tooltipCarbon')"
                  @mouseleave="onLeave"
                >
                  <el-button
                    :disabled="!carbonFootprintEntitlement"
                    class="payment-settings-button"
                    type="primary"
                    >Carbon Footprint Settings</el-button
                  >
                  <div
                    ref="tooltipCarbon"
                    class="btn-tooltip"
                    v-show="tooltipCarbon"
                  >
                    <p class="btn-tooltip-text">
                      To Setup the Carbon Footprint entitlement, please enable
                      Sustainability and Carbon Footprint.
                    </p>
                  </div>
                </div>
                <el-button
                  v-else
                  class="payment-settings-button"
                  type="primary"
                  @click="showCarbonFootprintModal = true"
                  >Carbon Footprint Settings</el-button
                >
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="info-field--label">Partner Billing:</div>
        </el-col>
        <el-col :span="8">
          <div v-if="isEditMode">
            <el-select
              class="el-input--customer-name el-input--small"
              v-model="customerEdit.contractDetails.isPartnerBilling"
              filterable
            >
              <el-option
                v-for="v in partnerBillingTypes"
                :key="v.value"
                :value="v.value"
                :label="v.label"
              />
            </el-select>
          </div>
          <div v-else class="info-field--value">
            <div class="info-field--value">
              <div class="setting-status">{{ partnerBilling }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-border">
        <el-col :span="5">
          <div class="info-field--label">Bill Pay:</div>
        </el-col>
        <el-col :span="7">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox v-model="customerEdit.entitlements.billPay" />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">
                {{ billPayEntitlement ? 'On' : 'Off' }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="pt-16">
        <el-col :span="5">
          <div class="info-field--label" v-if="customer.id">
            Activity History Chat:
          </div>
        </el-col>
        <el-col :span="8">
          <div class="info-field--value" v-if="isEditMode">
            <el-checkbox
              v-model="customerEdit.entitlements.activityHistoryChat"
            />
          </div>
          <div class="info-field--value" v-else>
            <div class="info-field--value">
              <div class="setting-status">
                {{ activityHistoryChatEntitlement ? 'On' : 'Off' }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </validation-observer>
    <el-row>
      <el-col :span="13">
        <h2 style="margin-top: 1em" class="info-block-title">
          SFTP Configuration
        </h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <div class="info-field--label" v-if="customer.id">
          Reports Delivery Settings:
        </div>
      </el-col>
      <el-col :span="17">
        <div class="info-field--value" v-if="isEditMode">
          <el-checkbox
            v-model="customerEdit.entitlements.reportDeliverySettings"
            @change="handleSustainability"
          />
        </div>
        <div class="info-field--value" v-else>
          <div class="info-field--value--with-button">
            <div class="setting-status">
              {{ carbonReportDeliverySettingsEntitlement ? 'On' : 'Off' }}
            </div>
            <el-button
              class="payment-settings-button"
              type="primary"
              :disabled="!carbonReportDeliverySettingsEntitlement"
              @click="portingProps.showCustomerSftpLocationsDialog()"
              >Configure SFTP</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="13">
        <h2 style="margin-top: 1em" class="info-block-title">Status</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="13">
        <div class="info-switch" v-if="isEditMode">
          <el-checkbox v-model="maskAsInactive"
            >Mark customer as inactive</el-checkbox
          >
        </div>
        <div class="info-switch" v-else>
          <el-checkbox :value="!customer.active" disabled
            >Mark customer as inactive</el-checkbox
          >
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="13" style="min-height: 1px"></el-col>
    </el-row>
    <el-row
      type="flex"
      align="middle"
      :class="{ 'disabled-content': isEditMode }"
    >
      <el-col :span="20">
        <h2 class="info-block-title">Users</h2>
      </el-col>
      <el-col :span="4">
        <el-button
          class="button-add-user"
          type="text"
          @click="
            portingProps.openAddUserModal(
              customer,
              userRolesRadioSelection,
              hierarchies,
              totalLocations,
            )
          "
          >+Add User</el-button
        >
      </el-col>
    </el-row>
    <el-table
      class="users-table with-shadow"
      :data="users"
      @sort-change="sortTable"
      :style="{ width: '100%' }"
      :class="{ 'disabled-content': isEditMode }"
    >
      <el-table-column label="ID" prop="id" width="80">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'user.info', params: { userId: scope.row.id } }"
            >{{ scope.row.id }}</router-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Name" prop="lastName" sortable="custom">
        <template slot-scope="scope"
          ><span data-type="user-first-name">{{
            fullName(scope)
          }}</span></template
        >
      </el-table-column>
      <el-table-column label="User Role" prop="userRole">
        <template slot-scope="scope"
          ><span>{{ userRole(scope.row.customers) }}</span></template
        >
      </el-table-column>
      <el-table-column label="Email" prop="email">
        <template slot-scope="scope"
          ><span :title="scope.row.email">{{ scope.row.email }}</span></template
        >
      </el-table-column>
      <el-table-column label="Locations" prop="locations">
        <template slot-scope="scope">{{
          customerLocations(scope.row.customers)
        }}</template>
      </el-table-column>
      <el-table-column label="Active" prop="active" sortable="custom">
        <template slot-scope="scope"
          ><span>{{ scope.row.active ? 'Yes' : 'No' }}</span></template
        >
      </el-table-column>
      <el-table-column label="Last Login" prop="lastLogin" sortable="custom">
        <template slot-scope="scope">
          <span v-if="scope.row.lastLogin">{{
            scope.row.lastLogin | datetime('M/d/yy, h:mm a')
          }}</span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column
        label="Chat Tag"
        prop="chatTag"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          <i
            v-if="isChatTag(scope.row)"
            class="icon-check__size el-icon-check"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Group Tag"
        prop="groupTag"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          <i
            v-if="isGroupTag(scope.row)"
            class="el-icon-check icon-check__size"
          />
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="120">
        <template slot-scope="scope">
          <el-tooltip
            effect="light"
            content="Edit User Role and Locations"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="text"
              title="Edit Button"
              @click="
                portingProps.openEditUserRole(
                  scope.row,
                  userRolesRadioSelection,
                  hierarchies,
                  totalLocations,
                  customer,
                )
              "
              ><i class="el-icon-edit edit-black"></i
            ></el-button>
          </el-tooltip>
          <el-button
            type="text"
            title="Send Activation Link"
            :disabled="!!scope.row.activatedAt"
            @click="sendActivationEmail(scope)"
            ><i class="icon icon-send-email"></i
          ></el-button>
          <el-button
            class="btn-remove"
            type="text"
            title="Remove User"
            @click="confirmUnmapUser(scope.row)"
            ><i class="el-icon-remove"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <confirm-delete
      :isVisible="showConfirmationModal"
      @close="showConfirmationModal = false"
      @cancel="cancelUnmapUser()"
      @ok="unmapUser"
      :message="confirmMessage"
    />
    <show-energy-star
      :dialogVisible="showEnergyStarDiag"
      @close="showEnergyStarDiag = false"
      @open-energy-star="handleShowEnergyStarAccDialog"
      :width="'30%'"
    />
    <energy-star-settings
      :dialogVisible="showEnergyStarAccDiag"
      @close="handleCloseAndRefreshData"
      :customerFDGCode="customer.fdgCode"
    />
    <carbon-footprint-modal
      :dialogVisible.sync="showCarbonFootprintModal"
      @close="showCarbonFootprintModal = false"
    />
    <delete-sftp-connections
      :dialogVisible.sync="showDeleteSftpConnModal"
      @close="showDeleteSftpConnModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import _, { isNil } from 'lodash';
import * as R from 'ramda';
import { customers, customersUsers, locations, user } from '@/api';
import MaskedInput from 'vue-masked-input';
import ConfirmDelete from '@/components/Controls/Confirmation';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  userRoles,
  customerLocations,
  handleEditUserRoles,
} from '../../lib/userRoleHelpers';
import ErrorTooltip from '@/pages/Customers/Locations/Controls/ErrorTooltip';
import { DateTime } from 'luxon';
import BaseRadioGroup from '@/components/FormComponents/BaseRadioGroup';
import EditUserRoleModal from './Modals/EditUserRoleModal';
import EnergyStarSettings from '../EnergyStarSettings/EnergyStarSettings';
import ShowEnergyStar from '../EnergyStarSettings/ShowEnergyStar';
import MapLocationsToStandardUser from './Modals/MapLocationsToStandardUser';
import CarbonFootprintModal from './Modals/CarbonFootprintModal';
import DeleteSftpConnections from './Modals/DeleteSftpConnections';
import { serializeOrderByV3 } from '../../lib/helpers';
import EnergyStarLocationsDownloaded from './Locations/Modals/EnergyStarLocationsDownloaded.vue';

const productTiersArr = [
  'Standard',
  'Gold',
  'Platinum',
  'Reporting & Analytics',
];

const contractTypesArr = [
  'Exelon Generation',
  'Constellation - Standalone',
  'Constellation - Embedded',
  'Constellation - Embedded (Lite)',
  'CNEG - Standalone',
  'CNEG - Embedded',
];

const partnerTypesArr = [
  { label: 'None', value: null },
  { label: 'Constellation', value: 'Constellation' },
  { label: 'Correlate', value: 'Correlate' },
  { label: 'Power Options', value: 'Power Options' },
  { label: 'World Connect', value: 'World Connect' },
];

const partnerBillingTypesArr = [
  { label: 'N/A', value: null },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const defaultNewUser = () => ({
  email: '',
  userRoleRadio: 'customerFullAccess',
  filterText: '',
  showOnlySelectedLocations: false,
  profile: {
    first_name: '',
    last_name: '',
  },
});
const defaultLocationData = () => ({
  line1: '',
  line2: '',
  line3: '',
  line4: '',
  city: '',
  state: '',
  postCode: '',
  country: '',
});
const defaultCustomerEdit = () => ({
  active: false,
  fdgCode: '',
  name: '',
  entitlements: {
    reportDeliverySettings: false,
    payments: false,
    energyStar: false,
    powerBi: false,
    weather: false,
    budgeting: false,
    activityHistoryChat: false,
    carbonFootprint: false,
    billPay: false,
  },
  contractDetails: {
    productTier: 'Standard',
    contractSigned: 'Exelon Generation',
    contractStartDate: '',
    contractEndDate: '',
    partner: 'None',
    isPartnerBilling: null,
  },
  targetHours: {
    setup: 48,
    live: 48,
    historical: 48,
    demo: 48,
  },
  address: defaultLocationData(),
  tin: '',
});

export default {
  components: {
    DeleteSftpConnections,
    CarbonFootprintModal,
    MapLocationsToStandardUser,
    MaskedInput,
    ConfirmDelete,
    ValidationObserver,
    ValidationProvider,
    BaseRadioGroup,
    ErrorTooltip,
    EnergyStarSettings,
    ShowEnergyStar,
    EditUserRoleModal,
    EnergyStarLocationsDownloaded,
  },
  data: function () {
    return {
      sustainability: false,
      isEditMode: false,
      showCarbonFootprintModal: false,
      showReportDeliverySettingsModal: false,
      tooltipES: false,
      tooltipCarbon: false,
      customerEdit: defaultCustomerEdit(),
      currentUser: null,
      newUser: defaultNewUser(),
      editableUser: {},
      deleteUser: {},
      userProfileId: null,
      editUser: {},
      selectedNotificationMethons: [],
      showEnergyStarDiag: false,
      showEnergyStarAccDiag: false,
      showEditUserRoleModal: false,
      energyStarAccData: {},
      notification_email: true,
      partnerBillingTypes: partnerBillingTypesArr,
      showDeleteSftpConnModal: false,
      contractTypes: contractTypesArr,
      productTiers: productTiersArr,
      partnerTypes: partnerTypesArr,
      pickerOptionsStartDate: {
        disabledDate: this.disabledDateStartDate,
      },
      pickerOptionsEndDate: {
        disabledDate: this.disabledDateEndDate,
      },
      locationsList: [],
      notificationsSettings: [
        {
          label: 'Email',
          value: 'notifications_email',
        },
        {
          label: 'SMS',
          value: 'notifications_sms',
        },
      ],
      carbonFootprint: {},
      showEntitlementDialog: false,
      showAddUserModal: false,
      showAddNewUserModal: false,
      showAddExistingUserModal: false,
      showConfirmationModal: false,
      confirmMessage: '',
      userModalEditMode: false,
      editableUserName: '',
      emailExistError: '',
      search: '',
      hierarchies: [],
      selectedLocations: [],
      totalLocations: 0,
    };
  },
  beforeMount() {
    if (this.energyStarEntitlement) {
      this.getEnergyStarAccount();
    }
  },
  async mounted() {
    await this.loadUsers();
    await this.fetchHierarchyAndUnmatchedLocations();
    this.buildHierarchy();
  },
  beforeDestroy() {
    this.resetDefaultHierarchy();
    this.resetUnmatchedLocations();
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer,
      users: (state) => state.customers.users,
      portingProps: (state) => state.porting.props,
    }),
    ...mapGetters({
      hierarchy: 'locations/hierarchy',
      unmatchedLocations: 'hierarchy/locations',
      getResourceByName: 'resources/getResourceByName',
    }),
    paymentFileFormats() {
      return _.get(this.customer, 'paymentFileTypes', []);
    },
    carbonFootprintEntitlement() {
      return _.get(this.customer, 'entitlements.carbonFootprint', false);
    },
    budgetingEntitlement() {
      return _.get(this.customer, 'entitlements.budgeting', false);
    },
    carbonReportDeliverySettingsEntitlement() {
      return _.get(this.customer, 'entitlements.reportDeliverySettings', false);
    },
    paymentEntitlement() {
      return _.get(this.customer, 'entitlements.payments', false);
    },
    weatherEntitlement() {
      return _.get(this.customer, 'entitlements.weather', false);
    },
    billPayEntitlement() {
      return _.get(this.customer, 'entitlements.billPay', false);
    },
    activityHistoryChatEntitlement() {
      return _.get(this.customer, 'entitlements.activityHistoryChat', false);
    },
    setup: {
      get() {
        return this.customerEdit.targetHours.setup;
      },
      set(val) {
        this.customerEdit.targetHours.setup = val;
        return val;
      },
    },
    line1() {
      return (
        (!isNil(this.customer.address) &&
          !isNil(this.customer.address.line1) &&
          this.customer.address.line1 !== '') ||
        this.isEditMode
      );
    },
    line2() {
      return (
        (!isNil(this.customer.address) &&
          !isNil(this.customer.address.line2) &&
          this.customer.address.line2 !== '') ||
        this.isEditMode
      );
    },
    cityField() {
      return (
        (!isNil(this.customer.address) &&
          !isNil(this.customer.address.city) &&
          this.customer.address.city !== '') ||
        this.isEditMode
      );
    },
    stateField() {
      return (
        (!isNil(this.customer.address) &&
          !isNil(this.customer.address.state) &&
          this.customer.address.state !== '') ||
        this.isEditMode
      );
    },
    countryField() {
      return (
        (!isNil(this.customer.address) &&
          !isNil(this.customer.address.country) &&
          this.customer.address.country !== '') ||
        this.isEditMode
      );
    },
    postcodeField() {
      return (
        (!isNil(this.customer.address) &&
          !isNil(this.customer.address.postCode) &&
          this.customer.address.postCode !== '') ||
        this.isEditMode
      );
    },
    tin() {
      return (
        (!isNil(this.customer.tin) && this.customer.tin !== '') ||
        this.isEditMode
      );
    },
    isChatTag() {
      return (tag) =>
        tag &&
        tag.profile &&
        tag.profile.chatSettings &&
        tag.profile.chatSettings.chatTag;
    },
    isGroupTag() {
      return (tag) =>
        tag &&
        tag.profile &&
        tag.profile.chatSettings &&
        tag.profile.chatSettings.groupTag;
    },
    live: {
      get() {
        return this.customerEdit.targetHours.live;
      },
      set(val) {
        this.customerEdit.targetHours.live = val;
        return val;
      },
    },
    historical: {
      get() {
        return this.customerEdit.targetHours.historical;
      },
      set(val) {
        this.customerEdit.targetHours.historical = val;
        return val;
      },
    },
    demo: {
      get() {
        return this.customerEdit.targetHours.demo;
      },
      set(val) {
        this.customerEdit.targetHours.demo = val;
        return val;
      },
    },
    sustain() {
      if (
        this.customer &&
        this.customer.entitlements &&
        this.customer.entitlements.carbonFootprint &&
        this.customer.entitlements.energyStar
      ) {
        this.sustainability = true;
        return true;
      }
      if (
        this.customer &&
        this.customer.entitlements &&
        this.customer.entitlements.energyStar
      ) {
        this.sustainability = true;
        return true;
      }
      if (
        this.customer &&
        this.customer.entitlements &&
        this.customer.entitlements.carbonFootprint
      ) {
        this.sustainability = true;
        return true;
      }
    },
    powerBiEntitlement() {
      return _.get(this.customer, 'entitlements.powerBi', false);
    },
    productTier() {
      return _.defaultTo(
        this.customer.contractDetails &&
          this.customer.contractDetails.productTier,
        'Standard',
      );
    },
    contractSigned() {
      return _.defaultTo(
        this.customer.contractDetails &&
          this.customer.contractDetails.contractSigned,
        'Exelon Generation',
      );
    },
    partnerBilling() {
      const { contractDetails } = this.customer;
      if (contractDetails && _.isNil(contractDetails.isPartnerBilling)) {
        return 'N/A';
      }
      if (contractDetails && contractDetails.isPartnerBilling) {
        return 'Yes';
      }
      return 'No';
    },
    energyStarEntitlement() {
      const energyStarEnt = _.get(
        this.customer,
        'entitlements.energyStar',
        false,
      );
      if (energyStarEnt) {
        this.getEnergyStarAccount();
      }
      return energyStarEnt;
    },
    maskAsInactive: {
      get() {
        return !this.customerEdit.active;
      },
      set(value) {
        this.customerEdit.active = !value;
      },
    },
    userRolesRadioSelection() {
      return customersUsers.userRolesRadioSelection;
    },
    isCustomerNotModified() {
      const customer = R.clone(this.customer);
      const newCustomer = R.clone(this.customerEdit);
      return R.equals(customer, newCustomer);
    },
    contractStartDate() {
      const { contractDetails } = this.customer;
      if (contractDetails && contractDetails.contractStartDate) {
        return DateTime.fromISO(contractDetails.contractStartDate).toFormat(
          'MM/dd/yyyy',
          { zone: 'utc' },
        );
      }
      return 'N/A';
    },
    contractEndDate() {
      const { contractDetails } = this.customer;
      if (contractDetails && contractDetails.contractEndDate) {
        return DateTime.fromISO(contractDetails.contractEndDate).toFormat(
          'MM/dd/yyyy',
          { zone: 'utc' },
        );
      }
      return 'N/A';
    },
    fullName() {
      return (scope) =>
        !_.isNil(scope.row.profile)
          ? `${scope.row.profile.lastName} ${scope.row.profile.firstName}`
          : '-';
    },
    contractStartDateEdit: {
      get() {
        return this.customerEdit.contractDetails.contractStartDate;
      },
      set(val) {
        if (!_.isNil(val)) {
          this.customerEdit.contractDetails.contractStartDate =
            DateTime.fromJSDate(new Date(val)).endOf('day').toISO();
          return val;
        }
        this.customerEdit.contractDetails.contractStartDate = null;
        return null;
      },
    },
    states: {
      get() {
        const { country } = this.customerEdit.address;
        if (!country) {
          return {};
        }
        return R.pipe(
          R.propOr({}, 'value'),
          R.pickBy((value, key) => R.startsWith(country, key)),
        )(this.getResourceByName('states'));
      },
    },
    countries: {
      get() {
        return R.propOr({}, 'value', this.getResourceByName('countries'));
      },
    },
    contractEndDateEdit: {
      get() {
        return this.customerEdit.contractDetails.contractEndDate;
      },
      set(val) {
        if (!_.isNil(val)) {
          this.customerEdit.contractDetails.contractEndDate =
            DateTime.fromJSDate(new Date(val)).endOf('day').toISO();
          return val;
        }
        this.customerEdit.contractDetails.contractEndDate = null;
        return null;
      },
    },
  },
  watch: {
    'customer.entitlements.activityHistoryChat': {
      handler(activityHistoryChat) {
        this.customerEdit.entitlements.activityHistoryChat =
          activityHistoryChat;
      },
      immediate: true,
    },
    showAddUserModal(isVisible) {
      if (!isVisible) {
        this.$refs.observerAddUser.reset();
      }
    },
    showAddNewUserModal(isVisible) {
      if (!isVisible) {
        this.$refs.observerAddNewUser.reset();
      }
    },
    'portingProps.refreshUsersData'(isRefreshable) {
      if (isRefreshable) {
        this.loadUsers();
      }
    },
  },
  methods: {
    ...mapMutations({
      setCustomersList: 'customers/setCustomersList',
      resetDefaultHierarchy: 'locations/resetHierarchy',
      resetUnmatchedLocations: 'hierarchy/resetUnmatchedLocations',
    }),
    ...mapActions({
      setUsers: 'customers/setUsers',
      updateCustomer: 'customers/updateCustomer',
      setCustomer: 'customers/setCustomer',
      fetchDefaultHierarchies: 'locations/fetchDefaultHierarchy',
      fetchUnmachedLocations: 'hierarchy/fetchUnmachedLocations',
    }),
    pathOr: R.pathOr,
    prop: R.prop,
    partner() {
      const { entitlements, contractDetails } = this.customer;
      if (
        entitlements &&
        entitlements.constellation &&
        contractDetails &&
        !contractDetails.partner
      ) {
        this.customerEdit.contractDetails.partner = 'Constellation';
        return 'Constellation';
      }
      return contractDetails ? contractDetails.partner : null;
    },
    onChangeCountry() {
      this.customerEdit.address.state = '';
    },
    handleOpenPaymentDialog() {
      this.handleOpenOverlay();
      this.showPaymentDialog = true;
    },
    countryPrettyName(country) {
      const countries = R.propOr(
        {},
        'value',
        this.getResourceByName('countries'),
      );
      return countries[country].pretty_name;
    },
    disabledDateStartDate(date) {
      const { contractDetails } = this.customerEdit;
      if (contractDetails.contractEndDate) {
        return date > new Date(contractDetails.contractEndDate);
      }
    },
    disabledDateEndDate(date) {
      const { contractDetails } = this.customerEdit;
      if (contractDetails.contractStartDate) {
        return date < new Date(contractDetails.contractStartDate);
      }
    },
    handleSustainabilityEntitlements(val) {
      if (val) {
        this.customerEdit.entitlements.energyStar = true;
        this.customerEdit.entitlements.carbonFootprint = true;
        return;
      }
      this.customerEdit.entitlements.energyStar = false;
      this.customerEdit.entitlements.carbonFootprint = false;
    },
    handleSustainability() {
      if (
        this.customerEdit.entitlements.carbonFootprint &&
        this.customerEdit.entitlements.energyStar
      ) {
        this.sustainability = true;
        return;
      }
      if (this.customerEdit.entitlements.energyStar) {
        this.sustainability = true;
        return;
      }
      if (this.customerEdit.entitlements.carbonFootprint) {
        this.sustainability = true;
        return;
      }
      this.sustainability = false;
    },
    handleProductTier(val) {
      const { contractDetails, entitlements } = this.customerEdit;
      contractDetails.productTier = val;
      switch (val) {
        case 'Standard':
          Object.keys(entitlements)
            .filter(
              (key) =>
                !['reportDeliverySettings', 'activityHistoryChat'].includes(
                  key,
                ),
            )
            .forEach((key) => {
              entitlements[key] = false;
              this.sustainability = false;
            });
          break;
        case 'Gold':
          Object.keys(entitlements)
            .filter(
              (key) =>
                ![
                  'reportDeliverySettings',
                  'activityHistoryChat',
                  'billPay',
                ].includes(key),
            )
            .forEach((key) => {
              entitlements[key] = ![
                'energyStar',
                'carbonFootprint',
                'activityHistoryChat',
              ].includes(key);
              this.sustainability = false;
            });
          break;
        case 'Reporting & Analytics':
          Object.keys(entitlements)
            .filter(
              (key) =>
                !['reportDeliverySettings', 'activityHistoryChat'].includes(
                  key,
                ),
            )
            .forEach((key) => {
              entitlements[key] = [
                'powerBi',
                'weather',
                'carbonFootprint',
              ].includes(key);
              this.sustainability = true;
            });
          break;
        case 'Platinum':
          Object.keys(entitlements)
            .filter(
              (key) =>
                ![
                  'reportDeliverySettings',
                  'activityHistoryChat',
                  'billPay',
                ].includes(key),
            )
            .forEach((key) => {
              entitlements[key] = true;
              this.sustainability = true;
            });
          break;
      }
    },
    sendActivationEmail({ row }) {
      customersUsers.activate(row.id).then(() => {
        this.$message({
          message: 'The activation email was sent.',
          type: 'success',
        });
      });
    },
    async loadAllUsers(params) {
      const {
        data = [],
        meta: {
          content_range: { total = 0 },
        },
      } = await locations.list(this.$route.params.customerId, params);
      this.locationsList = data;
      this.totalLocations = total;
    },
    async loadUsers() {
      await this.loadAllUsers({ limit: 10000, orderBy: this.orderBy });
      if (this.totalLocations > 10000) {
        await this.loadAllUsers({
          limit: this.totalLocations,
          offset: 10000,
          orderBy: this.orderBy,
        });
      }
      const users = await customersUsers.get(
        this.customer.id || this.$route.params.customerId,
        this.orderBy ? [this.orderBy] : null,
      );
      this.setUsers(users.data.results);
    },
    userRole(customers) {
      return userRoles(customers, this.customer.id);
    },
    customerLocations(customers) {
      return customerLocations(customers, this.customer.id, this.locationsList);
    },
    sortTable({ prop, order }) {
      this.orderBy =
        prop && order ? serializeOrderByV3(prop, order) : undefined;
      this.loadUsers();
    },
    openAddUserModal() {
      this.showAddUserModal = true;
    },
    async addUser() {
      const isNew = await this.isCustomerNewUser(this.newUser.email);
      if (!isNew) {
        this.$refs.userEmail.applyResult({
          errors: ['The user is already assigned to this customer'],
          valid: false,
          failedRules: {},
        });
        return;
      }
      const { email } = this.newUser;
      const { data = [] } = await user.userList({ email, order: '+last_name' });
      const userInfo = Array.isArray(data) ? R.head(data) : {};
      const isNewUser = R.isEmpty(userInfo) || R.isNil(userInfo);
      this.hideAllModal();
      if (isNewUser) {
        this.showAddNewUserModal = true;
      } else {
        Object.assign(this.newUser, userInfo);
        this.showAddExistingUserModal = true;
      }
    },
    handleSelectedLocations(locations) {
      this.selectedLocations = locations;
    },
    handleFilterValue(val) {
      this.newUser.filterText = val;
    },
    handleShowOnlySelectedLocations(val) {
      this.newUser.showOnlySelectedLocations = val;
    },
    addNewUser() {
      let confirmationMessage = `User ${this.newUser.profile.first_name} ${this.newUser.profile.last_name} was added`;
      let newUser = {
        userRoleRadio: this.newUser.userRoleRadio,
        email: this.newUser.email,
        profile: {
          first_name: this.newUser.profile.first_name,
          last_name: this.newUser.profile.last_name,
        },
      };
      customersUsers
        .post(this.customer.id, newUser)
        .then((userProfile) => {
          this.hideAllModal();
          this.assignUserRole({
            userId: +userProfile.id,
            userRoleRadio: this.newUser.userRoleRadio,
          });
          this.assignLocations(+userProfile.id);
          this.loadUsers();
          this.$message({
            type: 'success',
            message: confirmationMessage,
          });
        })
        .catch((e) => {
          if (e.response.data.code === 406) {
            this.$message({
              message: 'The user is already assigned to this customer.',
              type: 'error',
            });
          }
          this.hideAllModal();
        });
    },
    async fetchHierarchyAndUnmatchedLocations() {
      const { customerId } = this.$route.params;
      await this.fetchDefaultHierarchies({ customerId });
      const defaultHierarchy =
        this.hierarchy && this.hierarchy.find((h) => !h.parentId);
      defaultHierarchy &&
        (await this.fetchUnmachedLocations({
          customerId,
          hierarchyId: defaultHierarchy.id,
        }));
    },
    buildHierarchy() {
      const hierarchies = [];
      const listHierarchy = this.hierarchy.slice();
      const map = new Map();
      listHierarchy.forEach((hierarchy, i) => {
        map.set(hierarchy.id, i);
        hierarchy.children = [];
        if (hierarchy.parentId) {
          if (hierarchy.location) {
            listHierarchy[map.get(hierarchy.parentId)].children.push(
              hierarchy.location,
            );
          } else {
            listHierarchy[map.get(hierarchy.parentId)].children.push(hierarchy);
          }
        } else {
          hierarchies.push(hierarchy);
        }
      });
      hierarchies.push(...this.unmatchedLocations);
      if (!hierarchies.length) {
        this.hierarchies = [
          { name: 'All Locations', children: [...this.locationsList] },
        ];
        return;
      }
      if (hierarchies.length) {
        this.hierarchies = [
          { name: 'All Locations', children: [...hierarchies] },
        ];
      }
    },
    async editUserInfo(payload) {
      await this.assignUserRole(payload);
      await this.assignLocations(payload.userId);
      this.loadUsers();
    },
    async assignUserRole(payload) {
      const userRoles = handleEditUserRoles(payload);
      const { permissions, userId, fullName, readableUserRole, editUserRole } =
        userRoles;
      const body = {
        permissions,
        userId,
      };
      try {
        await customersUsers.assignPermissions(
          this.$route.params.customerId,
          body,
        );
        if (editUserRole) {
          this.$message({
            message: `Changes made successfully. The user ${fullName} was assigned ${readableUserRole}.`,
            type: 'success',
          });
        }
      } catch (e) {
        this.$message({
          message: 'The user role assignment failed. Please try again.',
          type: 'error',
        });
      }
    },
    async assignLocations(userId) {
      const { locationsIds, event } = this.selectedLocations;
      const locIds =
        locationsIds &&
        locationsIds.filter((selectedLocation) => !_.isNil(selectedLocation));
      const body = {
        locationsIds: locIds,
      };
      if (event) {
        try {
          await locations.setUserLocations(
            this.$route.params.customerId,
            userId,
            body,
          );
          this.selectedLocations = {};
        } catch (e) {
          this.$message({
            message: 'The location assignment failed. Please try again.',
            type: 'error',
          });
        }
      }
    },
    async addExistingUser() {
      const userId = this.newUser.id;
      const customerId = this.customer.id;
      const { firstName, lastName } = this.newUser.profile;
      const fullName = `${lastName} ${firstName}`;
      const notificationEmail = this.notification_email;
      try {
        await user.assignUser(userId, {
          userId,
          customerId,
          notificationEmail,
        });
        await this.assignUserRole({
          userId: +userId,
          userRoleRadio: this.newUser.userRoleRadio,
        });
        await this.assignLocations(+userId);
        await this.loadUsers();
        this.$message({
          type: 'success',
          message: `User ${fullName} was added`,
        });
      } catch (e) {
        const code = _.get(e, 'response.data.code', 500);
        if (code === 409) {
          this.$message({
            showClose: true,
            message: `This user has been already added to ${this.customer.name}`,
            type: 'error',
          });
        } else {
          console.log(e);
        }
      }
      this.hideAllModal();
    },
    confirmUnmapUser(userData) {
      this.showConfirmationModal = true;
      this.deleteUser = userData;
      const { firstName, lastName } = userData.profile;
      const { name } = this.customer;
      this.confirmMessage = `Are you sure you want to unassign customer ${name} from user ${firstName} ${lastName}?`;
    },
    cancelUnmapUser() {
      this.showConfirmationModal = false;
      this.$message({
        type: 'info',
        message: 'Delete canceled',
      });
    },
    async getEnergyStarAccount() {
      const { customerId } = this.$route.params;
      const { data } = await customers.getEnergyStarAcc(customerId);
      this.energyStarAccData = data;
    },
    async unmapUser(notification_email) {
      try {
        const user_id = this.deleteUser.id;
        const customer_id = parseInt(this.customer.id);
        await user.unAssignUser(user_id, {
          user_id,
          customer_id,
          notification_email,
        });
        this.$message({
          type: 'success',
          message: 'Delete completed',
        });
        if (!_.isNil(this.deleteUser.customers)) {
          this.deleteUser.customers = this.deleteUser.customers.filter(
            (customer) => customer.id !== customer_id,
          );
        }
        const firstName = R.pathOr('', ['profile', 'firstName'])(
          this.deleteUser,
        );
        const lastName = R.pathOr('', ['profile', 'lastName'])(this.deleteUser);
        if (
          _.isNil(this.deleteUser.customers) ||
          !this.deleteUser.customers.length
        ) {
          this.$alert(
            `There is no customer assigned to ${firstName} ${lastName}!`,
            'Warning',
            { confirmButtonText: 'OK' },
          );
        }
        this.loadUsers();
      } catch (e) {
        console.log(e);
      } finally {
        this.showConfirmationModal = false;
      }
    },
    hideAllModal() {
      this.showAddExistingUserModal = false;
      this.showAddNewUserModal = false;
      this.showAddUserModal = false;
      this.showEditUserRoleModal = false;
    },
    openDeleteSftpConnModal() {
      this.showDeleteSftpConnModal = true;
    },
    clearNewUserProfile() {
      this.newUser.profile.first_name = '';
      this.newUser.profile.last_name = '';
      this.newUser.filterText = '';
      this.newUser.showOnlySelectedLocations = false;
      this.newUser.userRoleRadio = 'customerFullAccess';
      this.notification_email = true;
    },
    async isCustomerNewUser(email) {
      await this.loadUsers();
      return R.none(R.propEq('email', email))(this.users);
    },
    async updateCustomer() {
      this.isLoading = true;
      this.customerEdit.targetHours.setup =
        +this.customerEdit.targetHours.setup;
      this.customerEdit.targetHours.live = +this.customerEdit.targetHours.live;
      this.customerEdit.targetHours.demo = +this.customerEdit.targetHours.demo;
      this.customerEdit.targetHours.historical =
        +this.customerEdit.targetHours.historical;
      try {
        const data = await customers.updateCustomer(
          this.customerEdit.id,
          this.customerEdit,
        );
        this.setCustomersList([]);
        await this.setCustomer(data);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        this.isEditMode = false;
      }
    },
    enableEditMode() {
      Object.assign(
        this.customerEdit,
        R.clone({
          ...this.customer,
          address: isNil(this.customer.address)
            ? { ...defaultLocationData() }
            : this.customer.address,
          tin: isNil(this.customer.tin) ? '' : this.customer.tin,
        }),
      );
      this.partner();
      this.isEditMode = true;
    },
    cancelEditMode() {
      Object.assign(this.customerEdit, R.clone(this.customer));
      this.isEditMode = false;
    },
    handleShowEnergyStarDialog() {
      this.showEnergyStarDiag = true;
    },
    handleShowEnergyStarAccDialog() {
      this.showEnergyStarAccDiag = true;
      this.showEnergyStarDiag = false;
    },
    handleEnergyStarModals() {
      if (_.isEmpty(this.energyStarAccData)) {
        this.handleShowEnergyStarDialog();
      } else {
        this.handleShowEnergyStarAccDialog();
      }
    },
    handleShowEditUserRoleModal(user) {
      this.editUser = user;
      this.showEditUserRoleModal = true;
    },
    handleCloseAndRefreshData() {
      this.showEnergyStarAccDiag = false;
      this.getEnergyStarAccount();
    },
    onHover(position, tooltip) {
      this[tooltip] = true;
      let x = position.clientX;
      let y = position.clientY;
      this.$refs[tooltip].style.top = y + 20 + 'px';
      this.$refs[tooltip].style.left = x + 20 + 'px';
    },
    onLeave() {
      this.tooltipES = false;
      this.tooltipCarbon = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/constants.scss';
.pt-16 {
  padding-top: 4px;
}
.edit-black {
  color: #3b3e48;
}
a {
  color: #4a90e2;
}
.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebeef5;
}
.info {
  padding: 20px 40px;
  height: calc(100vh - 60px);
  overflow: auto;

  &-block-title {
    margin: 20px 0;
    font-size: 16px;
    font-weight: bold;
  }

  &-switch {
    margin: 10px 0;
  }

  &-field {
    &--label,
    &--value {
      font-size: 14px;
      line-height: 36px;
    }
    &--label {
      width: 220px;
      color: #222222;
    }
    &--value {
      font-weight: 600;
      color: #4a4a4a;
      &--with-button {
        display: flex;
        &--reset-font {
          font-weight: normal;
          .btn-tooltip {
            top: 0;
            left: 0;
            position: fixed;
            z-index: 100;
            background: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 8px;
            max-width: 250px;
            height: fit-content;
            &-text {
              color: #4a4a4a;
              font-size: 12px;
              line-height: 16px;
              margin: 0;
            }
          }
        }
        .setting-status {
          padding-right: 2em;
        }
      }
    }
  }
}
.wrapper.el-button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
}

.button-add-user {
  float: right;
  font-size: 16px;
  font-weight: 600;
}
.modal-dialog {
  /deep/ {
    .el-dialog__header {
      padding-bottom: 0;
    }
    .el-dialog__body {
      padding-bottom: 1px;
    }
  }
  .field-error {
    position: absolute;
    left: 0;
    top: calc(100% + 1px);
    text-align: left;
  }
  &--text {
    position: relative;
    padding-left: 10px;
    line-height: 17px;
    color: #909399;
    font-size: 12px;
    letter-spacing: 0;
    word-break: break-word;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 2px;
      background-color: #2893f9;
    }
  }
  &_field {
    margin: 20px 0;
    .el-input {
      margin-right: 15px;
      &.el-input--small {
        .el-input__inner {
          line-height: 30px;
          height: 30px;
        }
      }
    }
    .el-button {
      padding: 0 20px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
    }
    label {
      flex: none;
      width: 130px;
      margin: 0;
      line-height: 30px;
    }
    &--email {
      padding: 45px 0 12px 0;
      color: #222222;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
      word-break: break-word;
    }
    &--notification-email {
      padding: 30px 0 15px 0;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 14px;
      span {
        color: #606266;
      }
    }
  }
  &--add-existing-user {
    .modal-dialog_actions {
      margin: 0;
      padding: 0 0 30px 0;
    }
  }
  &--add-new-user {
    /deep/ .el-dialog__body {
      .modal-dialog_field {
        margin-bottom: 15px;
      }
    }
    .modal-dialog_actions {
      margin: 0;
      padding: 0 0 30px 0;
    }
  }
  .el-col {
    &:first-child {
      padding-right: 15px;
    }

    + .el-col {
      padding-left: 15px;
    }
  }
}
.payment-settings-button {
  font-weight: bold;
  width: 200px;
  &:disabled {
    border-color: #9ec0e9;
    background-color: #9ec0e9;
    opacity: 0.8;
    color: #ffffff;
  }
}
.icon-check {
  &__size {
    font-size: 16px;
  }
}
.modal-dialog_actions {
  justify-content: space-between;
}
.is-disabled {
  .icon-send-email {
    background-image: url(../../assets/customers/send-email-disabled.svg);
  }
}
.modal-edit {
  .el-button {
    + .el-button {
      margin-left: 20px;
    }
  }
}

.btn {
  &-remove {
    i {
      font-size: 16px;
      color: #dcdfe6;
    }
  }
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover {
  .btn-remove {
    i {
      color: #f86259;
    }
  }
}
.pl-7 {
  padding-left: 7px;
}
/deep/ .modal-dialog .el-dialog__headerbtn {
  top: 15px;
  right: 15px;
}
</style>
<style lang="scss" scoped>
.customer-info-page {
  .main-block-title {
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-text {
      line-height: 36px;
    }
    &-actions {
      .el-button {
        font-size: 14px;
        font-weight: 600;
        min-width: 106px;
        /*text-decoration: none;*/
      }
      .action-edit {
        font-size: 16px;
        min-width: auto;
      }
    }
  }
  .customer-edit-form {
    .has-error {
      input {
        border: 1px solid #fb1c12;
      }
    }
    .el-input {
      font-size: 14px;
      line-height: 32px;
      width: 100px;
      &--customer-name {
        width: 200px;
      }
    }
    .el-select {
      &--customer-name {
        width: 200px;
      }
    }
  }

  .el-table__row {
    cursor: auto;
  }
  @media screen and (min-width: 1441px) {
    table {
      width: 100% !important;
      colgroup {
        display: none;
      }
      tr {
        td,
        th {
          &:nth-child(1) {
            width: 80px;
          }
          &:nth-child(4) {
            width: 100px;
          }
          &:nth-child(5) {
            width: 180px;
          }
          &:nth-child(6) {
            width: 100px;
          }
        }
      }
    }
  }
  .label-bold {
    font-weight: bold;
  }
  .users-table {
    font-size: 12px;
    th {
      padding: 0;

      .cell {
        display: flex;
        align-items: center;
        height: 40px;
      }
    }
  }
}
</style>
