<template lang="pug">
  .hierarchy-modal-content
    .modal-dialog_fields(:class='{"has-error": !validName}')
      .modal-dialog_field.hierarchy-message
        label Hierarchy Name
        el-input#hierarchy-name(v-model='hierarchyName', size='small', placeholder='Name')
    .modal-dialog_note The location hierarchy name should have a unique name.
    .modal-dialog_actions
      el-button.plain-button-reverse(@click='closeModal') Cancel
      el-button(type='primary', :disabled='!hierarchyName.trim().length', @click='createHierarchy') Add Hierarchy
</template>

<script>
import { Events } from '@/lib';

export default {
  props: ['valid-name'],
  data() {
    return {
      hierarchyName: '',
    };
  },
  watch: {
    validName(val) {
      if (val) {
        this.hierarchyName = '';
      }
    },
  },
  mounted() {
    Events.$on('reset-hierarchy-modal', () => {
      this.hierarchyName = '';
    });
  },
  methods: {
    createHierarchy() {
      this.hierarchyName = this.hierarchyName.trim();
      this.$emit('create-hierarchy', this.hierarchyName);
    },
    closeModal() {
      this.$emit('close-modal');
      this.hierarchyName = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog_actions {
  padding: 30px 0;
}
.modal-dialog {
  &_fields {
    color: #606266;
    font-size: 14px;
    line-height: 19px;

    &.has-error {
      .el-input {
        position: relative;

        > .el-input__inner {
          border: 1px solid #f86259 !important;
        }

        &::after {
          display: block;
          content: 'Please enter a unique name.';
          position: absolute;
          bottom: 100%;
          left: 0;
          height: 20px;
          font-size: 12px;
          color: #f86259;
        }
      }
    }
  }
  &_field {
    display: flex;
    align-items: center;

    label {
      min-width: 132px;
      margin-top: 0;
    }
  }
  &_note {
    margin-top: 14px;
  }
  &_actions {
    padding: 30px 0;

    .el-button {
      width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
</style>
