<template lang="pug">
.filter.with-shadow
  el-row.filter-search(:class="{'with-border-instead-bottom-shadow': isOpened}")
      i.icon-search
      el-input.filter-search-input(:placeholder='placeholder',
                                   ref="searchInput",
                                   :value="searchQuery",
                                   @input="handleChange",
                                   :class='{"with-filter": showFilter, "bold-text": boldText}',
                                    data-element="search-input")
      el-button.button-filter(v-if='showFilter',
                              :class='{"button-filter__active": isOpened}',
                              @click.native='openFilters', data-testid='showFiltersBtn') Filters
        i.icon.icon-filters
  .filter-container(v-if='isOpened')
    slot
</template>

<script>
export default {
  props: {
    showFilter: {
      type: Boolean,
      default: () => true,
    },
    searchQuery: {
      type: String,
    },
    placeholder: {
      type: String,
      default: () => 'Search',
    },
    openFilter: {
      type: Boolean,
      default: () => false,
    },
    boldText: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isOpened: this.openFilter,
    };
  },
  methods: {
    handleChange(value = '') {
      this.$emit('onSearchUpdate', value);
    },
    openFilters() {
      this.isOpened = !this.isOpened;

      if (this.isOpened) {
        this.open();
      } else {
        this.close();
      }
    },
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/constants.scss';
.el-input {
  /deep/ &__inner {
    font-weight: 600;
    font-size: 13px;
  }
}
.filter {
  margin-bottom: 10px;
  &-search {
    position: relative;

    &.el-row {
      display: block;
    }

    .icon-search {
      width: 40px;
      height: 40px;
      position: absolute;
      background: url(../assets/icons/search.svg) center center no-repeat;
      z-index: 1;
    }

    .button-filter {
      min-width: 160px;
      height: 40px;
      position: absolute;
      background: #ffffff;
      color: #606266;
      text-align: center;
      border: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: solid 1px #dcdfe6;
      .icon {
        height: 8px;
        margin-right: 0;
      }
      &:hover {
        color: #fff;
        background-color: $primary-hover;
        .icon-filters {
          background-image: url(../assets/icons/filters-active.svg);
        }
      }
      &__active {
        background-color: $primary;
        color: #ffffff;
        .icon-filters {
          background-image: url(../assets/icons/filters-active.svg);
        }
      }
    }
  }

  &-container {
    background: #ffffff;
  }

  .with-border-instead-bottom-shadow {
    border-bottom: solid 1px #dcdfe6;
  }
}
</style>

<style lang="scss">
.filter-search-input.el-input {
  &.with-filter {
    width: calc(100% - 160px);
  }
  border: none;
  > input {
    border: none;
    padding: 0 44px !important;
  }
}
</style>
