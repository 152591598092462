<template>
  <div class="delete-prompt">
    <div class="editor-modal_content">
      <p>
        Are you sure you want to delete the location:
        <span class="bold">{{ locationName }}</span
        >?
      </p>
      <p>
        If there is weather information or other information related to this
        location, it will also be deleted.This action cannot be undone.
      </p>

      <p>
        To confirm deletion of this location, type "DELETE" in the text box and
        click "Confirm Delete".
      </p>
      <br />
    </div>
    <div class="el-input el-input--small el-input--prefix">
      <input type="text" v-model="deleteConfirm.text" />
    </div>
    <div class="editor-modal_actions">
      <el-button @click="cancel"> Cancel</el-button>
      <el-button
        class="btn-close"
        type="primary"
        @click="confirm"
        :disabled="isDisabled"
      >
        Confirm Delete</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ['locationName', 'deleteConfirm'],
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  computed: {
    isDisabled() {
      if (this.deleteConfirm.text === 'DELETE') return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  text-align: center;
}
.bold {
  font-weight: 700;
}

.editor-modal {
  &_content {
    text-align: center;
    word-break: normal;
  }
  &_actions {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .el-button {
      width: 78px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 12px;
      border-radius: 100px;
      font-weight: 500;

      &.btn-close {
        width: 126px;
      }
    }
  }
}
</style>
