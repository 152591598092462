<template lang="pug">
  .history-modal
    .history-table
      el-table(:data="historyFormatted", max-height='320', empty-text=' ')
        el-table-column(label='Date Processed', prop='created_at')
          template(slot-scope='scope')
            span.processed-date {{ dateFormat(scope.row) }}
        el-table-column(label='Bill Batch ID')
          template(slot-scope='scope')
            span {{ scope.row.batch_id || 'N/A' }}
        el-table-column(label='Download Processed File', width="190px")
          template(slot-scope='scope')
            el-button.el-button.el-button--text(v-if="scope.row.batch_id === 'Manual Update'")
            el-button.el-button.el-button--text(v-else-if="scope.row.down_file_id", @click="getProcessedFileUrl(scope.row.down_file_id)") Download
            span(v-else) N/A
    .history-footer
      span * — Current Bill version

</template>

<script>
import { batches } from '@/api';
import { DateTime } from 'luxon';
import _ from 'lodash';
export default {
  props: {
    history: {
      type: Array,
      default: () => [],
    },
    customerId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    historyFormatted() {
      let history = _.cloneDeep(this.history);
      // sort by ascending
      const sorted = _.sortBy(history, [
        function (row) {
          return DateTime.fromISO(row.created_at).valueOf();
        },
      ]);
      const billBatchIds = [];
      sorted.forEach((row, i, arr) => {
        const { batch_id, down_file_id } = row;
        if (!_.isEmpty(batch_id) && !_.isEmpty(down_file_id)) {
          if (billBatchIds.includes(batch_id)) {
            arr[i].batch_id = 'Manual Update';
          } else {
            billBatchIds.push(batch_id);
          }
        }
      });
      return sorted.reverse();
    },
  },
  methods: {
    dateFormat(row) {
      return `${DateTime.fromISO(row.created_at).toFormat('LL/dd/yyyy')} ${
        row.status === 'active' ? '*' : ''
      }`;
    },
    async getProcessedFileUrl(file_id) {
      const processedFileUrl = await batches.getProcessedFileUrl(
        this.customerId,
        file_id,
      );
      window.open(processedFileUrl.link, '_self');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../../styles/constants';

.history-table {
  .processed-date {
    color: #606266;
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
  }
}

.history-footer {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  span {
    color: #273a58;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
  }
}
</style>
