import * as R from 'ramda';
import { http, dataMiddleware, batchTypes } from '@/lib';
import { hosts } from '@/lib/api.configs';
import qs from 'qs';

const convertDate = (batches) => {
  if (batches.data) {
    batches.data = batches.data.map((item) => ({
      ...item,
      batch_type: (
        batchTypes.find((e) => e.value === item.batch_type) || { label: '-' }
      ).label,
    }));
  }

  return batches;
};

const reduceUploads = R.compose(convertDate);

// const post = (id, batch) => http(hosts.v1)
//   .post(`/customers/${id}/batches`, batch)
//   .then(dataMiddleware);

const validate = (id, params) =>
  http(hosts.v1)
    .post(`/customers/${id}/batches/validate`, params)
    .then(dataMiddleware);

const list = (params) =>
  http(hosts.v1)
    .get(`/batches?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }))
    .then(reduceUploads)
    .catch((e) => console.log('This is the error response: ', e.response));

const getProcessedFileUrl = (customerId, fileId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/files/${fileId}`)
    .then(dataMiddleware);

export {
  // post,
  list,
  validate,
  getProcessedFileUrl,
};
