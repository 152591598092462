import { http, dataMiddleware } from '@/lib';
import { hosts } from '../lib';
import qs from 'qs';

// --- Auth
const authenticate = (email, password) =>
  http(hosts.v1)
    .post('/auth/login', { basic: { email, password } })
    .then(dataMiddleware);
const refreshToken = (refreshToken) =>
  http(hosts.v3)
    .post('/auth/refresh-token', { refreshToken })
    .then(dataMiddleware);
const forgotPassword = async (email) =>
  http(hosts.v1).post('/auth/forgot-password', { email }).then(dataMiddleware);
const resetPassword = async (token, password) =>
  http(hosts.v1)
    .post(`/auth/reset/${token}`, { password })
    .then(dataMiddleware);
const checkResetToken = async (token) =>
  http(hosts.v1).get(`auth/reset/${token}`).then(dataMiddleware);
const refreshPassword = (identity) => http().get('').then(dataMiddleware);

// --- Users methods
const getMe = () => http(hosts.v1).get(`/auth/me`).then(dataMiddleware);
const getUser = (id = 'me', params) =>
  http(hosts.v3)
    .get(`/users/${id}?${qs.stringify(params)}`)
    .then(dataMiddleware);
const list = (params) =>
  http(hosts.v1).get('/users', { params }).then(dataMiddleware);
const unAssignUser = async (user_id, body) =>
  http(hosts.v1).post(`/users/${user_id}/unassign`, body).then(dataMiddleware);
const assignUser = async (user_id, body) =>
  http(hosts.v1).post(`/users/${user_id}/assign`, body).then(dataMiddleware);
const deactivate = async (user_id) =>
  http(hosts.v1)
    .post(`/users/${user_id}/deactivate`, { user_id })
    .then(dataMiddleware);
const activate = async (user_id) =>
  http(hosts.v1)
    .post(`/users/${user_id}/activate`, { user_id })
    .then(dataMiddleware);
const deleteUser = async (user_id) =>
  http(hosts.v1).delete(`/users/${user_id}`).then(dataMiddleware);
const changePassword = ({ user_id, old_password, password }) =>
  http(hosts.v1)
    .post(`/users/${user_id}/change-password`, { old_password, password })
    .then(dataMiddleware);
const userList = (params) =>
  http(hosts.v3).get(`users?${qs.stringify(params)}`);
export {
  authenticate,
  refreshToken,
  checkResetToken,
  forgotPassword,
  refreshPassword,
  resetPassword,
  userList,
  getUser,
  getMe,
  list,
  unAssignUser,
  assignUser,
  deactivate,
  activate,
  deleteUser,
  changePassword,
};
