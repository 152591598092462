<template lang="pug">
  .tab-bill-account-history
    .filters-container
      filters(@change="filtersChange")
    .filter
      .filter--label.filter--label__grey {{ virtualAccounts.length }} Accounts found
      .filter--input.filter--input__virtual-account
        el-select(v-model="virtualAccountId" size="small" filterable)
          el-option(v-for="item in virtualAccounts", :key="item.virtualAccountId", :label="virtualAccountLabel(item)", :value="item.virtualAccountId")
    .info
      .info--label Meter readings for virtual Account ID {{ virtualAccountId }}
      .info--row
        .info--col
          .info--line
            .info--label Meter ID:
            .info--value {{ prop('meterSerial', virtualAccount) }}
          .info--line
            .info--label Utility:
            .info--value {{ prop('commodity', virtualAccount) }}
          .info--line
            .info--label Location:
            .info--value
              a(@click="loadBillPath(`/customers/${customerId}/locations/${virtualAccount.locationId}`)") {{ pathOr('', ['location', 'name'], virtualAccount) }}
        .info--col
          .info--line
            .info--label Service Account ID:
            .info--value {{ prop('accountCode', virtualAccount) }}
          .info--line
            .info--label Type:
            .info--value {{ prop('billType', virtualAccount) }}
    .filter
      .filter--label Service Start Date from:
      .filter--input.filter--input__daterange
        el-date-picker(
          v-model="period"
          format='MM/dd/yy'
          type="daterange"
          range-separator="To"
          size="small"
          start-placeholder="Start date"
          end-placeholder="End date")
    .table
      .table--title Showing {{ tableData.length }} of {{ pagination.total }} bill blocks
      .table--content
        el-table(:data="tableData" size="small" :row-class-name="rowStyle" :key="keyTable")
          el-table-column(label='Bill ID')
            template(slot-scope='scope')
              a(@click="loadBillPath({name: 'customer.bills.info', params: {customerId, billId: scope.row.bill_id}, query: billLinkQuery})")
                | {{ scope.row.bill_id }}
          el-table-column(label='Start Date')
            template(slot-scope='scope')
              span {{ scope.row.start_date | datetime('LL/dd/yy') }}
          el-table-column(label='End Date')
            template(slot-scope='scope')
              span {{ scope.row.end_date | datetime('LL/dd/yy') }}
          el-table-column(v-if="fullView" label='Days of Service')
            template(slot-scope='scope')
              span
                span.day-of-service {{ scope.row.daysOfService }}
                span ({{ scope.row.serviceDateMethod }})
          el-table-column(label='Subcharges')
            template(slot-scope='scope')
              span {{ scope.row.subcharges | currency }}
          el-table-column(label='Consumption')
            template(slot-scope='scope')
              span {{ +scope.row.consumption | consumptionFilter }}
          el-table-column(v-if="fullView" label='Gen Consumption')
            template(slot-scope='scope')
              span {{ scope.row.genConsumption  | consumptionFilter }}
          el-table-column(v-if="fullView" label='Demand')
            template(slot-scope='scope')
              span {{ scope.row.demand | toFixed2 }}
          el-table-column(v-if="fullView" label='Unit Cost')
            template(slot-scope='scope')
              span {{ scope.row.unitCost | unitCost }}
          el-table-column(v-if="fullView" label='Load Factor')
            template(slot-scope='scope')
              span {{ scope.row.load_factor | toFixed2 }}
          el-table-column(v-if="fullView" label='Estimated')
            template(slot-scope='scope')
              span {{ scope.row.estimated ? 'Yes' : '' }}
          el-table-column(v-if="fullView" label='Rate Plan')
            template(slot-scope='scope')
              span {{ scope.row.rate_code }}
      el-pagination.paginator-wrapper(background,
        :disabled="isLoading"
        v-if='pagination.total > pagination.limit || pagination.limit > 20',
        layout='prev, pager, next, ->, sizes',
        :page-size='pagination.limit',
        :current-page='currentPage',
        @current-change='changeOffset',
        @size-change='changeLimit',
        :total='pagination.total')
</template>
<script>
import * as R from 'ramda';
import Filters from './AccountHistoryFilters';
import { virtual_accounts } from '@/api';
import pipes from '@/lib/pipes';
import { DateTime } from 'luxon';
import { mapGetters, mapActions } from 'vuex';

const defaultPagination = () => ({
  total: 0,
  limit: 20,
  offset: 0,
});

export default {
  components: {
    Filters,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fullView: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    const { virtual_account_id } = this.$route.query;
    this.virtualAccountId = virtual_account_id || null;
  },
  filters: {
    consumptionFilter: function (value) {
      return `${pipes.consumption((+value).toFixed(3))}`;
    },
    arrayToString: function (value) {
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return '';
    },
    toFixed2: function (value) {
      const num = Number(value);
      return Number.isNaN(num) ? value : num.toFixed(2);
    },
    unitCost: function (value) {
      return Number.isNaN(Number(value))
        ? value
        : '$' + Number(value).toFixed(2);
    },
  },
  data() {
    return {
      isLoading: false,
      tableData: [],
      period: null,
      virtualAccountId: null,
      filters: {},
      pagination: defaultPagination(),
      orders: ['-end_date', '-start_date', '-bill_id'],
      keyTable: 0,
    };
  },
  computed: {
    ...mapGetters({
      bill: 'bills/bill',
    }),
    customerId() {
      return this.$route.params.customerId;
    },
    virtualAccounts() {
      const filters = R.omit(['location_name'], this.filters);
      const isIncluded = (item) => {
        let locationMatch = true;
        if (R.has('location_name', this.filters)) {
          locationMatch = this.filters.location_name.includes(
            R.path(['location', 'name'], item),
          );
        }
        return (
          R.reduce(
            (acc, key) => {
              const match = filters[key].includes(item[key]);
              return match && acc;
            },
            true,
            R.keys(filters),
          ) && locationMatch
        );
      };
      const list = R.filter(isIncluded)(this.items);
      return this.sortVirtualAccounts(
        R.uniqBy(R.prop('virtualAccountId'), list),
      );
    },
    virtualAccount() {
      if (this.virtualAccountId) {
        return (
          R.find(
            R.propEq('virtualAccountId', this.virtualAccountId),
            this.items,
          ) || {}
        );
      } else {
        return {};
      }
    },
    currentPage() {
      return this.offset / this.limit + 1;
    },
    billLinkQuery() {
      const { meter_serial, commodity, account_code } = this.virtualAccount;
      return {
        meter_serial: [meter_serial],
        commodity: [commodity],
        account_code: [account_code],
      };
    },
  },
  watch: {
    fullView() {
      this.keyTable++;
    },
    async virtualAccountId(val) {
      if (val) {
        await this.loadData();
      } else {
        this.pagination = defaultPagination();
        this.tableData = [];
      }
    },
    period() {
      this.loadData();
    },
  },
  methods: {
    ...mapActions({
      $loadBillPath: 'bills/loadBillPath',
    }),
    pathOr: R.pathOr,
    prop: R.prop,
    loadBillPath(path) {
      this.$loadBillPath({
        path: path,
        router: this.$router,
      });
    },
    filtersChange(filters) {
      this.filters = filters;
      this.virtualAccountId = R.prop(
        'virtualAccountId',
        R.head(this.virtualAccounts),
      );
    },
    async getLatestBillBlock(virtual_account_id) {
      try {
        const response = await virtual_accounts.getAccountHistory({
          customer_id: this.customerId,
          virtual_account_id,
          params: {
            limit: 1,
            orders: this.orders,
          },
        });
        const { data = [] } = response;
        return R.head(data);
      } catch (e) {
        console.log(`can't load virtual account history`);
      }
    },
    async loadData() {
      const params = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        orders: this.orders,
      };
      if (Array.isArray(this.period)) {
        params.start_date = DateTime.fromJSDate(this.period[0])
          .setZone('UTC', { keepLocalTime: true })
          .toString();
        params.end_date = DateTime.fromJSDate(this.period[1])
          .setZone('UTC', { keepLocalTime: true })
          .endOf('day')
          .toString();
      }
      const request = {
        customer_id: this.customerId,
        virtual_account_id: this.virtualAccountId,
        params,
      };
      try {
        const response = await virtual_accounts.getAccountHistory(request);
        const { data = [], meta = {} } = response;
        this.pagination.total = +R.pathOr(0, ['content_range', 'total'], meta);
        this.tableData = data;
      } catch (e) {
        console.log(`can't load virtual account history`);
      }
    },
    async changeOffset(e) {
      this.pagination.offset = (e - 1) * this.pagination.limit;
      await this.loadData();
    },
    async changeLimit(e) {
      this.pagination.limit = +e;
      this.pagination.offset = 0;
      await this.loadData();
    },
    virtualAccountLabel(item) {
      if (item) {
        const { meterSerial, accountCode, commodity, billType } = item;
        return `MID: ${meterSerial}, SA ID: ${accountCode}, ${commodity} ${billType}`;
      }
      return '';
    },
    sortVirtualAccounts(items) {
      const firstSort = (item) => {
        const commodity = R.prop('commodity')(item);
        return R.cond([
          [R.equals('ELECTRIC'), () => 1],
          [R.equals('NATURALGAS'), () => 2],
          [R.T, () => 3],
        ])(commodity);
      };

      return R.sortWith([
        R.ascend(firstSort),
        R.ascend(R.prop('meterSerial')),
        R.ascend(R.prop('accountCode')),
        R.descend(R.prop('billType')),
      ])(items);
    },
    rowStyle({ row }) {
      return this.bill && +row.id === +this.bill.id ? 'row_highlighted' : '';
    },
  },
};
</script>
<style lang="scss">
.tab-bill-account-history {
  padding: 10px 20px 20px 20px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
  color: #222222;

  a {
    cursor: pointer;
  }

  .day-of-service {
    display: inline-block;
    min-width: 20px;
  }

  .row_highlighted {
    font-weight: 700;
  }

  .filter {
    &--label {
      margin: 0 0 6px 0;
      letter-spacing: 0;
      line-height: 17px;

      &__grey {
        color: #94969d;
      }
    }

    &--input {
      &__virtual-account {
        max-width: 640px;

        .el-select {
          width: 100%;
        }
      }

      &__daterange {
        max-width: 265px;

        .el-date-editor {
          width: 100%;

          .el-range-separator {
            width: 28px;
          }

          .el-range-input {
            font-size: 14px;
          }
        }
      }
    }
  }

  .info {
    display: block;
    margin: 16px 0 40px 0;

    &--row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &--col {
      margin: 0 50px 0 0;
    }

    &--line {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &--label {
      padding-right: 8px;
      line-height: 17px;
      margin: 0 0 10px 0;
    }

    &--value {
      color: #273a58;
      font-weight: 700;
    }
  }

  .table {
    margin: 24px -20px 0 -20px;

    th,
    td {
      padding: 7px 0;
    }

    &--title {
      margin: 0 0 8px 20px;
      color: #666666;
      line-height: 22px;
    }
  }
}
</style>
