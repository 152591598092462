<template lang="pug">
  .location-control.control
    el-dialog.location-modal(:title="title",
      custom-class="select-location-dialog",
      :visible="isVisible",
      :append-to-body="true",
      top="40px",
      width="640px",
      @close="close")
      remittance-control(v-if="isVisible",
        :locations="providersVendors",
        :stored-locations="selectedLocation",
        @select-locations="selectLocation")
      .location-control_actions
        el-button(type="primary", size="small", @click="changeFilter", :disabled="isNotSelected") Select
</template>

<script>
import RemittanceControl from './RemittanceControl';
import * as R from 'ramda';

export default {
  components: {
    RemittanceControl,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    selectedLocation: {
      type: Object,
      default: () => {},
      required: true,
    },
    vendor: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      selected: this.selectedLocation,
      isLoading: false,
    };
  },
  computed: {
    providersVendors() {
      return R.pathOr([], ['providers_vendors'], this.vendor);
    },
    title() {
      return `Select remittance address for ${
        this.vendor.pretty_name || this.vendor.code
      }`;
    },
    isNotSelected() {
      return R.isEmpty(this.selected);
    },
  },
  watch: {
    selectedLocation(location) {
      this.selected = location;
    },
  },
  methods: {
    selectLocation(location) {
      this.selected = location;
    },
    changeFilter() {
      this.$emit('onChange', this.selected);
      this.$emit('close');
      this.selected = {};
    },
    close() {
      this.$emit('close');
      this.selected = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.location-control_actions {
  padding-top: 20px;
  text-align: center;

  .el-button {
    width: 160px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 900;
  }
}
</style>

<style lang="scss">
.location-modal {
  .location-control_container {
    overflow-y: auto;
  }

  .el-dialog__header {
    padding: 40px 40px 0 40px;
  }

  .el-dialog__title {
    color: #273a58;
    font-size: 24px;
    font-weight: 700;
  }

  .el-dialog__headerbtn {
    top: 42px;
    right: 38px;
  }

  .el-dialog__body {
    padding: 20px 15px;
  }

  .el-tabs__header {
    padding: 0 25px;
    margin-bottom: 20px;
  }

  .el-tabs__item {
    font-weight: 900;
  }
}
</style>

<style lang="scss">
.location-modal {
  display: flex;
}
.select-location-dialog {
  margin: auto !important;
}
</style>
