import { render, staticRenderFns } from "./Upload.vue?vue&type=template&id=3536ed15&scoped=true&lang=pug&"
import script from "./Upload.vue?vue&type=script&lang=js&"
export * from "./Upload.vue?vue&type=script&lang=js&"
import style0 from "./Upload.vue?vue&type=style&index=0&id=3536ed15&lang=scss&scoped=true&"
import style1 from "./Upload.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3536ed15",
  null
  
)

export default component.exports