<template lang="pug">
  .location()
    .page-header
      router-link.main-block-title.link.clickable(:to="{ name: 'customer.locations' , query: $route.query}")
        i.icon.icon-arrow-pointing-to-right.main-block-title-back-icon
        span.main-block-title-text Location Detail for {{ location.name }}
        span.weather-active.dot-margin(:class="[location.weather_active ? 'weather-active-color' : 'weather-active-not']")
      .page-header-actions
        el-button.btn.btn-delete(type="text", size="mini", @click="handleShowWeather()") Weather Settings
      .page-header-actions
        el-button.btn.btn-delete(type="text", size="mini", @click="handleShowDelete()") Delete Location

    el-dialog(:visible="showVirtualAccountsNotification"
      @close="handleHideVirtualAccountsNotification()"
      width="480px")
      virtual-accounts-notification(
        @close="handleHideVirtualAccountsNotification()"
        :locationName='location.name'
        :message="virtualAccountsMessage"
      )
    el-dialog(:visible="showDeletePrompt"
      @close="handleHideDeletePrompt()"
      width="800px")
      delete-prompt(
        @confirm="handleConfirmDelete()"
        :locationName='location.name'
        :deleteConfirm='deleteConfirm'
        @cancel="handleHideDeletePrompt()"
      )
    el-dialog(v-if="showWeatherSettings"
      :visible="showWeatherSettings"
      @close="handleHideWeatherSettings()"
      width="480px"
      title="Change Weather Settings")
      weather-modal(
        @confirm="handleConfirmWeatherSettings($event)"
        :locationName='location.name'
        :weatherActive='location.weather_active'
        :weatherSetup='location.weather_setup'
        @cancel="handleHideWeatherSettings()"
      )
    .hierarchy-breadcrumbs
      .hierarchy-breadcrumbs-item(v-if="!hierarchyLoading", v-for="{name, id} in hierarchyBreadcrumbs", :key="id")
        span.name(@click="breadCrumbsItemClick(id)") {{ name }}
        span.nav >
    .location-content(v-if='location')
      el-tabs(v-model='activeTab')
        el-tab-pane(label='Virtual Accounts', name='virtual-accounts', data-action='Show virtual accounts')
          virtual-accounts-tab(v-if="location", :location='location')
        el-tab-pane(label='Attributes', name='attributes', data-action='Show attributes')
          attributes-tab(:location='location')
</template>

<script>
import * as R from 'ramda';
import _ from 'lodash';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AttributesTab from './Tabs/Attributes';
import DeletePrompt from './Modals/DeletePrompt';
import WeatherModal from './Modals/WeatherModal';
import VirtualAccountsNotification from './Modals/VirtualAccountsNotification';
import VirtualAccountsTab from './Tabs/VirtualAccounts';
import { locations } from '@/api';
import { Events } from '@/lib';

export default {
  components: {
    AttributesTab,
    DeletePrompt,
    WeatherModal,
    VirtualAccountsNotification,
    VirtualAccountsTab,
  },
  data() {
    return {
      deleteConfirm: { text: '' },
      activeTab: 'virtual-accounts',
      showDeletePrompt: false,
      showWeatherSettings: false,
      showVirtualAccountsNotification: false,
    };
  },
  beforeMount() {
    this.$clearState();
  },
  beforeDestroy() {
    this.$clearState();
  },
  computed: {
    ...mapGetters({
      location: 'locations/location',
      groups: 'virtual_accounts/groups',
      isLoading: 'locations/loading',
      hierarchy: 'locations/hierarchy',
      hierarchyLoading: 'locations/hierarchyLoading',
    }),
    virtualAccountsMessage() {
      return `You cannot delete location <b>${this.location.name}</b> because there are one or more Virtual Accounts mapped to this location.`;
    },
    isPayloadEmpty() {
      return R.isEmpty(this.location.payload);
    },
    parentNodeDefaultHierarchy() {
      return (
        _.get(this, 'hierarchy', [])
          .slice()
          .find((h) => !h.parentId) || {}
      );
    },
    hierarchyBreadcrumbs() {
      /**
       * Search for lo `location_id` through `hierarchy`,
       * if found then search for parents by reducing path
       */
      const hierarchy = _.get(this, 'hierarchy', []).slice();
      const found =
        hierarchy.find((h) => h.locationId === this.location.id) || {};
      return [
        ..._.get(found, 'path', []).reduce(
          (acc, item) => [...acc, hierarchy.find((h) => h.id === item)],
          [],
        ),
        { id: found.id, name: _.get(found, 'location.name', '') },
      ];
    },
  },
  async mounted() {
    await this.fetchHierarchy();
    await this.load();
  },
  methods: {
    ...mapActions({
      $fetchGroups: 'virtual_accounts/fetchGroups',
      $setLocation: 'locations/setLocation',
      $fetchDefaultHierarchy: 'locations/fetchDefaultHierarchy',
      $putLocation: 'locations/putLocation',
    }),
    ...mapMutations({
      $clearState: 'locations/clearState',
    }),
    async load(
      customerId = this.$route.params.customerId,
      locationId = this.$route.params.locationId,
    ) {
      await this.$setLocation({ customerId, locationId });
      if (R.isEmpty(this.location)) {
        Events.$emit('not.found');
      }
    },
    fetchHierarchy() {
      const { customerId } = this.$route.params;
      this.$fetchDefaultHierarchy({ customerId });
    },
    breadCrumbsItemClick(item) {
      this.$router.push(
        `/customers/${this.$route.params.customerId}/hierarchies/${this.parentNodeDefaultHierarchy.id}?selected=${item}`,
      );
    },
    handleHideVirtualAccountsNotification() {
      this.showVirtualAccountsNotification = false;
    },
    async handleConfirmWeatherSettings(status) {
      if (!status.weather_changed) {
        this.showWeatherSettings = false;
        return;
      }
      const { locationId, customerId } = this.$route.params;
      let payload = _.pickBy(
        this.location.payload,
        (value, key) => key !== 'metadata',
      );
      let metadata = this.location.payload.metadata;
      if (metadata && metadata.length) {
        metadata.forEach((item) => {
          payload[item.id] = item.value;
        });
      }
      let data = _.pickBy(this.location, (value, key) => key !== 'payload');
      data.weather_active = status.weather_active;
      let body = {
        ...data,
        payload: { ..._.mapKeys(payload, (value, key) => _.camelCase(key)) },
      };
      _.each(payload, (v, key, obj) => {
        if (key !== 'weather_active' && key !== 'weather_setup') {
          obj[key] = _.trim(obj[key]);
        }
        if (['latitude', 'longitude'].indexOf(key) >= 0) {
          obj[key] = this.setMaximumDecimals(obj[key]);
        }
        return true;
      });
      body = _.mapKeys(body, (value, key) => _.camelCase(key));
      body.buildingType = body.buildingType.toUpperCase();
      try {
        await this.$putLocation({
          customerId,
          locationId,
          body,
        });
      } catch (e) {
        this.$message({
          showClose: true,
          message: `Error: Location update error`,
          type: 'error',
        });
      }
      this.showWeatherSettings = false;
    },
    async handleShowDelete() {
      this.deleteConfirm.text = '';
      if (this.groups.length > 0) {
        this.showVirtualAccountsNotification = true;
      } else {
        this.showDeletePrompt = true;
      }
    },
    async handleShowWeather() {
      this.showWeatherSettings = true;
    },
    handleHideDeletePrompt() {
      this.deleteConfirm = { text: '' };
      this.showDeletePrompt = false;
    },
    handleHideWeatherSettings() {
      this.showWeatherSettings = false;
    },
    async handleConfirmDelete() {
      const { locationId, customerId } = this.$route.params;
      try {
        await locations.deleteLocation(customerId, locationId);
      } catch (e) {
        if (
          e.response.data.message !== undefined &&
          e.response.data.message !== null
        ) {
          this.$message({
            showClose: true,
            message: `Error: ${e.response.data.message}`,
            type: 'error',
          });
        } else if (
          e.response.data.error !== undefined &&
          e.response.data.error !== null
        ) {
          this.$message({
            showClose: true,
            message: `Error: ${e.response.data.error}`,
            type: 'error',
          });
        }
      }
      this.showDeletePrompt = false;
      this.$router.go(-1);
    },
    setMaximumDecimals(coordinate) {
      coordinate = Number.parseFloat(coordinate);
      if (_.isFinite(coordinate)) {
        return coordinate.toFixed(6);
      }
      return 'N/A';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/constants.scss';
@import '../../../styles/dot.scss';
.location {
  padding: 20px 40px;
  min-height: calc(100vh - (#{$header-height}));
  /*overflow: auto;*/

  /deep/ .main-block-title {
    padding-bottom: 0;
  }

  &-content {
    /deep/ .el-tabs__item {
      &.is-active {
        font-weight: 700;
      }
    }
  }
  .hierarchy-breadcrumbs {
    margin-top: 7px;
    margin-left: 34px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #7d89a6;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;

    &-item {
      cursor: pointer;

      .name,
      .nav {
        margin-right: 6px;
      }

      .name:hover {
        color: #409eff;
      }

      &:last-child {
        .nav {
          display: none;
        }
      }
    }
  }

  .btn {
    text-decoration: none;
    &-delete {
      margin: 0 15px;
      height: 30px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 14px;
      font-weight: 600;
    }
    &-delete {
      color: #606266;
      &:hover {
        color: #f86259;
      }
    }
  }
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.weather-active {
  @include weather-active;
}
.dot-margin {
  margin-left: 10px;
}
</style>
