<template lang="pug">
  .hierarchy-modal-content
    .modal-dialog-breadcrumbs {{ breadcrumbs }}
    .modal-dialog_fields(:class='{"has-error": !isValid}')
      .modal-dialog_field.hierarchy-message
        label Group Name
        el-input(v-model='name', size='small', placeholder='Name')
    .modal-dialog_note The location group name should have a unique name. You can create no more than 10 nestings.
    .modal-dialog_actions
      el-button.plain-button-reverse(@click='closeModal') Cancel
      el-button(type='primary', :disabled='!name.length', @click='create') Add New Group
</template>

<script>
import { Events } from '@/lib';

export default {
  data() {
    return {
      name: '',
      isValid: true,
    };
  },
  props: {
    breadcrumbs: {
      type: String,
      required: true,
      default: () => '',
    },
    validateLocationGroup: {
      type: Function,
      required: true,
      default: () => false,
    },
  },
  watch: {
    name() {
      if (!this.isValid) {
        this.isValid = true;
      }
    },
  },
  mounted() {
    Events.$on('reset-location-group-modal', () => {
      this.name = '';
    });
  },
  methods: {
    create() {
      this.isValid = this.validateLocationGroup(this.name);
      if (this.isValid) {
        this.$emit('createLocationGroup', this.name);
        this.name = '';
      }
    },
    closeModal() {
      this.$emit('close-modal');
      this.name = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog-breadcrumbs {
  color: #909399;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 16px;
}

.modal-dialog_actions {
  padding: 30px 0;
}
.modal-dialog {
  &_fields {
    color: #606266;
    font-size: 14px;
    line-height: 19px;
    padding-top: 6px;

    &.has-error {
      .el-input {
        position: relative;

        > .el-input__inner {
          border: 1px solid #f86259 !important;
        }

        &::after {
          display: block;
          content: 'Please enter a unique name.';
          position: absolute;
          bottom: 100%;
          left: 0;
          height: 20px;
          font-size: 12px;
          color: #f86259;
        }
      }
    }
  }
  &_field {
    display: flex;
    align-items: center;

    label {
      min-width: 132px;
      margin-top: 0;
    }
  }
  &_note {
    margin-top: 14px;
  }
  &_actions {
    padding: 30px 0;

    .el-button {
      width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;

      + .el-button {
        margin-left: 30px;
      }
    }
  }
}
</style>
