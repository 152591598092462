<template lang="pug">
  .locations-hierarchies-container.page-container
    .page-header
      h1.page-header-title Location Hierarchies for {{ customer.name }}
      .page-header-actions
        el-button.create-hierarchy-button(type='text', @click='showCreateHierarchyModal = true') + Create Hierarchy
    filter-input(:searchQuery='params.query', :showFilter='false', @onSearchUpdate='onSearchQueryChange')

    el-table.locations-hierarchies-table.with-shadow(:data="data",
                                                     data-type="hierarchies-list",
                                                     @sort-change='sortTable',
                                                     @row-click="goTo")
      el-table-column(label="ID", prop="id", sortable)
      el-table-column(label="Hierarchy", prop="name", sortable)
      el-table-column(label="Date Created", prop="created_at", sortable)
        template(slot-scope="scope")
          span(data-type="pear-customer-created", :data-id="scope.row.id") {{ scope.row.created_at | datetime('DDD') }}
      el-table-column(width="100")
        template(slot-scope="scope")
          el-button(type="text")
            i.icon.icon-edit-filled(@click="e => navigateToHierarchy(e, scope.row.id)")
          el-button(type="text", icon="el-icon-close", @click.stop="deleteHierarchy(scope.row)")

    el-dialog.modal-dialog(title='Create a New Hierarchy', width='440px', :visible.sync='showCreateHierarchyModal', @close="resetHierarchyModal")
      create-hierarchy-modal(:valid-name="isValidName", @create-hierarchy='create', @close-modal='closeHierarchyModal')

    el-dialog.delete-modal(:visible.sync='deleteModal',
                          :modal='false',
                          :show-close='false',
                          top='45px',
                          width='410px',
                          :title="`Are you sure you want to remove ${currentHierarchy.name} and all child sub-groups in this hierarchy?`")
      delete-modal(@closeDeleteModal='closeDeleteModal',
                  @confirmDelete='confirmDelete',
                  @confirmCancel='closeDeleteModal',
                  :locationsForDelete="nestedItems.nestedLocations",
                  :groupsForDelete="nestedItems.nestedGroups")
</template>

<script>
import { hierarchies as h } from '@/api';
import { Events } from '@/lib';
import { mapState, mapActions } from 'vuex';
import { FilterInput } from '@/components';
import createHierarchyModal from './CreateHierarchyModal.vue';
import DeleteModal from './View/DeleteModal.vue';

export default {
  components: {
    FilterInput,
    createHierarchyModal,
    DeleteModal,
  },
  data() {
    return {
      hierarchiesChilds: [],
      currentHierarchy: '',
      showCreateHierarchyModal: false,
      isValidName: true,
      deleteModal: false,
    };
  },
  watch: {
    params() {
      this.fetchData();
    },
  },
  props: {
    showFilter: {
      type: Boolean,
      default: () => true,
    },
    searchQuery: {
      type: String,
    },
    placeholder: {
      type: String,
      default: () => 'Search',
    },
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer || {},
      data: (state) => state.hierarchies.data,
      isFetching: (state) => state.hierarchies.isFetching,
      params: (state) => state.hierarchies.params,
    }),
    nestedItems() {
      let countLocations = 0;
      let countGroups = 0;
      if (this.hierarchiesChilds) {
        let item = this.hierarchiesChilds;
        item.forEach((item) => {
          if (item.location_id) {
            countLocations++;
          } else if (item.name) {
            countGroups++;
          }
        });
      }
      return { nestedGroups: --countGroups, nestedLocations: countLocations };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      fetchHierarchies: 'hierarchies/fetchHierarchies',
      createHierarchy: 'hierarchies/createHierarchy',
      setParams: 'hierarchies/setParams',
    }),
    updateParams(params) {
      this.setParams({ ...this.params, ...params });
    },
    fetchData() {
      this.fetchHierarchies(this.$route.params.customerId);
    },
    onSearchQueryChange(query) {
      this.updateParams({ query, offset: 0 });
    },
    sortTable(obj) {
      let order = '';
      if (obj.prop && obj.order) {
        order = `${obj.order === 'descending' ? '-' : '+'}${obj.prop}`;
      }
      this.updateParams({ orders: [order] });
    },
    goTo({ id = 0 }) {
      const { customerId } = this.$route.params;
      this.$router.push(`/customers/${customerId}/hierarchies/${id}`);
    },
    async create(name) {
      let error = null;
      if (name === '') {
        this.isValidName = false;
        return;
      } else {
        this.isValidName = true;
      }

      await this.createHierarchy({
        customerId: this.$route.params.customerId,
        body: { name },
      }).catch(
        ({
          response: {
            data: { code },
          },
        }) => (error = code),
      );

      if (error && error === 409) {
        this.isValidName = false;
      } else {
        this.isValidName = true;
        this.closeHierarchyModal();
      }
    },
    closeHierarchyModal() {
      this.showCreateHierarchyModal = false;
    },
    resetHierarchyModal() {
      this.isValidName = true;
      Events.$emit('reset-hierarchy-modal');
    },
    navigateToHierarchy(e, id) {
      e.preventDefault();
      e.stopPropagation();
      this.$router.push(
        `/customers/${this.$route.params.customerId}/hierarchies/${id}?editMode=true`,
      );
      return false;
    },
    openDeleteModal() {
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    async deleteHierarchy(row) {
      this.currentHierarchy = row;
      const { data } = await h.fetchHierarchy(
        this.$route.params.customerId,
        row.id,
      );
      this.hierarchiesChilds = data;
      this.openDeleteModal();
    },
    async confirmDelete() {
      await h.deleteItem(
        this.$route.params.customerId,
        this.currentHierarchy.id,
        this.currentHierarchy.id,
      );
      await this.fetchData();
      this.closeDeleteModal();
    },
  },
};
</script>

<style lang="scss">
.locations-hierarchies-table {
  .el-table__row {
    cursor: pointer;
  }
  .el-icon-close {
    color: #273a58;
  }
}
</style>
