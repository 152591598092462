<template>
  <BaseModal
    :dialogVisible="dialogVisible"
    :title="isUpdateMode && isEditMode ? updateAccount : title"
    @close="close"
  >
    <template #content>
      <h2 v-if="energyStarForm.id" class="energyStarId">
        Energy Star Account Id: {{ energyStarForm.id }}
      </h2>
      <el-button
        v-if="isUpdateMode"
        class="edit-button"
        type="text"
        @click="isEditMode = !isEditMode"
        >Edit</el-button
      >
      <ErrorCard :statusMessage="statusMessage" />
      <ValidationObserver ref="observer">
        <el-form label-position="left" class="form-width">
          <h2 class="subtitle">Customer Account Credentials</h2>
          <BaseInput
            v-model="energyStarForm.username"
            :rules="isEditMode && !isUpdateMode ? 'required' : ''"
            :immediate="true"
            label="Username:"
            name="username"
            size="small"
            :isEditMode="isEditMode && !isUpdateMode"
            maxlength="60"
            :required="true"
          />
          <div
            :class="[
              isUpdateMode ? 'override-width-with-btn' : 'override-width',
            ]"
          >
            <BaseInput
              @input="handlePassFlags"
              v-model="energyStarForm.password"
              :rules="
                handleFieldErrors(
                  { changed: passFlags.changed },
                  'verify_password',
                )
              "
              ref="passwordRef"
              :immediate="true"
              vid="password"
              label="Password:"
              maxlength="120"
              name="password"
              size="small"
              :classes="isUpdateMode ? '' : 'input-width'"
              :required="true"
              :isEditMode="isEditMode"
              v-slot="{ flags }"
            >
              <el-popover
                v-if="isEditMode && isUpdateMode"
                placement="bottom"
                width="200"
                trigger="click"
                content="Password was copied to clipboard!"
              >
                <el-button
                  class="pl-10"
                  type="text"
                  slot="reference"
                  @click="getPlainPass"
                  @mouseleave.native="disabled = false"
                  >Copy password</el-button
                >
              </el-popover>
              <p
                v-if="flags.pristine && isEditMode && !isUpdateMode"
                class="text-under-input"
              >
                Create a password that is at least 8 characters long and
                includes at least three of the following: lowercase letters,
                numbers and/or special characters (such as *,#,%,etc.).
              </p>
            </BaseInput>
          </div>
          <BaseInput
            v-show="isEditMode"
            ref="passConfirmRef"
            @input="handleConfirmPassFlags"
            v-model="confirmPassword"
            :rules="
              handleFieldErrors(
                { changed: passFlags.changed, dirty: passConfirmFlags.dirty },
                'confirmed:password',
              )
            "
            label="Confirm Password:"
            name="confirmPass"
            size="small"
            :required="true"
            maxlength="120"
            :isEditMode="isEditMode"
          />
          <hr class="border" />
          <h2 class="subtitle">Organization Primary Contact</h2>
          <BaseInput
            v-model="energyStarForm.contact.firstName"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="First Name:"
            name="firstName"
            :required="true"
            size="small"
            maxlength="100"
            :isEditMode="isEditMode"
          />
          <BaseInput
            v-model="energyStarForm.contact.lastName"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="Last Name:"
            name="lastName"
            size="small"
            :required="true"
            maxlength="100"
            :isEditMode="isEditMode"
          />
          <BaseInput
            v-model="energyStarForm.contact.jobTitle"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="Job Title:"
            name="address"
            size="small"
            :required="true"
            maxlength="100"
            :isEditMode="isEditMode"
          />
          <BaseInput
            ref="emailRef"
            @input="handleEmailFlags"
            v-model="energyStarForm.contact.email"
            :immediate="isUpdateMode ? true : false"
            rules="required|email"
            vid="email"
            label="Email:"
            name="email"
            size="small"
            :required="true"
            :isEditMode="isEditMode"
          />
          <BaseInput
            v-show="isEditMode"
            ref="emailConfirmRef"
            @input="handleConfirmEmailFlags"
            v-model="confirmEmail"
            :immediate="isUpdateMode ? true : false"
            :rules="
              handleFieldErrors(
                { changed: emailFlags.changed, dirty: emailConfirmFlags.dirty },
                'confirmed:email',
              )
            "
            label="Confirm Email:"
            name="confirmEmail"
            size="small"
            :required="true"
            :isEditMode="isEditMode"
          />
          <BaseInput
            v-model="energyStarForm.contact.phone"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="Phone:"
            name="phone"
            size="small"
            :required="true"
            maxlength="30"
            :isEditMode="isEditMode"
          />
          <BaseSelect
            v-model="energyStarForm.contact.address.country"
            @change="resetState"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="Country:"
            size="small"
            name="country"
            :required="true"
            :isEditMode="isEditMode"
          >
            <el-option
              v-for="(v, k) in countries"
              :key="k"
              :label="v.pretty_name"
              :value="k"
            />
          </BaseSelect>
          <BaseSelect
            v-model="language"
            :immediate="isUpdateMode ? true : false"
            label="Language:"
            name="language"
            size="small"
            :isEditMode="isEditMode"
          >
            <el-option :value="'English'" />
          </BaseSelect>
          <BaseInput
            v-model="energyStarForm.contact.address.address1"
            type="textarea"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            :required="true"
            label="Street Address:"
            size="small"
            maxlength="100"
            name="address1"
            :isEditMode="isEditMode"
          />
          <BaseInput
            v-model="energyStarForm.contact.address.city"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="City/Municipality:"
            maxlength="100"
            name="city"
            size="small"
            :required="true"
            :isEditMode="isEditMode"
          />
          <BaseSelect
            v-model="energyStarForm.contact.address.state"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="State/Province:"
            name="state"
            size="small"
            :required="true"
            :isEditMode="isEditMode"
          >
            <el-option
              v-for="(v, k) in states"
              :key="k"
              :label="v.pretty_name"
              :value="v.abbreviation"
            />
          </BaseSelect>
          <BaseInput
            v-model="energyStarForm.contact.address.postalCode"
            :immediate="isUpdateMode ? true : false"
            rules="required"
            label="Postal Code:"
            name="postalCode"
            size="small"
            maxlength="20"
            :required="true"
            :isEditMode="isEditMode"
          />
          <hr class="border" />
          <h2 class="subtitle">About Your Organization</h2>
          <div class="override-width">
            <BaseInput
              v-model="energyStarForm.organization.name"
              :immediate="isUpdateMode ? true : false"
              rules="required"
              label="Organization Name:"
              size="small"
              name="orgName"
              :isEditMode="isEditMode"
              :required="true"
              :labelWidth="'200px'"
              maxlength="100"
            />
            <BaseSelect
              v-model="energyStarForm.organization.primaryBusiness"
              @change="changeOtherBusinessValue"
              :immediate="isUpdateMode ? true : false"
              rules="required"
              label="Primary Business or Service of Your Organization:"
              placeholder="Select Primary Business or Service"
              name="primaryBusiness"
              size="small"
              :isEditMode="isEditMode"
              :required="true"
              :labelWidth="'200px'"
            >
              <el-option v-for="k in primaryBusinesses" :key="k" :value="k" />
            </BaseSelect>
            <BaseInput
              v-if="energyStarForm.organization.primaryBusiness === 'Other'"
              v-model="energyStarForm.organization.otherBusinessDescription"
              :immediate="isUpdateMode ? true : false"
              rules="required"
              label="Other Business or Service of Your Organization:"
              maxlength="100"
              name="otherBusinessDescription"
              size="small"
              :isEditMode="isEditMode"
              :required="true"
              :labelWidth="'200px'"
            />
          </div>
          <hr class="border" />
        </el-form>
      </ValidationObserver>
    </template>
    <template #footer>
      <el-button class="button-size plain-button" @click="close"
        >Cancel</el-button
      >
      <el-button
        v-if="isEditMode"
        class="button-size"
        type="primary"
        @click="postOrPutAcc"
        >{{ isUpdateMode ? 'Apply Changes' : 'Create Account' }}</el-button
      >
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/FormComponents/BaseInput';
import BaseSelect from '@/components/FormComponents/BaseSelect';
import ErrorCard from '@/components/Cards/ErrorCard';
import { customers } from '@/api';
import { mapGetters } from 'vuex';
import * as R from 'ramda';
import { ValidationObserver } from 'vee-validate';
import * as generatePassword from 'password-generator';
import { isEmpty } from 'lodash';

const initialFormData = () => ({
  username: '',
  password: '',
  contact: {
    firstName: '',
    lastName: '',
    address: {
      address1: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
    email: '',
    jobTitle: '',
    phone: '',
  },
  organization: {
    name: '',
    primaryBusiness: '',
    otherBusinessDescription: '',
  },
});
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    customerFDGCode: {
      type: String,
    },
    energyStarEntitlement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: 'Create an Energy Star Account',
      energyStarForm: initialFormData(),
      isEditMode: false,
      isUpdateMode: false,
      energyStarAccData: {},
      disabled: false,
      confirmPassword: '',
      passFlags: '',
      emailFlags: '',
      confirmEmail: '',
      statusMessage: '',
      emailConfirmFlags: '',
      passConfirmFlags: '',
      updateAccount: 'Edit you Energy Star account',
      language: 'English',
      primaryBusinesses: [
        'Architecture/Design Firm',
        'Banking/Financial',
        'Commercial Real Estate',
        'Congregation/Faith-Based Organization',
        'Data Center',
        'Drinking Water Treatment/Distribution',
        'Education',
        'Energy Efficiency Program',
        'Entertainment/Recreation',
        'Food Service',
        'Government: Non-U.S.',
        'Government: U.S. Federal',
        'Government: U.S. Local',
        'Government: U.S. State',
        'Healthcare',
        'Hospitality',
        'Legal services',
        'Manufacturing/Industrial',
        'Media',
        'Multifamily Housing',
        'Retail',
        'Senior Care',
        'Service and Product Provider/Consultant',
        'Transportation',
        'Utility',
        'Wastewater Treatment',
        'Other',
      ],
    };
  },
  async mounted() {
    if (this.energyStarEntitlement) {
      await this.getEnergyStarAccount();
      this.init();
    }
  },
  async beforeUpdate() {
    await this.getEnergyStarAccount();
    this.init();
  },
  components: {
    BaseModal,
    BaseInput,
    BaseSelect,
    ErrorCard,
    ValidationObserver,
  },
  computed: {
    ...mapGetters({
      getResourceByName: 'resources/getResourceByName',
    }),
    states: {
      get() {
        const { country } = this.energyStarForm.contact.address;
        return R.pipe(
          R.propOr({}, 'value'),
          R.pickBy((value, key) => R.startsWith(country, key)),
        )(this.getResourceByName('states'));
      },
    },
    countries() {
      const countries = R.propOr(
        {},
        'value',
        this.getResourceByName('countries'),
      );
      delete countries['MX'];
      return countries;
    },
  },
  methods: {
    close() {
      this.$emit('close', false);
      if (!this.isUpdateMode) {
        this.energyStarForm = initialFormData();
      }
      this.isEditMode = false;
      this.confirmPassword = '';
      this.confirmEmail = '';
      if (this.statusMessage !== '') {
        this.statusMessage = '';
      }
      this.$refs.observer.reset();
    },
    async getEnergyStarAccount() {
      const { customerId } = this.$route.params;
      const { data } = await customers.getEnergyStarAcc(customerId);
      this.energyStarAccData = data;
    },
    async init() {
      if (!isEmpty(this.energyStarAccData)) {
        this.energyStarForm = this.energyStarAccData;
        this.isUpdateMode = true;
      } else {
        this.isEditMode = true;
        this.energyStarForm.username = `pear_${this.customerFDGCode}_energystar`;
        this.energyStarForm.password = `${generatePassword(
          15,
          false,
        )}${Math.floor(Math.random() * 10)}`;
      }
      if (this.isUpdateMode) {
        this.title = 'Energy Star Account Information';
      }
    },
    async postOrPutAcc() {
      await this.$refs.observer.validate().then((success) => {
        if (success) {
          if (this.isEditMode && !this.isUpdateMode) {
            this.postCustomersData();
          }
          if (this.isUpdateMode && this.isEditMode) {
            this.putCustomersData();
          }
          this.$refs.observer.reset();
        }
      });
    },
    handleFieldErrors(flags, fieldErrors) {
      if (this.isEditMode && !this.isUpdateMode) {
        return `required|${fieldErrors}`;
      }
      if (
        this.isEditMode &&
        this.isUpdateMode &&
        (flags.changed || flags.dirty)
      ) {
        return `required|${fieldErrors}`;
      }
      return '';
    },
    handlePassFlags() {
      this.passFlags = this.$refs.passwordRef.$children[0].$children[0].flags;
    },
    handleEmailFlags() {
      this.emailFlags = this.$refs.emailRef.$children[0].$children[0].flags;
    },
    handleConfirmEmailFlags() {
      this.emailConfirmFlags =
        this.$refs.emailConfirmRef.$children[0].$children[0].flags;
    },
    handleConfirmPassFlags() {
      this.passConfirmFlags =
        this.$refs.passConfirmRef.$children[0].$children[0].flags;
    },
    resetState() {
      this.energyStarForm.contact.address.state = '';
    },
    async getPlainPass() {
      const { customerId } = this.$route.params;
      const { data } = await customers.getPlainPassword(customerId);
      this.$copyText(data.password);
    },
    async postCustomersData() {
      const { customerId } = this.$route.params;
      try {
        await customers.postEnergyStarAcc(customerId, this.energyStarForm);
        this.$message({
          showClose: true,
          message: `Account creation is successful.`,
          type: 'info',
        });
        this.isEditMode = false;
        this.confirmPassword = '';
        this.confirmEmail = '';
        this.close();
      } catch (e) {
        this.statusMessage = e.response.data.message || e.response.data.error;
      }
    },
    async putCustomersData() {
      const { customerId } = this.$route.params;
      if (
        !this.passFlags.changed ||
        this.energyStarForm.password === '********'
      ) {
        delete this.energyStarForm.password;
      }
      try {
        await customers.putEnergyStarAcc(customerId, this.energyStarForm);
        this.$message({
          showClose: true,
          message: `Update of the account is successful.`,
          type: 'info',
        });
        this.isEditMode = false;
        this.emailFlags.changed = false;
        this.passFlags.changed = false;
        this.confirmPassword = '';
        this.confirmEmail = '';
        this.close();
      } catch (e) {
        this.statusMessage = e.response.data.message;
      }
    },
    changeOtherBusinessValue(value) {
      if (value !== 'Other') {
        this.energyStarForm.organization.otherBusinessDescription = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-size {
  width: 35%;
}
.plain-button {
  color: #2893f9;
  border-color: #2893f9;
}
.subtitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 0 0 16px 0;
  display: flex;
  align-items: center;
  color: #273a58;
}
.energyStarId {
  color: #273a58;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 1em;
}
.edit-button {
  position: absolute;
  top: 10px;
  left: 330px;
  font-size: 18px;
}
.text-under-input {
  color: #909399;
  line-height: 16px;
  padding-left: 1em;
  word-break: break-word;
  margin-bottom: 0;
  font-size: 12px;
  margin-top: 5px;
}
.border {
  border-top: medium;
  width: 50em;
}
.pl-10 {
  padding: 0 0 0 10px;
}
.override-width {
  width: 35.5vw;
}
.override-width-with-btn {
  width: 34vw;
}
.form-width {
  width: 60%;
}
/deep/ .el-button--text {
  text-decoration: none;
}
</style>
