<template>
  <main-layout>
    <div class="vendor-details-container">
      <VendorDetailsHeader :vendor="vendor" />
      <VendorGeneralInfo :vendor="vendor" />
      <div v-if="vendor.vendorProviderDetails">
        <VendorBlock
          v-for="(vendor, index) in vendor.vendorProviderDetails"
          :key="vendor.id"
          :vendor="vendor"
          :index="index"
        />
      </div>
    </div>
  </main-layout>
</template>

<script>
import { Main } from '@/components/layouts';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import VendorBlock from './components/VendorBlock.vue';
import VendorGeneralInfo from './components/VendorGeneralInfo.vue';
import VendorDetailsHeader from './components/VendorDetailsHeader.vue';

export default {
  components: {
    MainLayout: Main,
    VendorDetailsHeader,
    VendorGeneralInfo,
    VendorBlock,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      vendor: 'vendors/vendor',
    }),
  },
  methods: {
    ...mapMutations({
      $resetVendor: 'vendors/resetVendor',
    }),
    ...mapActions({
      $getVendor: 'vendors/getVendor',
    }),
  },
  async beforeMount() {
    this.$resetVendor();
    await this.$getVendor(this.$route.params.vendorId);
  },
};
</script>

<style lang="scss" scoped>
@import './style/vendors.scss';
</style>
