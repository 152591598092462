<template lang="pug">
  div
    .tab-bill-chain-info
      el-row
        el-col(:span="12")
          .info
            .info__label Bill Chain ID:
            .info__value  {{ billChainId }}
          .info
            .info__label Vendor:
            .info__value {{ getPrettyVendorByCode(bill.vendorCode) || '-' }}
          .info
            .info__label Billing ID:
            .info__value {{ bill.clientAccount }}
        el-col(:span="24")
          .bill-tabs-container-items
            .bill-tabs-container-items-title Bill Chain ID:
            div
              el-select.select-size(v-model="selectedChainString",
              filterable
              size="small",
              data-action="select-format-type",
              popper-class="select-format-type_dropdown"
              @change="reloadBillChain")
                el-option(v-for="option in chainsOfCurrentBill",
                :key="option.chain_id",
                :label="handleChainIdNaming(option)"
                :value="option.chain_id"
                )
          .bill-tabs-container-items
            .bill-tabs-container-items-title Invoice date from:
            div
              el-date-picker.datepicker-size(
              v-model="selectedDateRange",
              @change="onSelectedDatesChange"
              size="small",
              type="daterange",
              range-separator="to",
              start-placeholder="Start date",
              format="MM/dd/yyyy",
              end-placeholder="End date")
      .table
        el-table(:data='billChain' size="small")
          el-table-column( prop='bill_id' label='Bill ID')
            template(slot-scope="scope")
              a(:class="{ bold: scope.row.bill_id == bill.id }" @click="loadBillPath(`/customers/${bill.customerId}/bills/${scope.row.bill_id}`)", data-type='bill_id') {{ scope.row['bill_id'] || '-' }}
          el-table-column(prop='statement_date' label='Invoice Date' min-width='110')
            template(slot-scope="scope")
              span(:class="{ bold: scope.row.bill_id == bill.id }" data-type='statement_date') {{ scope.row['statement_date'] | datetime('MM/dd/yyyy') || '-' }}
          el-table-column(prop='current_bill_charges' label='Current Charges' min-width='115')
            template(slot-scope="scope")
              span(:class="{ bold: scope.row.bill_id == bill.id }" data-type='current_bill_charges') {{ scope.row['current_bill_charges'] || 0 | currency }}
          el-table-column(prop='past_due_amount' label='Past Due')
            template(slot-scope="scope")
              span(:class="{ bold: scope.row.bill_id == bill.id }" data-type='past_due_amount') {{ scope.row['past_due_amount'] || 0 | currency }}
          el-table-column(prop='total_bill_charges' label='Total Amount Due' min-width='130')
            template(slot-scope="scope")
              span(:class="{ bold: scope.row.bill_id == bill.id }" data-type='total_bill_charges') {{ scope.row['total_bill_charges'] || 0 | currency }}
          el-table-column(prop='due_date' label='Date Due')
            template(slot-scope="scope")
              span(:class="{ bold: scope.row.bill_id == bill.id }" data-type='due_date') {{ scope.row['due_date'] | datetime('MM/dd/yyyy') || '-' }}
          el-table-column(prop='prior_bill_days' min-width='160' label='Days Since Previous Bill')
            template(slot-scope="scope")
              span(:class="{ bold: scope.row.bill_id == bill.id }" data-type='prior_bill_days') {{ scope.row['prior_bill_days'] ||'First Bill' }}
      el-pagination(
        class="paginator-wrapper is-background"
        layout="prev, pager, next, ->, sizes"
        :total="totalBillsInChain"
        :page-size="limit"
        :page-sizes="itemsPerPage"
        :current-page="currentPage"
        @size-change="changeLimit"
        @current-change="changeOffset")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { bills } from '@/api';

export default {
  data() {
    return {
      itemsPerPage: [12, 24, 36, 48],
      offset: 0,
      limit: 12,
      chainId: null,
      chainsOfCurrentBill: [],
      billChain: [],
      totalBillsInChain: 0,
      billChainId: '-',
      selectedDateRange: [],
      start_date: null,
      end_date: null,
      selectedChainString: '',
    };
  },
  async beforeMount() {
    try {
      await this.getChainId();
      this.selectedChainString = `${this.computedChain.chain_id} (this bill's chain)`;
      this.billChainId = this.computedChain.chain_id;
      if (
        this.start_date &&
        this.start_date !== '-1' &&
        this.end_date &&
        this.end_date !== '-1'
      ) {
        this.selectedDateRange = [this.start_date, this.end_date];
      }
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapGetters({
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
      bill: 'bills/bill',
    }),
    computedChain() {
      return this.chainsOfCurrentBill.find((b) =>
        b.bill_in_chain ? b.chain_id : '',
      );
    },
    currentPage() {
      return this.offset / this.limit + 1;
    },
  },
  methods: {
    ...mapActions({
      $loadBillPath: 'bills/loadBillPath',
    }),
    loadBillPath(path) {
      this.$loadBillPath({
        path: path,
        router: this.$router,
      });
    },
    async getChainId () {
      const {data} = await bills.getChainsForBill(this.bill.customerId, this.bill.clientAccount, this.bill.id);
      this.chainsOfCurrentBill = await data;
      this.chainsOfCurrentBill.find((b) => {
        if (b.bill_in_chain) {
          this.chainId = b.chain_id;
          this.loadBillChain();
        }
      });
    },
    async loadBillChain(customerId, chainId) {
      try {
        const { data, meta } = await bills.getBillChain(
          this.bill.customerId,
          this.chainId,
          {
            limit: this.limit,
            offset: this.offset,
            start_date: this.start_date,
            end_date: this.end_date,
          },
        );
        this.billChain = data;
        this.totalBillsInChain = +meta.content_range.total || 0;
      } catch (e) {
        console.log(e);
      }
    },
    reloadBillChain(chainId) {
      this.offset = 0;
      this.chainId = chainId;
      this.loadBillChain();
    },
    changeOffset(pageNo) {
      this.offset = (pageNo - 1) * this.limit;
      this.loadBillChain();
    },
    changeLimit(limit) {
      this.offset = 0;
      this.limit = limit;
      this.loadBillChain();
    },
    onSelectedDatesChange(selectedDates) {
      if (selectedDates) {
        this.start_date = DateTime.fromJSDate(selectedDates[0])
          .setZone('UTC', { keepLocalTime: true })
          .toString();
        this.end_date = DateTime.fromJSDate(selectedDates[1])
          .setZone('UTC', { keepLocalTime: true })
          .toString();
      } else {
        this.start_date = null;
        this.end_date = null;
      }
      this.offset = 0;
      this.loadBillChain();
    },
    handleChainIdNaming(option) {
      if (this.computedChain.chain_id === option.chain_id) {
        return `${option.chain_id} (this bill's chain)`;
      }
      return option.chain_id;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-bill-chain-info {
  padding: 10px 20px;
  font-size: 12px;

  a {
    cursor: pointer;
  }

  .info {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    + .info {
      padding-top: 10px;
    }

    &__label {
      padding-right: 7px;
      min-width: 150px;
      color: #222222;
      &__span {
        font-weight: bold;
      }
    }
    &__value {
      color: #273a58;
      font-weight: 700;
    }
  }
  .table {
    // padding: 10px 20px;
    padding-top: 20px;
    th,
    td {
      padding: 7px 0;
    }

    .bold {
      font-weight: 700;
    }

    &--title {
      margin: 0 0 8px 20px;
      color: #666666;
      line-height: 22px;
    }
  }
  .bill-tabs {
    &-container {
      margin: 24px -20px 0 -20px;

      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;
      &-items {
        padding: 25px 0 0 0;
        &-flex {
          display: flex;
          justify-content: space-between;
          padding: 25px 0 0 0;
        }
        &-title {
          color: #222222;
          margin: 0 0 10px 0;
          font-size: 12px;
        }
        .select-size {
          width: 35%;
        }
        .datepicker-size {
          max-width: 360px;
        }
        .bold {
          font-weight: 700;
        }
      }
    }
  }
}
</style>
