<template>
  <el-dialog
    :visible="dialogVisible"
    @close="close"
    width="25%"
    center
    :show-close="false"
  >
    <div class="modal-content" v-if="type">
      {{ type }} regenerated successfully.
    </div>
    <div class="modal-content" v-else>
      Failed to regenerate {{ errorType }}.
    </div>
    <div slot="footer">
      <el-button type="primary" class="button-width" @click="close"
        >OK</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    type: {
      type: String,
      default: () => '',
    },
    errorType: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog {
  /deep/ &__header {
    padding: 10px 10px 5px;
  }
}
.modal-content {
  display: flex;
  justify-content: center;
}
.button-width {
  width: 20%;
}
</style>
