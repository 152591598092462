import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

const list = (params) =>
  http(hosts.v3).get('/vendors', { params }).then(dataMiddleware);

const vendorByCode = (code) =>
  http(hosts.v1).get(`/vendors/by-code/${code}`).then(dataMiddleware);

const providersByCode = (params) =>
  http(hosts.v3)
    .get(`/vendors/providers-by-code`, { params })
    .then(dataMiddleware);

const get = (id) => http(hosts.v3).get(`vendors/${id}`).then(dataMiddleware);

const updateBulk = (body) =>
  http(hosts.v3).patch(`vendors/pretty-names`, body).then(dataMiddleware);

const validatePrettyName = (id, prettyName) => {
  return http(hosts.v3)
    .patch(`/vendors/pretty-names`, [
      {
        id,
        prettyName: prettyName || null,
      },
    ])
    .then(dataMiddleware);
};

export {
  list,
  get,
  updateBulk,
  vendorByCode,
  validatePrettyName,
  providersByCode,
};
