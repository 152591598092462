import { hosts } from '@/lib/api.configs';
import { http } from '@/lib';
import { dataMiddleware } from '@/lib/http';
import qs from 'qs';

const local = [
  {
    label: 'Monthly Extract',
    value: 'ADMIN_CUSTOMER_MONTHLY_EXTRACT',
  },
  {
    label: 'Bill Extract',
    value: 'ADMIN_CUSTOMER_BILL_EXTRACT',
  },
  {
    label: 'Location Report',
    value: 'CUSTOMER_LOCATIONS',
  },
  {
    label: 'Bill Errors',
    value: 'ADMIN_BILL_ERRORS',
  },
  {
    label: 'Billing Line Items',
    value: 'CUSTOMER_BILLING_LINE_ITEMS',
  },
  {
    label: 'Vendor Spend Report',
    value: 'CUSTOMER_VENDOR_SPEND',
  },
  {
    label: 'Bill Chains',
    value: 'CUSTOMER_BILL_CHAINS',
  },
];

const global = [
  {
    label: 'Monthly Bills Processed Report',
    value: 'ADMIN_PROCESSED_BILLS',
  },
  {
    label: 'Bill Processing Time',
    value: 'BILL_PROCESSING_TIME',
  },
  {
    label: 'Activity Report',
    value: 'ADMIN_BILL_ACTIVITY_REPORT',
  },
];

export const types = { global, local };

export const getFiltersData = (customerId) => {
  return {
    type: types,
    format: [
      {
        label: 'CSV',
        value: 'csv',
      },
    ],
  };
};

/**
 * @description set pretty types
 * @param {array} reports
 * @param {string} category
 */
const setPrettyTypes = (reports, category = 'local') => {
  return {
    ...reports,
    data: reports.data.map((item) => ({
      ...item,
      title: (types[category].find((type) => type.value === item.type) || {})
        .label,
    })),
  };
};

/**
 * @description get reports list
 * @param {string} customerId
 * @param {object} params
 */
export const list = ({ customerId, params }) =>
  http(hosts.v1)
    .get(
      `/customers/${customerId}/reports?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    )
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }))
    .then((res) => setPrettyTypes(res));

/**
 * @description get report by id
 * @param {string} customerId
 * @param {string} reportId
 */
export const report = (customerId, reportId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/reports/${reportId}`)
    .then(dataMiddleware)
    .then((report) => ({
      ...report,
      title: types.local.find((e) => e.value === report.type).label,
    }));

/**
 * @description create report
 * @param {string} customerId
 * @param {object} reportOptions
 */
export const createReport = (customerId, reportOptions) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/reports`, reportOptions)
    .then(dataMiddleware);

/**
 * @description fetch filters
 * @param {string} customerId
 */
export const fetchFilters = (customerId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/generate-billing-line-items-export/filters`)
    .then(dataMiddleware);

/**
 * @description fetch preview
 * @param {string} customerId
 * @param {object} params
 */
export const fetchPreview = (customerId, params) =>
  http(hosts.v1)
    .get(
      `/customers/${customerId}/generate-billing-line-items-export-preview?${qs.stringify(
        params,
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(dataMiddleware);

export const crossCustomerReports = (params) =>
  http(hosts.v1)
    .get(`/reports?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }))
    .then((res) => setPrettyTypes(res, 'global'));

export const createCrossCustomerReport = (reportOptions) =>
  http(hosts.v1).post(`/reports`, reportOptions).then(dataMiddleware);

export const getCustomerReportTemplates = (customerId, params) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/reports/templates?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware);
export const getTemplatesAvailableValues = (templateId, customerId) =>
  http(hosts.v3)
    .get(`customers/${customerId}/reports/available-filters/${templateId}`)

export const getGlobalReportTemplates = (params) =>
  http(hosts.v3)
    .get(
      `/admin/reports/templates?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware);

export const createGlobalReport = (body) =>
  http(hosts.v3).post(`/admin/reports/run-now`, body).then(dataMiddleware);

export const createCustomerReport = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/reports/run-now`, body)
    .then(dataMiddleware);

export const getGlobalReports = (params) => {
  return http(hosts.v3)
    .get(
      `/admin/reports/list?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware)
    .then((data) => ({
      total: data.total,
      data: data.results || [],
      filters: data.filters,
    }))
    .then((res) => setPrettyTypes(res));
};

export const getCustomerReports = (customerId, params) => {
  return http(hosts.v3)
    .get(
      `/customers/${customerId}/reports/list?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware);
};
