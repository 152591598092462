import _ from 'lodash';
import { vendors, customers } from '@/api';

const getDefaultState = () => ({
  vendor: {
    id: '',
    fdg_id: '',
    name: '',
    code: '',
    remittance_address: {
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      post_code: '',
    },
    custsvc_phone: '',
    web_address: '',
    remittance_name: '',
    main_phone: '',
  },
  vendorsByCode: [],
  list: [],
  meta: {},
  filters: {},
  isLoading: false,
  blocksExpanded: false,
  blocksExpandedChanged: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    id: (state) => _.get(state.vendor, ['id'], 5),
    code: (state) => _.get(state.vendor, ['code'], ''),
    vendor: (state) => state.vendor || getDefaultState().vendor,
    list: (state) => state.list,
    meta: (state) => state.meta,
    filters: (state) => state.filters,
    blocksExpanded: (state) => state.blocksExpanded,
    blocksExpandedChanged: (state) => state.blocksExpandedChanged,
  },
  mutations: {
    setList(state, list) {
      state.list = [...list];
    },
    setVendor(state, vendor) {
      state.vendor = { ...state.vendor, ...vendor };
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setMeta(state, filters) {
      state.meta = filters;
    },
    resetVendor(state) {
      state.vendor = getDefaultState().vendor;
    },
    setVendorsByCode(state, vendorsByCode) {
      state.vendorsByCode = vendorsByCode;
    },
    setBlocksExpanded(state, expanded) {
      state.blocksExpanded = expanded;

      // I'm setting this to true and then immediately to false in order to watch for changes in VendorBlock component instances. This is needed if you want to expand blocks that are collapsed when expand is TRUE
      state.blocksExpandedChanged = true;
      setTimeout(() => {
        state.blocksExpandedChanged = false;
      }, 0);
    },
  },
  actions: {
    async getProvidersByCode({ commit, state }, { query }) {
      try {
        state.isLoading = true;
        const { results } = await vendors.providersByCode(
          Object.assign({}, state.filters, { query }),
        );
        commit('setVendorsByCode', results || []);
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
      }
    },
    async getList({ commit, state }) {
      try {
        state.isLoading = true;
        const { results, total } = await vendors.list(state.filters);
        commit('setList', results || []);
        commit('setMeta', { total });
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
      }
    },
    validatePrettyName({ commit, state }, { id, prettyName }) {
      return vendors.validatePrettyName(id, prettyName);
    },
    async getVendor({ commit, state }, vendorId) {
      try {
        state.isLoading = true;
        const vendor = await vendors.get(vendorId);
        commit('setVendor', vendor);
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
      }
    },
    async getCustomerVendor({ commit, state }, { customerId, vendorId }) {
      try {
        state.isLoading = true;
        const vendor = await customers.getCustomerVendor(customerId, vendorId);
        commit('setVendor', vendor.data);
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
      }
    },
    async updateCustomerVendorCustomAttributes(
      { commit, state },
      { customerId, providerVendorId, attributes },
    ) {
      try {
        state.isLoading = true;
        const response = await customers.updateCustomAttributes(
          customerId,
          providerVendorId,
          attributes,
        );
        commit('setVendor', response.data);
        return response;
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
      }
    },
    async updateVendorsBulk({ commit, state }, body) {
      try {
        state.isLoading = true;
        await vendors.updateBulk(
          body.map((vendor) => ({
            id: vendor.id,
            prettyName: vendor.prettyName,
          })),
        );
        commit('setList', body);
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
      }
    },
  },
};
