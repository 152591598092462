<template>
  <div class="radio-group">
    <h3 class="user-role">Select the user role:</h3>
    <el-radio-group v-model="innerValue">
      <el-radio v-for="r in radioButtons" :key="r.label" :label="r.label">{{
        r.name
      }}</el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
    },
    radioButtons: {
      type: Array,
      default: () => [],
    },
    column: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-radio {
  padding-bottom: 16px;
}
.radio-group {
  padding-bottom: 1em;
  .user-role {
    color: #273a58;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
