import { render, staticRenderFns } from "./TreeRoot.vue?vue&type=template&id=8753cf34&scoped=true&lang=pug&"
import script from "./TreeRoot.vue?vue&type=script&lang=js&"
export * from "./TreeRoot.vue?vue&type=script&lang=js&"
import style0 from "./TreeRoot.vue?vue&type=style&index=0&id=8753cf34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8753cf34",
  null
  
)

export default component.exports