<template lang="pug">
  .form-container

    .form-message(v-if='showMessage')
      h1.message-title Password reset email sent
      p.message-text If the email address <b>{{ email }}</b> maps to an account in our system, we will send instructions to reset your password. Please check your email for instructions.
      p.message-text If you don't receive an email, please check your Spam folder or contact your pear.ai administrator.
      router-link.form-link(to='/auth/login') Back to Login

    div(v-if='!showMessage')
      h1.message-title Forgot your Password?
      form.form(method='POST', action='/', @submit.prevent='sendResetLink')
        p.message-text Enter your email address and we will send you a link to reset it
        .form-error(v-show='errors.has("email")' v-html='errors.first("email")')
        .form-control
          .form-icon.email_icon
          input(type='email', v-model='email', :class='{ active: !!email, "has-error": errors.has("email") }', v-validate='"required|email"', name='email', placeholder='Email')
          button.button-clear(v-if='errors.first("email") && email.length > 0',
                              type='button',
                              @click='clearEmail')
        button.form-button(:disabled='!email || errors.has("email")') Send reset link
        router-link.form-link(to='/auth/login') Back to Login
</template>

<script>
import { user } from '@/api';

export default {
  name: 'ForgotPage',
  data() {
    return {
      email: '',
      showMessage: false,
    };
  },
  methods: {
    async sendResetLink() {
      if (!this.errors.has('email')) {
        await user.forgotPassword(this.email);
        this.showMessage = !this.showMessage;
      }
    },
    clearEmail() {
      this.email = '';
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_login';
</style>
