<template>
  <div class="success-wrapper">
    <div class="text-wrapper">
      <p v-html="message" />
    </div>
    <div class="button-wrapper">
      <el-button type="primary" @click="close">OK</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    locationName: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
  },
  name: 'delete',
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.success-wrapper {
  .text-wrapper {
    p {
      text-align: center;
      word-break: normal;
      margin: 0;
      color: #909399;
      font-size: 16px;
      letter-spacing: 0.09px;
      line-height: 22px;
    }
  }
  .bold {
    font-weight: 700;
  }

  .button-wrapper {
    margin-top: 37px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .el-button {
      border-radius: 15px;
      background-color: #0080ff;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900;
      line-height: 14px;
      text-align: center;
      padding: 8px 30px;
    }
  }
}
</style>
