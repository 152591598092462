<template>
  <div id="reportFilters">
    <el-form label-position="top">
      <template v-for="(filter, i) in report.availableFilters">
        <div class="form-item" v-bind:key="i">
          <div
            class="form-item-input"
            v-for="item in filter"
            v-bind:key="item.key"
          >
            <div
              v-if="
                item.element === 'select' || item.element === 'single-select'
              "
            >
              <base-select
                :isEditMode="true"
                :filterable="true"
                :title="selectTitle(filters, item)"
                size="medium"
                :ordered="true"
                :label="item.label"
                :name="item.label"
                immediate
                :multiple="item.element === 'select'"
                v-model="filters[item.key]"
                @change="onChange($event, item)"
              >
                <el-option
                  v-for="v in item.availableValues"
                  :key="v.key"
                  :value="v.key"
                  :label="v.value"
                />
              </base-select>
            </div>
            <div v-if="item.element === 'date' || item.element === 'month'">
              <date-range-filter
                :customOptions="dateRangeFilterCustomOptions"
                v-bind:id="testId(item.label)"
                :filter="filters[item.key]"
                :filterKey="item.key"
                :reportType="report.readableId"
                :resetDateRange="resetDateRange"
                :label="item.label"
                :elementType="item.element"
                @date-range-pick="handleDateRangePick"
              />
            </div>
            <div v-if="item.element === 'text'">
              <BaseInput
                :isEditMode="true"
                v-model="filters[item.key]"
                :placeholder="item.placeholder ? item.placeholder : 'Search'"
                :name="item.name"
                :label="item.label"
                size="medium"
              />
            </div>
            <div v-if="item.element === 'checkbox'">
              <BaseCheckbox
                :isEditMode="true"
                v-model="filters[item.key]"
                :labelCheckbox="item.label"
                :labelWidth="'0'"
              />
            </div>
          </div>
        </div>
      </template>
    </el-form>
    <location-attrs-filters
      :location-attrs="locationsAttrs"
      @set-location-attrs="onSetFilters"
    />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import DateRangeFilter from '@/components/FormComponents/DateRangeFilter'
import BaseCheckbox from '@/components/FormComponents/BaseCheckbox'
import LocationAttrsFilters from '../pages/Customers/Exports/Filters/LocationAttrsFilters'
import _ from 'lodash'

export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    resetDateRange: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    availableFilters() {
      return this.report.availableFilters
    },
    testId() {
      return label => _.camelCase(`${label}Range`)
    },
    selectTitle() {
      return (filters, item) => {
        if (!filters[item.key]) return
        return item.availableValues
          .filter(locationAttr => {
            return _.isArray(filters[item.key])
              ? filters[item.key].find(key => locationAttr.key === key)
              : filters[item.key] === locationAttr.key
          })
          .map(locationAttr => locationAttr.value)
      }
    },
  },
  methods: {
    onSetFilters(val) {
      this.$emit('set-location-attrs', val)
    },
    handleDateRangePick(filter) {
      this.filters[filter.key] = filter.value ? { ...filter.value } : undefined
      this.filters.start_date = filter.value && filter.value.min
      this.filters.end_date = filter.value && filter.value.max
      this.$emit('change-filter', { key: filter.key, value: filter.value })
    },
    onChange(val, { key, availableValues }) {
      if (key !== 'location_attributes') {
        return
      }
      this.locationsAttrs = [
        ...availableValues.filter(locationAttr =>
          val.find(key => locationAttr.key === key),
        ),
      ]
    },
  },
  components: {
    BaseInput,
    LocationAttrsFilters,
    BaseSelect,
    BaseCheckbox,
    DateRangeFilter,
    ValidationObserver,
  },
  data() {
    return {
      showTab: false,
      title: '',
      locationsAttrs: [],
      innerVisible: false,
      isEditMode: false,
      dateRangeFilterCustomOptions: [],
    }
  },
  watch: {
    report: function(report) {
      this.dateRangeFilterCustomOptions.splice(0)
      if (this.report.readableId === 'ADMIN_GLOBAL_NEW_VIRTUAL_ACCOUNTS') {
        this.dateRangeFilterCustomOptions.push('7days')
      }
      report.availableFilters.forEach(filter => {
        filter.forEach(item => {
          if (item.defaultValue) {
            this.filters[item.key] = item.defaultValue
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
.select-width {
  width: 280px;
}

#reportFilters .pl-12 {
  padding: 0;
}
</style>
<style lang="scss" scoped>
> .form-item-input {
  flex-grow: initial;
  padding-right: 10px;
}

> .el-date-editor {
  width: 280px;
}

> .form-item {
  display: flex;
  flex-wrap: wrap;

  .el-input {
    width: 280px;
  }
}
::v-deep .el-select__tags-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
</style>
