import ExceptionsUnmatched from '@/pages/Customers/Exceptions/Unmatched/List';
import ExceptionsUnprocessed from '@/pages/Customers/Exceptions/Unprocessed/List';

export default {
  path: 'exceptions',
  redirect: {
    name: 'customer.exceptions.unmatched',
  },
  component: {
    template: '<router-view></router-view>',
  },
  name: 'customer.exceptions',
  meta: {
    auth: true,
    showNavbar: true,
    breadcrumb: {
      titleTemplate: '<%= customer %> Unmatched Service Addresses',
      storeMappings: { customer: 'customers/name' },
    },
  },
  children: [
    {
      path: 'unmatched',
      name: 'customer.exceptions.unmatched',
      component: ExceptionsUnmatched,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: false,
      },
    },
    {
      path: 'unprocessed',
      component: ExceptionsUnprocessed,
      name: 'customer.exceptions.unprocessed',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
      },
    },
  ],
};
