import { DateTime } from 'luxon';
import accounting from 'accounting';
import { startCase } from 'lodash';
import { lowerCase } from 'lodash/string';

const datetime = (value, format = 'DDD') => {
  if (!value) return '';
  return DateTime.fromISO(String(value), { zone: 'utc' }).toFormat(format);
  /*
    Attach { zone: 'utc' } option to luxon Datetime.fromISO method.
    It should fix all datetime global offsets.
   */
};

const consumption = (value) => {
  let result = 0.0;
  let isNegative = String(value).charAt(0) === '-';

  if (isNegative) {
    value = String(value).slice(1);
  }

  result = accounting.formatMoney(value, {
    format: '%s%v',
    symbol: '',
    precision: 1,
    thousand: ',',
    decimal: '.',
  });

  return isNegative ? `-${result}` : result;
};

const currency = (value, symbol = '$') => {
  let result = 0.0;
  value = +value === 0 ? 0 : value;
  const isNegative = String(value).charAt(0) === '-';

  if (isNegative) {
    value = String(value).slice(1);
  }

  result = accounting.formatMoney(value, {
    format: '%s%v',
    symbol: symbol,
    precision: 2,
    thousand: ',',
    decimal: '.',
  });

  return isNegative ? `-${result}` : result;
};

/**
 * Convert unit from backend to user friendly string
 * @param {float} value - The title of the book.
 * @param {string} unit - The author of the book.
 */

const unit = (value, unit = '', nonUsBill = false) => {
  unit = `${unit}`;
  let currencySymbol = '$';
  if (nonUsBill) {
    currencySymbol = 'USD';
  }
  if (unit === 'USD') {
    return nonUsBill
      ? `${currencySymbol} ${currency((+value).toFixed(3), '')}`
      : `${currencySymbol}${currency((+value).toFixed(3), '')}`;
  } else if (unit === 'USDCCF') {
    return `${(+value).toFixed(3)} ${currencySymbol}/ccf`;
  } else if (unit === 'USDTHERM') {
    return `${(+value).toFixed(3)} ${currencySymbol}/therm`;
  } else if (unit === 'USDTON') {
    return `${(+value).toFixed(3)} ${currencySymbol}/ton`;
  } else if (unit === 'USDMIN') {
    return `${(+value).toFixed(3)} ${currencySymbol}/min`;
  } else if (unit === 'USDMB') {
    return `${(+value).toFixed(3)} ${currencySymbol}/Mb`;
  } else if (unit === 'USDSQFEET') {
    return `${(+value).toFixed(3)} ${currencySymbol}/sqfeet`;
  } else if (unit === 'USDGAL') {
    return `${(+value).toFixed(3)} ${currencySymbol}/gal`;
  } else if (unit === 'KWHDAY') {
    return `${(+value).toFixed(3)} kWh/day`;
  } else if (unit === 'PERCENT') {
    return `${(+value).toFixed(3)}%`;
  } else if (unit === 'USDDAY') {
    return `${(+value).toFixed(3)} ${currencySymbol}/day`;
  } else if (unit === 'USDKWHDAY') {
    return `${(+value).toFixed(3)} ${currencySymbol}/kWh/day`;
  } else if (unit === 'USDKWH') {
    return `${(+value).toFixed(3)} ${currencySymbol}/kWh`;
  } else if (unit === 'TONDAY') {
    return `${(+value).toFixed(3)} ton/day`;
  } else if (unit === 'CCFDAY') {
    return `${(+value).toFixed(3)} ccf/day`;
  } else if (unit === 'THERMDAY') {
    return `${(+value).toFixed(3)} therm/day`;
  } else if (unit === 'MINDAY') {
    return `${(+value).toFixed(3)} min/day`;
  } else if (unit === 'CALLS') {
    return `${(+value).toFixed(3)} calls`;
  } else if (unit === 'MB') {
    return `${(+value).toFixed(3)} Mb`;
  } else if (unit === 'MBDAY') {
    return `${(+value).toFixed(3)} Mb/day`;
  } else if (unit === 'SQFEETDAY') {
    return `${(+value).toFixed(3)} sqfeet/day`;
  } else if (unit === 'KWH') {
    return `${consumption((+value).toFixed(3))} kWh`;
  } else if (unit === 'KW') {
    return `${consumption((+value).toFixed(3))} kW`;
  } else if (unit === 'KVARH') {
    return `${consumption((+value).toFixed(3))} kvarh`;
  } else if (unit === 'USDMMBTU') {
    return `${(+value).toFixed(3)} ${currencySymbol}/mmbtu`;
  } else if (unit === 'MMBTUDAY') {
    return `${(+value).toFixed(3)} mmbtu/day`;
  } else if (unit === 'KWHDD') {
    return `${(+value).toFixed(3)} kWh/DD`;
  } else if (unit === 'CAD') {
    return `CAD ${currency((+value).toFixed(3), '')}`;
  } else if (unit) {
    return `${(+value).toFixed(3)} ${(unit && unit.toLowerCase() !== 'null'
      ? unit
      : ''
    ).toLowerCase()}`;
  }
  return (+value).toFixed(3);
};

const toTitleCase = (str) => {
  return startCase(lowerCase(str));
};

export default {
  datetime,
  unit,
  consumption,
  toTitleCase,
};
