import { customers } from '@/api';
import * as R from 'ramda';

export default {
  namespaced: true,
  state: {
    customer: {},
    users: [],
    filters: {},
    list: [],
    storageKey: 'recentCustomers',
    storageSize: 5,
    vendors: [],
    meta: {},
    vendorName: '',
  },

  getters: {
    name: (state) => state.customer.name || '',
    id: (state) => state.customer.id,
    users: (state) => state.users,
    entitlements: R.pathOr({}, ['customer', 'entitlements']),
    list: (state) => state.list,
    customer: (state) => state.customer,
    recentCustomers: (state) => {
      try {
        return JSON.parse(localStorage.getItem(state.storageKey));
      } catch (e) {
        return [];
      }
    },
    customersMap: (state) => {
      return R.reduce((acc, v) => {
        acc[v.id] = v.name;
        return acc;
      }, {})(state.list);
    },
    vendors: (state) => state.vendors,
    meta: (state) => state.meta,
    filters: (state) => state.filters,
    vendorName: (state) => state.vendorName,
  },

  mutations: {
    setCurrentCustomer(state, customer) {
      state.customer = { active: false, ...customer };
    },
    setUsers(state, users) {
      state.users = [...users];
    },
    setCustomersList(state, list) {
      state.list = list;
    },
    setRecentCustomer(state, customerId) {
      if (!customerId) {
        return;
      }
      let customers;
      try {
        customers = JSON.parse(localStorage.getItem(state.storageKey)) || [];
      } catch (e) {
        customers = [];
      }
      customers = R.reject(R.equals(customerId))(customers);
      customers.unshift(customerId);
      localStorage.setItem(
        state.storageKey,
        JSON.stringify(customers.slice(0, state.storageSize)),
      );
    },
    setCustomerVendors(state, vendors) {
      state.vendors = vendors;
    },
    setVendorName(state, vendorName) {
      state.vendorName = vendorName;
    },
    setMeta(state, filters) {
      state.meta = filters;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
  },

  actions: {
    async setCustomer({ commit, state, dispatch }, customer) {
      if (state.customer.id && state.customer.id !== customer.id) {
        await dispatch('bills/clearFilters', null, { root: true });
      }
      return commit('setCurrentCustomer', customer);
    },
    async updateCustomer({ commit }, { id, body }) {
      await customers.updateCustomer(id, body);
      return commit('setCurrentCustomer', { id, ...body });
    },
    setUsers({ commit }, users) {
      return Promise.resolve(commit('setUsers', users));
    },
    async getCustomersList({ commit }, activeCustomers) {
      try {
        let { data = [] } = await customers.list({
          limit: 5000,
          orders: ['+id'],
          active: activeCustomers,
        });
        commit('setCustomersList', data);
      } catch (e) {
        commit('setCustomersList', []);
      }
    },
    async getCustomerVendors({ commit, state }, customerId) {
      const { data = {} } = await customers.getCustomerVendors(
        customerId,
        state.filters,
      );
      commit('setMeta', { total: data.total });
      commit('setCustomerVendors', data.results);
    },
    async updateVendorsBulk({ commit, state }, body) {
      await customers.updateBulk(
        state.customer.id,
        body.map((vendor) => ({
          id: vendor.id,
          prettyName: vendor.customPrettyName,
        })),
      );
      commit('setCustomerVendors', body);
    },
  },
};
