<template>
  <div class="error-bar-wrapper">
    <div class="header-wrapper">
      <h2>Bill Validation Errors</h2>
      <div class="btn-v1parser" v-if="isParserV1">v1 Parser</div>
      <template v-else>
        <span class="critical" v-if="errorStatus.type === 'critical'">
          {{ errorStatus.count }} Critical Errors
        </span>
        <span class="audit" v-else-if="errorStatus.type === 'audit'">
          {{ errorStatus.count }} Audit Errors
        </span>
        <span class="processed" v-else-if="errorStatus.type === 'processed'">
          <i class="el-icon-check"></i>
        </span>
      </template>
    </div>
    <div class="select-wrapper" v-if="!isParserV1">
      <span>Go to:</span>
      <el-select @change="selectChange" v-model="stateModel" filterable>
        <el-option
          v-for="err in billErrors"
          :key="err.workflowState"
          :label="err.workflowState"
          :value="err.workflowState"
        ></el-option>
      </el-select>
    </div>
    <div
      class="errors-container-wrapper error-bar-content"
      ref="container"
      :key="componentKey"
    >
      <div
        class="errors-container"
        v-bind:key="category.id"
        v-for="category in billErrorsSorted"
        :ref="formatRef(category.workflowState)"
      >
        <div class="errors-item" v-if="isParserV1">
          <div
            class="error-entity-container"
            v-bind:key="errorItem.validationCheckId"
            v-for="errorItem in category.errors"
          >
            <span class="text text-padding">{{ errorItem.message }}</span>
            <span class="code">{{ errorItem.validationCheckId }}</span>
          </div>
        </div>
        <div class="errors-item" v-else>
          <h3>
            {{ category.workflowState }}
            {{ getUnresolvedErrors(category.errors) }}
          </h3>
          <div v-bind:key="errorItem.id" v-for="errorItem in category.errors">
            <div
              class="error-element"
              v-if="isResolvable(errorItem.validationCheckId)"
            >
              <el-row class="error-row">
                <el-checkbox-group
                  class="el-check"
                  v-model="checkedErrorItems"
                  @change="handleChangeCheckedErrorItems"
                >
                  <el-checkbox
                    class="el-check ml-28"
                    :class="{ 'grey-out': isResolved(errorItem) }"
                    :label="errorItem.id"
                    :key="errorItem.id"
                    :checked="errorItem.resolved"
                    data-testid="errorRow"
                  >
                    <el-row
                      class="error-entity-container"
                      data-testid="resolvableErrorContainer"
                    >
                      <span
                        class="text"
                        :class="{ 'grey-out': isResolved(errorItem) }"
                        data-testid="errorMessage"
                      >
                        {{ errorItem.message }}
                      </span>
                      <span
                        class="code"
                        :class="{ 'grey-out': isResolved(errorItem) }"
                        data-testid="errorCode"
                      >
                        {{ errorItem.validationCheckId }}
                      </span>
                    </el-row>
                  </el-checkbox>
                </el-checkbox-group>
              </el-row>
              <el-row
                v-if="checkedErrorItems.indexOf(errorItem.id) !== -1"
                class="tag-select-row"
              >
                <tag-select
                  :options="errorReasons"
                  :initialValue="errorItem.reasoningId"
                  @updatedValue="updatedErrorReason(errorItem, $event)"
                />
              </el-row>
            </div>
            <div
              class="error-entity-container"
              data-testid="unresolvableErrorContainer"
              v-else
            >
              <span class="checkbox-placeholder">!</span>
              <span class="text" data-testid="errorMessage">
                {{ errorItem.message }}
              </span>
              <span class="code" data-testid="errorCode">
                {{ errorItem.validationCheckId }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="check-all-container" v-if="!isParserV1">
      <el-checkbox
        v-model="checkAll"
        :indeterminate="isIndeterminate"
        :disabled="areAllUnresolvable"
        @change="handleCheckAllChange"
      >
        <span class="check-all">Check All</span>
      </el-checkbox>
    </div>
    <template v-if="!isParserV1">
      <div class="actions-container">
        <span class="title" v-show="changesMade">
          Changes made. Click Submit to save them
        </span>
        <div class="comment-input-container">
          <el-input
            class="comment-input"
            v-model="comment"
            type="textarea"
            :rows="1"
            :autosize="{ maxRows: 3 }"
            size="small"
            :disabled="!changesMade"
            placeholder="Add a comment here (optional)"
            data-testid="commentInput"
          ></el-input>
        </div>
        <template v-if="isMyBill">
          <el-button
            @click="resolveAndKeep"
            :loading="keepLoading"
            :disabled="!changesMade"
            data-testid="updateAndKeepBtn"
          >
            Update and Keep Assignment
          </el-button>
          <el-button
            @click="resolveAndRelease"
            :loading="releaseLoading"
            :disabled="!changesMade"
            data-testid="updateAndReleaseBtn"
          >
            Update and Release Assignment
          </el-button>
        </template>
        <template v-else>
          <el-button
            @click="resolveAndKeep"
            :loading="keepLoading"
            :disabled="!changesMade"
            data-testid="updateAndKeepBtn"
          >
            Submit
          </el-button>
        </template>
        <el-button
          @click="cancel"
          :disabled="!changesMade"
          data-testid="cancelBtn"
        >
          Cancel
        </el-button>
      </div>
    </template>
  </div>
</template>

<script>
import { bills, globalBills } from '@/api'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import * as R from 'ramda'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import TagSelect from '@/components/ExtendedFormComponents/TagSelect'
import { updateBillErrorReasoning } from '@/api/bills'

const workflowStateOrder = [
  'Integrity Check',
  'Data Verification I',
  'Data Verification II',
  'Data Audit I',
  'Data Audit II',
  'Processed',
]
const orderNum = workflowState =>
  workflowStateOrder.findIndex(
    s => s === R.prop('workflowState', workflowState),
  )

export default {
  components: { TagSelect, BaseSelect },
  props: {
    workflowState: {
      type: String,
      default: () => '',
    },
    navigation: {
      type: Object,
      default: () => ({}),
    },
    billId: {
      type: [String, Number],
      default: () => '',
    },
    billValidations: {
      type: Array,
      default: () => [],
    },
    errorsList: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      history: [],
      stateModel: '',
      checkedErrorItems: [],
      resolve: [],
      unresolve: [],
      checkAll: false,
      isIndeterminate: false,
      bulkActionsQueue: [],
      keepLoading: false,
      releaseLoading: false,
      componentKey: 0,
      inputLimit: 300,
      clonedCheckedErrorItems: [],
      comment: '',
      errorReasons: [],
      activatedErrorReasons: [],
    }
  },
  watch: {
    billId() {
      this.updateWFState()
      this.history = []
    },
    filterData() {
      this.updateWFState()
    },
    comment(val) {
      if (val.length > this.inputLimit) {
        this.comment = R.slice(0, 300, this.comment)
      }
    },
  },
  async mounted() {
    if (this.errorsList.length && this.billValidations.length) {
      this.clonedResolvedItems = _.cloneDeep(
        this.errorsList.filter(
          errorItem =>
            errorItem && this.isResolvable(errorItem.validationCheckId),
        ),
      )
      this.clonedCheckedErrorItems = _.cloneDeep(this.checkedErrorItems)
      this.handleCheckAllState()
    }

    const errorReasonsResponse = await bills.getBillErrorReasons()
    this.errorReasons = errorReasonsResponse.results
  },
  computed: {
    ...mapGetters({
      isParserV1: 'bills/isParserV1',
      resolvedErrors: 'bills/resolvedErrors',
      user: 'user/user',
      filterData: 'globalBills/filtersData/filterData',
    }),
    isResolved() {
      return errorItem => {
        if (!this.checkedErrorItems.includes) {
          this.checkedErrorItems = []
          for (let i = 0; i < this.clonedResolvedItems.length; i++) {
            this.checkedErrorItems.push(this.clonedResolvedItems[i].id)
          }
        }
      }
    },
    areAllUnresolvable() {
      return this.errorsList.every(
        errorItem => !this.isResolvable(errorItem.validationCheckId),
      )
    },
    changedReasons() {
      return !this.activatedErrorReasons.reduce((reduced, currentError) => {
        return (
          this.clonedCheckedErrorItems.indexOf(currentError.id) === -1 ||
          ((!currentError.reason ||
            currentError.reasoningId === currentError.reason.id) &&
            reduced)
        )
      }, true)
    },
    changesMade() {
      this.clonedCheckedErrorItems = this.clonedCheckedErrorItems.sort(
        (a, b) => a - b,
      )
      this.checkedErrorItems = this.checkedErrorItems.sort((a, b) => a - b)
      return (
        this.changedReasons ||
        !_.isEqual(this.clonedCheckedErrorItems, this.checkedErrorItems)
      )
    },
    states() {
      return (this.filterData.workflow_states || []).map(({ name }) => name)
    },
    billErrors() {
      if (
        !this.billValidations ||
        !this.errorsList ||
        !this.filterData.workflow_states
      ) {
        return []
      }

      const tmp = {}
      this.errorsList
        .map(currentError => {
          const resolvedError = this.resolvedErrors.find(currentErr => {
            return (
              currentErr.validationCheckId === currentError.validationCheckId &&
              JSON.stringify({ ...currentErr.data }) ===
                JSON.stringify({ ...currentError.data })
            )
          })
          if (resolvedError) {
            currentError.reasoningId = resolvedError.reasoningId
          }
          return currentError
        })
        .forEach(e => {
          const validation =
            this.billValidations.find(i => +i.id === +e.validationCheckId) || {}
          if (!validation) return
          const state = `${(
            this.filterData.workflow_states.find(
              i => i.value === validation.workflowState,
            ) || {}
          ).name || 'Deprecated'}`

          if (!tmp[state]) {
            tmp[state] = []
          }

          tmp[state].push(e)
        })
      const list = Object.keys(tmp).map(workflowState => ({
        workflowState,
        errors: tmp[workflowState],
      }))
      return R.sortBy(orderNum)(list)
    },
    billErrorsSorted() {
      return R.sortBy(orderNum)(this.billErrors)
    },
    myNextBillId() {
      return R.path(['next', 'billId'], this.navigation)
    },
    myNextBillCustomerId() {
      return R.path(['next', 'customerId'], this.navigation)
    },
    hasNextBill() {
      return this.myNextBillId && this.myNextBillId
    },
    isMyBill() {
      return +R.path(['id'], this.user) === this.userId
    },
    errorItemsList() {
      return this.errorsList
    },
    errorStatus() {
      if (!this.billValidations) {
        return { type: '', count: 0 }
      }

      const getCount = (states = []) =>
        _.flatten(
          this.billErrors
            .filter(e => states.includes(e.workflowState))
            .map(({ errors }) => errors.filter(i => !i.resolved)),
        ).length
      const state = (
        this.billValidations.find(
          i => i.workflowState === this.workflowState,
        ) || {}
      ).name
      const count = (
        (this.billErrors.find(e => e.workflowState === state) || {}).errors ||
        []
      ).filter(i => !i.resolved).length
      if (
        [
          'integrity_check',
          'data_verification_1',
          'data_verification_2',
        ].includes(this.workflowState)
      ) {
        return {
          type: getCount([
            'Integrity Check',
            'Data Verification I',
            'Data Verification II',
          ])
            ? 'critical'
            : '',
          count: getCount([
            'Integrity Check',
            'Data Verification I',
            'Data Verification II',
          ]),
        }
      } else if (
        ['data_audit_1', 'data_audit_2'].includes(this.workflowState)
      ) {
        return {
          type: getCount(['Data Audit I', 'Data Audit II']) ? 'audit' : '',
          count: getCount(['Data Audit I', 'Data Audit II']),
        }
      } else if (['processed'].includes(this.workflowState)) {
        return { type: 'processed', count }
      } else {
        return { type: '', count }
      }
    },
  },
  methods: {
    ...mapActions({
      loadBillPath: 'bills/loadBillPath',
      getMyBillsSummary: 'myBills/getMyBillsSummary',
    }),
    updatedErrorReason(error, reason) {
      if (
        !this.activatedErrorReasons.find(currentError => {
          return currentError.id === error.id
        })
      ) {
        this.activatedErrorReasons.push(error)
      }
      error.reason = reason
      this.activatedErrorReasons = [...this.activatedErrorReasons]
    },
    handleChangeCheckedErrorItems(checkedItemList) {
      let checkedCount = checkedItemList.length
      this.checkAll = checkedCount === this.clonedResolvedItems.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.clonedResolvedItems.length
    },
    handleCheckAllState() {
      const checkedErrors = this.clonedResolvedItems.filter(
        check => check.resolved === true,
      )
      this.isIndeterminate = false
      if (!this.clonedResolvedItems.length) {
        this.checkAll = false
        return
      }
      if (checkedErrors.length === this.clonedResolvedItems.length) {
        this.checkAll = true
        return
      }
      if (checkedErrors.length) {
        this.isIndeterminate = true
        return
      }
      this.checkAll = false
    },
    handleCheckAllChange(val) {
      const errorListIds = this.clonedResolvedItems.map(
        errorItem => errorItem.id,
      )
      this.checkedErrorItems = val ? errorListIds : []
      this.isIndeterminate = false
    },
    getUnresolvedErrors(errors = []) {
      const items = errors.filter(e => !e.resolved)
      return items.length ? `(${items.length})` : ''
    },
    updateWFState() {
      if (!this.billValidations) {
        this.stateModel = ''
        return
      }
      const states = this.billErrors.map(({ workflowState }) => workflowState)
      const state = (
        this.billValidations.find(
          i => i.workflowState === this.workflowState,
        ) || {}
      ).name
      if (!states.includes(state)) {
        this.stateModel = ''
        return
      }

      this.stateModel = state
    },
    formatRef(val = '') {
      return val.toLowerCase().replace(/ /g, '_')
    },
    selectChange(val) {
      const [ref] = this.$refs[this.formatRef(val)]

      setTimeout(() => this.$refs.container.scrollTo(0, ref.offsetTop), 300)
    },
    isResolvable(code) {
      return (
        this.billValidations &&
        this.billValidations.find(e => +e.id === +code && e.resolvable)
      )
    },
    clearState() {
      this.resolve = []
      this.unresolve = []
      this.history = []
    },
    cancel() {
      this.clearState()
      this.componentKey += 1
    },
    async resolveAndKeep() {
      this.keepLoading = true
      await this.bulkResolveError()
      await this.$emit('loadBill')
      this.clearState()
      this.keepLoading = false
    },
    async resolveAndRelease() {
      this.releaseLoading = true
      await this.bulkResolveError()
      await this.bulkReassign()
      this.clearState()
      this.releaseLoading = false
      this.redirectAfterReassign()
    },
    redirectAfterReassign() {
      if (this.hasNextBill) {
        this.loadBillPath({
          path: {
            name: 'customer.bills.info',
            params: {
              customerId: String(this.myNextBillCustomerId),
              billId: this.myNextBillId,
            },
            query: this.$route.query,
          },
          router: this.$router,
        })
      } else {
        this.$router.push({ name: 'bills.my' })
      }
    },
    async bulkResolveError() {
      this.resolve = this.checkedErrorItems.filter(
        id => !this.clonedCheckedErrorItems.includes(id),
      )
      this.unresolve = this.clonedCheckedErrorItems.filter(
        id => !this.checkedErrorItems.includes(id),
      )
      if (this.resolve.length) {
        const data = {
          billMetaId: +this.billId,
          errors: _.uniq(
            this.errorsList
              .filter(e => this.resolve.includes(e.id))
              .map(e => {
                return {
                  billErrorId: parseInt(e.id),
                  reasoningId: parseInt(
                    e.reason
                      ? e.reason.id
                      : this.errorReasons.find(errReason => errReason.isDefault)
                          .id,
                  ),
                }
              }),
          ),
          comment: this.comment,
        }
        try {
          await bills.resolveErrors(data)
          this.$message({
            message: 'Resolve Errors successful.',
            type: 'success',
          })
        } catch (e) {
          this.$message({
            message: 'Resolve Errors failed. Please try again later.',
            type: 'error',
          })
        }
      }

      if (this.unresolve.length) {
        const data = {
          billMetaId: +this.billId,
          errorsIds: _.uniq(
            this.errorsList
              .filter(e => this.unresolve.includes(e.id))
              .map(e => parseInt(e.id)),
          ),
          comment: this.comment,
        }
        try {
          await bills.unresolveErrors(data)
          this.$message({
            message: 'Unresolve Errors successful.',
            type: 'success',
          })
        } catch (e) {
          this.$message({
            message: 'Unresolve Errors failed. Please try again later',
            type: 'error',
          })
        }
      }

      for (const currentError of this.activatedErrorReasons) {
        if (this.clonedCheckedErrorItems.indexOf(currentError.id) !== -1) {
          if (
            currentError.reason &&
            currentError.reasoningId !== currentError.reason.id
          ) {
            try {
              await updateBillErrorReasoning({
                billErrorId: currentError.id,
                reasoningId: currentError.reason.id,
              })
              this.$message({
                message:
                  'Updated Reasoning For Bill:' +
                  currentError.id +
                  ' : ID(' +
                  currentError.reason.id +
                  ')',
                type: 'success',
              })
            } catch (e) {
              this.$message({
                message:
                  'Failed To Update Reasoning For Bill:' +
                  currentError.id +
                  ' : ID(' +
                  currentError.reason.id +
                  ')',
                type: 'error',
              })
            }
          }
        }
      }
    },
    async bulkReassign() {
      const data = {
        assign: {
          billsIds: [this.billId],
          assignee: 0,
        },
      }
      try {
        await globalBills.bulkActions(data)
        await this.getMyBillsSummary()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../../../styles/constants.scss';
.error-bar-wrapper {
  .pill-select {
    margin-left: 50px;

    &:hover {
      input {
        border-style: solid;
        background-color: var(--el-disabled-fill-base);
      }
      .el-input__suffix {
        border-style: none;
        display: flex;
        align-items: center;
      }
    }

    .el-input__suffix {
      display: none;
    }

    input {
      width: auto;
      height: 18px;
      font-size: 12px;
      border-radius: 25px;
      padding: 8px;
      border-style: none;
      background-color: var(--el-disabled-fill-base);
    }
  }
}
.el-checkbox__label {
  width: 97%;
}
.ml-28 {
  margin-left: 28px;
}
.el-check {
  width: 100%;
  max-width: 97%;
}
.error-bar-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .check-all-container {
    border-top: 1px solid #d8dce5;
    margin: 0px 28px 10px 28px;
    .check-all {
      font-weight: 600;
      margin-top: 10px;
      color: #606266;
      font-size: 12px;
    }
  }
  .error-bar-content {
    flex-grow: 2;
    overflow-y: scroll;
    @extend %scrollbar;
  }
  .btn-v1parser {
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 12px;
    margin: 0 17px 0 auto;
    background-color: #fbecd8;
    color: #e6a23b;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
  }
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h2 {
      color: #222222;
      font-size: 18px;
      line-height: 24px;
      margin: 20px 20px 20px 57px;
    }

    span {
      font-size: 16px;
      line-height: normal;
      font-weight: 700;

      &.critical {
        color: #f56c6c;
      }

      &.processed {
        color: #67c23a;
        font-size: 20px;
      }

      &.audit {
        color: #f3a745;
      }
    }
  }

  .select-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 57px;

    > span {
      margin-right: 20px;
    }

    .el-select {
      input {
        height: 29px;
        line-height: normal;
      }

      i {
        line-height: normal;
      }
    }
  }

  .errors-container {
    .errors-item {
      margin-top: 20px;
      h3 {
        color: #222222;
        font-size: 16px;
        line-height: 22px;
        margin-left: 57px;
      }

      .error-element {
        margin-bottom: 8px;
        .error-row {
          margin-bottom: 8px;

          .el-checkbox {
            display: flex;
            align-items: center;

            .error-entity-container {
              margin-bottom: 0px;
            }
          }
        }
        .tag-select-row {
          margin-left: 50px;
        }
      }

      .error-entity-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        min-height: 20px;

        .check-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .touch-indicator {
            height: 7px;
            width: 7px;
            border-radius: 50%;
            background-color: transparent;
            margin: 0 10px;

            &.touched {
              background-color: #a3d0fd;
            }
          }
        }

        &.checked {
          cursor: pointer;
          span {
            color: #c1c4cc;
            font-weight: 400 !important;
          }

          .el-icon-check {
            display: flex;
          }

          .el-checkbox {
            display: none;
          }

          &:hover {
            span {
              color: #4a90e2;
            }
            .el-checkbox {
              display: flex;
            }
            .el-icon-check {
              display: none;
            }
          }
        }

        .el-icon-check {
          display: none;
          margin: 0 10px 0 0;
          font-size: 12px;
          font-weight: 700;
          color: #4a90e2;
        }

        .el-checkbox {
          margin: 0 8px 0 0;
          max-height: 20px;
          .el-checkbox__input {
            line-height: normal;
            vertical-align: baseline;
          }
        }

        .checkbox-placeholder {
          flex: 0 0 12px;
          margin: 0 8px 0 29px;
          font-weight: 700;
          color: #f86259;
        }

        > span {
          color: #606266;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 14px;
          text-align: left;

          &.text {
            text-overflow: ellipsis;
            overflow: hidden;
            flex-grow: 1;
            white-space: normal;
            &-padding {
              padding-left: 17px;
            }
          }

          &:last-child {
            margin: 0 17px;
          }
        }
      }
    }
  }

  .actions-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.05);

    span.title {
      color: #f66c6d;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      margin: 12px 0;
    }

    .el-button {
      height: 30px;
      line-height: normal;
      padding: 0;
      width: 256px;
      border-radius: 4px;
      background-color: #edf5ff;
      margin-left: 0;
      color: #3f9fff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      border: none;
      margin-bottom: 8px;

      &:last-child {
        background-color: transparent;
      }
    }
  }
}
.comment-input-container {
  width: 100%;
}
.comment-input {
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
}
.grey-out {
  opacity: 0.5;
}
</style>
