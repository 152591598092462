import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';
import * as qs from 'qs';

export const getCommunicationErrors = (params) =>
  http(hosts.v3)
    .get(
      `emailer/delivery-status?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware);
