<template lang="pug">
  el-dialog(class="modal-dialog"
    :title='title',
    width='756px',
    :visible.sync='showModal',
    @open="initForm",
    @close="close")
    validation-observer(v-slot="{ invalid }", ref="observer")
      error-card.err-card-styling(:title="''", :statusMessage="errorMessage")
      form.create-location
        h3 Main Information
        .row
          .form-group
            label Description
            validation-provider.validation-provider-wrapper(name="name", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.name")
              el-input(placeholder="Description",
                v-model="form.name",
                :class='{ "has-error": flags.invalid && flags.touched}')
              error-tooltip(:error="errors[0]")
          .form-group
            label Type
            validation-provider.validation-provider-wrapper(name="building_type", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.building_type")
              el-select(placeholder="Type",
                filterable
                clearable
                v-model="form.buildingType",
                :popper-append-to-body="false",
                :class='{ "has-error": flags.invalid && flags.touched}')
                el-option(v-for='item in ["Building", "Campus", "Storefront"]', :key='item', :label='item', :value='item')
              error-tooltip(:error="errors[0]")
        .row
          .form-group
            label Address
            validation-provider.validation-provider-wrapper(name="address", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.address")
              el-input(placeholder="Address",
                v-model="form.address",
                :class='{ "has-error": flags.invalid && flags.touched}')
              error-tooltip(:error="errors[0]")
          .form-group
            label City
            validation-provider.validation-provider-wrapper(name="city", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.city")
              el-input(placeholder="City",
                v-model="form.city",
                :class='{ "has-error": flags.invalid && flags.touched}')
              error-tooltip(:error="errors[0]")
        .row
          .form-group
            label Postcode
            validation-provider.validation-provider-wrapper(name="postcode", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.postcode")
              el-input(placeholder="00000",
                v-model="form.postcode",
                :class='{ "has-error": flags.invalid && flags.touched}')
              error-tooltip(:error="errors[0]")
          .form-group
            label State
            validation-provider.validation-provider-wrapper(name="state", rules="required", immediate, v-slot="{ flags, errors }", ref="payload.state")
              el-select(placeholder="State",
                filterable
                clearable
                v-model="form.state",
                :popper-append-to-body="false",
                :class='{ "has-error": flags.invalid && flags.touched}')
                el-option(v-for='(v, k) in states', :key='k', :label='v.pretty_name', :value='k')
              error-tooltip(:error="errors[0]")
        .row
          .form-group
            label Area (Sq. Ft.)
            validation-provider.validation-provider-wrapper(name="square_feet", rules="required|double", immediate, v-slot="{ flags, errors }", ref="payload.square_feet")
              el-input(placeholder="0",
                v-model.number="form.squareFeet",
                :class='{ "has-error": flags.invalid && flags.touched}')
              error-tooltip(:error="errors[0]")
          .form-group
            label Country
            validation-provider.validation-provider-wrapper(name="country", immediate, v-slot="{ flags, errors }", ref="payload.country")
              el-select(placeholder="Country",
                filterable
                clearable
                v-model="form.country",
                :class='{ "has-error": flags.invalid}',
                :popper-append-to-body="false")
                el-option(v-for='(v, k) in countries', :key='k', :label='v.pretty_name', :value='k')
              error-tooltip(:error="errors[0]")
        .row
          .form-group
            label Latitude
            validation-provider.validation-provider-wrapper(name="latitude", immediate, v-slot="{ flags, errors }", ref="payload.latitude")
              el-input(placeholder="00.0000",
                :class='{ "has-error": flags.invalid}',
                v-model="form.latitude")
              error-tooltip(:error="errors[0]")
          .form-group
            label Longitude
            validation-provider.validation-provider-wrapper(name="longitude", immediate, v-slot="{ flags, errors }", ref="payload.longitude")
              el-input(placeholder="00.0000",
                :class='{ "has-error": flags.invalid}',
                v-model="form.longitude")
              error-tooltip(:error="errors[0]")
        .hr
        h3 Optional Fields
        .row
          .form-group
            label Phone
            validation-provider.validation-provider-wrapper(name="site_phone", immediate, v-slot="{ flags, errors }", ref="payload.site_phone")
              el-input(placeholder="000-000-0000",
                :class='{ "has-error": flags.invalid}',
                v-model="form.payload.sitePhone")
              error-tooltip(:error="errors[0]")
          .form-group
            label Site number
            validation-provider.validation-provider-wrapper(name="site_number", immediate, v-slot="{ flags, errors }", ref="payload.site_number")
              el-input(placeholder="Site number",
                :class='{ "has-error": flags.invalid}',
                v-model="form.payload.siteNumber")
              error-tooltip(:error="errors[0]")
        .row
          .form-group
            label Fax
            validation-provider.validation-provider-wrapper(name="site_fax", immediate, v-slot="{ flags, errors }", ref="payload.site_fax")
              el-input(placeholder="(000)-000-0000",
                :class='{ "has-error": flags.invalid}',
                v-model="form.payload.siteFax")
              error-tooltip(:error="errors[0]")
          .form-group
            label Division
            validation-provider.validation-provider-wrapper(name="site_division", immediate, v-slot="{ flags, errors }", ref="payload.site_division")
              el-input(placeholder="Division",
                :class='{ "has-error": flags.invalid}',
                v-model="form.payload.siteDivision")
              error-tooltip(:error="errors[0]")
        .row.single
          .form-group
            label Operating status
            validation-provider.validation-provider-wrapper(name="site_status", immediate, v-slot="{ flags, errors }", ref="payload.site_status")
              el-select(placeholder="Select",
                filterable
                clearable
                v-model="form.payload.siteStatus",
                :class='{ "has-error": flags.invalid}',
                :popper-append-to-body="false")
                el-option(v-for='item in ["Operational", "Non-Operational", "Not Set"]', :key='item', :label='item', :value='item')
              error-tooltip(:error="errors[0]")
          .form-group
        template(v-if="customAttributes.length > 0")
          .hr
          h3 Custom Attributes
          .row.custom-attributes(v-for="c in chunkBy2(customAttributes)")
            .form-group(v-for='{id, attribute_name, attribute_value, attribute_uom} in c', :class="{'form-group-one': c.length === 1}")
              label {{ attribute_name }}
              el-input(v-if="customAttributesForm[attribute_name].type === 'String'",
                v-model="customAttributesForm[attribute_name].value",
                size="small",
                placeholder="N/A",
                @blur="handleInput(attribute_name)")
              el-input-number(v-if="customAttributesForm[attribute_name].type === 'Numeric'",
                size="small",
                v-model="customAttributesForm[attribute_name].value",
                @change="handleInput(attribute_name)")
              el-date-picker(v-if="customAttributesForm[attribute_name].type === 'Date'",
                size="small",
                v-model="customAttributesForm[attribute_name].value",
                format="MM.dd.yyyy",
                placeholder="N/A",
                :clearable="false",
                @blur="handleInput(attribute_name)")
        .hr
        h3 Weather Data
        .form-group
          label Activate location weather data:
          el-checkbox.checkbox-padding( v-model="form.weatherActive")
        .controls
          el-button.plain-button-reverse(@click="() => close('showModal')") Cancel
          el-button(type="primary", @click="handleSubmit", :loading="isLoading", :disabled="invalid") Create
</template>

<script>
import { locations } from '@/api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import _ from 'lodash';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import ErrorTooltip from '@/pages/Customers/Locations/Controls/ErrorTooltip';
import { mapGetters } from 'vuex';
import ErrorCard from '@/components/Cards/ErrorCard.vue';

const trimSpace = (obj = {}) => {
  const keys = R.keys(obj) || [];
  return keys.reduce((acc, key) => {
    acc[key] = typeof obj[key] === 'string' ? R.trim(obj[key]) : obj[key];
    return acc;
  }, {});
};

const initialFormData = () => ({
  name: '',
  buildingType: 'Building',
  address: '',
  postcode: '',
  squareFeet: '',
  state: '',
  city: '',
  country: 'US',
  latitude: '',
  longitude: '',
  weatherActive: true,
  payload: {
    siteDivision: '',
    sitePhone: '',
    siteFax: '',
    siteNumber: '',
    siteStatus: '',
  },
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ErrorTooltip,
    ErrorCard,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  watch: {
    visible(val) {
      this.showModal = val;
    },
    'form.country': function () {
      this.form.state = '';
    },
  },
  data() {
    return {
      metadataList: [],
      form: initialFormData(),
      showModal: false,
      isLoading: false,
      customAttributesForm: {},
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      getResourceByName: 'resources/getResourceByName',
    }),
    states: {
      get() {
        const { country } = this.form;
        return R.pipe(
          R.propOr({}, 'value'),
          R.pickBy((value, key) => R.startsWith(country, key)),
        )(this.getResourceByName('states'));
      },
    },
    countries: {
      get() {
        return R.propOr({}, 'value', this.getResourceByName('countries'));
      },
    },
    customAttributes() {
      return this.metadataList.map((e) => ({
        ...e,
        attribute_value: '',
      }));
    },
  },
  async beforeMount() {
    await this.loadMetadataList();
    this.setCustomAttributesForm();
  },
  methods: {
    async loadMetadataList() {
      const { data } = await locations.getMetaData(
        this.$route.params.customerId,
      );
      data.sort((a, b) => a.sort_order - b.sort_order);
      this.metadataList = data;
    },
    handleInput(key) {
      const { value, initial_value } = this.customAttributesForm[key];

      if (value !== initial_value) {
        this.customAttributesForm[key].wasChanged = true;
      }
      if (value === '' || value === null || value === undefined) {
        this.customAttributesForm[key].value = null;
      }
    },
    scrollToTop() {
      const el = document.querySelector('.el-dialog__body');
      if (el) {
        el.scroll(0, 0);
      }
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        const payload = _.pickBy(
          this.form.payload,
          (value, key) => key !== 'metadata',
        );
        const mandatory = _.pickBy(
          this.form,
          (value, key) => key !== 'payload',
        );
        mandatory.buildingType = mandatory.buildingType.toUpperCase();
        const metadata = this.getMetadata();
        metadata.forEach((item) => {
          payload[item.id] = item.value;
        });
        const request = {
          ...trimSpace(mandatory),
          payload: { ...trimSpace(payload) },
        };
        await locations.create(this.$route.params.customerId, request);

        this.initForm();
        this.$refs.observer.validate();
        this.showModal = false;
      } catch (e) {
        const code = _.get(e, 'response.status', 500);
        if (code === 409) {
          this.errorMessage = e.response.data.error || '';
          this.scrollToTop();
        }
        if (code === 406) {
          try {
            const errors = JSON.parse(_.get(e, 'response.data.message', '{}'));
            _.each(errors, (i, key, obj) => {
              this.$refs[key].applyResult({
                errors: obj[key],
                valid: false,
                failedRules: {},
              });
            });
          } catch (e) {
            console.log('invalid json response');
          }
        }
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    setCustomAttributesForm() {
      this.customAttributesForm = this.customAttributes.reduce((o, k) => {
        o[k.attribute_name] = {
          id: k.id,
          type: k.attribute_type,
          value: k.attribute_value,
          initial_value: k.attribute_value,
          wasChanged: false,
        };
        return o;
      }, {});
    },
    close() {
      this.errorMessage = '';
      this.$emit('close');
    },
    chunkBy2(arr = []) {
      return _.chunk(arr, 2);
    },
    getMetadata() {
      return Object.keys(this.customAttributesForm)
        .map((key) => {
          let dateValue = null;

          if (
            this.customAttributesForm[key].value &&
            this.customAttributesForm[key].type === 'Date'
          ) {
            dateValue = DateTime.fromMillis(
              Date.parse(this.customAttributesForm[key].value),
            ).toFormat('MM.dd.yyyy');
          }

          return {
            ...this.customAttributesForm[key],
            value: dateValue || String(this.customAttributesForm[key].value),
          };
        })
        .filter((e) => e.wasChanged)
        .map(({ id, value }) => ({ id, value }))
        .filter((e) => e.value !== 'null');
    },
    initForm() {
      if (this.$refs.observer) {
        this.$refs.observer.reset();
        this.$refs.observer.validate();
        this.form = initialFormData();
        this.setCustomAttributesForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.err-card-styling {
  margin-bottom: 10px;
  margin-top: 10px;
}
/deep/ {
  .el-dialog {
    max-height: 75vh;

    &__body {
      max-height: calc(75vh - 75px);
      overflow-y: auto;
    }
  }

  .el-select {
    .el-input__suffix {
      display: flex;
      align-items: center;
    }
  }

  .el-select-dropdown.el-popper {
    position: absolute !important;
    top: 30px !important;
    left: 0 !important;
  }
}

form.create-location {
  .row {
    .form-group {
      label {
        word-break: break-word;
      }
    }

    &.custom-attributes {
      .form-group {
        &-one {
          margin-left: 0;
          margin-right: 0;
        }

        /deep/ {
          .el-input {
            width: 130px;
            min-width: 130px;
          }

          .el-input-number {
            .el-input {
              width: 130px;
              min-width: 130px;
            }
          }

          .el-date-editor {
            &.el-input {
              width: 130px;
              min-width: 130px;
            }
          }
        }
      }
    }
  }
}

.el-input {
  width: initial;
}

.validation-provider-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.icon-question.el-tooltip {
  position: absolute;
  left: 100%;
}

.checkbox-padding {
  padding-left: 1em;
}
</style>
