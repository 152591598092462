import { http, dataMiddleware } from '@/lib';
import { hosts } from '@/lib/api.configs';

const list = (id, params) =>
  http(hosts.v1)
    .get(`/customers/${id}/exceptions`, { params })
    .then(dataMiddleware);

const listUnprocessed = (id, params) =>
  http(hosts.v1)
    .get(`/customers/${id}/unprocessed-bills`, { params })
    .then(dataMiddleware);

const getMatchesList = (id, serviceZip) =>
  http(hosts.v1)
    .get(`/customers/${id}/exceptions/matches`, {
      params: { service_zip: serviceZip },
    })
    .then(dataMiddleware);

const matchException = (id, locationId, data) => {
  return http(hosts.v3)
    .post(`/customers/${id}/locations/${locationId}/virtual-account`, data)
    .then(dataMiddleware);
};

const searchLocation = (id, query) =>
  http(hosts.v1)
    .get(`/customers/${id}/exceptions/closest_matches`, { params: { query } })
    .then(dataMiddleware);

const mapUnprocessedBill = (customerId, recordId, bill_id) => {
  return http(hosts.v1)
    .post(`/customers/${customerId}/unprocessed-bills/${recordId}/match`, {
      bill_id,
    })
    .then(dataMiddleware);
};

export {
  list,
  matchException,
  getMatchesList,
  searchLocation,
  listUnprocessed,
  mapUnprocessedBill,
};
