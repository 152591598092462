import Papa from 'papaparse';

const moment = require('moment-timezone');
const { DateTime } = require('luxon');
const R = require('ramda');

const dateFormat = (gTimestamp, format = 'ddd MMM D YYYY') => {
  const seconds = (gTimestamp.seconds || 0) * 1000;
  const nanos = (gTimestamp.nanos || 0) / 1000000;
  return moment.tz(seconds + nanos, 'UTC').format(format);
};

// Make one generic fucntion for date formatting with luxon
const dateFormatSlash = (timestamp, format = ' dd/LLL/yyyy t') => {
  return DateTime.fromISO(timestamp).toFormat(format);
};

const dateFormatFull = (timestamp, format = 'LLLL dd, y') => {
  return DateTime.fromISO(timestamp).toFormat(format);
};

const ISODateFormat = (timestamp, format = 'ddd MMM D YYYY') =>
  moment.tz(timestamp, 'UTC').format(format);

const convertDateFromProtobuf = (seconds = 0, nanos = 0) =>
  new Date(seconds * 1000 + nanos / 1000000);

const pickNotEmpty = R.pickBy(R.complement(R.isEmpty));
const pickNotNil = R.pickBy(R.complement(R.isNil));

const mapOrderByField = (order, field) =>
  `${order === 'descending' ? '-' : '+'}${field}`;

const csvJSON = (csv) => {
  const arr = Papa.parse(csv);
  const result = [];
  const headers = arr.data.shift();
  arr.data.forEach((line) => {
    const obj = {};
    headers.forEach((key, i) => {
      obj[key] =
        key === 'sort_order'
          ? (obj[key] = +line[i])
          : (obj[key] = line[i] === '' ? undefined : line[i]);
    });
    result.push(obj);
  });
  return result;
};
const requiredString = (name) => {
  return `Please enter your ${name}.`;
};
const customMessages = {
  custom: {
    paymentField: {
      required: 'Please select a Payment file format to continue.',
    },
    emailRecipients: {
      required: () => 'Please enter at least one email to continue.',
      email: () => 'One or more Email recipients are incorrect.',
    },
    username: {
      required: requiredString('username'),
    },
    password: {
      required: requiredString('password'),
    },
    confirmPass: {
      required: requiredString('password confirmation'),
      confirmed: () => 'Passwords do not match.',
    },
    firstName: {
      required: requiredString('firstname'),
    },
    lastName: {
      required: requiredString('lastname'),
    },
    jobTitle: {
      required: requiredString('job title'),
    },
    email: {
      required: requiredString('email'),
    },
    confirmEmail: {
      required: requiredString('email confirmation'),
      confirmed: () => 'Emails do not match.',
    },
    phone: {
      required: requiredString('phone number'),
    },
    country: {
      required: requiredString('country'),
    },
    address1: {
      required: requiredString('address'),
    },
    city: {
      required: requiredString('city'),
    },
    state: {
      required: requiredString('state'),
    },
    postalCode: {
      required: requiredString('postal code'),
    },
    orgName: {
      required: requiredString('organization name'),
    },
    primaryBusiness: {
      required: requiredString('primary business'),
    },
    otherBusinessDescription: {
      required: requiredString('other business description'),
    },
  },
};

export {
  mapOrderByField,
  convertDateFromProtobuf,
  dateFormat,
  dateFormatSlash,
  dateFormatFull,
  ISODateFormat,
  pickNotEmpty,
  pickNotNil,
  csvJSON,
  customMessages,
};
