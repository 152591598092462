<template lang="pug">
.home
  .main-block-title
    span.main-block-title-text Dashboard for {{ customer.name }}
  el-table.home-table.with-shadow(:data='tableData')
    el-table-column(label='' prop='label' width='150')
    el-table-column(label='Bills Loaded', prop='bill_loaded')
      template(slot-scope='scope')
        span(data-type='bill_loaded', @click="navigate(scope)") {{ scope.row['bill_loaded'] || 0 }}
    el-table-column(label='Integrity Check', prop='integrity_check')
      template(slot-scope='scope')
        span(data-type='integrity_check', @click="navigate(scope)") {{ scope.row['integrity_check'] || 0 }}
    el-table-column(label='Data Verification I', prop='data_verification_1')
      template(slot-scope='scope')
        span(data-type='data_verification_1', @click="navigate(scope)") {{ scope.row['data_verification_1'] || 0 }}
    el-table-column(label='Data Verification II', prop='data_verification_2')
      template(slot-scope='scope')
        span(data-type='data_verification_2', @click="navigate(scope)") {{ scope.row['data_verification_2'] || 0 }}
    el-table-column(label='Data Audit I', prop='data_audit_1')
      template(slot-scope='scope')
        span(data-type='data_audit_1', @click="navigate(scope)") {{ scope.row['data_audit_1'] || 0 }}
    el-table-column(label='Data Audit II', prop='data_audit_2')
      template(slot-scope='scope')
        span(data-type='data_audit_2', @click="navigate(scope)") {{ scope.row['data_audit_2'] || 0 }}
    el-table-column(label='Processed', prop='processed')
      template(slot-scope='scope')
        span(data-type='processed', @click="navigate(scope)") {{ scope.row['processed'] || 0 }}
    el-table-column(label='Total', prop='total', width='120')
      template(slot-scope='scope')
        span(data-type='total', @click="navigate(scope)") {{ scope.row['total'] || 0 }}
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import qs from 'qs';
import * as R from 'ramda';

export default {
  data: function () {
    return {
      periods: [
        { label: 'Since Yesterday', period: 'yesterday' },
        { label: 'Last 7 Days', period: 'days7ago' },
        { label: 'Last 31 Days', period: 'days31ago' },
        { label: 'All-Time', period: 'all_time' },
      ],
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer,
    }),
    ...mapGetters({ billsData: 'home/billsData' }),
    tableData() {
      const converter = (value) =>
        R.reduce((acc, item) => {
          const state = R.prop('workflow_state', item);
          const qty = R.prop(value.period, item);
          const total = R.propOr(0, 'total', acc) + parseInt(qty);
          return state && qty ? { ...acc, ...{ [state]: qty, total } } : acc;
        }, value)(this.billsData || []);
      return R.map(converter)(this.periods);
    },
  },
  methods: {
    ...mapActions({
      fetchBillsData: 'home/fetchBillsData',
      clearBillsFilters: 'bills/clearFilters',
    }),
    ...mapMutations({
      clearDashboardData: 'home/clearState',
    }),
    navigate(scope) {
      const dateRows = [
        `loadedDateMin=${moment
          .tz(moment().add(-1, 'days'), 'UTC')
          .format()}&loadedDateMax=${moment.tz(moment(), 'UTC').format()}`,
        `loadedDateMin=${moment
          .tz(moment().add(-7, 'days'), 'UTC')
          .format()}&loadedDateMax=${moment.tz(moment(), 'UTC').format()}`,
        `loadedDateMin=${moment
          .tz(moment().add(-31, 'days'), 'UTC')
          .format()}&loadedDateMax=${moment.tz(moment(), 'UTC').format()}`,
        ``,
      ];

      this.clearBillsFilters();

      if (scope.$index >= 3 && scope.column.property === 'total') {
        return this.$router.push({
          path: `/customers/${this.$route.params.customerId}/bills`,
        });
      }

      const workflowStates =
        scope.column.property === 'total' ? null : [scope.column.property];

      const pathName = qs.stringify({
        workflowStates,
        ...qs.parse(dateRows[scope.$index]),
      });

      return this.$router.push({
        path: `/customers/${this.$route.params.customerId}/bills?${pathName}`,
      });
    },
  },
  mounted() {
    this.clearDashboardData();
    this.fetchBillsData(this.$route.params.customerId);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/constants.scss';

.main-block-title {
  padding-bottom: 0;
}
.home {
  padding: 20px 40px;
  height: calc(100vh - 60px);
  overflow: auto;

  .main-block-title {
    margin-bottom: 20px;
  }

  &-field {
    margin: 10px 0;
    color: #222222;
    &.value {
      color: #4a4a4a;
    }
  }
}
</style>
<style lang="scss">
.home-table {
  text-align: left;

  th {
    padding: 0;

    .cell {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      font-size: 14px;
      line-height: 14px;
      color: #909399;
    }
  }
  .cell {
    text-align: left;
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    color: #606266;

    span {
      cursor: pointer;
      font-size: 16px;
      line-height: 14px;
      color: #2893f9;
      text-decoration: underline;
      font-weight: 400;

      &:hover {
        color: #2684de;
        font-weight: 700;
      }
    }
  }
}
</style>
