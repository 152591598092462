<template lang="pug">
.match-modal(v-loading.loading='matching')
  .match-modal_content
    .match-modal_title Virtual Account: {{ exception.virtualAccountId }}
    .match-modal_info
      .info-item
        .info-item_label Vendor:
        .info-item_value {{ exception.vendorCode }}
      .info-item
        .info-item_label Service Address:
        .info-item_value {{ exception.serviceAddress }}
      .info-item
        .info-item_label Meter ID:
        .info-item_value {{ exception.meterSerial }}
      .info-item
        .info-item_label Service Zip:
        .info-item_value {{ exception.serviceZip || '' }}
      .info-item
        .info-item_label Service Account ID:
        .info-item_value {{ exception.accountCode }}
      .info-item
        .info-item_label  # Bill Blocks:
        .info-item_value(v-if="mapServiceAddress") N/A
        .info-item_value(v-else) {{ exception.itemsCount || '' }}

    .match-modal_data(v-loading.loading='loading')
      .match-modal_title(v-if='locations && locations.length') Matching Locations with Zip {{ exception.serviceZip || '' }}
      .match-modal_table(v-if='locations && locations.length')
        .match-modal_table-head
          .match-modal_table-row
            .match-modal_table-cell.address Location Address
            .match-modal_table-cell.description Location Description
            .match-modal_table-cell.zip Zip
            .match-modal_table-cell.state State
            .match-modal_table-cell.button &nbsp;
        .match-modal_table-body
          .match-modal_table-row(v-for='location in locations', :key='location.id')
            .match-modal_table-cell.address {{ location.address }}
            .match-modal_table-cell.description {{ location.name }}
            .match-modal_table-cell.zip {{ location.postcode }}
            .match-modal_table-cell.state {{ location.state }}
            .match-modal_table-cell.button
              el-radio.match-button(v-model="selected", :label='location') Map

      .match-modal_title Next closest Matches
      .match-modal_search
        el-input(v-model='query',
        size='medium',
        placeholder='Search location address',
        @keyup.native.enter='locationsSearch()',
        prefix-icon='el-icon-search')
          el-button.search-button(slot='append', size='medium', @click='locationsSearch()') Search
      div.search-results(v-if='shouldSearch') Search results
      div.search-results(v-else)
        .match-modal_table(v-if='!!foundLocations.length')
          .match-modal_table-head
            .match-modal_table-row
              .match-modal_table-cell.address Location Address
              .match-modal_table-cell.description Location Description
              .match-modal_table-cell.zip Zip
              .match-modal_table-cell.state State
              .match-modal_table-cell.button &nbsp;
          .match-modal_table-body
            .match-modal_table-row(v-for='location in foundLocations', :key='location.id')
              .match-modal_table-cell.address {{ location.address }}
              .match-modal_table-cell.description {{ location.name }}
              .match-modal_table-cell.zip {{ location.postcode }}
              .match-modal_table-cell.state {{ location.state }}
              .match-modal_table-cell.button
                el-radio(v-model="selected", :label='location') Map
        div.search-results(v-else) No search results
      .match-modal_map-all
        el-checkbox(v-model="mapServiceAddress")
          span Map all virtual accounts with this Service Address
  .match-modal_actions
    el-button.plain-button-reverse(@click="cancel()", plain) Cancel
    el-button(v-if="mapServiceAddress" type="primary", @click="match()", :disabled='isButtonDisabled') Update
    el-button(v-else type="primary", @click="match()", :disabled='isButtonDisabled') Update {{ exception.itemsCount }} Bill Block{{ exception.itemsCount > 1 ? 's' : '' }}
</template>

<script>
import { exceptions as exceptionsApi } from '@/api';
import * as R from 'ramda';

export default {
  data() {
    return {
      query: '',
      selected: {},
      foundLocations: null,
      mapServiceAddress: false,
    };
  },
  computed: {
    isButtonDisabled() {
      return R.isEmpty(this.selected);
    },
    shouldSearch() {
      return !!R.isNil(this.foundLocations);
    },
  },
  props: {
    exception: {
      type: Object,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    matching: {
      type: Boolean,
    },
    isModalOpened: {
      type: Boolean,
    },
  },
  watch: {
    isModalOpened: function (newProp) {
      if (newProp) {
        this.query = '';
        this.foundLocations = [];
        this.mapServiceAddress = false;
      }
    },
  },
  methods: {
    match() {
      this.$emit('match', {
        location: { ...this.selected },
        mapServiceAddress: this.mapServiceAddress,
      });
      this.selected = {};
    },
    cancel() {
      this.$emit('cancel');
      this.selected = {};
    },

    locationsSearch(query = this.query) {
      exceptionsApi
        .searchLocation(this.$route.params.customerId, query)
        .then((data) => {
          this.foundLocations =
            typeof data !== 'undefined' && data.data ? [...data.data] : [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../../../styles/constants';

.match-modal {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #273a58;
  &_title {
    color: #273a58;
    font-weight: 600;
  }
  &_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 12px;
    line-height: 1;
    border-bottom: 1px solid #ebeef5;
  }
  .info-item {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 22px;
    margin-left: 20px;
    &_label {
      margin: 0 10px 0 0;
      min-width: 95px;
    }
    &_value {
      font-weight: 700;
    }
  }
  &_data {
    // max-height: 370px;
    // overflow-y: scroll;

    .match-modal_title {
      margin: 10px 0;
    }
  }
  &_table {
    font-size: 12px;
    line-height: 1;
    font-weight: 600;

    &-head {
      position: relative;
      font-weight: 400;
      box-shadow: $primary-shadow;
      z-index: 1;

      .match-modal_table-row {
        margin-bottom: 0;
      }
      .match-modal_table-cell {
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }
    &-body {
      max-height: 154px;
      overflow-y: auto;
      box-shadow: $primary-shadow;

      @extend %scrollbar;
    }
    &-row {
      display: flex;
      border-bottom: 1px solid #ebeef5;
    }
    &-cell {
      display: flex;
      align-items: center;
      padding: 7px 0;
    }
  }
  &_map-all {
    margin: 10px 0;
    text-align: right;
  }
  &_actions {
    display: flex;
    padding: 10px 0 30px;
    justify-content: center;

    .el-button {
      width: 170px;
      padding-top: 7px;
      padding-bottom: 7px;

      + .el-ubtton {
        margin-left: 40px;
      }
    }
  }
}
.address {
  width: 300px;
  padding-left: 20px;
}
.description {
  width: 200px;
  padding-left: 20px;
}
.zip {
  width: 168px;
  padding-left: 20px;
}
.state {
  width: 88px;
  padding-left: 20px;
}
.button {
  display: flex;
  justify-content: flex-end;
  width: 100px;
  padding-right: 15px;

  .match-button {
    color: inherit;
  }
}
</style>

<style lang="scss">
@import '../../../../styles/constants.scss';

.match-modal_search {
  margin-bottom: 15px;
  box-shadow: $primary-shadow;

  .el-input-group__append:hover {
    background-color: #2684de;
  }
  .el-input__inner {
    border: 0;
  }
  .el-input-group__append {
    padding: 0 36px;
    color: #fff;
    border: 0;
    background-color: $primary;
  }
}
</style>
