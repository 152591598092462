<template>
  <!--  Extended in Vue3, 'this' object gets forwarded to Vue3-nav for display and navigation purposes  -->
  <main-layout>
    <router-view></router-view>
  </main-layout>
</template>

<script>
import { Main } from '@/components/layouts';

export default {
  components: {
    mainLayout: Main,
  },
};
</script>
