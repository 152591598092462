<template lang="pug">
  .page.page-reports
    .page-header
      h1.page-title Reports
    .page-content
      el-tabs(v-model="activeTab")
        el-tab-pane(label='Reports List', name='list', data-action='show-list')
          list-tab(v-if="activeTab === 'list'")
        el-tab-pane(label='Create Report', name='create', data-action='create-report')
          create-tab(v-if="activeTab === 'create'", :handleCreateReport="createReport", @showList="showList")
</template>

<script>
import { Main } from '@/components/layouts';
import ListTab from './Tabs/List/View';
import CreateTab from './Tabs/Create/View';
import * as R from 'ramda';
import { createCustomerReport } from '../../../api/reports';

export default {
  components: {
    mainLayout: Main,
    CreateTab,
    ListTab,
  },
  beforeMount() {
    this.activeTab = R.pathOr('list', ['tab'], this.$route.params);
  },
  data() {
    return {
      activeTab: 'list',
    };
  },
  watch: {
    activeTab(tab) {
      this.$router.push({ name: 'customer.exports.tab', params: { tab } });
    },
    $route(to, from) {
      this.activeTab = R.pathOr('list', ['tab'], this.$route.params);
    },
  },
  methods: {
    async createReport(params) {
      try {
        const { customerId } = this.$route.params;
        await createCustomerReport(customerId, params);
      } catch (e) {
        console.error(e);
      }
    },
    showList() {
      this.activeTab = 'list';
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 80px);
  padding: 20px 40px 60px 40px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
  }
}

/deep/ {
  .el-tabs__item {
    &:hover {
      color: #273a58;
    }

    &.is-active {
      font-weight: 900;
      color: #273a58;
    }
  }

  .el-tabs__active-bar {
    background-color: #273a58;
  }
}

.page-content {
  /deep/ {
    .el-tabs__content {
      min-height: 300px;
      overflow: visible;
    }
  }
}
</style>
