var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",staticClass:"change-password__container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"change-password__title"},[_vm._v("Change password for "+_vm._s(_vm.user.email))]),_c('div',{staticClass:"change-password__content"},[_c('form',[_c('input',{staticClass:"saveUser",attrs:{"name":"username","autocomplete":"username","id":"username","type":"text"},domProps:{"value":_vm.user.email}}),_c('validation-provider',{ref:"old_password",staticClass:"input__container",attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var flags = ref.flags;
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('label',[_vm._v("Current password:")]),_c('div',{staticClass:"input__wrapper"},[_c('el-input',{class:{ "has-error": flags.invalid && flags.touched},attrs:{"type":"password","size":"small","autocomplete":"current-password","placeholder":"**********","data-testid":"currentPasswordIput"},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}}),(errors[0] && _vm.isEmpty(failedRules))?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('validation-provider',{ref:"password",staticClass:"input__container",attrs:{"rules":{required: true, min: 8, max: 31, hasDigit: true, hasUpperCaseLetter: true, noSpaces: true },"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var flags = ref.flags;
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('label',[_vm._v("New password:")]),_c('div',{staticClass:"input__wrapper"},[_c('el-input',{class:{ "has-error": flags.invalid && flags.touched},attrs:{"type":"password","autocomplete":"new-password","size":"small","placeholder":"********","data-testid":"newPasswordInput"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(failedRules.hasDigit || failedRules.min || failedRules.max || failedRules.hasUpperCaseLetter)?_c('div',{staticClass:"error"},[_vm._v("Must contain 8 or more characters including one uppercase letter and one number.")]):(failedRules.noSpaces)?_c('div',{staticClass:"error"},[_vm._v("Spaces are not allowed.")]):(flags.invalid && _vm.isEmpty(failedRules))?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('validation-provider',{staticClass:"input__container",attrs:{"rules":{required: true, is: _vm.password},"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var flags = ref.flags;
var failedRules = ref.failedRules;
return [_c('label',[_vm._v("New password again:")]),_c('div',{staticClass:"input__wrapper"},[_c('el-input',{class:{ "has-error": flags.invalid && flags.touched},attrs:{"autocomplete":"new-password","type":"password","size":"small","placeholder":"********","data-testid":"newPasswordAgainInput"},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),(flags.invalid && flags.touched)?_c('div',{staticClass:"error"},[_vm._v("Passwords do not match.")]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"change-password__actions"},[_c('el-button',{staticClass:"plain-button-reverse",attrs:{"size":"small","data-testid":"cancelPassChangeBtn"},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary","size":"small","disabled":invalid,"data-testid":"confirmPassChangeBtn"},on:{"click":_vm.confirm}},[_vm._v("Change password")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }