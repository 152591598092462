<template>
  <div class="wrapper">
    <div class="filters">
      <div class="filter">
        <div class="filter-title">Report Type</div>
        <div class="filter-input">
          <el-select
            class="report-type-select"
            v-model="filters.type"
            size="small"
            :multiple="true"
            data-action="select-report-type"
            popper-class="select-report-type_dropdown"
            filterable
          >
            <el-option
              v-for="option in availableFilters.types"
              :key="option.type"
              :label="option.name"
              :value="option.type"
              :data-element="option.type"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="filter">
        <div class="filter-title">Format</div>
        <el-select
          v-model="filters.format"
          size="small"
          data-action="select-format-type"
          multiple
          popper-class="select-format-type_dropdown"
          filterable
        >
          <el-option
            v-for="option in availableFilters.formats"
            :key="option"
            :label="option.toUpperCase()"
            :value="option"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter">
        <div class="filter-title">Creation Date</div>
        <div class="filter-input">
          <el-date-picker
            v-model="period"
            size="small"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            format="yyyy-MM-dd"
            @change="changePeriod"
            end-placeholder="End date"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="content">
      <el-table
        class="reports-table"
        :data="reports"
        ref="reportsTable"
        @sort-change="sortTable"
        v-loading.body.lock="isLoading"
        :empty-text="noReportsMessage"
        data-element="reports-list"
        :cell-style="() => 'border-bottom: 1px solid #ebeef5 !important;'"
      >
        <el-table-column label="Report Type" prop="type" sortable="sortable">
          <template slot-scope="scope"
            ><span>{{ scope.row.reportTemplate.title }}</span></template
          >
        </el-table-column>
        <el-table-column
          label="Format"
          prop="format"
          width="120"
          class-name="report-format"
          sortable="sortable"
        ></el-table-column>
        <el-table-column label="Date range">
          <template slot-scope="scope"
            ><span>{{
              setDateRange(scope.row.startDate, scope.row.endDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Creation Date"
          prop="created_at"
          sortable="sortable"
        >
          <template slot-scope="scope"
            ><span>{{
              scope.row.createdAt | datetime('LLLL d y t ZZZZ')
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          label="Status"
          prop="status"
          width="150"
          sortable="sortable"
        >
          <template slot-scope="scope">
            <a
              class="status-done"
              v-if="scope.row.status === 'Done'"
              :href="scope.row.url"
              target="_blank"
              data-element="report-link"
              >Download</a
            >
            <span
              v-else
              :title="scope.row.status === 'Error' ? scope.row.reason : ''"
              :class="`status-${scope.row.status.toLowerCase()}`"
              >{{ scope.row.status }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pagination-container"
      background="background"
      v-if="total > page_sizes[0]"
      layout="prev, pager, next, sizes"
      :page-size="filters.limit"
      :page-sizes="page_sizes"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
      :total="total"
      :disabled="isLoading"
    >
    </el-pagination>
  </div>
</template>

<script>
import * as R from 'ramda';
import { serializeOrderBy } from '@/lib/helpers';
import { DateTime } from 'luxon';
import { getCustomerReports } from '../../../../../api/reports';
import _ from 'lodash';

export default {
  data() {
    return {
      reports: [],
      period: {},
      noReportsMessage: '',
      isLoading: false,
      hasProcessingReports: false,
      checker: null,
      formats: [
        {
          label: 'CSV',
          value: 'csv',
        },
      ],
      filters: {
        type: [],
        format: '',
        orders: ['createdAt.desc'],
        limit: 20,
        offset: 0,
      },
      page_sizes: [10, 20, 30, 40, 50, 100],
      total: 0,
      availableFilters: {},
    };
  },
  beforeMount() {
    this.loadReports();
  },
  beforeDestroy() {
    if (this.checker) {
      clearInterval(this.checker);
    }
  },
  computed: {
    currentPage() {
      return this.filters.offset / this.filters.limit + 1;
    },
  },
  watch: {
    reports() {
      if (
        (!R.isNil(this.filters.start_date) ||
          !R.isNil(this.filters.end_date)) &&
        !this.total
      ) {
        this.noReportsMessage = 'No data found';
      } else {
        this.noReportsMessage =
          'You have no reports. Click "Create Report" to create a new one.';
      }
    },
    hasProcessingReports(value) {
      if (value) {
        this.checkProcessingReports();
      }
    },
    'filters.format'() {
      this.onFilterChange();
    },
    'filters.type'() {
      this.onFilterChange();
    },
  },
  methods: {
    async loadReports() {
      const { customerId } = this.$route.params;
      const params = R.pickBy(R.compose(R.not, R.isEmpty))(this.filters);
      if (this.reports.length > 0) {
        params.exclude = ['filters'];
      }
      const { results, total, filters } = await getCustomerReports(
        customerId,
        params,
      );
      this.total = total;
      this.reports = results;
      if (_.isEmpty(this.availableFilters)) {
        this.availableFilters = filters;
      }
      this.hasProcessingReports = R.any(
        (v) => v.status.toLowerCase() === 'processing',
      )(results);
      this.clearChecker();
    },
    changePeriod(period) {
      if (R.isNil(period)) {
        delete this.filters.startDate;
        delete this.filters.endDate;
      } else {
        const start_date = DateTime.fromJSDate(period[0])
          .setZone('UTC', { keepLocalTime: true })
          .toString();
        const end_date = DateTime.fromJSDate(period[1])
          .setZone('UTC', { keepLocalTime: true })
          .endOf('day')
          .toString();
        this.filters = { ...this.filters, start_date, end_date };
      }
      this.onFilterChange();
    },
    changeLimit(limit) {
      this.filters.limit = limit;
      this.loadReports();
    },
    changeOffset(page) {
      this.filters.offset = (page - 1) * this.filters.limit;
      this.loadReports();
    },
    sortTable({ prop, order }) {
      const { path, query } = this.$route;

      if (R.isNil(prop) || R.isNil(order)) {
        this.$router.push({
          path: path,
          query: {
            ...query,
            orders: [],
          },
        });
      } else {
        this.$router.push({
          path: path,
          query: {
            ...query,
            orders: serializeOrderBy(prop, order),
          },
        });
      }
    },
    setDateRange(start, end) {
      if (start && end) {
        const startMonth = DateTime.fromISO(start).setZone('UTC');
        const endMonth = DateTime.fromISO(end).setZone('UTC');
        if (startMonth.toFormat('LLL y') === endMonth.toFormat('LLL y')) {
          return startMonth.toFormat('LLL y');
        } else {
          return `${this.$options.filters.datetime(
            start,
            'LLL y',
          )} - ${this.$options.filters.datetime(end, 'LLL y')}`;
        }
      }

      return 'All time';
    },
    checkProcessingReports() {
      if (R.isNil(this.checker)) {
        this.checker = setInterval(() => {
          this.loadReports();
          this.clearChecker();
        }, 5000);
      }
    },
    clearChecker() {
      if (!this.hasProcessingReports) {
        clearInterval(this.checker);
        this.checker = null;
      }
    },
    onFilterChange() {
      this.filters.offset = 0;
      this.loadReports();
    },
  },
};
</script>

<style lang="scss">
.el-scrollbar__wrap {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped>
.filters {
  margin: 0 0 10px 0;
  padding: 20px 24px 24px 24px;
  font-size: 14px;
  border-radius: 8px;
  background-color: #fdfdfd;
  box-shadow: 0 2px 5px 0 rgba(204, 204, 204, 0.5);
  display: flex;
}

.filter {
  padding: 0 25px 0 0;

  &-title {
    color: #606266;
    margin: 0 0 10px 0;
  }

  /deep/ .el-date-editor--daterange.el-input__inner {
    max-width: 285px;
  }
}

.status-done {
  color: #0080ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.content {
  margin-bottom: 30px;
}

/deep/ {
  .el-pagination {
    display: flex;
  }

  .el-pagination__sizes {
    margin-left: auto;
  }
}
</style>
