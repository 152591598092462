export default {
  namespaced: true,
  state: {
    insights: [],
  },

  getters: {
    list: (state) => state.insights,
  },

  mutations: {
    setInsights(state, insights) {
      state.insights = insights;
    },
  },

  actions: {
    setInsights({ commit }, insights) {
      return commit('setInsights', insights);
    },
  },
};
