<template>
  <el-dialog
    class="modal-upload-dialog"
    :title="title"
    width="746px"
    :visible.sync="showUploadModal"
    @open="open"
    @close="close"
  >
    <div class="modal-upload-wrapper" data-element="upload-locations-csv-form">
      <slot name="modal-description">
        <p>
          Upload a CSV file containing locations for <b>{{ customerName }}</b
          >. It is important that the CSV Location file conform to the naming
          conventions specified to prevent errors from occurring.
        </p>
      </slot>
      <form>
        <div class="input-wrapper">
          <label>{{ labelText }}:</label>
          <div class="input-container">
            <div class="input-field" :class="{ 'has-error': uploadError }">
              {{ (file.length && file) || `Please, browse your CSV file...` }}
            </div>
            <input
              type="file"
              @click="handleClickOnInput"
              @change="handleFileChange"
              ref="file-input"
              data-element="upload-locations-input"
            />
            <button @click="browse">Browse</button>
          </div>
          <div class="error-container" v-if="uploadError">
            <i class="icon icon-exclamation-mark"></i>
            <div v-html="errorMessage"></div>
          </div>
        </div>
        <el-button
          class="submit-button"
          @click="submit"
          :loading="isValidateFetching"
          :disabled="!file.length"
          data-action="upload-locations-form-submit"
          >{{ buttonText }}</el-button
        >
      </form>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { locations } from '@/api';
import Papa from 'papaparse';
import { validateLocationsCsv } from '../../../../api/locations';

const captureFileBytes = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve([...new Uint8Array(this.result)]);
    };
    reader.readAsArrayBuffer(data);
  });
const extractErrMsg = (e) => {
  try {
    if (typeof e.response.data === 'object') {
      const msg = JSON.parse(_.get(e, 'response.data.message', '{}'));
      // Specific validation errors or missing data, will specify column with error
      let errorMsg = '';
      if (typeof msg === 'object' && Object.keys(msg).length) {
        Object.keys(msg).forEach((key, line) => {
          if (line > 0) {
            errorMsg += '<br/>';
          }
          errorMsg += `${key}: ${msg[key]}`;
        });
      }
      return errorMsg;
    }
  } catch (er) {
    // UTF-16, missing commas or other csv validation errors
    if (e.response.data.message === 'Invalid csv syntax') {
      return 'Invalid csv syntax. Please make sure csv file is valid and saved in UTF-8 format.';
    }
  }
  // Other unexpected errors
  return 'Unknown CSV error, please check your file contents and retry';
};
const locationHeaders = [
  'Description',
  'Number',
  'Service Address',
  'City',
  'State',
  'Postcode',
  'Country',
  'Division',
  'Location Type',
  'Square Feet',
  'Status',
  'Latitude',
  'Longitude',
  'Phone',
  'Fax',
  'Weather',
];
const locationHeadersToDelete = [
  'Location Type',
  'Service Address',
  'Division',
  'Fax',
  'Number',
  'Phone',
  'Status',
  'Weather',
  'Description',
];

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: 'CSV File',
    },
    buttonText: {
      type: String,
      default: 'Submit File',
    },
    confirmUploadError: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    fileType: {
      type: String,
      default: 'locations',
    },
  },
  watch: {
    visible(val) {
      this.showUploadModal = val;
    },
    confirmUploadError(val) {
      if (val) {
        this.uploadError = true;
        this.errorMessage = this.errorMsg;
      }
    },
  },
  computed: {
    ...mapGetters({
      customerName: 'customers/name',
      getResourceByName: 'resources/getResourceByName',
    }),
  },
  data() {
    return {
      showUploadModal: false,
      file: '',
      errorMessage: '',
      fileData: [],
      locationsMetadata: [],
      result: [],
      isValidateFetching: false,
      uploadError: false,
      validations: {},
      buildingTypes: ['BUILDING', 'CAMPUS', 'STOREFRONT'],
    };
  },
  methods: {
    async open() {
      const { data } = await locations.getMetaData(
        this.$route.params.customerId,
      );
      this.locationsMetadata = data;
    },
    handleClickOnInput(e) {
      e.target.value = '';
    },
    handleFileChange(e) {
      this.file = e.target.value.slice(e.target.value.lastIndexOf('\\') + 1);
      this.fileData = _.get(e, 'target.files.0', '');
      this.uploadError = false;
      this.errorMessage = '';
    },
    browse(e) {
      e.preventDefault();
      this.$refs['file-input'].click();
    },
    async submit(e) {
      e.preventDefault();
      if (this.fileType === 'locations') {
        await this.handleLocationsData();
      }
      if (this.fileType === 'attributes') {
        await this.handleAttributesData();
      }
    },
    async handleAttributesData() {
      try {
        this.isValidateFetching = true;
        const data = await captureFileBytes(this.fileData);
        await locations.uploadAttributesCsv(this.$route.params.customerId, {
          data,
        });
        this.$emit('success');
        this.close();
      } catch (e) {
        this.$emit('error');
        this.uploadError = true;
        const code = _.get(e, 'response.data.code', 500);
        this.errorMessage =
          code === 406
            ? extractErrMsg(e)
            : 'it cannot process the CSV file, pls check your file or retry';
      } finally {
        this.isValidateFetching = false;
      }
    },
    csvValidations(locations) {
      const nameAndAddressValidation = /^[\w\s(),.'"_\-&#/]*$/;
      this.uploadError = false;
      const errorMessages = [];
      locations.forEach((location) => {
        if (!location.name) {
          errorMessages.push(
            `The description of address ${
              location.name || ''
            } is required.<br/>`,
          );
        }

        if (!nameAndAddressValidation.test(location.name)) {
          errorMessages.push(
            `${location.name} description field is not valid.<br/>`,
          );
        }

        if (!location.address) {
          errorMessages.push(
            `The address field of ${
              location.name || ''
            } description is required.<br/>`,
          );
        }

        if (!nameAndAddressValidation.test(location.address)) {
          errorMessages.push(
            `${location.name} description field is not valid.<br/>`,
          );
        }

        if (location.country !== 'US') {
          errorMessages.push(
            `${location.name} country field is non-US or is not a valid US country.<br/>`,
          );
        }

        if (!/^\d{3,5}(-\d{4})?$/.test(location.postcode)) {
          errorMessages.push(
            `${location.name} country field is not a valid US postcode.<br/>`,
          );
        }

        if (!this.buildingTypes.includes(location.buildingType)) {
          errorMessages.push(
            `${
              location.name
            } Building Type is not valid. The supported values are: ${this.buildingTypes.join(
              ', ',
            )}.<br/>`,
          );
        }

        if (errorMessages.length > 0) {
          this.uploadError = true;
          this.errorMessage = errorMessages.join('');
        }
      });
    },
    filteredLocation(location, property) {
      if (location[property] === 'N/A' || location[property] === '-') {
        return undefined;
      }
      if (location[property]) {
        return location[property];
      }
      return undefined;
    },
    changeStatesValue(location) {
      const states = this.getResourceByName('states');
      Object.keys(states.value).reduce((accumulator, key) => {
        if (states.value[key].abbreviation === location['State']) {
          location['State'] = key;
        }
      }, '');
    },
    deleteCustomAttributes(customAttributes, location, mappedLocation) {
      customAttributes.forEach((customAttr) => {
        const foundLocation = this.locationsMetadata.find(
          (locationMeta) => locationMeta.attribute_name === customAttr,
        );

        if (_.isEmpty(foundLocation)) {
          this.uploadError = true;
          this.errorMessage = `Unknown attribute ${customAttr}`;
        }
        mappedLocation.payload[foundLocation.id] = location[customAttr]
          ? location[customAttr]
          : undefined;
        delete location[customAttr];
      });
      locationHeadersToDelete.forEach(
        (attributeToDelete) => delete location[attributeToDelete],
      );
    },
    async getAndParseCsv() {
      const data = await captureFileBytes(this.fileData);
      const uint8ArrayLocationData = new Uint8Array(data);
      const fileData = uint8ArrayLocationData.reduce(
        (acc, i) => (acc += String.fromCharCode.apply(null, [i])),
        '',
      );
      const parsedFileData = Papa.parse(fileData, {
        header: true,
        trimHeaders: true,
        skipEmptyLines: true,
      });
      const customAttributes = _.difference(
        Object.keys(parsedFileData.data[0]),
        locationHeaders,
      );
      return parsedFileData.data.map((location) => {
        this.changeStatesValue(location);
        const mappedLocation = {
          name: this.filteredLocation(location, 'Description'),
          address: this.filteredLocation(location, 'Service Address'),
          squareFeet: +this.filteredLocation(location, 'Square Feet'),
          buildingType: this.filteredLocation(
            location,
            'Location Type',
          ).toUpperCase(),
          weatherActive: location['Weather'] === 'Enabled',
          latitude: this.filteredLocation(location, 'Latitude'),
          longitude: this.filteredLocation(location, 'Longitude'),
          payload: {
            siteDivision: this.filteredLocation(location, 'Division'),
            siteFax: this.filteredLocation(location, 'Fax'),
            siteNumber: this.filteredLocation(location, 'Number'),
            sitePhone: this.filteredLocation(location, 'Phone'),
            siteStatus: this.filteredLocation(location, 'Status'),
          },
        };
        this.deleteCustomAttributes(customAttributes, location, mappedLocation);
        return {
          ..._.mapKeys(location, (value, key) => _.camelCase(key)),
          ...mappedLocation,
        };
      });
    },
    async handleLocationsData() {
      const parsedCsv = await this.getAndParseCsv();
      this.csvValidations(parsedCsv);
      try {
        this.isValidateFetching = true;
        if (!this.uploadError) {
          this.validations = await validateLocationsCsv(
            this.$route.params.customerId,
            { locations: parsedCsv },
          );
          this.$emit('success', {
            validations: this.validations,
            data: parsedCsv,
          });
          this.close();
        }
      } catch (e) {
        this.$emit('error');
        this.uploadError = true;
        this.errorMessage = e.response.data.error;
      } finally {
        this.isValidateFetching = false;
      }
    },
    close() {
      this.uploadError = false;
      this.errorMessage = '';
      this.result = [];
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  label {
    color: #222222;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
  }
  .input-container {
    margin-top: 11px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    input {
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 294px;
      top: 0;
      left: 0;
    }

    .input-field {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-sizing: border-box;
      height: 30px;
      width: 294px;
      border: 1px solid #d8dce6;
      border-radius: 4px 0 0 4px;
      background-color: #ffffff;
      color: #acacac;
      font-size: 12px;
      line-height: 30px;
      text-align: left;
      text-indent: 10px;
      border-right: none;

      &.has-error {
        border: 1px solid #f86259;
      }
    }

    button {
      height: 30px;
      width: 81px;
      border-radius: 0 4px 4px 0;
      background-color: #2487e3;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      border: none;
      line-height: 17px;
    }
  }

  .error-container {
    color: #f86259;
    margin-top: 11px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
