import * as api from '../../api/index';
import axios from 'axios';

const initialState = () => {
  return {
    props: {},
  };
};

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    props: (state) => state.props,
  },

  mutations: {
    setProps(state, payload) {
      state.props = payload;
      state.props.vue2Api = api;
      state.props.axios = axios;
      state.props.vue2Plugins = {
        elementUI: {
          plugins: {
            message: this._vm.$message,
          },
        },
      };
    },
    resetState: (state) => {
      const initState = initialState();
      Object.assign(state, initState);
    },
  },

  actions: {
    callStoreMethod({ state }, payload) {
      if (payload.methodName === 'getter') {
        return this.getters[payload.moduleName];
      }
      if (payload.methodName === 'dispatch') {
        return this.dispatch(payload.moduleName, payload.data);
      }
      if (payload.methodName === 'mutate') {
        return this.commit(payload.moduleName, payload.data);
      }
      if (payload.methodName === 'state') {
        const paths = payload.moduleName.split('.');
        const result = paths.reduce((reduced, path) => {
          return reduced ? reduced[path] : this.state[path];
        }, null);
        return result;
      }
    },
  },
};
