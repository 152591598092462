import { prop, path, propOr } from 'ramda';
import filters from './bills.filters';
import { bills } from '@/api';

const defaultBillState = () => ({
  errors: [],
  files: [],
  comments: {},
});

const defaultFiltersState = () => ({
  query: '',
  bill_types: [],
  error_types: [],
  commodities: [],
  workflow_states: [],
  location_ids: [],
  vendor_codes: [],
  amount_due_min: null,
  amount_due_max: null,
  loaded_date_min: null,
  loaded_date_max: null,
  statement_date_min: null,
  statement_date_max: null,
});
const defaultPaginationState = () => ({
  limit: 20,
  offset: 0,
});

const defaultOrdersState = () => ['-due_date', '-statement_date'];

export default {
  namespaced: true,
  modules: { _filters: filters },
  state: {
    currentBillExchangeRateObject: 'N/A',
    history: [],
    bill: defaultBillState(),
    myBills: [],
    list: [],
    filters: defaultFiltersState(),
    pagination: defaultPaginationState(),
    orders: defaultOrdersState(),
    priority: { high: 500, normal: 100 },
    dontReload: false,
    billOrVAFrozen: false,
    reasonFrozenBill: null,
  },

  getters: {
    currentBillExchangeRateObject: (state) =>
      state.currentBillExchangeRateObject,
    id: (state) => prop('id', state.bill),
    comments: (state) => state.bill.comments,
    total_comments: (state) => state.bill.comments.total,
    bill: (state) => state.bill,
    resolvedErrors: (state) => state.bill.resolvedErrors,
    filters: (state) => state.filters,
    priority: (state) => state.priority,
    dontReload: (state) => state.dontReload,
    history: (state) => state.history,
    isParserV1: (state) =>
      path(['versions', 'workflowStates'], state.bill) === 1,
    billOrVAFrozen: (state) => state.billOrVAFrozen,
    reasonFrozenBill: (state) => state.reasonFrozenBill,
  },

  mutations: {
    setList(state, data) {
      state.list = data;
    },
    setHistory(state, data) {
      state.history = data;
    },
    setCurrentBill(state, bill) {
      state.bill = { ...bill, comments: bill.comments || { data: [] } };
    },
    setTotalComments(state, total_comments) {
      state.bill.comments = { ...state.bill.comments, total: total_comments };
    },
    loadMoreComments(state, comments) {
      state.bill.comments.data = [
        ...(state.bill.comments.data || []),
        ...(comments || []),
      ];
    },
    addComment(state, comment) {
      state.bill.comments.data = [comment, ...state.bill.comments.data];
      state.bill.comments.total++;
    },
    setLimit(state, limit) {
      state.pagination.limit = limit;
    },
    setOffset(state, offset) {
      state.pagination.offset = offset;
    },
    setCommodities(state, commodities) {
      state.filters.commodities = [...commodities];
    },
    setBillTypes(state, bill_types) {
      state.filters.bill_types = [...bill_types];
    },
    setErrorTypes(state, error_types) {
      state.filters.error_types = [...error_types];
    },
    setLocations(state, locations) {
      state.filters.location_ids = [...locations];
    },
    setVendorCodes(state, vendor_codes) {
      state.filters.vendor_codes = [...vendor_codes];
    },
    setStatuses(state, statuses) {
      state.filters.workflow_states = [...statuses];
    },
    setQuery(state, query) {
      state.filters.query = query;
    },

    setOrder(state, order) {
      state.orders = Array.isArray(order) ? [...order] : [order];
    },
    setAmountDue(state, charges) {
      state.filters.amount_due_min = propOr(
        state.filters.amount_due_min,
        'min',
        charges,
      );
      state.filters.amount_due_max = propOr(
        state.filters.amount_due_max,
        'max',
        charges,
      );
    },
    setStatementDates(state, dates) {
      state.filters.statement_date_min = prop('min', dates);
      state.filters.statement_date_max = prop('max', dates);
    },
    setLoadedDates(state, dates) {
      state.filters.loaded_date_min = prop('min', dates);
      state.filters.loaded_date_max = prop('max', dates);
    },
    setMyBills(state, bills) {
      state.myBills = bills || [];
    },
    clearFilters(state) {
      state.filters = defaultFiltersState();
      state.pagination = defaultPaginationState();
      state.orders = defaultOrdersState();
    },
    clearBill(state) {
      state.bill = defaultBillState();
    },
    setDontReload(state, flag) {
      state.dontReload = flag;
    },
    setCurrentBillExchangeRateObject(state, data) {
      state.currentBillExchangeRateObject = data;
    },
    setBillOrVAFrozen(state, isFrozen) {
      state.billOrVAFrozen = isFrozen;
    },
    setReasonFrozenBill(state, reason) {
      state.reasonFrozenBill = reason;
    },
  },

  actions: {
    async getExchangeRates({ commit }, { customerId, billId }) {
      return Promise.resolve(
        commit(
          'setCurrentBillExchangeRateObject',
          await bills.getExchangeRates(customerId, billId),
        ),
      );
    },
    loadBillPath({ commit }, { path, router }) {
      commit('setDontReload', false);
      return Promise.resolve(router.push(path));
    },
    setBill({ commit }, bill) {
      return Promise.resolve(commit('setCurrentBill', bill));
    },
    setTotalComments({ commit }, count) {
      return Promise.resolve(commit('setTotalComments', count));
    },
    loadMoreComments({ commit }, comments) {
      return Promise.resolve(commit('loadMoreComments', comments));
    },
    addComment({ commit }, comment) {
      return Promise.resolve(commit('addComment', comment));
    },
    async setBillTypes({ commit }, bill_types) {
      return commit('setBillTypes', bill_types || []);
    },
    async setLocations({ commit }, locations) {
      return commit('setLocations', locations || []);
    },
    async setVendorCodes({ commit }, vendor_codes) {
      return commit('setVendorCodes', vendor_codes || []);
    },
    async setErrorTypes({ commit }, error_types) {
      return commit('setErrorTypes', error_types || []);
    },
    async setStatuses({ commit }, statuses) {
      return commit('setStatuses', statuses || []);
    },
    async setPagination({ commit }, { limit, offset }) {
      await commit('setLimit', limit);
      await commit('setOffset', offset);
    },
    async setLimit({ commit }, limit) {
      return commit('setLimit', limit);
    },
    async setOffset({ commit }, offset) {
      return commit('setOffset', offset);
    },
    setCommodities: async ({ commit }, commodities) =>
      commit('setCommodities', commodities),

    setOrder: async ({ commit }, order) => commit('setOrder', order),
    setAmountDue: async ({ commit }, charges) =>
      commit('setAmountDue', charges),
    setStatementDates: async ({ commit }, dates) =>
      commit('setStatementDates', dates),
    setLoadedDates: async ({ commit }, dates) =>
      commit('setLoadedDates', dates),
    clearFilters: async ({ commit }) => commit('clearFilters'),

    async putBill({ dispatch }, { customerId, billId, body, comment = '' }) {
      await bills.put(customerId, billId, { data: body, comment });
    },
    async putBillV3({ dispatch }, { customerId, billId, body = '' }) {
      await bills.putV3(customerId, billId, body);
    },
    listHistory: async ({ commit }, { customerId, billId }) => {
      try {
        const { data = [] } = await bills.listHistory(customerId, billId);
        commit('setHistory', data);
      } catch (e) {
        console.error(e);
      }
    },
    list: async ({ commit }, { customerId, params }) => {
      try {
        const { data = [] } = await bills.list(customerId, params);
        commit('setList', data);
      } catch (e) {
        console.error(e);
      }
    },
    async setBillIsFrozen({ commit }, bill) {
      commit('setReasonFrozenBill', null);
      let isFrozen = false;
      let reason = bill.items[0].vaFrozenReason;
      bill.items.forEach((item) => {
        if (item.vaFrozenAt !== null && item.vaFrozenReason !== null) {
          isFrozen = true;
        }
        if (item.vaFrozenReason !== reason) {
          reason = null;
        }
      });
      if (reason !== null && reason !== undefined && isFrozen) {
        commit('setReasonFrozenBill', reason);
      }

      commit('setBillOrVAFrozen', isFrozen);
    },
  },
};
