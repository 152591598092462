import BillBatches from './Batches.vue';
import FindBills from './Find.vue';
import MyBills from './My.vue';
import MainLayout from './container.vue';

export default {
  path: '/bills',
  component: MainLayout,
  meta: {
    auth: true,
    breadcrumb: {
      titleTemplate: 'Bills',
    },
  },
  children: [
    {
      path: 'dashboard',
      name: 'bills.dashboard',
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'Bills Dashboard',
        },
      },
    },
    {
      path: 'batches',
      name: 'bills.batches',
      component: BillBatches,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'Bill Batches',
        },
      },
    },
    {
      path: 'find',
      name: 'bills.find',
      component: FindBills,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'Find Bills',
        },
      },
    },
    {
      path: 'my',
      name: 'bills.my',
      component: MyBills,
      meta: {
        auth: true,
        showNavbar: true,
        showBreadcrumbs: true,
        breadcrumb: {
          titleTemplate: 'My Bills',
        },
      },
    },
  ],
};
