<template lang="pug">
.editor()
  el-dialog.editor-modal(:visible.sync='isEditorModalOpen',
                        :modal='false',
                        :show-close='false',
                        top='25px',
                        width='360px')
    editor-modal(:modal-type='modalType',
                @close-editor-modal='closeEditorModal',
                @confirm-update='updateBill',
                @confirm-cancel='cancelUpdate')
  el-dialog.editor-modal(:visible.sync='isEditorInfoModalOpen',
                        :show-close='false',
                        top='25px',
                        width='420px')
    editor-info-modal(:modal-type='infoModalType',
                      @close-editor-modal='closeEditorInfoModal')
  .editor-header
    .editor-header_info
      .editor-header_item
        | Customer
        b {{ billCsv.customer.name || 'N/A' }}
      .editor-header_item
        | ID
        b {{ billCsv.bill.id || 'N/A'}}
          span ({{ billCsv.bill.bill_type }})
    .editor-header_actions
      el-button.plain-button-reverse(@click='showModal("cancel", false)') Cancel
      el-button(type='primary', :disabled='!billWasEdited', @click='showModal("update", false)') Update Bill
  .editor-content
    el-tabs(type='card')
      el-tab-pane(v-for='(item, paneIndex) in parsedCsv', :label='item.meta.name', :key='paneIndex')
        .editor-table
          .editor-column(v-for='(column, columnIndex) in item.data', :key='columnIndex')
            editor-cell(v-for='(cell, index) in column',
                        :cell='cell',
                        :key='index',
                        :pane-index='paneIndex',
                        :column-index='columnIndex',
                        :cell-index='index',
                        :validation='validatedCellData[paneIndex][index]',
                        :cellName='item.data[0][index]',
                        @update-cell='updateCellValue')
</template>

<script>
import { bills } from '@/api';
import EditorModal from './EditorModal.vue';
import EditorInfoModal from './EditorInfoModal.vue';
import EditorCell from './EditorCell.vue';
import * as R from 'ramda';
import Papa from 'papaparse';

export default {
  components: {
    EditorModal,
    EditorInfoModal,
    EditorCell,
  },
  data() {
    return {
      isEditorModalOpen: false,
      isEditorInfoModalOpen: false,
      modalType: null,
      infoModalType: null,
      billCsv: {
        customer: {
          name: '',
        },
        bill: {
          id: '',
          bill_type: '',
        },
        payload: {},
      },
      parsedCsv: [],
      validatedCellData: [],
      isLoading: true,
      closeTabFromModal: false,
      billWasEdited: false,
    };
  },
  created() {
    window.addEventListener('beforeunload', this.handleTabClose);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleTabClose);
  },
  async mounted() {
    await this.loadBillCsv();
  },
  methods: {
    async loadBillCsv() {
      let {
        params: { customerId },
      } = this.$route;
      let {
        params: { billId },
      } = this.$route;
      this.billCsv = await bills.getBillCsv(customerId, billId);

      this.setPageTitle();
      this.parseCsv();

      this.isLoading = false;
    },
    setPageTitle() {
      let {
        customer: { name: customerName },
      } = this.billCsv;
      let {
        bill: { id: billId },
      } = this.billCsv;
      document.title = `Update Bill CSVs for ${customerName} bill ID ${billId}`;
    },
    showModal(type) {
      this.modalType = type;
      this.isEditorModalOpen = !this.isEditorModalOpen;
    },
    closeEditorModal() {
      this.isEditorModalOpen = !this.isEditorModalOpen;
    },
    showInfoModal(type) {
      this.infoModalType = type;
      this.isEditorInfoModalOpen = !this.isEditorInfoModalOpen;
    },
    closeEditorInfoModal() {
      window.open('', '_parent', '');
      window.close();
    },
    handleTabClose(event) {
      if (!this.closeTabFromModal) {
        event.preventDefault();
        event.returnValue = 'Are you sure?';
        return 'Are you sure?';
      }
    },
    parseCsv() {
      this.billCsv.payload.forEach((item) => {
        let parsedItem = Papa.parse(item.payload);
        parsedItem.meta.name = item.name;

        if (R.last(parsedItem.data).length === 1) {
          parsedItem.data = R.init(parsedItem.data);
        }

        this.parsedCsv.push(parsedItem);
      });

      this.validateCellData();
    },
    validateCellData() {
      let checkColumns = R.addIndex(R.map);

      checkColumns((item, index) => {
        let validatedColumn = [];

        R.map((cell) => {
          let valueEndsOn = R.slice(-3, Infinity, cell);

          // Check if the cell content is observationHeader type
          if (
            R.contains(':U', valueEndsOn) ||
            R.contains(':C', valueEndsOn) ||
            R.contains(':UC', valueEndsOn)
          ) {
            validatedColumn.push('observationHeader');
            // Check if the cell content is number type
          } else if (
            R.contains('INFO_COST', cell) ||
            R.contains('TOTALPAYAMOUNT', cell)
          ) {
            validatedColumn.push('number');
            // Check if the cell content is date type
          } else if (
            R.contains('STARTDATE', cell) ||
            R.contains('ENDDATE', cell) ||
            R.contains('DUEDATE', cell) ||
            R.contains('STATEMENTDATE', cell)
          ) {
            validatedColumn.push('date');
            // String is the default type
          } else if (R.contains('DAYSOFSERVICE', cell)) {
            validatedColumn.push('integer');
          } else {
            validatedColumn.push('string');
          }
        }, this.parsedCsv[index].data[0]);

        this.validatedCellData.push(validatedColumn);
      }, this.parsedCsv);
    },
    unparseCsv() {
      let unparsedCsv = [];
      this.parsedCsv.forEach((item) => {
        let unparsedItem = Papa.unparse(item, {
          quotes: false,
          quoteChar: '',
          escapeChar: '',
          delimiter: ',',
          header: true,
          newline: '\r\n',
        });

        unparsedCsv.push(unparsedItem);
      });

      let prepareBillCsv = R.addIndex(R.forEach);

      prepareBillCsv((item, index) => {
        this.billCsv.payload[index].payload = unparsedCsv[index];
      }, this.billCsv.payload);
    },
    async updateBill() {
      await this.unparseCsv();

      let {
        params: { customerId },
      } = this.$route;
      let {
        params: { billId },
      } = this.$route;

      bills.postChangedBillCsv(customerId, billId, this.billCsv);

      this.closeTabFromModal = true;
      this.showInfoModal('updating');
    },
    updateCellValue(newValue) {
      this.parsedCsv[newValue.paneIndex].data[newValue.columnIndex] = R.update(
        newValue.cellIndex,
        newValue.value,
        this.parsedCsv[newValue.paneIndex].data[newValue.columnIndex],
      );
      this.$forceUpdate();

      if (!this.billWasEdited) {
        this.billWasEdited = !this.billWasEdited;
      }
    },
    cancelUpdate() {
      this.closeTabFromModal = true;
      this.showInfoModal('canceled');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../../../../styles/constants.scss';

.editor {
  @extend %scrollbar;
  overflow-y: initial;

  &-header {
    display: flex;
    margin-bottom: 15px;

    &_info {
      display: flex;
      align-items: center;
      width: 50%;
    }
    &_item {
      + .editor-header_item {
        margin-left: 90px;
      }
      b {
        margin-left: 20px;

        span {
          text-transform: capitalize;
        }
      }
    }
    &_actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;

      .el-button {
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 600;
      }
    }
  }
  &-content {
    height: calc(100vh - 65px);
    padding-bottom: 32px;
    box-sizing: border-box;

    @extend %scrollbar;
    overflow-y: initial;
  }
  &-table {
    display: flex;
    //overflow-x: auto;
  }
}
</style>
<style lang="scss">
.editor {
  &-header {
    padding: 0 100px;
  }

  &-content {
    padding: 0 100px;

    .el-tabs__nav {
      background-color: #fff;
    }
    .el-tabs__header {
      margin-bottom: 0;
    }
    .el-tabs__content {
      border: 1px solid #909399;
      border-radius: 4px;
      background-color: #fff;
    }
  }
  &-modal {
    .el-dialog {
      border-radius: 4px;

      &__header {
        padding: 0;
      }
    }
  }
  .el-tabs__content {
    overflow: initial;
  }
}
.editor-modal {
  &_content {
    text-align: center;
  }
  &_actions {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .el-button {
      width: 78px;
      padding-top: 7px;
      padding-bottom: 7px;
      border-radius: 100px;
      font-size: 12px;
      font-weight: 500;

      &.btn-close {
        width: 92px;
      }
    }
  }
  .el-dialog__body {
    padding: 30px;
  }
}
</style>
